import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserOverviewPageComponent } from "./pages/user-overview-page/user-overview-page.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShareModule } from "../share/share.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UserCreatePageComponent } from "./pages/user-create-page/user-create-page.component";
import { SelectAgentsPageComponent } from "./pages/select-agents-page/select-agents-page.component";
import { UserDetailPageComponent } from "./pages/user-detail-page/user-detail-page.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserTransactionComponent } from "./components/user-transaction/user-transaction.component";
import { UserRequestTransactionComponent } from "./components/user-request-transaction/user-request-transaction.component";
import { UserEventTransactionComponent } from "./components/user-event-transaction/user-event-transaction.component";
import { UserDonationTransactionComponent } from "./components/user-donation-transaction/user-donation-transaction.component";
import { UserRedeemTransactionComponent } from "./components/user-redeem-transaction/user-redeem-transaction.component";
import { SignUpSettingPageComponent } from "./pages/sign-up-setting-page/sign-up-setting-page.component";
import { SignUpOverviewPageComponent } from "./pages/sign-up-overview-page/sign-up-overview-page.component";
import { SignUpUserDetailComponent } from "./pages/sign-up-user-detail/sign-up-user-detail.component";
import { SignUpDisapprovePopupComponent } from "./components/sign-up-disapprove-popup/sign-up-disapprove-popup.component";

const routes: Routes = [
  { path: "overview", component: UserOverviewPageComponent },
  { path: "overview/:id/agent", component: SelectAgentsPageComponent },
  {
    path: "overview/:id",
    component: UserDetailPageComponent,
    children: [
      {
        path: "profile",
        component: UserProfileComponent,
        data: { animationState: "profile" },
      },
      {
        path: "transaction",
        component: UserTransactionComponent,
        data: { animationState: "transaction" },
      },
      { path: "", redirectTo: "profile", pathMatch: "full" },
      { path: "**", redirectTo: "profile" },
    ],
  },
  { path: "create", component: UserCreatePageComponent },
  { path: "create/agent", component: SelectAgentsPageComponent },
  { path: "sign-up-setting", component: SignUpSettingPageComponent },
  { path: "sign-up-overview", component: SignUpOverviewPageComponent },
  { path: "sign-up-overview/:id", component: SignUpUserDetailComponent },
  { path: "", redirectTo: "overview", pathMatch: "full" },
  { path: "**", redirectTo: "overview" },
];

@NgModule({
  declarations: [
    UserOverviewPageComponent,
    UserCreatePageComponent,
    SelectAgentsPageComponent,
    UserDetailPageComponent,
    UserProfileComponent,
    UserTransactionComponent,
    UserRequestTransactionComponent,
    UserEventTransactionComponent,
    UserDonationTransactionComponent,
    UserRedeemTransactionComponent,
    SignUpSettingPageComponent,
    SignUpOverviewPageComponent,
    SignUpUserDetailComponent,
    SignUpDisapprovePopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    MatDatepickerModule,
    MatSnackBarModule,
  ],
})
export class UserModule {}
