
import { CellGroup, ICellGroup } from "../cell-groups/cell-group";
import { UpdateCenterLinkModel } from "./update-center-link-model";
import { UpdateCenterModel } from "./update-center-model";

export interface ICenter {
    uuId: string;
    name: string;
    centerCode: string;
    phoneNumber: string;
    cellGroups: ICellGroup[];
    redeemEnabled: boolean;
    canFindCenters:ICenter[];
    otherContactInfo:any;
}

export interface ICenterLink{
    center:Center;
    canFindCenters:Center[];
}
export class CenterLink{
    center:Center;
    canFindCenters:Center[];
    constructor(center: Center,canFindCenters:Center[]) {
        this.center=center;
        this.canFindCenters=canFindCenters;
        this.center.canFindCenters=canFindCenters;
    }
    getUpdateCenterLinkModel(): UpdateCenterLinkModel {
        return {
            targetCenter:this.center.uuId,
            centersList:this.canFindCenters.map(c=>c.uuId)
        }
    }
}

export class Center {
    uuId: string;
    name: string;
    centerCode: string;
    phoneNumber: string;
    cellGroups: CellGroup[];

    selected: boolean;
    editing: boolean;
    redeemEnabled: boolean;

    otherContactInfo:any;
    canFindCenters: Center[];

    imagePath: string;

    constructor(center: ICenter) {
        this.uuId = center.uuId;
        this.name = center.name;
        this.centerCode = center.centerCode;
        this.phoneNumber = center.phoneNumber;
        this.selected = false;
        this.editing = false;
        this.redeemEnabled = center.redeemEnabled;
        if(center.otherContactInfo&&typeof center.otherContactInfo=="string")
            this.otherContactInfo=JSON.parse(center.otherContactInfo);
        this.cellGroups = center.cellGroups ? center.cellGroups.map(cellGroup => new CellGroup(cellGroup)).sort(CellGroupSorting) : [];
     
        this.canFindCenters=center.canFindCenters ?center.canFindCenters.map(c=>new Center(c)):[];
        this.imagePath="";
        if( this.otherContactInfo!=null&&typeof this.otherContactInfo.icon!="undefined"&&this.otherContactInfo.icon!=null){
            this.imagePath=this.otherContactInfo.icon;
        }
    }

    getUpdateCenterModel(): UpdateCenterModel {
        return {
            uuId: this.uuId,
            centerCode: this.centerCode,
            phoneNumber: this.phoneNumber,
            name: this.name,
            OtherContactInfo:JSON.stringify(this.otherContactInfo)
        }
    }
}

function CellGroupSorting(a: CellGroup, b: CellGroup) {
    return a.name.localeCompare(b.name, 'en', { numeric: true })
}
