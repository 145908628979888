<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>活動</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'request'" translate>義工服務</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'event'" translate>中心活動</p>
    </div>

    <p class="page-title mt-8" *ngIf="tab == 'request'" translate>義工服務</p>
    <p class="page-title mt-8" *ngIf="tab == 'event'" translate>中心活動</p>

    <div class="container mt-5">
        <div class="container-header">
            <p class="container-header-tab" routerLink="request" routerLinkActive="active" (click)="tab = 'request'" translate>義工服務</p>
            <p class="container-header-tab" routerLink="event" routerLinkActive="active" (click)="tab = 'event'" translate>中心活動</p>
        </div>
        <div class="container-body">
            <div class="inline-flex-column" [@slide]="prepareRoute(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </div>

</div>