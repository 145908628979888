<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>請求/訂單</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>創建活動記錄</p>
    </div>

    <p class="page-title mt-8" translate>創建活動記錄</p>

    <div class="container mt-5">
        <form class="container-body" [formGroup]="form.formGroup">
            <p class="text-lightgrey text-sm" translate>欄目帶 * 號為必填項</p>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '活動名目' | translate }}" [matTooltipPosition]="'above'" translate>活動名目</p>
                <input class="user-input md" type="text" formControlName="eventName" placeholder="{{ '活動名目' | translate }}">

                <div class="user-input-error zoomIn" *ngIf="eventName.touched && eventName.errors">
                    <p *ngIf="eventName.errors.required" translate>請填寫活動名目</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" translate>活動類型</p>
                <div class="user-select">
                    <select class="user-select xxxl" formControlName="eventType">
                        <option [ngValue]="null" selected disabled translate>請選擇活動類型</option>
                        <option *ngFor="let type of eventTypes" [ngValue]="type.uuId">{{ type.name }}</option>
                    </select>
                </div>

                <div class="user-input-error zoomIn" *ngIf="eventType.touched && eventType.errors">
                    <p *ngIf="eventType.errors.required" translate>請選擇活動類型</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '交易類型' | translate }}" [matTooltipPosition]="'above'" translate>交易類型</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row align-items-center">
                        <label>
                            <input type="radio" value="Free" formControlName="transactionType">
                            <p class="lh-8" translate>免費活動</p>
                        </label>

                        <label class="ml-8">
                            <input type="radio" value="UserSpend" formControlName="transactionType">
                            <p class="lh-8" translate>用户扣分</p>
                        </label>

                        <label class="ml-8">
                            <input type="radio" value="UserEarn" formControlName="transactionType">
                            <p class="lh-8" translate>用户加分</p>
                        </label>
                    </div>

                    <div class="transaction-fee-box" *ngIf="transactionType.value != 'Free' && transactionType.value != null">
                        <ng-container>
                            <p *ngIf="transactionType.value == 'UserSpend'" translate>默認每個帳戶將會支出</p>
                            <p *ngIf=" transactionType.value=='UserEarn'" translate>默認每個帳戶將會賺取</p>
                            <input class=" user-input ml-4 mr-4" style="width: 4.3125rem;" type="number" formControlName="transactionFee">
                            <p translate>時分</p>
                        </ng-container>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="transactionType.touched && transactionType.errors">
                        <p *ngIf="transactionType.errors.required" translate>請選擇交易類型</p>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="transactionFee.touched && transactionFee.errors">
                        <p *ngIf="transactionFee.errors.required" translate>請填寫交易時分</p>
                        <p *ngIf="transactionFee.errors.min" translate>交易時分必須大於 0</p>
                    </div>
                </div>

            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '參與方式' | translate }}" [matTooltipPosition]="'above'" translate>參與方式</p>
                <label>
                    <input type="radio" value="FIFJ" formControlName="participationFormat">
                    <p translate>先到先得</p>
                </label>

                <label class="ml-8">
                    <input type="radio" value="Staff" formControlName="participationFormat">
                    <p translate>職員處理</p>
                </label>

                <div class="user-input-error zoomIn" *ngIf="participationFormat.touched && participationFormat.errors">
                    <p *ngIf="participationFormat.errors.required" translate>請選擇參與方式</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '活動日期' | translate }}" [matTooltipPosition]="'above'" translate>活動日期</p>

                <ng-container *ngIf="form.eventDays.length == 0">
                    <app-event-day-scheduler [cancelAction]="cancelAction" (valueSignal)="print($event)" [hasMinDate]="false"></app-event-day-scheduler>
                </ng-container>

                <ng-container *ngIf="form.eventDays.length == 1">
                    <form [formGroup]="form.eventDays[0].formGroup">
                        <input matInput class="user-input md" [matDatepicker]="picker2" formControlName="date" (click)="picker2.open()" placeholder="{{ '活動日期' | translate }}" readonly>
                        <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                    </form>

                    <p class="click-text-blue ml-9" (click)="singleDayMode.setValue(false)" translate>多日期活動設置</p>

                    <div class="user-input-error zoomIn" *ngIf="form.eventDays[0].date.touched && form.eventDays[0].date.errors">
                        <p *ngIf="form.eventDays[0].date.errors.required" translate>請填寫活動日期</p>
                    </div>
                </ng-container>

                <ng-container *ngIf="form.eventDays.length > 1;then schedule"></ng-container>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '截止報名' | translate }}" [matTooltipPosition]="'above'" translate>截止報名</p>
                <input matInput class="user-input md" [matDatepicker]="picker" formControlName="closeDate" (click)="picker.open()" placeholder="{{ '截止報名' | translate }}" readonly>
                <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>

                <app-time-picker class="ml-9" [form]="closeDate"></app-time-picker>

                <div class="user-input-error zoomIn" *ngIf="closeDate.touched && closeDate.errors">
                    <p *ngIf="closeDate.errors.required" translate>請填寫截止報名日期</p>
                    <p *ngIf="closeDate.errors.time" translate>請填寫截止報名時間</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '內容類型' | translate }}" [matTooltipPosition]="'above'" translate>內容類型</p>
                <label>
                    <input type="radio" formControlName="urlType" value="text">
                    <p translate>純文本</p>
                </label>
                <label style="margin-left: 1.875rem;">
                    <input type="radio" formControlName="urlType" value="url">
                    <p translate>超鏈接</p>
                </label>
            </div>

            <div class="input-row" *ngIf="urlType.value == 'url'">
                <p class="title required" matTooltip="{{ '內容類型' | translate }}" [matTooltipPosition]="'above'" translate>連結網址</p>
                <input class="user-input xl" type="text" formControlName="url" placeholder="{{ '請輸入超鏈接' | translate }}">

                <button type="button" class="button outline blue sm ml-6" type="button" (click)="openURL()" translate>瀏覽網址</button>

                <div class="zoomIn user-input-error" *ngIf="url.touched && url.errors">
                    <p class="error-text text-sm" *ngIf="url.errors.required" translate>請輸入超鏈接</p>
                </div>
            </div>

            <form class="inline-flex-column" *ngIf="form.eventDays.length == 1">
                <div class="input-row">
                    <p class="title" translate>開始時間</p>
                    <app-time-picker [form]="form.eventDays[0].start"></app-time-picker>

                    <div class="user-input-error zoomIn" *ngIf="form.eventDays[0].start.touched && form.eventDays[0].start.errors">
                        <p *ngIf="form.eventDays[0].start.errors.time" translate>請填寫活動開始時間</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title" translate>結束時間</p>
                    <app-time-picker [form]="form.eventDays[0].end"></app-time-picker>

                    <div class="user-input-error zoomIn" *ngIf="form.eventDays[0].end.touched && form.eventDays[0].end.errors">
                        <p *ngIf="form.eventDays[0].end.errors.time" translate>請填寫活動結束時間</p>
                        <p *ngIf="form.eventDays[0].end.errors.invalid" translate>活動結束時間必須在活動開始時間後</p>
                    </div>
                </div>
            </form>

            <ng-container *ngIf="profile">
                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 1.5rem;" matTooltip="{{ '集合地址' | translate }}" [matTooltipPosition]="'above'" translate>集合地址</p>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center" *ngFor="let address of profile.addresses;let index = index">
                            <div class="inline-flex-column">
                                <div class="inline-flex-row" *ngIf="address.editing == false">
                                    <label>
                                        <input class="mr-4" type="radio" formControlName="venue" [value]="address.room.uuId">
                                        <p style="line-height: 1.5rem;">{{ address.toString() }}</p>
                                    </label>
                                    <i class="material-icons-outlined text-lightblue ml-4" style="font-size: 1rem;cursor: pointer;" (click)="address.editing = true">create</i>
                                </div>
                                <ng-container *ngIf="address.editing == true">
                                    <app-address-form [form]="address.form" [index]="index+2" [dropAction]="dropAddressAction"></app-address-form>
                                    <button type="button" class="add-address-button" (click)="editAddress(address)" translate>保存地址</button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="inline-flex-row">
                            <label>
                                <input class="mr-4" type="radio" formControlName="venue" [value]="NAaddress.uuId">
                                <p style="line-height: 1.5rem;">{{ NAaddress.name.toString() }}</p>
                            </label>
                        </div>

                        <ng-container *ngFor="let form of venueForms">
                            <app-address-form class="mt-4" [form]="form" [index]="2" [dropAction]="dropVenue"></app-address-form>

                            <button type="button" class="add-address-button" (click)="saveVenue()" translate>保存地址</button>
                        </ng-container>

                        <button type="button" class="add-address-button" *ngIf="venueForms.length == 0" (click)="addVenue()" translate>增添地址</button>
                    </div>
                </div>
            </ng-container>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '參與者數量' | translate }}" [matTooltipPosition]="'above'" translate>參與者數量</p>
                <input class="user-input md" type="number" disabled [value]="form.users.length">

                <div class="user-input-error zoomIn" *ngIf="vacancy.touched && vacancy.errors">
                    <p *ngIf="vacancy.errors.required" translate>請填寫參與者數量</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title align-self-start lh-8" translate>活動詳情</p>
                <textarea style="width: 22rem;height: 8.625rem;" formControlName="eventDetail"></textarea>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '指定交易會員' | translate }}" [matTooltipPosition]="'above'" translate>指定交易會員</p>
                <div class="inline-flex-column" *ngIf="form.users.length == 0">
                    <p class="text-lightgrey lh-8" translate>未指定，請在會員列表中確認人選</p>
                    <button type="button" class="button outline blue sm" [routerLink]="['/main/activity/event-record/select-participants']" translate>會員列表</button>
                </div>

                <div class="inline-flex-row align-items-center" *ngIf="form.users.length > 0">
                    <button type="button" class="button outline blue sm" [routerLink]="['/main/activity/event-record/select-participants']" translate>選擇會員</button>
                </div>
            </div>

            <div class="inline-flex-column mt-4" *ngIf="form.users.length > 0">
                <div class="inline-flex-row align-items-center">
                    <input class="mr-4" type="checkbox" [formControl]="form.giveAll">
                    <p *ngIf=" transactionType.value == 'UserEarn'" translate>每個帳戶將會賺取</p>
                    <p *ngIf=" transactionType.value != 'UserEarn'" translate>每個帳戶將會支出</p>
                    <input class="user-input ml-4 mr-4" type="number" style="width: 4.3125rem;" [formControl]="form.giveInput">
                    <p translate>時分</p>
                </div>

                <div class="inline-flex-row mt-4">
                    <p translate>已選擇以下2</p>
                    <p class="ml-2 mr-2 text-lightblue">{{ form.users.length }}</p>
                    <p translate>位會員，請填妥交易時分.</p>
                </div>

                <div class="agent-table">
                    <div class="agent-table-row header">
                        <div class="agent-table-cell">
                            <p translate>會員編號</p>
                        </div>
                        <div class="agent-table-cell">
                            <p translate>中文姓名</p>
                        </div>
                        <div class="agent-table-cell">
                            <p translate>中心代碼</p>
                        </div>
                        <div class="agent-table-cell">
                            <p translate>電話</p>
                        </div>
                        <div class="agent-table-cell">
                            <p translate>交易時分</p>
                        </div>
                        <div class="agent-table-cell"></div>
                    </div>
                    <div class="agent-table-row" *ngFor="let user of form.users;let index = index">
                        <div class="agent-table-cell">
                            <p>{{ user.memberId }}</p>
                        </div>
                        <div class="agent-table-cell">
                            <p>{{ user.chineseName }}</p>
                        </div>
                        <div class="agent-table-cell">
                            <p>{{ user.center.centerCode }}</p>
                        </div>
                        <div class="agent-table-cell">
                            <p>{{ user.phone }}</p>
                        </div>
                        <div class="agent-table-cell">
                            <input class="user-input" style="width: 4rem;" type="number" [formControl]="user.recordForm">
                        </div>
                        <div class="agent-table-cell">
                            <p class="text-xs click-text-lightblue" (click)="dropUser(index)" translate>移除</p>
                        </div>
                    </div>
                </div>

                <div class="inline-flex-row mt-4">
                    <p translate>總共支出</p>
                    <p class="ml-2 mr-2 text-lightblue">{{ form.sum }}</p>
                    <p translate>時分至所選會員帳戶</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title align-self-start lh-8" translate>備註</p>
                <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
            </div>

            <div class="input-row" *ngFor="let column of form.columns">
                <p class="title">{{ column.columnName }}</p>
                <input type="text" class="user-input" [formControl]="column.input" placeholder="{{ column.columnDescription }}">
            </div>

            <div class="inline-flex-row align-items-center align-self-center" style="margin-top: 4.6875rem;">
                <button type="button" class="button outline" (click)="reset()" translate>取消創建</button>
                <button type="button" class="button fill blue" style="margin-left: 9.375rem;" (click)="submit()" translate>提 交</button>
            </div>
        </form>
    </div>
</div>

<ng-template #schedule>
    <div class="inline-flex-column">
        <div class="inline-flex-row align-items-center">
            <p translate>由</p>
            <p class="ml-2 mr-2">{{ form.getEventStartDate() | date: 'longDate': '': lang}}</p>
            <p translate>至</p>
            <p class="ml-2 mr-2">{{ form.getEventEndDate() | date: 'longDate': '': lang}}</p>
            <p translate>，活動舉辦</p>
            <p class="ml-2 mr-2 text-lightblue">{{ form.eventDays.length }}</p>
            <p translate>次</p>
            <div class="inline-flex-row align-items-center schedule-toggle" (click)="toggle = !toggle" [ngClass]="{on: toggle}">
                <p class="click-text-lightblue ml-8">收起</p>
            </div>
        </div>

        <div [@dropdown]="toggle ? 'on' : 'off'">
            <div class="agent-table" style="overflow: hidden;padding-bottom: 7.5rem;">
                <div class="agent-table-row header">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p translate>活動舉辦</p>
                        <p class="text-lightblue ml-2 mr-2">{{ form.eventDays.length }}</p>
                        <p translate>次</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;"></div>
                    <div class="agent-table-cell"></div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;" (click)="clearAll()">
                        <i class="click-text-lightblue material-icons-round" style="font-size: 1.25rem;">delete</i>
                        <p class="click-text-lightblue">移除所有</p>
                    </div>
                </div>

                <div class="agent-table-row" *ngFor="let day of form.eventDays; let index = index">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p>{{ day.date.value | date: 'longDate' : '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;">
                        <p>{{ day.date.value | date: 'EEEE': '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p *ngIf="day.editing == false">{{ day.start.value | date: 'HH:mm' }}</p>
                        <app-time-picker *ngIf="day.editing == true" [form]="day.start"></app-time-picker>
                        <p class="ml-5 mr-5" translate>至</p>
                        <p *ngIf="day.editing == false">{{ day.end.value | date: 'HH:mm' }}</p>
                        <app-time-picker *ngIf="day.editing == true" [form]="day.end"></app-time-picker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;">
                        <p class="click-text-lightblue text-xs" *ngIf="day.editing == false" (click)="day.editing = true" translate>修改</p>
                        <p class="click-text-lightblue text-xs" *ngIf="day.editing == true" (click)="day.editing = false" translate>確認</p>
                        <p class="click-text-lightblue text-xs" style="margin-left: 1.75rem;" (click)="dropEventDay(index)" translate>移除</p>
                    </div>
                </div>

                <div class="agent-table-row" *ngIf="newEventDayForm">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <input matInput class="user-input" style="width: 5.8125rem;" [matDatepicker]="picker3" [formControl]="newEventDayForm.date" (click)="picker3.open()" placeholder="{{ '活動日' | translate }}" readonly>
                        <mat-datepicker touchUi #picker3 disabled="false"></mat-datepicker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;"></div>
                    <div class="agent-table-cell">
                        <app-time-picker [form]="newEventDayForm.start"></app-time-picker>
                        <p class="ml-5 mr-5" translate>至</p>
                        <app-time-picker [form]="newEventDayForm.end"></app-time-picker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;">
                        <p class="click-text-lightblue text-xs" (click)="saveNewEventDay()" translate>保存</p>
                        <p class="click-text-lightblue text-xs" style="margin-left: 1.75rem;" (click)="newEventDayForm = null" translate>移除</p>
                    </div>
                </div>

                <div class="agent-table-row align-items-center" style="border-bottom: none;" *ngIf="newEventDayForm == null">
                    <p class="click-text-lightblue" (click)="addEventDay()" translate>+ 新增日期</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>