import { Component, Input, OnInit } from '@angular/core';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-event-transaction-type-filter',
  templateUrl: './event-transaction-type-filter.component.html',
  styleUrls: ['./event-transaction-type-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class EventTransactionTypeFilterComponent implements OnInit {

  @Input() action: (statuses: string[]) => void = () => { }
  statuses = [
    { name: "免費活動", selected: false, value: 'Free' },
    { name: "會員加分", selected: false, value: 'UserEarn' },
    { name: "會員扣分", selected: false, value: 'UserSpend' },
  ];
  display: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  reset(): void {
    this.statuses.forEach(status => status.selected = false);
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.statuses.filter(status => status.selected).map(status => status.value));
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter(status => status.selected).length;
  }
}
