import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Center } from 'src/app/models/centers/center';
import { CenterService } from 'src/app/services/center/center.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { StaffService } from 'src/app/services/staff/staff.service';
import { CreateStaffForm } from '../../forms/create-staff-form';

@Component({
  selector: 'app-staff-create-page',
  templateUrl: './staff-create-page.component.html',
  styleUrls: ['./staff-create-page.component.scss']
})
export class StaffCreatePageComponent implements OnInit {

  form: CreateStaffForm = new CreateStaffForm();
  centers: Center[] = [];

  constructor(
    private centerService: CenterService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private staffService: StaffService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.centerService.getCenters()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => this.centers = value
      });
  }

  dropAddressAction = ((index: number) => {
    this.form.addresses.splice(index, 1);
  }).bind(this);

  onCenterChange(): void {
    this.form.accessibleCenters = [(this.centers.find(c => c.uuId == this.center.value) as Center)];
  }

  permissionAction = ((centers: Center[]) => {
    this.form.accessibleCenters = centers;
  }).bind(this);

  dropPermission(index: number): void {
    this.form.accessibleCenters.splice(index, 1);
  }

  submit(): void {
    if (this.form.valid == false) {
      this.form.formGroup.markAllAsTouched();
      this.form.addresses.forEach(address => address.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();
    this.staffService.registerStaff(this.form.getCreateStaffModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => this.popupMessageService.messageSignal.emit(PopupMessages.CreateStaffSuccessMessage(
          new Observable<any>(subscriber => {
            this.router.navigate(['/main/staff/overview', value.result.uuid]);
          })
        ))
      });
  }

  get defaultCenter(): Center | undefined {
    return this.centers.find(center => center.uuId == this.center.value);
  }

  get memberId(): AbstractControl {
    return this.form.formGroup.controls['memberId'];
  }

  get username(): AbstractControl {
    return this.form.formGroup.controls['username'];
  }

  get password(): AbstractControl {
    return this.form.formGroup.controls['password'];
  }

  get center(): AbstractControl {
    return this.form.formGroup.controls['center'];
  }

  get chineseName(): AbstractControl {
    return this.form.formGroup.controls['chineseName'];
  }

  get englishName(): AbstractControl {
    return this.form.formGroup.controls['englishName'];
  }

  get gender(): AbstractControl {
    return this.form.formGroup.controls['gender'];
  }

  get phone(): AbstractControl {
    return this.form.formGroup.controls['phone'];
  }
}
