import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupMessages } from 'src/app/backend/popup-message';
import { EventDay } from 'src/app/models/events/event-day';
import { EventService } from 'src/app/services/event/event.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-event-attendance-popup',
  templateUrl: './event-attendance-popup.component.html',
  styleUrls: ['./event-attendance-popup.component.scss']
})
export class EventAttendancePopupComponent implements OnInit {

  @Input() reloadAction: () => void = () => { }
  @Input() eventdays: EventDay[]=[];

  display: boolean = false;
  lang: string = this.translateService.currentLang;
  oldValue:any={};
  total: number=0;
  nums:number=30;
  isEdit:boolean=false;
  constructor(
    private translateService: TranslateService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService) { 

    }

  ngOnInit(): void {
    this.total=0;


    this.eventdays.forEach((eventday)=>{
      this.total+=eventday.numOfAttendance?eventday.numOfAttendance:0;
      if(eventday.uuId!=null)
        this.oldValue[eventday.uuId]=eventday.numOfAttendance;
    });


    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
  }
  open(): void {
    this.display = true;
  }
  closeAction: () => void = (() => {
    this.display = false;
  }).bind(this);
  changeTotal:()=>void =(()=>{

    this.total=0;
    this.eventdays.forEach((eventday)=>{
      this.total+=eventday.numOfAttendance?eventday.numOfAttendance:0;
    });
  }).bind(this);
  save:()=>void =(()=>{


    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.changeTotal();
      forkJoin(this.eventdays.map((eventday)=>{
        
        if(eventday.uuId!=null)
          this.oldValue[eventday.uuId]=eventday.numOfAttendance;
        return this.eventService.SetAttendance( {
          uuId:eventday.uuId,
          date:eventday.date.toISOString(),
          startTime:eventday.startTime.toISOString(),
          endTime:eventday.endTime==null?"":eventday.endTime.toISOString(),
          numOfAttendance:eventday.numOfAttendance
        })
      })).pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
         this.popupMessageService.messageSignal.emit(PopupMessages.EditAttendanceSuccessMessage);
         this.reloadAction();
         this.closeEdit();
         this.closeAction();
      });
    });

  }).bind(this);

  closeEdit: () => void = (() => {
    this.isEdit = false;
    this.eventdays.forEach((eventday)=>{
      if(eventday.uuId!=null)
        eventday.numOfAttendance=this.oldValue[eventday.uuId];
    });
  }).bind(this);

  edit:()=>void =(()=>{
    this.isEdit=true;
  }).bind(this);


  checkState:(date:EventDay)=>void=((date:EventDay)=>{
    var now=new Date();
    if(now<date.startTime){
      return 1;
    }else if(now>date.startTime && (date.endTime!=null &&now < date.endTime)){
      return 0;
    }else return -1;
  }).bind(this);
}
