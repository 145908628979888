import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Center } from "src/app/models/centers/center";
import { CreateProductModel } from "src/app/models/redeems/create-product-model";
import { ProductListItem } from "src/app/models/redeems/product-list-item";
import * as dayjs from "dayjs";

export class CreateProductForm {
  formGroup: FormGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    source: new FormControl(null, [Validators.required]),
    format: new FormControl(null, [Validators.required]),
    target: new FormControl(null, [Validators.required]),
    targetAll: new FormControl(false),
    price: new FormControl(null, [Validators.required, Validators.min(0)]),
    stock: new FormControl(null, [Validators.required, Validators.min(-1)]),
    adequateStock: new FormControl(false),
    limit: new FormControl(null, [Validators.required, Validators.min(-1)]),
    noLimit: new FormControl(false),
    closeDate: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    launchDate: new FormControl(null, [Validators.required]),
    launchImmediately: new FormControl(false),
    questionSetting: new FormControl(null, [Validators.required]),
    free: new FormControl(false),
  });
  centers: Center[] = [];
  image: Blob | null = null;
  imagePath: string | null = null;
  constructor() {
    (
      this.formGroup.controls["targetAll"] as FormControl
    ).valueChanges.subscribe((value) => {
      var control = this.formGroup.controls["target"];

      if (value == true) {
        control.disable();
        control.setValue("所有會員");
      } else {
        control.enable();
        control.setValue(null);
      }
    });

    (
      this.formGroup.controls["adequateStock"] as FormControl
    ).valueChanges.subscribe((value) => {
      var control = this.formGroup.controls["stock"];

      if (value == true) {
        control.disable();
        control.setValue(-1);
      } else {
        control.enable();
        control.setValue(null);
      }
    });

    (this.formGroup.controls["noLimit"] as FormControl).valueChanges.subscribe(
      (value) => {
        var control = this.formGroup.controls["limit"];

        if (value == true) {
          control.disable();
          control.setValue(-1);
        } else {
          control.enable();
          control.setValue(null);
        }
      },
    );

    (
      this.formGroup.controls["launchImmediately"] as FormControl
    ).valueChanges.subscribe((value) => {
      let date = this.formGroup.controls["launchDate"];

      if (value == true) {
        date.disable();
        date.setValue(null);
      } else {
        date.enable();
        date.setValue(null);
      }
    });

    (this.formGroup.controls["free"] as FormControl).valueChanges.subscribe(
      (value) => {
        var control = this.formGroup.controls["price"];

        if (value == true) {
          control.disable();
          control.setValue(0);
        } else {
          control.enable();
          control.setValue(null);
        }
      },
    );

    (
      this.formGroup.controls["closeDate"] as FormControl
    ).valueChanges.subscribe((value) => {
      if (
        this.formGroup.controls["launchDate"].valid &&
        this.formGroup.controls["closeDate"].valid
      ) {
        if (
          this.formGroup.controls["launchImmediately"].value == false &&
          (this.formGroup.controls["launchDate"].value as Date) >
            (this.formGroup.controls["closeDate"].value as Date)
        ) {
          this.formGroup.controls["closeDate"].setErrors({
            invalid: "invalid",
          });
        }
      }
    });

    (
      this.formGroup.controls["launchDate"] as FormControl
    ).valueChanges.subscribe((value) => {
      if (
        this.formGroup.controls["launchDate"].valid &&
        this.formGroup.controls["closeDate"].valid
      ) {
        if (
          this.formGroup.controls["launchImmediately"].value == false &&
          (this.formGroup.controls["launchDate"].value as Date) >
            (this.formGroup.controls["closeDate"].value as Date)
        ) {
          this.formGroup.controls["closeDate"].setErrors({
            invalid: "invalid",
          });
        }
      }
    });
  }

  isValid(): boolean {
    return this.formGroup.valid;
  }

  getFormData(): FormData {
    let date = dayjs().add(3, "minutes").toDate();

    let model: CreateProductModel = {
      ProductName: this.formGroup.controls["title"].value,
      Description: this.formGroup.controls["description"].value,
      Price: this.formGroup.controls["price"].value,
      Stock: this.formGroup.controls["stock"].value,
      MaximumQuantityPerPerson: this.formGroup.controls["limit"].value,
      LaunchDate:
        this.formGroup.controls["launchImmediately"].value == true
          ? date.toJSON()
          : (this.formGroup.controls["launchDate"].value as Date).toJSON(),
      CloseDate: (this.formGroup.controls["closeDate"].value as Date).toJSON(),
      Brand: this.formGroup.controls["source"].value,
      Target: this.formGroup.controls["target"].value,
      Format: this.formGroup.controls["format"].value,
      Image: this.image,
      CenterList: this.centers.map((center) => center.uuId),
      IsAnswerQuestionRequired:
        this.formGroup.controls["questionSetting"].value,
    };

    let data = new FormData();
    Object.keys(model).forEach((key) => {
      if (key == "CenterList") {
        if ((model[key] as string[]).length > 0)
          (model[key] as string[]).forEach((value) => data.append(key, value));
        return;
      }
      if (key == "Image" && (this.image as any)?.name == undefined) {
        if (model["Image"] != null)
          data.append(
            key,
            model["Image"],
            this.formGroup.controls["title"].value +
              (model["Image"].type == "image/jpg" ? ".jpg" : ".png"),
          );
      } else data.append(key, model[key]);
    });

    return data;
  }

  apply(product: ProductListItem): void {
    this.formGroup.reset();
    this.centers = [];

    this.formGroup.controls["title"].setValue(product.productName);
    this.formGroup.controls["format"].setValue(
      product.format == "FirstComeFirstServed" ? "1" : "0",
    );
  }
}
