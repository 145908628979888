import { EventDay } from "src/app/models/events/event-day";
import { UpdateEventDayModel } from "src/app/models/events/update-event-day-model";
import { CreateEventDayForm } from "./create-event-day-form";

export class UpdateEventDayForm extends CreateEventDayForm {
  uuId: string | null;

  constructor(eventDay: EventDay) {
    super(eventDay);
    this.uuId = eventDay.uuId;
  }

  getUpdateEventDayModel(): UpdateEventDayModel {
    return {
      uuId: this.uuId,
      date: (this.date.value as Date).toJSON(),
      startTime: (this.start.value as Date).toJSON(),
      endTime: (this.end.value as Date).toJSON(),
      numOfAttendance: this.numOfAttendance,
    };
  }

  getModel(): UpdateEventDayModel {
    return this.getUpdateEventDayModel();
  }
}
