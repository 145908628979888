import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { IApproveSignUpUserModel } from "src/app/models/sign-ups/approve-sign-up-user-model";
import { ISignUpUser, SignUpUser } from "src/app/models/sign-ups/sign-up-user";
import {
  ISignUpUserListItem,
  SignUpUserListItem,
} from "src/app/models/sign-ups/sign-up-user-list-item";

@Injectable({
  providedIn: "root",
})
export class SignUpService {
  constructor(private httpService: HttpService) {}

  getSignUpUserList(
    query: string,
  ): Observable<{ list: SignUpUserListItem[]; count: number }> {
    return this.httpService
      .get<{ list: ISignUpUserListItem[]; count: number }>(
        "auth/signuplist" + query,
      )
      .pipe(
        map((value) => {
          return {
            list: value.result.list.map((u) => new SignUpUserListItem(u)),
            count: value.result.count,
          };
        }),
      );
  }

  getSignUpUser(userUUId: string): Observable<SignUpUser> {
    return this.httpService
      .get<ISignUpUser>("auth/SignUpUser?UserUUId=" + userUUId)
      .pipe(map((value) => new SignUpUser(value.result)));
  }

  startApproval(userUUId: string): Observable<any> {
    return this.httpService.post<any, { userUUId: string }>(
      "auth/startApproval",
      { userUUId: userUUId },
    );
  }

  approve(model: IApproveSignUpUserModel): Observable<any> {
    return this.httpService.post<any, IApproveSignUpUserModel>(
      "auth/approve",
      model,
    );
  }

  disapprove(model: { UserUUId: string; Reason: string }): Observable<any> {
    return this.httpService.post<any, { UserUUId: string; Reason: string }>(
      "auth/disapprove",
      model,
    );
  }

  resetApproval(userUUId: string): Observable<any> {
    return this.httpService.post<any, { userUUId: string }>(
      "auth/ResetApproval",
      { userUUId: userUUId },
    );
  }
  getSignUserNum(): Observable<Number> {
    return this.httpService
      .get<Number>("auth/SignUserNum")
      .pipe(map((value) => Number(value.result)));
  }
}
