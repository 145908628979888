import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private appLoadingSignal: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public getAppLoadingSignal(): EventEmitter<boolean> {
    return this.appLoadingSignal;
  }

  public startLoading(): void {
    this.appLoadingSignal.emit(true);
  }

  public stopLoading(): void {
    this.appLoadingSignal.emit(false);
  }
}
