import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IService, Service } from "./service";
import { UpdateServiceTypeModel } from "./update-service-type-model";

export interface IServiceType {
    uuId: string;
    name: string;
    services: IService[];
}

export class ServiceType {
    public uuId: string;
    public name: string;
    public services: Service[];

    editing: boolean;
    selected: boolean;
    form: FormGroup;

    constructor(serviceType: IServiceType) {
        this.uuId = serviceType.uuId;
        this.name = serviceType.name;
        this.services = serviceType.services.map(service => new Service(service)).sort(ServiceSorting);
        this.editing = false;
        this.form = new FormGroup({
            name: new FormControl(serviceType.name, [Validators.required])
        });
        this.selected = false;
    }

    getUpdateServiceTypeModel(): UpdateServiceTypeModel {
        return {
            uuId: this.uuId,
            name: this.form.controls['name'].value
        }
    }

    reset(): void {
        this.services.forEach(service => service.selected = false);
    }

    selectAll(): void {
        if (this.isSelectedAll)
            this.services.forEach(service => service.selected = false);
        else
            this.services.forEach(service => service.selected = true);
    }


    get isSelectedAll(): boolean {
        return this.services.every(service => service.selected == true);
    }

    get selectedServices(): Service[] {
        return this.services.filter(service => service.selected == true);
    }
}

function ServiceSorting(a: Service, b: Service): number {
    if (a.servicesCode == null)
        return 1;
    if (b.servicesCode == null)
        return -1;
    return a.servicesCode.localeCompare(b.servicesCode, 'en', { numeric: true })
}