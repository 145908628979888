import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { DonationService } from 'src/app/services/donation/donation.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { CreateUserDonationForm } from '../../forms/create-user-donation-form';

@Component({
  selector: 'app-donation-user',
  templateUrl: './donation-user.component.html',
  styleUrls: ['./donation-user.component.scss']
})
export class DonationUserComponent implements OnInit {

  form: CreateUserDonationForm = this.donationService.createUserDonationForm;

  constructor(
    private donationService: DonationService,
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    if (this.form.donor == null)
      this.form.formGroup.disable();
    else
      this.form.formGroup.enable();
  }

  submit(): void {
    if (this.form.formGroup.invalid || this.form.donor == null) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      this.form.formGroup.markAllAsTouched();
      return;
    }

    this.loadingService.startLoading();
    this.donationService.donateToCenter(this.form.getCreateUserDonationModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.popupMessageService.messageSignal.emit(PopupMessages.DonateToCenterSuccessMessage);
          this.form.reset();
        }
      })
  }
}
