import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AppSetting } from "src/app/backend/app-setting";
import { HttpService } from "src/app/backend/http.service";
import { LoginRequest } from "src/app/models/auths/login-request";
import { LoginResponse } from "src/app/models/auths/login-response";
import { ChangeUsernameAndPasswordModel } from "src/app/models/profiles/change-username-password-model";
import { CreateUserModel } from "src/app/models/profiles/create-user-model";
import { CreateUserForm } from "src/app/modules/user/forms/create-user-form";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  form: CreateUserForm = new CreateUserForm();

  constructor(private httpService: HttpService) {}

  public register(model: CreateUserModel): Observable<string> {
    return this.httpService
      .post<string, CreateUserModel>("auth/register", model)
      .pipe(map((value) => value.result));
  }

  public signIn(body: LoginRequest): Observable<LoginResponse> {
    return this.httpService
      .post<LoginResponse, LoginRequest>("auth/portal_login", body)
      .pipe(
        map((value) => value.result),
        tap((value) => this.storageTokenOnSuccess(value.role, value.token)),
      );
  }

  public signOut(): void {
    localStorage.removeItem(AppSetting.tokenKey);
    localStorage.removeItem(AppSetting.roleKey);
  }

  public changeUsernameAndPassword(model: ChangeUsernameAndPasswordModel) {
    return this.httpService.post<any, ChangeUsernameAndPasswordModel>(
      "auth/edit_login",
      model,
    );
  }

  public forcedSignOut(uuId: string): Observable<any> {
    return this.httpService.post<any, { uuid: string }>("auth/force_logout", {
      uuid: uuId,
    });
  }

  private storageTokenOnSuccess(role: string, token: string): void {
    localStorage.setItem(AppSetting.tokenKey, token);
    localStorage.setItem(AppSetting.roleKey, role);
    var decoded: any = jwtDecode(token);
    localStorage.setItem(
      "OrgCode",
      decoded[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"
      ],
    );
  }

  public isAuthenticated(): boolean {
    return localStorage.getItem(AppSetting.tokenKey) != null;
  }
}
