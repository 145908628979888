import { Component, OnInit } from "@angular/core";
import { Center, CenterLink } from "src/app/models/centers/center";
import { CenterService } from "src/app/services/center/center.service";
import { finalize } from "rxjs/operators";
import { UpdateCenterLinkModel } from "src/app/models/centers/update-center-link-model";

@Component({
  selector: "app-center-linker-volunteer",
  templateUrl: "./center-linker-volunteer.component.html",
  styleUrls: ["./center-linker-volunteer.component.scss"],
})
export class CenterLinkerVolunteerComponent implements OnInit {
  index: number = 0;
  centers: CenterLink[] = [];
  loading: boolean = false;

  constructor(private centerService: CenterService) {}

  ngOnInit(): void {
    this.fetch();
  }
  fetch(): void {
    this.loading = true;
    this.centerService
      .centerLinkVolunteer()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.centers = value;
        },
      });
  }
  hasCenter(center: Center): boolean {
    var selected = this.selectedCenter;
    if (selected == null) return false;
    //console.log(center.name,selected.canFindCenters,selected.canFindCenters.find(c=>c.uuId==center.uuId));
    return selected.canFindCenters.find((c) => c.uuId == center.uuId) != null;
  }
  update(center: Center): void {
    var selected = this.selectedCenter;
    if (selected != null) {
      var findCenter = selected.canFindCenters.find(
        (c) => c.uuId == center.uuId,
      );
      if (findCenter == null) {
        selected.canFindCenters.push(center);
      } else {
        selected.canFindCenters.splice(
          selected.canFindCenters.indexOf(findCenter),
          1,
        );
      }

      this.centerService
        .setCenterLinkVolunteer(
          this.centers[this.index].getUpdateCenterLinkModel(),
        )
        .subscribe({
          next: (value) => {
            this.fetch();
          },
        });
    }
  }
  get selectedCenter(): Center | null {
    return this.centers.length > 0 ? this.centers[this.index].center : null;
  }
}
