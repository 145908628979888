<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>通告</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>通告概覽</p>
    </div>

    <p class="page-title mt-8" translate>通告概覽</p>

    <div class="container mt-5">
        <div class="container-body">
            <div class="inline-flex-row align-items-center">
                <app-search-input class="flex-1" [size]="'xl'" [placeholder]="'請輸入紀錄編號 或 通告標題'" [action]="searchFilterAction"></app-search-input>

                <button class="icon-button orange" (click)="fetch()">
                    <i class="material-icons-round mb-1">refresh</i>
                    <p translate>重新整理</p>
                </button>

                <button class="icon-button orange ml-5" routerLink="/main/announcement/create">
                    <i class="material-icons-round mb-1">note_add</i>
                    <p translate>創建通告</p>
                </button>
            </div>

            <div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
                <app-center-filter [action]="centerFilterAction"></app-center-filter>
                <app-announcement-date-range-filter class="ml-4" [action]="dateFilterAction"></app-announcement-date-range-filter>
                <app-announcement-status-filter class="ml-4" [action]="statusFilterAction"></app-announcement-status-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>

            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>紀錄編號</p>
                        <div class="inline-flex-column" (click)="filters.orderById = !filters.orderById; filters.orderByAnnounceDate = null; fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.orderById}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.orderById}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
                        <p translate>發布日期</p>
                        <div class="inline-flex-column" (click)="filters.orderByAnnounceDate = !filters.orderByAnnounceDate; filters.orderById = null; fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.orderByAnnounceDate}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.orderByAnnounceDate}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p matTooltip="{{ '參與中心' | translate }}" [matTooltipPosition]="'above'" translate>參與中心</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 13.5rem;flex-grow: 27;">
                        <p translate>中心通告標題</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>創建職員</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
                        <p translate>狀態</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 10rem;flex-grow: 20;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let announcement of announcements">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [(ngModel)]="announcement.selected" (change)="cacheSelectedAnnouncement(announcement)">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <img class="pin" *ngIf="announcement.isPinned" src="/v2/assets/pin.png" alt="pin">
                        <p>{{ announcement.id }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
                        <p>{{ announcement.announceDate | date: 'yyyy/MM/dd' }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ announcement.involvedCenters }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 13.5rem;flex-grow: 27;">
                        <p [matTooltip]="announcement.title" matTooltipPosition="above">{{ announcement.title }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p [matTooltip]="announcement.createdBy" matTooltipPosition="above">{{ announcement.createdBy }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
                        <ng-container *ngIf="announcement.isActive">
                            <div class="status orange announcement-status mr-4" *ngIf="announcement.announceDate > now">
                                <p translate>未發布</p>
                            </div>
                            <div class="status green announcement-status mr-4" *ngIf="announcement.announceDate < now && announcement.expirationDate > now">
                                <p translate>已發布</p>
                            </div>
                            <div class="status grey announcement-status mr-4" *ngIf="announcement.expirationDate < now">
                                <p translate>已完結</p>
                            </div>
                        </ng-container>
                        <div class="status grey announcement-status mr-4" *ngIf="announcement.isActive == false">
                            <p translate>已終止</p>
                        </div>
                        <div class="status blue announcement-status" *ngIf="announcement.isEdited">
                            <p translate>已修改 </p>
                        </div>
                        <p></p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 10rem;flex-grow: 20;">
                        <p class="flex-0 click-text-lightblue mr-8" [routerLink]="['/main/announcement/overview', announcement.uuId]" translate>檢視</p>
                        <p class="flex-0 click-text-lightblue mr-8" (click)="duplicateAnnouncement(announcement)" translate>再次創建</p>
                        <p class="flex-0 click-text-lightblue mr-8" (click)="deleteAnnouncement(announcement.uuId)" translate>移除</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="announcements.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteAnnouncementList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>