import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DonationPageComponent } from "./pages/donation-page/donation-page.component";
import { RouterModule, Routes } from "@angular/router";
import { DonationUserComponent } from "./components/donation-user/donation-user.component";
import { DonationCenterComponent } from "./components/donation-center/donation-center.component";
import { DonationOverviewComponent } from "./components/donation-overview/donation-overview.component";
import { SelectDonorPageComponent } from "./pages/select-donor-page/select-donor-page.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { ShareModule } from "../share/share.module";
import { SelectDoneesPageComponent } from "./pages/select-donees-page/select-donees-page.component";

const routes: Routes = [
  { path: "user/select-donor", component: SelectDonorPageComponent },
  { path: "center/select-donees", component: SelectDoneesPageComponent },
  {
    path: "",
    component: DonationPageComponent,
    children: [
      {
        path: "user",
        component: DonationUserComponent,
        data: { animationState: "user" },
      },
      {
        path: "center",
        component: DonationCenterComponent,
        data: { animationState: "center" },
      },
      {
        path: "overview",
        component: DonationOverviewComponent,
        data: { animationState: "overview" },
      },
      { path: "", redirectTo: "user", pathMatch: "full" },
      { path: "**", redirectTo: "user" },
    ],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  declarations: [
    DonationPageComponent,
    DonationUserComponent,
    DonationCenterComponent,
    DonationOverviewComponent,
    SelectDonorPageComponent,
    SelectDoneesPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    ShareModule,
  ],
})
export class DonationModule {}
