import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSetting } from './backend/app-setting';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { ErrorHandleInterceptor } from './interceptors/error-handle.interceptor';
import { MainModule } from './modules/main/main.module';
import { ShareModule } from './modules/share/share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SystemModule } from './modules/system/system.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserModule } from './modules/user/user.module';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';

import zh from '@angular/common/locales/zh-Hant-HK';
import { AnnouncementModule } from './modules/announcement/announcement.module';
import { StaffModule } from './modules/staff/staff.module';
import { DonationModule } from './modules/donation/donation.module';
import { RedeemModule } from './modules/redeem/redeem.module';
import { RequestModule } from './modules/request/request.module';
import { EventModule } from './modules/event/event.module';
import { ActivityModule } from './modules/activity/activity.module';
import { TrashModule } from './modules/trash/trash.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { OverviewModule } from './modules/overview/overview.module';

registerLocaleData(zh);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/v2/locales/", ".json");
}

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    UserModule,
    AnnouncementModule,
    MainModule,
    ShareModule,
    SystemModule,
    StaffModule,
    RedeemModule,
    DonationModule,
    ActivityModule,
    RequestModule,
    EventModule,
    TrashModule,
    AnalyticsModule,
    OverviewModule,
    AngularFireModule.initializeApp(AppSetting.firebaseConfig),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'zh-Hant-HK',
      useDefaultLang: true,
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] }
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'zh' },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandleInterceptor, multi: true },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

