import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShareModule } from "../share/share.module";
import { RequestModule } from "../request/request.module";
import { EventModule } from "../event/event.module";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivityOverviewPageComponent } from "./pages/activity-overview-page/activity-overview-page.component";
import { RequestOverviewComponent } from "../request/components/request-overview/request-overview.component";
import { EventOverviewComponent } from "../event/components/event-overview/event-overview.component";
import { ActivityCreatePageComponent } from "./pages/activity-create-page/activity-create-page.component";
import { RequestCreateComponent } from "../request/components/request-create/request-create.component";
import { EventCreateComponent } from "../event/components/event-create/event-create.component";
import { SelectRequesterPageComponent } from "../request/pages/select-requester-page/select-requester-page.component";
import { SelectVolunteersPageComponent } from "../request/pages/select-volunteers-page/select-volunteers-page.component";
import { SelectEventParticipantsPageComponent } from "../event/pages/select-event-participants-page/select-event-participants-page.component";
import { RequestDetailPageComponent } from "../request/pages/request-detail-page/request-detail-page.component";
import { EventDetailPageComponent } from "../event/pages/event-detail-page/event-detail-page.component";
import { CreateEventRecordPageComponent } from "../event/pages/create-event-record-page/create-event-record-page.component";
import { SelectEventRecordParticipantsPageComponent } from "../event/pages/select-event-record-participants-page/select-event-record-participants-page.component";
import { RequestRecordComponent } from "./pages/request-record/request-record.component";

const routes: Routes = [
  {
    path: "create/request/select-requester",
    component: SelectRequesterPageComponent,
  },
  {
    path: "create/request/select-volunteers",
    component: SelectVolunteersPageComponent,
  },
  {
    path: "create/event/select-participants",
    component: SelectEventParticipantsPageComponent,
  },
  {
    path: "overview/request/:id/select-volunteers",
    component: SelectVolunteersPageComponent,
  },
  { path: "overview/request/:id", component: RequestDetailPageComponent },
  { path: "overview/event/:id", component: EventDetailPageComponent },
  {
    path: "overview/event/:id/select-participants",
    component: SelectEventParticipantsPageComponent,
  },
  {
    path: "overview",
    component: ActivityOverviewPageComponent,
    children: [
      {
        path: "request",
        component: RequestOverviewComponent,
        data: { animationState: "request" },
      },
      {
        path: "event",
        component: EventOverviewComponent,
        data: { animationState: "event" },
      },
      { path: "", redirectTo: "request", pathMatch: "full" },
      { path: "**", redirectTo: "request" },
    ],
  },
  {
    path: "create",
    component: ActivityCreatePageComponent,
    children: [
      {
        path: "request",
        component: RequestCreateComponent,
        data: { animationState: "request" },
      },
      {
        path: "event",
        component: EventCreateComponent,
        data: { animationState: "event" },
      },
      { path: "", redirectTo: "request", pathMatch: "full" },
      { path: "**", redirectTo: "request" },
    ],
  },
  { path: "event-record", component: CreateEventRecordPageComponent },
  { path: "request-record", component: RequestRecordComponent },
  {
    path: "event-record/select-participants",
    component: SelectEventRecordParticipantsPageComponent,
  },
  { path: "", redirectTo: "overview", pathMatch: "full" },
  { path: "**", redirectTo: "overview" },
];

@NgModule({
  declarations: [
    ActivityOverviewPageComponent,
    ActivityCreatePageComponent,
    RequestRecordComponent,
  ],
  imports: [
    CommonModule,
    RequestModule,
    ShareModule,
    EventModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDatepickerModule,
  ],
})
export class ActivityModule {}
