import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, PartialObserver } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { IOrganizationTimeConfig, DefaultOrganizationTimeConfig, OrganizationConfig } from 'src/app/models/organizations/organization-config';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';

@Component({
  selector: 'app-time-setting',
  templateUrl: './time-setting.component.html',
  styleUrls: ['./time-setting.component.scss']
})
export class TimeSettingComponent implements OnInit {

  public days: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  public trashDays: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45];
  public hours: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  public minutes: number[] = [0, 15, 30, 45];

  public disableIn:boolean=false;
  public disableOut:boolean=false;
  config: IOrganizationTimeConfig = DefaultOrganizationTimeConfig;

  public form: FormGroup = new FormGroup({
    inviteLimitDay: new FormControl(1),
    inviteLimitHour: new FormControl(1),
    inviteLimitMinute: new FormControl(0),

    requestNotificationDay: new FormControl(0),
    requestNotificationHour: new FormControl(0),
    requestNotificationMinute: new FormControl(0),

    requestNotificationStaffDay: new FormControl(0),
    requestNotificationStaffHour: new FormControl(0),
    requestNotificationStaffMinute: new FormControl(0),

    eventNotificationDay: new FormControl(0),
    eventNotificationHour: new FormControl(0),

    checkinLimit: new FormControl(0),
    checkoutLimit: new FormControl(0),
    startLimit: new FormControl(1),
    endLimit: new FormControl(1),
    trashClearDay: new FormControl(1),
    IsFullDayEnd:new FormControl(false),
    IsFullDayStart:new FormControl(false)
  });

  constructor(
    private organizationService: OrganizationService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.fetchResource().subscribe(this.fetchResourceObserver);
  }

  save(): void {
    this.config.MinimunCreateTimeFromNow = this.form.controls['inviteLimitDay'].value * 1440 + this.form.controls['inviteLimitHour'].value * 60 + this.form.controls['inviteLimitMinute'].value;
   
    var a=this.form.controls["requestNotificationDay"].value* 1440 + this.form.controls['requestNotificationHour'].value * 60 + this.form.controls['requestNotificationMinute'].value
    if(a!=0){
      this.config.NoMatchNotification=a;
    }else{
      this.config.NoMatchNotification=null;
    }
    var b=this.form.controls["eventNotificationDay"].value* 1440 + this.form.controls['eventNotificationHour'].value * 60 
    this.config.EventNotification=b;


    var c=this.form.controls["requestNotificationStaffDay"].value* 1440 + this.form.controls['requestNotificationStaffHour'].value * 60 + this.form.controls['requestNotificationStaffMinute'].value
    this.config.RequestNotificationStaff=c;

    this.config.CheckInStartTime = this.form.controls['checkinLimit'].value;
    this.config.CheckInEndTime = this.form.controls['startLimit'].value;
    this.config.CheckOutStartTime = this.form.controls['checkoutLimit'].value;
    this.config.CheckOutEndTime = this.form.controls['endLimit'].value;
    this.config.TrashClearTime = this.form.controls['trashClearDay'].value;

    this.config.IsFullDayStart=this.form.controls['IsFullDayStart'].value?true:false;
    this.config.IsFullDayEnd=this.form.controls['IsFullDayEnd'].value?true:false;

    var json:any={};
    json=JSON.parse(JSON.stringify(this.config));
    if(json["NoMatchNotification"]==null){
      delete(json["NoMatchNotification"]);
    }
    this.loadingService.startLoading();
    this.organizationService.setTimeConfig(json)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          localStorage.setItem('EventNotification',this.config.EventNotification+"");
          localStorage.setItem('NoMatchNotification',this.config.NoMatchNotification+"");
          this.popupMessageService.messageSignal.emit(PopupMessages.EditTimeConfigSuccessMessage);
        }
      });
  }
  disabledCheckInLimit():void{
    this.disableIn=this.form.controls['IsFullDayStart'].value?false:true;
    console.log(" this.disableIn");
    if(this.disableIn)
     this.form.controls["checkinLimit"].disable();
     else
     this.form.controls["checkinLimit"].enable();
  }
  disabledCheckOutLimit():void{
    this.disableOut=this.form.controls['IsFullDayEnd'].value?false:true;
    console.log(" this.disableOut",this.disableOut);
    if(this.disableOut)
     this.form.controls["checkoutLimit"].disable();
     else
     this.form.controls["checkoutLimit"].enable();
  }
  fetchResource(): Observable<OrganizationConfig> {
    this.loadingService.startLoading();
    return this.organizationService.getOrganizationConfig()
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }

  fetchResourceObserver: PartialObserver<OrganizationConfig> = {
    next: value => {
      this.config = value == null || value.config == null ? DefaultOrganizationTimeConfig : value.config;
      this.form.controls['inviteLimitDay'].setValue(Math.floor(this.config.MinimunCreateTimeFromNow / 1440));
      this.form.controls['inviteLimitHour'].setValue(Math.floor(this.config.MinimunCreateTimeFromNow% 1440 / 60));
      this.form.controls['inviteLimitMinute'].setValue(this.config.MinimunCreateTimeFromNow % 60);
      this.form.controls['checkinLimit'].setValue(typeof (this.config.CheckInStartTime) == "string" ? parseInt(this.config.CheckInStartTime) : this.config.CheckInStartTime);
      this.form.controls['checkoutLimit'].setValue(typeof (this.config.CheckOutStartTime) == "string" ? parseInt(this.config.CheckOutStartTime) : this.config.CheckOutStartTime);
      this.form.controls['startLimit'].setValue(typeof (this.config.CheckInEndTime) == "string" ? parseInt(this.config.CheckInEndTime) : this.config.CheckInEndTime);
      this.form.controls['endLimit'].setValue(typeof (this.config.CheckOutEndTime) == "string" ? parseInt(this.config.CheckOutEndTime) : this.config.CheckOutEndTime);
      this.form.controls['trashClearDay'].setValue(this.config.TrashClearTime ? this.config.TrashClearTime : DefaultOrganizationTimeConfig.TrashClearTime);
      
      this.form.controls['IsFullDayStart'].setValue(this.config.IsFullDayStart);
      if(this.config.IsFullDayStart){
        this.form.controls['checkinLimit'].disable();
      }
      this.form.controls['IsFullDayEnd'].setValue(this.config.IsFullDayEnd);
      if(this.config.IsFullDayEnd){
        this.form.controls['checkoutLimit'].disable();
      }
      
   

      if(this.config.NoMatchNotification!=null){
        this.form.controls['requestNotificationDay'].setValue(Math.floor(this.config.NoMatchNotification / 1440));
        this.form.controls['requestNotificationHour'].setValue(Math.floor(this.config.NoMatchNotification% 1440 / 60));
        this.form.controls['requestNotificationMinute'].setValue(this.config.NoMatchNotification % 60);
      }
      if(this.config.EventNotification!=null){
        this.form.controls['eventNotificationDay'].setValue(Math.floor(this.config.EventNotification / 1440));
        this.form.controls['eventNotificationHour'].setValue(Math.floor(this.config.EventNotification% 1440 / 60));
      }
      if(this.config.RequestNotificationStaff!=null){
        this.form.controls['requestNotificationStaffDay'].setValue(Math.floor(this.config.RequestNotificationStaff / 1440));
     
        this.form.controls['requestNotificationStaffHour'].setValue(Math.floor(this.config.RequestNotificationStaff% 1440 / 60));
        this.form.controls['requestNotificationStaffMinute'].setValue(this.config.RequestNotificationStaff % 60);
      }
    }
  }

}
