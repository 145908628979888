import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ArcElement, Chart, ChartData, ChartOptions, DoughnutController, DoughnutControllerChartOptions, Legend } from 'chart.js';
import { ServiceRatio } from 'src/app/models/analytics/service-ratio';
import { Center } from 'src/app/models/centers/center';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ExportService } from 'src/app/services/export/export.service';

@Component({
  selector: 'app-service-ratio-analysis',
  templateUrl: './service-ratio-analysis.component.html',
  styleUrls: ['./service-ratio-analysis.component.scss']
})
export class ServiceRatioAnalysisComponent implements AfterViewInit {

  serviceRatio!: ServiceRatio;
  StateType: string = "All";
  dataType: string = "Request";



  chart: Chart|null=null;
  @ViewChild('graph') graph!: ElementRef;
  @ViewChild('graphLabel') graphLabel!:ElementRef;
  context!: CanvasRenderingContext2D;

  @Input() form!: FormControl;
  @Input() centers!: Center[];

  centerUUId?: string;
  
  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  cellGroupsUUId?: string;
  cellGroup: FormControl = new FormControl(null);
  


  constructor(
    private analyticsService: AnalyticsService,
    private renderer: Renderer2,
    private exportService:ExportService
  ) { }

  ngAfterViewInit(): void {
    Chart.register(DoughnutController, ArcElement, Legend);

    this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext('2d') as CanvasRenderingContext2D;
    
    this.fetch();

    this.form.valueChanges.subscribe(value => {
      this.centerUUId=value;
      this.fetch();
    });
  }
  dateformat(data:Date){
    return data.getFullYear()+"-"+(data.getMonth()+1<10?"0":"")+(data.getMonth()+1)+"-"+(data.getDate()<10?"0":"")+(data.getDate());
  }
  fetch(): void {
    var start=!this.start.value?undefined:this.dateformat(this.start.value);
    var end=!this.end.value?undefined:this.dateformat(this.end.value);
    this.analyticsService.getServiceRatio(this.centerUUId,this.dataType,start,end,this.cellGroup.value)
      .subscribe(value => {
      //   this.serviceRatio={ all:[],
      //   finished:[],
      //   finishedUser:[],
      //   allUser:[]}
      //   for(var i=0;i<10;i++){
      //     value.all.forEach(item=>this.serviceRatio.all.push(item));
      //     value.finished.forEach(item=>this.serviceRatio.finished.push(item));
      //     value.finishedUser.forEach(item=>this.serviceRatio.finishedUser.push(item));
      //     value.allUser.forEach(item=>this.serviceRatio.allUser.push(item));
      //   }
        this.serviceRatio = value;
        this.initGraph()
      });
  }

  UserNumber(index:string):number{
    var n:number|undefined=0;
    this.StateType=="All"?n=this.serviceRatio.allUser.find(item=>item.index==index)?.value:n=this.serviceRatio.finishedUser.find(item=>item.index==index)?.value;
    return n?n:0;
  }

  initGraph(): void {
    if (this.chart)
      this.chart.destroy();
    this.chart = new Chart(this.context, {
      type: 'doughnut',
      data: this.getData(),
      options: chartOptions
    });
    this.renderer.setStyle( this.graphLabel.nativeElement,'top',(this.chart.chartArea.top+(this.chart.chartArea.height-this.graphLabel.nativeElement.offsetHeight)/2)+'px');
  }
  public getData(): ChartData {
    var colorList:string[]=[];
    var data:number[]=this.StateType == "All" ? this.serviceRatio.all.map(service => service.value) : this.serviceRatio.finished.map(service => service.value);
    for(var i=0;i<data.length;i++){
      colorList.push(this.color(i));
    }
    return {
      datasets: [{
        data: data,
        backgroundColor: colorList,
        borderColor: colorList,
      }],
      labels: this.StateType == "All" ? this.serviceRatio.all.map(service => service.index) : this.serviceRatio.finished.map(service => service.index),
    }
  }
  color(index:number):string{
    var colorList=[
      'rgba(255, 166, 32, 1)', 'rgba(249, 133, 67, 1)', 'rgba(160, 210, 255, 1)', 'rgba(59, 161, 255, 1)', 'rgba(47, 84, 235, 1)',
      'rgba(4, 105, 255, 1)', 'rgba(4, 240, 255, 1)', 'rgba(179, 245, 245, 1)', 'rgba(54, 203, 203, 1)', 'rgba(8, 151, 156, 1)',
      'rgba(107, 99, 151, 1)', 'rgba(188, 17, 249, 1)', 'rgba(214, 184, 255, 1)', 'rgba(151, 95, 229, 1)', 'rgba(83, 29, 171, 1)',
      'rgba(212, 56, 13, 1)', 'rgba(255, 0, 168, 1)', 'rgba(255, 203, 212, 1)', 'rgba(242, 99, 123, 1)', 'rgba(245, 34, 45, 1)',
      'rgba(255, 221, 102, 1)', 'rgba(212, 216, 16, 1)', 'rgba(255, 240, 181, 1)', 'rgba(251, 212, 55, 1)', 'rgba(212, 177, 6, 1)',
      'rgba(89, 232, 1, 1)', 'rgba(173, 244, 195, 1)', 'rgba(78, 203, 116, 1)', 'rgba(56, 158, 13, 1)',
      'rgba(220, 5, 5, 1)', 'rgba(195, 226, 226, 1)', 'rgba(54, 203, 203, 1)', 'rgba(166, 192, 192, 1)',
    ];
    return colorList[index%colorList.length];
  }
  get displayList(): { index: string, value: number }[] {
    return this.serviceRatio ? (this.StateType == "All" ? this.serviceRatio.all : this.serviceRatio.finished) : [];
  }
  
  get total(): number {
    if (!this.serviceRatio)
      return 1;
    var total = 0;
    if (this.StateType == "All")
      this.serviceRatio.all.forEach(service => total += service.value);
    else
      this.serviceRatio.finished.forEach(service => total += service.value);
    return total;
  }


  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null)
      return null;
    return (<Date>this.start.value).toLocaleDateString() + " - " + (<Date>this.end.value).toLocaleDateString();
  }
  // get displayListPage():number[]{
  //   this.pageIndex;
  //   var n:number[]=[];

  //   if( this.pageIndex+5>this.MaxPage){
  //     for(var i=Math.max(this.MaxPage-5,0);i<this.MaxPage;i++){
  //       n.push(i+1);
  //     }
  //   }else{
  //     if( this.pageIndex==0){
  //       for(var i=this.pageIndex;i<Math.min(this.MaxPage,this.pageIndex+5);i++){
  //         n.push(i+1);
  //       }
  //     }else{
  //       for(var i=this.pageIndex-1;i<Math.min(this.MaxPage,this.pageIndex+4);i++){
  //         n.push(i+1);
  //       }
  //     }
  //   }

  //   return n;
  // }
  // get MaxPage():number{
  //   return Math.ceil(this.displayList.length/6);
  // }
  // prevPage():void{
  //   this.pageIndex--;
  //   this.pageIndex=Math.max(this.pageIndex,0);
  // }
  // nextPage():void{
  //   this.pageIndex++;
  //   this.pageIndex=Math.min(this.pageIndex,this.MaxPage-1);
  // }
  // jumpPage(i:number):void{
  //   this.pageIndex=i-1;
  // }
  export():void{
    var cellName="";
    if(this.cellGroup.value){
      this.centers.forEach(center=>center.cellGroups.forEach(cellGroup=>cellGroup.uuId==this.cellGroup.value?cellName=cellGroup.name:null));
    }
    this.exportService.exportServiceRatioData(this.serviceRatio,this.dataType,this.StateType,cellName,this.start.value,this.end.value);
  }
}

const chartOptions: any = {
  responsive: true,
  cutout: '80%'
};

