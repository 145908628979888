import { Component, Input, OnInit } from '@angular/core';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-request-status-filter',
  templateUrl: './request-status-filter.component.html',
  styleUrls: ['./request-status-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class RequestStatusFilterComponent implements OnInit {

  @Input() action: (statuses: string[], urgent: boolean) => void = () => { }
  statuses = [
    { name: "等候配對", selected: false, value: 'Pending' },
    { name: "未開始", selected: false, value: 'Ready' },
    { name: "進行中", selected: false, value: 'Started' },
    { name: "已完結", selected: false, value: 'Finished' },
    { name: "已終止", selected: false, value: 'Cancelled' }
  ];
  public urgent: boolean = false;
  display: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  reset(): void {
    this.statuses.forEach(status => status.selected = false);
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.statuses.filter(status => status.selected).map(status => status.value), this.urgent);
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter(status => status.selected).length;
  }

}
