import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementOverviewPageComponent } from './pages/announcement-overview-page/announcement-overview-page.component';
import { AnnouncementCreatePageComponent } from './pages/announcement-create-page/announcement-create-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ShareModule } from '../share/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectAnnouncementUsersPageComponent } from './pages/select-announcement-users-page/select-announcement-users-page.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnnouncementCreateUsersPopupComponent } from './components/announcement-create-users-popup/announcement-create-users-popup.component';
import { AnnouncementDetailPageComponent } from './pages/announcement-detail-page/announcement-detail-page.component';
import { AnnouncementDeactivationPopupComponent } from './components/announcement-deactivation-popup/announcement-deactivation-popup.component';

const routes: Routes = [
  { path: 'overview', component: AnnouncementOverviewPageComponent },
  { path: 'overview/:id', component: AnnouncementDetailPageComponent },
  { path: 'overview/:id/user', component: SelectAnnouncementUsersPageComponent },
  { path: 'create', component: AnnouncementCreatePageComponent },
  { path: 'create/user', component: SelectAnnouncementUsersPageComponent },
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: '**', redirectTo: 'overview' }
]

@NgModule({
  declarations: [AnnouncementOverviewPageComponent, AnnouncementCreatePageComponent, SelectAnnouncementUsersPageComponent, AnnouncementCreateUsersPopupComponent, AnnouncementDetailPageComponent, AnnouncementDeactivationPopupComponent],
  imports: [
    CommonModule,
    ShareModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ]
})
export class AnnouncementModule { }
