import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { profile } from "console";
import { forkJoin, Observable, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Health } from "src/app/models/healths/health";
import { Living } from "src/app/models/livings/living";
import { VolunteerChoice } from "src/app/models/requests/volunteer-choice";
import { LoadingService } from "src/app/services/loading/loading.service";
import { OrganizationService } from "src/app/services/organization/organization.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { RequestService } from "src/app/services/request/request.service";
import { VolunteerService } from "src/app/services/volunteer/volunteer.service";

@Component({
  selector: "app-select-volunteers-page",
  templateUrl: "./select-volunteers-page.component.html",
  styleUrls: ["./select-volunteers-page.component.scss"],
})
export class SelectVolunteersPageComponent implements OnInit {
  volunteers: VolunteerChoice[] = [];

  back_page: string = "";

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
    districts: [],
    livingConditions: [],
    conditions: [],
    healths: [],
    genders: [],
    matchedBefore: false,
    memberIdOrder: null,
    timeBalanceOrder: null,
    cellGroup: [],
    subDistricts: [],
  };

  constructor(
    private volunteerService: VolunteerService,
    private requestService: RequestService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private location: Location,
    private popupMessageService: PopupMessageService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((value) => {
      if (typeof value.back != "undefined") {
        this.back_page = value.back;

        this.fetchByRecord();
        return;
      } else if (value.id == null) {
        if (this.requestService.form.valid == false) {
          this.location.back();
          return;
        }

        this.fetchByForm();
        return;
      } else {
        this.fetchByRequest();
      }
    });
  }
  fetchByRecord(): void {
    this.loadingService.startLoading();
    this.profileService
      .getProfileList("?start=0&limit=100000")
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((result) => {
        this.volunteers = result.list
          .filter(
            (profile) =>
              profile.uuId != this.requestService.requestRecordForm.requester,
          )
          .map((profile) => VolunteerChoice.parse(profile));
        this.volunteers
          .filter((v) =>
            this.requestService.requestRecordForm.volunteers
              .map((vs) => vs.userUUId)
              .includes(v.userUUId),
          )
          .forEach((v) => (v.selected = true));
      });
  }
  fetchByForm(): void {
    this.loadingService.startLoading();
    this.volunteerService
      .searchVolunteers({
        regions: [],
        districts: [],
        service: this.requestService.form.formGroup.controls["service"].value,
        startTime: (
          this.requestService.form.formGroup.controls["start"].value as Date
        ).toJSON(),
        endTime:
          this.requestService.form.formGroup.controls["noEndTime"].value == true
            ? null
            : (
                this.requestService.form.formGroup.controls["end"].value as Date
              ).toJSON(),
        requesterUUId: this.requestService.form.requester,
        addressUUId: this.requestService.form.formGroup.controls["venue"].value,
        numberOfVolunteer:
          this.requestService.form.formGroup.controls["numberOfVolunteer"]
            .value,
      })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((result) => {
        this.volunteers = result;
        this.volunteers
          .filter((v) =>
            this.requestService.form.volunteers
              .map((vs) => vs.userUUId)
              .includes(v.userUUId),
          )
          .forEach((v) => (v.selected = true));
      });
  }

  fetchByRequest(): void {
    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      forkJoin({
        volunteers: this.volunteerService.searchVolunteersByRequest(value.id, {
          regions: [],
          districts: [],
        }),
        request: this.requestService.getRequestByUUId(value.id),
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((result) => {
          this.volunteers = result.volunteers;
          this.volunteers
            .filter((v) =>
              result.request.volunteers
                .map((volunteer) => volunteer.userUUId)
                .includes(v.userUUId),
            )
            .forEach((v) => {
              v.selected = true;
              v.disabled = true;
            });
        });
    });
  }

  get filteredVolunteers(): VolunteerChoice[] {
    let results = this.volunteers.filter(
      (volunteer) =>
        (this.filters.searchInput == null ||
          volunteer.chineseName.includes(this.filters.searchInput) ||
          volunteer.memberId.includes(this.filters.searchInput)) &&
        (this.filters.matchedBefore == false || volunteer.isMatched == true) &&
        (this.filters.centers.length == 0 ||
          this.filters.centers
            .map((c: Center) => c.uuId)
            .includes(volunteer.center.uuId)) &&
        (this.filters.districts.length == 0 ||
          this.filters.districts.includes(volunteer.defaultDistrict.name)) &&
        (this.filters.subDistricts.length == 0 ||
          this.filters.subDistricts.find(
            (sd: string) => volunteer.defaultAddress.indexOf(sd) > -1,
          )) &&
        (this.filters.genders.length == 0 ||
          this.filters.genders.includes(volunteer.gender)) &&
        (this.filters.cellGroup.length == 0 ||
          this.filters.cellGroup.find(
            (cellgroup: CellGroup) =>
              typeof volunteer.cellGroups != "undefined" &&
              volunteer.cellGroups.find(
                (vcg: CellGroup) => vcg.uuId == cellgroup.uuId,
              ),
          )),
    );

    if (this.filters.memberIdOrder !== null)
      if (this.filters.memberIdOrder === true)
        return results.sort((a, b) =>
          a.memberId > b.memberId ? 1 : a.memberId < b.memberId ? -1 : 0,
        );
      else
        return results.sort((b, a) =>
          a.memberId > b.memberId ? 1 : a.memberId < b.memberId ? -1 : 0,
        );

    if (this.filters.timeBalanceOrder !== null)
      if (this.filters.timeBalanceOrder === true)
        return results.sort((a, b) =>
          a.timeBalance > b.timeBalance
            ? 1
            : a.timeBalance < b.timeBalance
              ? -1
              : 0,
        );
      else
        return results.sort((b, a) =>
          a.timeBalance > b.timeBalance
            ? 1
            : a.timeBalance < b.timeBalance
              ? -1
              : 0,
        );

    return results;
  }
  submit(): void {
    this.route.params.subscribe((value) => {
      if (value.id == null && this.back_page == "") {
        this.requestService.form.volunteers = this.selectedVolunteers;
        this.router.navigate(["/main/activity/create/request"]);
        return;
      } else if (value.id == null && this.back_page == "request-record") {
        this.requestService.requestRecordForm.volunteers =
          this.selectedVolunteers;
        this.router.navigate(["/main/activity/request-record"]);
        return;
      }

      this.loadingService.startLoading();
      this.organizationService
        .getOrganizationSetting()
        .pipe(
          switchMap((v) => {
            if (v.DirectConfirm == true) {
              return this.requestService.inviteVolunteers(value.id, {
                volunteersUUId: this.selectedVolunteers
                  .filter((v) => v.disabled == false)
                  .map((v) => v.userUUId),
              });
            } else {
              return this.requestService.askForInterest(value.id, {
                volunteersUUId: this.selectedVolunteers
                  .filter((v) => v.disabled == false)
                  .map((v) => v.userUUId),
              });
            }
          }),
        )
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.InviteVolunteerSuccessMessage(
              new Observable<any>((subscriber) => {
                this.router.navigate([
                  "/main/activity/overview/request",
                  value.id,
                ]);
              }),
            ),
          );
        });
    });
  }

  get displayedVolunteers(): VolunteerChoice[] {
    return this.filteredVolunteers.splice(
      (this.filters.page - 1) * this.filters.itemsPerPage,
      this.filters.page * this.filters.itemsPerPage,
    );
  }

  get pages(): number {
    return Math.ceil(
      this.filteredVolunteers.length / this.filters.itemsPerPage,
    );
  }

  get isSelectedAll(): boolean {
    return this.filteredVolunteers.every((v) => v.selected);
  }

  get isSelectedPage(): boolean {
    return this.displayedVolunteers.every((v) => v.selected);
  }

  get selectedVolunteers(): VolunteerChoice[] {
    return this.volunteers.filter((v) => v.selected);
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.filteredVolunteers.forEach((v) => (v.selected = false));
    else this.filteredVolunteers.forEach((v) => (v.selected = true));
  }

  selectPage(): void {
    if (this.isSelectedPage)
      this.displayedVolunteers.forEach((v) => (v.selected = false));
    else this.displayedVolunteers.forEach((v) => (v.selected = true));
  }

  back(): void {
    this.location.back();
  }

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
  }).bind(this);

  searchFilterAction: (input: string) => void = (input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
  };

  addressFilterAction: (addresses: string[], subDistricts: string[]) => void = (
    addresses: string[],
    subDistricts: string[],
  ) => {
    console.log("addressFilterAction", addresses, subDistricts);
    this.filters.districts = addresses;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
  };

  conditionFilterAction = ((
    genders: string[] | any,
    healths: Health[] | any,
    livings: Living[] | any,
  ) => {
    this.filters.genders = genders;
    this.filters.healths = healths;
    this.filters.conditions = livings;
    this.filters.page = 1;
  }).bind(this);

  cellGroupFilterAction = ((center: Center[]) => {
    this.filters.cellGroup = [];
    center.forEach((c) =>
      c.cellGroups.forEach((cg) =>
        cg.selected ? this.filters.cellGroup.push(cg) : null,
      ),
    );
    this.filters.page = 1;
  }).bind(this);
}
