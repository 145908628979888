import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-activity-template-page',
  templateUrl: './activity-template-page.component.html',
  styleUrls: ['./activity-template-page.component.scss'],
  animations: [PortalAnimation.ActivityPageSlide]
})
export class ActivityTemplatePageComponent implements OnInit {


  tab: string = "request";

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url == "/main/system/activity-template/event") {
        this.tab = "event";
      }
      else {
        this.tab = "request";
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }

}
