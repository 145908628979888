<div class="box">
    <app-service-type-setting></app-service-type-setting>

    <div style="margin-top: 2.8125rem;">
        <app-service-setting></app-service-setting>
    </div>

    <div style="margin-top: 2.8125rem;">
        <app-health-setting></app-health-setting>
    </div>

    <div style="margin-top: 2.8125rem;">
        <app-living-setting></app-living-setting>
    </div>

    <div style="margin-top: 2.8125rem;">
        <app-transportation-setting></app-transportation-setting>
    </div>
</div>