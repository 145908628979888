import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopupMessages } from 'src/app/backend/popup-message';
import { UserTransaction } from 'src/app/models/transactions/user-transaction';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { RequestService } from 'src/app/services/request/request.service';

@Component({
  selector: 'app-user-request-transaction',
  templateUrl: './user-request-transaction.component.html',
  styleUrls: ['./user-request-transaction.component.scss']
})
export class UserRequestTransactionComponent implements OnInit {

  userId!: string;
  @Input() transactions: UserTransaction[] = [];
  @Input() reloadAction: () => void = () => { }

  filters: any = {
    page: 1,
    itemsPerPage: 10,
    idOrder: null,
    dateOrder: true
  };

  constructor(
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private requestService: RequestService
  ) { }

  ngOnInit(): void {
    this.route.parent?.params.subscribe(value => {
      this.userId = value.id;
    });
  }

  deleteRequest(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.reloadAction();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteRequestMessage(this.requestService.deleteRequests([uuId])));
  }

  deleteRequestList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.reloadAction();
      subscriber.unsubscribe();
    });
    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteRequestListMessage(this.requestService.deleteRequests(this.selectedTransactions.map(t => t.request.uuId))));
  }

  get pages(): number {
    return this.transactions ? Math.ceil(this.transactions.length / this.filters.itemsPerPage) : 0;
  }

  get selectedTransactions(): UserTransaction[] {
    return this.transactions.filter(transaction => transaction.selected);
  }

  get displayedTransactions(): UserTransaction[] {
    let result = this.transactions;

    if (this.filters.idOrder != null) {
      if (this.filters.idOrder == true)
        result = this.transactions.sort((a, b) => a.request.id > b.request.id ? 1 : a.request.id < b.request.id ? -1 : 0);
      else
        result = this.transactions.sort((b, a) => a.request.id > b.request.id ? 1 : a.request.id < b.request.id ? -1 : 0);
    }

    if (this.filters.dateOrder != null) {
      if (this.filters.dateOrder == true)
        result = this.transactions.sort((b, a) => a.request.checkOutTime > b.request.checkOutTime ? 1 : a.request.checkOutTime < b.request.checkOutTime ? -1 : 0);
      else
        result = this.transactions.sort((a, b) => a.request.checkOutTime > b.request.checkOutTime ? 1 : a.request.checkOutTime < b.request.checkOutTime ? -1 : 0);
    }

    return result.slice((this.filters.page - 1) * this.filters.itemsPerPage, this.filters.page * this.filters.itemsPerPage);
  }

  get isSelectedAll(): boolean {
    return this.displayedTransactions.every(transaction => transaction.selected == true);
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.displayedTransactions.forEach(transaction => transaction.selected = false);
    else
      this.displayedTransactions.forEach(transaction => transaction.selected = true);
  }
}
