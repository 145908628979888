import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Observable, PartialObserver } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import {
  IApplicationSetting,
  DefaultApplicationSetting,
} from "src/app/models/organizations/application-setting";
import { LoadingService } from "src/app/services/loading/loading.service";
import { OrganizationService } from "src/app/services/organization/organization.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-application-setting-page",
  templateUrl: "./application-setting-page.component.html",
  styleUrls: ["./application-setting-page.component.scss"],
})
export class ApplicationSettingPageComponent implements OnInit {
  setting: IApplicationSetting = DefaultApplicationSetting;

  public volunteerForm: FormGroup = new FormGroup({
    birthday: new FormControl("staff"),
    phone: new FormControl("staff"),
    address: new FormControl("staff"),
    health: new FormControl("staff"),
    contactPerson: new FormControl("staff"),
    education: new FormControl("staff"),
    phoneVisibility: new FormControl("staff"),
    list: new FormControl("staff"),
  });

  public requesterForm: FormGroup = new FormGroup({
    birthday: new FormControl("staff"),
    phone: new FormControl("staff"),
    address: new FormControl("staff"),
    health: new FormControl("staff"),
    contactPerson: new FormControl("staff"),
    education: new FormControl("staff"),
    addressDetail: new FormControl("staff"),
    phoneVisibility: new FormControl("staff"),
    contactPersonVisibility: new FormControl("staff"),
    requesterAgentPrivacyVisibility: new FormControl("staff"),
  });

  constructor(
    private organizationService: OrganizationService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.fetchResource().subscribe(this.fetchResourceObserver);
  }

  save(): void {
    this.setting.volunteerPrivacyBirthday =
      this.volunteerForm.controls["birthday"].value;
    this.setting.volunteerPrivacyPhone =
      this.volunteerForm.controls["phone"].value;
    this.setting.volunteerPrivacyAddress =
      this.volunteerForm.controls["address"].value;
    this.setting.volunteerPrivacyHealth =
      this.volunteerForm.controls["health"].value;
    this.setting.volunteerPrivacyContactPerson =
      this.volunteerForm.controls["contactPerson"].value;
    this.setting.volunteerPrivacyEducation =
      this.volunteerForm.controls["education"].value;
    this.setting.volunteerPrivacyPhoneVisibility =
      this.volunteerForm.controls["phoneVisibility"].value;
    this.setting.volunteerPrivacyList =
      this.volunteerForm.controls["list"].value;

    this.setting.requesterPrivacyBirthday =
      this.requesterForm.controls["birthday"].value;
    this.setting.requesterPrivacyPhone =
      this.requesterForm.controls["phone"].value;
    this.setting.requesterPrivacyAddress =
      this.requesterForm.controls["address"].value;
    this.setting.requesterPrivacyHealth =
      this.requesterForm.controls["health"].value;
    this.setting.requesterPrivacyContactPerson =
      this.requesterForm.controls["contactPerson"].value;
    this.setting.requesterPrivacyEducation =
      this.requesterForm.controls["education"].value;
    this.setting.requesterPrivacyAddressVisibility =
      this.requesterForm.controls["addressDetail"].value;
    this.setting.requesterPrivacyPhoneVisibility =
      this.requesterForm.controls["phoneVisibility"].value;
    this.setting.requesterPrivacyContactPersonVisibility =
      this.requesterForm.controls["contactPersonVisibility"].value;
    this.setting.requesterAgentPrivacyVisibility =
      this.requesterForm.controls["requesterAgentPrivacyVisibility"].value;

    this.loadingService.startLoading();
    this.organizationService
      .setApplicationSetting(this.setting)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.EditApplicationSettingSuccessMessage,
          );
        },
      });
  }

  fetchResource(): Observable<IApplicationSetting> {
    this.loadingService.startLoading();
    return this.organizationService
      .getApplicationSetting()
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }

  fetchResourceObserver: PartialObserver<IApplicationSetting> = {
    next: (value) => {
      this.setting = value == null ? DefaultApplicationSetting : value;
      this.volunteerForm.controls["birthday"].setValue(
        this.setting.volunteerPrivacyBirthday,
      );
      this.volunteerForm.controls["phone"].setValue(
        this.setting.volunteerPrivacyPhone,
      );
      this.volunteerForm.controls["address"].setValue(
        this.setting.volunteerPrivacyAddress,
      );
      this.volunteerForm.controls["health"].setValue(
        this.setting.volunteerPrivacyHealth,
      );
      this.volunteerForm.controls["contactPerson"].setValue(
        this.setting.volunteerPrivacyContactPerson,
      );
      this.volunteerForm.controls["education"].setValue(
        this.setting.volunteerPrivacyEducation,
      );
      this.volunteerForm.controls["phoneVisibility"].setValue(
        this.setting.volunteerPrivacyPhoneVisibility
          ? this.setting.volunteerPrivacyPhoneVisibility
          : DefaultApplicationSetting.volunteerPrivacyPhoneVisibility,
      );
      this.volunteerForm.controls["list"].setValue(
        this.setting.volunteerPrivacyList
          ? this.setting.volunteerPrivacyList
          : DefaultApplicationSetting.volunteerPrivacyList,
      );

      this.requesterForm.controls["birthday"].setValue(
        this.setting.requesterPrivacyBirthday,
      );
      this.requesterForm.controls["phone"].setValue(
        this.setting.requesterPrivacyPhone,
      );
      this.requesterForm.controls["address"].setValue(
        this.setting.requesterPrivacyAddress,
      );
      this.requesterForm.controls["health"].setValue(
        this.setting.requesterPrivacyHealth,
      );
      this.requesterForm.controls["contactPerson"].setValue(
        this.setting.requesterPrivacyContactPerson,
      );
      this.requesterForm.controls["education"].setValue(
        this.setting.requesterPrivacyEducation,
      );
      this.requesterForm.controls["addressDetail"].setValue(
        this.setting.requesterPrivacyAddressVisibility
          ? this.setting.requesterPrivacyAddressVisibility
          : DefaultApplicationSetting.requesterPrivacyAddressVisibility,
      );
      this.requesterForm.controls["phoneVisibility"].setValue(
        this.setting.requesterPrivacyPhoneVisibility
          ? this.setting.requesterPrivacyPhoneVisibility
          : DefaultApplicationSetting.requesterPrivacyPhoneVisibility,
      );
      this.requesterForm.controls["contactPersonVisibility"].setValue(
        this.setting.requesterPrivacyContactPersonVisibility
          ? this.setting.requesterPrivacyContactPersonVisibility
          : DefaultApplicationSetting.requesterPrivacyContactPersonVisibility,
      );
      this.requesterForm.controls["requesterAgentPrivacyVisibility"].setValue(
        this.setting.requesterAgentPrivacyVisibility
          ? this.setting.requesterAgentPrivacyVisibility
          : DefaultApplicationSetting.requesterAgentPrivacyVisibility,
      );
    },
  };
}
