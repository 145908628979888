import { FormControl } from "@angular/forms";

export interface IAgent {
    uuId: string;
    memberId: string;
    chineseName: string;
    centerCode: string;
    phone: string;
    remark: any;
}

export class Agent {
    public uuId: string;
    public memberId: string;
    public chineseName: string;
    public centerCode: string;
    public phone: string;
    public remark: FormControl;

    public isOriginal: boolean;

    constructor(agent: IAgent) {
        this.uuId = agent.uuId;
        this.memberId = agent.memberId;
        this.chineseName = agent.chineseName;
        this.centerCode = agent.centerCode;
        this.phone = agent.phone;
        this.remark = new FormControl(agent.remark);
        this.isOriginal = false;
    }
}