import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FirebaseService } from "./services/firebase/firebase.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Portal";

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
  ) {
    var lang = localStorage.getItem("lang") || "zh-Hant-HK";
    this.translateService.use(lang).subscribe();
    this.translateService.onLangChange.subscribe((value) => {
      setTimeout(() => {
        console.log("reloadLang");
        this.translateService.reloadLang("en").subscribe();
      }, 1000);
    });
    firebaseService.requestPermission();
  }
}
