import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../share/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RedeemOverviewPageComponent } from './pages/redeem-overview-page/redeem-overview-page.component';
import { RedeemCreatePageComponent } from './pages/redeem-create-page/redeem-create-page.component';
import { RedeemSettingPageComponent } from './pages/redeem-setting-page/redeem-setting-page.component';
import { RedeemDetailPageComponent } from './pages/redeem-detail-page/redeem-detail-page.component';
import { RedeemQuestionComponent } from './components/redeem-question/redeem-question.component';
import { RedeemImagePopupComponent } from './components/redeem-image-popup/redeem-image-popup.component';
import { RedeemManagementPageComponent } from './pages/redeem-management-page/redeem-management-page.component';
import { ProductManagementRedeemComponent } from './components/product-management-redeem/product-management-redeem.component';
import { ProductManagementPendingComponent } from './components/product-management-pending/product-management-pending.component';
import { RedeemPopupComponent } from './components/redeem-popup/redeem-popup.component';
import { SelectRedeemUserPageComponent } from './pages/select-redeem-user-page/select-redeem-user-page.component';
import { RedeemGrantPopupComponent } from './components/redeem-grant-popup/redeem-grant-popup.component';

const routes: Routes = [
  { path: 'overview', component: RedeemOverviewPageComponent },
  { path: 'create', component: RedeemCreatePageComponent },
  { path: 'setting', component: RedeemSettingPageComponent },
  { path: 'overview/:id', component: RedeemDetailPageComponent },
  { path: 'overview/:id/management', component: RedeemManagementPageComponent },
  { path: 'overview/:id/select-redeem-user', component: SelectRedeemUserPageComponent },
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: '**', redirectTo: 'overview' }
];

@NgModule({
  declarations: [
    RedeemOverviewPageComponent,
    RedeemCreatePageComponent,
    RedeemSettingPageComponent,
    RedeemDetailPageComponent,
    RedeemQuestionComponent,
    RedeemImagePopupComponent,
    RedeemManagementPageComponent,
    ProductManagementRedeemComponent,
    ProductManagementPendingComponent,
    RedeemPopupComponent,
    SelectRedeemUserPageComponent,
    RedeemGrantPopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ShareModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule
  ]
})
export class RedeemModule { }
