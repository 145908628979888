import { Component, Input, OnInit } from "@angular/core";
import { Center } from "src/app/models/centers/center";
import { PortalAnimation } from "src/app/portal-animation";
import { CenterService } from "src/app/services/center/center.service";

@Component({
  selector: "app-center-filter",
  templateUrl: "./center-filter.component.html",
  styleUrls: ["./center-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class CenterFilterComponent implements OnInit {
  @Input() action: (centers: Center[]) => void = () => {};
  centers: Center[] = [];
  display: boolean = false;

  constructor(private centerService: CenterService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.centerService.getCenters().subscribe({
      next: (value) => {
        this.centers = value;
      },
    });
  }

  reset(): void {
    this.centers.forEach((center) => (center.selected = false));
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.centers.filter((center) => center.selected));
    }
    this.display = !this.display;
  }

  get numberOfSelectedCenters(): number {
    return this.centers.filter((center) => center.selected).length;
  }
}
