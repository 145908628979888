import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { ITransaction, Transaction } from 'src/app/models/transactions/transaction';
import { UserTransaction } from 'src/app/models/transactions/user-transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private httpService: HttpService
  ) { }

  public getUserTransactions(userUUId: string): Observable<UserTransaction[]> {
    return this.httpService.get<UserTransaction[]>("transactions/" + userUUId + "/list")
      .pipe(map(value => value.result.map(t => new UserTransaction(t))));
  }

  public cancelTransaction(transactionUUId: string, model: { remark: string }): Observable<any> {
    return this.httpService.post<any, { remark: string }>("transactions/" + transactionUUId + "/cancel", model);
  }

  public editTransaction(transactionUUId: string, model: { amount: number }): Observable<{ newTransactionUUId: string }> {
    return this.httpService.put<{ newTransactionUUId: string }, { amount: number }>("transactions/" + transactionUUId, model)
      .pipe(map(value => value.result));
  }

  public getDeletedTransaction(): Observable<Transaction[]> {
    return this.httpService.get<ITransaction[]>("transactions/deletedList")
      .pipe(map(value => value.result.map(transaction => new Transaction(transaction))));
  }

  public restoreTransactions(transactionUUIds: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("transactions/restore", { list: transactionUUIds });
  }

  public deleteTransactionsPermanently(transactionUUIds: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("transactions/deletePermanently", { list: transactionUUIds });
  }
}
