export interface IOrganizationConfig {
    config: string;
}

export class OrganizationConfig {
    config: IOrganizationTimeConfig;

    constructor(config: IOrganizationConfig) {
        this.config = JSON.parse(config.config);
    }
}

export interface IOrganizationTimeConfig {
    RequestDefaultDuration: number;
    EventNotification:number;
    MinimunCreateTimeFromNow: number;
    CheckInStartTime: number;
    CheckInEndTime: number;
    CheckOutStartTime: number;
    CheckOutEndTime: number;
    StartNotification: number;
    OverStartNotification: number;
    EndNotification: number;
    OverEndNotification: number;
    OvertimeLimit: number;
    TrashClearTime: number;
    NoMatchNotification:number|null;
    IsFullDayStart:boolean;
    IsFullDayEnd:boolean;
    RequestNotificationStaff:number;
}

export const DefaultOrganizationTimeConfig: IOrganizationTimeConfig = {
    RequestDefaultDuration: 2,
    MinimunCreateTimeFromNow: 60,
    EventNotification:4320,
    RequestNotificationStaff:4320,
    CheckInStartTime: 15,
    CheckInEndTime: 7200,
    CheckOutStartTime: 15,
    CheckOutEndTime: 7200,
    StartNotification: 15,
    OverStartNotification: 15,
    EndNotification: 15,
    OverEndNotification: 15,
    OvertimeLimit: 15,
    TrashClearTime: 14,
    NoMatchNotification:null,
    IsFullDayStart:false,
    IsFullDayEnd:false
}
