import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSettingPageComponent } from './pages/data-setting-page/data-setting-page.component';
import { RecommendationSettingPageComponent } from './pages/recommendation-setting-page/recommendation-setting-page.component';
import { RouterModule, Routes } from '@angular/router';
import { CenterSettingComponent } from './components/center-setting/center-setting.component';
import { DataSettingContainerComponent } from './components/data-setting-container/data-setting-container.component';
import { EventSettingContainerComponent } from './components/event-setting-container/event-setting-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationSettingContainerComponent } from './components/organization-setting-container/organization-setting-container.component';
import { CellGroupSettingComponent } from './components/cell-group-setting/cell-group-setting.component';
import { CellGroupMembersPopupComponent } from './components/cell-group-members-popup/cell-group-members-popup.component';
import { ShareModule } from '../share/share.module';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceTypeSettingComponent } from './components/service-type-setting/service-type-setting.component';
import { ServiceSettingComponent } from './components/service-setting/service-setting.component';
import { HealthSettingComponent } from './components/health-setting/health-setting.component';
import { LivingSettingComponent } from './components/living-setting/living-setting.component';
import { TransportationSettingComponent } from './components/transportation-setting/transportation-setting.component';
import { EventTypeSettingComponent } from './components/event-type-setting/event-type-setting.component';
import { HealthLivingSettingComponent } from './components/health-living-setting/health-living-setting.component';
import { RecommandSettingComponent } from './components/recommand-setting/recommand-setting.component';
import { AddCellGroupMemberPageComponent } from './pages/add-cell-group-member-page/add-cell-group-member-page.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeSettingComponent } from './components/time-setting/time-setting.component';
import { ApplicationSettingPageComponent } from './pages/application-setting-page/application-setting-page.component';
import { UserTemplateSettingPageComponent } from './pages/user-template-setting-page/user-template-setting-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivityTemplatePageComponent } from './pages/activity-template-page/activity-template-page.component';
import { RequestTemplateComponent } from './components/request-template/request-template.component';
import { EventTemplateComponent } from './components/event-template/event-template.component';
import { CenterLinkerVolunteerComponent } from './components/center-linker-volunteer/center-linker-volunteer.component';
import { CenterDetailComponent } from './components/center-detail/center-detail.component';

const routes: Routes = [
  {
    path: 'setting', component: DataSettingPageComponent, children: [
      { path: 'organization', component: OrganizationSettingContainerComponent, data: { animationState: 'organization' } },
      { path: 'data', component: DataSettingContainerComponent, data: { animationState: 'data' } },
      { path: 'event', component: EventSettingContainerComponent, data: { animationState: 'event' } },
      { path: 'time', component: TimeSettingComponent, data: { animationState: 'time' } },
      { path: '', redirectTo: 'organization', pathMatch: 'full' },
      { path: '**', redirectTo: 'organization' }
    ]
  },
  { path: ':id/select-member', component: AddCellGroupMemberPageComponent },
  { path: 'recommendation', component: RecommendationSettingPageComponent },
  { path: 'application', component: ApplicationSettingPageComponent },
  { path: 'user-template', component: UserTemplateSettingPageComponent },
  {
    path: 'activity-template', component: ActivityTemplatePageComponent, children: [
      { path: 'request', component: RequestTemplateComponent, data: { animationState: 'request' } },
      { path: 'event', component: EventTemplateComponent, data: { animationState: 'event' } },
      { path: '', redirectTo: 'request', pathMatch: 'full' },
      { path: '**', redirectTo: 'request' }
    ]
  },
  { path: '', redirectTo: 'setting', pathMatch: 'full' },
  { path: '**', redirectTo: 'setting' }
]

@NgModule({
  declarations: [DataSettingPageComponent, RecommendationSettingPageComponent, CenterSettingComponent, DataSettingContainerComponent, EventSettingContainerComponent, OrganizationSettingContainerComponent, CellGroupSettingComponent, CellGroupMembersPopupComponent, ServiceTypeSettingComponent, ServiceSettingComponent, HealthSettingComponent, LivingSettingComponent, TransportationSettingComponent, EventTypeSettingComponent, HealthLivingSettingComponent, RecommandSettingComponent, AddCellGroupMemberPageComponent, TimeSettingComponent, ApplicationSettingPageComponent, UserTemplateSettingPageComponent, ActivityTemplatePageComponent, RequestTemplateComponent, EventTemplateComponent, CenterLinkerVolunteerComponent, CenterDetailComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    MatDatepickerModule
  ]
})
export class SystemModule { }
