<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedFormats > 0}" (click)="toggle()">
    <i style="transform: scaleX(-1);" class="material-icons filter-icon" *ngIf="numberOfSelectedFormats == 0">favorite_border</i>
    <p class="filter-number" *ngIf="numberOfSelectedFormats > 0">{{ numberOfSelectedFormats }}</p>
    <p translate>參與類型</p>
    <div class="filter-body center" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-6">
                <p class="text-bold flex-1" style="color: #5A5A5A;" translate>參與類型</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">
                <label class="checkbox mb-4" *ngFor="let format of formats">
                    <input class="mr-8" type="checkbox" [(ngModel)]="format.selected">
                    <p>{{ format.name | translate }}</p>
                </label>
            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>