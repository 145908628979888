import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StaffOverviewPageComponent } from "./pages/staff-overview-page/staff-overview-page.component";
import { StaffCreatePageComponent } from "./pages/staff-create-page/staff-create-page.component";
import { StaffDetailPageComponent } from "./pages/staff-detail-page/staff-detail-page.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShareModule } from "../share/share.module";
import { TranslateModule } from "@ngx-translate/core";
import { CenterPermissionPopupComponent } from "./components/center-permission-popup/center-permission-popup.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StaffProfilePageComponent } from "./pages/staff-profile-page/staff-profile-page.component";
import { SuperAdminGuard } from "src/app/guards/super-admin.guard";

const routes: Routes = [
  {
    path: "overview",
    component: StaffOverviewPageComponent,
    canActivate: [SuperAdminGuard],
  },
  {
    path: "create",
    component: StaffCreatePageComponent,
    canActivate: [SuperAdminGuard],
  },
  {
    path: "overview/:id",
    component: StaffDetailPageComponent,
    canActivate: [SuperAdminGuard],
  },
  { path: "my-profile", component: StaffProfilePageComponent },
  { path: "", redirectTo: "overview", pathMatch: "full" },
  { path: "**", redirectTo: "overview" },
];

@NgModule({
  declarations: [
    StaffOverviewPageComponent,
    StaffCreatePageComponent,
    StaffDetailPageComponent,
    CenterPermissionPopupComponent,
    StaffProfilePageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    TranslateModule.forChild(),
    MatTooltipModule,
  ],
})
export class StaffModule {}
