import { Component, Input, OnInit } from '@angular/core';
import { Center } from 'src/app/models/centers/center';
import { CenterService } from 'src/app/services/center/center.service';

@Component({
  selector: 'app-center-permission-popup',
  templateUrl: './center-permission-popup.component.html',
  styleUrls: ['./center-permission-popup.component.scss']
})
export class CenterPermissionPopupComponent implements OnInit {

  @Input() action: (centers: Center[]) => void = () => { }

  display: boolean = false;
  centers: Center[] = [];
  @Input() defaultCenter: Center | undefined = undefined;

  constructor(
    private centerService: CenterService) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.centerService.getCenters()
      .subscribe({
        next: value => this.centers = value
      });
  }

  reset(): void {
    this.centers.filter(center => center.uuId != this.defaultCenter?.uuId).forEach(center => center.selected = false);

  }

  open(centers: Center[]): void {
    this.defaultCenter = centers[0];
    this.centers.forEach(center => {
      center.selected = centers.some(c => c.uuId == center.uuId);
    });
    this.display = true;
  }

  close(): void {
    this.centers.forEach(center => center.selected = false);
    this.display = false;
  }

  submit(): void {
    this.action(this.centers.filter(center => center.selected));
    this.close();
  }
}
