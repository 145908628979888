<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="member-box zoomIn" (click)="$event.stopPropagation()">

        <div class="member-box-header">
            <p class="member-title">{{ name }}</p>
        </div>

        <div class="member-box-body">
            <div class="inline-flex-row align-items-start">
                 <p class="text-sm text-lightgrey mb1 mt-8" translate>欄目帶 * 號為必填項</p>
            </div>
            <div class="input-row-image ">
                <p class="title " translate>中心標誌</p>
                <img #image *ngIf="file" [src]="file" height="60" width="60" alt="Logo">
                <p>{{oldIconTxt}}</p>
                <input #imageInput style="display: none;" type="file" accept="image/*" (change)="onImageImport($event.target.files)">
                <button  *ngIf="!file" class="button outline blue sm alignSelfCenter" type="button" (click)="imageInput.click()" translate>上傳圖片</button>
                <i  *ngIf="file" class="click-text-lightblue material-icons-outlined create-icon" (click)="imageInput.click()">create</i>
            </div>
            <div class="inline-flex-row align-items-start" style="cursor: pointer;" routerLink="user">
                <i  class="material-icons-round text-lightblue text-lg">help_outline</i>
                <p class="text-lightgrey text-xs ml-3">如無上傳圖片，系統則默認顯示HINCare標誌</p>
            </div>
            <div class="input-row">
                <p class="title required" translate>中心代碼</p>
                <input class="user-input md" type="text" [(ngModel)]="centerCode" >
            </div>
            <div class="input-row">
                <p class="title required" translate>中心名稱</p>
                <input class="user-input md" type="text" [(ngModel)]="name" >
            </div>
            <div class="input-row">
                <p class="title required" translate>聯絡電話</p>
                <input class="user-input md" type="text" [(ngModel)]="phoneNumber" >
            </div>
            <div class="input-row">
                <p><input type="checkbox" [(ngModel)]="wts"></p>
                <p>使用WhatsApp為默認的聯絡職員方式</p>
                <input class="user-input md wts_input" type="text" [(ngModel)]="wts_num" >
            </div>
            <div class="inline-flex-row align-items-start" style="cursor: pointer;" routerLink="user">
                <i  class="material-icons-round text-lightblue text-lg">help_outline</i>
                <p class="text-lightgrey text-xs ml-3">勾選後，會員使用應用程式中的“聯絡職員”功能，會跳轉至WhatsApp第三方聯絡中心職員</p>
            </div>
            <div class="input-row">
                <p class="title required" translate>服務時間</p>
                <input class="user-input md long" type="text"  [(ngModel)]="service_Time" >
            </div>
            <div class="inline-flex-row align-items-start" style="cursor: pointer;" routerLink="user">
                <i  class="material-icons-round text-lightblue text-lg">help_outline</i>
                <p class="text-lightgrey text-xs ml-3">服務時間作信息提示用，應用程式會員可自行聯絡中心，不受限制</p>
            </div>
        </div>

        <div class="member-box-footer">
            <button class="button outline mar5_25" (click)="close()" translate>返回</button>
            <button type="button" class="button md fill blue mal5_25" (click)="save()" translate>保存</button>
        </div>
    </div>
    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>