import { UpdateEventDayForm } from "src/app/modules/event/forms/update-event-day-form";

export interface IEventDay {
    uuId: string | null;
    date: Date;
    startTime: Date;
    endTime: Date;
    isCancelled: boolean;
    numOfAttendance: number|null;
}

export class EventDay {
    uuId: string | null;
    date: Date;
    startTime: Date;
    endTime: Date | null;
    isCancelled: boolean;

    editing: boolean;
    numOfAttendance: number|null;

    constructor(eventDay: IEventDay) {
        this.uuId = eventDay.uuId;
        this.date = typeof (eventDay.date) == 'string' ? new Date(eventDay.date + 'Z') : eventDay.date;
        this.startTime = typeof (eventDay.startTime) == 'string' ? new Date(eventDay.startTime + 'Z') : eventDay.startTime;
        this.endTime = eventDay.endTime ? (typeof (eventDay.endTime) == 'string' ? new Date(eventDay.endTime + 'Z') : eventDay.endTime) : null;
        this.isCancelled = eventDay.isCancelled;
        this.numOfAttendance=eventDay.numOfAttendance;
        this.editing = false;
    }
    public getStartTime(): Date {
        var time = new Date(this.date);
        time.setHours(this.startTime.getHours());
        time.setMinutes(this.startTime.getMinutes());
        return time;
    }

    public getEndTime(): Date | null {
        if (this.endTime == null)
            return null;
        var time = new Date(this.date);
        time.setHours(this.endTime.getHours());
        time.setMinutes(this.endTime.getMinutes());
        return time;
    }
}