<div class="box">
    <p class="title" translate>细胞小组</p>
    <div class="inline-flex-column">
        <form class="input-row" style="width: 36.875rem;" [formGroup]="form">
            <div class="user-select">
                <select class="user-select lg" formControlName="center">
                    <option [ngValue]="null" selected disabled translate>所属中心</option>
                    <option [ngValue]="center.uuId" *ngFor="let center of centers">{{ center.name }}</option>
                </select>
            </div>
            <input type="text" class="user-input lg" style="margin-left: 1.5625rem;" formControlName="name" placeholder="{{ '新增细胞小组名稱' | translate }}">
            <p class="click-text-blue text-xs ml-8" (click)="create()" translate>保存</p>
        </form>

        <div class="cell-group-box">
            <div class="center-column">
                <div class="center-item mb-2" [ngClass]="{active: index == i}" *ngFor="let center of centers; let i = index" (click)="index = i">
                    <p>{{ center.name }}</p>
                    <p>{{ center.cellGroups.length }}</p>
                </div>
            </div>

            <div class="cell-group-column">
                <ng-container *ngIf="selectedCenter">
                    <div class="cell-group-item mb-2" *ngFor="let cellGroup of selectedCenter.cellGroups">
                        <ng-container *ngIf="cellGroup.editing;then edit;else read"></ng-container>
                        <ng-template #read>
                            <p class="cell-group-name">{{ cellGroup.name }}</p>
                            <div class="cell-group-leader ml-8">
                                <p *ngIf="cellGroup.leaders.length > 0" matTooltip="{{ '組長：' | translate: {value: cellGroup.leaders[0].name} }}" [matTooltipPosition]="'above'">{{ '組長：' | translate: {value: cellGroup.leaders[0].name} }}</p>
                                <p *ngIf="cellGroup.leaders.length > 1">...</p>
                            </div>
                            <i class="click-text-lightblue material-icons-outlined create-icon ml-8" (click)="cellGroup.editing = true">create</i>
                            <i class="click-text-lightblue material-icons-outlined create-icon ml-8" (click)="member.open(cellGroup)">search</i>
                        </ng-template>

                        <ng-template #edit>
                            <form class="inline-flex-row align-items-center" [formGroup]="cellGroup.form">
                                <input class="user-input lg" type="text" formControlName="name" placeholder="细胞小组名稱">
                                <p class="click-text-blue text-xs ml-8" (click)="update(cellGroup)" translate>保存</p>
                                <p class="click-text-blue text-xs ml-4" (click)="cellGroup.editing = false" translate>取消</p>
                            </form>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>

<app-cell-group-members-popup #member></app-cell-group-members-popup>