import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TemplateColumn } from "src/app/backend/app-column";
import { Address } from "src/app/models/addresses/address";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Health } from "src/app/models/healths/health";
import { Agent } from "src/app/models/profiles/agent";
import { Availability } from "src/app/models/profiles/availability";
import { Profile } from "src/app/models/profiles/profile";
import { UpdateUserModel } from "src/app/models/profiles/update-user-model";
import { Service } from "src/app/models/services/service";
import { UpdateMyStaffModel } from "src/app/models/staffs/update-my-staff-model";
import { UpdateStaffModel } from "src/app/models/staffs/update-staff-model";

export class UpdateUserForm {
    formGroup: FormGroup;
    healths: Health[];
    cellGroups: CellGroup[];
    services: Service[];
    addresses: Address[];
    availabilities: Availability[];
    columns: TemplateColumn[];
    agents: Agent[];
    staffCenters: Center[];


    constructor(profile: Profile) {
        this.formGroup = new FormGroup({
            chineseName: new FormControl(profile.chineseName, [Validators.required]),
            englishName: new FormControl(profile.englishName),
            gender: new FormControl(profile.gender, [Validators.required]),
            birthday: new FormControl(profile.dateOfBirth, [Validators.required]),
            center: new FormControl(profile.center.uuId, [Validators.required]),
            phone: new FormControl(profile.phone, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
            phoneModel: new FormControl(profile.mobilePhoneModel),
            simCard: new FormControl(profile.hasSimCard, [Validators.required]),
            username: new FormControl(profile.userName, [Validators.minLength(4)]),
            password: new FormControl(null, [Validators.minLength(4)]),
            literacy: new FormControl(profile.literacy, [Validators.required]),
            educationLevel: new FormControl(profile.educationLevel, [Validators.required]),
            contactPersonName: new FormControl(profile.contactPersonName),
            contactPersonPhone: new FormControl(profile.contactPersonPhoneNumber),
            allowApproval: new FormControl(profile.allowApproval),
            remark:new FormControl(profile.remark)
        });

        this.healths = [...profile.healths];
        this.cellGroups = [...profile.cellGroups];
        this.services = [...profile.services];
        this.availabilities = [...profile.availabilities];
        this.columns = [];
        this.agents = [...profile.agents];
        this.addresses = [...profile.addresses];
        this.agents.forEach(agent => agent.isOriginal = true);
        this.staffCenters = [...profile.staffCenters];
    }

    addAddress(): void {
        this.addresses.push(new Address({
            uuId: null,
            region: { uuId: '', name: null, livingConditions: [] },
            district: { uuId: '', name: null, livingConditions: [] },
            subDistrict: { uuId: '', name: '', livingConditions: [] },
            street: { uuId: '', name: '', livingConditions: [] },
            streetNumber: { uuId: '', name: '', livingConditions: [] },
            estate: { uuId: '', name: '', livingConditions: [] },
            block: { uuId: '', name: '', livingConditions: [] },
            floor: { uuId: '', name: '', livingConditions: [] },
            room: { uuId: '', name: '', livingConditions: [] },
            isDefault: false
        }));
    }

    getUpdateUserModel(): UpdateUserModel {
        let model: UpdateUserModel = {
            chineseName: this.formGroup.controls['chineseName'].value,
            englishName: this.formGroup.controls['englishName'].value,
            dateOfBirth: (this.formGroup.controls['birthday'].value as Date).toISOString(),
            gender: this.formGroup.controls['gender'].value === 'M' ? 'M'
                : this.formGroup.controls['gender'].value === 'F' ? 'F'
                    : this.formGroup.controls['gender'].value === '男' ? 'M'
                        : 'F',
            district: null,
            HKID: null,
            MarriageStatus: null,
            EducationLevel: this.formGroup.controls['educationLevel'].value,
            Literacy: this.formGroup.controls['literacy'].value,
            Career: null,
            Remark: this.formGroup.controls['remark'].value,
            phoneNumber: this.formGroup.controls['phone'].value,
            HasSimCard: this.formGroup.controls['simCard'].value,
            MobilePhoneModel: this.formGroup.controls['phoneModel'].value,
            CenterCode: this.formGroup.controls['center'].value,
            addresses: this.addresses.map(address => address.getUpdateAddressModel()),
            healthConditions: {
                healthConditions: this.healths.map(health => health.uuId)
            },
            services: {
                services: this.services.map(service => service.uuId)
            },
            contactPersonName: this.formGroup.controls['contactPersonName'].value,
            contactPersonPhoneNumber: this.formGroup.controls['contactPersonPhone'].value,
            availabilities: {
                availabilities: this.availabilities
            },
            cellGroups: this.cellGroups.map(group => group.uuId),
            extraPersonJson: this.stringtifyColumns(this.columns)
        }

        if (model.addresses.length > 0) model.addresses[0].isDefault = true;
        return model;
    }

    getUpdateMyStaffModel(): UpdateMyStaffModel {
        var model: UpdateMyStaffModel = {
            ChineseName: this.formGroup.controls['chineseName'].value,
            EnglishName: this.formGroup.controls['englishName'].value,
            DateOfBirth: new Date("1970-01-01"),
            Gender: this.formGroup.controls['gender'].value,
            Remark: this.formGroup.controls['remark'].value,
            HasSimCard: false,
            CenterCode: this.formGroup.controls['center'].value,
            Addresses: this.addresses.map(a => a.getUpdateAddressModel()),
            PhoneNumber: this.formGroup.controls['phone'].value,
        };
        model.Addresses[0].isDefault = true;
        return model;
    }

    getUpdateStaffModel(): UpdateStaffModel {
        var model: UpdateStaffModel = {
            ChineseName: this.formGroup.controls['chineseName'].value,
            EnglishName: this.formGroup.controls['englishName'].value,
            DateOfBirth: new Date("1970-01-01"),
            Gender: this.formGroup.controls['gender'].value,
            Remark: null,
            HasSimCard: false,
            CenterCode: this.formGroup.controls['center'].value,
            Addresses: this.addresses.map(a => a.getUpdateAddressModel()),
            CentersStaff: this.staffCenters.map(c => c.uuId),
            PhoneNumber: this.formGroup.controls['phone'].value,
            allowApproval: this.formGroup.controls['allowApproval'].value
        };
        model.Addresses[0].isDefault = true;
        return model;
    }

    get valid(): boolean {
        return this.formGroup.valid && this.addresses.every(address => address.form.formGroup.valid);
    }

    doChangeUsernameAndPassword(): boolean {
        return this.formGroup.controls['username'].value && this.formGroup.controls['username'].valid
            && this.formGroup.controls['password'].value && this.formGroup.controls['password'].valid;
    }

    protected stringtifyColumns(columns: TemplateColumn[]): string {
        var temp: any = {};
        columns.forEach(column => {
            temp[column.columnName] = column.input.value
        });
        return JSON.stringify(temp);
    }
}