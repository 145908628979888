<div class="box">
    <p class="title" translate>交通工具</p>
    <div class="inline-flex-column">
        <form class="input-row" [formGroup]="form" style="width: 36.875rem;">
            <input type="text" class="user-input sm" formControlName="name" placeholder="{{ '新增項目' | translate }}">
            <p class="click-text-blue text-xs ml-3" (click)="create()" translate>保存</p>
        </form>

        <div class="transportation-box">
            <div class="transportation-item" *ngFor="let transportation of transportations">
                <ng-container *ngIf="transportation.editing == false;else editing">
                    <p class="name">{{ transportation.name }}</p>
                    <i class="click-text-lightblue material-icons-outlined create-icon" (click)="transportation.editing = true">create</i>
                </ng-container>
                <ng-template #editing>
                    <form class="inline-flex-row align-items-center" [formGroup]="transportation.form">
                        <input type="text" class="user-input sm" formControlName="name" placeholder="{{ '交通工具' | translate }}">
                        <p class="click-text-blue text-xs ml-3" (click)="update(transportation)" translate>保存</p>
                        <p class="click-text-blue text-xs ml-3" (click)="transportation.editing = false" translate>取消</p>
                    </form>
                </ng-template>
            </div>
        </div>

    </div>

    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>