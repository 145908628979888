<div class="input-row">
    <p class="title align-self-start lh-8" translate>中央補貼</p>
    <form class="inline-flex-column" [formGroup]="form">
        <div class="inline-flex-row">
            <label>
                <input type="radio" formControlName="isSubsidised" [value]="false">
                <p class="lh-8" translate>否</p>
            </label>

            <label style="margin-left: 1.5rem;">
                <input type="radio" formControlName="isSubsidised" [value]="true">
                <p class="lh-8" translate>是</p>
            </label>
        </div>
        <div class="inline-flex-row align-items-center mt-3" *ngIf="isSubsidised">
            <p class="lh-8" translate>系統會補貼</p>
            <ng-container *ngIf="editing">
                <input class="user-input ml-2 mr-2" style="width: 3.6875rem;" type="number" formControlName="amount">
            </ng-container>
            <ng-container *ngIf="editing == false">
                <p class="text-lightblue ml-2 mr-2">{{ subsidy.amount }}</p>
            </ng-container>
            <p translate>時分 至</p>
            <p class="mr-2">{{ requester.memberId }} {{ requester.chineseName }}</p>
            <p translate>帳戶</p>
            <ng-container *ngIf="editing">
                <p class="click-text-lightblue ml-8" (click)="subsidise()" translate>確認</p>
                <p class="click-text-lightblue ml-8" [ngClass]="{disabled: subsidy == null}" (click)="editing = false" translate>取消</p>
            </ng-container>
            <ng-container *ngIf="editing == false">
                <p class="click-text-lightblue ml-8" (click)="editing = true" translate>編輯</p>
            </ng-container>
        </div>
    </form>

</div>