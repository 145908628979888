import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from '../share/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RequestOverviewComponent } from './components/request-overview/request-overview.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RequestCreateComponent } from './components/request-create/request-create.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SelectRequesterPageComponent } from './pages/select-requester-page/select-requester-page.component';
import { SelectVolunteersPageComponent } from './pages/select-volunteers-page/select-volunteers-page.component';
import { VolunteerScorePopupComponent } from './components/volunteer-score-popup/volunteer-score-popup.component';
import { RequestDetailPageComponent } from './pages/request-detail-page/request-detail-page.component';
import { RequestDetailVolunteerTableComponent } from './components/request-detail-volunteer-table/request-detail-volunteer-table.component';
import { RequestFeedbackPopupComponent } from './components/request-feedback-popup/request-feedback-popup.component';
import { RequestStartPopupComponent } from './components/request-start-popup/request-start-popup.component';
import { RequestTerminatePopupComponent } from './components/request-terminate-popup/request-terminate-popup.component';
import { RequestSubsidyComponent } from './components/request-subsidy/request-subsidy.component';
import { RequestRatingComponent } from './components/request-rating/request-rating.component';



@NgModule({
  declarations: [
    RequestOverviewComponent,
    RequestCreateComponent,
    SelectRequesterPageComponent,
    SelectVolunteersPageComponent,
    VolunteerScorePopupComponent,
    RequestDetailPageComponent,
    RequestDetailVolunteerTableComponent,
    RequestFeedbackPopupComponent,
    RequestStartPopupComponent,
    RequestTerminatePopupComponent,
    RequestSubsidyComponent,
    RequestRatingComponent
  ],
  imports: [
    CommonModule,
    ShareModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    MatDatepickerModule
  ]
})
export class RequestModule { }
