<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedItems > 0}" (click)="toggle()">
    <i class="filter-icon material-icons-outlined" *ngIf="numberOfSelectedItems == 0">favorite_border</i>
    <p class="filter-number" *ngIf="numberOfSelectedItems > 0">{{ numberOfSelectedItems }}</p>
    <p translate>個人情況</p>
    <div class="filter-body condition" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-8">
                <p class="text-bold flex-1" style="color: #5A5A5A" translate>個人情況</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">
                <label class="checkbox mb-4">
                    <p translate>年齡</p>
                </label>

                <form class="inline-flex-row align-items-center mt-4" [formGroup]="ageFromGroup">
                    <input class="user-input xxs mr-3" type="number" formControlName="from" placeholder="{{ '年齡' | translate }}">
                    <p class="mr-6" translate>歲 至</p>
                    <input class="user-input xxs mr-3" type="number" formControlName="to" placeholder="{{ '年齡' | translate }}">
                    <p translate>歲*</p>
                </form>

                <div class="border-bottom mt-4 mb-8"></div>

                <label class="checkbox mb-4">
                    <!-- <input class="mr-8" type="checkbox" [ngModel]="isSelectedAllGender" (click)="selectAllGenders()"> -->
                    <p translate>性別</p>
                </label>
                <label class="checkbox mb-4" *ngFor="let gender of genders">
                    <input class="mr-8" type="checkbox" [(ngModel)]="gender.selected">
                    <p class="text-sm">{{ gender.name | translate }}</p>
                </label>

                <div class="border-bottom mt-4 mb-8"></div>

                <label class="checkbox mb-4">
                    <!-- <input class="mr-8" type="checkbox" [ngModel]="isSelectedAllHealthConditions" (click)="selectAllHealthConditions()"> -->
                    <p translate>健康狀況</p>
                </label>
                <label class="checkbox mb-6" *ngFor="let health of healths">
                    <input class="mr-8" type="checkbox" [(ngModel)]="health.selected">
                    <p class="text-sm">{{ health.name }}</p>
                </label>

                <div class="border-bottom mt-4 mb-8"></div>

                <label class="checkbox mb-4">
                    <!-- <input class="mr-8" type="checkbox" [ngModel]="isSelectedAllLivingConditions" (click)="selectAllLivingConditions()"> -->
                    <p translate>居住條件</p>
                </label>
                <label class="checkbox mb-4" *ngFor="let living of livings">
                    <input class="mr-8" type="checkbox" [(ngModel)]="living.selected">
                    <p class="text-sm">{{ living.name }}</p>
                </label>
            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>