import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Volunteer } from "src/app/models/requests/volunteer";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RequestService } from "src/app/services/request/request.service";
import { VolunteerService } from "src/app/services/volunteer/volunteer.service";

@Component({
  selector: "app-request-feedback-popup",
  templateUrl: "./request-feedback-popup.component.html",
  styleUrls: ["./request-feedback-popup.component.scss"],
})
export class RequestFeedbackPopupComponent implements OnInit {
  public display: boolean = false;
  public questions: any = DefaultFeedBackQuestion;

  public volunteer: Volunteer | null = null;

  public rating: number = 0;
  public ratings: number[] = [1, 2, 3, 4, 5];

  constructor(
    private volunteerService: VolunteerService,
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {}

  rate(rating: number): void {
    this.rating = rating;
  }

  open(volunteer: Volunteer): void {
    this.volunteer = volunteer;

    this.rating = volunteer.volunteerRating;

    this.questions = DefaultFeedBackQuestion;

    if (volunteer != null && volunteer.volunteerComment != null) {
      this.questions = JSON.parse(volunteer.volunteerComment);
    }

    this.display = true;
  }

  close(): void {
    this.display = false;
    this.volunteer = null;
  }

  submit(): void {
    if (this.volunteer == null) return;
    if (this.isValidAnswer() == false) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    let feedback: any = {};
    Object.keys(this.questions).forEach((key) => {
      feedback[key] = {
        Q: this.questions[key].Q,
        A:
          typeof this.questions[key].A == "string"
            ? this.questions[key].A
            : this.questions[key].A.value,
      };
    });
    this.loadingService.startLoading();

    this.route.params.subscribe((value) => {
      this.volunteerService
        .feedback(value.id, {
          VolunteerUUId: this.volunteer != null ? this.volunteer.userUUId : "",
          rating: this.rating,
          comment: JSON.stringify(feedback),
        })
        .pipe(
          finalize(() => {
            this.loadingService.stopLoading();
            if (this.volunteer != null)
              this.volunteer.volunteerRating = this.rating;
          }),
        )
        .subscribe(() => this.close());
    });
  }

  reset(): void {
    if (this.volunteer?.volunteerCommentCreatedBy) return;

    this.rating = 0;

    Object.keys(this.questions).forEach((key) => {
      this.questions[key].A.reset();
    });
  }

  isValidAnswer(): boolean {
    if (this.rating == 0) return false;

    var valid = true;
    Object.keys(this.questions).forEach((key) => {
      if (this.questions[key].A.valid == false) valid = false;
    });

    return valid;
  }

  get stars(): number[] {
    return this.ratings.filter((r) => r <= this.rating);
  }

  get outlineStars(): number[] {
    return this.ratings.filter((r) => r > this.rating);
  }

  get disabled(): boolean {
    return (
      this.volunteer != null && this.volunteer.volunteerCommentCreatedBy != null
    );
  }
}

const DefaultFeedBackQuestion = {
  Q1: {
    Q: "受助者態度是否友善?\n Is the requester friendly and sincere?",
    A: new FormControl(null, [Validators.required]),
  },
  Q2: {
    Q: "是否願意再次幫助?\n Are you willing to help again?",
    A: new FormControl(null, [Validators.required]),
  },
  Q3: {
    Q: "此次服務是否順利?\n Was the service conducted smoothly?",
    A: new FormControl(null, [Validators.required]),
  },
  OtherComment: { Q: "", A: "" },
}; //職員填寫義工想法或建議:
