import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { forkJoin } from "rxjs";
import { defaultIfEmpty, finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Product } from "src/app/models/redeems/product";
import { Redeem } from "src/app/models/redeems/redeem";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-product-management-pending",
  templateUrl: "./product-management-pending.component.html",
  styleUrls: ["./product-management-pending.component.scss"],
})
export class ProductManagementPendingComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Input() product!: Product;
  @Input() redeems: Redeem[] = [];

  filters: any = {
    itemsPerPage: 10,
    page: 1,
    searchInput: null,
    centers: [],
  };

  constructor(
    private redeemService: RedeemService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {}

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
  }).bind(this);

  approve(): void {
    var users = this.redeems.filter((redeem) => redeem.selected);
    if (users.some((user) => user.quantityInput.invalid)) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    if (users.length == 0) {
      return;
    }

    this.loadingService.startLoading();
    forkJoin(
      users.map((user) =>
        this.redeemService.approve(user.uuId, user.quantityInput.value),
      ),
    )
      .pipe(
        defaultIfEmpty(),
        finalize(() => this.loadingService.stopLoading()),
      )
      .subscribe({
        next: (value) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ApproveRedeemSuccessMessage,
          );
          this.refresh.emit();
        },
        error: (error) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ApproveRedeemFailMessage,
          );
        },
      });
  }

  selectPage(): void {
    if (this.isPageSelectedAll)
      this.displayedRedeems
        .filter((redeem) => redeem.hasEnoughBalance)
        .forEach((redeem) => (redeem.selected = false));
    else
      this.displayedRedeems
        .filter((redeem) => redeem.hasEnoughBalance)
        .forEach((redeem) => (redeem.selected = true));
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.redeems
        .filter((redeem) => redeem.hasEnoughBalance)
        .forEach((redeem) => (redeem.selected = false));
    else
      this.redeems
        .filter((redeem) => redeem.hasEnoughBalance)
        .forEach((redeem) => (redeem.selected = true));
  }

  edit(redeem: Redeem): void {
    if (redeem.quantityInput.invalid) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    var sum = this.product.price * redeem.quantityInput.value;

    if (sum > redeem.timeBalance) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    redeem.price = sum;
    redeem.quantity = redeem.quantityInput.value;
    redeem.editing = false;
  }
  cancelRedeem(redeem: Redeem): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.refresh.emit();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.CancelRedeemMessage(this.redeemService.cancel(redeem.uuId)),
    );
  }

  get displayedRedeems(): Redeem[] {
    return this.redeems
      .filter((redeem) => redeem.redeemState == "Pending")
      .filter(
        (redeem) =>
          (this.filters.searchInput == null ||
            redeem.chineseName.includes(this.filters.searchInput) ||
            redeem.memberId.includes(this.filters.searchInput)) &&
          (this.filters.centers.length == 0 ||
            (this.filters.centers as Center[])
              .map((c) => c.centerCode)
              .includes(redeem.centerCode)),
      )
      .slice(
        (this.filters.page - 1) * this.filters.itemsPerPage,
        this.filters.page * this.filters.itemsPerPage,
      );
  }

  get isPageSelectedAll(): boolean {
    return this.displayedRedeems.every((redeem) => redeem.selected);
  }

  get isSelectedAll(): boolean {
    return this.redeems
      .filter((redeem) => redeem.redeemState == "Pending")
      .every((redeem) => redeem.selected);
  }

  get numberOfSelectedUsers(): number {
    return this.redeems.filter((redeem) => redeem.selected).length;
  }

  get candidates(): Redeem[] {
    return this.redeems.filter((redeem) => redeem.hasEnoughBalance);
  }

  random(): void {
    let users = [...this.candidates];

    users.forEach((candidate) => {
      candidate.quantityInput.setValue(0);
      candidate.selected = false;
    });

    if (users.length == 0) return;

    let i = 0;
    while (i < this.product.stock || this.product.stock == -1) {
      var user = users[this.getRandomInt(users.length)];

      user.quantityInput.setValue(user.quantityInput.value + 1);
      user.selected = true;

      if (
        user.quantityInput.value >= user.quantity ||
        (this.product.maximumQuantityPerPerson != -1 &&
          user.quantityInput.value >= this.product.maximumQuantityPerPerson) ||
        (user.quantityInput.value + 1) * this.product.price > user.timeBalance
      ) {
        users.splice(
          users.findIndex((u) => u.uuId == user.uuId),
          1,
        );
      }

      if (users.length == 0) {
        break;
      }

      i++;
    }

    this.candidates.forEach(
      (user) => (user.quantity = user.quantityInput.value),
    );
  }

  private getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  get numberOfRedeemUsers(): number {
    return [...new Set(this.redeems.map((redeem) => redeem.userUUId))].length;
  }

  get pages(): number {
    return Math.ceil(this.redeems.length / this.filters.itemsPerPage);
  }
}
