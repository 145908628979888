import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateLivingModel } from "./update-living-model";

export interface ILiving {
    uuId: string;
    name: string;
    type: AddressType
}

export class Living {
    uuId: string;
    name: string;
    type: AddressType;

    editing: boolean;
    selected: boolean;
    form: FormGroup;

    public constructor(living: ILiving) {
        this.uuId = living.uuId;
        this.name = living.name;
        this.type = living.type;
        this.editing = false;
        this.selected = false;
        this.form = new FormGroup({
            name: new FormControl(living.name, [Validators.required]),
            type: new FormControl(living.type, [Validators.required])
        });
    }

    getUpdateLivingModel(): UpdateLivingModel {
        return {
            uuId: this.uuId,
            name: this.form.controls['name'].value,
            type: this.form.controls['type'].value
        }
    }
}

export enum AddressType {
    Country,
    Region,
    District,
    SubDistrict,
    Street,
    StreetNumber,
    Estate,
    Block,
    Floor,
    Room
}