<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>後台設置</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>應用程式設置</p>
    </div>

    <p class="page-title mt-8" translate>應用程式設置</p>

    <div class="container mt-5">
        <div class="container-body">
            <form class="inline-flex-column" [formGroup]="volunteerForm">
                <p class="header-title" translate>義工個人私隱</p>
                <div class="setting-row">
                    <p class="title" translate>出生日期</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="birthday" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="birthday" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>電話號碼</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="phone" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="phone" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>地址</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="address" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="address" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>身體狀況</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="health" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 8.75rem;">
                        <input type="radio" formControlName="health" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="health" value="all">
                        <p class="text-lightgrey" translate>全部人</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>緊急聯絡人</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="contactPerson" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 8.75rem;">
                        <input type="radio" formControlName="contactPerson" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="contactPerson" value="all">
                        <p class="text-lightgrey" translate>全部人</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>教育程度</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="education" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 8.75rem;">
                        <input type="radio" formControlName="education" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="education" value="all">
                        <p class="text-lightgrey" translate>全部人</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>配對後電話號碼</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="phoneVisibility" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 8.75rem;">
                        <input type="radio" formControlName="phoneVisibility" value="user">
                        <p class="text-lightgrey" translate>對相關義工可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="phoneVisibility" value="all">
                        <p class="text-lightgrey" translate>對受助者以及相關義工可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>邀請義工名單</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="list" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 8.75rem;">
                        <input type="radio" formControlName="list" value="user">
                        <p class="text-lightgrey" translate>對受助者可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="list" value="all">
                        <p class="text-lightgrey" translate>受助者可見，配對後對相關義工公開</p>
                    </div>
                </div>
            </form>

            <div class="border-bottom" style="margin: 2rem 0;"></div>

            <form class="inline-flex-column" [formGroup]="requesterForm">
                <p class="header-title" translate>受助者個人私隱</p>
                <div class="setting-row">
                    <p class="title" translate>出生日期</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="birthday" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="birthday" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>電話號碼</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="phone" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="phone" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>地址</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="address" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="address" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>身體狀況</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="health" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="health" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>緊急聯絡人</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="contactPerson" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="contactPerson" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>教育程度</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="education" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="education" value="user">
                        <p class="text-lightgrey" translate>本人可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>邀請義工時詳細地址</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="addressDetail" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="addressDetail" value="user">
                        <p class="text-lightgrey" translate>對受邀請義工可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>配對義工後電話號碼</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="phoneVisibility" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="phoneVisibility" value="user">
                        <p class="text-lightgrey" translate>對相關義工可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>配對義工後緊急聯絡人</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="contactPersonVisibility" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="contactPersonVisibility" value="user">
                        <p class="text-lightgrey" translate>對相關義工可見</p>
                    </div>
                </div>
                <div class="setting-row">
                    <p class="title" translate>配對義工後代理人私隱</p>
                    <div class="inline-flex-row align-items-center" style="flex: 0 0 7rem;">
                        <input type="radio" formControlName="requesterAgentPrivacyVisibility" value="staff">
                        <p class="text-lightgrey" translate>僅職員可見</p>
                    </div>
                    <div class="inline-flex-row align-items-center">
                        <input type="radio" formControlName="requesterAgentPrivacyVisibility" value="user">
                        <p class="text-lightgrey" translate>對相關義工可見</p>
                    </div>
                </div>
            </form>

            <div class="inline-flex-row justify-content-center" style="margin-top: 1.125rem;margin-bottom: 1rem;">
                <button type="button" class="button md fill blue" (click)="save()" translate>保存</button>
            </div>
        </div>
    </div>
</div>