<form class="inline-flex-column" style="padding-left: 1.5rem;">
    <p class="text-lightgrey text-sm" translate>欄目帶 * 號為必填項</p>

    <div class="input-row">
        <p class="title required" translate>活動名目</p>
        <input class="user-input md" type="text" placeholder="{{ '活動名目' | translate }}">
    </div>

    <div class="input-row">
        <p class="title required" translate>活動類型</p>
        <div class="user-select">
            <select class="user-select xxxl">
                <option [ngValue]="null" selected disabled translate>請選擇活動類型</option>
            </select>
        </div>
    </div>

    <div class="input-row">
        <p class="title required align-self-start lh-8" translate>交易類型</p>
        <div class="inline-flex-column">
            <div class="inline-flex-row align-items-center">
                <label>
                    <input type="radio" value="Free" name="template">
                    <p class="lh-8" translate>免費活動</p>
                </label>

                <label class="ml-8">
                    <input type="radio" value="UserSpend" name="template">
                    <p class="lh-8" translate>用户扣分</p>
                </label>

                <label class="ml-8">
                    <input type="radio" value="UserEarn" name="template">
                    <p class="lh-8" translate>用户加分</p>
                </label>
            </div>
        </div>

    </div>

    <div class="input-row">
        <p class="title required" translate>參與方式</p>
        <label>
            <input type="radio" value="FIFJ">
            <p translate>先到先得</p>
        </label>

        <label class="ml-8">
            <input type="radio" value="Staff">
            <p translate>職員處理</p>
        </label>
    </div>

    <div class="input-row">
        <p class="title required align-self-start lh-8" translate>活動日期</p>

        <input matInput class="user-input md" [matDatepicker]="picker2" [min]="minDate" (click)="picker2.open()" placeholder="{{ '活動日期' | translate }}" readonly>
        <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>

        <p class="click-text-blue ml-9" translate>多日期活動設置</p>
    </div>

    <div class="input-row">
        <p class="title required" translate>截止報名</p>
        <input matInput class="user-input md" [matDatepicker]="picker" [min]="minDate" (click)="picker.open()" placeholder="{{ '截止報名' | translate }}" readonly>
        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>

        <app-time-picker class="ml-9" [form]="timeForm"></app-time-picker>
    </div>

    <div class="input-row">
        <p class="title" translate>開始時間</p>
        <app-time-picker [form]="timeForm"></app-time-picker>
    </div>

    <div class="input-row">
        <p class="title" translate>結束時間</p>
        <app-time-picker [form]="timeForm"></app-time-picker>
    </div>

    <div class="input-row">
        <p class="title required" translate>參與者數量</p>
        <input class="user-input md" type="number">
    </div>

    <div class="input-row">
        <p class="title align-self-start lh-8" translate>活動詳情</p>
        <textarea style="width: 22rem;height: 8.625rem;"></textarea>
    </div>

    <div class="input-row">
        <p class="title required align-self-start lh-8" translate>邀請會員</p>
        <div class="inline-flex-column">
            <p class="text-lightgrey lh-8" translate>未指定，請在會員列表中確認人選</p>
            <button type="button" class="button outline blue sm" translate>會員列表</button>
        </div>
    </div>

    <div class="input-row">
        <p class="title align-self-start lh-8" translate>備註</p>
        <textarea style="width: 22rem;height: 8.625rem;"></textarea>
    </div>

    <div class="input-row" *ngFor="let column of columns">
        <p class="title">{{ column.columnName }}</p>
        <input class="user-input sm" type="text" [placeholder]="column.columnDescription">
        <label class="margin-left: 2rem;align-items-center">
            <input class="ml-6 mr-4"  type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="!column.display" (change)="changeDisplay(column.uuId)">
            <p translate>會員不可見</p>
        </label>
        <i class="material-icons-round ml-4 text-lg" style="color: #F5222D;cursor: pointer;" (click)="removeColumn(column.uuId)">remove_circle</i>

    </div>

    <div class="input-row" style="cursor: pointer;" *ngIf="!showColumnForm;else ColumnForm" (click)="showColumnForm = !showColumnForm">
        <i class="material-icons-round text-lg text-lightblue">add_circle</i>
        <p class="text-lightblue ml-4" translate>增加自訂義框</p>
    </div>

    <ng-template #ColumnForm>
        <div class="input-row">
            <div class="template-div">
                <input type="text" class="template-input" [formControl]="columnName" placeholder="{{ '標題名稱' | translate }}">
            </div>
            <div class="template-div" style="margin-left: 2rem;">
                <input type="text" class="template-input lg" [formControl]="columnDescription" placeholder="{{ '描述文字' | translate }}">
            </div>

            <label class="align-items-center">
                <input class="ml-6 mr-4" type="checkbox" [(ngModel)]="hideUser" [ngModelOptions]="{standalone: true}">
                <p translate>會員不可見</p>
            </label>

            <p class="click-text-lightblue text-sm ml-8" (click)="saveColumn()" translate>保存</p>
            <i class="material-icons-round ml-8 text-lg" style="color: #F5222D;cursor: pointer;" (click)="resetForm()">remove_circle</i>
            <i class="material-icons-round ml-8 text-sm text-lightblue">error_outline</i>
            <p class="text-lightgrey text-sm ml-4" translate>注意，保存後不可刪除</p>
        </div>
    </ng-template>
</form>

<div class="inline-flex-row align-items-center align-self-center" style="margin-top: 4.6875rem;margin-bottom: 2rem;">
    <button type="button" class="button outline disable-grey" translate>取消創建</button>
    <button type="button" class="button outline disable-grey" style="margin-left: 9.375rem;" translate>提 交</button>
</div>