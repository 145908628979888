<ng-container *ngTemplateOutlet="pagination"></ng-container>

<div class="user-table mt-2">
    <div class="user-table-row header">
        <div class="user-table-cell" style="flex-basis: 8.25rem; flex-grow: 66;">
            <p translate>會員編號</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p translate>中文姓名</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p translate>暱稱</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p translate>中心代碼</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
            <p translate>性別</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
            <p translate>年齡</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
            <p translate>電話</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
            <p translate>狀態</p>
        </div>

        <div class="user-table-cell" *ngIf="request.requestStatus == 'Finished'" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p translate>交易時分</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
            <p translate>操作</p>
        </div>
    </div>

    <div class="user-table-row" *ngFor="let volunteer of displayedVolunteers">
        <div class="user-table-cell" style="flex-basis: 8.25rem; flex-grow: 66;">
            <p translate>{{ volunteer.memberId }}</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p>{{ volunteer.chineseName }}</p>
        </div>

        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p>{{ volunteer.englishName }}</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p>{{ volunteer.center.centerCode }}</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
            <p>{{ volunteer.gender | translate }}</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
            <p>{{ volunteer.age }}</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
            <p>{{ volunteer.phone }}</p>
            <p *ngIf="!volunteer.phone">-</p>
        </div>

        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
            <p *ngIf="request.requestStatus == 'Pending' && (volunteer.volunteerStatus == 'Asked' || volunteer.volunteerStatus == 'Invited')" translate>暫無回應</p>
            <p *ngIf="request.requestStatus != 'Pending' &&  (volunteer.volunteerStatus == 'Asked' || volunteer.volunteerStatus == 'Invited' || volunteer.volunteerStatus == 'Interested')" translate>沒有參與</p>
            <p class="text-yellow" *ngIf="request.requestStatus == 'Pending' && volunteer.volunteerStatus == 'Interested'" translate>接受邀請</p>
            <p class="text-green" *ngIf="request.requestStatus == 'Pending' && volunteer.volunteerStatus == 'Confirmed'" translate>已配對</p>
            <p *ngIf="request.requestStatus == 'Ready' && volunteer.volunteerStatus == 'Confirmed'" translate>未報到</p>
            <p class="text-green" *ngIf="request.requestStatus == 'Ready' && volunteer.volunteerStatus == 'CheckedIn'" translate>已報到</p>
            <p class="text-red" *ngIf="(request.requestStatus == 'Started' || request.requestStatus == 'Finished') && volunteer.volunteerStatus == 'Confirmed'" translate>缺席</p>
            <p *ngIf="request.requestStatus == 'Started' && volunteer.volunteerStatus == 'CheckedIn'" translate>進行中</p>
            <p class="text-green" *ngIf="volunteer.volunteerStatus == 'CheckedOut'" translate>已完結</p>
            <p class="text-red" *ngIf="volunteer.volunteerStatus == 'Rejected'" translate>已拒絕</p>
        </div>

        <div class="user-table-cell" [ngClass]="{editing: volunteer.transaction?.editing }" *ngIf="request.requestStatus == 'Finished'" style="flex-basis: 5.5rem;flex-grow: 44;">
            <p *ngIf="volunteer.transaction == null">-</p>
            <ng-container *ngIf="volunteer.transaction">
                <p *ngIf="volunteer.transaction.editing == false" translate>{{ volunteer.transaction.amount | number }}</p>
                <input *ngIf="volunteer.transaction.editing == true" type="number" [formControl]="volunteer.transaction.form" style="width: 100%;height: 100%;border: none;text-align: center;">
            </ng-container>
        </div>

        <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
            <ng-container *ngIf="request.requestStatus == 'Pending'">
                <p class="click-text-lightblue" [ngClass]="{'disabled': volunteer.volunteerStatus != 'Asked'}" (click)="showInterest(volunteer.userUUId)" translate>接受</p>
                <p class="click-text-lightblue ml-8" [ngClass]="{'disabled': volunteer.volunteerStatus != 'Interested' && volunteer.volunteerStatus != 'Invited'}" (click)="match(volunteer.userUUId)" translate>配對</p>
            </ng-container>

            <ng-container *ngIf="request.requestStatus != 'Pending' && request.requestStatus != 'Finished'">
                <p class=" click-text-lightblue" [ngClass]="{'disabled': volunteer.volunteerStatus != 'Confirmed'}" (click)="checkIn(volunteer.userUUId)" translate>報到</p>
            </ng-container>

            <ng-container *ngIf="request.requestStatus == 'Finished'">
                <ng-container *ngIf="volunteer.transaction == null || volunteer.transaction.editing == false">
                    <p class="click-text-lightblue" [ngClass]="{'disabled': volunteer.transaction == null}" (click)="volunteer.transaction.editing = true" translate>修改時分</p>
                    <p class="click-text-lightblue ml-8" [ngClass]="{'disabled': volunteer.transaction == null}" (click)="feedback.open(volunteer)" translate>反饋</p>
                </ng-container>

                <ng-container *ngIf="volunteer.transaction?.editing == true">
                    <p class="click-text-lightblue" (click)="editTransaction(volunteer.transaction)" translate>確認</p>
                    <p class="click-text-lightblue ml-8" (click)="volunteer.transaction.editing = false" translate>取消</p>
                </ng-container>
            </ng-container>

            <ng-container *ngIf=" request.requestStatus !='Finished'">
                <p class=" click-text-lightblue ml-8" [routerLink]="['/main/user/overview', volunteer.userUUId]" translate>檢視</p>
            </ng-container>
        </div>
    </div>

    <div class="user-table-row" *ngIf="displayedVolunteers.length == 0">
        <div class="user-table-cell">
            <p class="text-lightgrey" translate>沒有紀錄</p>
        </div>
    </div>
</div>


<div class="inline-flex-row align-items-center text-lightgrey" *ngIf="request.requestStatus == 'Finished'">
    <p class="mr-2">{{ request.requester.memberId }} {{ request.requester.chineseName }}</p>
    <p translate>總共支出</p>
    <p class="ml-2 mr-2 text-blue">{{ spending | number }}</p>
    <p translate>時分至所選會員帳戶</p>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">

        <div class="inline-flex-row" *ngIf="request.requestStatus == 'Pending'">
            <p translate>你已選擇配對 </p>
            <p class="text-lightblue ml-2">{{ request.numberOfMatchedVolunteers }}</p>
            <p>{{ '/位義工' | translate: {value: request.numberOfVolunteer} }}</p>
        </div>

        <div class="inline-flex-row" *ngIf="startToday">
            <p translate>服務尚未開始</p>
        </div>

        <div class="inline-flex-row" *ngIf="request.requestStatus != 'Pending' && request.requestStatus != 'Cancelled'">
            <p translate>已報到 </p>
            <p class="text-lightblue ml-2">{{ request.numberOfCheckedInVolunteers }}</p>
            <p>/</p>
            <p class="mr-2">{{ request.numberOfVolunteer }}</p>
            <p translate>人，最後報到時間</p>
            <p class="ml-2">{{ request.latestCheckedInTime | date: 'hh:mm aa' }}</p>
        </div>

        <div class="flex-1"></div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>

    </div>
</ng-template>

<app-request-feedback-popup #feedback></app-request-feedback-popup>