import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { DonationService } from "src/app/services/donation/donation.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { CreateCenterDonationForm } from "../../forms/create-center-donation-form";

@Component({
  selector: "app-donation-center",
  templateUrl: "./donation-center.component.html",
  styleUrls: ["./donation-center.component.scss"],
})
export class DonationCenterComponent implements OnInit {
  disabled: FormControl = new FormControl(false);
  form: CreateCenterDonationForm =
    this.donationService.createCenterDonationForm;
  input: FormControl = new FormControl(null, [
    Validators.pattern("([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)"),
  ]);

  constructor(
    private donationService: DonationService,
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.input.disable();
    this.disabled.valueChanges.subscribe((value) => {
      this.form.users.forEach((user) =>
        value ? user.amount.disable() : user.amount.enable(),
      );
      value ? this.input.enable() : this.input.disable();
    });

    this.input.valueChanges.subscribe((value) => {
      this.form.users.forEach((user) => user.amount.setValue(value));
    });
  }

  submit(): void {
    if (this.form.users.length == 0) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.NoRecordSelectedMessage,
      );
      return;
    }

    this.form.users.forEach((user) => user.amount.enable());

    if (!this.form.users.every((user) => user.amount.valid)) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      this.input.markAllAsTouched();
      this.form.users.forEach((user) => {
        user.amount.markAllAsTouched();
        if (this.disabled.value) user.amount.disable();
      });
      return;
    }

    this.loadingService.startLoading();
    this.donationService
      .donateToUsers(this.form.getCreateCenterDonationModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.DonateToUsersSuccessMessage,
          );
          this.reset();
        },
      });
  }

  reset(): void {
    this.form.reset();
  }

  drop(index: number): void {
    this.form.users.splice(index, 1);
  }

  get sum(): number {
    return this.form.users
      .map((user) => user.amount.value as number)
      .reduce((sum, v) => (sum += v), 0);
  }
}
