import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { concat, Observable } from "rxjs";
import { Center } from "src/app/models/centers/center";
import { CenterService } from "src/app/services/center/center.service";
import { OrganizationService } from "src/app/services/organization/organization.service";

@Component({
  selector: "app-initialize-popup",
  templateUrl: "./initialize-popup.component.html",
  styleUrls: ["./initialize-popup.component.scss"],
})
export class InitializePopupComponent implements OnInit {
  public display: boolean = false;
  public centers: Center[] = [];

  public logoUrl: string | null = null;

  public OrganizationFormGroup: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(18),
    ]),
    logo: new FormControl(null, [Validators.required]),
    useDefaultLogo: new FormControl(false),
  });

  public UnitFormGroup: FormGroup = new FormGroup({
    unit: new FormControl(null, [Validators.required]),
  });

  public CenterFormGroup: FormGroup = new FormGroup({
    centerCode: new FormControl(null, [
      Validators.required,
      Validators.pattern("[A-Z]*[a-z]*[0-9]*"),
    ]),
    centerName: new FormControl(null, [Validators.required]),
    centerContact: new FormControl(null, [Validators.required]),
  });

  public InviteSettingFormGroup: FormGroup = new FormGroup({
    directInvitation: new FormControl(null, [Validators.required]),
  });

  public RequestSettingFormGroup: FormGroup = new FormGroup({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
  });

  public currentSlide: number = 0;

  constructor(
    private organizationService: OrganizationService,
    private centerService: CenterService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.getCenters();
  }

  public onImageImport(files: FileList): void {
    this.OrganizationFormGroup.controls["useDefaultLogo"].setValue(false);
    this.OrganizationFormGroup.get("logo")?.setValue(files[0]);

    this.processLogoImage(
      this.OrganizationFormGroup.get("logo")?.value,
    ).subscribe({
      next: (response) => {
        this.logoUrl = response;
      },
      complete: () => {},
    });
  }

  public useDefaultImage(): void {
    if (this.OrganizationFormGroup.controls["useDefaultLogo"].value == false) {
      this.OrganizationFormGroup.controls["logo"].reset();
      return;
    }

    this.getDefaultLogo().subscribe((v) => {
      this.processLogoImage(v).subscribe({
        next: (response) => {
          this.logoUrl = response;
        },
        complete: () => {},
      });
    });
  }

  public show(): void {
    this.display = true;
  }

  public hide(): void {
    this.display = false;
  }

  public confirm(): void {
    var formData: FormData = new FormData();
    formData.append("uuId", this.centers[0].uuId);
    formData.append(
      "centerCode",
      this.CenterFormGroup.controls["centerCode"].value,
    );
    formData.append("name", this.CenterFormGroup.controls["centerName"].value);
    formData.append(
      "phoneNumber",
      this.CenterFormGroup.controls["centerContact"].value,
    );
    formData.append("OtherContactInfo", "");
    concat(
      this.organizationService.setOrganizationNameAndPortal(
        this.OrganizationFormGroup.get("name")?.value,
        this.logoUrl as string,
      ),
      this.organizationService.setOrganizationSetting({
        MinTimePerUnit: this.unit === "hour" ? 1 : 15,
        TimePerMark: this.unit === "hour" ? 60 : 1,
        DirectConfirm: this.directInvitation,
        StartRole: this.serviceStartSetting ? "volunteer" : "requester",
        EndRole: this.serviceEndSetting ? "volunteer" : "requester",
      }),
      this.centerService.updateCenter(formData),
    ).subscribe({
      next: (response) => {
        this.hide();
      },
      error: (error) => {},
    });
  }

  get logoName(): string {
    return this.OrganizationFormGroup.get("logo")?.value?.name;
  }

  get organizationName(): string {
    return this.OrganizationFormGroup.get("name")?.value;
  }

  get organizationFormValid(): boolean {
    return (
      this.OrganizationFormGroup.get("name")?.valid &&
      (this.OrganizationFormGroup.get("useDefaultLogo")?.value ||
        this.OrganizationFormGroup.valid)
    );
  }

  get unit(): string {
    return this.UnitFormGroup.get("unit")?.value;
  }

  get centerInfo(): string {
    return (
      this.CenterFormGroup.get("centerCode")?.value +
      " " +
      this.CenterFormGroup.get("centerName")?.value +
      " " +
      this.CenterFormGroup.get("centerContact")?.value
    );
  }

  get directInvitation(): boolean {
    return this.InviteSettingFormGroup.get("directInvitation")?.value;
  }

  get serviceStartSetting(): boolean {
    return this.RequestSettingFormGroup.get("start")?.value;
  }

  get serviceEndSetting(): boolean {
    return this.RequestSettingFormGroup.get("end")?.value;
  }

  private processLogoImage(logo: File): Observable<any> {
    return new Observable((subscriber) => {
      if (logo == null) {
        subscriber.complete();
        return;
      }
      var fileReader: FileReader = new FileReader();
      fileReader.onload = function (e) {
        subscriber.next(e.target?.result);
      };
      fileReader.readAsDataURL(logo);
    });
  }

  private getDefaultLogo(): Observable<any> {
    return this.http.get("/v2/assets/default-logo.png", {
      responseType: "blob",
    });
  }

  private getCenters(): void {
    this.centerService.getCenters().subscribe({
      next: (value) => (this.centers = value.map((v) => new Center(v))),
    });
  }
}
