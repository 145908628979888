import { Address, IAddress } from "../addresses/address";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface ISignUpUserListItem {
  uuId: string;
  chineseName: string;
  englishName: string;
  gender: string;
  dateOfBirth: Date;
  phone: string;
  address: IAddress;
  signUpAt: Date;
  status: string;
}

export class SignUpUserListItem {
  uuId: string;
  chineseName: string;
  englishName: string;
  gender: string;
  dateOfBirth: Date;
  phone: string;
  address: Address;
  signUpAt: Date;
  status: string;

  selected: boolean;

  constructor(user: ISignUpUserListItem) {
    this.uuId = user.uuId;
    this.chineseName = user.chineseName;
    this.englishName = user.englishName;
    this.gender = user.gender;
    this.dateOfBirth = dayjs.utc(user.dateOfBirth).toDate();
    this.phone = user.phone;
    this.address = new Address(user.address);
    this.signUpAt = dayjs.utc(user.signUpAt).toDate();
    this.status = user.status;
    this.selected = false;
  }

  get age(): number {
    return dayjs().diff(this.dateOfBirth, "years");
  }

  get signUpOverTime(): boolean {
    return (
      this.status == "Pending" && dayjs().diff(this.signUpAt, "days", true) > 14
    );
  }
}
