import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-bin-page',
  templateUrl: './bin-page.component.html',
  styleUrls: ['./bin-page.component.scss'],
  animations: [PortalAnimation.TrashPageSlide]
})
export class BinPageComponent implements OnInit {


  tab: string = "request";

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.router.url == "/main/trash/event")
      this.tab = "event";
    if (this.router.url == "/main/trash/user")
      this.tab = "user";
    if (this.router.url == "/main/trash/donation")
      this.tab = "donation";
    if (this.router.url == "/main/trash/redeem")
      this.tab = "redeem";
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }

}
