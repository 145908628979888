import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AppSetting } from "../backend/app-setting";
import { AuthenticationService } from "../services/authenticate/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authenticationService.isAuthenticated() == false) {
      localStorage.removeItem(AppSetting.tokenKey);
      localStorage.removeItem(AppSetting.roleKey);
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }
}
