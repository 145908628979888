import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-redeem-image-popup",
  templateUrl: "./redeem-image-popup.component.html",
  styleUrls: ["./redeem-image-popup.component.scss"],
})
export class RedeemImagePopupComponent implements OnInit {
  @ViewChild("image") image!: ElementRef;
  @Input() action: (image: Blob) => void = () => {};

  display: boolean = false;
  file: Blob | null = null;
  uploadFile: Blob | null = null;

  constructor(private popupMessageService: PopupMessageService) {}

  ngOnInit(): void {}

  onImageUpload(files: FileList) {
    Array.from(files).forEach((file) => {
      if (
        file.type != "image/png" &&
        file.type != "image/jpeg" &&
        file.type != "image/gif"
      ) {
        return;
      }

      if (file.size > 2097152) {
        return;
      }

      this.file = file;
      this.uploadFile = file;
      (this.image.nativeElement as HTMLImageElement).src = URL.createObjectURL(
        this.file,
      );
    });
  }

  delete(): void {
    this.file = null;
    this.uploadFile = null;
  }

  open(image?: Blob): void {
    this.display = true;
    if (image != null) {
      this.file = image;
      setTimeout(
        () =>
          ((this.image.nativeElement as HTMLImageElement).src =
            URL.createObjectURL(this.file)),
      );
    }
  }

  close() {
    this.action(this.file as Blob);
    this.display = false;
  }
}
