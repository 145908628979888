import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/backend/http.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(
    private httpService: HttpService
  ) { }

  public assignAgent(model: { AssigneeUUId: string, AgentUUId: string, Remark: string }): Observable<any> {
    return this.httpService.post<any, { AssigneeUUId: string, AgentUUId: string, Remark: string }>("agents/assign", model);
  }

  public removeAgent(model: { AssigneeUUId: string, AgentUUId: string }): Observable<any> {
    return this.httpService.put<any, { AssigneeUUId: string, AgentUUId: string }>("agents/remove", model);
  }
}
