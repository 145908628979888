<div class="analytics-container">
    <div class="inline-flex-row justify-content-end border-bottom" style="padding: 0.5rem 1rem;">
        <i matTooltip="{{ '總交易時分 - 義工服務和中心活動加起來的總交易時分。' | translate }}" [matTooltipPosition]="'above'" class="material-icons-outlined text-lightgrey" style="font-size: 1rem">info</i>
    </div>

    <div class="inline-flex-row flex-1">
        <div class="inline-flex-column align-items-center justify-content-center flex-1">
            <p style="font-size: 1rem;line-height: 1.25rem;" translate>總交易時分</p>
            <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ totalTimeBalance | number : '1.0-0'}}</p>
            <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: averageTimeBalance | number : '1.0-2'} }}</p>
        </div>
        <div style="border-right: 1px solid #E8E8E8;"></div>
        <div class="inline-flex-column flex-1">
            <div class="inline-flex-column justify-content-center align-items-center flex-1 border-bottom">
                <p style="font-size: 1rem;" translate>義工服務總交易時分</p>
                <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ requestTotalTimeBalance | number : '1.0-0' }}</p>
                <p class="text-sm mt-4 text-lightgrey">{{ '義工服務平均交易時分' | translate: {value: requestAverageTimeBalance | number : '1.0-2'} }}</p>
            </div>
            <div class="inline-flex-column justify-content-center align-items-center flex-1">
                <p style="font-size: 1rem;" translate>中心活動總交易時分</p>
                <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ eventTotalTimeBalance | number : '1.0-0' }}</p>
                <p class="text-sm mt-4 text-lightgrey">{{ '中心活動平均交易時分' | translate: {value: eventAverageTimeBalance | number : '1.0-2'} }}</p>
            </div>
        </div>
    </div>
</div>