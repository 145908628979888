import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { InitializePopupComponent } from '../../components/initialize-popup/initialize-popup.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  @ViewChild("init") init!: InitializePopupComponent;
  constructor(
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.getOrganizationSetting();
  }

  public getOrganizationSetting(): void {
    this.organizationService.getOrganizationSetting()
      .subscribe({
        next: value => {
          if (value == null)
            this.init.show();
        }
      });
  }
}
