<div class="box">
    <p class="title" translate>可提供服務</p>
    <div class="inline-flex-column">
        <form class="input-row" style="min-width: 36.875rem;" [formGroup]="form">
            <div class="user-select">
                <select class="user-select lg" formControlName="type">
                    <option [ngValue]="null" selected disabled translate>可提供服務類型</option>
                    <option [ngValue]="serviceType.uuId" *ngFor="let serviceType of serviceTypes" translate>{{ serviceType.name }}</option>
                </select>
            </div>
            <input type="text" class="user-input xxs" style="margin-left: 1.5625rem;" formControlName="code" placeholder="{{ '服務編號' | translate }}">
            <input type="text" class="user-input lg ml-4" style="border-radius: 4px 0 0 4px;" formControlName="name" placeholder="{{ '可提供服務名稱' | translate }}">
            <app-emoji-picker [form]="form.controls['name']"></app-emoji-picker>
            <label class="inline-flex-row align-items-center ml-4">
                <input type="checkbox" formControlName="destination">
                <p class="text-grey ml-2" translate>需要目的地</p>
            </label>
            <p class="click-text-blue text-xs ml-8" (click)="create()" translate>保存</p>
        </form>

        <div class="service-box">
            <div class="service-type-column">
                <div class="service-type-item mb-2" [ngClass]="{active: index == i}" *ngFor="let serviceType of serviceTypes; let i = index" (click)="index = i">
                    <p>{{ serviceType.name }}</p>
                    <p>{{ serviceType.services.length }}</p>
                </div>
            </div>

            <div class="service-column">
                <ng-container *ngIf="selectedServiceType">
                    <div class="service-item mb-2" *ngFor="let service of selectedServiceType.services">
                        <ng-container *ngIf="service.editing;then edit;else read"></ng-container>
                        <ng-template #read>
                            <p class="code">{{ service.servicesCode }}</p>
                            <p class="name ml-4">{{ service.name }}</p>
                            <label class="inline-flex-row align-items-center ml-4">
                                <input type="checkbox" [ngModel]="service.destinationNeeded" disabled>
                                <p class="text-grey ml-2" translate>需要目的地</p>
                            </label>
                            <i class="material-icons-outlined create-icon ml-8" [ngClass]="{'red': service.enable, 'disabled': service.enable == false}" (click)="disableService(service)">block</i>
                            <i class="click-text-lightblue material-icons-outlined create-icon ml-8" *ngIf="service.enable == false" (click)="enableService(service)">refresh</i>
                            <i class="click-text-lightblue material-icons-outlined create-icon ml-8" *ngIf="service.enable == true" (click)="service.editing = true">create</i>
                        </ng-template>
                        <ng-template #edit>
                            <form class="inline-flex-row align-items-center" [formGroup]="service.form">
                                <input type="text" class="user-input xxs" formControlName="code" placeholder="{{ '服務編號' | translate }}">
                                <input type="text" class="user-input lg ml-4" style="border-radius: 4px 0 0 4px;" formControlName="name" placeholder="{{ '可提供服務名稱' | translate }}">
                                <app-emoji-picker [form]="service.form.controls['name']"></app-emoji-picker>
                                <label class="inline-flex-row align-items-center ml-4">
                                    <input type="checkbox" formControlName="destinationNeeded">
                                    <p class="text-grey ml-2" translate>需要目的地</p>
                                </label>
                                <p class="click-text-blue text-xs ml-8" (click)="update(service)" translate>保存</p>
                                <p class="click-text-blue text-xs ml-4" (click)="service.editing = false" translate>取消</p>
                            </form>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>