<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>會員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>會員概覽</p>
    </div>

    <p class="page-title mt-8" *ngIf="profile">{{ profile.chineseName }} {{ profile.englishName }}</p>

    <div class="container mt-5">
        <div class="container-header">
            <p class="container-header-tab" routerLink="profile" routerLinkActive="active" translate>個人檔案</p>
            <p class="container-header-tab" routerLink="transaction" routerLinkActive="active" translate>交易紀錄</p>
        </div>
        <div class="container-body">
            <div class="inline-flex-column" [@slide]="prepareRoute(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </div>

</div>