import { IAddressComponent } from "../addresses/address";
import { Center, ICenter } from "../centers/center";
import { ITransaction, Transaction } from "../transactions/transaction";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IVolunteer {
  birthday: string;
  center: ICenter;
  checkedInTime: string;
  checkedOutTime: string;
  chineseName: string;
  defaultDistrict: IAddressComponent;
  defaultRegion: IAddressComponent;
  district: string;
  gender: string;
  hasMobilePhone: boolean;
  memberId: string;
  phone: string;
  requesterComment: string;
  requesterCommentCreatedBy: string;
  isRequesterComment: boolean;
  requesterRating: number;
  timeBalance: number;
  transaction: ITransaction;
  userUUId: string;
  volunteerComment: string;
  volunteerCommentCreatedBy: string;
  isVolunteerComment: boolean;
  volunteerRating: number;
  volunteerStatus: string;
}

export class Volunteer {
  birthday: Date;
  center: ICenter;
  checkedInTime: Date | null;
  checkedOutTime: Date | null;
  chineseName: string;
  defaultDistrict: IAddressComponent;
  defaultRegion: IAddressComponent;
  district: string;
  gender: string;
  hasMobilePhone: boolean;
  memberId: string;
  phone: string;
  requesterComment: string;
  requesterCommentCreatedBy: string;
  requesterRating: number;
  timeBalance: number;
  transaction: Transaction | null;
  userUUId: string;
  volunteerComment: string;
  volunteerCommentCreatedBy: string;
  volunteerRating: number;
  volunteerStatus: string;
  isVolunteerComment: boolean;
  isRequesterComment: boolean;

  //ISelectable
  selected: boolean;

  constructor(volunteer: IVolunteer) {
    this.birthday = dayjs.utc(volunteer.birthday).toDate();
    this.center = new Center(volunteer.center);
    this.checkedInTime = volunteer.checkedInTime
      ? dayjs.utc(volunteer.checkedInTime).toDate()
      : null;
    this.checkedOutTime = volunteer.checkedOutTime
      ? dayjs.utc(volunteer.checkedOutTime).toDate()
      : null;
    this.chineseName = volunteer.chineseName;
    this.defaultDistrict = volunteer.defaultDistrict;
    this.defaultRegion = volunteer.defaultRegion;
    this.district = volunteer.district;
    this.gender = volunteer.gender;
    this.hasMobilePhone = volunteer.hasMobilePhone;
    this.memberId = volunteer.memberId;
    this.phone = volunteer.phone;
    this.requesterComment = volunteer.requesterComment;
    this.requesterCommentCreatedBy = volunteer.requesterCommentCreatedBy;
    this.requesterRating = volunteer.requesterRating;
    this.timeBalance = volunteer.timeBalance;
    this.transaction = volunteer.transaction
      ? new Transaction(volunteer.transaction)
      : null;
    this.userUUId = volunteer.userUUId;
    this.volunteerComment = volunteer.volunteerComment;
    this.volunteerCommentCreatedBy = volunteer.volunteerCommentCreatedBy;
    this.volunteerRating = volunteer.volunteerRating;
    this.volunteerStatus = volunteer.volunteerStatus;
    this.selected = false;
    this.isVolunteerComment = volunteer.isVolunteerComment;
    this.isRequesterComment = volunteer.isRequesterComment;
  }

  get age(): number {
    return dayjs().diff(this.birthday, "years");
  }
}
