import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RequestService } from "src/app/services/request/request.service";

@Component({
  selector: "app-request-terminate-popup",
  templateUrl: "./request-terminate-popup.component.html",
  styleUrls: ["./request-terminate-popup.component.scss"],
})
export class RequestTerminatePopupComponent implements OnInit {
  @Input() reloadAction: () => void = () => {};

  public display: boolean = false;
  public reasons: [string, boolean][] = [
    ["受助者日程有變", false],
    ["義工不足", false],
    ["更改申請內容", false],
    ["自訂原因", false],
  ];
  public reasonText: string | null = null;

  constructor(
    private loadingService: LoadingService,
    private requestService: RequestService,
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {}

  cancelRequest(): void {
    this.route.params.subscribe((value) => {
      var customReason: [string, boolean] = this.reasons.slice(
        this.reasons.length - 1,
        this.reasons.length,
      )[0];
      var model: { CancelRequestReason: string } = {
        CancelRequestReason: this.reasons.some((reason) => reason[1])
          ? this.reasons
              .slice(0, this.reasons.length - 1)
              .filter((reason) => reason[1])
              .map((reason) => reason[0])
              .concat()
              .join() + (customReason[1] ? "," + this.reasonText : "")
          : customReason[1]
            ? "," + this.reasonText
            : "",
      };

      this.loadingService.startLoading();
      this.requestService
        .cancelRequest(value.id, model)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.close();
          this.popupMessageService.messageSignal.emit(
            PopupMessages.CancelRequestSuccessMessage,
          );
          this.reloadAction();
        });
    });
  }

  open() {
    this.display = true;
  }

  close(): void {
    this.reasons.forEach((reason) => (reason[1] = false));
    this.reasonText = null;
    this.display = false;
  }
}
