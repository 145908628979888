<div class="box">
    <p class="title" matTooltip="{{ '可提供服務類別' | translate }}" [matTooltipPosition]="'above'" translate>可提供服務類別</p>
    <div class="inline-flex-column">

        <form class="input-row" style="min-width: 36.875rem;" [formGroup]="form">
            <input type="text" class="user-input lg" style="border-radius: 4px 0 0 4px;" formControlName="name" placeholder="{{ '不超過8個文字' | translate }}">
            <app-emoji-picker [form]="form.controls['name']"></app-emoji-picker>
            <p class="click-text-blue text-xs ml-4" (click)="create()" translate>保存</p>
        </form>

        <div class="service-type-box">
            <div class="service-type-item" *ngFor="let serviceType of serviceTypes">
                <ng-container *ngIf="serviceType.editing == false;else editing">
                    <p class="name">{{ serviceType.name }}</p>
                    <i class="click-text-lightblue material-icons-outlined create-icon" (click)="serviceType.editing = true">create</i>
                </ng-container>
                <ng-template #editing>
                    <form class="inline-flex-row align-items-center" [formGroup]="serviceType.form">
                        <input type="text" class="user-input lg" style="border-radius: 4px 0 0 4px;" formControlName="name" placeholder="{{ '不超過8個文字' | translate }}">
                        <app-emoji-picker [form]="serviceType.form.controls['name']"></app-emoji-picker>
                        <p class="click-text-blue text-xs ml-4" (click)="update(serviceType)" translate>保存</p>
                        <p class="click-text-blue text-xs ml-4" (click)="serviceType.editing = false" translate>取消</p>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>