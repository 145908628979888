<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedStatuses > 0}" (click)="toggle()">
    <i style="transform: scaleX(-1);" class="material-icons-outlined filter-icon" *ngIf="numberOfSelectedStatuses == 0">local_offer</i>
    <p class="filter-number" *ngIf="numberOfSelectedStatuses > 0">{{ numberOfSelectedStatuses }}</p>
    <p translate>狀態</p>
    <div class="filter-body center" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-6">
                <p class="text-bold flex-1" style="color: #5A5A5A;" translate>狀態</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">
                <label class="checkbox mb-4" *ngFor="let status of statuses">
                    <input class="mr-8" type="checkbox" [(ngModel)]="status.selected">
                    <p>{{ status.name | translate }}</p>
                </label>
            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>