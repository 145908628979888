import { Component, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-activity-overview-page",
  templateUrl: "./activity-overview-page.component.html",
  styleUrls: ["./activity-overview-page.component.scss"],
  animations: [PortalAnimation.ActivityPageSlide],
})
export class ActivityOverviewPageComponent implements OnInit {
  tab: string = "request";

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      if (this.router.url == "/main/activity/overview/event") {
        this.tab = "event";
      } else {
        this.tab = "request";
      }
    });
  }

  ngOnInit(): void {}

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animationState"]
    );
  }
}
