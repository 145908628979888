<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="import-popup zoomIn" (click)="$event.stopPropagation()">
        <div class="import-popup-header">
            <p class="flex-1" style="font-size: 1rem;line-height: 1.5rem;" translate>批量更新/創建</p>
            <i class="material-icons-round text-lightgrey" style="cursor: pointer;font-size: 1.25rem;" (click)="close()">close</i>
        </div>
        <div class="import-popup-body">
            <div class="upload-box" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (click)="fileInput.click()">
                <i class="material-icons-round text-lightblue" style="font-size: 4rem;">unarchive</i>
                <p class="mt-8" translate>點擊或將文件拖拽到這裡上傳</p>
                <p class="text-lightgrey mt-4" translate>支持擴展名：.xlsx .xls</p>
            </div>

            <div class="inline-flex-row mt-4" *ngFor="let file of files">
                <i class="material-icons-round text-lightgrey" style="font-size: 1rem;">attach_file</i>
                <div class="inline-flex-column flex-1">
                    <p class="text-lightgrey">{{ file.file.name }}</p>
                    <div *ngIf="!file.error;else error">
                        <mat-progress-bar *ngIf="!file.uploadResult" mode="buffer"></mat-progress-bar>
                        <mat-progress-bar *ngIf="file.uploadResult" mode="determinate" value="100"></mat-progress-bar>
                    </div>
                    <ng-template #error>
                        <mat-progress-bar color="warn" mode="determinate" value="100"></mat-progress-bar>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="import-popup-footer">
            <p class="click-text-lightblue" (click)="exportSample()" translate>示範格式</p>
            <div class="flex-1"></div>
            <button class="button xs outline" (click)="close()" translate>取 消</button>
            <button class="button xs fill blue ml-4" (click)="confirm()" translate>確 定</button>
        </div>
    </div>
</div>

<input type="file" style="display: none;" (change)="onFileImport($event.target.files);fileInput.value = ''" #fileInput>