import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { Center } from "src/app/models/centers/center";
import { CenterService } from "src/app/services/center/center.service";

@Component({
  selector: "app-center-setting",
  templateUrl: "./center-setting.component.html",
  styleUrls: ["./center-setting.component.scss"],
})
export class CenterSettingComponent implements OnInit {
  centers: Center[] = [];
  loading: boolean = true;

  form: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
  });

  constructor(private centerService: CenterService) {}

  ngOnInit(): void {
    setTimeout(() => this.fetch(), 500);
  }

  fetch(): void {
    this.loading = true;
    this.centerService
      .getCenters()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.centers = value;
        },
      });
  }

  refresh() {
    this.fetch();
    this.form.reset();
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    var form: FormData = new FormData();
    form.append("centerCode", this.form.controls["code"].value);
    form.append("phoneNumber", this.form.controls["phone"].value);
    form.append("name", this.form.controls["name"].value);
    this.centerService
      .createCenter(form)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
          this.form.reset();
        },
      });
  }
}
