import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { CreateHealthModel } from 'src/app/models/healths/create-health-model';
import { Health, IHealth } from 'src/app/models/healths/health';
import { UpdateHealthModel } from 'src/app/models/healths/update-health-model';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(
    private httpService: HttpService
  ) { }

  public getHealthConditionList(): Observable<Health[]> {
    return this.httpService.get<IHealth[]>("healthConditions")
      .pipe(map(value => value.result.map(health => new Health(health)).sort(HealthSorting)))
  }

  public createHealthCondition(model: CreateHealthModel): Observable<any> {
    return this.httpService.post<any, CreateHealthModel>("healthConditions", model);
  }

  public updateHealthCondition(model: UpdateHealthModel): Observable<any> {
    return this.httpService.put<any, UpdateHealthModel>("healthConditions", model);
  }
}

function HealthSorting(a: Health, b: Health): number {
  return a.name.localeCompare(b.name, 'en', { numeric: true })
}
