<div class="analysis-container">
  <div class="analysis-container-header">
    <div>
      <p class="text-bold" style="margin-bottom: 1rem;" translate>捐分</p>
    </div>

    <div class="mt-8" style="display: inline-flex; flex-direction: row;">
      <div class="inline-flex-row startline" style="height: 100%; align-items: center;">
        <p class="data-type-button" [ngClass]="{active: dataType == 'Month'}" (click)="dataType='Month'; setDataType();"
          translate>本月</p>
        <p class="data-type-button" [ngClass]="{active: dataType == 'Year'}" (click)="dataType='Year'; setDataType();"
          translate>全年</p>
      </div>

      <div class="inline-flex-row endinline" style="position:relative">
        <input class="user-input xl" [ngModel]="dateRange" (click)="dataType=''; picker.open()"
          placeholder="{{placeholder}}" readonly>
        <ng-container *ngTemplateOutlet="datepicker"></ng-container>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        <mat-date-range-input style="display: none;" [rangePicker]="picker">
          <input matStartDate [formControl]="start">
          <input matEndDate [formControl]="end" (dateInput)="fetch()">
        </mat-date-range-input>
      </div>
    </div>
  </div>

  <div class="analysis-container-body">
    <div
      style="display: inline-flex; flex-direction: row; align-items: center; position: relative; justify-content: center;">
      <div #graphLabel class="inline-flex-column align-items-center" style="position: absolute;">
        <p class="text-lightgrey" translate>總捐分累計</p>
        <p class="mt-4" style="font-size: 1.875rem; line-height: 1.875rem;">{{ total }}</p>
      </div>
      <div style="width: 400px; height: 250px;">
        <canvas #graph></canvas>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; padding: 1rem 0.25rem 0 0.25rem; height: 100%;"
      *ngIf="donationValue != null">
      <div style="height: 100%;">
        <p class="text-wrap-bottom" translate>中心捐分累計</p>
        <p class="totalDonation">{{ donationValue.toUser }}</p>
      </div>
      <div style="height: 100%;">
        <p class="text-wrap-bottom" translate>會員捐分累計</p>
        <p class="totalDonation">{{ donationValue.toCenter }}</p>
      </div>
      <div style="height: 100%;">
        <p class="text-wrap-bottom" translate>義工服務 - 中心補貼累計</p>
        <p class="totalDonation">{{ donationValue.requestDonation }}</p>
      </div>
    </div>
  </div>

  <div class="analysis-container-footer"><a class="exportBtn data-type-button" translate (click)="export()">匯出報告</a>
  </div>
</div>
<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px"
    height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted">
    <path
      d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
    </path>
  </svg></ng-template>