import { Component, OnInit } from "@angular/core";
import { PopupMessages } from "src/app/backend/popup-message";
import { ImportFile } from "src/app/models/imports/import-file";
import { ExportService } from "src/app/services/export/export.service";
import { ImportService } from "src/app/services/import/import.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-import-popup",
  templateUrl: "./import-popup.component.html",
  styleUrls: ["./import-popup.component.scss"],
})
export class ImportPopupComponent implements OnInit {
  importType: string = "User";
  files: ImportFile[] = [];
  records: any[] = [];

  display: boolean = false;

  constructor(
    private popupMessageService: PopupMessageService,
    private importService: ImportService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {
    this.importService.importPopupSignal.subscribe((value) => {
      this.open(value);
    });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.onFileImport(event.dataTransfer?.files);
  }

  onFileImport(files: FileList | undefined) {
    if (files == undefined) return;

    Array.from(files).forEach((file) => {
      if (
        file.name.substring(file.name.length - 5) != ".xlsx" &&
        file.name.substring(file.name.length - 4) != ".xls"
      ) {
        this.popupMessageService.messageSignal.emit(
          PopupMessages.InvalidFileFormatMessage,
        );
        return;
      }

      this.importService.uploadExcel(file).subscribe();

      var importFile = new ImportFile(file);
      this.files.push(importFile);

      switch (this.importType) {
        case "User":
          this.parseUserExcel(importFile);
          break;
        case "Request":
          this.parseRequestExcel(importFile);
          break;
        case "Event":
          this.parseEventExcel(importFile);
          break;
        case "Donation":
          this.parseDonationExcel(importFile);
          break;
        default:
          this.popupMessageService.messageSignal.emit(
            PopupMessages.InvalidInformationMessage,
          );
          break;
      }
    });
  }

  open(type: string): void {
    this.importType = type;
    this.display = true;
  }

  close(): void {
    this.display = false;
    this.records = [];
    this.files = [];
  }

  private parseUserExcel(file: ImportFile): void {
    this.importService.parseUserExcel(file.file).subscribe({
      next: (value) => (this.records = this.records.concat(value)),
      error: (error) =>
        (file.error =
          "EXCEL FILE MAY CONTAIN SOME EMPTY VALUE IN BLANK ROW OR IN WRONG FORMAT!"),
      complete: () => (file.uploadResult = true),
    });
  }

  private parseRequestExcel(file: ImportFile): void {
    this.importService.parseRequestExcel(file.file).subscribe({
      next: (value) => (this.records = this.records.concat(value)),
      error: (error) =>
        (file.error =
          "EXCEL FILE MAY CONTAIN SOME EMPTY VALUE IN BLANK ROW OR IN WRONG FORMAT!"),
      complete: () => (file.uploadResult = true),
    });
  }

  private parseEventExcel(file: ImportFile): void {
    this.importService.parseEventExcel(file.file).subscribe({
      next: (value) => (this.records = this.records.concat(value)),
      error: (error) =>
        (file.error =
          "EXCEL FILE MAY CONTAIN SOME EMPTY VALUE IN BLANK ROW OR IN WRONG FORMAT!"),
      complete: () => (file.uploadResult = true),
    });
  }

  private parseDonationExcel(file: ImportFile): void {
    this.importService.parseDonationExcel(file.file).subscribe({
      next: (value) => (this.records = this.records.concat(value)),
      error: (error) =>
        (file.error =
          "EXCEL FILE MAY CONTAIN SOME EMPTY VALUE IN BLANK ROW OR IN WRONG FORMAT!"),
      complete: () => (file.uploadResult = true),
    });
  }

  confirm(): void {
    switch (this.importType) {
      case "User":
        this.importService.importTaskSignal.emit(
          this.importService.processUserData(this.records),
        );
        break;
      case "Request":
        this.importService.importTaskSignal.emit(
          this.importService.processRequestData(this.records),
        );
        break;
      case "Event":
        this.importService.importTaskSignal.emit(
          this.importService.processEventData(this.records),
        );
        break;
      case "Donation":
        this.importService.importTaskSignal.emit(
          this.importService.processDonationData(this.records),
        );
        break;
      default:
        break;
    }
    this.close();
  }

  exportSample(): void {
    switch (this.importType) {
      case "User":
        this.exportService.exportUserSample();
        break;
      case "Request":
        this.exportService.exportRequestSample();
        break;
      case "Event":
        this.exportService.exportEventSample();
        break;
      case "Donation":
        this.exportService.exportDonationSample();
        break;
      default:
        break;
    }
  }
}
