<div class="chart-container">
  <div class="chart-container-header">
    <div class=" align-items-center">
      <p class="text-bold" translate>不活躍會員</p>
    </div>
    <div class="filterHeader">
      <div class="inline-flex-row mt-8 startline">
        <button class="left-button" (click)="dataType = 'Request';fetch()" [ngClass]="{'active': dataType == 'Request'}"
          translate>義工服務</button>
        <button class="right-button" (click)="dataType = 'Event';fetch()" [ngClass]="{'active': dataType != 'Request'}"
          translate>中心活動</button>
      </div>
      <div class="inline-flex-row mt-8 endinline" style="position:relative">
        <input class="user-input xl ml-4" [ngModel]="dateRange" (click)="picker.open()" placeholder="{{placeholder}}"
          readonly>
        <ng-container *ngTemplateOutlet="datepicker"></ng-container>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        <mat-date-range-input style="display: none;" [rangePicker]="picker">
          <input matStartDate [formControl]="start">
          <input matEndDate [formControl]="end" (dateInput)="fetch()">
        </mat-date-range-input>
      </div>
    </div>
  </div>

  <div class="chart-container-body">
    <div class="inline-flex-row" style="margin-bottom: 0.5rem;">
      <p translate>共有</p>
      <p class="text-blue ml-2 mr-2">{{ users.length }}</p>
      <p translate>位會員在</p>
      <p>&nbsp;{{ placeholder }}&nbsp;</p>
      <p translate>沒有交易紀錄</p>
    </div>
    <app-simple-table-box style="flex: 7;" [displayList]="displayedUsers">
      <div class="inactive-user-table-row header head">
        <div class="inactive-user-table-cell">
          <p translate>會員編號</p>
        </div>
        <div class="inactive-user-table-cell">
          <p translate>中文姓名</p>
        </div>
        <div class="inactive-user-table-cell">
          <p translate>性別</p>
        </div>
        <div class="inactive-user-table-cell">
          <p translate>中心代碼</p>
        </div>
        <div class="inactive-user-table-cell">
          <p translate>電話</p>
        </div>
        <div class="inactive-user-table-cell">
          <p translate>時分</p>
        </div>
        <div class="inactive-user-table-cell"></div>
      </div>
      <ng-template #row let-service='service' let-index="index">
        <div class="inactive-user-table-row">
          <div class="inactive-user-table-cell">
            <p>{{ service.memberId }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p>{{ service.chineseName }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p>{{ service.gender | translate }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p>{{ service.center.centerCode }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p translate>{{ service.phone }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p translate [ngClass]="{redColor:service.timeBalance<0}">{{ service.timeBalance }}</p>
          </div>
          <div class="inactive-user-table-cell">
            <p class="click-text-lightblue" [routerLink]="['/main/user/overview', service.uuId]" translate>檢視</p>
          </div>
        </div>
      </ng-template>
    </app-simple-table-box>
    <div class="inactive-user-table-row" *ngIf="displayedUsers.length == 0">
      <div class="inactive-user-table-cell">
        <p class="text-grey" translate>沒有紀錄</p>
      </div>
    </div>
  </div>
  <div class="chart-container-footer"><a class="exportBtn data-type-button" translate (click)="export()">匯出報告</a></div>

</div>
<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px"
    height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted">
    <path
      d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
    </path>
  </svg></ng-template>
