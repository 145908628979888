import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Service } from 'src/app/models/services/service';
import { SignUpUserListItem } from 'src/app/models/sign-ups/sign-up-user-list-item';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SignUpService } from 'src/app/services/sign-up/sign-up.service';

@Component({
  selector: 'app-sign-up-overview-page',
  templateUrl: './sign-up-overview-page.component.html',
  styleUrls: ['./sign-up-overview-page.component.scss']
})
export class SignUpOverviewPageComponent implements OnInit {

  users: SignUpUserListItem[] = [];
  caches: SignUpUserListItem[] = [];
  count: number = 0;
  pages: number = 0;

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    districts: [],
    subDistricts: [],
    services: [],
    signUpDateOrder: true,
    statuses: []
  };

  constructor(
    private signUpService: SignUpService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.signUpService.getSignUpUserList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.users = value.list;
        this.count = value.count;
        this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
        this.users.forEach(user => {
          if (this.caches.find(u => u.uuId == user.uuId))
            user.selected = true;
        });
      });
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction = ((value: string[]) => {
    this.filters.statuses = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  changePage(): void {
    this.router.navigate(['/main/user/sign-up-overview'], { queryParams: { page: this.filters.page, itemsPerPage: this.filters.itemsPerPage } })
  }

  selectAll(): void {
    if (this.isSelectedAll) {
      this.users.forEach(p => {
        p.selected = false;
        this.caches.splice(this.caches.findIndex(u => u.uuId == p.uuId), 1);
      });
    }
    else {
      this.caches = this.caches.concat(this.users.filter(user => user.selected == false));
      this.users.forEach(user => user.selected = true);
    }
  }

  get isSelectedAll(): boolean {
    return this.users.every(profile => profile.selected == true);
  }

  cacheSelectedUser(user: SignUpUserListItem): void {
    if (user.selected)
      this.caches.push(user);
    else
      this.caches.splice(this.caches.findIndex(u => u.uuId == user.uuId), 1);
  }


  private getQueryString(): string {
    return "?start=" + (this.filters.page - 1) * this.filters.itemsPerPage
      + "&limit=" + this.filters.itemsPerPage
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.services.map((service: Service) => "&services=" + service.uuId).join(""))
      + (this.filters.districts.map((district: string) => "&districts=" + district).join(""))
      + (this.filters.subDistricts.map((subDistrict: string) => "&subDistricts=" + subDistrict).join(""))
      + (this.filters.signUpDateOrder != null ? "&OrderBySignUpDate=" + String(this.filters.signUpDateOrder) : "")
      + (this.filters.statuses.map((status: string) => "&status=" + status).join(""))
  }

  deleteUser(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteUserMessage(this.profileService.deleteUsers([uuId])));
  }

  deleteUserList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteUserListMessage(this.profileService.deleteUsers(this.caches.map(p => p.uuId))));
  }
}
