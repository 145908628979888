<div class="box">
    <p class="title" matTooltip="{{ '居住條件限制' | translate }}" [matTooltipPosition]="'above'" translate>居住條件限制</p>
    <div class="inline-flex-column">
        <form class="input-row" style="min-width: 36.875rem;" [formGroup]="form">
            <div class="user-select">
                <select class="user-select lg" formControlName="type">
                    <option [ngValue]="null" selected disabled translate>選擇住宅類型</option>
                    <option [ngValue]="type.type" *ngFor="let type of types">{{ type.name | translate }}</option>
                </select>
            </div>
            <input type="text" class="user-input lg" style="margin-left: 1.5625rem;" formControlName="name" placeholder="{{ '新增居住條件' | translate }}">
            <p class="click-text-blue text-xs ml-4" (click)="create()" translate>保存</p>
        </form>

        <div class="living-box">

            <div class="living-type-column">
                <div class="living-type-item mb-2" [ngClass]="{active: index == i}" *ngFor="let type of types; let i = index" (click)="index = i">
                    <p>{{ type.name | translate }}</p>
                    <p>{{ numberOfLivingOfType(type.type) }}</p>
                </div>
                <label class="inline-flex-row align-items-center mt-4">
                    <i class="material-icons-round info-icon mr-5 align-self-start">error_outline</i>
                    <p class="text-lightgrey text-xs" style="white-space: pre-line;" translate>請根據居住條件實際情況去選擇所屬類型以提高住宅條件自動顯示準確率</p>
                </label>
            </div>

            <div class="living-column">
                <div class="living-item mb-2" *ngFor="let living of livingsOfSelectedType">
                    <ng-container *ngIf="living.editing == false;then read;else edit"></ng-container>
                    <ng-template #read>
                        <p class="name">{{ living.name }}</p>
                        <i class="click-text-lightblue material-icons-outlined create-icon ml-4" (click)="living.editing = true">create</i>
                    </ng-template>
                    <ng-template #edit>
                        <form class="inline-flex-row align-items-center" [formGroup]="living.form">
                            <input type="text" class="user-input lg" formControlName="name" placeholder="{{ '新增居住條件' | translate }}">
                            <p class="click-text-blue text-xs ml-4" (click)="update(living)" translate>保存</p>
                            <p class="click-text-blue text-xs ml-4" (click)="living.editing = false" translate>取消</p>
                        </form>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>