import { ProductExportModel } from "../exports/product-export-model";
import { IRedeem, Redeem } from "./redeem";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IProductListItem {
  closeDate: Date;
  format: string;
  id: number;
  isEdited: boolean;
  launchDate: Date;
  productName: string;
  stock: number;
  uuId: string;
  active: boolean;
  brand: string;
  totalStock: number;
  userCount: number;
  redeemCount: number;
  redeems: IRedeem[];
  pendingCount: number;
}

export class ProductListItem {
  closeDate: Date;
  format: string;
  id: number;
  isEdited: boolean;
  launchDate: Date;
  productName: string;
  stock: number;
  uuId: string;
  active: boolean;
  selected: boolean;
  brand: string;
  totalStock: number;
  userCount: number;
  redeemCount: number;
  redeems: Redeem[];
  pendingCount: number;
  constructor(item: IProductListItem) {
    this.closeDate = dayjs.utc(item.closeDate).toDate();
    this.format = item.format;
    this.id = item.id;
    this.isEdited = item.isEdited;
    this.launchDate = dayjs.utc(item.launchDate).toDate();
    this.productName = item.productName;
    this.stock = item.stock;
    this.uuId = item.uuId;
    this.active = item.active;
    this.selected = false;
    this.brand = item.brand;
    this.totalStock = item.totalStock;
    this.userCount = item.userCount;
    this.redeemCount = item.redeemCount;
    this.redeems = item.redeems
      ? item.redeems.map((redeem) => new Redeem(redeem))
      : [];
    this.pendingCount = item.pendingCount;
  }

  getExportModel(): ProductExportModel {
    return {
      獎勵項目編號: this.id.toString(),
      獎勵標題: this.productName,
      來源: this.brand,
      參與類型: this.format == "FirstComeFirstServed" ? "先到先得" : "職員處理",
      發布日期: dayjs(this.launchDate).format("YYYY-MM-DD"),
      截止日期: dayjs(this.closeDate).format("YYYY-MM-DD"),
      設定數量: (
        this.stock +
        this.redeems
          .map((redeem) => redeem.quantity)
          .reduce((sum, value) => (sum += value), 0)
      ).toString(),
      庫存餘額: this.stock.toString(),
      已兌換會員人數: this.redeems.length.toString(),
      已兌換獎勵數量: this.redeems
        .map((redeem) => redeem.quantity)
        .reduce((sum, value) => (sum += value), 0)
        .toString(),
      狀態: this.isClosed
        ? "已結束"
        : this.isLaunched
          ? "已發佈"
          : this.isPending
            ? "等候發布"
            : "",
    };
  }

  // get isPending(): boolean {
  //     return this.format != "FirstComeFirstServed" && this.stock != 0 && this.closeDate < new Date();
  // }
  get isPending(): boolean {
    return this.stock != 0 && this.pendingCount > 0;
  }

  get isLaunched(): boolean {
    return this.launchDate < new Date();
  }

  get isOutOfStock(): boolean {
    return this.stock == 0 && this.closeDate > new Date();
  }

  get isClosed(): boolean {
    return this.closeDate < new Date();
  }
  get redRot(): boolean {
    return this.isPending || this.isOutOfStock;
  }
}
