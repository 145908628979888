import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { RecommandRatio } from 'src/app/models/recommands/recommand-ratio';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { RecommandRatioService } from 'src/app/services/recommand-ratio/recommand-ratio.service';

@Component({
  selector: 'app-recommand-setting',
  templateUrl: './recommand-setting.component.html',
  styleUrls: ['./recommand-setting.component.scss']
})
export class RecommandSettingComponent implements OnInit {

  ratios: RecommandRatio[] = [];

  constructor(
    private recommandRatioService: RecommandRatioService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.recommandRatioService.getRecommandRatio()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.ratios = value;
        }
      });
  }

  updateRatios(): void {
    this.loadingService.startLoading();
    this.recommandRatioService.setRecommandRatio(this.ratios)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe();
  }

  get serviceRatio(): RecommandRatio | undefined {
    return this.ratios.find(ratio => ratio.name == "service");
  }
  
  get availabilityRatio(): RecommandRatio | undefined {
    return this.ratios.find(ratio => ratio.name == "availability");
  }
  
  get addressRatio(): RecommandRatio | undefined {
    return this.ratios.find(ratio => ratio.name == "address");
  }
  
  get conditionRatio(): RecommandRatio | undefined {
    return this.ratios.find(ratio => ratio.name == "condition");
  }
  
  get feedbackRatio(): RecommandRatio | undefined {
    return this.ratios.find(ratio => ratio.name == "feedback");
  }

}
