<form class="login-bg" [formGroup]="form">
    <div style="position: absolute; top: 1rem; right: 1rem;">
        <p class="text-grey" *ngIf="lang == 'zh-Hant-HK'" (click)="setLang('en')" style="cursor: pointer;" translate>Eng</p>
        <p class="text-grey" *ngIf="lang == 'en'" (click)="setLang('zh-Hant-HK')" style="cursor: pointer;" translate>中文</p>
    </div>

    <p class="login-title" translate>HINCare 網絡後台端</p>
    <p class="text-lightgrey mt-8" translate>隨時隨地、輕鬆支援時間銀行移動客戶端的網絡後台</p>

    <div class="user-select" style="margin-top: 6.125rem;">
        <i class="material-icons login-input-icon">dns</i>
        <select class="user-select" formControlName="organization" style="width: 23rem;height: 2.5rem;text-indent: 1.5rem;font-size: 1rem;">
            <option [ngValue]="null" selected disabled translate>請選擇所屬機構</option>
            <option *ngFor="let organization of organizations" [ngValue]="organization.code">{{ organization.name }}</option>
        </select>
        <ng-container *ngIf="organization.touched && organization.errors">
            <p class="login-input-error zoomIn" *ngIf="organization.errors.required" translate>請選擇機構</p>
        </ng-container>
    </div>

    <div class="login-input-div" style="margin-top: 1.5rem;">
        <i class="material-icons login-input-icon">face</i>
        <input class="login-input" type="text" formControlName="username" placeholder="{{ '帳號名稱' | translate }}">
        <ng-container *ngIf="username.touched && username.errors">
            <p class="login-input-error zoomIn" *ngIf="username.errors.required" translate>帳號不能遺空</p>
        </ng-container>
    </div>

    <div class="login-input-div" style="margin-top: 1.5rem;">
        <i class="material-icons login-input-icon">lock</i>
        <input class="login-input" type="password" formControlName="password" placeholder="{{ '帳號密碼' | translate }}">
        <ng-container *ngIf="password.touched && password.errors">
            <p class="login-input-error zoomIn" *ngIf="password.errors.required" translate>密碼不能遺空</p>
            <p class="login-input-error zoomIn" *ngIf="password.errors.wrong" translate>帳號或密碼錯誤</p>
        </ng-container>
    </div>

    <button class="login-button" style="margin-top: 4.375rem;" type="submit" (click)="submit()" translate>登 錄</button>

    <div class="flex-row align-items-center" style="margin-top: 10.3125rem;">
        <p class="text-lightgrey" translate>幫助</p>
        <p class="text-lightgrey" style="margin-left: 2.5rem;" translate>私隱</p>
        <p class="text-lightgrey" style="margin-left: 2.5rem;" translate>條款</p>
    </div>
    <p class="text-lightgrey text-sm mt-4" translate>copyright © 2019 香港大學</p>
</form>