import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import {
  Announcement,
  IAnnouncement,
} from "src/app/models/announcements/announcement";
import {
  AnnouncementItem,
  IAnnouncementItem,
} from "src/app/models/announcements/announcement-item";
import { CreateAnnouncementModel } from "src/app/models/announcements/create-announcement-model";
import { UpdateAnnouncementModel } from "src/app/models/announcements/update-announcement-model";
import { Profile } from "src/app/models/profiles/profile";
import { CreateAnnouncementForm } from "src/app/modules/announcement/forms/create-announcement-form";

@Injectable({
  providedIn: "root",
})
export class AnnouncementService {
  form: CreateAnnouncementForm = new CreateAnnouncementForm();
  private selection: string[] = [];

  setSelection(profiles: Profile[]) {
    this.selection = profiles.map((p) => p.uuId);
    // console.log(profiles.values());
    window.localStorage.setItem(
      "AnnouncementList",
      JSON.stringify(profiles.map((p) => p.uuId)),
    );
  }

  getSelection(): string[] {
    const a: string = window.localStorage.getItem("AnnouncementList") ?? "";
    if (a == "") return [];
    else {
      var list = JSON.parse(a);
      return list;
    }
  }

  constructor(private httpService: HttpService) {}

  getAnnouncement(uuId: string): Observable<Announcement> {
    return this.httpService
      .get<IAnnouncement>("announcements/" + uuId)
      .pipe(map((value) => new Announcement(value.result)));
  }

  getAnnouncementList(
    queryString: string,
  ): Observable<{ list: AnnouncementItem[]; count: number }> {
    return this.httpService
      .get<{ list: IAnnouncementItem[]; count: number }>(
        "announcements" + queryString,
      )
      .pipe(
        map((value) => {
          return {
            count: value.result.count,
            list: value.result.list.map(
              (announcement) => new AnnouncementItem(announcement),
            ),
          };
        }),
      );
  }

  createAnnouncement(model: CreateAnnouncementModel): Observable<string> {
    return this.httpService
      .post<string, CreateAnnouncementModel>("announcements", model)
      .pipe(map((value) => value.result));
  }

  updateAnnouncement(model: UpdateAnnouncementModel): Observable<any> {
    return this.httpService.put<any, UpdateAnnouncementModel>(
      "announcements",
      model,
    );
  }

  deactivateAnnouncement(uuId: string, reason: string): Observable<any> {
    return this.httpService.put<
      any,
      { announcementUUId: string; reason: string }
    >("announcements/Deactivate", { announcementUUId: uuId, reason: reason });
  }

  deleteAnnouncement(uuId: string): Observable<any> {
    return this.httpService.delete<any>("announcements/" + uuId);
  }

  addRecipients(
    announcementUUId: string,
    userUUIds: string[],
  ): Observable<any> {
    return this.httpService.put<
      any,
      { announcementUUId: string; recipientUUIds: string[] }
    >("announcements/addRecipients", {
      announcementUUId: announcementUUId,
      recipientUUIds: userUUIds,
    });
  }

  removeRecipient(announcementUUId: string, userUUId: string): Observable<any> {
    return this.httpService.put<
      any,
      { announcementUUId: string; recipientUUId: string }
    >("announcements/RemoveRecipient", {
      announcementUUId: announcementUUId,
      recipientUUId: userUUId,
    });
  }

  pinAnnouncement(model: { AnnouncementUUId: string }): Observable<any> {
    return this.httpService.post<any, { AnnouncementUUId: string }>(
      "announcements/Pin",
      model,
    );
  }

  unpinAnnouncement(model: { AnnouncementUUId: string }): Observable<any> {
    return this.httpService.post<any, { AnnouncementUUId: string }>(
      "announcements/unpin",
      model,
    );
  }
}
