import { Center, ICenter } from "../centers/center";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IAnnouncementItem {
  id: string;
  uuId: string;
  announceDate: Date;
  expirationDate: Date;
  title: string;
  createdBy: string;
  isEdited: boolean;
  isActive: boolean;
  isPinned: boolean;
  centers: ICenter[];
  content: string;
  url: string;
  type: string;
}

export class AnnouncementItem {
  id: string;
  uuId: string;
  announceDate: Date;
  expirationDate: Date;
  title: string;
  content: string;
  url: string;
  type: string;
  createdBy: string;
  isEdited: boolean;
  isActive: boolean;
  isPinned: boolean;
  centers: Center[];

  selected: boolean;

  constructor(item: IAnnouncementItem) {
    this.id = item.id;
    this.uuId = item.uuId;
    this.announceDate = dayjs.utc(item.announceDate).toDate();
    this.expirationDate = dayjs.utc(item.expirationDate).toDate();
    this.title = item.title;
    this.content = item.content;
    this.url = item.url;
    this.type = item.type;
    this.createdBy = item.createdBy;
    this.isEdited = item.isEdited;
    this.isActive = item.isActive;
    this.isPinned = item.isPinned;
    this.centers = item.centers.map((center) => new Center(center));
    this.selected = false;
  }

  get involvedCenters(): string {
    return this.centers.map((center) => center.centerCode).join(", ");
  }
}
