<div class="top-bar">
    <p class="text-orange text-lg" translate>HINCare 網絡會員端</p>
    <p class="text-bold text-lg" translate>{{ orgName }}</p>
    <div class="inline-flex-row align-items-center">
        <p class="text-grey" *ngIf="lang == 'zh-Hant-HK'" (click)="setLang('en')" style="cursor: pointer;" translate>Eng</p>
        <p class="text-grey" *ngIf="lang == 'en'" (click)="setLang('zh-Hant-HK')" style="cursor: pointer;" translate>中文</p>
        <div class="name-box" tabindex="0">
            <p class="text-grey" *ngIf="profile" style="margin-left: 2.5rem;">{{ profile.chineseName }}</p>
            <i class="material-icons-round">expand_more</i>
            <div class="name-box-popup"(click)="$event.stopPropagation()">
                <p class="text-grey border-bottom" style="padding-bottom: 0.5rem;" [routerLink]="['/main/staff/my-profile']" translate>查看職員檔案</p>
                <p class="text-grey" style="padding-top: 0.5rem;" (click)="signOut()" translate>登出</p>
            </div>
        </div>
        <div class="inline-flex-column noti" tabindex="1" style="position: relative;">
            <i class="material-icons-outlined text-grey" style="margin-left: 1.5rem; cursor: pointer;">notifications_active</i>
            <div class="notification-box" (click)="$event.stopPropagation()">
                <div class="notification-list">
                    <div class="notification" *ngFor="let notification of notifications">
                        <p>{{ notification.notificationContent.notificationTitle }}</p>
                        <p class="mt-4">{{ notification.notificationContent.notificationBody }}</p>
                        <p class="align-self-end text-grey text-sm mt-4">{{ notification.createdAt | date: 'yyyy-MM-dd HH:mm' }}</p>
                    </div>
                </div>
                <button class="button sm outline align-self-center mt-4 mb-4" (click)="blur()" translate>關閉</button>
            </div>
        </div>
    </div>
</div>