export interface IOrganization {
  uuId: string;
  code: string;
  name: string;
  config: string;
}

export class Organization {
  public uuId: string;
  public code: string;
  public name: string;
  public config: string;

  constructor(organization: IOrganization) {
    this.uuId = organization.uuId;
    this.code = organization.code;
    this.name = organization.name;
    this.config = organization.config;
  }
}
