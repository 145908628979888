<div class="box">
    <p class="title" matTooltip="{{ '健康狀況限制' | translate }}" [matTooltipPosition]="'above'" translate>健康狀況限制</p>
    <div class="inline-flex-column">
        <div class="input-row">
            <p class="text-grey" translate>請指定居住條件和對應相斥的健康狀況</p>
        </div>

        <div class="health-box">
            <div class="health-column">
                <p class="mt-4 mb-6" style="flex:0 0 1.375rem; line-height: 1.375rem;" translate>義工健康狀況</p>
                <div class="health-item mb-2" [ngClass]="{active: index == i}" *ngFor="let health of healths; let i = index" (click)="index = i">
                    <p>{{ health.name }}</p>
                    <p>{{ getHealthConditionConflictsLength(health) }}</p>
                </div>
            </div>

            <div class="condition-column">
                <p class="mt-4 mb-6 text-grey" style="flex:0 0 1.375rem; line-height: 1.375rem;margin-bottom: 1.25rem;">{{selectedHealthName}}</p>

                <div class="status orange mb-8" *ngFor="let conflict of selectedHealthConflicts">
                    <p class="mr-2">{{ conflict.name }}</p>
                    <i class="material-icons-round" (click)="deleteConditionConflict(conflict)">close</i>
                </div>

                <div class="inline-flex-row align-items-center align-self-start mb-8" style="cursor: pointer;" (click)="popup.open(selectedHealthConflicts)">
                    <i class="material-icons-round add-icon mr-4">add_circle</i>
                    <p class="text-sm" style="color: #4A4A4A;" translate>新增居住條件</p>
                </div>

                <div class="inline-flex-row">
                    <i class="material-icons-outlined info-icon mr-5">error_outline</i>
                    <p class="text-lightgrey text-xs" style="width: 22.6875rem;white-space: pre-line;" translate>義工若擁有不符合或相斥對應的申請人居住條件需求的健康狀況，在該申請人的義工配對系統中推薦評分會相應降低</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-living-popup #popup [action]="createConflictAction"></app-living-popup>