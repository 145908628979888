import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { CreateAnnouncementForm } from '../../forms/create-announcement-form';

@Component({
  selector: 'app-announcement-create-page',
  templateUrl: './announcement-create-page.component.html',
  styleUrls: ['./announcement-create-page.component.scss']
})
export class AnnouncementCreatePageComponent implements OnInit {

  minDate: Date = new Date()
  form: CreateAnnouncementForm = this.announcementService.form;

  constructor(
    private announcementService: AnnouncementService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  openURL(): void {
    if (this.url.value == null)
      return;
    window.open(this.url.value);
  }

  submit(): void {
    if (this.form.formGroup.valid == false || this.form.invalidExpireDate == true) {
      this.form.formGroup.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    if (this.form.users.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.EmptyParticipantErrorMessage);
      return;
    }

    this.loadingService.startLoading();
    this.announcementService.createAnnouncement(this.form.getCreateAnnouncementModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.form.reset();
          this.popupMessageService.messageSignal.emit(PopupMessages.CreateAnnouncementSuccessMessage(
            new Observable<any>(subscriber => {
              this.router.navigate(['/main/announcement/overview', value]);
            })
          ));
        }
      });
  }

  get title(): AbstractControl {
    return this.form.formGroup.controls['title'];
  }

  get type(): AbstractControl {
    return this.form.formGroup.controls['type'];
  }

  get url(): AbstractControl {
    return this.form.formGroup.controls['url'];
  }

  get announceDate(): FormControl {
    return this.form.formGroup.controls['announceDate'] as FormControl;
  }

  get expireDate(): FormControl {
    return this.form.formGroup.controls['expireDate'] as FormControl;
  }
}
