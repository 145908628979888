import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { ErrorHandleService } from "src/app/services/error-handle/error-handle.service";
import { ImportService } from "src/app/services/import/import.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-import-status-popup",
  templateUrl: "./import-status-popup.component.html",
  styleUrls: ["./import-status-popup.component.scss"],
})
export class ImportStatusPopupComponent implements OnInit {
  public currentTask: { rate: number; text: string | null } =
    this.importService.currentTask;
  display: boolean = false;

  constructor(
    private importService: ImportService,
    private popupMessageService: PopupMessageService,
    private errorHandleService: ErrorHandleService,
  ) {}

  ngOnInit(): void {
    this.importService.importTaskSignal.subscribe(
      (observable: Observable<any>) => {
        this.open();
        observable.pipe(finalize(() => this.close())).subscribe({
          next: (value) => {},
          error: (value) => this.errorHandleService.errorSignal.emit(value),
          complete: () =>
            this.popupMessageService.messageSignal.emit(
              PopupMessages.ImportSuccessMessage(
                new Observable((subscriber) =>
                  this.importService.refreshSignal.emit(),
                ),
              ),
            ),
        });
      },
    );

    this.currentTask = this.importService.currentTask;
  }

  open(): void {
    this.display = true;
  }

  close(): void {
    this.display = false;
    setTimeout(() => {
      this.currentTask.rate = 0;
      this.currentTask.text = null;
    }, 1000);
  }
}
