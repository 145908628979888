import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() placeholder: string = "請輸入姓名 或 會員編號";
  @Input() action: (value: string) => void = (value: string) => { };

  @Input() size: string = "md";

  input: FormControl = new FormControl(null);

  constructor(
  ) { }

  ngOnInit(): void {
  }

  onChange(): void {
    this.action(this.input.value);
  }

}
