import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Address, IAddress } from "../addresses/address";
import { Center, ICenter } from "../centers/center";
import { IService, Service } from "../services/service";
import { IApproveSignUpUserModel } from "./approve-sign-up-user-model";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface ISignUpUser {
  userUUId: string;
  username: string;
  chineseName: string;
  englishName: string;
  gender: string;
  phone: string;
  hasSimCand: boolean;
  dateOfBirth: Date;
  contactPersonName: string;
  contactPersonPhone: string;
  educationLevel: string;
  literacy: string;
  address: IAddress;
  services: IService[];
  signUpAt: Date;
  signUpStatus: string;
  approvedBy: string;
  disapprovedBy: string;
  disapprovedAt: Date;
  disapprovedReason: string;
  wantJoinCenter: ICenter;
}

export class SignUpUser {
  userUUId: string;
  username: string;
  chineseName: string;
  englishName: string;
  gender: string;
  phone: string;
  hasSimCand: boolean;
  dateOfBirth: Date;
  contactPersonName: string;
  contactPersonPhone: string;
  educationLevel: string;
  literacy: string;
  address: IAddress;
  services: IService[];
  signUpAt: Date;
  signUpStatus: string;
  approvedBy: string;
  disapprovedBy: string;
  disapprovedAt: Date;
  disapprovedReason: string;
  wantJoinCenter: Center | null;
  form: FormGroup;

  constructor(user: ISignUpUser) {
    this.userUUId = user.userUUId;
    this.username = user.username;
    this.chineseName = user.chineseName;
    this.englishName = user.englishName;
    this.gender = user.gender;
    this.phone = user.phone;
    this.hasSimCand = user.hasSimCand;
    this.dateOfBirth = dayjs.utc(user.dateOfBirth).toDate();
    this.contactPersonName = user.contactPersonName;
    this.contactPersonPhone = user.contactPersonPhone;
    this.educationLevel = user.educationLevel;
    this.literacy = user.literacy;
    this.address = new Address(user.address);
    this.services = user.services.map((s) => new Service(s));
    this.signUpAt = dayjs.utc(user.signUpAt).toDate();
    this.signUpStatus = user.signUpStatus;
    this.approvedBy = user.approvedBy;
    this.disapprovedBy = user.disapprovedBy;
    this.disapprovedAt = dayjs.utc(user.disapprovedAt).toDate();
    this.disapprovedReason = user.disapprovedReason;
    this.form = new FormGroup({
      memberId: new FormControl(null, [Validators.required]),
      center: new FormControl(null, [Validators.required]),
    });
    if (user.wantJoinCenter != null) {
      this.wantJoinCenter = new Center(user.wantJoinCenter);
      this.form.controls["center"].setValue(user.wantJoinCenter.uuId);
    } else {
      this.wantJoinCenter = null;
    }
  }

  get age(): number {
    return dayjs().diff(this.dateOfBirth, "years");
  }

  getApproveModel(): IApproveSignUpUserModel {
    return {
      userUUId: this.userUUId,
      memberId: this.form.controls["memberId"].value,
      centerUUId: this.form.controls["center"].value,
    };
  }
}
