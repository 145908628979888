import { Component, Input, OnInit } from '@angular/core';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-redeem-type-filter',
  templateUrl: './redeem-type-filter.component.html',
  styleUrls: ['./redeem-type-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class RedeemTypeFilterComponent implements OnInit {

  display: boolean = false;

  @Input() action: (formats: number[]) => void = () => { };
  formats: { name: string, value: number, selected: boolean }[] = [
    { name: "職員處理", value: 0, selected: false },
    { name: "先到先得", value: 1, selected: false },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    if (this.display) this.action(this.formats.filter(format => format.selected).map(format => format.value));
    this.display = !this.display;
  }

  reset(): void {
    this.formats.forEach(format => format.selected = false);
  }

  clear(): void {
    this.formats.forEach(format => format.selected = false);
  }

  get numberOfSelectedFormats(): number {
    return this.formats.filter(format => format.selected).length;
  }
}
