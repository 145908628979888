<div class="box">
    <p class="title" matTooltip="{{ '推薦比重設置' | translate }}" [matTooltipPosition]="'above'" translate>推薦比重設置</p>

    <div class="inline-flex-column">
        <div class="input-row">
            <p class="text-grey" matTooltip="{{ '義工配對系統會根據所設定的不同推薦因素比重綜合評分以推薦符合申請人需求的義工' | translate }}" [matTooltipPosition]="'above'" translate>義工配對系統會根據所設定的不同推薦因素比重綜合評分以推薦符合申請人需求的義工</p>
        </div>


        <div class="inline-flex-column mt-5">
            <div class="inline-flex-row align-items-center">
                <p class="field" matTooltip="{{ '推薦因素' | translate }}" [matTooltipPosition]="'above'" translate>推薦因素</p>
                <p class="field" translate>重要程度</p>
            </div>

            <div class="inline-flex-row align-items-center" style="margin-top: 1.5rem;" *ngIf="serviceRatio">
                <p class="sub-field" translate>可提供的服務</p>
                <button class="option" [ngClass]="{active: serviceRatio.weight == 10}" (click)="serviceRatio.weight = 10; updateRatios()" translate>普通</button>
                <button class="option" [ngClass]="{active: serviceRatio.weight == 20}" (click)="serviceRatio.weight = 20; updateRatios()" translate>重要</button>
                <button class="option" [ngClass]="{active: serviceRatio.weight == 100}" (click)="serviceRatio.weight = 100; updateRatios()" translate>非常重要</button>
            </div>

            <div class="inline-flex-row align-items-center" style="margin-top: 1.5rem;" *ngIf="availabilityRatio">
                <p class="sub-field" translate>空閒時間</p>
                <button class="option" [ngClass]="{active: availabilityRatio.weight == 10}" (click)="availabilityRatio.weight = 10;updateRatios()" translate>普通</button>
                <button class="option" [ngClass]="{active: availabilityRatio.weight == 20}" (click)="availabilityRatio.weight = 20;updateRatios()" translate>重要</button>
                <button class="option" [ngClass]="{active: availabilityRatio.weight == 100}" (click)="availabilityRatio.weight = 100;updateRatios()" translate>非常重要</button>
            </div>

            <div class="inline-flex-row align-items-center" style="margin-top: 1.5rem;" *ngIf="addressRatio">
                <p class="sub-field" translate>地理位置</p>
                <button class="option" [ngClass]="{active: addressRatio.weight == 10}" (click)="addressRatio.weight = 10;updateRatios()" translate>普通</button>
                <button class="option" [ngClass]="{active: addressRatio.weight == 20}" (click)="addressRatio.weight = 20;updateRatios()" translate>重要</button>
                <button class="option" [ngClass]="{active: addressRatio.weight == 100}" (click)="addressRatio.weight = 100;updateRatios()" translate>非常重要</button>
            </div>

            <div class="inline-flex-row align-items-center" style="margin-top: 1.5rem;" *ngIf="conditionRatio">
                <p class="sub-field" translate>居住/健康條件</p>
                <button class="option" [ngClass]="{active: conditionRatio.weight == 10}" (click)="conditionRatio.weight = 10;updateRatios()" translate>普通</button>
                <button class="option" [ngClass]="{active: conditionRatio.weight == 20}" (click)="conditionRatio.weight = 20;updateRatios()" translate>重要</button>
                <button class="option" [ngClass]="{active: conditionRatio.weight == 100}" (click)="conditionRatio.weight = 100;updateRatios()" translate>非常重要</button>
            </div>

            <div class="inline-flex-row align-items-center" style="margin-top: 1.5rem;" *ngIf="feedbackRatio">
                <p class="sub-field" translate>申請人反饋</p>
                <button class="option" [ngClass]="{active: feedbackRatio.weight == 10}" (click)="feedbackRatio.weight = 10;updateRatios()" translate>普通</button>
                <button class="option" [ngClass]="{active: feedbackRatio.weight == 20}" (click)="feedbackRatio.weight = 20;updateRatios()" translate>重要</button>
                <button class="option" [ngClass]="{active: feedbackRatio.weight == 100}" (click)="feedbackRatio.weight = 100;updateRatios()" translate>非常重要</button>
            </div>
        </div>
    </div>

</div>