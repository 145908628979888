export interface IRedeemHistory {
  productUUId: string;
  redeemCode: string;
  redeemDate: Date;
  productName: string;
  quantity: number;
  price: number;
  transactionId: number;
}

export class RedeemHistory {
  productUUId: string;
  redeemCode: string;
  redeemDate: Date;
  productName: string;
  quantity: number;
  price: number;
  transactionId: number;

  selected: boolean;

  constructor(redeem: IRedeemHistory) {
    this.productName = redeem.productName;
    this.redeemCode = redeem.redeemCode;
    this.redeemDate = new Date(redeem.redeemDate);
    this.productUUId = redeem.productUUId;
    this.quantity = redeem.quantity;
    this.price = redeem.price;
    this.transactionId = redeem.transactionId;
    this.selected = false;
  }

  get total(): number {
    return this.price * this.quantity;
  }
}
