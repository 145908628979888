import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Service } from 'src/app/models/services/service';
import { ServiceType } from 'src/app/models/services/service-type';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-service-setting',
  templateUrl: './service-setting.component.html',
  styleUrls: ['./service-setting.component.scss']
})
export class ServiceSettingComponent implements OnInit {

  index: number = 0;
  serviceTypes: ServiceType[] = [];

  loading: boolean = false;
  form: FormGroup = new FormGroup({
    type: new FormControl(null, [Validators.required]),
    code: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    destination: new FormControl(false)
  });

  constructor(
    private serviceService: ServiceService,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.serviceService.signal.subscribe(() => {
      this.fetch();
    })
  }

  fetch(): void {
    this.loading = true;
    this.serviceService.getAllServiceTypeList()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: value => {
          this.serviceTypes = value;
        }
      });
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.serviceService.createService({
      name: this.form.controls['name'].value,
      servicesCode: this.form.controls['code'].value,
      serviceTypeUUId: this.form.controls['type'].value,
      destinationNeeded: this.form.controls['destination'].value
    }).pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.fetch();
          this.form.reset();
          this.form.controls['destination'].setValue(false);
        }
      });
  }

  update(service: Service): void {
    if (service.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.serviceService.updateService(service.getUpdateServiceModel())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.form.reset();
          this.form.controls['destination'].setValue(false);
          this.fetch();
        }
      });
  }

  enableService(service: Service): void {
    service.form.controls['enable'].setValue(true);

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(() => {
      this.fetch();
      subscriber.unsubscribe();
    })

    this.popupMessageService.messageSignal.emit(PopupMessages.EnableServiceMessage(
      this.serviceService.updateService(service.getUpdateServiceModel())
    ));
  }

  disableService(service: Service): void {
    service.form.controls['enable'].setValue(false);

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(() => {
      this.fetch();
      subscriber.unsubscribe();
    })

    this.popupMessageService.messageSignal.emit(PopupMessages.DisableServiceMessage(
      this.serviceService.updateService(service.getUpdateServiceModel())
    ));
  }

  get selectedServiceType(): ServiceType {
    return this.serviceTypes[this.index];
  }
}
