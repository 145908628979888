import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverviewPageComponent } from "./pages/overview-page/overview-page.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AnalyticsModule } from "../analytics/analytics.module";
import { MatBadgeModule } from "@angular/material/badge";
import { UrgentRequestPageComponent } from "./pages/urgent-request-page/urgent-request-page.component";
import { RequestPreviewComponent } from "./components/request-preview/request-preview.component";
import { EventPreviewComponent } from "./components/event-preview/event-preview.component";
import { ShareModule } from "../share/share.module";

const routes: Routes = [
  { path: "urgent", component: UrgentRequestPageComponent },
  {
    path: "",
    component: OverviewPageComponent,
    children: [
      {
        path: "request",
        component: RequestPreviewComponent,
        data: { animationState: "request" },
      },
      {
        path: "event",
        component: EventPreviewComponent,
        data: { animationState: "event" },
      },
      { path: "", redirectTo: "request", pathMatch: "full" },
      { path: "**", redirectTo: "request" },
    ],
  },
  { path: "**", redirectTo: "console" },
];

@NgModule({
  declarations: [
    OverviewPageComponent,
    UrgentRequestPageComponent,
    RequestPreviewComponent,
    EventPreviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes),
    AnalyticsModule,
    MatBadgeModule,
    ShareModule,
  ],
})
export class OverviewModule {}
