import { ThrowStmt } from "@angular/compiler";
import { Address, IAddress } from "../addresses/address";
import { RequestExportModel } from "../exports/request-export-model";
import { IService, Service } from "../services/service";
import { IRequester, Requester } from "./requester";
import {ITransaction,Transaction}from "../transactions/transaction";

export interface IRequestListItem {
    id: number;
    uuId: string;
    requester: IRequester;
    startTime: Date;
    endTime: Date;
    requestStatus: string;
    actualDuration: number;
    service: IService;
    volunteers: IRequestListItemVolunteer[];
    deletedDate: Date;
    remark: string;
    extraRequestJson: any;
}

export class RequestListItem {
    id: number;
    uuId: string;
    requester: Requester;
    startTime: Date;
    endTime: Date;
    requestStatus: string;
    actualDuration: number;
    service: Service;
    volunteers: RequestListItemVolunteer[];
    deletedDate: Date | null;
    selected: boolean;
    toggle: boolean;
    remark: string;
    extraRequestJson: any;
    totalUseTimeBalance:number|null;

    constructor(request: IRequestListItem) {
        this.id = request.id;
        this.uuId = request.uuId;
        this.requester = new Requester(request.requester);
        this.startTime = new Date(request.startTime + 'Z');
        this.endTime = new Date(request.endTime + 'Z');
        this.requestStatus = request.requestStatus;
        this.actualDuration = request.actualDuration;
        this.service = new Service(request.service);
        this.volunteers = request.volunteers.map(volunteer => new RequestListItemVolunteer(volunteer));
        if(this.requestStatus=="Finished"){
            if(request.volunteers.filter(volunteer=>volunteer.volunteerStatus=="CheckedOut").length==0){
                this.totalUseTimeBalance=null;
            }else{
                this.totalUseTimeBalance=request.volunteers.filter(volunteer=>volunteer.volunteerStatus=="CheckedOut").map(volunteer=>volunteer.transaction.amount).reduce((a,b)=>a+b);
            }
        }
        else
            this.totalUseTimeBalance=null;
        this.deletedDate = request.deletedDate ? new Date(request.deletedDate + 'Z') : null;
        this.selected = false;
        this.toggle = false;
        this.remark = request.remark;
        this.extraRequestJson = this.extraRequestJson ? JSON.parse(request.extraRequestJson) : null;
    }

    getExportModel(): RequestExportModel {
        var model: any = {
            "紀錄編號*": this.id.toString(),
            "申請人編號*": this.requester.memberId,
            "申請人名稱": this.requester.chineseName,
            "服務日期(YYYY-MM-DD)*": this.startTime.toJSON().split('T')[0],
            "預計開始時間(HH:mm)*": this.startTime.toTimeString().substr(0, 5),
            "預計結束時間(HH:mm)*": this.endTime.toTimeString().substr(0, 5),
            "服務鐘數*": this.actualDuration ? this.actualDuration.toString() : null,
            "提供服務*（字眼必須和後台資料設置一致）": this.service.name,
            "義工編號*（用逗號隔開）": this.JoinedVolunteers.map(v => v.memberId).join(", "),
            "義工名稱（用逗號隔開）": this.volunteerNames,
            "實際開始時間(HH:mm)*": this.startTime.toTimeString().substr(0, 5),
            "實際結束時間(HH:mm)*": this.endTime.toTimeString().substr(0, 5),
            "備註": this.remark,
            "每個義工的交易時分*":this.volunteers.filter(v=>v.transaction!=null).map(v=>v.transaction.amount).join(",") ,           
            "總交易時分*":this.volunteers.filter(v=>v.transaction!=null).map(v=>v.transaction.amount).reduce((a,c)=>(a+c),0),
            "狀態": this.requestStatus == 'Finished' ? '已完成' : this.requestStatus == 'Cancelled' ? '已取消' : this.requestStatus == 'Pending' ? '等候配對' : ''
        }

        if (this.extraRequestJson != null)
            Object.keys(this.extraRequestJson).forEach(key => {
                model[key] = this.extraRequestJson[key];
            });

        return model;
    }

    get JoinedVolunteers(): RequestListItemVolunteer[] {
        return this.volunteers.filter(volunteer => volunteer.volunteerStatus === 'CheckedOut' || volunteer.volunteerStatus === 'CheckedIn' || volunteer.volunteerStatus === 'Confirmed');
    }

    get volunteerNames(): string {
        return this.JoinedVolunteers.map(v => v.chineseName).join(', ');
    }

    get matchingOvertime(): boolean {
        var date = new Date();
        date.setMinutes(date.getMinutes() + Number(localStorage.getItem("RequestNotificationStaff")==null?"0":localStorage.getItem("RequestNotificationStaff")));
        return this.requestStatus == 'Pending' && this.startTime < date;
    }

    get startOvertime(): boolean {
        return this.requestStatus == 'Ready' && this.startTime < new Date();
    }

    get endOvertime(): boolean {
        return this.requestStatus == 'Started' && this.endTime < new Date();
    }

    /** Return remaining date before deleted permanently */
    get deletedDays(): number {
        return Math.ceil((new Date().getTime() - (this.deletedDate as Date).getTime()) / 86400000);
    }

    get redRot():boolean{
        return this.matchingOvertime||this.startOvertime||this.endOvertime;
    }
}

export interface IRequestListItemVolunteer {
    memberId: string;
    chineseName: string;
    volunteerStatus: string;
    transaction:Transaction;
}

export class RequestListItemVolunteer {
    memberId: string;
    chineseName: string;
    volunteerStatus: string;
    transaction:Transaction;

    constructor(volunteer: IRequestListItemVolunteer) {
        this.memberId = volunteer.memberId;
        this.chineseName = volunteer.chineseName;
        this.volunteerStatus = volunteer.volunteerStatus;
        this.transaction=volunteer.transaction;
    }
}