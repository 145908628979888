<div class="analytics-container">
    <div class="inline-flex-row" style="padding: 0.5rem 1rem;">
        <p class="text-lightgrey flex-1" translate>推薦成功率</p>
        <i matTooltip="{{ '推薦成功率 - 由系統推薦的義工人選最後成為最終服務人員的轉換率。' | translate }}" [matTooltipPosition]="'above'" class="material-icons-outlined text-lightgrey" style="font-size: 1rem">info</i>
    </div>

    <p class="text-xxl" style="padding-left: 1rem;">{{ successRate }}%</p>

    <div class="flex-1"></div>

    <div style="padding: 1rem;">
        <progress [value]="successRate" max="100"></progress>
    </div>
</div>