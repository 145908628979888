import { District } from "./district";

export class Region {
  name: string;
  districts: District[];

  constructor(name: string, districtNames: string[]) {
    this.name = name;
    this.districts = districtNames.map((name) => new District(name));
  }

  public selectAll(): void {
    if (this.isSelectedAll) this.districts.forEach((d) => (d.selected = false));
    else this.districts.forEach((d) => (d.selected = true));
  }

  public reset(): void {
    this.districts.forEach((d) => (d.selected = false));
  }

  get isSelectedAll(): boolean {
    return this.districts.every((d) => d.selected);
  }
}
