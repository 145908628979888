import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-user-ranking-analysis',
  templateUrl: './user-ranking-analysis.component.html',
  styleUrls: ['./user-ranking-analysis.component.scss']
})
export class UserRankingAnalysisComponent implements OnInit {

  @Input() form!: FormControl;

  public ranking: { index: string, value: number }[] = [];
  public totalDonation: number = 0;
  public firstDonation:Date|null=null;
  public PrevMonthStart:Date=new Date();
  public PrevMonthEnd:Date=new Date();
  public currentLang:string="";
  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService
  ) {
    this.currentLang=this.translateService.currentLang;
    var nowDate=new Date();
    this.PrevMonthStart.setDate(1);
    this.PrevMonthStart.setMonth(nowDate.getMonth()-1);

    this. PrevMonthEnd = new Date(this.PrevMonthStart.getFullYear(),this.PrevMonthStart.getMonth()+1, 0);
   }

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe(value => {
      this.fetch(value);
    });
  }

  fetch(centerUUId?: string): void {
    forkJoin({
      ranking: this.analyticsService.getRanking(centerUUId),
      donation: this.analyticsService.getTotalDonation()
    }).subscribe(value => {
      this.firstDonation = value.donation.startTime ;
      this.ranking = value.ranking;
      this.totalDonation = value.donation.sum | 0;
    });
  }

  get topRanking(): { index: string, value: number }[] {
    return this.ranking.sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0).slice(0, 10);
  }
}
