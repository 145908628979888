import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandleService } from '../services/error-handle/error-handle.service';
// import { ImportService } from '../services/import-export/import.service';

// Intercept error on http request
@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {
  public enabled: boolean = true;

  constructor(
    private errorHandleService: ErrorHandleService,
    // private importService: ImportService
  ) {
    // importService.interceptorSignal.subscribe(value => this.enabled = value);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError(
          (error: HttpErrorResponse) => {
            if (error.error == null)
              return throwError(error)
            if (error.error['error_message'] && this.enabled)
              this.errorHandleService.errorSignal.emit(error.error);
            return throwError(error.error);
          }
        )
      );
  }
}