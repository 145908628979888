import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TemplateColumn } from "src/app/backend/app-column";
import { CreateEventModel } from "src/app/models/events/create-event-model";
import { Profile } from "src/app/models/profiles/profile";
import { CreateEventDayForm } from "./create-event-day-form";

export class CreateEventForm {
  formGroup: FormGroup;
  users: Profile[];
  eventDays: CreateEventDayForm[];
  columns: TemplateColumn[];

  constructor() {
    this.formGroup = new FormGroup({
      eventName: new FormControl(null, [Validators.required]),
      eventType: new FormControl(null, [Validators.required]),
      transactionType: new FormControl(null, [Validators.required]),
      transactionFee: new FormControl(null),
      participationFormat: new FormControl(null, [Validators.required]),
      closeDate: new FormControl(null, [Validators.required]),
      venue: new FormControl(null, [Validators.required]),
      vacancy: new FormControl(null, [Validators.required]),
      eventDetail: new FormControl(null),
      remark: new FormControl(null),
      singleDayMode: new FormControl(true),
      urlType: new FormControl(null),
      url: new FormControl(null),
    });

    this.users = [];
    this.eventDays = [new CreateEventDayForm()];
    this.columns = [];

    this.formGroup.controls["urlType"].valueChanges.subscribe((value) => {
      if (value == "url") {
        this.formGroup.controls["url"].setValidators([Validators.required]);
      } else {
        this.formGroup.controls["url"].setValidators([]);
      }
      this.formGroup.controls["url"].updateValueAndValidity();
    });

    this.formGroup.controls["singleDayMode"].valueChanges.subscribe((value) => {
      if (value == true) {
        this.eventDays = [new CreateEventDayForm()];
        return;
      }

      this.eventDays = [];
    });

    this.formGroup.controls["transactionType"].valueChanges.subscribe(
      (value) => {
        if (value == "Free") {
          this.formGroup.controls["transactionFee"].setValidators(null);
          this.formGroup.controls["transactionFee"].setValue(null);
          return;
        }

        this.formGroup.controls["transactionFee"].setValidators([
          Validators.required,
          Validators.min(0),
        ]);
        this.formGroup.controls["transactionFee"].setValue(null);
      },
    );
  }

  public getEventStartDate(): Date {
    return this.eventDays
      .map((ed) => ed.start.value)
      .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))[0];
  }

  public getEventEndDate(): Date {
    return this.eventDays
      .map((ed) => ed.end.value)
      .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];
  }

  reset(): void {
    this.formGroup.reset();
    this.users = [];
    this.formGroup.controls["singleDayMode"].setValue(true);
    this.columns.forEach((column) => column.input.reset());
  }

  getCreateEventModel(): CreateEventModel {
    return {
      eventTypeUUId: this.formGroup.controls["eventType"].value,
      eventName: this.formGroup.controls["eventName"].value,
      venue: this.formGroup.controls["venue"].value,
      startTime: this.getEventStartDate().toJSON(),
      endTime: this.getEventEndDate().toJSON(),
      isUnknowEndTime: false,
      participantVacancy: this.formGroup.controls["vacancy"].value,
      remark: this.formGroup.controls["remark"].value,
      detail: this.formGroup.controls["eventDetail"].value,
      invitedUsers: this.users.map((u) => u.uuId),
      eventPay: this.formGroup.controls["transactionType"].value,
      earning: this.formGroup.controls["transactionFee"].value
        ? this.formGroup.controls["transactionFee"].value
        : 0,
      eventJoinType: this.formGroup.controls["participationFormat"].value,
      applyDeadline: this.formGroup.controls["closeDate"].value.toJSON(),
      eventDays: this.eventDays.map((ed) => ed.getCreateEventDayModel()),
      extraEventJson: this.stringtifyColumns(this.columns),
      url: this.formGroup.controls["url"].value,
    };
  }

  stringtifyColumns(columns: TemplateColumn[]): string {
    var temp: any = {};
    columns.forEach((column) => {
      temp[column.columnName] = column.input.value;
    });
    return JSON.stringify(temp);
  }
}
