import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { CreateCenterDonationModel } from 'src/app/models/donations/create-center-donation-model';
import { CreateUserDonationModel } from 'src/app/models/donations/create-user-donation-model';
import { DonationImportModel } from 'src/app/models/imports/donation-import-model';
import { Transaction, ITransaction } from 'src/app/models/transactions/transaction';
import { CreateCenterDonationForm } from 'src/app/modules/donation/forms/create-center-donation-form';
import { CreateUserDonationForm } from 'src/app/modules/donation/forms/create-user-donation-form';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  createUserDonationForm: CreateUserDonationForm = new CreateUserDonationForm();
  createCenterDonationForm: CreateCenterDonationForm = new CreateCenterDonationForm();

  constructor(
    private httpService: HttpService
  ) { }

  public getDonationList(): Observable<Transaction[]> {
    return this.httpService.get<ITransaction[]>("donations").
      pipe(map(value => value.result.map(transaction => new Transaction(transaction)).sort(TransactionSorting)));
  }

  public donateToCenter(model: CreateUserDonationModel): Observable<any> {
    return this.httpService.post("donations/DonationCenter", model);
  }

  public donateToUsers(model: CreateCenterDonationModel): Observable<any> {
    return this.httpService.post("donations/DonationUsers", model);
  }

  public importDonation(model: DonationImportModel): Observable<any> {
    return this.httpService.post<any, DonationImportModel>("donations/Import", model);
  }
}


function TransactionSorting(a: Transaction, b: Transaction): number {
  return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
}