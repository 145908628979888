import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { RequestService } from 'src/app/services/request/request.service';

@Component({
  selector: 'app-request-start-popup',
  templateUrl: './request-start-popup.component.html',
  styleUrls: ['./request-start-popup.component.scss']
})
export class RequestStartPopupComponent implements OnInit {

  display: boolean = false;
  form: FormControl = new FormControl(new Date())

  constructor(
    private popupMessageService: PopupMessageService,
    private requestService: RequestService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  open(): void {
    this.display = true;
  }

  close(): void {
    this.display = false;
  }

  confirm() {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.requestService.startRequest(value.id, { dateTime: this.form.value.toISOString() })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {
          this.popupMessageService.messageSignal.emit(PopupMessages.StartRequestSuccessMessage);
        });
    });
  }
}
