import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { Observable } from "rxjs";
import { finalize, shareReplay } from "rxjs/operators";
import { Profile } from "src/app/models/profiles/profile";
import { PortalAnimation } from "src/app/portal-animation";
import { LoadingService } from "src/app/services/loading/loading.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-user-detail-page",
  templateUrl: "./user-detail-page.component.html",
  styleUrls: ["./user-detail-page.component.scss"],
  animations: [PortalAnimation.UserPageSlide],
})
export class UserDetailPageComponent implements OnInit {
  profile: Profile | null = null;
  observable: Observable<Profile> | null = null;

  constructor(
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.route.params.subscribe((value) => {
      this.profileService.getObservable = this.profileService
        .getProfileByUUId(value.id)
        .pipe(shareReplay(1));

      // this.loadingService.startLoading();
      this.profileService.getObservable
        // .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: (value) => (this.profile = value),
        });
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animationState"]
    );
  }
}
