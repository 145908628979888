<div class="score-box">
    <i class="material-icons-outlined text-lightblue" style="font-size: 1rem;" (mouseover)="display = true" (mouseout)="display = false">info</i>

    <div class="score-popup zoomIn" *ngIf="display && hinDetail">
        <div class="score-header">
            <p class="text-sm" style="flex-basis: 6.875rem;" translate>推薦總分</p>
            <p class="text-orange">{{ sum }}</p>
        </div>

        <div class="score-body">
            <div class="inline-flex-row align-items-center">
                <p class="text-xs" style=" flex-basis: 6.875rem;" translate>可提供服務</p>
                <p class="text-xs mr-4" style="flex-basis: 1.75rem;">+{{ hinDetail.service }}</p>
                <div class="inline-flex-row align-items-center text-red" *ngIf="hinRemark.userIsNotProvidingRequiredService">
                    <i class="material-icons-outlined mr-2 text-sm">info</i>
                    <p class="text-xs" translate>不適合</p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center mt-3">
                <p class="text-xs" style=" flex-basis: 6.875rem;" translate>空閒時間</p>
                <p class="text-xs mr-4" style="flex-basis: 1.75rem;">+{{ hinDetail.availability_start + hinDetail.availability_end }}</p>
                <div class="inline-flex-row align-items-center text-red" *ngIf="hinRemark.userIsNotAvailableDuringRequestedStartTime || hinRemark.userIsNotAvailableDuringRequestedEndTime">
                    <i class="material-icons-outlined mr-2 text-sm">info</i>
                    <p class="text-xs" translate>沒有空閒</p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center mt-3">
                <p class="text-xs" style=" flex-basis: 6.875rem;" translate>健康/居住條件</p>
                <p class="text-xs mr-4" style="flex-basis: 1.75rem;">+{{ hinDetail.condition }}</p>
                <div class="inline-flex-row align-items-center text-red" *ngIf="hinRemark.userHasContradictingCondition">
                    <i class="material-icons-outlined mr-2 text-sm">info</i>
                    <p class="text-xs" translate>條件相斥</p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center mt-3">
                <p class="text-xs" style=" flex-basis: 6.875rem;" translate>地理位置</p>
                <p class="text-xs mr-4" style="flex-basis: 1.75rem;">+{{ hinDetail.address }}</p>
                <div class="inline-flex-row align-items-center text-red" *ngIf="hinRemark.userAddressIsFarAwayFromRequester">
                    <i class="material-icons-outlined mr-2 text-sm">info</i>
                    <p class="text-xs" translate>位置較遠</p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center mt-3">
                <p class="text-xs" style=" flex-basis: 6.875rem;" translate>會員反饋</p>
                <p class="text-xs mr-4" style="flex-basis: 1.75rem;">+{{ hinDetail.completed + hinDetail.failed }}</p>
                <div class="inline-flex-row align-items-center text-red" *ngIf="hinRemark.userReceivedNegativeFeedbackFromRequester">
                    <i class="material-icons-outlined mr-2 text-sm">info</i>
                    <p class="text-xs" translate>評分較低</p>
                </div>
            </div>
        </div>
    </div>

    <div class="score-popup zoomIn" *ngIf="display && hinDetail == null">
        <p class="inline-flex-row align-items-center justify-content-center flex-1" translate>沒有評分</p>
    </div>
</div>