import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Center } from 'src/app/models/centers/center';
import { EventType } from 'src/app/models/event-types/event-type';
import { EventListItem } from 'src/app/models/events/event-list-item';
import { EventService } from 'src/app/services/event/event.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';

@Component({
  selector: 'app-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss']
})
export class EventPreviewComponent implements OnInit {


  events: EventListItem[] = [];
  caches: EventListItem[] = [];
  count: number = 0;
  pages: number = 0;

  filters: any = {
    page: 1,
    itemsPerPage: 5,
    searchInput: null,
    from: null,
    to: null,
    statuses: null,
    isDeleted: null,
    idOrder: null,
    dateOrder: true,
    eventTypes: [],
    eventTransactionTypes: [],
    centers: []
  }

  constructor(
    private eventService: EventService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 5;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.eventService.getEvents(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.count = value.count;
        this.pages = value.count != 0 ? Math.ceil(value.count / this.filters.itemsPerPage) : 0;
        this.events = value.list;
        this.events.forEach(e => {
          if (this.caches.find(event => event.uuId == e.uuId))
            e.selected = true;
        });
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction = ((start: Date, end: Date) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  eventTypeFilterAction = ((eventTypes: EventType[]) => {
    this.filters.eventTypes = eventTypes;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  eventTransactionTypeFilterAction = ((types: string[]) => {
    this.filters.eventTransactionTypes = types;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction = ((status: number) => {
    this.filters.statuses = status;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  changePage(): void {
    this.router.navigate(['/main/overview/event'], { queryParams: { page: this.filters.page, itemsPerPage: this.filters.itemsPerPage } })
  }

  cacheEvent(e: EventListItem): void {
    if (e.selected)
      this.caches.push(e);
    else
      this.caches.splice(this.caches.findIndex(event => event.uuId == e.uuId), 1);
  }

  deleteEvent(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteEventMessage(this.eventService.deleteEvents([uuId])));
  }

  deleteEventList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteEventListMessage(this.eventService.deleteEvents(this.events.filter(e => e.selected).map(e => e.uuId))));
  }

  applyAgain(event: EventListItem): void {
    this.loadingService.startLoading();
    this.eventService.getEventByUUId(event.uuId)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.eventService.form.formGroup.controls['eventName'].setValue(value.eventName);
          this.eventService.form.formGroup.controls['eventType'].setValue(value.eventType.uuId);
          this.eventService.form.formGroup.controls['transactionType'].setValue(value.eventPay);
          this.eventService.form.formGroup.controls['transactionFee'].setValue(value.earning);
          this.eventService.form.formGroup.controls['participationFormat'].setValue(value.eventJoinType);
          this.eventService.form.formGroup.controls['venue'].setValue(value.venue?.uuId);
          this.eventService.form.formGroup.controls['vacancy'].setValue(value.participantVacancy);
          this.eventService.form.formGroup.controls['eventDetail'].setValue(value.detail);
          this.eventService.form.formGroup.controls['remark'].setValue(value.remark);
          this.router.navigate(['/main/activity/create/event']);
        }
      })
  }

  private getQueryString(): string {
    return "?start=" + (this.filters.page - 1) * this.filters.itemsPerPage
      + "&limit=" + this.filters.itemsPerPage
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.from ? "&from=" + this.filters.from.toISOString() : "")
      + (this.filters.to ? "&to=" + this.filters.to.toISOString() : "")
      + (this.filters.statuses ? "&statuses=" + this.filters.statuses : "")
      + (this.filters.idOrder != null ? "&orderById=" + String(this.filters.idOrder) : "")
      + (this.filters.dateOrder != null ? "&orderByDate=" + String(this.filters.dateOrder) : "")
      + (this.filters.isDeleted ? "&isDeleted=" + String(this.filters.isDeleted) : "")
      + (this.filters.eventTypes.map((type: EventType) => "&eventTypes=" + type.uuId).join(""))
      + (this.filters.eventTransactionTypes.map((type: string) => "&eventPays=" + type).join(""))
      + (this.filters.centers ? this.filters.centers.map((c: Center) => "&centers=" + c.uuId) : "");
  }


  selectAll(): void {
    if (this.isSelectedAll) {
      this.events.forEach(e => {
        e.selected = false;
        this.caches.splice(this.caches.findIndex(u => u.uuId == e.uuId), 1);
      });
    }
    else {
      this.caches = this.caches.concat(this.events.filter(event => event.selected == false));
      this.events.forEach(e => e.selected = true);
    }
  }

  get isSelectedAll(): boolean {
    return this.events.every(event => event.selected == true);
  }

}
