import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShareModule } from "../share/share.module";
import { TranslateModule } from "@ngx-translate/core";
import { EventOverviewComponent } from "./components/event-overview/event-overview.component";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { EventCreateComponent } from "./components/event-create/event-create.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { EventDaySchedulerComponent } from "./components/event-day-scheduler/event-day-scheduler.component";
import { SelectEventParticipantsPageComponent } from "./pages/select-event-participants-page/select-event-participants-page.component";
import { EventCreateUserManagementPopupComponent } from "./components/event-create-user-management-popup/event-create-user-management-popup.component";
import { EventDetailPageComponent } from "./pages/event-detail-page/event-detail-page.component";
import { EventDetailUserManagementPopupComponent } from "./components/event-detail-user-management-popup/event-detail-user-management-popup.component";
import { EventDetailRejectedUserTabComponent } from "./components/event-detail-rejected-user-tab/event-detail-rejected-user-tab.component";
import { EventDetailPendingUserTabComponent } from "./components/event-detail-pending-user-tab/event-detail-pending-user-tab.component";
import { EventDetailAcceptedUserTabComponent } from "./components/event-detail-accepted-user-tab/event-detail-accepted-user-tab.component";
import { EventDetailConfirmedUserTabComponent } from "./components/event-detail-confirmed-user-tab/event-detail-confirmed-user-tab.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CreateEventRecordPageComponent } from "./pages/create-event-record-page/create-event-record-page.component";
import { SelectEventRecordParticipantsPageComponent } from "./pages/select-event-record-participants-page/select-event-record-participants-page.component";
import { EventAttendancePopupComponent } from "./components/event-attendance-popup/event-attendance-popup.component";

@NgModule({
  declarations: [
    EventOverviewComponent,
    EventCreateComponent,
    EventDaySchedulerComponent,
    SelectEventParticipantsPageComponent,
    EventCreateUserManagementPopupComponent,
    EventDetailPageComponent,
    EventDetailUserManagementPopupComponent,
    EventDetailRejectedUserTabComponent,
    EventDetailPendingUserTabComponent,
    EventDetailAcceptedUserTabComponent,
    EventDetailConfirmedUserTabComponent,
    CreateEventRecordPageComponent,
    SelectEventRecordParticipantsPageComponent,
    EventAttendancePopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    MatDatepickerModule,
    ScrollingModule,
  ],
})
export class EventModule {}
