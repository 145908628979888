import { FormControl, Validators } from "@angular/forms";
import { Center, ICenter } from "../centers/center";
import { EventParticipantExportModel } from "../exports/event-participant-export-model";
import { Transaction } from "../transactions/transaction";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IEventParticipant {
  uuId: string;
  chineseName: string;
  phone: string;
  gender: string;
  dateOfBirth: Date;
  englishName: string;
  memberId: string;
  center: ICenter;
  state: string;
  lastReadEdited: string;
  requestTime: Date | null;
  timeBalance: Number;
}

export class EventParticipant {
  uuId: string;
  chineseName: string;
  phone: string;
  gender: string;
  dateOfBirth: Date;
  englishName: string;
  memberId: string;
  center: Center | null;
  state: string;
  lastReadEdited: Date;
  timeBalance: Number;

  transaction: Transaction | null;

  selected: boolean;
  editing: boolean;
  form: FormControl;

  requestTime: Date | null;

  constructor(participant: IEventParticipant, transactions?: Transaction[]) {
    this.uuId = participant.uuId;
    this.chineseName = participant.chineseName;
    this.phone = participant.phone;
    this.gender = participant.gender;
    this.dateOfBirth = dayjs.utc(participant.dateOfBirth).toDate();
    this.englishName = participant.englishName;
    this.memberId = participant.memberId;
    this.center = participant.center ? new Center(participant.center) : null;
    this.state = participant.state;
    this.lastReadEdited = dayjs.utc(participant.lastReadEdited).toDate();
    this.requestTime = participant.requestTime
      ? dayjs.utc(participant.requestTime).toDate()
      : null;
    this.timeBalance = participant.timeBalance;
    var existTransaction = transactions
      ? transactions.find(
          (t) =>
            t.transferee.uuId === this.uuId || t.transferer.uuId === this.uuId,
        )
      : null;

    this.transaction = existTransaction ? existTransaction : null;

    if (this.transaction?.status == "Completed" && this.state != "Absent")
      this.state = "Confirmed";

    this.selected = false;
    this.editing = false;

    this.form = new FormControl(null, Validators.required);
  }

  getExportModel(): EventParticipantExportModel {
    return {
      會員編號: this.memberId,
      姓名: this.chineseName,
      暱稱: this.englishName,
      中心編號: this.center?.centerCode,
      性別: this.gender == "M" ? "男" : this.gender == "F" ? "女" : "",
      年齡: this.age.toString(),
      電話: this.phone,
      狀態:
        this.state == "Absent"
          ? "缺席"
          : this.state == "Rejected"
            ? "已拒絕"
            : this.state == "Asked"
              ? "等待回應"
              : this.state == "Interested"
                ? "接受邀請"
                : this.state == "Unselected"
                  ? "未被選中"
                  : this.state == "Confirmed" && this.transaction == null
                    ? "確認參加"
                    : "已完成",
      交易時分: this.transaction ? this.transaction.amount.toString() : "暫無",
      交易編碼: this.transaction ? this.transaction.id.toString() : "暫無",
      完成時間: this.transaction
        ? dayjs(this.transaction.date).format("YYYY-MM-DD HH:mm")
        : "暫無",
    };
  }

  get age(): number {
    return dayjs().diff(this.dateOfBirth, "years");
  }
}
