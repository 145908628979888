import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Center } from 'src/app/models/centers/center';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ProductService } from 'src/app/services/product/product.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { CreateProductForm } from '../../forms/create-product-form';
import { PopupMessages } from 'src/app/backend/popup-message';

@Component({
  selector: 'app-redeem-create-page',
  templateUrl: './redeem-create-page.component.html',
  styleUrls: ['./redeem-create-page.component.scss']
})
export class RedeemCreatePageComponent implements OnInit, AfterViewInit {

  @ViewChild('image') image!: ElementRef;
  file!: Blob;
  form: CreateProductForm = this.productService.form;

  constructor(
    private productService: ProductService,
    private loadingService: LoadingService,
    private router: Router,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    console.log(this.form.formGroup.controls['format'].value)
    if (this.form.image != null)
      (this.image.nativeElement as HTMLImageElement).src = URL.createObjectURL(this.form.image);
    else if(this.form.imagePath!=null){
      this.productService.getProductImage(this.form.imagePath)
        .subscribe({
          next: result => {

            this.file = result;
            this.form.image = result;
            console.log(this.file , this.form.image )
            setTimeout(() => 
              { 
                console.log(this.image,this.hasImage);
                (this.image.nativeElement as HTMLImageElement).src = URL.createObjectURL(result);
              }
              , 1000);

          },
          error: error => {
            //Do Nothing
          }
        })
       
    }
  }


  submit() {
    if (this.form.isValid() == false) {
      this.form.formGroup.markAllAsTouched();
      return;
    }
    if((this.form.formGroup.get("launchDate")?.value as Date)<new Date()&&!this.form.formGroup.get("launchImmediately")?.value)
      this.popupMessageService.messageSignal.emit(PopupMessages.RedeemLaunchDateFailMessage);

    this.loadingService.startLoading();
    this.productService.createProduct(this.form.getFormData())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: result => {
          this.form.formGroup.reset();
          this.form.centers = [];
          this.form.image = null;
          this.router.navigate(["main/redeem/overview", result]);
        }
      });
  }
  
  imageUploadAction: (image: Blob) => void = ((image: Blob) => {
    this.form.image = image;
    this.file = image;
    setTimeout(() => (this.image.nativeElement as HTMLImageElement).src = URL.createObjectURL(this.form.image), 1000);
  }).bind(this);

  centerPopupAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.form.centers = centers;
  }).bind(this);

  get hasImage():boolean{
    return this.filename == null||this.form.image !=null;
  }

  get filename(): string {
    return (this.form.image as any)?.name;
  }

  get title(): AbstractControl {
    return this.form.formGroup.controls['title'];
  }

  get target(): AbstractControl {
    return this.form.formGroup.controls['target'];
  }

  get source(): AbstractControl {
    return this.form.formGroup.controls['source'];
  }

  get format(): AbstractControl {
    return this.form.formGroup.controls['format'];
  }

  get price(): AbstractControl {
    return this.form.formGroup.controls['price'];
  }

  get stock(): AbstractControl {
    return this.form.formGroup.controls['stock'];
  }

  get limit(): AbstractControl {
    return this.form.formGroup.controls['limit'];
  }

  get closeDate(): AbstractControl {
    return this.form.formGroup.controls['closeDate'];
  }

  get description(): AbstractControl {
    return this.form.formGroup.controls['description'];
  }

  get launchDate(): AbstractControl {
    return this.form.formGroup.controls['launchDate'];
  }

  get questionSetting(): AbstractControl {
    return this.form.formGroup.controls['questionSetting'];
  }

}
