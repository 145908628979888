import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Profile } from "src/app/models/profiles/profile";
import { AuthenticationService } from "src/app/services/authenticate/authentication.service";
import { CenterService } from "src/app/services/center/center.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { StaffService } from "src/app/services/staff/staff.service";

@Component({
  selector: "app-staff-detail-page",
  templateUrl: "./staff-detail-page.component.html",
  styleUrls: ["./staff-detail-page.component.scss"],
})
export class StaffDetailPageComponent implements OnInit {
  profile: Profile | undefined;
  centers: Center[] = [];
  readonly: boolean = true;
  showSuccessMessage: boolean = false;

  constructor(
    private profileService: ProfileService,
    private staffService: StaffService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private popupMessageService: PopupMessageService,
    private centerService: CenterService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      forkJoin({
        profile: this.profileService.getProfileByUUId(value.id),
        center: this.centerService.getCenters(),
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: (v) => {
            this.profile = v.profile;
            this.centers = v.center;
          },
        });
    });
  }

  dropAddressAction = ((index: number) => {
    this.profile?.form.addresses.splice(index, 1);
  }).bind(this);

  public forcedSignOut(): void {
    this.loadingService.startLoading();
    this.authService
      .forcedSignOut(this.profile?.uuId as string)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () =>
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ForcedSignOutSuccessMessage,
          ),
      });
  }

  submit(): void {
    if (this.profile?.form.valid == false) {
      this.profile.form.formGroup.markAllAsTouched();
      this.profile.form.addresses.forEach((address) =>
        address.form.formGroup.markAllAsTouched(),
      );
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    let observable: Observable<any>;
    if (this.profile?.form.doChangeUsernameAndPassword())
      observable = this.staffService
        .editStaffProfile(
          this.profile.uuId,
          this.profile.form.getUpdateStaffModel(),
        )
        .pipe(
          switchMap(() => {
            return this.authService.changeUsernameAndPassword({
              userUUId: (this.profile as Profile).uuId,
              username: this.username.value,
              password: this.password.value,
            });
          }),
        );
    else
      observable = this.staffService.editStaffProfile(
        (this.profile as Profile).uuId,
        (this.profile as Profile).form.getUpdateStaffModel(),
      );

    this.loadingService.startLoading();
    observable
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.readonly = true;
          // this.showSuccessMessage = true;
          forkJoin({
            text: this.translateService.get("資料已保存"),
            action: this.translateService.get("關閉"),
          }).subscribe((value) => {
            this.snackBar.open(value.text, value.action, {
              horizontalPosition: "right",
              duration: 5000,
            });
          });
          window.scrollTo(0, 0);
          this.fetch();
        },
      });
  }

  get memberId(): AbstractControl {
    return this.profile?.form.formGroup.controls["memberId"] as FormControl;
  }

  get username(): AbstractControl {
    return this.profile?.form.formGroup.controls["username"] as FormControl;
  }

  get password(): AbstractControl {
    return this.profile?.form.formGroup.controls["password"] as FormControl;
  }

  get center(): AbstractControl {
    return this.profile?.form.formGroup.controls["center"] as FormControl;
  }

  get chineseName(): AbstractControl {
    return this.profile?.form.formGroup.controls["chineseName"] as FormControl;
  }

  get englishName(): AbstractControl {
    return this.profile?.form.formGroup.controls["englishName"] as FormControl;
  }

  get gender(): AbstractControl {
    return this.profile?.form.formGroup.controls["gender"] as FormControl;
  }

  get phone(): AbstractControl {
    return this.profile?.form.formGroup.controls["phone"] as FormControl;
  }

  permissionAction = ((centers: Center[]) => {
    (this.profile as Profile).form.staffCenters = centers;
  }).bind(this);

  dropPermission(index: number): void {
    (this.profile as Profile).form.staffCenters.splice(index, 1);
  }

  get defaultCenter(): Center | undefined {
    return this.centers.find((center) => center.uuId == this.center.value);
  }

  deleteStaff(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteStaffMessage(
        this.staffService.deleteStaffs([this.profile?.uuId as string]),
      ),
    );
  }
}
