import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { Profile, IProfile } from 'src/app/models/profiles/profile';
import { CreateStaffModel } from 'src/app/models/staffs/create-staff-model';
import { UpdateMyStaffModel } from 'src/app/models/staffs/update-my-staff-model';
import { UpdateStaffModel } from 'src/app/models/staffs/update-staff-model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private httpService: HttpService) { }

  public getStaffProfileList(): Observable<Profile[]> {
    return this.httpService.get<IProfile[]>("profiles/staff/list")
      .pipe(map(value => value.result.map(profile => new Profile(profile))));
  }

  public registerStaff(model: CreateStaffModel): Observable<any> {
    return this.httpService.post<any, CreateStaffModel>("auth/registerStaff", model);
  }

  public editMyStaffProfile(model: UpdateMyStaffModel): Observable<any> {
    return this.httpService.put<any, UpdateMyStaffModel>("profiles/my-staff", model);
  }

  public editStaffProfile(userUUId: string, model: UpdateStaffModel): Observable<any> {
    return this.httpService.post<any, UpdateStaffModel>("profiles/staff/" + userUUId, model);
  }

  public deleteStaffs(model: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("auth/delete", { list: model });
  }
}
