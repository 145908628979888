import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CellGroup } from 'src/app/models/cell-groups/cell-group';
import { Center } from 'src/app/models/centers/center';
import { Health } from 'src/app/models/healths/health';
import { Living } from 'src/app/models/livings/living';
import { Agent } from 'src/app/models/profiles/agent';
import { Profile } from 'src/app/models/profiles/profile';
import { Service } from 'src/app/models/services/service';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CreateUserForm } from '../../forms/create-user-form';

@Component({
  selector: 'app-select-agents-page',
  templateUrl: './select-agents-page.component.html',
  styleUrls: ['./select-agents-page.component.scss']
})
export class SelectAgentsPageComponent implements OnInit {

  userUUId: string | null = null;

  count: number = 0;
  pages: number = 0;
  profiles: Profile[] = [];
  caches: Agent[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
    districts: [],
    subDistricts: [],
    services: [],
    conditions: [],
    healths: [],
    genders: [],
    memberIdOrder: true,
    creditOrder: null,
    cellGroup:[]
  };

  constructor(
    private authService: AuthenticationService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });

    this.route.params.subscribe(value => {
      if (value.id == null) {
        this.caches = this.authService.form.agents;
      }
      else {
        this.userUUId = value.id;
        this.profileService.agentSelections?.subscribe(value => {
          if (value.uuid == this.userUUId) {
            this.caches = value.agents as Agent[];
          }
        });
      }
    })
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.profileService.getProfileList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.count = value.count;
          this.profiles = value.list;
          this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
          this.profiles.forEach(profile => {
            if (this.caches.find(u => u.uuId == profile.uuId))
              profile.selected = true;
          });
        }
      });
  }

  cacheSelectedProfile(profile: Profile): void {
    if (profile.selected)
      this.caches.push(new Agent({
        uuId: profile.uuId,
        memberId: profile.memberId,
        chineseName: profile.chineseName,
        centerCode: profile.center.centerCode,
        phone: profile.phone,
        remark: null
      }));
    else
      this.caches.splice(this.caches.findIndex(u => u.uuId == profile.uuId), 1);
  }

  changePage(): void {
    this.router.navigate(['/main/user/create/agent'], { queryParams: { page: this.filters.page, itemsPerPage: this.filters.itemsPerPage } })
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction = ((value: Center[] | any) => {
    this.filters.centers = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  conditionFilterAction = ((genders: string[] | any, healths: Health[] | any, livings: Living[] | any) => {
    this.filters.genders = genders;
    this.filters.healths = healths;
    this.filters.conditions = livings;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);


  private getQueryString(): string {
    return "?start=" + (this.filters.page - 1) * this.filters.itemsPerPage
      + "&limit=" + this.filters.itemsPerPage
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.centers.map((center: Center) => "&centers=" + center.uuId).join(""))
      + (this.filters.services.map((service: Service) => "&services=" + service.uuId).join(""))
      + (this.filters.districts.map((district: string) => "&districts=" + district).join(""))
      + (this.filters.subDistricts.map((subDistrict: string) => "&subDistricts=" + subDistrict).join(""))
      + (this.filters.conditions.map((condition: Living) => "&livingConditions=" + condition.uuId).join(""))
      + (this.filters.healths.map((health: Health) => "&healthConditions=" + health.uuId).join(""))
      + (this.filters.genders.map((gender: string) => "&gender=" + gender).join(""))
      + (this.filters.memberIdOrder != null ? "&orderByMemberId=" + String(this.filters.memberIdOrder) : "")
      + (this.filters.creditOrder != null ? "&orderByBalance=" + String(this.filters.creditOrder) : "")
      + (this.filters.cellGroup.map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId).join(""))
  }

  cellGroupFilterAction = ((center: Center[]) => {
    this.filters.cellGroup=[];
    center.forEach(c=>c.cellGroups.forEach(cg=>cg.selected?this.filters.cellGroup.push(cg):null))
    console.log(this.filters.cellGroup);
    this.fetch();
  }).bind(this);

  selectAll(): void {
    if (this.isSelectedAll) {
      this.profiles.forEach(p => {
        p.selected = false;
        this.caches.splice(this.caches.findIndex(u => u.uuId == p.uuId), 1);
      });
    }
    else {
      this.caches = this.caches.concat(this.profiles
        .filter(profile => profile.selected == false)
        .map(profile => new Agent({
          uuId: profile.uuId,
          memberId: profile.memberId,
          chineseName: profile.chineseName,
          centerCode: profile.center.centerCode,
          phone: profile.phone,
          remark: null
        })));
      this.profiles.forEach(p => p.selected = true);
    }
  }

  submit(): void {
    if (this.userUUId == null) {
      this.authService.form.agents = this.caches;
      this.router.navigate(['/main/user/create']);
      return;
    }

    this.profileService.agentSelections = of({ uuid: this.userUUId, agents: this.caches });
    this.router.navigate(['/main/user/overview', this.userUUId], { queryParams: { readonly: false } });
  }

  back(): void {
    if (this.userUUId == null) {
      this.router.navigate(['/main/user/create']);
      return;
    }

    this.router.navigate(['/main/user/overview', this.userUUId], { queryParams: { readonly: false } });
  }

  get isSelectedAll(): boolean {
    return this.profiles.every(profile => profile.selected == true);
  }

  fetchEverything(): void {
    this.loadingService.startLoading();
    this.profileService.getProfileList(this.getEverythingQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.caches = value.list.map(profile => new Agent({
            uuId: profile.uuId,
            memberId: profile.memberId,
            chineseName: profile.chineseName,
            centerCode: profile.center.centerCode,
            phone: profile.phone,
            remark: null
          }));
          this.profiles.forEach(profile => {
            if (this.caches.find(u => u.uuId == profile.uuId))
              profile.selected = true;
          });
        }
      });
  }

  private getEverythingQueryString(): string {
    return "?start=0"
      + "&limit=5000"
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.centers.map((center: Center) => "&centers=" + center.uuId).join(""))
      + (this.filters.services.map((service: Service) => "&services=" + service.uuId).join(""))
      + (this.filters.districts.map((district: string) => "&districts=" + district).join(""))
      + (this.filters.conditions.map((condition: Living) => "&livingConditions=" + condition.uuId).join(""))
      + (this.filters.healths.map((health: Health) => "&healthConditions=" + health.uuId).join(""))
      + (this.filters.cellGroup.map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId).join(""))
      + (this.filters.genders.map((gender: string) => "&gender=" + gender).join(""))
      + (this.filters.memberIdOrder != null ? "&orderByMemberId=" + String(this.filters.memberIdOrder) : "")
      + (this.filters.creditOrder != null ? "&orderByBalance=" + String(this.filters.creditOrder) : "")
  }

  get isSelectedEverything(): boolean {
    return this.caches.length >= this.count;
  }

  selectEverything(): void {
    if (this.isSelectedEverything == true) {
      this.caches = [];
      this.profiles.forEach(profile => profile.selected = false)
    }
    else {
      this.fetchEverything();
    }
  }
}
