import { FormControl, Validators } from "@angular/forms";
import { IAddressComponent } from "../addresses/address";
import { CellGroup } from "../cell-groups/cell-group";
import { Center, ICenter } from "../centers/center";
import { Profile } from "../profiles/profile";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IVolunteerChoice {
  userUUId: string;
  chineseName: string;
  englishName: string;
  gender: string;
  birthday: Date;
  district: string;
  hasMobilePhone: boolean;
  center: ICenter;
  memberId: string;
  phone: string;
  timeBalance: number;
  defaultDistrict: IAddressComponent;
  defaultRegion: IAddressComponent;

  isRecommended: boolean;
  recommendNum: number;
  isMatched: boolean;
  hinRemark: IHINRemark;
  hinDetail: IHINDetail;
  cellGroups: CellGroup[];
  defaultAddress: string;
}

export class VolunteerChoice {
  userUUId: string;
  chineseName: string;
  englishName: string;
  gender: string;
  birthday: Date;
  district: string;
  hasMobilePhone: boolean;
  center: Center;
  memberId: string;
  phone: string;
  timeBalance: number;
  defaultDistrict: IAddressComponent;
  defaultRegion: IAddressComponent;
  defaultAddress: string;
  selected: boolean;
  disabled: boolean;

  isRecommended: boolean;
  recommendNum: number;
  isMatched: boolean;
  hinRemark: IHINRemark;
  hinDetail: IHINDetail;
  cellGroups: CellGroup[];
  recordForm: FormControl;

  constructor(volunteer: IVolunteerChoice) {
    this.userUUId = volunteer.userUUId;
    this.chineseName = volunteer.chineseName;
    this.englishName = volunteer.englishName;
    this.gender = volunteer.gender;
    this.birthday = dayjs.utc(volunteer.birthday).toDate();
    this.district = volunteer.district;
    this.hasMobilePhone = volunteer.hasMobilePhone;
    this.center = new Center(volunteer.center);
    this.memberId = volunteer.memberId;
    this.phone = volunteer.phone;
    this.timeBalance = volunteer.timeBalance;
    this.defaultDistrict = volunteer.defaultDistrict;
    this.defaultRegion = volunteer.defaultRegion;
    this.isMatched = volunteer.isMatched;
    this.isRecommended = volunteer.isRecommended;
    this.recommendNum = volunteer.recommendNum;
    this.hinRemark = volunteer.hinRemark;
    this.hinDetail = volunteer.hinDetail;
    this.selected = false;
    this.disabled = false;
    this.cellGroups = volunteer.cellGroups;
    this.defaultAddress = volunteer.defaultAddress;

    this.recordForm = new FormControl(null, [
      Validators.required,
      Validators.min(0),
    ]);
  }

  get age(): number {
    return dayjs().diff(this.birthday, "years");
  }

  static parse(profile: Profile) {
    return new VolunteerChoice({
      userUUId: profile.uuId,
      chineseName: profile.chineseName,
      englishName: profile.englishName,
      gender: profile.gender,
      birthday: profile.dateOfBirth,
      district: profile.district,
      hasMobilePhone: profile.hasSimCard,
      center: profile.center,
      memberId: profile.memberId,
      phone: profile.phone,
      timeBalance: profile.timeBalance,
      defaultDistrict: {
        uuId:
          profile.defaultAddress == null
            ? ""
            : profile.defaultAddress.district.uuId,
        name:
          profile.defaultAddress == null
            ? ""
            : profile.defaultAddress.district.name,
        livingConditions:
          profile.defaultAddress == null
            ? []
            : profile.defaultAddress.district.livingConditions,
      },
      defaultRegion: {
        uuId:
          profile.defaultAddress == null
            ? ""
            : profile.defaultAddress.region.uuId,
        name:
          profile.defaultAddress == null
            ? ""
            : profile.defaultAddress.region.name,
        livingConditions:
          profile.defaultAddress == null
            ? []
            : profile.defaultAddress.region.livingConditions,
      },
      isRecommended: false,
      recommendNum: 0,
      isMatched: false,
      hinRemark: {
        userIsNotProvidingRequiredService: false,
        userAddressIsFarAwayFromRequester: false,
        userHasContradictingCondition: false,
        userReceivedNegativeFeedbackFromRequester: false,
        userIsNotAvailableDuringRequestedStartTime: false,
        userIsNotAvailableDuringRequestedEndTime: false,
      },
      hinDetail: {
        service: 0,
        availability_start: 0,
        availability_end: 0,
        address: 0,
        condition: 0,
        completed: 0,
        failed: 0,
      },
      cellGroups: profile.cellGroups,
      defaultAddress:
        profile.defaultAddress == null ? "" : profile.defaultAddress.toString(),
    });
  }
}

export interface IHINRemark {
  userIsNotProvidingRequiredService: boolean;
  userAddressIsFarAwayFromRequester: boolean;
  userHasContradictingCondition: boolean;
  userReceivedNegativeFeedbackFromRequester: boolean;
  userIsNotAvailableDuringRequestedStartTime: boolean;
  userIsNotAvailableDuringRequestedEndTime: boolean;
}

export interface IHINDetail {
  service: number;
  availability_start: number;
  availability_end: number;
  address: number;
  condition: number;
  completed: number;
  failed: number;
}
