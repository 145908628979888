<div class="fadeIn inline-flex-column">

    <div class="flex-row text-lightgrey">
        <p class="text-grey" translate>數據分析</p>
    </div>

    <div class="inline-flex-row align-items-center mt-8">
        <p class="page-title flex-1" translate>數據分析</p>
        <div class="user-select">
            <select class="user-select xxxl" [formControl]="form">
                <option [ngValue]="null" translate>全部</option>
                <option [ngValue]="center.uuId" *ngFor="let center of centers">{{ center.name }}</option>
            </select>
        </div>
    </div>

    <div class="inline-flex-row mt-8">
        <div class="inline-flex-column flex-1">
            <app-balance-analysis [form]="form"></app-balance-analysis>
        </div>
        <div class="inline-flex-column flex-1">
            <app-application-analysis class="ml-8" [form]="form"></app-application-analysis>
        </div>
        <div class="inline-flex-column flex-1">
            <app-success-rate-analysis class="ml-8" [form]="form"></app-success-rate-analysis>
        </div>
    </div>

    <app-chart-analysis class="mt-8" [form]="form"></app-chart-analysis>
    <div class="inline-flex-row mt-8">
        <div class="inline-flex-column" style="flex: 3 0;">
            <app-service-ratio-analysis class="flex-1" [form]="form" [centers]="centers"></app-service-ratio-analysis>
        </div>
        <div class="inline-flex-column" style="flex: 1 0;">
            <app-user-ranking-analysis class="ml-8 flex-1" [form]="form"></app-user-ranking-analysis>
        </div>
    </div>

    <div class="inline-flex-row mt-8">
        <div class="inline-flex-column" style="flex: 1 0;">
            <app-cell-group-data-analysis class="mt-8 flex-1" [form]="form" [centers]="centers"></app-cell-group-data-analysis>
        </div>
        <div class="inline-flex-column" style="flex: 1 0;">
            <app-inactive-user-analysis class="mt-8 ml-8 flex-1" [form]="form"></app-inactive-user-analysis>
        </div>
    </div>
</div>