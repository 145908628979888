export interface IAvailability {
    uuId: string | null;
    weekDay: number;
    availabilityStart: any;
    availabilityEnd: any;
}

export class Availability {
    public uuId: string | null;
    public weekDay: number;
    public availabilityStart: Date;
    public availabilityEnd: Date;

    constructor(availability: IAvailability) {
        this.uuId = availability.uuId;
        this.weekDay = availability.weekDay;
        this.availabilityStart = new Date(availability.availabilityStart + 'Z');
        this.availabilityEnd = new Date(availability.availabilityEnd + 'Z');
    }
}