import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { Transportation } from "src/app/models/transportations/transportation";
import { TransportationService } from "src/app/services/transportation/transportation.service";

@Component({
  selector: "app-transportation-setting",
  templateUrl: "./transportation-setting.component.html",
  styleUrls: ["./transportation-setting.component.scss"],
})
export class TransportationSettingComponent implements OnInit {
  transportations: Transportation[] = [];

  loading: boolean = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });

  constructor(private transportationService: TransportationService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.transportationService
      .getTransportationList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.transportations = value;
        },
      });
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.transportationService
      .createTransportation({
        name: this.form.controls["name"].value,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.form.reset();
          this.fetch();
        },
      });
  }

  update(transportation: Transportation): void {
    if (transportation.form.invalid == true) {
      transportation.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.transportationService
      .updateTransportation(transportation.getUpdateTransportationModel())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
        },
      });
  }
}
