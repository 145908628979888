import { AddressForm } from "src/app/modules/share/forms/address-form";
import { ILiving, Living } from "../livings/living";
import { UpdateAddressModel } from "./update-address-model";

export interface IAddress {
  uuId: string | null;
  region: IAddressComponent;
  district: IAddressComponent;
  subDistrict: IAddressComponent;
  street: IAddressComponent;
  streetNumber: IAddressComponent;
  estate: IAddressComponent;
  block: IAddressComponent;
  floor: IAddressComponent;
  room: IAddressComponent;
  isDefault: boolean;
}

export class Address {
  public uuId: string | null;
  public region: IAddressComponent;
  public district: IAddressComponent;
  public subDistrict: IAddressComponent;
  public street: IAddressComponent;
  public streetNumber: IAddressComponent;
  public estate: IAddressComponent;
  public block: IAddressComponent;
  public floor: IAddressComponent;
  public room: IAddressComponent;
  public isDefault: boolean;

  public livings: Living[];

  editing: boolean;
  form: AddressForm;

  constructor(address: IAddress) {
    this.uuId = address.uuId;
    this.region = address.region;
    this.district = address.district;
    this.subDistrict = address.subDistrict;
    this.street = address.street;
    this.streetNumber = address.streetNumber;
    this.estate = address.estate;
    this.block = address.block;
    this.floor = address.floor;
    this.room = address.room;
    this.isDefault = address.isDefault;
    this.livings = address.region.livingConditions
      .map((living) => new Living(living))
      .concat(
        address.region.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.district.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.subDistrict.livingConditions.map(
          (living) => new Living(living),
        ),
      )
      .concat(
        address.street.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.streetNumber.livingConditions.map(
          (living) => new Living(living),
        ),
      )
      .concat(
        address.estate.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.block.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.floor.livingConditions.map((living) => new Living(living)),
      )
      .concat(
        address.room.livingConditions.map((living) => new Living(living)),
      );

    this.editing = false;
    this.form = new AddressForm();
    this.form.formGroup.controls["region"].setValue(address.region.name);
    this.form.formGroup.controls["district"].setValue(address.district.name);
    this.form.formGroup.controls["subDistrict"].setValue(
      address.subDistrict.name,
    );
    this.form.formGroup.controls["street"].setValue(address.street.name);
    this.form.formGroup.controls["streetNumber"].setValue(
      address.streetNumber.name,
    );
    this.form.formGroup.controls["estate"].setValue(address.estate.name);
    this.form.formGroup.controls["building"].setValue(address.block.name);
    this.form.formGroup.controls["floor"].setValue(address.floor.name);
    this.form.formGroup.controls["room"].setValue(address.room.name);
  }

  public toString(): string {
    return (
      this.region.name +
      this.district.name +
      this.subDistrict.name +
      (this.street.name ? this.street.name : "") +
      (this.streetNumber.name ? this.streetNumber.name : "") +
      (this.estate.name ? this.estate.name : "") +
      this.block.name +
      (this.floor.name ? this.floor.name : "") +
      (this.room.name ? this.room.name : "")
    );
  }

  getUpdateAddressModel(): UpdateAddressModel {
    return {
      uuId: this.uuId,
      region: this.form.formGroup.controls["region"].value,
      district: this.form.formGroup.controls["district"].value,
      subDistrict: this.form.formGroup.controls["subDistrict"].value,
      street: this.form.formGroup.controls["street"].value,
      streetNumber: this.form.formGroup.controls["streetNumber"].value,
      estate: this.form.formGroup.controls["estate"].value,
      block: this.form.formGroup.controls["building"].value,
      floor: this.form.formGroup.controls["floor"].value,
      room: this.form.formGroup.controls["room"].value,
      isDefault: false,
      livingConditions: this.form.livings.map((lc) => lc.uuId),
    };
  }
}

export interface IAddressComponent {
  uuId: string;
  name: string | any;
  livingConditions: ILiving[];
}
