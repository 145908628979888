import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Organization } from 'src/app/models/organizations/organization';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public organizations: Organization[] = [];

  lang: string = this.translateService.currentLang;
  public form: FormGroup = new FormGroup({
    organization: new FormControl(null, [Validators.required]),
    username: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required])
  });

  constructor(
    private authService: AuthenticationService,
    private loadingService: LoadingService,
    private firebaseService: FirebaseService,
    private router: Router,
    private organizationService: OrganizationService,
    private translateService: TranslateService
  ) { }

  setLang(language: string): void {
    this.translateService.use(language).subscribe();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/main/overview']);
      return;
    }

    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading()
    this.organizationService.getOrganizationList()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: response => this.organizations = response
      });
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
      localStorage.setItem('lang', value.lang);
    })
  }

  submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loadingService.startLoading();
    this.authService.signIn({
      organizationCode: this.form.controls['organization'].value,
      username: this.form.controls['username'].value,
      password: this.form.controls['password'].value,
      deviceToken: this.firebaseService.getToken(),
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => this.router.navigate(['/main/overview']),
        error: error => {
          this.password.setValue(null);
          this.password.setErrors({ wrong: true });
        }
      });
  }

  get organization(): AbstractControl {
    return this.form.controls['organization'];
  }

  get username(): AbstractControl {
    return this.form.controls['username'];
  }

  get password(): AbstractControl {
    return this.form.controls['password'];
  }
}
