import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Observable, PartialObserver } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { AddressForm } from "src/app/modules/share/forms/address-form";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { TemplateService } from "src/app/services/template/template.service";

@Component({
  selector: "app-user-template-setting-page",
  templateUrl: "./user-template-setting-page.component.html",
  styleUrls: ["./user-template-setting-page.component.scss"],
})
export class UserTemplateSettingPageComponent implements OnInit {
  addressForm: AddressForm = new AddressForm();

  public columns: {
    uuId: string;
    columnName: string;
    columnDescription: string;
    display: boolean;
  }[] = [];

  public showColumnForm: boolean = false;
  public columnName: FormControl = new FormControl(null, [Validators.required]);
  public columnDescription: FormControl = new FormControl(null);
  public hideUser: boolean = false;

  constructor(
    private templateService: TemplateService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.fetchResource().subscribe(this.fetchResourceObserver);
    this.addressForm.disabled = true;
  }

  fetchResource(): Observable<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > {
    this.loadingService.startLoading();
    return this.templateService
      .getProfileColumns()
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }

  fetchResourceObserver: PartialObserver<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > = {
    next: (value) => {
      this.columns = value;
    },
  };

  public changeDisplay(uuid: string): void {
    this.columns.forEach((item) => {
      if (item.uuId == uuid) {
        this.templateService
          .updateProfileColumn(item.uuId, !item.display)
          .pipe(finalize(() => this.loadingService.stopLoading()))
          .subscribe({
            next: () => {
              this.resetForm();
              this.fetchResource().subscribe(this.fetchResourceObserver);
            },
          });
      }
    });
  }
  public saveColumn(): void {
    if (!this.columnName.valid) {
      this.columnName.markAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }
    this.loadingService.startLoading();
    this.templateService
      .setProfileColumn(
        this.columnName.value,
        this.columnDescription.value,
        !this.hideUser,
      )
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.resetForm();
          this.fetchResource().subscribe(this.fetchResourceObserver);
        },
      });
  }

  public removeColumn(uuid: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        subscriber.unsubscribe();

        var sub = this.popupMessageService.executeSuccessSignal.subscribe(
          (value) => {
            sub.unsubscribe();
          },
        );

        var ob = new Observable((subscriber) => {
          this.fetchResource().subscribe(this.fetchResourceObserver);
        });

        this.popupMessageService.messageSignal.emit(
          PopupMessages.RemoveTemplateColumnSuccessMessage(ob),
        );
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.RemoveTemplateColumnMessage(
        this.templateService.removeProfileColumn(uuid),
      ),
    );
  }

  public resetForm(): void {
    this.columnName.reset();
    this.columnDescription.reset();
    this.showColumnForm = false;
  }
}
