<div class="inline-flex-row align-items-center">
    <app-search-input class="flex-1" [placeholder]="'請輸入紀錄編號 或 姓名 或會員編號'" [size]="'xl'" [action]="searchFilterAction"></app-search-input>

    <button class="icon-button orange" (click)="fetch()">
        <i class="material-icons-round mb-1">refresh</i>
        <p translate>重新整理</p>
    </button>

    <button class="icon-button orange ml-5" [routerLink]="['/main/activity/create/request']">
        <i class="material-icons-round mb-1">inbox</i>
        <p translate>創建申請</p>
    </button>

    <button class="icon-button yellow ml-5" (click)="openImportPopup()">
        <i class="material-icons-round mb-1">unarchive</i>
        <p translate>滙入紀錄</p>
    </button>

    <button class="icon-button yellow ml-5" (click)="exportRequests()">
        <i class="material-icons-round mb-1">archive</i>
        <p translate>匯出所有</p>
    </button>
</div>

<div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
    <app-address-filter [action]="addressFilterAction"></app-address-filter>
    <app-service-filter [action]="serviceFilterAction" [title]="'服務類型'" class="ml-4"></app-service-filter>
    <app-service-date-range-filter [action]="dateFilterAction" class="ml-4"></app-service-date-range-filter>
    <app-request-status-filter [action]="statusFilterAction" class="ml-4"></app-request-status-filter>
</div>

<ng-container *ngTemplateOutlet="pagination"></ng-container>

<div class="list-table mt-4 mb-4" style="overflow-y:visible;">
    <div class="list-table-row header">
        <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
            <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p translate>紀錄編號</p>
            <div class="inline-flex-column" (click)="filters.idOrder = !filters.idOrder;filters.dateOrder = null;fetch()">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.idOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.idOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p translate>申請人</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
            <p translate>電話</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p translate>服務類型</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p matTooltip="{{ '服務日期' | translate }}" [matTooltipPosition]="'above'" translate>服務日期</p>
            <div class="inline-flex-column" (click)="filters.dateOrder = !filters.dateOrder;filters.idOrder = null;fetch()">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.dateOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.dateOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
            <p translate>狀態</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
            <p translate>時分</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 6rem;flex-grow: 12;">
            <p translate>義工</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;">
            <p translate>操作</p>
        </div>
    </div>
    <div class="list-table-row" *ngFor="let request of requests">
        <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
            <input type="checkbox" [(ngModel)]="request.selected" (change)="cacheRequest(request)">
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p><span [ngClass]="{'red-rot':request.redRot,'white-rot':!request.redRot}" ></span>{{ request.id }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p class="click-text-lightblue" [routerLink]="['/main/user/overview', request.requester.uuId]">{{ request.requester.chineseName }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
            <div class="inline-flex-row align-items-center" style="flex-basis: 6.5rem;">
                <p class="flex-1">{{ request.requester.phone }}</p>
                <img src="/v2/assets/mobile.svg" *ngIf="!request.requester.phone">
            </div>
            <p></p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p [matTooltip]="request.service.name" matTooltipPosition="above">{{ request.service.name }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p>{{ request.startTime | date: 'dd-MM-yyyy' }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
            <div class="status orange mr-2" *ngIf="request.requestStatus == 'Pending'">
                <p translate>等候配對</p>
            </div>
            <div class="status green mr-2" *ngIf="request.requestStatus == 'Ready'">
                <p translate>未開始</p>
            </div>
            <div class="status green mr-2" *ngIf="request.requestStatus == 'Started'">
                <p translate>進行中</p>
            </div>
            <div class="status grey mr-2" *ngIf="request.requestStatus == 'Finished'">
                <p translate>已完結</p>
            </div>
            <div class="status grey mr-2" *ngIf="request.requestStatus == 'Cancelled'">
                <p translate>已終止</p>
            </div>
            <div class="status red mr-2" *ngIf="request.matchingOvertime">
                <p translate>配對超時</p>
            </div>
            <div class="status red mr-2" *ngIf="request.startOvertime">
                <p translate>超時開始</p>
            </div>
            <div class="status red mr-2" *ngIf="request.endOvertime">
                <p translate>超時結束</p>
            </div>
            <p></p>
        </div>
        <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
            <p *ngIf=" request.totalUseTimeBalance == null">-</p>
            <p [matTooltip]="request.actualDuration" matTooltipPosition="above">{{ request.totalUseTimeBalance | number:'1.0-2' }}</p>
        </div>
        <div class="list-table-cell" [matTooltip]="request.volunteerNames" matTooltipPosition="above" style="flex-basis: 6rem;flex-grow: 12;">
            <p *ngIf="request.JoinedVolunteers.length > 0" style="flex-basis: 1rem;">{{ request.JoinedVolunteers[0].chineseName }}</p>
            <p *ngIf="request.JoinedVolunteers.length > 1">...+{{ request.JoinedVolunteers.length - 1}}</p>
            <p></p>
        </div>
        <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;position: relative;overflow: visible;">
            <p class="click-text-lightblue flex-0" [routerLink]="request.uuId" translate>檢視</p>
            <p class="click-text-lightblue flex-0 ml-8" (click)="deleteRequest(request.uuId)" translate>移除</p>
            <i class="material-icons-round click-text-lightblue ml-8" (click)="request.toggle = !request.toggle">more_horiz</i>
            <div class="operation-popup" [@dropdown]="request.toggle ? 'on' : 'off'">
                <p class="click-text-lightblue mt-8" [routerLink]="['/main/activity/overview/request', request.uuId, 'select-volunteers']" [ngClass]="{'disabled': request.requestStatus != 'Pending'}" translate>重新邀請</p>
                <p class="click-text-lightblue mt-8 mb-8" (click)="applyAgain(request)" translate>再次申請</p>
            </div>
        </div>
    </div>

    <div class="list-table-row" *ngIf="requests.length == 0">
        <div class="list-table-cell">
            <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
        </div>
    </div>
</div>

<ng-container *ngTemplateOutlet="pagination"></ng-container>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteRequestList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>