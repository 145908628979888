import { FormControl, Validators } from "@angular/forms";
import { Profile } from "./profile";

export class Donee {
  uuId: string;
  memberId: string;
  chineseName: string;
  centerCode: string;
  phone: string;
  timeBalance: number;
  amount: FormControl;

  constructor(profile: Profile) {
    this.uuId = profile.uuId;
    this.memberId = profile.memberId;
    this.chineseName = profile.chineseName;
    this.centerCode = profile.center.centerCode;
    this.phone = profile.phone;
    this.timeBalance = profile.timeBalance;
    this.amount = new FormControl(null, [
      Validators.required,
      Validators.pattern(
        "([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)",
      ),
    ]);
  }
}
