<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="feedback-box zoomIn" (click)="$event.stopPropagation()">
        <p class="click-text-blue align-self-end" (click)="reset()" translate>重置</p>

        <div class="inline-flex-row">
            <p translate>義工：</p>
            <p class="ml-4" *ngIf="volunteer">{{ volunteer.chineseName }}</p>
        </div>

        <div class="inline-flex-row" style="margin-top: 1.5rem;">
            <p style="line-height: 2.5rem;" translate>對申請人評價</p>
            <div class="inline-flex-column" style="padding-left: 2rem;">
                <div class="inline-flex-row" style="margin-bottom: 1.25rem;">
                    <i class="material-icons-round star" *ngFor="let star of stars" (click)="rate(star)">star</i>
                    <i class="material-icons-round star-border" *ngFor="let star of outlineStars" (click)="rate(star)">star_border</i>
                </div>

                <p class="text-lightgrey text-xs" *ngIf="volunteer && volunteer.volunteerCommentCreatedBy&&!volunteer.isVolunteerComment">{{ '該評價由職員' | translate: {value: volunteer.volunteerCommentCreatedBy } }}</p>

                <div class="inlineflex-column mt-6" *ngFor="let question of questions | keyvalue">
                    <ng-container *ngIf="question.key != 'OtherComment'">
                        <p style="white-space: pre-line;" *ngIf="question.value.Q.value == null">{{ question.value.Q }}</p>
                        <div class="inline-flex-row mt-6">
                            <button class="rating-button" [ngClass]="{'filled': question.value.A == 'no' || question.value.A.value == 'no'}" (click)="question.value.A.setValue('no')" [disabled]="disabled" translate>否</button>
                            <button class="rating-button" [ngClass]="{'filled': question.value.A == 'notClear' || question.value.A.value == 'notClear'}" (click)="question.value.A.setValue('notClear')" [disabled]="disabled" translate>不清楚</button>
                            <button class="rating-button" [ngClass]="{'filled': question.value.A == 'yes' || question.value.A.value == 'yes'}" (click)="question.value.A.setValue('yes')" [disabled]="disabled" translate>是</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="question.key == 'OtherComment'">
                    </ng-container>
                </div>
                <div class="inlineflex-column mt-6" *ngFor="let question of questions | keyvalue">
                    <ng-container *ngIf="question.key != 'OtherComment'">
                    </ng-container>
                    <ng-container *ngIf="question.key == 'OtherComment'">
                        <p style="white-space: pre-line;" *ngIf="question.value.Q.value == null" translate>VolunteerOtherComment</p>
                        <div class="inline-flex-row mt-6">
                            <textarea [disabled]="disabled" style="width: 22rem;height:8.625rem" [(ngModel)]="question.value.A"></textarea>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="flex-1"></div>

        <div class="flex-row justify-content-center" style="margin-left: -5rem;">
            <button class="button outline" (click)="close()" translate>返 回</button>
            <button class="button fill blue" style="margin-left: 7.5rem;" *ngIf="disabled == false" (click)="submit()" translate>確 認</button>
        </div>
    </div>
</div>