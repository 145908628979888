import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { EventParticipant } from 'src/app/models/events/event-participant';
import { EventService } from 'src/app/services/event/event.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { Event } from 'src/app/models/events/event';

@Component({
  selector: 'app-event-detail-accepted-user-tab',
  templateUrl: './event-detail-accepted-user-tab.component.html',
  styleUrls: ['./event-detail-accepted-user-tab.component.scss']
})
export class EventDetailAcceptedUserTabComponent implements OnInit {

  @Input() users: EventParticipant[] = [];
  @Input() closeAction: () => void = () => { }
  @Input() reloadAction: () => void = () => { }
  @Input() changeTabAction: (index: number) => void = () => { }
  @Input() vacancy: number = 0;
  @Input() event!: Event ;

  constructor(
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {

  }

  remove(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.reloadAction();
      subscriber.unsubscribe();
    });

    this.route.params.subscribe(value => {
      this.popupMessageService.messageSignal.emit(PopupMessages.RemoveUserFromEventMessage(
        this.eventService.removeParticipants(value.id, { userList: [uuId], role: "Attendant" })
      ));
    });
  }

  randomPick(): void {
    var numberOfPick = this.vacancy;
    while (this.selectedUsers.length < numberOfPick && !this.users.every(p => p.selected)) {
      var randomIndex = Math.floor(Math.random() * this.users.length);
      if (!this.users[randomIndex].selected) {
        this.users[randomIndex].selected = true;
      }
    }
  }

  joinEvent(): void {
    if (this.selectedUsers.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.ZeroParticipantErrorMessage);
      return;
    }

    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.eventService.joinEvent(value.id, { list: this.users.filter(u => u.selected).map(u => u.uuId) })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {
          this.reloadAction();
          this.changeTabAction(0);
          if (this.vacancy - this.selectedUsers.length <= 0 && this.users.filter(p => !p.selected).length > 0)
            this.unselectUsers();
        });
    });
  }

  private unselectUsers(): void {
    this.route.params.subscribe(value => {
      this.eventService.unselect(value.id, { list: this.users.filter(p => !p.selected).map(p => p.uuId) })
        .subscribe(value => {
          this.reloadAction();
        });
    });
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.users.forEach(user => user.selected = false);
    else
      this.users.forEach(user => user.selected = true);
  }

  get selectedUsers(): EventParticipant[] {
    return this.users.filter(user => user.selected);
  }

  get isSelectedAll(): boolean {
    return this.users.every(user => user.selected);
  }
  needRed(user:EventParticipant): boolean{
    return true;
    // var date=new Date();
    // date.setDate(date.getDate() - 1);
    // return (user.requestTime!=null&&user.requestTime>date&&this.event.eventJoinType=="Staff");
  }
}
