import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { DailyRequestCount } from "src/app/models/analytics/daily-request-count";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  ChartOptions,
  ChartConfiguration,
  ChartData,
  Filler,
  Tooltip,
} from "chart.js";

@Component({
  selector: "app-application-analysis",
  templateUrl: "./application-analysis.component.html",
  styleUrls: ["./application-analysis.component.scss"],
})
export class ApplicationAnalysisComponent implements AfterViewInit {
  chart!: Chart;
  dailyRequestCounts: DailyRequestCount[] = [];
  @Input() form!: FormControl;

  centerUUId?: string;
  startTime?: string;
  endTime?: string;

  @ViewChild("graph") graph!: ElementRef;
  context!: CanvasRenderingContext2D;

  constructor(private analyticsService: AnalyticsService) {}

  ngAfterViewInit(): void {
    Chart.register(
      CategoryScale,
      LinearScale,
      LineController,
      PointElement,
      LineElement,
      Filler,
      Tooltip,
    );

    this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext(
      "2d",
    ) as CanvasRenderingContext2D;

    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });

    this.analyticsService.getBalanceAnalysisDateRange().subscribe((value) => {
      this.startTime = value.startTime;
      this.endTime = value.endTime;
      this.fetch();
    });
  }

  fetch(): void {
    this.analyticsService
      .getDailyRequestCount(this.centerUUId, this.startTime, this.endTime)
      .subscribe((value) => {
        this.dailyRequestCounts = value;
        this.initChart();
      });
  }

  private initChart(): void {
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(this.context, this.getChartConfig());
  }

  private getChartConfig(): ChartConfiguration {
    return {
      type: "line",
      data: this.getChartData(),
      options: chartOptions,
    };
  }

  getChartData(): ChartData {
    return {
      labels: this.dailyRequestCounts
        .sort((a, b) =>
          a.createdDate > b.createdDate
            ? 1
            : a.createdDate < b.createdDate
              ? -1
              : 0,
        )
        .map((count) => count.createdDate),
      datasets: [
        {
          data: this.dailyRequestCounts
            .sort((a, b) =>
              a.createdDate > b.createdDate
                ? 1
                : a.createdDate < b.createdDate
                  ? -1
                  : 0,
            )
            .map((count) => count.number),
          fill: "origin",
          backgroundColor: "rgb(133, 67, 244)",
          borderColor: "rgb(133, 67, 244)",
          borderWidth: 1,
          tension: 0.25,
        },
      ],
    };
  }

  get totalRequestCount(): number {
    var total: number = 0;
    if (this.dailyRequestCounts.length > 0)
      this.dailyRequestCounts.forEach((count) => (total += count.number));
    return total;
  }

  get averageRequestCount(): number {
    return this.dailyRequestCounts.length > 0
      ? this.totalRequestCount / this.dailyRequestCounts.length
      : 0;
  }
}

const chartOptions: ChartOptions = {
  elements: {
    point: { radius: 0 },
  },
  plugins: {
    legend: { display: false },
  },
  scales: {
    x: {
      grid: { display: false },
      ticks: { display: false },
      display: false,
    },
    y: {
      grid: { display: false },
      ticks: { display: false },
      display: false,
    },
  },
};
