import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-service-date-range-filter",
  templateUrl: "./service-date-range-filter.component.html",
  styleUrls: ["./service-date-range-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class ServiceDateRangeFilterComponent implements OnInit {
  lang: string = this.translateService.currentLang;

  @Input() action: (start: Date, end: Date) => void = () => {};
  form: FormGroup = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  display: boolean = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((value) => {
      this.lang = value.lang;
    });
  }

  public toggle(): void {
    if (this.end.value != null) (<Date>this.end.value).setHours(23, 59, 59);

    if (this.display == true) {
      this.action(this.start.value, this.end.value);
    }
    this.display = !this.display;
  }

  clear(): void {
    this.form.reset();
  }

  get start(): AbstractControl {
    return this.form.controls["start"];
  }

  get end(): AbstractControl {
    return this.form.controls["end"];
  }
}
