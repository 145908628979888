import { Component, Input, OnInit } from '@angular/core';
import { IHINRemark, IHINDetail } from 'src/app/models/requests/volunteer-choice';

@Component({
  selector: 'app-volunteer-score-popup',
  templateUrl: './volunteer-score-popup.component.html',
  styleUrls: ['./volunteer-score-popup.component.scss']
})
export class VolunteerScorePopupComponent implements OnInit {
  @Input() hinRemark!: IHINRemark;
  @Input() hinDetail!: IHINDetail;

  display: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get sum(): number {
    console.log(this.hinDetail);
    if(this.hinDetail == null)
      return 0;

    return this.hinDetail.service
      + this.hinDetail.availability_start 
      + this.hinDetail.availability_end 
      + this.hinDetail.condition 
      + this.hinDetail.address 
      + this.hinDetail.completed 
      + this.hinDetail.failed;
  }
}
