export interface IApplicationSetting {
    volunteerPrivacyBirthday: string;
    volunteerPrivacyPhone: string;
    volunteerPrivacyAddress: string;
    volunteerPrivacyHealth: string;
    volunteerPrivacyContactPerson: string;
    volunteerPrivacyEducation: string;
    volunteerPrivacyPhoneVisibility: string;
    volunteerPrivacyList: string;

    requesterPrivacyBirthday: string;
    requesterPrivacyPhone: string;
    requesterPrivacyAddress: string;
    requesterPrivacyHealth: string;
    requesterPrivacyContactPerson: string;
    requesterPrivacyEducation: string;
    requesterPrivacyPhoneVisibility: string
    requesterPrivacyAddressVisibility: string;
    requesterPrivacyContactPersonVisibility: string;
    requesterAgentPrivacyVisibility: string;
}

export const DefaultApplicationSetting: IApplicationSetting = {
    volunteerPrivacyBirthday: "staff",
    volunteerPrivacyPhone: "staff",
    volunteerPrivacyAddress: "staff",
    volunteerPrivacyHealth: "staff",
    volunteerPrivacyContactPerson: "staff",
    volunteerPrivacyEducation: "staff",
    volunteerPrivacyPhoneVisibility: "staff",
    volunteerPrivacyList: "staff",

    requesterPrivacyBirthday: "staff",
    requesterPrivacyPhone: "staff",
    requesterPrivacyAddress: "staff",
    requesterPrivacyHealth: "staff",
    requesterPrivacyContactPerson: "staff",
    requesterPrivacyEducation: "staff",
    requesterPrivacyPhoneVisibility: "staff",
    requesterPrivacyAddressVisibility: "staff",
    requesterPrivacyContactPersonVisibility: "staff",
    requesterAgentPrivacyVisibility: "staff"
}
