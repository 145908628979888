import * as dayjs from "dayjs";
import { CreateUserModel } from "../profiles/create-user-model";
import { IProfile } from "../profiles/profile";
import { UpdateUserModel } from "../profiles/update-user-model";

export interface IUserImportModel {
  "會員編號*": string;
  "會員姓名*": string;
  會員暱稱: string;
  "性別*": string;
  "中心編號*（須和後台設置中的中心編號一致）": string;
  "出生日期*（YYYY-MM-DD）": string;
  "電話號碼*": string;
  "登入帳號*": string;
  "會員密碼*": string;
  "網卡配備*（是否有數據可上網）": string;
  "讀寫能力*": string;
  "教育程度*": string;
  "住址（地區）*": string;
  "住址（分區）*": string;
  "住址（區）": string;
  "住址（街道）": string;
  "住址（街號）": string;
  "屋苑／屋邨": string;
  "住址（大廈/期/座）": string;
  樓層: string;
  單位: string;
  "居住條件（須和後台設置中的資料一致）": string;
  "身體狀況（須和後台設置中的資料一致）": string;
  緊急聯絡人名稱: string;
  緊急聯絡人電話: string;
  "可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）": string;
  "細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）": string;
  電話型號: string;
  "智能電話？（是否可安裝使用app）": string;
  備註: string;
}

export class UserImportModel {
  uuId: string | null;
  index: number;
  username: string;
  password: string;
  memberId: string;
  phoneNumber: string;
  profile: any;

  constructor(model: IUserImportModel, index: number) {
    this.uuId = null;
    this.index = index;
    this.username = model["登入帳號*"]?.trim();
    this.password = model["會員密碼*"]?.trim();
    this.memberId = model["會員編號*"]?.trim();
    this.phoneNumber = model["電話號碼*"]?.trim();
    this.profile = {
      chineseName: model["會員姓名*"]?.trim(),
      englishName: model["會員暱稱"]?.trim(),
      gender:
        model["性別*"]?.trim() === "男"
          ? "M"
          : model["性別*"]?.trim() === "女"
            ? "F"
            : null,
      CenterCode: model["中心編號*（須和後台設置中的中心編號一致）"]?.trim(),
      dateOfBirth: dayjs(model["出生日期*（YYYY-MM-DD）"]).toJSON(),
      mobilePhoneModel: model["電話型號"]?.trim(),
      hasSimCard:
        model["網卡配備*（是否有數據可上網）"] == "是"
          ? true
          : model["網卡配備*（是否有數據可上網）"] == "不是"
            ? false
            : null,
      addresses: [
        {
          region: model["住址（地區）*"]?.trim(),
          district: model["住址（分區）*"]?.trim(),
          subDistrict: model["住址（區）"] ? model["住址（區）"]?.trim() : "",
          street: model["住址（街道）"] ? model["住址（街道）"]?.trim() : "",
          streetNumber: model["住址（街號）"]
            ? model["住址（街號）"]?.trim()
            : "",
          estate: model["屋苑／屋邨"]?.trim()
            ? model["屋苑／屋邨"]?.trim()
            : "",
          block: model["住址（大廈/期/座）"]
            ? model["住址（大廈/期/座）"]?.trim()
            : "",
          floor: model["樓層"] ? model["樓層"]?.trim() : "",
          room: model["單位"] ? model["單位"].trim() : "",
          livingConditions: model["居住條件（須和後台設置中的資料一致）"]
            ? model["居住條件（須和後台設置中的資料一致）"]
                .split(",")
                .map((s) => s.trim())
            : [],
          isDefault: true,
        },
      ],
      literacy:
        model["讀寫能力*"]?.trim() == "能讀能寫"
          ? "CanReadAndWrite"
          : model["讀寫能力*"]?.trim() == "只能讀，不能寫"
            ? "CanRead"
            : "CanSpeak",
      educationLevel:
        model["教育程度*"]?.trim() == "大學或以上"
          ? "University"
          : model["教育程度*"]?.trim() == "中學"
            ? "Secondary"
            : model["教育程度*"]?.trim() == "小學"
              ? "Primary"
              : "None",
      district: null,
      HKID: null,
      MarriageStatus: null,
      Career: null,
      Remark: model["備註"],
      services: {
        services: model[
          "可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）"
        ]
          ? model[
              "可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）"
            ]
              .split(",")
              .map((s) => s.trim())
          : [],
      },
      healthConditions: {
        healthConditions: model["身體狀況（須和後台設置中的資料一致）"]
          ? model["身體狀況（須和後台設置中的資料一致）"]
              .split(",")
              .map((s) => s.trim())
          : [],
      },
      availabilities: {
        availabilities: [],
      },
      cellGroups: model[
        "細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）"
      ]
        ? model[
            "細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）"
          ]
            .split(",")
            .map((s) => s.trim())
        : [],
      contactPersonName: model["緊急聯絡人名稱"]?.trim(),
      contactPersonPhoneNumber: model["緊急聯絡人電話"]?.trim(),
      extraPersonJson: "{}",
    };
  }

  public selfValidate(): string | null {
    if (!this.profile.gender)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "未輸入性別\n"
      );
    if (!this.profile.chineseName)
      return "第" + (this.index + 7) + "列: " + "請輸入姓名\n";
    if (!this.username || this.username.length > 8)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的登入帳號格式錯誤\n"
      );
    if (
      (this.uuId == null && (!this.password || this.password.length < 4)) ||
      (!this.uuId &&
        (this.password == null ||
          (this.password != null && this.password.length < 4)))
    )
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的登入密碼格式錯誤\n"
      );
    if (!this.memberId || this.memberId.length > 15)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的會員編號格式錯誤\n"
      );
    if (!this.profile.dateOfBirth)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的出生日期格式錯誤\n"
      );
    if (!this.profile.CenterCode)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的中心不存在\n"
      );
    if ((this.profile.services.services as string[]).some((s) => s === null))
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的服務包含不存在的服務\n"
      );
    if (
      (this.profile.addresses[0].livingConditions as string[]).some(
        (s) => s === null,
      )
    )
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的居住條件包含不存在的居住條件\n"
      );
    if (
      (this.profile.healthConditions.healthConditions as string[]).some(
        (h) => h === null,
      )
    )
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的健康條件包含不存的健康條件\n"
      );
    if (this.phoneNumber && this.phoneNumber.length !== 8)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的電話號碼只能有8個數字\n"
      );
    if ((this.profile.cellGroups as string[]).some((group) => group == null))
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的細包小組包含不存在的小組\n"
      );
    if (this.profile.hasSimCard == null)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的網卡配備格式錯誤\n"
      );
    if (this.profile.addresses[0].region == null)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的住址（地區）格式錯誤\n"
      );
    if (this.profile.addresses[0].district == null)
      return (
        "第" +
        (this.index + 7) +
        "列: " +
        this.profile.chineseName +
        "的住址（分區）格式錯誤\n"
      );
    return null;
  }

  getCreateUserModel(): CreateUserModel {
    return {
      username: this.username,
      password: this.password,
      memberId: this.memberId,
      phoneNumber: this.phoneNumber,
      profile: {
        chineseName: this.profile.chineseName,
        englishName: this.profile.englishName,
        dateOfBirth: this.profile.dateOfBirth,
        gender: this.profile.gender,
        district: null,
        HKID: null,
        MarriageStatus: null,
        educationLevel: this.profile.educationLevel,
        literacy: this.profile.literacy,
        Career: null,
        Remark: this.profile.Remark,
        HasSimCard: this.profile.hasSimCard,
        MobilePhoneModel: this.profile.mobilePhoneModel,
        CenterCode: this.profile.CenterCode,
        addresses: this.profile.addresses,
        healthConditions: {
          healthConditions: this.profile.healthConditions.healthConditions,
        },
        services: {
          services: this.profile.services.services,
        },
        contactPersonName: this.profile.contactPersonName,
        contactPersonPhoneNumber: this.profile.contactPersonPhoneNumber,
        availabilities: {
          availabilities: [
            {
              uuId: null,
              weekDay: 0,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 1,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 2,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 3,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 4,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 5,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
            {
              uuId: null,
              weekDay: 6,
              availabilityStart: "2000-02-08T22:00:00.000Z",
              availabilityEnd: "2000-02-09T13:00:00.000Z",
            },
          ],
        },
        extraPersonJson: "{}",
        cellGroups: this.profile.cellGroups,
      },
    };
  }

  getUpdateUserModel(): UpdateUserModel {
    return {
      phoneNumber: this.phoneNumber,
      chineseName: this.profile.chineseName,
      englishName: this.profile.englishName,
      dateOfBirth: this.profile.dateOfBirth,
      gender: this.profile.gender,
      district: null,
      HKID: null,
      MarriageStatus: null,
      EducationLevel: this.profile.educationLevel,
      Literacy: this.profile.literacy,
      Career: null,
      Remark: this.profile.Remark,
      HasSimCard: this.profile.hasSimCard,
      MobilePhoneModel: this.profile.mobilePhoneModel,
      CenterCode: this.profile.CenterCode,
      addresses: this.profile.addresses,
      healthConditions: {
        healthConditions: this.profile.healthConditions.healthConditions,
      },
      services: {
        services: this.profile.services.services,
      },
      contactPersonName: this.profile.contactPersonName,
      contactPersonPhoneNumber: this.profile.contactPersonPhoneNumber,
      availabilities: null,
      extraPersonJson: "{}",
      cellGroups: this.profile.cellGroups,
    };
  }
}
