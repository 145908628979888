<form class="box" [formGroup]="form">
    <div class="time_cat">
        <p class="header-title" translate>服務/活動進程</p>
        
        <div class="setting-row">
            <p class="title" translate>服務申請時限</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="inviteLimitDay">
                    <option [ngValue]=day *ngFor="let day of days" translate>{{ '日' | translate: { value: day } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="inviteLimitHour">
                    <option [ngValue]=hour *ngFor="let hour of hours" translate>{{ '小時' | translate: { value: hour } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="inviteLimitMinute">
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>設置後，會員只可申請時限外的義工服務</p>
        </div>
        <div class="setting-row">
            <p class="title" translate>配對超時狀態</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="requestNotificationStaffDay">
                    <option [ngValue]=day *ngFor="let day of days" translate>{{ '日' | translate: { value: day } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="requestNotificationStaffHour">
                    <option [ngValue]=hour *ngFor="let hour of hours" translate>{{ '小時' | translate: { value: hour } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="requestNotificationStaffMinute">
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>超過該時限，尚未有足夠義工確認配對，該紀錄狀態會顯示為“配對超時”</p>
        </div>
        <div class="setting-row">
            <p class="title" translate>邀請結果通知</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="requestNotificationDay">
                    <option [ngValue]=day *ngFor="let day of days" translate>{{ '日' | translate: { value: day } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="requestNotificationHour">
                    <option [ngValue]=hour *ngFor="let hour of hours" translate>{{ '小時' | translate: { value: hour } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="requestNotificationMinute">
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>超過該時限，尚未有足夠義工確認配對，系統會發出通知給申請人</p>
        </div>
      
        <div class="setting-row">
            <p class="title" translate>預計開始前</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="checkinLimit" >
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate [translateParams]="{ value: minute }">分鐘</option>
                </select>
            </div>
            <div  class=" ml-5">
                <label><input type="checkbox" formControlName="IsFullDayStart" value="true"  (click)="disabledCheckInLimit()"><p class="ml-5" translate>同一服務日期內，預計開始時間前的任何時段</p></label>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>設定義工服務可在服務開始前確認報到的時限</p>
        </div>

        <div class="setting-row">
            <p class="title" translate>預計開始後</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="checkoutLimit" >
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                </select>
            </div>
            <div  class=" ml-5">
                <label><input type="checkbox" formControlName="IsFullDayEnd" value="true" (click)="disabledCheckOutLimit()"><p class="ml-5"  translate>同一服務日期內，預計結束時間前的任何時段</p></label>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>設定義工服務可在服務開始後確認報到的時限</p>
        </div>

        <div class="setting-row">
            <p class="title" translate>鎖定開始時限</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="startLimit">
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                    <option [ngValue]="7200" translate>不鎖單</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>超過該時限尚未有確認服務開始，應用程式會鎖定該服務紀錄，須經過職員後台處理</p>
        </div>

        <div class="setting-row">
            <p class="title" translate>鎖定結束時限</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="endLimit">
                    <option [ngValue]=minute *ngFor="let minute of minutes" translate>{{ '分鐘' | translate: { value: minute } }}</option>
                    <option [ngValue]="7200" translate>不鎖單</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>超過該時限尚未有確認服務結束，應用程式會鎖定該服務紀錄，須經過職員後台處理</p>
        </div>
    </div>

    <div class="time_cat">
        <p class="header-title" translate>中心活動時限</p>
        <div class="setting-row">
            <p class="title" translate>人數不足狀態</p>
            <div class="remark" translate>截止報名日期前</div>
            <div class="user-select">
                <select class="user-select sm" formControlName="eventNotificationDay">
                    <option [ngValue]=day *ngFor="let day of days" translate>{{ '日' | translate: { value: day } }}</option>
                </select>
            </div>
            <div class="user-select ml-5">
                <select class="user-select sm" formControlName="eventNotificationHour">
                    <option [ngValue]=hour *ngFor="let hour of hours" translate>{{ '小時' | translate: { value: hour } }}</option>
                </select>
            </div>
            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>到該時限後，尚未有足夠人數參加，狀態會顯示為“人數不足”</p>
        </div>
    </div>

    <div class="time_cat">
        <p class="header-title" translate>垃圾桶</p>
        <div class="setting-row">
            <p class="title" translate>垃圾桶刪除時限</p>
            <div class="user-select">
                <select class="user-select sm" formControlName="trashClearDay">
                    <option [ngValue]=day *ngFor="let day of trashDays" translate>{{ '日' | translate: { value: day } }}</option>
                </select>
            </div>

            <i class="material-icons-round ml-5 text-lightblue text-lg">help_outline</i>
            <p class="text-lightgrey text-xs ml-3" style="flex: 0 0 auto;" translate>紀錄在垃圾桶中儲存到該時限後，系統自動會永久清除該紀錄</p>
        </div>
    </div>
    <div class="inline-flex-row justify-content-center" style="margin-top: 5rem;">
        <button type="button" class="button md fill blue" (click)="save()" translate>保存</button>
    </div>
</form>