import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import {
  catchError,
  defaultIfEmpty,
  finalize,
  switchMap,
  tap,
} from "rxjs/operators";
import { RequestService } from "src/app/services/request/request.service";
import { CreateRequestRecordForm } from "src/app/modules/request/forms/create-request-record-form";
import { Profile } from "src/app/models/profiles/profile";
import { LoadingService } from "src/app/services/loading/loading.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { ServiceService } from "src/app/services/service/service.service";
import { TransportationService } from "src/app/services/transportation/transportation.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { TemplateService } from "src/app/services/template/template.service";
import { OrganizationService } from "src/app/services/organization/organization.service";
import { ServiceType } from "src/app/models/services/service-type";
import { Transportation } from "src/app/models/transportations/transportation";
import { Living } from "src/app/models/livings/living";
import { Service } from "src/app/models/services/service";
import { AddressForm } from "src/app/modules/share/forms/address-form";
import { PopupMessages } from "src/app/backend/popup-message";
import { PortalAnimation } from "src/app/portal-animation";
import {
  RequestImportModel,
  RequestVolunteerImportModel,
} from "src/app/models/imports/request-import-model";
import { RequestRecordModel } from "src/app/models/requests/create-request-model";

@Component({
  selector: "app-request-record",
  templateUrl: "./request-record.component.html",
  styleUrls: ["./request-record.component.scss"],
  animations: [PortalAnimation.ActivityPageSlide],
})
export class RequestRecordComponent implements OnInit {
  form: CreateRequestRecordForm = this.requestService.requestRecordForm;
  requester: Profile | null = null;
  serviceTypes: ServiceType[] = [];
  transportations: Transportation[] = [];
  venueForms: AddressForm[] = [];
  destinationForms: AddressForm[] = [];
  minDate: Date = new Date();
  constructor(
    private requestService: RequestService,
    private profileService: ProfileService,
    private serviceService: ServiceService,
    private loadingService: LoadingService,
    private transportationService: TransportationService,
    private popupMessageService: PopupMessageService,
    private templateService: TemplateService,
    private router: Router,
    private organizationService: OrganizationService,
  ) {}
  ngOnInit(): void {
    this.form.formGroup.disable({ onlySelf: true, emitEvent: false });

    this.fetch();
  }
  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      profile:
        this.form.requester == null
          ? of(null)
          : this.profileService.getProfileByUUId(this.form.requester),
      service: this.serviceService.getServiceTypeList(),
      transportation: this.transportationService.getTransportationList(),
      templates: this.templateService.getRequestColumns(),
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.requester = value.profile;
        this.serviceTypes = value.service;
        this.transportations = value.transportation;
        if (value.profile != null)
          this.form.formGroup.enable({ onlySelf: true, emitEvent: false });
        this.form.formGroup.controls["numberOfVolunteer"].disable({
          onlySelf: true,
          emitEvent: false,
        });
        if (this.form.formGroup.controls["service"].value) {
          let st = this.serviceTypes.find((serviceType) =>
            serviceType.services.some(
              (s) => s.uuId == this.form.formGroup.controls["service"].value,
            ),
          );
          this.form.formGroup.controls["serviceType"].setValue(st?.uuId, {
            emitEvent: false,
          });
        }

        if (this.form.columns.length == 0) {
          this.form.columns = value.templates.map((column) => {
            return {
              columnName: column.columnName,
              columnDescription: column.columnDescription,
              input: new FormControl({
                value: null,
                disabled: this.form.formGroup.disabled,
              }),
            };
          });
        } else {
          this.form.columns.forEach((col) => {
            if (this.form.formGroup.disabled) col.input.disable();
            else col.input.enable();
          });
        }
      });
  }

  reset(): void {
    this.requester = null;
    this.form.reset();
    this.form.formGroup.disable();
  }
  canSubmit(): boolean {
    if (!this.form.valid) return false;
    if (this.form.volunteers.length == 0) return false;

    var hasnull = false;
    this.form.volunteers.forEach((user) => {
      if (user.recordForm.value == "" || isNaN(user.recordForm.value)) {
        hasnull = true;
      }
    });
    if (hasnull) return false;
    return true;
  }
  submit(): void {
    if (!this.canSubmit()) {
      this.form.formGroup.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    var model: RequestRecordModel;
    var startDT = this.form.formGroup.controls["start"].value;
    var endDT = this.form.formGroup.controls["end"].value;
    var vlist: RequestVolunteerImportModel[] = this.form.volunteers.map(
      (user) => {
        return new RequestVolunteerImportModel(
          user.userUUId,
          startDT,
          endDT,
          user.recordForm.value,
        );
      },
    );
    if (this.requester == null) return;
    else
      model = new RequestRecordModel(
        this.requester.uuId,
        this.venue.value,
        this.destination.value,
        this.service.value,
        startDT,
        endDT,
        this.form.formGroup.controls["remark"].value,
        vlist,
        vlist.length,
        this.form.formGroup.controls["transportation"].value,
        this.form.stringtifyColumns(this.form.columns),
      );
    console.log("start Send ImportRequest", model);
    this.loadingService.startLoading();
    this.requestService
      .CreateRequestRecord(model)
      .pipe(
        catchError((error) => {
          this.loadingService.stopLoading();
          return EMPTY;
        }),
        finalize(() => {
          this.loadingService.stopLoading();
          return;
        }),
      )
      .subscribe((value) => {
        console.log("end Send ImportRequest", value);
        this.newRequestUUId = value.result.uuid;
        this.reset();
        this.router.navigate([
          "/main/activity/overview/request",
          this.newRequestUUId,
        ]);
      });
  }

  addVenue(): void {
    this.venueForms.push(new AddressForm());
  }

  saveVenue(): void {
    if (
      this.venueForms.length == 0 ||
      !this.venueForms.every((form) => form.formGroup.valid)
    ) {
      this.venueForms.forEach((form) => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    this.loadingService.startLoading();

    forkJoin(
      this.venueForms.map((form) =>
        this.profileService.createAddress(
          this.requester?.uuId as string,
          form.getCreateAddressModel(),
        ),
      ),
    )
      .pipe(
        defaultIfEmpty(),
        finalize(() => this.loadingService.stopLoading()),
      )
      .subscribe((value) => {
        this.venueForms = [];
        this.fetch();
      });
  }

  dropVenue = (() => {
    this.venueForms = [];
  }).bind(this);
  dropUser(index: number): void {
    this.form.volunteers.splice(index, 1);
  }
  addDestination(): void {
    this.destinationForms.push(new AddressForm());
  }

  saveDestination(): void {
    if (
      this.destinationForms.length == 0 ||
      !this.destinationForms.every((form) => form.formGroup.valid)
    ) {
      this.destinationForms.forEach((form) =>
        form.formGroup.markAllAsTouched(),
      );
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    this.loadingService.startLoading();

    forkJoin(
      this.destinationForms.map((form) =>
        this.profileService.createAddress(
          this.requester?.uuId as string,
          form.getCreateAddressModel(),
        ),
      ),
    )
      .pipe(
        defaultIfEmpty(),
        finalize(() => this.loadingService.stopLoading()),
      )
      .subscribe((value) => {
        this.destinationForms = [];
        this.fetch();
      });
  }

  dropDestination = (() => {
    this.destinationForms = [];
  }).bind(this);

  dropVolunteer(index: number) {
    this.form.volunteers.splice(index, 1);
  }

  get serviceType(): AbstractControl {
    return this.form.formGroup.controls["serviceType"];
  }

  get service(): AbstractControl {
    return this.form.formGroup.controls["service"];
  }

  get date(): AbstractControl {
    return this.form.formGroup.controls["date"];
  }

  get start(): AbstractControl {
    return this.form.formGroup.controls["start"];
  }

  get end(): AbstractControl {
    return this.form.formGroup.controls["end"];
  }

  get noEndTime(): AbstractControl {
    return this.form.formGroup.controls["noEndTime"];
  }

  get venue(): AbstractControl {
    return this.form.formGroup.controls["venue"];
  }

  get destination(): AbstractControl {
    return this.form.formGroup.controls["destination"];
  }

  get numberOfVolunteer(): AbstractControl {
    return this.form.formGroup.controls["numberOfVolunteer"];
  }

  get selectedServiceTypeServices(): Service[] {
    var type = this.serviceTypes.find(
      (st) => st.uuId == this.serviceType.value,
    );
    return type ? type.services : [];
  }

  get hasDestination(): boolean {
    let service = this.selectedServiceTypeServices.find(
      (service) => service.uuId == this.service.value,
    );
    return service ? service.destinationNeeded : false;
  }

  get selectedVenueLivingConditions(): Living[] {
    let address = this.requester?.addresses.find(
      (address) => address.room.uuId == this.venue.value,
    );
    return address ? address.livings : [];
  }

  get selectedDestinationLivingCondition(): Living[] {
    let address = this.requester?.addresses.find(
      (address) => address.room.uuId == this.destination.value,
    );
    return address ? address.livings : [];
  }

  //For holding uuid after create only
  private newRequestUUId: string | null = null;
}
