import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IAvailability {
  uuId: string | null;
  weekDay: number;
  availabilityStart: any;
  availabilityEnd: any;
}

export class Availability {
  public uuId: string | null;
  public weekDay: number;
  public availabilityStart: Date;
  public availabilityEnd: Date;

  constructor(availability: IAvailability) {
    this.uuId = availability.uuId;
    this.weekDay = availability.weekDay;
    this.availabilityStart = dayjs.utc(availability.availabilityStart).toDate();
    this.availabilityEnd = dayjs.utc(availability.availabilityEnd).toDate();
  }
}
