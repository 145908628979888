<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="request-terminate-popup zoomIn" (click)="$event.stopPropagation()">
        <p translate>請選譯中止原因</p>

        <label class="align-self-start mt-4" *ngFor="let reason of reasons">
            <input class="mr-4" type="checkbox" [(ngModel)]="reason[1]">
            <p>{{ reason[0] | translate }}</p>
        </label>

        <textarea [(ngModel)]="reasonText" class="mt-4 mb-4" style="height: 7.5rem;" placeholder="{{ '請選譯中止原因' | translate }}"></textarea>

        <div class="inline-flex-row justify-content-end">
            <button class="button outline xs" (click)="close()" translate>取 消</button>
            <button class="button fill xs red ml-4" (click)="cancelRequest()" translate>終 止</button>
        </div>
    </div>
</div>