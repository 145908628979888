import { FormGroup, FormControl } from "@angular/forms";
import { CreateCenterDonationModel } from "src/app/models/donations/create-center-donation-model";
import { Donee } from "src/app/models/profiles/donee";

export class CreateCenterDonationForm {
    users: Donee[];
    formGroup: FormGroup;

    constructor() {
        this.users = [];
        this.formGroup = new FormGroup({
            remark: new FormControl(null)
        })
    }

    getCreateCenterDonationModel(): CreateCenterDonationModel {
        return {
            list: this.users.map(user => { return { UserUUId: user.uuId, Amount: user.amount.value } }),
            remark: this.formGroup.controls['remark'].value
        }
    }

    reset(): void {
        this.users = [];
        this.formGroup.reset();
    }
}