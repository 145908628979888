import { Component, Input, OnInit } from "@angular/core";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-redeem-status-filter",
  templateUrl: "./redeem-status-filter.component.html",
  styleUrls: ["./redeem-status-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class RedeemStatusFilterComponent implements OnInit {
  @Input() action: (statuses: number[]) => void = () => {};
  statuses: { name: string; value: number; selected: boolean }[] = [
    { name: "未發布", value: 0, selected: false },
    { name: "進行中", value: 1, selected: false },
    { name: "已結束", value: 2, selected: false },
    { name: "等待抽籤", value: 3, selected: false },
    { name: "庫存不足", value: 4, selected: false },
    { name: "已修改", value: 5, selected: false },
    { name: "已下架", value: 6, selected: false },
  ];

  display: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  reset(): void {
    this.statuses.forEach((status) => (status.selected = false));
  }

  toggle(): void {
    if (this.display == true) {
      this.action(
        this.statuses
          .filter((status) => status.selected)
          .map((status) => status.value),
      );
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter((status) => status.selected).length;
  }
}
