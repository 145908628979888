import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { forkJoin, Observable } from 'rxjs';
import { buffer } from 'rxjs/operators';
import { AppSetting } from 'src/app/backend/app-setting';
import { HttpService } from 'src/app/backend/http.service';
import { ServiceRatio } from 'src/app/models/analytics/service-ratio';
import { TransactionChartData } from 'src/app/models/analytics/transaction-chart-data';
import { Event } from 'src/app/models/events/event';
import { DonationExportModel } from 'src/app/models/exports/donation-export-model';
import { EventExportModel } from 'src/app/models/exports/event-export-model';
import { ProductExportModel } from 'src/app/models/exports/product-export-model';
import { RequestExportModel } from 'src/app/models/exports/request-export-model';
import { TransactionExportModel } from 'src/app/models/exports/transaction-export-model';
import { UserExportModel } from 'src/app/models/exports/user-export-model';
import { Profile } from 'src/app/models/profiles/profile';
import { Product } from 'src/app/models/redeems/product';
import { Redeem } from 'src/app/models/redeems/redeem';
import { RedeemHistory } from 'src/app/models/redeems/redeem-history';
import { UserTransaction } from 'src/app/models/transactions/user-transaction';
import { OrganizationService } from '../organization/organization.service';
import { TemplateService } from '../template/template.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private httpService: HttpService,
    private templateService: TemplateService,
    private organizationService: OrganizationService
  ) { }
  async exportInactiveUser(users:Profile[],dataType: string,startDate?:Date,endDate?:Date){
    let builder: ReportBuilder = new ReportBuilder("Inactive User Export");
    builder.setCellValue("A1", "不活躍會員"); 
    builder.setWidth("B",20);
    builder.setWidth("D",20);

    builder.sheet.mergeCells('A1:D1');
    builder.setHeight(1,38);
    builder.setCellMiddle("A1");
    builder.setBorder("A1");

    builder.setHeight(2,38);
    builder.setCellValue('A2', "種類");
    builder.setBorder('A2');
    builder.setCellMiddle("A2");
    builder.setBackgroundColor('A2', 'FCF2D1');

    builder.setCellValue('B2',(dataType=="Request"?"義工服務":"中心活動"));
    builder.setBorder('B2');
    builder.setCellMiddle("B2");
    builder.sheet.mergeCells('B2:D2');

    var nowDate=new Date();
    var offset = nowDate.getTimezoneOffset();
    nowDate = new Date(nowDate.getTime() - (offset*60*1000));
    if(!startDate||!endDate){
      endDate=new Date();
      startDate=new Date(nowDate.setMonth(nowDate.getMonth()+8));
      startDate.setTime(startDate.getTime()  - (offset*60*1000))
    }else{
      startDate.setTime(startDate.getTime() - (offset*60*1000))
      endDate.setTime(endDate.getTime() - (offset*60*1000))
    }
   

    builder.setHeight(3,38);
    builder.setCellValue('A3', "初始日期");
    builder.setBorder('A3');
    builder.setCellMiddle("A3");
    builder.setBackgroundColor('A3', 'FCF2D1');


    builder.sheet.getCell(`B3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`B3`).value=startDate;
    builder.setBorder('B3');
    builder.setCellMiddle("B3");

    builder.setCellValue('C3', "結束日期");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'FCF2D1');
    builder.setCellMiddle("C3");

    builder.sheet.getCell(`D3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`D3`).value=endDate;
    builder.setBorder('D3');
    builder.setCellMiddle("D3");

    builder.setHeight(4,26);
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'F4B084');
    builder.setCellValue('A4', "會員編號");


    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'F4B084');
    builder.setCellValue('B4', "中文姓名");

    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'F4B084');
    builder.setCellValue('C4', "性別");

    builder.setBorder('D4');
    builder.setBackgroundColor('D4', 'F4B084');
    builder.setCellValue('D4', "中心代碼");
    builder.setBorder('E4');
    builder.setBackgroundColor('E4', 'F4B084');
    builder.setCellValue('E4', "電話");
    builder.setBorder('F4');
    builder.setBackgroundColor('F4', 'F4B084');
    builder.setCellValue('F4', "時分");

    var index=5;

    users.forEach((user,key)=>{
      builder.setBorder(`A${index}`);
      builder.setCellValue(`A${index}`, user.memberId+"");
      

      builder.setBorder(`B${index}`);
      builder.sheet.getCell(`B${index}`).value=user.chineseName;
  
      builder.setBorder(`C${index}`);
      builder.sheet.getCell(`C${index}`).value=user.gender=="M"?"男":"女";


      builder.setBorder(`D${index}`);
      builder.sheet.getCell(`D${index}`).value= user.center.centerCode ;
      builder.setBorder(`E${index}`);
      builder.sheet.getCell(`E${index}`).value= user.phone ;
      builder.setBorder(`F${index}`);
      builder.sheet.getCell(`F${index}`).numFmt = '0'
      builder.sheet.getCell(`F${index}`).value= user.timeBalance ;

      index++;
    })


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Inactive_User_Data_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  async exportCellGroupData(list:{ cellGourpName: string, NumOfTime: number|undefined , NumOfPoeper: number|undefined}[],dataType: string,StateType: string,startDate?:Date,endDate?:Date,serivceType?:string|undefined){
    let builder: ReportBuilder = new ReportBuilder("Service Ratio Export");

    builder.setCellValue("A1", "細胞小組分析"); 
     builder.setWidth("B",20);
    builder.setWidth("D",20);

    builder.sheet.mergeCells('A1:D1');
    builder.setHeight(1,38);
    builder.setCellMiddle("A1");
    builder.setBorder("A1");

    builder.setHeight(2,38);
    builder.setCellValue('A2', "種類");
    builder.setBorder('A2');
    builder.setCellMiddle("A2");
    builder.setBackgroundColor('A2', 'FCF2D1');
    var substring="";
    if(dataType=="Request"){
      substring+="-";
      switch(StateType){
        case "All":substring+="全部";break;
        case "Requester":substring+="找幫手";break;
        case "Volunteer":substring+="幫他人";break;
      }
    }
    builder.setCellValue('B2',(dataType=="Request"?"義工服務":"中心活動")+substring);
    builder.setBorder('B2');
    builder.setCellMiddle("B2");

    builder.setCellValue('C2', dataType=="Request"?"服務種類":"活動種類");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'FCF2D1');
    builder.setCellMiddle("C2");

    builder.setCellValue('D2',serivceType==""||typeof serivceType=="undefined"||serivceType==null?"全部":serivceType);
    builder.setBorder('D2');
    builder.setCellMiddle("D2");

    var nowDate=new Date();
    var offset = nowDate.getTimezoneOffset();
    nowDate = new Date(nowDate.getTime() - (offset*60*1000));
    if(!startDate||!endDate){
      endDate=nowDate;
      startDate=new Date(nowDate.getFullYear(),0,1);
      startDate.setTime(startDate.getTime() - (offset*60*1000))
    }else{
      startDate.setTime(startDate.getTime() - (offset*60*1000))
      endDate.setTime(endDate.getTime() - (offset*60*1000))
    }
   


    builder.setHeight(3,38);
    builder.setCellValue('A3', "初始日期");
    builder.setBorder('A3');
    builder.setCellMiddle("A3");
    builder.setBackgroundColor('A3', 'FCF2D1');


    builder.sheet.getCell(`B3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`B3`).value=startDate;
    builder.setBorder('B3');
    builder.setCellMiddle("B3");

    builder.setCellValue('C3', "結束日期");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'FCF2D1');
    builder.setCellMiddle("C3");

    builder.sheet.getCell(`D3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`D3`).value=endDate;
    builder.setBorder('D3');
    builder.setCellMiddle("D3");


    builder.setHeight(4,38);
    if(dataType=='Request'&&StateType=='All')
      builder.setCellValue('A4', "總申請量");
    else if(dataType=='Request'&&StateType=='Done')
      builder.setCellValue('A4', "總完成量");
    else if(dataType=='Event')
      builder.setCellValue('A4', "總數量");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'FCF2D1');
    builder.setCellMiddle("A4");
    var num=0
    list.forEach(item=>num+=typeof item.NumOfTime=="undefined"?0:item.NumOfTime);
   
    builder.setCellValue('B4',num+"");
    builder.setBorder('B4');

    builder.sheet.mergeCells('B4:D4');
    builder.setBorder('B4');
    builder.setCellMiddle("B4");

    builder.setHeight(5,26);
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'F4B084');
    builder.setCellValue('A5', "細胞小組");

    builder.sheet.mergeCells('A5:B5');


    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'F4B084');
    if(dataType=='Request'&&StateType=='All')
      builder.setCellValue('C5', "服務次數");
    else if(dataType=='Event')
      builder.setCellValue('C5', "參與次數");

    builder.setBorder('D5');
    builder.setBackgroundColor('D5', 'F4B084');
    builder.setCellValue('D5', "人數");

    var index=6;

    list.forEach((item,key)=>{
      builder.setBorder(`A${index}`);
      builder.setCellValue(`A${index}`, item.cellGourpName+"");
      
    builder.sheet.mergeCells(`A${index}:B${index}`);

  
      builder.setBorder(`C${index}`);
      builder.sheet.getCell(`C${index}`).numFmt = '0'
      builder.sheet.getCell(`C${index}`).value=item.NumOfTime;


      builder.setBorder(`D${index}`);
      builder.sheet.getCell(`D${index}`).numFmt = '0'
      builder.sheet.getCell(`D${index}`).value=item.NumOfPoeper;

      index++;
    })


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Cell_Group_Data_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  
  async exportServiceRatioData(t:ServiceRatio,dataType:String,StateType:String,cellGroupName: string,startDate?:Date,endDate?:Date):Promise<void>{
    let builder: ReportBuilder = new ReportBuilder("Service Ratio Export");

    builder.setCellValue("A1", "活動類別分析");

    builder.setWidth("B",20);
    builder.setWidth("D",20);

    builder.sheet.mergeCells('A1:D1');
    builder.setHeight(1,38);
    builder.setCellMiddle("A1");
    builder.setBorder("A1");

    builder.setHeight(2,38);
    builder.setCellValue('A2', "種類");
    builder.setBorder('A2');
    builder.setCellMiddle("A2");
    builder.setBackgroundColor('A2', 'FCF2D1');

    builder.setCellValue('B2',(dataType=="Request"?"義工服務":"中心活動")+"-"+(StateType=="All"?"全部":"已完成"));
    builder.setBorder('B2');
    builder.setCellMiddle("B2");

    builder.setCellValue('C2', "細胞小組");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'FCF2D1');
    builder.setCellMiddle("C2");

    builder.setCellValue('D2',cellGroupName==""?"全部":cellGroupName);
    builder.setBorder('D2');
    builder.setCellMiddle("D2");

    var nowDate=new Date();
    var offset = nowDate.getTimezoneOffset();
    nowDate = new Date(nowDate.getTime() - (offset*60*1000));
    if(!startDate||!endDate){
      endDate=nowDate;
      startDate=new Date(nowDate.getFullYear(),0,1);
      startDate.setTime(startDate.getTime() - (offset*60*1000))
    }else{
      startDate.setTime(startDate.getTime() - (offset*60*1000))
      endDate.setTime(endDate.getTime() - (offset*60*1000))
    }
   


    builder.setHeight(3,38);
    builder.setCellValue('A3', "初始日期");
    builder.setBorder('A3');
    builder.setCellMiddle("A3");
    builder.setBackgroundColor('A3', 'FCF2D1');


    builder.sheet.getCell(`B3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`B3`).value=startDate;
    builder.setBorder('B3');
    builder.setCellMiddle("B3");

    builder.setCellValue('C3', "結束日期");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'FCF2D1');
    builder.setCellMiddle("C3");

    builder.sheet.getCell(`D3`).numFmt="YYYY-mm-dd"
    builder.sheet.getCell(`D3`).value=endDate;
    builder.setBorder('D3');
    builder.setCellMiddle("D3");


    builder.setHeight(4,38);
    if(dataType=='Request'&&StateType=='All')
      builder.setCellValue('A4', "總申請量");
    else if(dataType=='Request'&&StateType=='Done')
      builder.setCellValue('A4', "總完成量");
    else if(dataType=='Event')
      builder.setCellValue('A4', "總數量");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'FCF2D1');
    builder.setCellMiddle("A4");

    var list1:{
      index: string;
      value: number;
    }[],list2:{
        index: string;
        value: number;
    }[];
    var num=0;
    if(StateType=='All'){
      list1=t.all;
      list2=t.allUser
    }else{
      list1=t.finished;
      list2=t.finishedUser
    }

    list1.forEach(item=>num+=item.value);
   
    builder.setCellValue('B4',num+"");
    builder.setBorder('B4');

    builder.sheet.mergeCells('B4:D4');
    builder.setBorder('B4');
    builder.setCellMiddle("B4");

    var title="類別";
    builder.setHeight(4,26);
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'F4B084');
    builder.setCellValue('A5', title);


    builder.setBorder('B5');
    builder.setBackgroundColor('B5', 'F4B084');
    builder.setCellValue('B5', "佔比");

    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'F4B084');
    if(dataType=='Request'&&StateType=='All')
      builder.setCellValue('C5', "申請次數");
    else if(dataType=='Request'&&StateType=='Done')
      builder.setCellValue('C5', "完成次數");
    else if(dataType=='Event')
      builder.setCellValue('C5', "數量");

    builder.setBorder('D5');
    builder.setBackgroundColor('D5', 'F4B084');
    if(dataType=='Request')
      builder.setCellValue('D5', "義工人數");
    else if(dataType=='Event'&&StateType=='All')
      builder.setCellValue('D5', "確認人數");
    else if(dataType=='Event'&&StateType=='Done')
        builder.setCellValue('D5', "出席人數");


    var index=6;

    list1.forEach((item,key)=>{
      builder.setBorder(`A${index}`);
      builder.setCellValue(`A${index}`, item.index+"");
      builder.setBorder(`B${index}`);
      builder.sheet.getCell(`B${index}`).numFmt = '0.00%'
      builder.sheet.getCell(`B${index}`).value=item.value/num;
  
      builder.setBorder(`C${index}`);
      builder.sheet.getCell(`C${index}`).numFmt = '0'
      builder.sheet.getCell(`C${index}`).value=item.value;


      builder.setBorder(`D${index}`);
      builder.sheet.getCell(`D${index}`).numFmt = '0'
      builder.sheet.getCell(`D${index}`).value=list2.find(i=>i.index==item.index)?.value;

      index++;
    })


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Service_Ratio_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  async exportTransactionChartData(t:TransactionChartData|null,dataType: string,startDate:string,endDate:string):Promise<void>{
    let builder: ReportBuilder = new ReportBuilder("Transaction Chart Export");

    builder.setCellValue("A1", "時分交易趨勢報告");

    builder.setWidth("B",20);
    builder.setWidth("D",20);

    builder.sheet.mergeCells('A1:D1');
    builder.setHeight(1,38);
    builder.setCellMiddle("A1");
    builder.setBorder("A1");

    builder.setCellValue('A2', "初始日期");
    builder.setBorder('A2');
    builder.setCellMiddle("A2");
    builder.setBackgroundColor('A2', 'FCF2D1');

    if(!startDate){
      var nowDate=new Date();
      var offset = nowDate.getTimezoneOffset();
      nowDate = new Date(nowDate.getTime() - (offset*60*1000));
      var start_date,end_date;
        if(dataType=="Daily"){
          startDate=new Date().toISOString().split('T')[0];
          endDate=new Date().toISOString().split('T')[0];
        }else if(dataType=="Weekly"){
          start_date=new Date();
          start_date = new Date(start_date.getTime() - (offset*60*1000));
          start_date.setDate(start_date.getDate()-nowDate.getDay())

          end_date = new Date(start_date.getTime());
          end_date.setDate(end_date.getDate()+6)


          startDate=start_date.toISOString().split('T')[0];
          endDate=end_date.toISOString().split('T')[0];
        }else if(dataType=="Monthly"){
           start_date=new Date(nowDate.getFullYear(),nowDate.getMonth(),1);
          start_date=new Date(start_date.getTime()- (offset*60*1000));
          startDate=start_date.toISOString().split('T')[0];

           end_date=new Date(new Date(nowDate.getFullYear(),nowDate.getMonth()+1,1));
          end_date.setDate(end_date.getDate()-1);
          end_date=new Date(end_date.getTime()- (offset*60*1000));
          endDate=end_date.toISOString().split('T')[0];
        }else if(dataType=="Annually"){
          startDate=nowDate.getFullYear()+"-01-01";
          endDate=nowDate.getFullYear()+"-12-31";
        }
    }

    builder.setHeight(2,38);

    builder.setCellValue('B2',startDate);
    builder.setBorder('B2');
    builder.setCellMiddle("B2");

    builder.setCellValue('C2', "結束日期");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'FCF2D1');
    builder.setCellMiddle("C2");

    builder.setCellValue('D2',endDate);
    builder.setBorder('D2');
    builder.setCellMiddle("D2");


    builder.setHeight(3,38);
    builder.setCellValue('A3', "總時分");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'FCF2D1');
    builder.setCellMiddle("A3");

    var num=0;
    if(t!=null){
        num=t.amount.reduce((accumulator, currentValue) => accumulator+ currentValue.value,num);
    }
    builder.setCellValue('B3',num+"");
    builder.setBorder('B3');

    builder.sheet.mergeCells('B3:D3');
    builder.setBorder('B3');
    builder.setCellMiddle("B3");

    var title="";
    switch(dataType){
      case "Daily":title="每日";break;
      case "Weekly":title="每週";break;
      case "Monthly":title="每月";break;
      case "Annually":title="全年";break;
      default:title="日期";break;
    }
    builder.setHeight(4,26);
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'F4B084');
    builder.setCellValue('A4', title);


    builder.sheet.mergeCells('B4:D4');
    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'F4B084');
    builder.setCellValue('B4', "時分");
    var index=5;
    t?.amount.forEach((item,key)=>{
      builder.setBorder(`A${index}`);
      builder.setCellValue(`A${index}`, item.index+"");
  
  
      builder.sheet.mergeCells(`B${index}:D${index}`);
      builder.setBorder(`B${index}`);
      builder.setCellValue(`B${index}`,  item.value+"");

      index++;
    })


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Transaction_Chart_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }

  async exportUsers(users: UserExportModel[], reportName: string): Promise<void> {
    let builder: ReportBuilder = new ReportBuilder(reportName + " Export");

    users = users.sort(ExportUserSorting);

    this.templateService.getProfileColumns()
      .subscribe(async (value) => {

        let columns = [
          { header: '會員編號*', key: '會員編號*', width: 14 },
          { header: '會員姓名*', key: '會員姓名*', width: 14 },
          { header: '會員暱稱', key: '會員暱稱', width: 17 },
          { header: '性別*', key: '性別*', width: 9 },
          { header: '中心編號*（須和後台設置中的中心編號一致）', key: '中心編號*（須和後台設置中的中心編號一致）', width: 24 },
          { header: '出生日期*（YYYY-MM-DD）', key: '出生日期*（YYYY-MM-DD）', width: 24 },
          { header: '電話號碼*', key: '電話號碼*', width: 13 },
          { header: '登入帳號*', key: '登入帳號*', width: 15 },
          { header: '會員密碼*', key: '會員密碼*', width: 9 },
          { header: '網卡配備*（是否有數據可上網）', key: '網卡配備*（是否有數據可上網）', width: 28 },
          { header: '讀寫能力*', key: '讀寫能力*', width: 20 },
          { header: '教育程度*', key: '教育程度*', width: 12 },
          { header: '住址（地區）*', key: '住址（地區）*', width: 17 },
          { header: '住址（分區）*', key: '住址（分區）*', width: 22 },
          { header: '住址（區）', key: '住址（區）', width: 9 },
          { header: '住址（街道）', key: '住址（街道）', width: 15 },
          { header: '住址（街號）', key: '住址（街號）', width: 12 },
          { header: '屋苑／屋邨', key: '屋苑／屋邨', width: 13 },
          { header: '住址（大廈/期/座）', key: '住址（大廈/期/座）', width: 21 },
          { header: '樓層', key: '樓層', width: 9 },
          { header: '單位', key: '單位', width: 9 },
          { header: '居住條件（須和後台設置中的資料一致）', key: '居住條件（須和後台設置中的資料一致）', width: 30 },
          { header: '身體狀況（須和後台設置中的資料一致）', key: '身體狀況（須和後台設置中的資料一致）', width: 24 },
          { header: '緊急聯絡人名稱', key: '緊急聯絡人名稱', width: 16 },
          { header: '緊急聯絡人電話', key: '緊急聯絡人電話', width: 16 },
          { header: '可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）', key: '可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）', width: 28 },
          { header: '細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）', key: '細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）', width: 39 },
          { header: '電話型號', key: '電話型號', width: 14 },
          { header: '智能電話？（是否可安裝使用app）', key: '智能電話？（是否可安裝使用app）', width: 39 },
          { header: '備註', key: '備註', width: 20 },
          { header: '時分餘額(唯讀)', key: '時分餘額(唯讀)', width: 20 }
        ];

        columns = columns.concat(value.map(column => {
          return {
            header: column.columnName,
            key: column.columnName,
            width: 15
          }
        }));

        builder.setColumns(columns);

        builder.setCellValue("A1", "注意事項1:會員編號不可和已有的會員編號重複，系統會根據會員編號識別會員。");
        builder.sheet.mergeCells('A1:AD1');

        builder.setCellValue("A2", "注意事項2:登入帳號不可和已有的登入帳號重複");
        builder.sheet.mergeCells('A2:AD2');

        builder.setCellValue("A3", "注意事項3: 第一次使用時請先登入Portal——後台設置檢查/新增中心和設置各種資料。中心編號須和Portal後台設置——機構設置中已有中心編號一致，填寫編號即可。另，居住條件、身體狀況和可提供服務亦須和後台設置——資料設置字眼一致。");
        builder.sheet.mergeCells('A3:AD3');

        builder.setCellValue("A4", "注意事項4:如果會員是義工身分可進行一對多的義工服務，請填寫可提供服務欄，並在Portal的會員檔案中完善空閒時間（會員可在app中設置），才可在義工推薦時搜尋到該會員。");
        builder.sheet.mergeCells('A4:AD4');

        builder.sheet.addRow("");

        builder.sheet.getRow(6).values = columns.map(column => column.key);

        builder.setBorder('A6');
        builder.setBackgroundColor('A6', 'FDE9D9');
        builder.setBorder('B6');
        builder.setBackgroundColor('B6', 'FDE9D9');
        builder.setBorder('C6');
        builder.setBackgroundColor('C6', 'FDE9D9');
        builder.setBorder('D6');
        builder.setBackgroundColor('D6', 'FDE9D9');
        builder.setBorder('E6');
        builder.setBackgroundColor('E6', 'FDE9D9');
        builder.setBorder('F6');
        builder.setBackgroundColor('F6', 'FDE9D9');
        builder.setBorder('G6');
        builder.setBackgroundColor('G6', 'FDE9D9');
        builder.setBorder('H6');
        builder.setBackgroundColor('H6', 'FDE9D9');
        builder.setBorder('I6');
        builder.setBackgroundColor('I6', 'FDE9D9');
        builder.setBorder('J6');
        builder.setBackgroundColor('J6', 'FDE9D9');
        builder.setBorder('K6');
        builder.setBackgroundColor('K6', 'FDE9D9');
        builder.setBorder('L6');
        builder.setBackgroundColor('L6', 'FDE9D9');
        builder.setBorder('M6');
        builder.setBackgroundColor('M6', 'FDE9D9');
        builder.setBorder('N6');
        builder.setBackgroundColor('N6', 'FDE9D9');
        builder.setBorder('O6');
        builder.setBackgroundColor('O6', 'EBF1DE');
        builder.setBorder('P6');
        builder.setBackgroundColor('P6', 'EBF1DE');
        builder.setBorder('Q6');
        builder.setBackgroundColor('Q6', 'EBF1DE');
        builder.setBorder('R6');
        builder.setBackgroundColor('R6', 'EBF1DE');
        builder.setBorder('S6');
        builder.setBackgroundColor('S6', 'EBF1DE');
        builder.setBorder('T6');
        builder.setBackgroundColor('T6', 'EBF1DE');
        builder.setBorder('U6');
        builder.setBackgroundColor('U6', 'EBF1DE');
        builder.setBorder('V6');
        builder.setBackgroundColor('V6', 'EBF1DE');
        builder.setBorder('W6');
        builder.setBackgroundColor('W6', 'EBF1DE');
        builder.setBorder('X6');
        builder.setBackgroundColor('X6', 'EBF1DE');
        builder.setBorder('Y6');
        builder.setBackgroundColor('Y6', 'EBF1DE');
        builder.setBorder('Z6');
        builder.setBackgroundColor('Z6', 'EBF1DE');
        builder.setBorder('AA6');
        builder.setBackgroundColor('AA6', 'EBF1DE');
        builder.setBorder('AB6');
        builder.setBackgroundColor('AB6', 'EBF1DE');
        builder.setBorder('AC6');
        builder.setBackgroundColor('AC6', 'EBF1DE');
        builder.setBorder('AD6');
        builder.setBackgroundColor('AD6', 'EBF1DE');

        users.forEach((user, index) => {
          const row = builder.sheet.addRow(user);
          builder.setDataValidation(`D${index + 7}`, '"男,女"');
          builder.setDataValidation(`J${index + 7}`, '"是,不是"');
          builder.setDataValidation(`K${index + 7}`, '"能讀能寫,只能讀，不能寫,不懂文字"');
          builder.setDataValidation(`L${index + 7}`, '"大學或以上,中學,小學,不適用"');
          builder.setDataValidation(`M${index + 7}`, '"香港島,九龍,新界"');
          builder.setDataValidation(`N${index + 7}`, '"中西區,灣仔區,東區,南區,深水埗區,油尖旺區,九龍城區,黃大仙區,觀塘區,葵青區,荃灣區,屯門區,元朗區,北區,大埔區,沙田區,西貢區,離島區"');
          builder.setDataValidation(`AB${index + 7}`, '"是,不是"');
        })

        let buffer = await builder.workbook.xlsx.writeBuffer();

        saveAs(new Blob([buffer]), reportName + "_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
      })
  }

  async exportRequests(requests: RequestExportModel[], dateRange?: { startDate: Date, endDate: Date }): Promise<void> {

    forkJoin({
      columns: this.templateService.getRequestColumns(),
      name: this.organizationService.getOrganizationName()
    }).subscribe(async (value) => {
      if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null)
        dateRange = { startDate: new Date(2019, 1, 1), endDate: new Date() };

      let builder: ReportBuilder = new ReportBuilder("Request Export");

      let columns = [
        { header: '紀錄編號*', key: '紀錄編號*', width: 18 },
        { header: '申請人編號*', key: '申請人編號*', width: 21 },
        { header: '申請人名稱', key: '申請人名稱', width: 22 },
        { header: '服務日期(YYYY-MM-DD)*', key: '服務日期(YYYY-MM-DD)*', width: 28 },
        { header: '預計開始時間(HH:mm)*', key: '預計開始時間(HH:mm)*', width: 26 },
        { header: '預計結束時間(HH:mm)*', key: '預計結束時間(HH:mm)*', width: 22 },
        { header: '服務鐘數*', key: '服務鐘數*', width: 21 },
        { header: '提供服務*（字眼必須和後台資料設置一致）', key: '提供服務*（字眼必須和後台資料設置一致）', width: 47 },
        { header: '義工編號*（用逗號隔開）', key: '義工編號*（用逗號隔開）', width: 28 },
        { header: '義工名稱（用逗號隔開）', key: '義工名稱（用逗號隔開）', width: 43 },
        { header: '實際開始時間(HH:mm)*', key: '實際開始時間(HH:mm)*', width: 14 },
        { header: '實際結束時間(HH:mm)*', key: '實際結束時間(HH:mm)*', width: 21 },
        { header: '備註', key: '備註', width: 18 },
        { header: '每個義工的交易時分*', key: '每個義工的交易時分*', width: 18 },
        { header: '總交易時分*', key: '總交易時分*', width: 18 },
        { header: '狀態', key: '狀態', width: 18 },
      ];

      columns = columns.concat(value.columns.map(column => {
        return {
          header: column.columnName,
          key: column.columnName,
          width: 15
        }
      }));

      builder.setColumns(columns);

      await this.organizationService.getOrganizationName()
        .subscribe(name => {

        })

      builder.setCellValue("A1", value.name + "——義工服務紀錄報告");

      builder.sheet.mergeCells('A1:O1');

      builder.sheet.getCell('A1').alignment = { horizontal: 'center' };

      builder.setCellValue('A2', "開始日期");
      builder.setBorder('A2');
      builder.setBackgroundColor('A2', 'EBF1DE');

      builder.setCellValue('B2', dateRange.startDate.toJSON().split('T')[0]);

      builder.setCellValue('C2', "截止日期");
      builder.setBorder('C2');
      builder.setBackgroundColor('C2', 'EBF1DE');

      builder.setCellValue('D2', dateRange.endDate.toJSON().split('T')[0]);

      builder.sheet.addRow("");

      builder.sheet.getRow(4).values = columns.map(column => column.key);
      builder.setBorder('A4');
      builder.setBackgroundColor('A4', 'FDE9D9');
      builder.setBorder('B4');
      builder.setBackgroundColor('B4', 'FDE9D9');
      builder.setBorder('C4');
      builder.setBackgroundColor('C4', 'EBF1DE');
      builder.setBorder('D4');
      builder.setBackgroundColor('D4', 'FDE9D9');
      builder.setBorder('E4');
      builder.setBackgroundColor('E4', 'FDE9D9');
      builder.setBorder('F4');
      builder.setBackgroundColor('F4', 'FDE9D9');
      builder.setBorder('G4');
      builder.setBackgroundColor('G4', 'FDE9D9');
      builder.setBorder('H4');
      builder.setBackgroundColor('H4', 'FDE9D9');
      builder.setBorder('I4');
      builder.setBackgroundColor('I4', 'FDE9D9');
      builder.setBorder('J4');
      builder.setBackgroundColor('J4', 'EBF1DE');
      builder.setBorder('K4');
      builder.setBackgroundColor('K4', 'FDE9D9');
      builder.setBorder('L4');
      builder.setBackgroundColor('L4', 'FDE9D9');
      builder.setBorder('M4');
      builder.setBackgroundColor('M4', 'EBF1DE');
      builder.setBorder('N4');
      builder.setBackgroundColor('N4', 'FDE9D9');
      builder.setBorder('O4');
      builder.setBackgroundColor('O4', 'FDE9D9');
      builder.setBorder('P4');
      builder.setBackgroundColor('P4', 'EBF1DE');

      requests.forEach(request => {
        builder.sheet.addRow(request);
      })

      let buffer = await builder.workbook.xlsx.writeBuffer();

      saveAs(new Blob([buffer]), "Request_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
    })
  }

  async exportProducts(products: ProductExportModel[], dateRange?: { startDate: Date, endDate: Date }): Promise<void> {
    if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null)
      dateRange = { startDate: new Date(2019, 1, 1), endDate: new Date() };

    let builder: ReportBuilder = new ReportBuilder("Product Report");

    let columns = [
      { header: "獎勵項目編號", key: "獎勵項目編號", width: 21 },
      { header: "獎勵標題", key: "獎勵標題", width: 28 },
      { header: "來源", key: "來源", width: 28 },
      { header: "參與類型", key: "參與類型", width: 20 },
      { header: "發布日期", key: "發布日期", width: 22 },
      { header: "截止日期", key: "截止日期", width: 21 },
      { header: "設定數量", key: "設定數量", width: 16 },
      { header: "庫存餘額", key: "庫存餘額", width: 16 },
      { header: "已兌換會員人數", key: "已兌換會員人數", width: 15 },
      { header: "已兌換獎勵數量", key: "已兌換獎勵數量", width: 14 },
      { header: "狀態", key: "狀態", width: 21 },
    ]

    builder.setColumns(columns);

    builder.setCellValue("A1", "獎勵兌換列表報告");
    builder.sheet.getRow(1).height = 27;
    builder.sheet.mergeCells('A1:K1');
    builder.sheet.getCell('A1').alignment = { horizontal: 'center' };

    builder.setCellValue('A2', "開始日期");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');

    builder.setCellValue('B2', dateRange.startDate.toJSON().split('T')[0]);

    builder.setCellValue('C2', "截止日期");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');

    builder.setCellValue('D2', dateRange.endDate.toJSON().split('T')[0]);

    builder.sheet.addRow("");

    builder.sheet.getRow(4).values = columns.map(column => column.key);
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'FDE9D9');
    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'FDE9D9');
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'FDE9D9');
    builder.setBorder('D4');
    builder.setBackgroundColor('D4', 'FDE9D9');
    builder.setBorder('E4');
    builder.setBackgroundColor('E4', 'EBF1DE');
    builder.setBorder('F4');
    builder.setBackgroundColor('F4', 'EBF1DE');
    builder.setBorder('G4');
    builder.setBackgroundColor('G4', 'FDE9D9');
    builder.setBorder('H4');
    builder.setBackgroundColor('H4', 'FDE9D9');
    builder.setBorder('I4');
    builder.setBackgroundColor('I4', 'FDE9D9');
    builder.setBorder('J4');
    builder.setBackgroundColor('J4', 'FDE9D9');
    builder.setBorder('K4');
    builder.setBackgroundColor('K4', 'EBF1DE');

    products.forEach(product => {
      builder.sheet.addRow(product);
    })

    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Product_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }

  async exportRedeems(product: Product,redeems:Redeem[]): Promise<void> {
    let builder: ReportBuilder = new ReportBuilder(product.productName + " Report");

    let columns = [
      { header: "會員編號", key: "會員編號", width: 15 },
      { header: "姓名", key: "姓名", width: 28 },
      { header: "暱稱", key: "暱稱", width: 20 },
      { header: "中心編號", key: "中心編號", width: 22 },
      { header: "性別", key: "性別", width: 9 },
      { header: "年齡", key: "年齡", width: 10 },
      { header: "電話", key: "電話", width: 16 },
      { header: "換領數量", key: "換領數量", width: 15 },
      { header: "交易時分", key: "交易時分", width: 13 },
      { header: "換領編碼", key: "換領編碼", width: 14 },
      { header: "狀態", key: "狀態", width: 8 },
      { header: "換領時間", key: "換領時間", width: 21 },
      { header: "問題1", key: "問題1", width: 18 },
      { header: "答案1", key: "答案1", width: 10 },
      { header: "問題2", key: "問題2", width: 18 },
      { header: "答案2", key: "答案2", width: 10 },
      { header: "問題3", key: "問題3", width: 18 },
      { header: "答案3", key: "答案3", width: 10 },
      { header: "備註", key: "備註", width: 8 },
    ]

    builder.setColumns(columns);

    builder.setCellValue("A1", "獎勵標題");
    builder.setBorder('A1');
    builder.setBackgroundColor('A1', 'EBF1DE');
    builder.sheet.getRow(1).height = 27;

    builder.setCellValue("B1", product.productName);
    builder.sheet.mergeCells('B1:R1');

    builder.setCellValue("A2", "來源");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');

    builder.setCellValue("B2", product.brand);

    builder.setCellValue("C2", "參與類型");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');

    builder.setCellValue("D2", product.format == "FirstComeFirstServed" ? "先到先得" : "職員處理");

    builder.setCellValue("A3", "發布時間");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');

    builder.setCellValue("B3", product.launchDate.toJSON().split('T')[0] + " " + product.launchDate.toTimeString().substr(0, 5));

    builder.setCellValue("C3", "截止時間");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'EBF1DE');

    builder.setCellValue("D3", product.closeDate.toJSON().split('T')[0] + " " + product.closeDate.toTimeString().substr(0, 5));

    builder.setCellValue("A4", "數量");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", product.quantity.toString());

    builder.setCellValue("C4", "兌換比率（時分/件）");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", product.price.toString());

    builder.setCellValue("A5", "每人限領數量");
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'EBF1DE');

    builder.setCellValue("B5", product.maximumQuantityPerPerson.toString());

    builder.sheet.addRow("");

    builder.setCellValue("A7", "已兌換會員名單");

    builder.sheet.getRow(8).values = columns.map(column => column.key);

    builder.setBorder('A8');
    builder.setBackgroundColor('A8', 'FDE9D9');
    builder.setBorder('B8');
    builder.setBackgroundColor('B8', 'FDE9D9');
    builder.setBorder('C8');
    builder.setBackgroundColor('C8', 'EBF1DE');
    builder.setBorder('D8');
    builder.setBackgroundColor('D8', 'EBF1DE');
    builder.setBorder('E8');
    builder.setBackgroundColor('E8', 'EBF1DE');
    builder.setBorder('F8');
    builder.setBackgroundColor('F8', 'EBF1DE');
    builder.setBorder('G8');
    builder.setBackgroundColor('G8', 'FDE9D9');
    builder.setBorder('H8');
    builder.setBackgroundColor('H8', 'FDE9D9');
    builder.setBorder('I8');
    builder.setBackgroundColor('I8', 'FDE9D9');
    builder.setBorder('J8');
    builder.setBackgroundColor('J8', 'FDE9D9');
    builder.setBorder('K8');
    builder.setBackgroundColor('K8', 'EBF1DE');
    builder.setBorder('L8');
    builder.setBackgroundColor('L8', 'EBF1DE');
    builder.setBorder('M8');
    builder.setBackgroundColor('M8', 'EBF1DE');
    builder.setBorder('N8');
    builder.setBackgroundColor('N8', 'EBF1DE');
    builder.setBorder('O8');
    builder.setBackgroundColor('O8', 'EBF1DE');
    builder.setBorder('P8');
    builder.setBackgroundColor('P8', 'EBF1DE');
    builder.setBorder('Q8');
    builder.setBackgroundColor('Q8', 'EBF1DE');
    builder.setBorder('R8');
    builder.setBackgroundColor('R8', 'EBF1DE');
    builder.setBorder('S8');
    builder.setBackgroundColor('S8', 'EBF1DE');

    redeems.forEach(redeem => {
      builder.sheet.addRow(redeem.getExportModel());
    });

    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Product_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }

  async exportEvent(event: Event): Promise<void> {
    let builder: ReportBuilder = new ReportBuilder(
      
      (event.eventName.length>24?event.eventName.substring(0,24):event.eventName).replace(/[\*\?\:\\\/\[\]]/gi,"")
      
      + " Report");

    let columns = [
      { header: "會員編號", key: "會員編號", width: 17 },
      { header: "姓名", key: "姓名", width: 28 },
      { header: "暱稱", key: "暱稱", width: 20 },
      { header: "中心編號", key: "中心編號", width: 22 },
      { header: "性別", key: "性別", width: 9 },
      { header: "年齡", key: "年齡", width: 10 },
      { header: "電話", key: "電話", width: 16 },
      { header: "狀態", key: "狀態", width: 16 },
      { header: "交易時分", key: "交易時分", width: 13 },
      { header: "交易編碼", key: "交易編碼", width: 14 },
      { header: "完成時間", key: "完成時間", width: 21 },
    ];

    builder.setColumns(columns);

    builder.sheet.getRow(1).height = 27;

    builder.setCellValue("A1", "活動標題");
    builder.setBorder('A1');
    builder.setBackgroundColor('A1', 'EBF1DE');

    builder.setCellValue("B1", event.eventName);

    builder.setCellValue("C1", "活動編號");
    builder.setBorder('C1');
    builder.setBackgroundColor('C1', 'EBF1DE');

    builder.setCellValue("D1", event.id.toString());

    builder.sheet.mergeCells("D1:K1");

    builder.setCellValue("A2", "狀態");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');

    builder.setCellValue("B2", event.eventStatus);

    builder.setCellValue("C2", "參與類型");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');

    builder.setCellValue("D2", event.eventJoinType == "FIFJ" ? "先到先得" : "職員處理");

    builder.setCellValue("A3", "活動類型");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');

    builder.setCellValue("B3", `${event.eventType.code} ${event.eventType.name}`);

    builder.setCellValue("C3", "截止時間");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'EBF1DE');

    builder.setCellValue("D3", event.applyDeadline.toJSON().split('T')[0] + " " + event.applyDeadline.toTimeString().substr(0, 5));

    builder.setCellValue("A4", "參與者數量");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", event.participantVacancy.toString());

    builder.setCellValue("C4", "交易類型");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", event.eventPay == "Free" ? "免費" : event.eventPay == "UserSpend" ? "會員扣分" : "會員加分");

    builder.setCellValue("A5", "預設每會員交易時分");
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'EBF1DE');

    builder.setCellValue("B5", event.earning.toString());

    builder.setCellValue("A6", "活動日期");
    builder.setBorder('A6');
    builder.setBackgroundColor('A6', 'EBF1DE');


    builder.setCellValue("C5", "總交易時分");
    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'EBF1DE');

    console.log

    builder.setCellValue("D5",(event.eventPay == "UserSpend" ?"-":"")+
      (
        event.transactions.length==0?0:
         event.transactions.map(t=>t==null?0:t.amount).reduce((a,b)=>(a+b)).toString()
      )
    );


    var datelist:string="";
    event.eventDays.forEach((eventday)=>{
      datelist+=(datelist!="")?",":"";
      datelist+=eventday.date.toLocaleDateString("zh-hk",{
        year:"numeric",month: 'long', day: 'numeric' ,timeZone:"Asia/Hong_Kong",
        //hour12:true,hour:  "2-digit",minute: "2-digit" 
      });
    });


    builder.setCellValue("B6", datelist);



    builder.sheet.addRow("");

    builder.setCellValue("A8", "確認參加會員名單");

    builder.sheet.getRow(9).values = columns.map(column => column.key);

    builder.setBorder('A9');
    builder.setBackgroundColor('A9', 'FDE9D9');
    builder.setBorder('B9');
    builder.setBackgroundColor('B9', 'FDE9D9');
    builder.setBorder('C9');
    builder.setBackgroundColor('C9', 'EBF1DE');
    builder.setBorder('D9');
    builder.setBackgroundColor('D9', 'EBF1DE');
    builder.setBorder('E9');
    builder.setBackgroundColor('E9', 'EBF1DE');
    builder.setBorder('F9');
    builder.setBackgroundColor('F9', 'EBF1DE');
    builder.setBorder('G9');
    builder.setBackgroundColor('G9', 'FDE9D9');
    builder.setBorder('H9');
    builder.setBackgroundColor('H9', 'FDE9D9');
    builder.setBorder('I9');
    builder.setBackgroundColor('I9', 'FDE9D9');
    builder.setBorder('J9');
    builder.setBackgroundColor('J9', 'FDE9D9');
    builder.setBorder('K9');
    builder.setBackgroundColor('K9', 'FDE9D9');

    event.participants.forEach(user => {
      builder.sheet.addRow(user.getExportModel());
    });

    builder.addSheet("實際出席人次");
    builder.index=1;

    builder.setCellValue("A1", "實際出席人次記錄");
    builder.setFontSize("A1",14);

    builder.setCellValue("A2", "活動標題");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');
    builder.setCellValue("B2",event.eventName);


    builder.setCellValue("C2", "活動編號");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');
    builder.setCellValue("D2",event.id.toString());

    
    builder.setCellValue("A3", "總實際出席人數");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');
    var total:number=0;
    event.eventDays.forEach((eventday)=>{
      if(eventday.numOfAttendance!=null)
        total+=eventday.numOfAttendance;
    })
    builder.setCellValue("B3",total.toString());

    builder.setCellValue("A4", "活動日期");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", "開始時間");
    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'EBF1DE');

    builder.setCellValue("C4", "狀態");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", "實際出席人數");
    builder.setBorder('D4');
    builder.setBackgroundColor('D4', 'EBF1DE');

    let columns2 = [
      { header: "活動日期", key: "會員編號", width: 17 },
      { header: "開始時間", key: "姓名", width: 28 },
      { header: "狀態", key: "暱稱", width: 20 },
      { header: "實際出席人數", key: "中心編號", width: 22 }
    ];
    builder.setColumns(columns2);


    var now=new Date();
   
    event.eventDays.forEach((eventday, index) => {
      var state:string='';
      if(now<eventday.startTime){//dateRange.startDate.toJSON().split('T')[0]
        state=('未開始');
      }else if(now>eventday.startTime && (eventday.endTime!=null &&now < eventday.endTime)){
        state=('進行中');
      }else {
        state=('已結束');
      };
      builder.sheet.addRow([ 
          eventday.date.toLocaleDateString("zh-hk",{
            year:"numeric",month: 'long', day: 'numeric' ,timeZone:"Asia/Hong_Kong",
            //hour12:true,hour:  "2-digit",minute: "2-digit" 
          }),
          eventday.startTime.toLocaleTimeString("zh-hk",{
            hour:  "2-digit",minute: "2-digit" 
          }),
          state,
          eventday.numOfAttendance==null?"0":eventday.numOfAttendance.toString()
      ]);
    });


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Event_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  async exportEventApplyOnly(event: Event): Promise<void> {
    let builder: ReportBuilder = new ReportBuilder(
      
      (event.eventName.length>24?event.eventName.substring(0,24):event.eventName).replace(/[\*\?\:\\\/\[\]]/gi,"")
      
      + " Report");

    let columns = [
      { header: "會員編號", key: "會員編號", width: 17 },
      { header: "姓名", key: "姓名", width: 28 },
      { header: "暱稱", key: "暱稱", width: 20 },
      { header: "中心編號", key: "中心編號", width: 22 },
      { header: "性別", key: "性別", width: 9 },
      { header: "年齡", key: "年齡", width: 10 },
      { header: "電話", key: "電話", width: 16 },
      { header: "狀態", key: "狀態", width: 16 },
      { header: "交易時分", key: "交易時分", width: 13 },
      { header: "交易編碼", key: "交易編碼", width: 14 },
      { header: "完成時間", key: "完成時間", width: 21 },
    ];

    builder.setColumns(columns);

    builder.sheet.getRow(1).height = 27;

    builder.setCellValue("A1", "活動標題");
    builder.setBorder('A1');
    builder.setBackgroundColor('A1', 'EBF1DE');

    builder.setCellValue("B1", event.eventName);

    builder.setCellValue("C1", "活動編號");
    builder.setBorder('C1');
    builder.setBackgroundColor('C1', 'EBF1DE');

    builder.setCellValue("D1", event.id.toString());

    builder.sheet.mergeCells("D1:K1");

    builder.setCellValue("A2", "狀態");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');

    builder.setCellValue("B2", event.eventStatus);

    builder.setCellValue("C2", "參與類型");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');

    builder.setCellValue("D2", event.eventJoinType == "FIFJ" ? "先到先得" : "職員處理");

    builder.setCellValue("A3", "活動類型");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');

    builder.setCellValue("B3", `${event.eventType.code} ${event.eventType.name}`);

    builder.setCellValue("C3", "截止時間");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'EBF1DE');

    builder.setCellValue("D3", event.applyDeadline.toJSON().split('T')[0] + " " + event.applyDeadline.toTimeString().substr(0, 5));

    builder.setCellValue("A4", "參與者數量");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", event.participantVacancy.toString());

    builder.setCellValue("C4", "交易類型");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", event.eventPay == "Free" ? "免費" : event.eventPay == "UserSpend" ? "會員扣分" : "會員加分");

    builder.setCellValue("A5", "預設每會員交易時分");
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'EBF1DE');

    builder.setCellValue("B5", event.earning.toString());

    builder.setCellValue("A6", "活動日期");
    builder.setBorder('A6');
    builder.setBackgroundColor('A6', 'EBF1DE');


    builder.setCellValue("C5", "總交易時分");
    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'EBF1DE');

    console.log

    builder.setCellValue("D5",(event.eventPay == "UserSpend" ?"-":"")+
      (
        event.transactions.length==0?0:
         event.transactions.map(t=>t==null?0:t.amount).reduce((a,b)=>(a+b)).toString()
      )
    );


    var datelist:string="";
    event.eventDays.forEach((eventday)=>{
      datelist+=(datelist!="")?",":"";
      datelist+=eventday.date.toLocaleDateString("zh-hk",{
        year:"numeric",month: 'long', day: 'numeric' ,timeZone:"Asia/Hong_Kong",
        //hour12:true,hour:  "2-digit",minute: "2-digit" 
      });
    });


    builder.setCellValue("B6", datelist);



    builder.sheet.addRow("");

    builder.setCellValue("A8", "確認參加會員名單");

    builder.sheet.getRow(9).values = columns.map(column => column.key);

    builder.setBorder('A9');
    builder.setBackgroundColor('A9', 'FDE9D9');
    builder.setBorder('B9');
    builder.setBackgroundColor('B9', 'FDE9D9');
    builder.setBorder('C9');
    builder.setBackgroundColor('C9', 'EBF1DE');
    builder.setBorder('D9');
    builder.setBackgroundColor('D9', 'EBF1DE');
    builder.setBorder('E9');
    builder.setBackgroundColor('E9', 'EBF1DE');
    builder.setBorder('F9');
    builder.setBackgroundColor('F9', 'EBF1DE');
    builder.setBorder('G9');
    builder.setBackgroundColor('G9', 'FDE9D9');
    builder.setBorder('H9');
    builder.setBackgroundColor('H9', 'FDE9D9');
    builder.setBorder('I9');
    builder.setBackgroundColor('I9', 'FDE9D9');
    builder.setBorder('J9');
    builder.setBackgroundColor('J9', 'FDE9D9');
    builder.setBorder('K9');
    builder.setBackgroundColor('K9', 'FDE9D9');
    event.participants.filter(user=>(user.state == "Confirmed" )||user.state == "Absent" ).forEach(user => {
      builder.sheet.addRow(user.getExportModel());
    });

    builder.addSheet("實際出席人次");
    builder.index=1;

    builder.setCellValue("A1", "實際出席人次記錄");
    builder.setFontSize("A1",14);

    builder.setCellValue("A2", "活動標題");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');
    builder.setCellValue("B2",event.eventName);


    builder.setCellValue("C2", "活動編號");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');
    builder.setCellValue("D2",event.id.toString());

    
    builder.setCellValue("A3", "總實際出席人數");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');
    var total:number=0;
    event.eventDays.forEach((eventday)=>{
      if(eventday.numOfAttendance!=null)
        total+=eventday.numOfAttendance;
    })
    builder.setCellValue("B3",total.toString());

    builder.setCellValue("A4", "活動日期");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", "開始時間");
    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'EBF1DE');

    builder.setCellValue("C4", "狀態");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", "實際出席人數");
    builder.setBorder('D4');
    builder.setBackgroundColor('D4', 'EBF1DE');

    let columns2 = [
      { header: "活動日期", key: "會員編號", width: 17 },
      { header: "開始時間", key: "中文姓名", width: 28 },
      { header: "狀態", key: "英文姓名", width: 20 },
      { header: "實際出席人數", key: "中心編號", width: 22 }
    ];
    builder.setColumns(columns2);


    var now=new Date();
   
    event.eventDays.forEach((eventday, index) => {
      var state:string='';
      if(now<eventday.startTime){//dateRange.startDate.toJSON().split('T')[0]
        state=('未開始');
      }else if(now>eventday.startTime && (eventday.endTime!=null &&now < eventday.endTime)){
        state=('進行中');
      }else {
        state=('已結束');
      };
      builder.sheet.addRow([ 
          eventday.date.toLocaleDateString("zh-hk",{
            year:"numeric",month: 'long', day: 'numeric' ,timeZone:"Asia/Hong_Kong",
            //hour12:true,hour:  "2-digit",minute: "2-digit" 
          }),
          eventday.startTime.toLocaleTimeString("zh-hk",{
            hour:  "2-digit",minute: "2-digit" 
          }),
          state,
          eventday.numOfAttendance==null?"0":eventday.numOfAttendance.toString()
      ]);
    });


    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Event_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  async exportRedeemApplyOnly(product:Product): Promise<void> {
    let builder: ReportBuilder = new ReportBuilder(
      
      (product.productName.length>24?product.productName.substring(0,24):product.productName).replace(/[\*\?\:\\\/\[\]]/gi,"")
      
      + " Report");

    let columns = [
      { header: "會員編號", key: "會員編號", width: 17 },
      { header: "姓名", key: "姓名", width: 28 },
      { header: "暱稱", key: "英文姓名", width: 20 },
      { header: "中心編號", key: "中心編號", width: 22 },
      { header: "性別", key: "性別", width: 9 },
      { header: "年齡", key: "年齡", width: 10 },
      { header: "電話", key: "電話", width: 16 },
      { header: "狀態", key: "狀態", width: 16 },
      { header: "兌換數量", key: "兌換數量", width: 14 },
      { header: "交易時分", key: "交易時分", width: 13 },
      { header: "換領編碼", key: "換領編碼", width: 14 },
      { header: "兌換時間", key: "兌換時間", width: 21 },
      { header: "確認已換領時間", key: "確認已換領時間", width: 21 },
    ];

    builder.setColumns(columns);

    builder.sheet.getRow(1).height = 27;


    builder.sheet.mergeCells("A1:M1");

    builder.setCellValue("A1", "獎勵兌換點名紙");
    builder.setBorder('A1');

    builder.setCellValue("A2", "獎勵標題");
    builder.setBorder('A2');
    builder.setBackgroundColor('A2', 'EBF1DE');

    builder.setCellValue("B2", product.productName);

    builder.setCellValue("C2", "獎勵編號");
    builder.setBorder('C2');
    builder.setBackgroundColor('C2', 'EBF1DE');
    builder.setCellValue("D2", product.id.toString());


    builder.setCellValue("A3", "狀態");
    builder.setBorder('A3');
    builder.setBackgroundColor('A3', 'EBF1DE');

    builder.setCellValue("B3", product.state);

    builder.setCellValue("C3", "參與方式");
    builder.setBorder('C3');
    builder.setBackgroundColor('C3', 'EBF1DE');

    builder.setCellValue("D3",product.format == "FIFJ" ? "先到先得" : "職員處理");

    builder.setCellValue("A4", "適用對象");
    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'EBF1DE');

    builder.setCellValue("B4", `${product.target}`);

    builder.setCellValue("C4", "截止換領時間");
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'EBF1DE');

    builder.setCellValue("D4", product.closeDate.toJSON().split('T')[0] + " " + product.closeDate.toTimeString().substring(0, 5));

    builder.setCellValue("A5", "獎勵件數");
    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'EBF1DE');

    builder.setCellValue("B5", product.quantity+"");

    builder.setCellValue("C5", "獎勵來源");
    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'EBF1DE');

    builder.setCellValue("D5", product.brand);

    builder.setCellValue("A6", "預設每用戶交易時分");
    builder.setBorder('A6');
    builder.setBackgroundColor('A6', 'EBF1DE');

    builder.setCellValue("B6", product.price+"");

    builder.setCellValue("A7", "發布日期");
    builder.setBorder('A7');
    builder.setBackgroundColor('A7', 'EBF1DE');



    builder.setCellValue("B7", product.launchDate.toJSON().split('T')[0]+ " " + product.launchDate.toTimeString().substring(0, 5));
  

    builder.sheet.addRow("");

    builder.setCellValue("A9", "確認兌換會員名單");


    builder.sheet.getRow(10).values = columns.map(column => column.key);

    builder.setBorder('A10');
    builder.setBackgroundColor('A10', 'FDE9D9');
    builder.setBorder('B10');
    builder.setBackgroundColor('B10', 'FDE9D9');
    builder.setBorder('C10');
    builder.setBackgroundColor('C10', 'EBF1DE');
    builder.setBorder('D10');
    builder.setBackgroundColor('D10', 'EBF1DE');
    builder.setBorder('E10');
    builder.setBackgroundColor('E10', 'EBF1DE');
    builder.setBorder('F10');
    builder.setBackgroundColor('F10', 'EBF1DE');
    builder.setBorder('G10');
    builder.setBackgroundColor('G10', 'FDE9D9');
    builder.setBorder('H10');
    builder.setBackgroundColor('H10', 'FDE9D9');
    builder.setBorder('I10');
    builder.setBackgroundColor('I10', 'FDE9D9');
    builder.setBorder('J10');
    builder.setBackgroundColor('J10', 'FDE9D9');
    builder.setBorder('K10');
    builder.setBackgroundColor('K10', 'FDE9D9');
    builder.setBorder('L10');
    builder.setBackgroundColor('L10', 'FDE9D9');
    builder.setBorder('M10');
    builder.setBackgroundColor('M10', 'FDE9D9');

    product.redeems.filter(redeem=>(redeem.redeemState == "Redeemed")||redeem.redeemState == "Delivered" )
                   .forEach(redeem => {
                      builder.sheet.addRow(redeem.getExportModelForRedeem());
                    });

    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Redeem_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }
  async exportEventList(events: EventExportModel[], dateRange?: { startDate: Date, endDate: Date }): Promise<void> {
    this.templateService.getEventColumns()
      .subscribe(async (value) => {
        if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null)
          dateRange = { startDate: new Date(2019, 1, 1), endDate: new Date() };

        let builder: ReportBuilder = new ReportBuilder("Events Report");

        let columns = [
          { header: "紀錄編號", key: "紀錄編號", width: 13 },
          { header: "活動名目", key: "活動名目", width: 38 },
          { header: "活動類型", key: "活動類型", width: 42 },
          { header: "交易類型", key: "交易類型", width: 13 },
          { header: "參加類型", key: "參加類型", width: 22 },
          { header: "活動日期（YYYY-MM-DD）", key: "活動日期（YYYY-MM-DD）", width: 32 },
          { header: "截止報名時間（YYYY-MM-DD HH：mm）", key: "截止報名時間（YYYY-MM-DD HH：mm）", width: 37 },
          { header: "預計開始時間（HH：mm）", key: "預計開始時間（HH：mm）", width: 24 },
          { header: "預計結束時間（HH：mm）", key: "預計結束時間（HH：mm）", width: 25 },
          { header: "可參加人數", key: "可參加人數", width: 13 },
          { header: "實際參加人數", key: "實際參加人數", width: 13 },
          { header: "預計參加的每位會員交易時分", key: "預計參加的每位會員交易時分", width: 28 },
          { header: "總交易時分", key: "總交易時分", width: 14 },
          { header: "活動狀態", key: "活動狀態", width: 14 },
          { header: "參加會員編號", key: "參加會員編號", width: 21 },
          { header: "參加會員", key: "參加會員", width: 42 },
          { header: "備註", key: "備註", width: 25 },
        ];

        columns = columns.concat(value.map(column => {
          return {
            header: column.columnName,
            key: column.columnName,
            width: 15
          }
        }));

        builder.setColumns(columns);

        builder.sheet.getRow(1).height = 40;

        builder.sheet.getRow(2).height = 25;
        
        builder.setCellValue("A1", "中心活動列表報告");
        builder.sheet.mergeCells("A1:Q1");

        builder.setCellValue("A2", "開始日期");
        builder.setBorder("A2");
        builder.setBackgroundColor("A2", "EAF1DD");

        builder.setCellValue('B2', dateRange.startDate.toJSON().split('T')[0]);

        builder.setCellValue("C2", "截止日期");
        builder.setBorder("C2");
        builder.setBackgroundColor("C2", "EAF1DD");

        builder.setCellValue('D2', dateRange.endDate.toJSON().split('T')[0]);

        builder.sheet.addRow("");

        builder.sheet.getRow(4).values = columns.map(column => column.key);

        builder.setBorder('A4');
        builder.setBackgroundColor('A4', 'FDE9D9');
        builder.setBorder('B4');
        builder.setBackgroundColor('B4', 'FDE9D9');
        builder.setBorder('C4');
        builder.setBackgroundColor('C4', 'FDE9D9');
        builder.setBorder('D4');
        builder.setBackgroundColor('D4', 'FDE9D9');
        builder.setBorder('E4');
        builder.setBackgroundColor('E4', 'FDE9D9');
        builder.setBorder('F4');
        builder.setBackgroundColor('F4', 'FDE9D9');
        builder.setBorder('G4');
        builder.setBackgroundColor('G4', 'FDE9D9');
        builder.setBorder('H4');
        builder.setBackgroundColor('H4', 'FDE9D9');
        builder.setBorder('I4');
        builder.setBackgroundColor('I4', 'FDE9D9');
        builder.setBorder('J4');
        builder.setBackgroundColor('J4', 'FDE9D9');
        builder.setBorder('K4');
        builder.setBackgroundColor('K4', 'FDE9D9');
        builder.setBorder('L4');
        builder.setBackgroundColor('L4', 'EBF1DE');
        builder.setBorder('M4');
        builder.setBackgroundColor('M4', 'EBF1DE');
        builder.setBorder('N4');
        builder.setBackgroundColor('N4', 'EBF1DE');
        builder.setBorder('O4');
        builder.setBackgroundColor('O4', 'EBF1DE');
        builder.setBorder('P4');
        builder.setBackgroundColor('P4', 'EBF1DE');

        events.forEach((event, index) => {
          builder.sheet.addRow(event);
          builder.setDataValidation(`D${index + 5}`, '"會員加分,會員扣分,免費"');
          builder.setDataValidation(`E${index + 5}`, '"職員處理,先到先得"');
        });

        let buffer = await builder.workbook.xlsx.writeBuffer();

        saveAs(new Blob([buffer]), "Event_List_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
      })
  }

  async exportTransactions(
    user: Profile,
    transactions: UserTransaction[],
    redeems: RedeemHistory[],
    dateRange?: { startDate: Date, endDate: Date }): Promise<void> {

    if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null)
      dateRange = { startDate: new Date(2019, 1, 1), endDate: new Date() };

    let builder: ReportBuilder = new ReportBuilder(user.chineseName + " Report");

    let columns = [
      { header: "時分交易日期", key: "時分交易日期", width: 18 },
      { header: "交易編號", key: "交易編號", width: 21 },
      { header: "義工服務", key: "義工服務", width: 28 },
      { header: "中心活動", key: "中心活動", width: 28 },
      { header: "獎勵兌換", key: "獎勵兌換", width: 28 },
      { header: "捐分", key: "捐分", width: 26 },
      { header: "時分交易類型", key: "時分交易類型", width: 22 },
      { header: "交易對象", key: "交易對象", width: 21 },
      { header: "交易時分", key: "交易時分", width: 47 },
    ];

    builder.sheet.mergeCells("A1:I1");

    builder.setColumns(columns);

    builder.sheet.getRow(1).height = 27;

    builder.setCellValue("A1", `會員${user.chineseName} (ID${user.memberId}) 時分交易記錄`);

    builder.setCellValue("A2", "開始日期");
    builder.setBorder("A2");
    builder.setBackgroundColor("A2", "EBF1DE");

    builder.setCellValue("B2", dateRange.startDate.toJSON().split('T')[0]);

    builder.setCellValue("D2", "截至日期");
    builder.setBorder("D2");
    builder.setBackgroundColor("D2", "EBF1DE");

    builder.setCellValue("E2", dateRange.endDate.toJSON().split('T')[0]);

    builder.sheet.addRow("");

    builder.sheet.getRow(4).values = columns.map(column => column.key);

    builder.setBorder('A4');
    builder.setBackgroundColor('A4', 'FDE9D9');
    builder.setBorder('B4');
    builder.setBackgroundColor('B4', 'FDE9D9');
    builder.setBorder('C4');
    builder.setBackgroundColor('C4', 'FDE9D9');
    builder.setBorder('D4');
    builder.setBackgroundColor('D4', 'FDE9D9');
    builder.setBorder('E4');
    builder.setBackgroundColor('E4', 'FDE9D9');
    builder.setBorder('F4');
    builder.setBackgroundColor('F4', 'FDE9D9');
    builder.setBorder('G4');
    builder.setBackgroundColor('G4', 'FDE9D9');
    builder.setBorder('H4');
    builder.setBackgroundColor('H4', 'FDE9D9');
    builder.setBorder('I4');
    builder.setBackgroundColor('I4', 'FDE9D9');

    transactions.map(transaction => {
      return {
        "時分交易日期": transaction.date.toJSON().split('T')[0],
        "交易編號": transaction.id.toString(),
        "義工服務": transaction.request ? transaction.request.service.name : "無",
        "中心活動": transaction.event ? transaction.event.eventName : "無",
        "獎勵兌換": transaction.type == 'Redeem' ? redeems.find(redeem => redeem.transactionId == transaction.id)?.productName : "無",
        "捐分": transaction.type == 'Donation' ? (transaction.transferee.uuId == user.uuId ? "中心捐分" : "會員捐分") : "無",
        "時分交易類型": transaction.transferee.uuId == user.uuId ? "收入" : "支出",
        "交易對象": transaction.transferee.uuId == user.uuId ? transaction.transferer.chineseName : transaction.transferee.chineseName,
        "交易時分": transaction.amount.toString(),
      } as TransactionExportModel
    }).forEach(transaction => {
      builder.sheet.addRow(transaction);
    })

    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), user.chineseName + "_Transaction_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");
  }

  async exportDonation(donations: DonationExportModel[], dateRange?: { startDate: Date, endDate: Date }): Promise<void> {
    if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null)
      dateRange = { startDate: new Date(2019, 1, 1), endDate: new Date() };

    let builder: ReportBuilder = new ReportBuilder("Donation Report");

    let columns = [
      { header: "紀錄編號", key: "紀錄編號", width: 12 },
      { header: "會員編號", key: "會員編號", width: 37 },
      { header: "會員姓名", key: "會員姓名", width: 42 },
      { header: "捐分類型", key: "捐分類型", width: 13 },
      { header: "捐分日期（YYYY-MM-DD）", key: "捐分日期（YYYY-MM-DD）", width: 32 },
      { header: "交易時分（捐出/受贈的時分）", key: "交易時分（捐出/受贈的時分）", width: 26 },
      { header: "備註", key: "備註", width: 25 }
    ];

    builder.setColumns(columns);

    builder.setCellValue("A1", "捐分紀錄匯出報告");

    builder.sheet.mergeCells("A1:H1");

    builder.setCellValue("A2", "開始日期");
    builder.setBorder("A2");
    builder.setBackgroundColor("A2", "EAF1DD");

    builder.setCellValue('B2', dateRange.startDate.toJSON().split('T')[0]);

    builder.setCellValue("C2", "截止日期");
    builder.setBorder("C2");
    builder.setBackgroundColor("C2", "EAF1DD");

    builder.setCellValue('D2', dateRange.endDate.toJSON().split('T')[0]);

    builder.sheet.addRow("");

    builder.setCellValue("A4", "注意事項：中心捐分即中心將時分捐至會員帳戶，會員捐分即會員將時分捐至中心");
    builder.sheet.mergeCells("A4:H4");

    builder.sheet.getRow(5).values = columns.map(column => column.key);

    builder.setBorder('A5');
    builder.setBackgroundColor('A5', 'FDE9D9');
    builder.setBorder('B5');
    builder.setBackgroundColor('B5', 'FDE9D9');
    builder.setBorder('C5');
    builder.setBackgroundColor('C5', 'FDE9D9');
    builder.setBorder('D5');
    builder.setBackgroundColor('D5', 'FDE9D9');
    builder.setBorder('E5');
    builder.setBackgroundColor('E5', 'FDE9D9');
    builder.setBorder('F5');
    builder.setBackgroundColor('F5', 'FDE9D9');
    builder.setBorder('G5');
    builder.setBackgroundColor('G5', 'EAF1DD');

    donations.forEach((donation, index) => {
      builder.sheet.addRow(donation);
      builder.setDataValidation(`D${index + 6}`, '"中心捐分,會員捐分"');
    });

    let buffer = await builder.workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "Donation_Export_" + new Date().toISOString().split('T')[0] + ".xlsx");

  }

  exportUserSample(): void {
    forkJoin({
      excel: this.parseExcel("member_import_sample.xlsx"),
      columns: this.templateService.getProfileColumns()
    })
      .subscribe(result => {
        var cs = [ "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ"];
        result.columns.forEach((column, index) => {
          result.excel.worksheets[0].getCell(cs[index] + "6").value = column.columnName;
        })
        result.excel.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer]), "會員檔案資料上傳示範.xlsx");
        })
      })
  }

  exportRequestSample(): void {
    window.open("https://drive.google.com/file/d/1_RmryHqbQeGQn894bIK6akVP0YdPWLgJ/view?usp=sharing")
  }

  exportEventSample(): void {
    window.open("https://drive.google.com/file/d/1-BjYD7oEAdqu2ArZ3xkiE609obvJcDrl/view?usp=sharing");
  }

  exportDonationSample(): void {
    window.open("https://drive.google.com/file/d/1-B-1RC_jWS0smFVRlN6FgXOuJe6PAQvP/view?usp=sharing");
  }

  private parseExcel(path: string): Observable<Workbook> {
    var observable = new Observable<Workbook>(subscriber => {
      const workbook = new Workbook();

      this.httpService.getExcels(path)
        .subscribe(value => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(value);
          reader.onload = () => {
            const buffer: any = reader.result;
            workbook.xlsx.load(buffer).then(() => {
              subscriber.next(workbook);
              subscriber.complete();
            });
          }
        });
    })

    return observable;
  }
}

export class ReportBuilder {
  workbook: Workbook;
  sheets: Worksheet[];
  index: number;

  get sheet(): Worksheet{
    return this.sheets[this.index];
  }

  addSheet(sheetName: string){
    this.sheets.push(this.workbook.addWorksheet(sheetName));
  }

  set NowSheetIndex(index:number){
    this.index=index;
  }

  getMaxSheetIndex(){
    return this.sheets.length;
  }

  constructor(sheetName: string) {
    this.workbook = new Workbook();
    this.sheets = [this.workbook.addWorksheet(sheetName)];
    this.index = 0;
  }

  setCellMiddle(cell: string){
    this.sheet.getCell(cell).alignment = {
        vertical: 'middle',
        horizontal: 'center'
    }
  }

  setHeight(row: number, value: number){
    this.sheet.getRow(row).height=value;
  }
  setWidth(colulmn:string,value:number){
    this.sheet.getColumn(colulmn).width=value;
  }

  setColumns(columns: any[]) {
    this.sheet.columns = columns;
  }

  setCellValue(cell: string, value: string) {
    this.sheet.getCell(cell).value = value;
  }

  setBackgroundColor(cell: string, colorCode: string) {
    this.sheet.getCell(cell).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: colorCode }
    }
  }

  setFontSize(cell:string,fontSize:number){
    this.sheet.getCell(cell).font= {
      name: 'Calibri (Body)',
      size: fontSize,
    };
  }

  setBorder(cell: string) {
    this.sheet.getCell(cell).border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } }
    };
  }

  setDataValidation(cell: string, formulae: string) {
    this.sheet.getCell(cell).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [formulae]
    }
  }
}

function ExportUserSorting(a: UserExportModel, b: UserExportModel): number {
  return a['會員編號*'].localeCompare(b['會員編號*'], 'en', { numeric: true })
}