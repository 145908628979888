import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from "dayjs";

@Component({
  selector: "app-user-ranking-analysis",
  templateUrl: "./user-ranking-analysis.component.html",
  styleUrls: ["./user-ranking-analysis.component.scss"],
})
export class UserRankingAnalysisComponent implements OnInit {
  @Input() form!: FormControl;

  get placeholder(): string {
    let placeholderStart = dayjs()
      .set("month", 0)
      .set("date", 1)
      .format("YYYY-MM-DD");
    let placeholderEnd = dayjs().format("YYYY-MM-DD");
    if (this.start.value != null) {
      placeholderStart = dayjs(this.start.value).format("YYYY-MM-DD");
    }
    if (this.end.value != null) {
      placeholderEnd = dayjs(this.end.value).format("YYYY-MM-DD");
    }
    return `${placeholderStart} - ${placeholderEnd}`;
  }

  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  centerUUId?: string;

  public ranking: { index: string; value: number }[] = [];
  public topRanking: { index: string; value: number }[] = [];
  public currentLang: string = "";

  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });
  }

  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null) return null;
    return `${dayjs(this.start.value).format("YYYY-MM-DD")} - ${dayjs(this.end.value).format("YYYY-MM-DD")}`;
  }

  fetch(): void {
    var start = !this.start.value
      ? undefined
      : dayjs(this.start.value).format("YYYY-MM-DD");
    var end = !this.end.value
      ? undefined
      : dayjs(this.end.value).format("YYYY-MM-DD");

    forkJoin({
      ranking: this.analyticsService.getRanking(this.centerUUId, start, end),
    }).subscribe((value) => {
      this.ranking = value.ranking;
      this.topRanking = this.sortRanking();
    });
  }

  sortRanking(): { index: string; value: number }[] {
    return this.ranking
      .sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0))
      .slice(0, 10);
  }
}
