import { Component, OnInit } from '@angular/core';
import { Redeem } from 'src/app/models/redeems/redeem';

@Component({
  selector: 'app-redeem-popup',
  templateUrl: './redeem-popup.component.html',
  styleUrls: ['./redeem-popup.component.scss']
})
export class RedeemPopupComponent implements OnInit {

  redeem: Redeem | null = null;
  display: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  open(redeem: Redeem): void {
    this.redeem = redeem;
    this.display = true;
  }

  close(): void {
    this.display = false;
    this.redeem = null;
  }
  public isMultiChoices(ans: any): boolean {
    return typeof (ans) != "string";
  }
}
