import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CreateLivingModel } from "src/app/models/livings/create-living-model";
import { ILiving, Living } from "src/app/models/livings/living";
import { UpdateLivingModel } from "src/app/models/livings/update-living-model";

@Injectable({
  providedIn: "root",
})
export class LivingService {
  constructor(private httpService: HttpService) {}

  public getLivingConditionList(): Observable<Living[]> {
    return this.httpService
      .get<ILiving[]>("livingConditions")
      .pipe(
        map((value) =>
          value.result
            .map((living) => new Living(living))
            .sort(LivingConditionSorting),
        ),
      );
  }

  public createLivingCondition(model: CreateLivingModel): Observable<any> {
    return this.httpService.post<any, CreateLivingModel>(
      "livingConditions",
      model,
    );
  }

  public updateLivingConditon(model: UpdateLivingModel): Observable<any> {
    return this.httpService.put<any, UpdateLivingModel>(
      "livingConditions",
      model,
    );
  }
}

function LivingConditionSorting(a: Living, b: Living): number {
  return a.name.localeCompare(b.name, "en", { numeric: true });
}
