import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Announcement } from 'src/app/models/announcements/announcement';
import { UpdateAnnouncementModel } from 'src/app/models/announcements/update-announcement-model';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';

@Component({
  selector: 'app-announcement-detail-page',
  templateUrl: './announcement-detail-page.component.html',
  styleUrls: ['./announcement-detail-page.component.scss']
})
export class AnnouncementDetailPageComponent implements OnInit {

  announcement: Announcement | null = null;
  now: Date = new Date();
  minDate: Date = new Date();

  lang = this.translateService.currentLang;

  constructor(
    private route: ActivatedRoute,
    private announcementService: AnnouncementService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private popupMessageService: PopupMessageService
  ) { }



  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
    this.fetch();
  }

  fetch(): void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.announcementService.getAnnouncement(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: value => {
            this.announcement = value;
            this.announcementService.setSelection((this.announcement.users));
          }
        });
    })
  }

  openURL(): void {
    if (this.url.value == null)
      return;
    window.open(this.url.value);
  }


  pinAnnouncement(): void {
    this.loadingService.startLoading();
    this.announcementService.pinAnnouncement({ AnnouncementUUId: this.announcement?.uuId as string })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => this.fetch()
      })
  }

  unpinAnnouncement(): void {
    this.loadingService.startLoading();
    this.announcementService.unpinAnnouncement({ AnnouncementUUId: this.announcement?.uuId as string })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => this.fetch()
      })
  }


  submit(): void {
    if (this.announcement?.form.invalid || this.announcement?.invalidExpireDate) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    if (this.announcement?.form.dirty == false) {
      this.announcement.editing = false;
      return;
    }

    this.loadingService.startLoading();
    this.announcementService.updateAnnouncement(this.announcement?.getUpdateAnnouncementModel() as UpdateAnnouncementModel)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.fetch();
          this.popupMessageService.messageSignal.emit(PopupMessages.EditAnnouncementSuccessMessage);
        }
      })
  }

  get title(): AbstractControl {
    return this.announcement?.form.controls['title'] as FormControl;
  }

  get type(): AbstractControl {
    return this.announcement?.form.controls['type'] as FormControl;
  }

  get url(): AbstractControl {
    return this.announcement?.form.controls['url'] as FormControl;
  }

  get announceDate(): FormControl {
    return this.announcement?.form.controls['announceDate'] as FormControl;
  }

  get expireDate(): FormControl {
    return this.announcement?.form.controls['expireDate'] as FormControl;
  }

  get content(): AbstractControl {
    return this.announcement?.form.controls['content'] as FormControl;
  }

  get remark(): AbstractControl {
    return this.announcement?.form.controls['remark'] as FormControl;
  }
}
