import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { CreateEventDayModel } from "src/app/models/events/create-event-day-model";
import { EventDay } from "src/app/models/events/event-day";

export interface IEventDayForm {
    formGroup: FormGroup;
    editing: boolean;

    getModel(): CreateEventDayModel;

    date: AbstractControl;

    start: AbstractControl

    end: AbstractControl;

}

export class CreateEventDayForm implements IEventDayForm {
    formGroup: FormGroup;
    editing: boolean;
    numOfAttendance:number|null|undefined;
    constructor(eventDay?: EventDay) {
        this.formGroup = new FormGroup({
            date: new FormControl(eventDay?.date, [Validators.required]),
            start: new FormControl(eventDay?.startTime, [Validators.required]),
            end: new FormControl(eventDay?.endTime, [Validators.required]),
            
        });
        this.editing = false;
        this.numOfAttendance=eventDay?.numOfAttendance;
        this.formGroup.controls['date'].valueChanges.subscribe((date: Date) => {
            // if (date == null)
            //     return;

            let start: Date = this.formGroup.controls['start'].value
            if (start != null) {
                start.setDate(date.getDate());
                start.setMonth(date.getMonth());
                start.setFullYear(date.getFullYear());
                this.start.setValue(new Date(start));
            }
            else {
                this.formGroup.controls['start'].setValue(new Date(date));
            }

            let end: Date = this.formGroup.controls['end'].value
            if (end != null) {
                end.setDate(date.getDate());
                end.setMonth(date.getMonth());
                end.setFullYear(date.getFullYear());
                this.end.setValue(new Date(end));
            }
            else {
                this.formGroup.controls['end'].setValue(new Date(date));
            }
        });

        this.formGroup.controls['start'].valueChanges.subscribe(() => {
            this.validateDate();
        });

        this.formGroup.controls['end'].valueChanges.subscribe(() => {
            setTimeout(() => this.validateDate(), 100);
        });

        this.date.setValue(this.date.value);
    }

    validateDate(): void {
        if (this.formGroup.controls['start'].value == null || this.formGroup.controls['end'].value == null)
            return;
        if (this.formGroup.controls['start'].invalid || this.formGroup.controls['end'].invalid)
            return;
        if (this.formGroup.controls['start'].value as Date >= (this.formGroup.controls['end'].value as Date))
            this.formGroup.controls['end'].setErrors({ invalid: true })
    }

    getCraeteEventDayModel(): CreateEventDayModel {
        return {
            date: (this.date.value as Date).toISOString(),
            startTime: (this.start.value as Date).toISOString(),
            endTime: (this.end.value as Date).toISOString(),
            numOfAttendance:this.numOfAttendance
        }
    }

    getModel(): CreateEventDayModel {
        return this.getCraeteEventDayModel();
    }

    get date(): AbstractControl {
        return this.formGroup.controls['date'];
    }

    get start(): AbstractControl {
        return this.formGroup.controls['start'];
    }

    get end(): AbstractControl {
        return this.formGroup.controls['end'];
    }
}