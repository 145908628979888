import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import {
  RedeemHistory,
  IRedeemHistory,
} from "src/app/models/redeems/redeem-history";
import { LoadingService } from "src/app/services/loading/loading.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-user-redeem-transaction",
  templateUrl: "./user-redeem-transaction.component.html",
  styleUrls: ["./user-redeem-transaction.component.scss"],
})
export class UserRedeemTransactionComponent implements OnInit {
  userUUId!: string;
  @Input() redeems: RedeemHistory[] = [];
  @Input() reloadAction: () => void = () => {};

  filters: any = {
    page: 1,
    itemsPerPage: 10,
    idOrder: null,
    dateOrder: true,
  };

  itemsPerPageOption = [5, 10, 20, 30, 50];

  constructor(
    private route: ActivatedRoute,
    private redeemService: RedeemService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {}

  get pages(): number {
    return this.redeems
      ? Math.ceil(this.redeems.length / this.filters.itemsPerPage)
      : 0;
  }

  get selectedTransactions(): RedeemHistory[] {
    return this.redeems.filter((redeem) => redeem.selected);
  }

  get displayedRedeems(): RedeemHistory[] {
    let result = this.redeems;

    if (this.filters.idOrder != null) {
      if (this.filters.idOrder == true)
        result = this.redeems.sort((a, b) =>
          a.redeemCode > b.redeemCode
            ? 1
            : a.redeemCode < b.redeemCode
              ? -1
              : 0,
        );
      else
        result = this.redeems.sort((b, a) =>
          a.redeemCode > b.redeemCode
            ? 1
            : a.redeemCode < b.redeemCode
              ? -1
              : 0,
        );
    }

    if (this.filters.dateOrder != null) {
      if (this.filters.dateOrder == true)
        result = this.redeems.sort((b, a) =>
          a.redeemDate > b.redeemDate
            ? 1
            : a.redeemDate < b.redeemDate
              ? -1
              : 0,
        );
      else
        result = this.redeems.sort((a, b) =>
          a.redeemDate > b.redeemDate
            ? 1
            : a.redeemDate < b.redeemDate
              ? -1
              : 0,
        );
    }

    return result.slice(
      (this.filters.page - 1) * this.filters.itemsPerPage,
      this.filters.page * this.filters.itemsPerPage,
    );
  }

  get isSelectedAll(): boolean {
    return this.displayedRedeems.every((redeem) => redeem.selected == true);
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.displayedRedeems.forEach((redeem) => (redeem.selected = false));
    else this.displayedRedeems.forEach((redeem) => (redeem.selected = true));
  }
}
