import { IHealth } from "../healths/health";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IRequester {
  uuId: string;
  chineseName: string;
  dateOfBirth: string;
  phone: string;
  memberId: string;
  gender: string;
  englishName: string;
  contactPersonName: string;
  contactPersonPhoneNumber: string;
  healths: IHealth[];
}

export class Requester {
  uuId: string;
  chineseName: string;
  dateOfBirth: Date;
  phone: string;
  memberId: string;
  gender: string;
  englishName: string;
  contactPersonName: string;
  contactPersonPhoneNumber: string;
  healths: IHealth[];

  constructor(requester: IRequester) {
    this.uuId = requester.uuId;
    this.chineseName = requester.chineseName;
    this.dateOfBirth = dayjs.utc(requester.dateOfBirth).toDate();
    this.phone = requester.phone;
    this.memberId = requester.memberId;
    this.gender = requester.gender;
    this.englishName = requester.englishName;
    this.contactPersonName = requester.contactPersonName;
    this.contactPersonPhoneNumber = requester.contactPersonPhoneNumber;
    this.healths = requester.healths;
  }
}
