<div class="analysis-container">
    <div class="analysis-container-header autoHeight">
        <p class="text-bold text-wrap"  translate>{{ '累積的總時分排行榜' | translate: {StartDate: PrevMonthStart | date: 'longDate':null:currentLang  ,EndDate:PrevMonthEnd| date: 'longDate':null:currentLang } }}</p>
    </div>

    <div class="analysis-container-body">
        <div class="ranking-box" *ngFor="let rank of topRanking; let i = index">
            <p class="ranking-number mr-4">{{ i+1 }}</p>
            <p class="flex-1">{{ rank.index }}</p>
            <p>{{ rank.value | number }}</p>
        </div>
        <div *ngIf="ranking.length == 0">
            <p translate>沒有排名</p>
        </div>
    </div>

    <div *ngIf="firstDonation!=null" class="analysis-container-footer autoHeight">
        <div>
            <p class="flex-1 text-wrap text-bold" translate>{{ '機構接收了會員捐贈的時分總數' | translate: {StartDate: firstDonation| date: 'longDate':null:currentLang} }}</p>
            <p class="totalDonation ">{{ totalDonation }}</p>
        </div>
    </div>
</div>