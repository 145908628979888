import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ServiceType } from 'src/app/models/services/service-type';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-service-type-setting',
  templateUrl: './service-type-setting.component.html',
  styleUrls: ['./service-type-setting.component.scss']
})
export class ServiceTypeSettingComponent implements OnInit {

  loading: boolean = false;
  serviceTypes: ServiceType[] = [];
  form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required])
  })

  constructor(
    private serviceService: ServiceService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.serviceService.getAllServiceTypeList()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: value => {
          this.serviceTypes = value
        }
      });
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.serviceService.createServiceType({
      name: this.form.controls['name'].value
    }).pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.fetch();
          this.form.reset();
          this.serviceService.signal.emit();
        }
      });
  }

  update(serviceType: ServiceType): void {
    if (serviceType.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.serviceService.updateServiceType(serviceType.getUpdateServiceTypeModel())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.fetch();
          this.serviceService.signal.emit()
        }
      });
  }

}
