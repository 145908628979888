import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Health } from "src/app/models/healths/health";
import { Living } from "src/app/models/livings/living";
import { Profile } from "src/app/models/profiles/profile";
import { Service } from "src/app/models/services/service";
import { ExportService } from "src/app/services/export/export.service";
import { ImportService } from "src/app/services/import/import.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-user-overview-page",
  templateUrl: "./user-overview-page.component.html",
  styleUrls: ["./user-overview-page.component.scss"],
})
export class UserOverviewPageComponent implements OnInit {
  count: number = 0;
  pages: number = 0;
  profiles: Profile[] = [];
  caches: Profile[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
    districts: [],
    subDistricts: [],
    services: [],
    conditions: [],
    healths: [],
    genders: [],
    memberIdOrder: true,
    creditOrder: null,
    birthdayFrom: null,
    birthdayTo: null,
    cellGroup: [],
  };

  constructor(
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private popupMessageService: PopupMessageService,
    private exportService: ExportService,
    private importService: ImportService,
  ) {}

  ngOnInit(): void {
    this.importService.refreshSignal.subscribe((value) => {
      this.fetch();
    });

    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.profileService
      .getProfileList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.count = value.count;
          this.profiles = value.list;
          this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
          this.profiles.forEach((profile) => {
            if (this.caches.find((u) => u.uuId == profile.uuId))
              profile.selected = true;
          });
        },
      });
  }

  cacheSelectedProfile(profile: Profile): void {
    if (profile.selected) this.caches.push(profile);
    else
      this.caches.splice(
        this.caches.findIndex((u) => u.uuId == profile.uuId),
        1,
      );
  }

  changePage(): void {
    this.router.navigate(["/main/user/overview"], {
      queryParams: {
        page: this.filters.page,
        itemsPerPage: this.filters.itemsPerPage,
      },
    });
  }

  private getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.services
        .map((service: Service) => "&services=" + service.uuId)
        .join("") +
      this.filters.districts
        .map((district: string) => "&districts=" + district)
        .join("") +
      this.filters.subDistricts
        .map((subDistrict: string) => "&subDistricts=" + subDistrict)
        .join("") +
      this.filters.conditions
        .map((condition: Living) => "&livingConditions=" + condition.uuId)
        .join("") +
      this.filters.healths
        .map((health: Health) => "&healthConditions=" + health.uuId)
        .join("") +
      this.filters.genders
        .map((gender: string) => "&gender=" + gender)
        .join("") +
      (this.filters.memberIdOrder != null
        ? "&orderByMemberId=" + String(this.filters.memberIdOrder)
        : "") +
      (this.filters.creditOrder != null
        ? "&orderByBalance=" + String(this.filters.creditOrder)
        : "") +
      (this.filters.birthdayFrom != null
        ? "&birthdayFrom=" + (this.filters.birthdayFrom as Date).toJSON()
        : "") +
      (this.filters.birthdayTo != null
        ? "&birthdayTo=" + (this.filters.birthdayTo as Date).toJSON()
        : "") +
      this.filters.cellGroup
        .map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId)
        .join("")
    );
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction = ((value: Center[] | any) => {
    this.filters.centers = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  conditionFilterAction = ((
    genders: string[] | any,
    healths: Health[] | any,
    livings: Living[] | any,
    birthdayFrom: Date,
    birthdayTo: Date,
  ) => {
    this.filters.genders = genders;
    this.filters.healths = healths;
    this.filters.conditions = livings;
    this.filters.page = 1;
    this.filters.birthdayFrom = birthdayFrom;
    this.filters.birthdayTo = birthdayTo;
    this.fetch();
  }).bind(this);

  cellGroupFilterAction = ((center: Center[]) => {
    this.filters.cellGroup = [];
    center.forEach((c) =>
      c.cellGroups.forEach((cg) =>
        cg.selected ? this.filters.cellGroup.push(cg) : null,
      ),
    );
    console.log(this.filters.cellGroup);
    this.fetch();
  }).bind(this);

  selectAll(): void {
    if (this.isSelectedAll) {
      this.profiles.forEach((p) => {
        p.selected = false;
        this.caches.splice(
          this.caches.findIndex((u) => u.uuId == p.uuId),
          1,
        );
      });
    } else {
      this.caches = this.caches.concat(
        this.profiles.filter((profile) => profile.selected == false),
      );
      this.profiles.forEach((p) => (p.selected = true));
    }
  }

  get isSelectedAll(): boolean {
    return this.profiles.every((profile) => profile.selected == true);
  }

  deleteUser(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteUserMessage(this.profileService.deleteUsers([uuId])),
    );
  }

  deleteUserList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteUserListMessage(
        this.profileService.deleteUsers(this.caches.map((p) => p.uuId)),
      ),
    );
  }

  exportUsers(): void {
    if (this.caches.length != 0) {
      this.exportService.exportUsers(
        this.caches.map((user) => user.getUserExportModel()),
        "User",
      );
      return;
    }

    this.loadingService.startLoading();
    this.profileService
      .getProfileList(this.getEverythingQuery())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.exportService.exportUsers(
          value.list.map((user) => user.getUserExportModel()),
          "User",
        );
      });
  }

  private getEverythingQuery(): string {
    return (
      "?start=0&limit=1000000" +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.services
        .map((service: Service) => "&services=" + service.uuId)
        .join("") +
      this.filters.districts
        .map((district: string) => "&districts=" + district)
        .join("") +
      this.filters.subDistricts
        .map((subDistrict: string) => "&subDistricts=" + subDistrict)
        .join("") +
      this.filters.conditions
        .map((condition: Living) => "&livingConditions=" + condition.uuId)
        .join("") +
      this.filters.healths
        .map((health: Health) => "&healthConditions=" + health.uuId)
        .join("") +
      this.filters.genders
        .map((gender: string) => "&gender=" + gender)
        .join("") +
      (this.filters.memberIdOrder != null
        ? "&orderByMemberId=" + String(this.filters.memberIdOrder)
        : "") +
      (this.filters.creditOrder != null
        ? "&orderByBalance=" + String(this.filters.creditOrder)
        : "") +
      (this.filters.birthdayFrom != null
        ? "&birthdayFrom=" + (this.filters.birthdayFrom as Date).toJSON()
        : "") +
      (this.filters.birthdayTo != null
        ? "&birthdayTo=" + (this.filters.birthdayTo as Date).toJSON()
        : "")
    );
  }

  openImportPopup(): void {
    this.importService.importPopupSignal.emit("User");
  }
}
