<div class="popup-bg" *ngIf="display">
    <div class="initialize-popup-container">
        <div class="slide-container" [ngClass]="{'page-1': currentSlide == 1, 'page-2': currentSlide == 2, 'page-3': currentSlide == 3, 'page-4': currentSlide == 4, 'page-5': currentSlide >= 5}">

            <div class="slide">
                <ng-container *ngTemplateOutlet="Main"></ng-container>
            </div>

            <div class="slide">
                <ng-container *ngTemplateOutlet="STEP1"></ng-container>
            </div>

            <div class="slide">
                <ng-container *ngTemplateOutlet="STEP2"></ng-container>
            </div>

            <div class="slide">
                <ng-container *ngTemplateOutlet="STEP3"></ng-container>
            </div>

            <div class="slide">
                <ng-container *ngTemplateOutlet="STEP4"></ng-container>
            </div>

            <div class="slide">
                <ng-container *ngTemplateOutlet="Preview"></ng-container>
            </div>

        </div>
    </div>
</div>

<ng-template #Main>
    <form class="flex-row align-items-stretch flex-1" [formGroup]="OrganizationFormGroup">
        <div class="flex-column" style="flex: 1;">
            <p class="initialize-popup-title" translate>開始創建專屬的時間銀行平台 ！</p>
            <p style="font-size: 1.125rem;margin-top: 2.8125rem;line-height: 1.125rem;" translate>平台名稱</p>
            <input class="initialize-popup-input mt-2" placeholder="{{ '不超過18個字' | translate }}" formControlName="name">
            <p style="font-size: 1.125rem; margin-top: 1.875rem;line-height: 1.125rem;" translate>機構LOGO</p>
            <div class="flex-row mt-2">
                <input #image style="display: none;" type="file" accept="image/*" (change)="onImageImport($event.target.files)">
                <input class="initialize-popup-input flex-1" placeholder="hincare.png" (click)="image.click()" [ngModel]="logoName" [ngModelOptions]="{standalone: true}" readonly>
                <button class="initialize-popup-button ml-3" type="button" (click)="image.click()" translate>選擇文件</button>
            </div>
            <label class="mt-3">
                <input type="checkbox" formControlName="useDefaultLogo" (change)="useDefaultImage()">
                <p class="ml-4" translate>使用HINCare平台默認LOGO</p>
            </label>
            <div class="preview-line">
                <p class="preview-text" translate>預覽</p>
            </div>
            <div class="flex-auto">
                <img *ngIf="logoUrl" [src]="logoUrl" height="60" width="60" alt="Logo">
            </div>

            <div class="flex-1"></div>

            <button class="start-button align-self-center mb-5" type="button" (click)="currentSlide = currentSlide + 1" [disabled]="!organizationFormValid" translate>開始</button>
        </div>
        <div class="flex-column justify-content-end align-items-center" style="flex:1">
            <p class="initialize-popup-title">HINCare</p>
            <p class="initialize-popup-title mt-3" translate>義工管理服務平台</p>
        </div>
    </form>
</ng-template>

<ng-template #STEP1>
    <form class="flex-column flex-1" [formGroup]="UnitFormGroup">
        <p class="click-text-lightblue align-self-start mb-5" (click)="currentSlide = currentSlide - 1" translate>上一步</p>
        <div class="flex-row align-items-center" style="margin-top: 2.25rem;">
            <div class="flex-row align-items-center">
                <div class="step-icon step-1" [ngClass]="{'done': currentSlide > 1}"></div>
                <p class="step-text" translate>進行中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 2}">
                <div class="step-icon step-2" [ngClass]="{'done': currentSlide > 2}"></div>
                <p class="step-text" translate>等待中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 3}">
                <div class="step-icon step-3" [ngClass]="{'done': currentSlide > 3}"></div>
                <p class="step-text" translate>等待中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 4}">
                <div class="step-icon step-4"></div>
                <p class="step-text" translate>等待中</p>
            </div>
        </div>
        <p class="align-self-center" style="margin-top:2rem; font-size: 1.125rem;line-height: 1.125rem;" translate>系統會幫義工活動計算時分，請選擇適用的時分單位</p>
        <div class="flex-column align-items-center justify-content-center flex-1">
            <div class="flex-column" style="margin-top: 3.625rem;">
                <label>
                    <input type="radio" style="font-size: 1.125rem;line-height: 1.125rem;" formControlName="unit" value="hour">
                    <p class="ml-8" translate>以1小時為單位</p>
                </label>
                <label style="margin-top: 2rem;">
                    <input type="radio" style="font-size: 1.125rem;line-height: 1.125rem;" formControlName="unit" value="minute">
                    <p class="ml-8" translate>以1分鐘為單位</p>
                </label>
            </div>
        </div>

        <div class="flex-1"></div>

        <button class="start-button align-self-center" (click)="currentSlide = currentSlide + 1" type="button" [disabled]="!UnitFormGroup.valid" translate>下一步</button>
    </form>
</ng-template>

<ng-template #STEP2>
    <form class="flex-column flex-1" [formGroup]="CenterFormGroup">
        <p class="click-text-lightblue align-self-start mb-5" (click)="currentSlide = currentSlide - 1" translate>上一步</p>
        <div class="flex-row align-items-center mt-5">
            <div class="flex-row align-items-center">
                <div class="step-icon step-1" [ngClass]="{'done': currentSlide > 1}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 2}">
                <div class="step-icon step-2" [ngClass]="{'done': currentSlide > 2}"></div>
                <p class="step-text" translate>進行中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 3}">
                <div class="step-icon step-3" [ngClass]="{'done': currentSlide > 3}"></div>
                <p class="step-text" translate>等待中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 4}">
                <div class="step-icon step-4"></div>
                <p class="step-text" translate>等待中</p>
            </div>
        </div>
        <p class="align-self-center" style="margin-top: 1.25rem;font-size: 1.125rem;line-height: 1.125rem;" translate>機構旗下至少需創建一個中心，請填寫中心信息：</p>
        <div class="flex-column align-self-center flex-1" style="margin-top: 1.5rem;">
            <div class="flex-row align-items-center justify-content-center">
                <div class="flex-column">
                    <p class="placeholder-text" translate>中心代碼</p>
                    <input class="initialize-popup-input mt-2" style="width: 10.25rem;" placeholder="{{ '建議英文或數字組成' | translate }}" formControlName="centerCode">
                </div>
                <div class="flex-column ml-3">
                    <p class="placeholder-text" translate>中心名稱</p>
                    <input class="initialize-popup-input mt-2 long" placeholder="{{ '中心名稱' | translate }}" formControlName="centerName">
                </div>
            </div>
            <div class="flex-row align-items-center mt-3">
                <div class="flex-column">
                    <p class="placeholder-text" translate>中心聯絡電話</p>
                    <input class="initialize-popup-input mt-2" style="width: 10.25rem;" placeholder="{{ '請輸入電話號碼' | translate }}" formControlName="centerContact">
                </div>
            </div>
        </div>
        <div class="flex-1"></div>
        <button class="start-button align-self-center" type="button" (click)="currentSlide = currentSlide + 1" [disabled]="!CenterFormGroup.valid" translate>下一步</button>
    </form>
</ng-template>

<ng-template #STEP3>
    <form class="flex-column flex-1" [formGroup]="InviteSettingFormGroup">
        <p class="click-text-lightblue align-self-start mb-5" (click)="currentSlide = currentSlide - 1" translate>上一步</p>
        <div class="flex-row align-items-center mt-5">
            <div class="flex-row align-items-center">
                <div class="step-icon step-1" [ngClass]="{'done': currentSlide > 1}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 2}">
                <div class="step-icon step-2" [ngClass]="{'done': currentSlide > 2}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 3}">
                <div class="step-icon step-3" [ngClass]="{'done': currentSlide > 3}"></div>
                <p class="step-text" translate>進行中</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 4}">
                <div class="step-icon step-4"></div>
                <p class="step-text" translate>等待中</p>
            </div>
        </div>
        <p class="align-self-center" style="margin-top: 2rem;font-size: 1.125rem;line-height: 1.125rem;width: 20.25rem;white-space: normal;" translate>在應用程式中，當會員申請義工服務時，可否自行選擇邀請符合要求的義工人選？</p>
        <div class="flex-column align-items-center justify-content-center flex-1">
            <div class="flex-column">
                <label>
                    <input type="radio" formControlName="directInvitation" [value]="true">
                    <p style="font-size: 1.125rem;line-height: 1.125rem;" translate>可以，會員可自由選擇邀請的義工</p>
                </label>
                <label style="margin-top: 1.5rem;">
                    <input type="radio" formControlName="directInvitation" [value]="false">
                    <p style="font-size: 1.125rem;line-height: 1.125rem;" translate>不可以，僅職員有權決定邀請的義工</p>
                </label>
            </div>
        </div>
        <div class="flex-1"></div>
        <button class="start-button align-self-center" (click)="currentSlide = currentSlide + 1" [disabled]="!InviteSettingFormGroup.valid" translate>下一步</button>
    </form>
</ng-template>

<ng-template #STEP4>
    <form class="flex-column flex-1" [formGroup]="RequestSettingFormGroup">
        <p class="click-text-lightblue align-self-start mb-5" (click)="currentSlide = currentSlide - 1" translate>上一步</p>
        <div class="flex-row align-items-center mt-5">
            <div class="flex-row align-items-center">
                <div class="step-icon step-1" [ngClass]="{'done': currentSlide > 1}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 2}">
                <div class="step-icon step-2" [ngClass]="{'done': currentSlide > 2}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 3}">
                <div class="step-icon step-3" [ngClass]="{'done': currentSlide > 3}"></div>
                <p class="step-text" translate>已完成</p>
            </div>
            <div class="step-line"></div>
            <div class="flex-row align-items-center" [ngClass]="{'pending': currentSlide < 4}">
                <div class="step-icon step-4"></div>
                <p class="step-text" translate>進行中</p>
            </div>
        </div>
        <p class="align-self-center" style="margin-top: 1.25rem;font-size: 1.125rem;line-height: 1.125rem;width: 29.25rem;white-space: normal" translate>在義工服務過程中，會員可在應用程式中報到及結束服務，請在兩個階段分別選擇哪個角色擁有權限：</p>
        <div class="flex-row flex-1" style="margin-top: 2rem;">
            <div class="flex-column flex-1-0">
                <p translate>服務開始，</p>
                <label style="margin-top: 1.25rem;">
                    <input type="radio" formControlName="start" [value]="true">
                    <div class="flex-row ml-2">
                        <p translate>由義工確認報到 </p>
                        <p>&nbsp;(</p>
                        <p class="text-blue" translate>推薦</p>
                        <p>)</p>
                    </div>
                </label>

                <p class="option-description" translate>當服務有多個配對義工時，最後一個義工報到後，系統視為服務開始，職員可於後台修改到場義工名單和實際開始時間</p>

                <label style="margin-top: 1rem;">
                    <input type="radio" formControlName="start" [value]="false">
                    <p class="ml-2" translate>由受助者確認報到</p>
                </label>

                <p class="option-description" translate>若實際義工沒有進行服務，受助者須聯絡職員處理，否則受助者確認報到後，系統則視為服務開始，所有義工到場</p>
            </div>
            <div class="flex-column flex-1-0">
                <p translate>服務結束，</p>

                <label style="margin-top: 1.25rem;">
                    <input type="radio" formControlName="end" [value]="true">
                    <p class="ml-2" translate>由義工確認結束</p>
                </label>

                <p class="option-description" translate>當服務有多個配對義工時，任何一個義工確認結束後，系統則視為服務結束，產生時分交易，職員可於後台修改時分</p>

                <label style="margin-top: 1rem;">
                    <input type="radio" formControlName="end" [value]="false">
                    <div class="flex-row ml-2">
                        <p translate>由受助者確認結束</p>
                        <p>&nbsp;(</p>
                        <p class="text-blue" translate>推薦</p>
                        <p>)</p>
                    </div>
                </label>

                <p class="option-description" translate>受助者確認結束後，系統則視為服務結束，產生時分交易，職員可於後台修改時分</p>
            </div>
        </div>

        <div class="flex-1"></div>

        <button class="start-button align-self-center" (click)="currentSlide = currentSlide + 1" [disabled]="!RequestSettingFormGroup.valid" translate>預覽後確認</button>
    </form>
</ng-template>

<ng-template #Preview>
    <div class="flex-column flex-1">
        <p class="click-text-lightblue align-self-start" (click)="currentSlide = currentSlide - 1" translate>上一步</p>
        <div class="flex-column align-items-center mt-5 flex-1">
            <p style="font-size: 1.125rem;line-height: 1.125rem;" translate>確認你選擇的系統流程方案</p>
            <ul class="flex-column justify-content-between flex-1" style="margin-top: 2rem;">
                <li style="font-size: 1.125rem;line-height: 1.125rem;" translate>你選擇的時分單位：</li>
                <p *ngIf="unit == 'minute'" style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" translate>1分鐘=1時分</p>
                <p *ngIf="unit == 'hour'" style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" translate>1小時=1時分</p>
                <li style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 1.5rem;" translate>機構下屬中心：</li>
                <p class="align-self-center text-bold" style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;">{{centerInfo}}</p>
                <li style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 1.5rem;" translate>在應用程式中，受助者是否可選擇邀請符合要求的義工人選：</li>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="directInvitation == true" translate>可以</p>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="directInvitation == false" translate>不可以</p>
                <li style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 1.5rem;" translate>義工服務開始時，可在應用程式中確認報到的角色是：</li>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="serviceStartSetting == true" translate>義工</p>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="serviceStartSetting == false" translate>受助者</p>
                <li style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 1.5rem;" translate>義工 義工服務結束時，可在應用程式中確認結束的角色是：</li>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="serviceEndSetting == true" translate>義工</p>
                <p style="font-size: 1.125rem;line-height: 1.125rem;margin-top: 0.75rem;text-align: center;" *ngIf="serviceEndSetting == false" translate>受助者</p>
            </ul>
        </div>

        <div class="flex-1"></div>

        <button class="start-button align-self-center" (click)="confirm()" translate>確認</button>
        <p class="text-lightgrey text-sm align-self-center mt-3" translate>（請注意，確認後不可再修改）</p>
    </div>
</ng-template>