<div class="box">
    <p class="title" matTooltip="{{ '中心活動類型' | translate }}" [matTooltipPosition]="'above'" translate>中心活動類型</p>
    <div class="inline-flex-column">
        <form class="input-row" [formGroup]="form" style="width: 36.875rem;">
            <input type="text" class="user-input xs" formControlName="code" placeholder="{{ '活動代碼' | translate }}">
            <input type="text" class="user-input lg ml-4" formControlName="name" placeholder="{{ '活動名稱' | translate }}">
            <p class="click-text-blue text-xs ml-4" (click)="create()" translate>保存</p>
        </form>
        <div class="event-type-box">
            <div class="event-type-item" *ngFor="let eventType of eventTypes">
                <ng-container *ngIf="eventType.editing == false;else editing">
                    <p class="code">{{ eventType.code }}</p>
                    <p class="name ml-4">{{ eventType.name }}</p>
                    <i class="click-text-lightblue material-icons-outlined create-icon" (click)="eventType.editing = true">create</i>
                </ng-container>
                <ng-template #editing>
                    <form class="inline-flex-row align-items-center" [formGroup]="eventType.form">
                        <input type="text" class="user-input xs" formControlName="code" placeholder="{{ '活動代碼' | translate }}">
                        <input type="text" class="user-input lg ml-4" formControlName="name" placeholder="{{ '活動名稱' | translate }}">
                        <p class="click-text-blue text-xs ml-3" (click)="update(eventType)" translate>保存</p>
                        <p class="click-text-blue text-xs ml-3" (click)="eventType.editing = false" translate>取消</p>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>