import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { ISignUpSetting } from "src/app/models/settings/sign-up-setting";
import jwtDecode from "jwt-decode";
import { AppSetting } from "src/app/backend/app-setting";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  constructor(private httpService: HttpService) {}

  GetSignUpSetting(): Observable<ISignUpSetting> {
    var decoded: any = jwtDecode(
      localStorage.getItem(AppSetting.tokenKey) as string,
    );

    return this.httpService
      .get<ISignUpSetting>(
        "settings/timebanksetting?OrgCode=" +
          decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"
          ],
      )
      .pipe(map((value) => value.result));
  }

  SetSignUpSetting(setting: ISignUpSetting): Observable<any> {
    return this.httpService.post("settings/timebanksetting", setting);
  }
}
