import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { ProductListItem } from "src/app/models/redeems/product-list-item";
import { ExportService } from "src/app/services/export/export.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProductService } from "src/app/services/product/product.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-redeem-overview-page",
  templateUrl: "./redeem-overview-page.component.html",
  styleUrls: ["./redeem-overview-page.component.scss"],
})
export class RedeemOverviewPageComponent implements OnInit {
  products: ProductListItem[] = [];
  caches: ProductListItem[] = [];

  count: number = 0;
  pages: number = 1;

  filters: any = {
    itemsPerPage: 10,
    page: 1,
    searchInput: null,
    from: null,
    to: null,
    statuses: [],
    isDeleted: false,
    centers: [],
    formats: [],
    orderById: true,
    orderByLaunchDate: null,
    orderByCloseDate: null,
  };

  constructor(
    private loadingService: LoadingService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.productService
      .getRedeemList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (result) => {
          this.products = result.list;
          this.count = result.count;
          this.pages = Math.ceil(result.count / this.filters.itemsPerPage);
          this.products.forEach((product) => {
            if (this.caches.find((p) => p.uuId == product.uuId))
              product.selected = true;
          });
        },
      });
  }

  changePage(): void {
    this.router.navigate(["/main/redeem/overview"], {
      queryParams: {
        page: this.filters.page,
        itemsPerPage: this.filters.itemsPerPage,
      },
    });
  }

  cache(redeem: ProductListItem): void {
    if (redeem.selected) this.caches.push(redeem);
    else
      this.caches.splice(
        this.caches.findIndex((p) => p.uuId == redeem.uuId),
        1,
      );
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  typeFilterAction: (formats: number[]) => void = ((formats: number[]) => {
    this.filters.formats = formats;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction: (start: Date, end: Date) => void = ((
    start: Date,
    end: Date,
  ) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction: (statuses: number[]) => void = ((statuses: number[]) => {
    this.filters.statuses = statuses;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  private getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      (this.filters.from
        ? "&LaunchDateFrom=" + this.filters.from.toJSON()
        : "") +
      (this.filters.to ? "&LaunchDateTo=" + this.filters.to.toJSON() : "") +
      this.filters.statuses
        .map((status: string) => "&statuses=" + status)
        .join("") +
      (this.filters.orderById != null
        ? "&OrderById=" + String(this.filters.orderById)
        : "") +
      (this.filters.orderByLaunchDate != null
        ? "&OrderByLaunchDate=" + String(this.filters.orderByLaunchDate)
        : "") +
      (this.filters.orderByCloseDate != null
        ? "&OrderByCloseDate=" + String(this.filters.orderByCloseDate)
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.formats
        .map((format: string) => "&productRedeemFormats=" + format)
        .join("")
    );
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.products.forEach((product) => {
        product.selected = false;
        this.caches.splice(
          this.caches.findIndex((r) => r.uuId == product.uuId),
          1,
        );
      });
    else {
      this.products.forEach((product) => (product.selected = true));
      this.caches = [...new Set([...this.caches, ...this.products])];
    }
  }

  get isSelectedAll(): boolean {
    return this.products.every((product) => product.selected);
  }

  deleteProduct(productUUId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        this.caches = [];
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteProductMessage(
        this.productService.deleteProduct(productUUId),
      ),
    );
  }

  deleteProductList(): void {
    let task = forkJoin(
      this.caches.map((product) =>
        this.productService.deleteProduct(product.uuId),
      ),
    );

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        this.caches = [];
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteProductListMessage(task, this.caches.length),
    );
  }

  exportProducts(): void {
    if (this.caches.length > 0) {
      this.exportService.exportProducts(
        this.caches.map((product) => product.getExportModel()),
        { startDate: this.filters.from, endDate: this.filters.to },
      );
    }

    this.loadingService.startLoading();
    this.productService
      .getRedeemList("?start=0&limit=100000000")
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.exportService.exportProducts(
          value.list.map((product) => product.getExportModel()),
          { startDate: this.filters.from, endDate: this.filters.to },
        );
      });
  }

  apply(product: ProductListItem): void {
    this.loadingService.startLoading();
    this.productService
      .getProduct(product.uuId)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.productService.form.formGroup.controls["title"].setValue(
          value.productName,
        );
        this.productService.form.formGroup.controls["source"].setValue(
          value.brand,
        );
        this.productService.form.formGroup.controls["format"].setValue(
          value.format == "FirstComeFirstServed" ? "0" : "1",
        );
        if (value.target == "所有會員")
          this.productService.form.formGroup.controls["targetAll"].setValue(
            true,
          );

        this.productService.form.formGroup.controls["target"].setValue(
          value.target,
        );
        this.productService.form.formGroup.controls["price"].setValue(
          value.price,
        );
        if (value.price == 0)
          this.productService.form.formGroup.controls["free"].setValue(true);
        if (value.stock == -1)
          this.productService.form.formGroup.controls["adequateStock"].setValue(
            true,
          );
        this.productService.form.formGroup.controls["stock"].setValue(
          value.stock,
        );
        if (value.maximumQuantityPerPerson == -1)
          this.productService.form.formGroup.controls["noLimit"].setValue(true);
        this.productService.form.formGroup.controls["limit"].setValue(
          value.maximumQuantityPerPerson,
        );
        this.productService.form.formGroup.controls["description"].setValue(
          value.description,
        );
        this.productService.form.formGroup.controls["questionSetting"].setValue(
          value.isAnswerQuestionRequired,
        );
        //this.productService.form.image=
        this.productService.form.imagePath = value.imagePath;
        console.log(this.productService.form.formGroup);
        this.router.navigate(["/main/redeem/create"]);
      });
  }
}
