<div class="chart-container">
    <p translate class="chart-container-header1">時分交易</p>
    <div class="chart-container-header">
        <div class="flex-1 inline-flex-row">
            <div class="inline-flex-row  startline">
                <button class="left-button" (click)="transactionType = 'Request';fetch()" [ngClass]="{'active': transactionType == 'Request'}" translate>義工服務</button>
                <button class="right-button" (click)="transactionType = 'Event';fetch()" [ngClass]="{'active': transactionType != 'Request'}" translate>中心活動</button>
            </div>
        </div>
        <!-- <p class="data-type-button" [ngClass]="{active: dataType == 'Daily'}" (click)="dataType = 'Daily';fetch();start.reset();end.reset()" translate>按小時</p> -->
        <p class="data-type-button" [ngClass]="{active: dataType == 'Daily'}" (click)="dataType = 'Daily';fetch();" translate>按日期</p>
        <p class="data-type-button" [ngClass]="{active: dataType == 'Weekly'}" (click)="dataType = 'Weekly';fetch();" translate>按週期</p>
        <p class="data-type-button" [ngClass]="{active: dataType == 'Monthly'}" (click)="dataType = 'Monthly';fetch();" translate>按月份</p>
        <p class="data-type-button" [ngClass]="{active: dataType == 'Yearly'}" (click)="dataType = 'Yearly';fetch();" translate>按年份</p>
        <div style="position:relative">
            <input class="user-input xl ml-4" [ngModel]="dateRange" (click)="picker.open()" placeholder="2015-10-10 ~ 2015-11-10" readonly>
            <ng-container *ngTemplateOutlet="datepicker"></ng-container>
            <mat-date-range-picker touchUi #picker></mat-date-range-picker>
            <mat-date-range-input style="display: none;" [rangePicker]="picker">
                <input matStartDate [formControl]="start">
                <input matEndDate [formControl]="end" (dateInput)="fetch()">
            </mat-date-range-input>
        </div>
    </div>

    <div class="chart-container-body">
        <div class="inline-flex-column flex-1">
            <p class="text-bold mb-8" *ngIf="transactionType == 'Request';" translate>義工服務時分交易量</p>
            <p class="text-bold mb-8" *ngIf="transactionType == 'Event';" translate>中心活動時分交易量</p>
            <canvas #graph></canvas>
        </div>

        <div class="service-column" style="flex-basis: 15rem;flex-grow: 0;flex-shrink: 0;">
            <app-simple-table-box  style="flex: 7;padding-left: 1rem;" *ngIf="transactionChartData" [displayList]="transactionChartData.service">
                <div class="head service-item"><p class="text-bold mb-8" translate>時分交易排名</p></div>
                <ng-template #row let-service='service' let-index="index">
                    <div class="service-rank" >
                        <p class="service-ranking-number" [ngClass]="{'top': index <= 3}">{{ index+ 1 }}</p>
                        <p style="max-width: 10rem;">{{ service.index }}</p>
                        <div class="flex-1"></div>
                        <p>{{ service.value | number:'1.0-2' }}</p>
                    </div>
                </ng-template>
            </app-simple-table-box>
        </div>
    </div>
    <div class="chart-container-footer"><a class="exportBtn data-type-button" translate (click)="export()">匯出報告</a></div>
</div>
<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px" height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></ng-template>
