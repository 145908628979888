<div class="popup-bg" *ngIf="display">
    <div class="image-popup zoomIn" (click)="$event.stopPropagation()">
        <div class="image-header">
            <p class="flex-1" translate>上傳宣傳圖片</p>
            <i class="material-icons-round close-icon" (click)="display = false">close</i>
        </div>

        <div class="image-body">
            <div class="upload-image" (click)="upload.click();">
                <i class="material-icons-round">add</i>
                <p>Upload</p>
                <div [ngClass]="{'show': file != null}" class="preview" (click)="delete();$event.stopPropagation()">
                    <img #image>
                </div>
            </div>

            <p class="upload-text mt-3" translate>點擊或將文件拖拽到這裡上傳</p>
            <div class="flex-column align-items-start mt-3">
                <p class="upload-grey-text" translate>-限一張圖片</p>
                <p class="upload-grey-text" translate>-支持擴展名：.JPG .PNG .GIF</p>
                <p class="upload-grey-text" translate>-將大小限制在 2MB 以下。</p>
                <p class="upload-grey-text" translate>-建議使用 16:9 的長寬比</p>
            </div>

            <div *ngIf="uploadFile" class="flex-row align-items-center justify-content-start align-self-stretch">
                <i class="material-icons-round" style="transform: rotate(-45deg);font-size: 1.25rem;">attachment</i>
                <div class="flex-column flex-1">
                    <div class="flex-row align-items-center justify-content-between">
                        <p>{{ file.name }}</p>
                        <div class="flex-row">
                            <i class="material-icons-round text-grey text-sm cursor-pointer" style="font-size: 1.25rem;" (click)="uploadFile = null">close</i>
                        </div>
                    </div>
                    <div class="blue-bar mt-1"></div>
                </div>
            </div>
        </div>


        <div class="image-footer">
            <button class="button outline xs" (click)="display = false" translate>取 消</button>
            <button class="button fill blue xs ml-4" (click)="close()" translate>確 定</button>
        </div>
    </div>
    <input #upload style="display: none;" type="file" (change)="onImageUpload($event.target.files)">
</div>