<form class="inline-flex-column" style="margin-bottom: 1.25rem;" *ngIf="question" [formGroup]="question">
    <div class="input-row">
        <p class="title required">{{ '問題' | translate: {value: index + 1} }}</p>
        <input class="user-input xl" type="text" formControlName="question" placeholder="{{ '請輸入問題描述？' | translate }}">

        <div class="zoomIn user-input-error" *ngIf="questionInput.touched && questionInput.errors">
            <p class="error-text" *ngIf="questionInput.errors.required" translate>請輸入問題描述？</p>
        </div>

        <div class="text-red flex-row align-items-center ml-8" *ngIf="index > 0" (click)="delete(index)">
            <i class="material-icons-round">delete</i>
            <p translate>移除</p>
        </div>
    </div>

    <div class="input-row">
        <p class="title required" matTooltip="{{ '問題設置' | translate }}" [matTooltipPosition]="'above'" translate>問題設置</p>
        <label class="text-grey">
            <input class="mr-1" type="radio" formControlName="setting" value="Multiple">
            <p translate>多選</p>
        </label>
        <label class="text-grey" style="margin-left: 3.125rem;">
            <input class="mr-1" type="radio" formControlName="setting" value="Single">
            <p translate>單選</p>
        </label>

        <div class="zoomIn user-input-error" *ngIf="setting.touched && setting.errors">
            <p class="error-text" *ngIf="setting.errors.required" translate>請輸入問題描述？</p>
        </div>
    </div>

    <div class="input-row" *ngFor="let option of options.controls;let i = index">
        <p class="title" [ngClass]="{required: i < 2}">{{ '選項' | translate: {value: i+1} }}</p>
        <input class="user-input xl" type="text" [formControl]="option" placeholder="{{ '請輸入選項文字描述' | translate }}" (keyup)="duplicateCheck(option, i)">

        <i class="remove-icon material-icons" (click)="removeOption(i)" *ngIf="i > 1">remove_circle</i>

        <div class="zoomIn user-input-error" *ngIf="option.touched && option.errors">
            <p class="error-text" *ngIf="option.errors.required" translate>請輸入選項文字描述</p>
            <p class="error-text" *ngIf="option.errors.duplicated" translate>選項描述不可重複</p>
        </div>
    </div>

    <div class="input-row" (click)="addOption()" *ngIf="options.length < 5">
        <i class="add-icon material-icons mr-4">add_circle</i>
        <p class="text-lightgrey" translate>增加選項（最多可設置5個選項）</p>
    </div>
</form>