import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { CreateTransportationModel } from 'src/app/models/transportations/create-transportation-model';
import { Transportation, ITransportation } from 'src/app/models/transportations/transportation';
import { UpdateTransportationModel } from 'src/app/models/transportations/update-transportation-model';

@Injectable({
  providedIn: 'root'
})
export class TransportationService {

  constructor(
    private httpService: HttpService
  ) { }

  public getTransportationList(): Observable<Transportation[]> {
    return this.httpService.get<ITransportation[]>("transportations")
      .pipe(map(value => value.result.map(transportation => new Transportation(transportation)).sort(TransportationSorting)));
  }

  public createTransportation(model: CreateTransportationModel): Observable<any> {
    return this.httpService.post<any, CreateTransportationModel>("transportations", model);
  }

  public updateTransportation(model: UpdateTransportationModel): Observable<any> {
    return this.httpService.put<any, UpdateTransportationModel>("transportations", model);
  }

  public editRequestTransportation(requestUUId: string, transportationUUId: string): Observable<any> {
    return this.httpService.post<any, { requestUUId: string, transportationUUId: string }>("requests/Transporation", { requestUUId: requestUUId, transportationUUId: transportationUUId });
  }
}

function TransportationSorting(a: Transportation, b: Transportation): number {
  return a.name.localeCompare(b.name, 'en', { numeric: true })
}