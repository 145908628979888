import { Component, OnInit } from "@angular/core";
import { LoadingService } from "src/app/services/loading/loading.service";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  public loading: boolean = false;

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService
      .getAppLoadingSignal()
      .subscribe((signal) => (this.loading = signal));
  }
}
