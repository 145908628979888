<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedCenters > 0}" (click)="toggle()">
    <img class="filter-icon" *ngIf="numberOfSelectedCenters == 0" src="/v2/assets/home.svg">
    <p class="filter-number" *ngIf="numberOfSelectedCenters > 0">{{ numberOfSelectedCenters }}</p>
    <p translate>中心代碼</p>
    <div class="filter-body center" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-6">
                <p class="text-bold flex-1" style="color: #5A5A5A;" translate>中心代碼</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">
                <label class="checkbox mb-4" *ngFor="let center of centers">
                    <input class="mr-8" type="checkbox" [(ngModel)]="center.selected">
                    <p>{{ center.name }}</p>
                </label>
            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>