import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { CellGroupData } from 'src/app/models/analytics/cell-group-data';
import { DailyRequestCount } from 'src/app/models/analytics/daily-request-count';
import { GetTransactionChartDataModel } from 'src/app/models/analytics/get-transaction-chart-data-model';
import { ServiceRatio } from 'src/app/models/analytics/service-ratio';
import { TransactionChartData } from 'src/app/models/analytics/transaction-chart-data';
import { Profile, IProfile } from 'src/app/models/profiles/profile';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private httpService: HttpService
  ) { }

  public getTotalTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/TotalTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getAverageTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/AverageTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getRequestTotalTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/Requests/TotalTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getRequestAverageTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/Requests/AverageTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getEventTotalTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/Events/TotalTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getEventAverageTimeBalance(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/Events/AverageTimeBalance" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getDailyRequestCount(centerUUId?: string): Observable<DailyRequestCount[]> {
    return this.httpService.get<DailyRequestCount[]>("Reports/CountRequestDaily" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getRequestSuccessRate(centerUUId?: string): Observable<number> {
    return this.httpService.get<number>("Reports/RequestMatchedRate" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public GetTransactionChartData(model: GetTransactionChartDataModel): Observable<TransactionChartData> {
    return this.httpService.post<TransactionChartData, GetTransactionChartDataModel>("Reports/GetTransactionsPeriod", model)
      .pipe(map(value => value.result));
  }

  public getInactiveUsers(centerUUId?: string,type?: string,startTime?:string,endTime?:string): Observable<Profile[]> {
    var getvalue="?a=1";
    getvalue+=(startTime ? "&startTime=" + startTime : "");
    getvalue+=(endTime ? "&endTime=" + endTime : "");
    getvalue+=(type ? "&type=" + type : "");
    getvalue+=(centerUUId ? "&centerUUId=" + centerUUId : "");
    return this.httpService.get<IProfile[]>("Reports/InactiveUser" + (getvalue))
      .pipe(map(value => value.result.map(profile => new Profile(profile))));
  }
  public getCellGroupData(startTime?:string,endTime?:string,type?: string,StateType?:string,ServiceUUId?:string,centerUUId?:string): Observable<CellGroupData> {
    var getvalue="?a=1";
    getvalue+=(startTime ? "&startTime=" + startTime : "");
    getvalue+=(endTime ? "&endTime=" + endTime : "");
    getvalue+=(type ? "&type=" + type : "");
    getvalue+=(StateType ? "&StateType=" + StateType : "");
    getvalue+=(ServiceUUId ? "&ServiceUUId=" + ServiceUUId : "");
    getvalue+=(centerUUId ? "&centerUUId=" + centerUUId : "");

    return this.httpService.get<CellGroupData>("Reports/CellGroupData" + getvalue)
      .pipe(map(value => value.result));
  }
   
  public getServiceRatio(centerUUId?: string,type?:string,startTime?:string,endTime?:string,cellGroupsUUId?:string): Observable<ServiceRatio> {
    var getvalue="?a=1";
    getvalue+=(centerUUId ? "&centerUUId=" + centerUUId : "");
    getvalue+=(startTime ? "&startTime=" + startTime : "");
    getvalue+=(endTime ? "&endTime=" + endTime : "");
    getvalue+=(type ? "&type=" + type : "");
    getvalue+=(cellGroupsUUId ? "&cellGroupsUUId=" + cellGroupsUUId : "");

    return this.httpService.get<ServiceRatio>("Reports/ServiceRatio" + getvalue)
      .pipe(map(value => value.result));
  }

  public getRanking(centerUUId?: string): Observable<{ index: string, value: number }[]> {
    return this.httpService.get<{ index: string, value: number }[]>("Reports/earnRanking" + (centerUUId ? "?centerUUId=" + centerUUId : ""))
      .pipe(map(value => value.result));
  }

  public getTotalDonation(): Observable<{ sum: number, startTime: Date }> {
    return this.httpService.get<{  sum: number, startTime: Date  }>("Reports/totalDonate")
      .pipe(map(value => value.result));
  }

}
