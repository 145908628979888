<div class="fadeIn">
    <div class="flex-row text-lightgrey" style="position: relative;">
        <p translate>請求/訂單</p>
        <p class="ml-4">/</p>
        <p class="ml-4" translate>中心活動</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>活動詳情</p>
        <button class="icon-button yellow" style="position: absolute; right: 80px;" (click)="exportEventApplyOnly()">
            <i class="material-icons-round mb-1">archive</i>
            <p translate>匯出點名紙</p>
        </button>
        <button class="icon-button yellow" style="position: absolute; right: 0;" (click)="exportEvent()">
            <i class="material-icons-round mb-1">archive</i>
            <p translate>匯出名單</p>
        </button>
    </div>

    <p class="page-title mt-8" translate>中心活動詳情</p>

    <div class="container mt-5" *ngIf="event">

        <div class="container-header">
            <p>{{ '記錄編號：' | translate: {value: event.id} }}</p>
            <div class="flex-1"></div>
            <p>{{ '創建時間：' | translate: {value: event.createdDate | date: 'yyyy-MM-dd HH:mm'} }}</p>
        </div>

        <ng-container *ngIf="event.isCancelled == true">
            <div class="container-body">
                <ng-container *ngTemplateOutlet="event_cancel"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="event.isCancelled == false">
            <form class="container-body" [formGroup]="event.form.formGroup">
                <ng-container *ngTemplateOutlet="event_status"></ng-container>
                <div class="message-box yellow" style="margin-top: 2rem;" *ngIf="NewJoinAlert">
                    <p class="ml-4" style="    flex: 1 0;">{{ '有會員報名了該活動，請在“會員管理”中查看' }}</p>
                    <span class="close_btn" (click)="closeAlert()"></span>
                </div>
                <div class="input-row">
                    <p class="title" matTooltip="{{ '活動狀態' | translate }}" [matTooltipPosition]="'above'" translate>活動狀態</p>
                    <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && !event.isOngoing">
                        <p translate>等待參加</p>
                    </div>
                    <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && event.isOngoing">
                        <p translate>進行中</p>
                    </div>
                    <div class="status grey mr-2" *ngIf="event.eventStatus === 'Complete'">
                        <p translate>已完成</p>
                    </div>
                    <div class="status grey mr-2" *ngIf="event.eventStatus === 'Cancel'">
                        <p translate>已終止</p>
                    </div>
                    <div class="status red mr-2" *ngIf="event.IsNoEnoughtParticipants">
                        <p translate>人數不足</p>
                    </div>
                    <div class="status blue" *ngIf="event.lastEditedBy">
                        <p translate>已修改</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '活動名目' | translate }}" [matTooltipPosition]="'above'" translate>活動名目</p>
                    <p *ngIf="event.editing == false">{{ event.eventName }}</p>
                    <ng-container *ngIf="event.editing == true">
                        <input class="user-input md" type="text" formControlName="eventName">

                        <div class="user-input-error zoomIn" *ngIf="eventName.touched && eventName.errors">
                            <p *ngIf="eventName.errors.required" translate>請填寫活動名目</p>
                        </div>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '活動類型' | translate }}" [matTooltipPosition]="'above'" translate>活動類型</p>
                    <p *ngIf="event.editing == false">{{ event.eventType.name }}</p>
                    <ng-container *ngIf="event.editing == true">
                        <div class="user-select">
                            <select class="user-select xxxl" formControlName="eventType">
                                <option [ngValue]="null" selected disabled translate>請選擇活動類型</option>
                                <option *ngFor="let type of eventTypes" [ngValue]="type.uuId">{{ type.name }}</option>
                            </select>
                        </div>

                        <div class="user-input-error zoomIn" *ngIf="eventType.touched && eventType.errors">
                            <p *ngIf="eventType.errors.required" translate>請選擇活動類型</p>
                        </div>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '交易類型' | translate }}" [matTooltipPosition]="'above'" translate>交易類型</p>
                    <input type="radio" checked>
                    <ng-container *ngIf="event.eventPay == 'Free'">
                        <p translate>免費活動</p>
                    </ng-container>
                    <ng-container *ngIf="event.eventPay == 'UserSpend'">
                        <p translate>用户扣分</p>
                        <ng-container *ngIf="event.editing == false">
                            <p class="ml-7">{{ '默認每個帳戶將會支出時分' | translate: {value: event.earning} }}</p>
                        </ng-container>
                        <ng-container *ngIf="event.editing == true">
                            <p translate>默認每個帳戶將會支出</p>
                            <input class=" user-input ml-4 mr-4" style="width: 4.3125rem;" type="number" formControlName="earning">
                            <p translate>時分</p>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="event.eventPay == 'UserEarn'">
                        <p translate>會員加分</p>
                        <ng-container *ngIf="event.editing == false">
                            <p class="ml-7">{{ '默認每個帳戶將會賺取時分' | translate: {value: event.earning} }}</p>
                        </ng-container>
                        <ng-container *ngIf="event.editing == true">
                            <p translate>默認每個帳戶將會賺取</p>
                            <input class=" user-input ml-4 mr-4" style="width: 4.3125rem;" type="number" formControlName="earning">
                            <p translate>時分</p>
                        </ng-container>
                    </ng-container>

                    <div class="user-input-error zoomIn" *ngIf="earning.touched && earning.errors">
                        <p *ngIf="earning.errors.required" translate>請填寫交易時分</p>
                        <p *ngIf="earning.errors.min" translate>交易時分必須大於 0</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '參與方式' | translate }}" [matTooltipPosition]="'above'" translate>參與方式</p>
                    <label>
                        <input type="radio" [checked]="event.eventJoinType == 'FIFJ'" [disabled]="event.eventJoinType != 'FIFJ'">
                        <p translate>先到先得</p>
                    </label>

                    <label>
                        <input class="ml-7" type="radio" [checked]="event.eventJoinType == 'Staff'" [disabled]="event.eventJoinType != 'Staff'">
                        <p translate>職員處理</p>
                    </label>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '內容類型' | translate }}" [matTooltipPosition]="'above'" translate>內容類型</p>
                    <ng-container *ngIf="event.editing == false">
                        <p *ngIf="event.url == null" translate>純文本</p>
                        <p *ngIf="event.url != null" translate>超鏈接</p>
                    </ng-container>
                    <ng-container *ngIf="event.editing == true">
                        <label>
                            <input type="radio" formControlName="urlType" value="text">
                            <p translate>純文本</p>
                        </label>
                        <label style="margin-left: 1.875rem;">
                            <input type="radio" formControlName="urlType" value="url">
                            <p translate>超鏈接</p>
                        </label>
                    </ng-container>
                </div>

                <div class="input-row" *ngIf="event.url != null || urlType.value == 'url'">
                    <p class="title required" translate>連結網址</p>
                    <ng-container *ngIf="event.editing == false">
                        <p>{{ event.url }}</p>
                        <button type="button" class="button outline blue sm ml-6" type="button" (click)="openURL()" translate>瀏覽網址</button>
                    </ng-container>
                    <ng-container *ngIf="event.editing == true">
                        <input class="user-input xl" type="text" formControlName="url" placeholder="{{ '請輸入超鏈接' | translate }}">

                        <button type="button" class="button outline blue sm ml-6" type="button" (click)="openFormURL()" translate>瀏覽網址</button>

                        <div class="zoomIn user-input-error" *ngIf="url.touched && url.errors">
                            <p class="error-text text-sm" *ngIf="url.errors.required" translate>請輸入超鏈接</p>
                        </div>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title" translate>會員管理</p>
                    <p translate>已參加</p>
                    <p class="ml-2 text-lightblue">{{ event.numberOfParticipants }}</p>
                    <p class="mr-2">/{{ event.participantVacancy }} </p>
                    <p translate>位，接受邀請</p>
                    <p class="ml-2 mr-2 text-lightblue">{{ event.numberOfApplication }}</p>
                    <p translate>位，共邀請</p>
                    <p class="ml-2 mr-2 text-lightblue">{{ event.numberOfInvited }}</p>
                    <p translate>位</p>
                    <button class="button outline blue sm ml-4" (click)="user_management.open()" translate>查看詳情</button>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start lh-8" matTooltip="{{ '活動日期' | translate }}" [matTooltipPosition]="'above'" translate>活動日期</p>
                    <ng-container *ngTemplateOutlet="schedule"></ng-container>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '截止報名' | translate }}" [matTooltipPosition]="'above'" translate>截止報名</p>
                    <p *ngIf="event.editing == false">{{ event.applyDeadline | date: 'longDate' : '' : lang }} {{ event.applyDeadline | date: 'HH:mm' }}</p>
                    <ng-container *ngIf="event.editing == true">
                        <input matInput class="user-input md" [matDatepicker]="picker" formControlName="applyDeadline" (click)="picker.open()" placeholder="{{ '截止報名' | translate }}" readonly>
                        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>

                        <app-time-picker class="ml-9" [form]="applyDeadline"></app-time-picker>

                        <div class="user-input-error zoomIn" *ngIf="applyDeadline.touched && applyDeadline.errors">
                            <p *ngIf="applyDeadline.errors.required" translate>請填寫截止報名日期</p>
                            <p *ngIf="applyDeadline.errors.time" translate>請填寫截止報名時間</p>
                        </div>
                    </ng-container>

                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 1.5rem;" matTooltip="{{ '會面地址' | translate }}" [matTooltipPosition]="'above'" translate>會面地址</p>
                    <p *ngIf="event.editing == false">{{ event.venue.toString() }}</p>
                    <ng-container *ngIf="event.editing == true">
                        <div class="inline-flex-column">
                            <div class="inline-flex-row align-items-center" *ngFor="let address of profile.addresses;let index = index">
                                <div class="inline-flex-column">
                                    <div class="inline-flex-row" *ngIf="address.editing == false">
                                        <label>
                                            <input class="mr-4" type="radio" formControlName="venue" [value]="address.room.uuId">
                                            <p style="line-height: 1.5rem;">{{ address.toString() }}</p>
                                        </label>
                                        <i class="material-icons-outlined text-lightblue ml-4" style="font-size: 1rem;cursor: pointer;" (click)="address.editing = true">create</i>
                                    </div>
                                    <ng-container *ngIf="address.editing == true">
                                        <app-address-form [form]="address.form" [index]="index+2" [dropAction]="dropAddressAction"></app-address-form>
                                        <button type="button" class="add-address-button" (click)="editAddress(address)" translate>保存地址</button>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="inline-flex-row align-items-center">
                                <label>
                                    <input class="mr-4" type="radio" formControlName="venue" [value]="NAaddress.uuId">
                                    <p style="line-height: 1.5rem;">{{ NAaddress.name.toString() }}</p>
                                </label>
                            </div>
                            <ng-container *ngFor="let form of venueForms">
                                <app-address-form class="mt-4" [form]="form" [index]="2" [dropAction]="dropVenue"></app-address-form>

                                <button type="button" class="add-address-button" (click)="saveVenue()" translate>保存地址</button>
                            </ng-container>

                            <button type="button" class="add-address-button" *ngIf="venueForms.length == 0" (click)="addVenue()" translate>增添地址</button>
                        </div>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '參與者數量' | translate }}" [matTooltipPosition]="'above'" translate>參與者數量</p>
                    <p *ngIf="event.editing == false">{{ event.participantVacancy }}</p>
                    <button  *ngIf="event.editing == false" class="button outline blue sm ml-4" (click)="event_attendance.open()" translate>出席人數</button>
                    <ng-container *ngIf="event.editing == true">
                        <input class="user-input md" type="number" formControlName="participantVacancy">
                        <div class="ml-8"  >
                            <p  translate>邀請人數必須大於活動人數</p>
                        </div>
                        <div class="user-input-error zoomIn" *ngIf="vacancy.touched && vacancy.errors">
                            <p *ngIf="vacancy.errors.required" translate>請填寫參與者數量</p>
                        </div>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title align-self-start lh-8" matTooltip="{{ '活動詳情' | translate }}" [matTooltipPosition]="'above'" translate>活動詳情</p>
                    <ng-container *ngIf="event.editing == false">
                        <p *ngIf="!event.detail">-</p>
                        <p class="lh-8" style="width: 25rem;white-space: pre-line;">{{ event.detail }}</p>
                    </ng-container>
                    <ng-container *ngIf="event.editing == true">
                        <textarea style="width: 22rem;height: 8.625rem;" formControlName="detail"></textarea>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title align-self-start lh-8" translate>備註</p>
                    <ng-container *ngIf="event.editing == false">
                        <p *ngIf="!event.remark" translate>暫無</p>
                        <p class="lh-8" style="width: 25rem;white-space: pre-line;">{{ event.remark }}</p>
                    </ng-container>
                    <ng-container *ngIf="event.editing == true">
                        <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
                    </ng-container>
                </div>

                <ng-container *ngIf="event.editing == false">
                    <div class="input-row" *ngFor="let column of columns">
                        <p class="title">{{ column.columnName }}</p>
                        <p *ngIf="event.extraEventJson">{{ event.extraEventJson[column.columnName] }}</p>
                        <p *ngIf="event.extraEventJson == null" translate>暫無</p>
                    </div>
                </ng-container>

                <ng-container *ngIf="event.editing == true">
                    <div class="input-row" *ngFor="let column of event.form.columns">
                        <p class="title">{{ column.columnName }}</p>
                        <input type="text" class="user-input" [formControl]="column.input">
                    </div>
                </ng-container>
            </form>

            <div class="container-footer">
                <ng-container *ngIf="event.editing == false">
                    <div class="inline-flex-row justify-content-center">
                        <button class="button fill red" style="margin-right: 5.875rem;" *ngIf="event.eventStatus != 'Complete'" (click)="terminate()" translate>終 止</button>
                        <button class="button outline blue" style="margin-right: 5.875rem;" (click)="event.editing = true" translate>編 輯</button>
                        <button class="button fill blue" *ngIf="event.full == false && event.isOngoing == false && event.eventStatus == 'Open'" [routerLink]="['/main/activity/overview/event', event.uuId, 'select-participants']" translate>重新邀請</button>
                        <button class="button fill blue" style="margin-left: 5.875rem;" *ngIf="event.eventStatus != 'Complete'" (click)="endEvent()" translate>確認結束</button>
                    </div>
                </ng-container>

                <ng-container *ngIf="event.editing == true">
                    <div class="inline-flex-row justify-content-center">
                        <button class="button outline" style="margin-right: 5.875rem;" (click)="event.editing = false" translate>取 消</button>
                        <button class="button fill blue" (click)="updateEvent()" translate>保 存</button>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <app-event-detail-user-management-popup #user_management  [event]="event" [users]="event.participants" [reloadAction]="reloadAction" [format]="event.eventJoinType" [vacancy]="quota" [type]="event.eventPay"></app-event-detail-user-management-popup>
        <app-event-attendance-popup #event_attendance [eventdays]="event.eventDays" [reloadAction]="reloadAction"  ></app-event-attendance-popup>
    </div>
</div>


<ng-template #event_status>
    <div class="flex-row">
        <div class="stage-container" style="padding-left: 0;">
            <p class="stage blue" *ngIf="event.eventStatus == 'Open' && event.isOngoing == false && event.full == false">1</p>
            <i class="material-icons-round stage blue" *ngIf="event.full || event.isOngoing || event.eventStatus == 'Complete'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>等候參加</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>該活動正在等候會員參加</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{blue: event.full}" *ngIf="event.eventStatus == 'Open' && event.isOngoing == false">2</p>
            <i class="material-icons-round stage blue" *ngIf="event.isOngoing || event.eventStatus == 'Complete'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>未開始</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>活動正在等待開始</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{blue: event.isOngoing}" *ngIf="event.eventStatus == 'Open'">3</p>
            <i class="material-icons-round stage blue" *ngIf="event.eventStatus == 'Complete'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>進行中</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>活動正在進行中</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': event.eventStatus === 'Complete'}">4</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已完結</p>
                </div>
                <p class="mt-2 text-lightgrey" translate>活動已經結束</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #event_cancel>
    <div class="inline-flex-column align-items-center">
        <i class="material-icons-round cross-icon mb-5">add_circle</i>
        <p style="font-size: 1.5rem;line-height: 2rem;" translate>該記錄已終止</p>
    </div>

    <div class="input-row">
        <p class="title" translate>記錄狀態</p>
        <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && !event.isOngoing">
            <p translate>等待參加</p>
        </div>
        <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && event.isOngoing">
            <p translate>進行中</p>
        </div>
        <div class="status grey mr-2" *ngIf="event.eventStatus === 'Complete'">
            <p translate>已完成</p>
        </div>
        <div class="status grey mr-2" *ngIf="event.eventStatus === 'Cancel'">
            <p translate>已終止</p>
        </div>
        <div class="status red mr-2" *ngIf="event.IsNoEnoughtParticipants">
            <p translate>人數不足</p>
        </div>
        <div class="status blue" *ngIf="event.lastEditedBy">
            <p translate>已修改</p>
        </div>
    </div>

    <div class="input-row"> 
        <ng-container>
            <p class="title align-self-start lh-8" translate>終止原因</p>
            <div  class="inline-flex-column">
                <textarea  id="newCancelDetail" name="cancelDetail"  style="width: 22rem; height: 8.625rem;" [(ngModel)]="event.cancelDetail"></textarea>
                <br>
            </div>
        </ng-container>
    </div>
    <button class="button fill blue center" translate (click)="saveCancelDetail()">保 存</button>
</ng-template>

<ng-template #schedule>
    <ng-container *ngIf="event.editing == false">
        <div class="inline-flex-column">
            <div class="inline-flex-row align-items-center">
                <p translate>由</p>
                <p class="ml-2 mr-2">{{ event.getEventStartDate() | date: 'longDate': '': lang}}</p>
                <p translate>至</p>
                <p class="ml-2 mr-2">{{ event.getEventEndDate() | date: 'longDate': '': lang}}</p>
                <p translate>，活動舉辦</p>
                <p class="ml-2 mr-2 text-lightblue">{{ event.eventDays.length }}</p>
                <p translate>次</p>
                <div class="inline-flex-row align-items-center schedule-toggle" (click)="toggle = !toggle" [ngClass]="{on: toggle}">
                    <p class="click-text-lightblue ml-8">收起</p>
                </div>
            </div>

            <div class="agent-table" style="overflow: hidden;" [@dropdown]="toggle ? 'on' : 'off'">
                <div class="agent-table-row header">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p translate>活動舉辦</p>
                        <p class="text-lightblue ml-2 mr-2">{{ event.eventDays.length }}</p>
                        <p translate>次</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;"></div>
                    <div class="agent-table-cell"></div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;"></div>
                </div>

                <div class="agent-table-row" *ngFor="let day of event.eventDays; let index = index">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p>{{ day.date | date: 'longDate' : '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;">
                        <p>{{ day.date | date: 'EEEE': '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p>{{ day.startTime | date: 'HH:mm' }}</p>
                        <p class="ml-5 mr-5" translate>至</p>
                        <p>{{ day.endTime | date: 'HH:mm' }}</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="event.editing == true">
        <div class="inline-flex-column">
            <div class="inline-flex-row align-items-center">
                <p translate>由</p>
                <p class="ml-2 mr-2">{{ event.form.getEventStartDate() | date: 'longDate': '': lang}}</p>
                <p translate>至</p>
                <p class="ml-2 mr-2">{{ event.form.getEventEndDate() | date: 'longDate': '': lang}}</p>
                <p translate>，活動舉辦</p>
                <p class="ml-2 mr-2 text-lightblue">{{ event.form.eventDays.length }}</p>
                <p translate>次</p>
                <div class="inline-flex-row align-items-center schedule-toggle" (click)="toggle = !toggle" [ngClass]="{on: toggle}">
                    <p class="click-text-lightblue ml-8">收起</p>
                </div>
            </div>

            <div class="agent-table" [ngStyle]="toggle ? {'overflow': 'visible'} : {'overflow': 'hidden'}" [@dropdown]="toggle ? 'on' : 'off'">
                <div class="agent-table-row header">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p translate>活動舉辦</p>
                        <p class="text-lightblue ml-2 mr-2">{{ event.form.eventDays.length }}</p>
                        <p translate>次</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;"></div>
                    <div class="agent-table-cell"></div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;"></div>
                </div>

                <div class="agent-table-row" *ngFor="let day of event.form.eventDays; let index = index">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <p>{{ day.date.value | date: 'longDate' : '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;">
                        <p>{{ day.date.value | date: 'EEEE': '' : lang }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p *ngIf="day.editing == false">{{ day.start.value | date: 'HH:mm' }}</p>
                        <app-time-picker *ngIf="day.editing == true" [form]="day.start"></app-time-picker>
                        <p class="ml-5 mr-5" translate>至</p>
                        <p *ngIf="day.editing == false">{{ day.end.value | date: 'HH:mm' }}</p>
                        <app-time-picker *ngIf="day.editing == true" [form]="day.end"></app-time-picker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;">
                        <p class="click-text-lightblue text-xs" *ngIf="day.editing == false" (click)="day.editing = true" translate>修改</p>
                        <p class="click-text-lightblue text-xs" *ngIf="day.editing == true" (click)="day.editing = false" translate>確認</p>
                        <p class="click-text-lightblue text-xs" style="margin-left: 1.75rem;" (click)="dropEventDay(index)" translate>移除</p>
                    </div>
                </div>

                <div class="agent-table-row" *ngIf="newEventDayForm">
                    <div class="agent-table-cell" style="flex: 0 0 10rem;">
                        <input matInput class="user-input" style="width: 5.8125rem;" [matDatepicker]="picker3" [formControl]="newEventDayForm.date" (click)="picker3.open()" placeholder="{{ '活動日' | translate }}" readonly>
                        <mat-datepicker touchUi #picker3 disabled="false"></mat-datepicker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 3.5rem;"></div>
                    <div class="agent-table-cell">
                        <app-time-picker [form]="newEventDayForm.start"></app-time-picker>
                        <p class="ml-5 mr-5" translate>至</p>
                        <app-time-picker [form]="newEventDayForm.end"></app-time-picker>
                    </div>
                    <div class="agent-table-cell" style="flex: 0 0 7.5rem;">
                        <p class="click-text-lightblue text-xs" (click)="saveNewEventDay()" translate>保存</p>
                        <p class="click-text-lightblue text-xs" style="margin-left: 1.75rem;" (click)="newEventDayForm = null" translate>移除</p>
                    </div>
                </div>

                <div class="agent-table-row align-items-center" style="border-bottom: none;" *ngIf="newEventDayForm == null">
                    <p class="click-text-lightblue" (click)="addEventDay()" translate>+ 新增日期</p>
                </div>
            </div>
        </div>
    </ng-container>

</ng-template>