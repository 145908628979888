import { Component, Input, OnInit } from "@angular/core";
import { EventType } from "src/app/models/event-types/event-type";
import { PortalAnimation } from "src/app/portal-animation";
import { EventTypeService } from "src/app/services/event-type/event-type.service";

@Component({
  selector: "app-event-type-filter",
  templateUrl: "./event-type-filter.component.html",
  styleUrls: ["./event-type-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class EventTypeFilterComponent implements OnInit {
  @Input() action: (centers: EventType[]) => void = () => {};
  eventTypes: EventType[] = [];
  display: boolean = false;

  constructor(private eventTypeService: EventTypeService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.eventTypeService.getEventTypeList().subscribe({
      next: (value) => {
        this.eventTypes = value;
      },
    });
  }

  reset(): void {
    this.eventTypes.forEach((eventType) => (eventType.selected = false));
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.eventTypes.filter((eventType) => eventType.selected));
    }
    this.display = !this.display;
  }

  get numberOfSelectedEventTypes(): number {
    return this.eventTypes.filter((eventType) => eventType.selected).length;
  }
}
