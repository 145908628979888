import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TemplateColumn } from "src/app/backend/app-column";
import { Profile } from "src/app/models/profiles/profile";
import { CreateRequestModel } from "src/app/models/requests/create-request-model";
import { VolunteerChoice } from "src/app/models/requests/volunteer-choice";

export class CreateRequestRecordForm {
    requester: string | null;
    formGroup: FormGroup;
    volunteers: VolunteerChoice[];
    columns: TemplateColumn[];
    giveAll: FormControl;
    giveInput: FormControl;

    constructor() {
        this.requester = null;
        this.giveAll = new FormControl(false);
        this.giveInput = new FormControl(null);
        this.giveInput.disable();
        this.giveAll.valueChanges.subscribe(value => {
            if (value == false) {
                this.giveInput.disable();
                this.volunteers.forEach(user => user.recordForm.enable());
            }
            else {
                this.giveInput.enable();
                this.volunteers.forEach(user => user.recordForm.disable());
            }
        });

        this.giveInput.valueChanges.subscribe(value => {
            if (this.giveAll.value == true) {
                this.volunteers.forEach(user => user.recordForm.setValue(value));
            }
        })
        console.log("new CreateRequestRecordForm");
        this.formGroup = new FormGroup({
            serviceType: new FormControl(null, Validators.required),
            service: new FormControl(null, Validators.required),
            date: new FormControl(null, [Validators.required]),
            start: new FormControl(null, [Validators.required]),
            end: new FormControl(null, [Validators.required]),
            noEndTime: new FormControl(false),
            venue: new FormControl(null, [Validators.required]),
            destination: new FormControl(null),
            numberOfVolunteer: new FormControl(null),
            remark: new FormControl(null),
            transportation: new FormControl(null)
        })
        this.volunteers = [];
        this.columns = [];

        this.formGroup.controls['date'].valueChanges.subscribe((date: Date) => {
            if (date == null)
                return;

            let start: Date = this.formGroup.controls['start'].value
            if (start != null) {
                start.setDate(date.getDate());
                start.setMonth(date.getMonth());
                start.setFullYear(date.getFullYear());
                this.formGroup.controls['start'].setValue(new Date(start));
            }
            else {
                this.formGroup.controls['start'].setValue(new Date(date));
            }

            let end: Date = this.formGroup.controls['end'].value
            if (end != null) {
                end.setDate(date.getDate());
                end.setMonth(date.getMonth());
                end.setFullYear(date.getFullYear());
                this.formGroup.controls['end'].setValue(new Date(end));
            }
            else {
                this.formGroup.controls['end'].setValue(new Date(date));
            }
        });

  

        this.formGroup.controls['serviceType'].valueChanges.subscribe(value => {
            this.formGroup.controls['service'].setValue(null);
        })

        this.formGroup.controls['start'].valueChanges.subscribe(() => {
            this.validateDate();
        });

        this.formGroup.controls['end'].valueChanges.subscribe(() => {
            setTimeout(() => this.validateDate(), 100);
        });
    }

    validateDate(): void {
        if (this.formGroup.controls['start'].value == null || this.formGroup.controls['end'].value == null)
            return;
        if (this.formGroup.controls['start'].invalid || this.formGroup.controls['end'].invalid)
            return;
        if (this.formGroup.controls['start'].value as Date >= (this.formGroup.controls['end'].value as Date))
            this.formGroup.controls['end'].setErrors({ invalid: true })
    }

    get valid(): boolean {
        return this.formGroup.valid;
    }

    reset(): void {
        this.requester = null;
        this.volunteers = [];
        this.formGroup.reset();
        this.giveAll.reset();
        this.giveInput.reset();
        this.columns.forEach(column => column.input.reset())
    }

    getCreateRequestModel(): CreateRequestModel {
        return {
            originalRequestUUId: null,
            addressUUId: this.formGroup.controls['venue'].value,
            destinationUUId: this.formGroup.controls['destination'].value,
            service: this.formGroup.controls['service'].value,
            startTime: this.formGroup.controls['start'].value,
            endTime: this.formGroup.controls['noEndTime'].value == true ? null : this.formGroup.controls['end'].value,
            remark: this.formGroup.controls['remark'].value,
            numberOfVolunteer: this.formGroup.controls['numberOfVolunteer'].value,
            transportationUUId: this.formGroup.controls['transportation'].value,
            extraRequestJson: this.stringtifyColumns(this.columns)
        };
    }

    stringtifyColumns(columns: TemplateColumn[]): string {
        var temp: any = {};
        columns.forEach(column => {
            temp[column.columnName] = column.input.value
        });
        return JSON.stringify(temp);
    }

    get sum(): number {
        return this.volunteers.map(user => user.recordForm.value ? user.recordForm.value : 0).reduce((sum, value) => sum += value, 0);
    }
}
