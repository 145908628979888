import { IEvent } from "../events/event";
import { IRequest } from "../requests/request";
import { ITransaction, Transaction } from "./transaction";

export interface IUserTransaction extends ITransaction {
    request: IRequest;
    event: IEvent;
}

export class UserTransaction extends Transaction {
    request: IRequest;
    event: IEvent;

    constructor(transaction: IUserTransaction) {
        super(transaction);
        this.request = transaction.request;
        this.event = transaction.event;
    }
}