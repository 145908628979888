<div class="container-body">
    <div class="inline-flex-row justify-content-center position-relative">
        <button class="date-button" (click)="picker.open()">
            <ng-container *ngIf="date.value == null">
                <p translate>全部</p>
                <i class="material-icons-round date-icon">arrow_drop_down</i>
            </ng-container>
            <ng-container *ngIf="date.value != null">
                <p>{{ date.value | date: 'yyyy/MM' }}</p>
                <i class="material-icons-round date-icon" (click)="$event.stopPropagation();close()">close</i>
            </ng-container>
        </button>
        <input style="display: none;" [matDatepicker]="picker" [formControl]="date" readonly>
        <mat-datepicker startView="year" (monthSelected)="onMonthSelected($event, picker)" disabled="false" touchUi #picker></mat-datepicker>

        <button class="icon-button yellow" style="position: absolute;right: 0;" (click)="exportUserTransactions()">
            <i class="material-icons-round mb-1">archive</i>
            <p translate>匯出所有</p>
        </button>
    </div>

    <div class="inline-flex-row justify-content-center mt-3">
        <p class="text-lightgrey" *ngIf="isCurrentMonth" translate>本月</p>
    </div>

    <div class="inline-flex-row justify-content-center mt-3">
        <p style="font-size: 1.5rem; line-height: 2rem;" translate>時分結餘：</p>
        <p class="text-orange" style="font-size: 1.5rem; line-height: 2rem;">{{ income - expend }}</p>
    </div>

    <div class="inline-flex-row align-items-center" style="margin-top: 2rem;">
        <div class="inline-flex-column align-items-end flex-1" style="margin-right: 6.5625rem;">
            <div class="inline-flex-column align-items-center">
                <p style="font-size: 1.5rem; line-height: 2rem;" translate>收入</p>
                <p class="text-green" style="font-size: 1.5rem; line-height: 2rem;margin-top: 1.5rem;">{{ income }}</p>
            </div>
        </div>
        <div style="border-right: 1px dashed #979797;height: 6.875rem;"></div>
        <div class="inline-flex-column align-items-start flex-1" style="margin-left: 6.5625rem;">
            <div class="inline-flex-column align-items-center">
                <p style="font-size: 1.5rem; line-height: 2rem;" translate>支出</p>
                <p class="text-red" style="font-size: 1.5rem; line-height: 2rem;margin-top: 1.5rem;">{{ expend }}</p>
            </div>
        </div>
    </div>

    <div class="mt-7" style="border-bottom: 1px solid #979797;"></div>

    <div class="inline-flex-row justify-content-center" style="margin-top: 1.5rem;">
        <button class="switch-button" [ngClass]="{'active': tab=='Request'}" (click)="tab='Request'">
            <i class="material-icons-round text mr-4">group</i>
            <p translate>義工服務</p>
        </button>
        <button class="switch-button" style="margin-left: 5rem;" [ngClass]="{'active': tab=='Event'}" (click)="tab='Event'">
            <i class="material-icons-round text mr-4">home</i>
            <p translate>中心活動</p>
        </button>
        <button class="switch-button" style="margin-left: 5rem;" [ngClass]="{'active': tab=='Donation'}" (click)="tab='Donation'">
            <i class="material-icons-round text mr-4">favorite_border</i>
            <p translate>捐分紀錄</p>
        </button>
        <button class="switch-button" style="margin-left: 5rem;" [ngClass]="{'active': tab=='Redeem'}" (click)="tab='Redeem'">
            <i class="material-icons-round text mr-4">redeem</i>
            <p translate>獎勵兌換</p>
        </button>
    </div>


    <div style="margin-top: 1.875rem;overflow: hidden;">
        <div class="switch-body" [ngClass]="{'tab-1': tab == 'Request','tab-2': tab == 'Event','tab-3': tab == 'Donation','tab-4': tab == 'Redeem'}">
            <div class="switch-page">
                <app-user-request-transaction [transactions]="requestTransactions" [reloadAction]="reloadAction"></app-user-request-transaction>
            </div>
            <div class="switch-page">
                <app-user-event-transaction [transactions]="eventTransactions" [reloadAction]="reloadAction"></app-user-event-transaction>
            </div>
            <div class="switch-page">
                <app-user-donation-transaction [transactions]="donationTransactions" [reloadAction]="reloadAction"></app-user-donation-transaction>
            </div>
            <div class="switch-page">
                <app-user-redeem-transaction [redeems]="filteredRedeems" [reloadAction]="reloadAction"></app-user-redeem-transaction>
            </div>
        </div>
    </div>
</div>