import { Component, Input, OnInit } from '@angular/core';
import { Service } from 'src/app/models/services/service';
import { ServiceType } from 'src/app/models/services/service-type';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-service-popup',
  templateUrl: './service-popup.component.html',
  styleUrls: ['./service-popup.component.scss']
})
export class ServicePopupComponent implements OnInit {

  @Input() action: (services: Service[]) => void = () => { };
  serviceTypes: ServiceType[] = [];
  display: boolean = false;

  constructor(
    private serviceService: ServiceService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.serviceService.getServiceTypeList()
      .subscribe({
        next: value => {
          this.serviceTypes = value;
        }
      });
  }

  open(services: Service[] | undefined): void {
    if (services == undefined) {
      this.display = true;
      return
    }

    this.serviceTypes.forEach(st => {
      st.services.filter(service => services.some(s => s.uuId == service.uuId))
        .forEach(service => service.selected = true);
    })
    this.display = true;
  }

  close(): void {
    this.reset();
    this.display = false;
  }

  submit(): void {
    this.action(this.serviceTypes.map(st => st.services.filter(s => s.selected)).reduce((previous, current) => previous = previous.concat(current), []));
    this.close();
  }

  selectAll(): void {
    this.serviceTypes.forEach(serviceType => serviceType.services.forEach(service => service.selected = true));
  }

  reset(): void {
    this.serviceTypes.forEach(serviceType => serviceType.services.forEach(service => service.selected = false));
  }

  get numberOfServices(): number {
    return this.serviceTypes.map(serviceType => serviceType.services.length).reduce((sum, count) => sum += count, 0);
  }

  get selectedServiceTypes(): ServiceType[] {
    let serviceTypes = this.serviceTypes.filter(serviceType => serviceType.selected == true);
    return serviceTypes.length > 0 ? serviceTypes : this.serviceTypes;
  }

}
