import { UpdateEventDayForm } from "src/app/modules/event/forms/update-event-day-form";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IEventDay {
  uuId: string | null;
  date: Date;
  startTime: Date;
  endTime: Date;
  isCancelled: boolean;
  numOfAttendance: number | null;
}

export class EventDay {
  uuId: string | null;
  date: Date;
  startTime: Date;
  endTime: Date | null;
  isCancelled: boolean;

  editing: boolean;
  numOfAttendance: number | null;

  constructor(eventDay: IEventDay) {
    this.uuId = eventDay.uuId;
    this.date = dayjs.utc(eventDay.date).toDate();
    this.startTime = dayjs.utc(eventDay.startTime).toDate();
    this.endTime = eventDay.endTime
      ? dayjs.utc(eventDay.endTime).toDate()
      : null;
    this.isCancelled = eventDay.isCancelled;
    this.numOfAttendance = eventDay.numOfAttendance;
    this.editing = false;
  }
}
