<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="request-start-popup zoomIn" (click)="$event.stopPropagation()">
        <p translate>請輸用實際開始時間</p>
        <app-time-picker class="mt-4" [form]="form"></app-time-picker>

        <div class="flex-1"></div>

        <div class="inline-flex-row justify-content-end">
            <button class="button outline xs" (click)="close()" translate>取 消</button>
            <button class="button fill blue xs ml-4" (click)="confirm()" translate>確 定</button>
        </div>
    </div>
</div>