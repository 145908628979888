<div class="analysis-container">
  <div class="analysis-container-header">
    <div class="inline-flex-row align-items-center">
      <p class="text-bold" translate>活動類別分析</p>
    </div>
    <div class="filterHeader">
      <div class="inline-flex-row mt-8 startline">
        <button class="left-button" (click)="dataType='Request'; fetch()" [ngClass]="{'active': dataType=='Request'}"
          translate>義工服務</button>
        <button class="middle-button" (click)="dataType='Event'; fetch()" [ngClass]="{'active': dataType=='Event'}"
          translate>中心活動</button>
        <button class="right-button" (click)="dataType='Donation'; StateType='All'; fetch()"
          [ngClass]="{'active': dataType=='Donation'}" translate>捐分</button>
      </div>

      <div class="inline-flex-row mt-8 endinline selectDIV">
        <div>
          <select class="user-select xxxl" [formControl]="cellGroup" (change)="fetch();">
            <option [ngValue]="null" translate>全部</option>
            <optgroup *ngFor="let center of centers" [label]="center.name">
              <option [ngValue]="cg.uuId" *ngFor="let cg of center.cellGroups">{{ cg.name }}</option>
            </optgroup>
          </select>
        </div>
      </div>

      <div class="inline-flex-row mt-8 endinline" style="position:relative">
        <input class="user-input xl ml-4" [ngModel]="dateRange" (click)="picker.open()" placeholder="{{placeholder}}"
          readonly>
        <ng-container *ngTemplateOutlet="datepicker"></ng-container>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        <mat-date-range-input style="display: none;" [rangePicker]="picker">
          <input matStartDate [formControl]="start">
          <input matEndDate [formControl]="end" (dateInput)="fetch()">
        </mat-date-range-input>
      </div>

    </div>
  </div>

  <div class="filterHeader stateData" *ngIf="dataType!='Donation';">
    <label>
      <input type="radio" name='StateType' [ngModel]="StateType" value="All" checked="checked"
        (change)="StateType='All';fetch()" />
      <span translate>全部</span>
    </label>
    <label>
      <input type="radio" name='StateType' [ngModel]="StateType" value="Done" (change)="StateType='Done';fetch()" />
      <span translate>已完成</span>
    </label>
  </div>

  <div class="analysis-container-body">
    <div class="inline-flex-column" style="flex: 7; position: relative; align-items: center;">
      <div style="height: 300px">
        <canvas #graph></canvas>
      </div>
      <div #graphLabel class="inline-flex-column align-items-center" style="position: absolute;">
        <p class="text-lightgrey" *ngIf="dataType=='Request'&&StateType=='All';" translate>總申請量</p>
        <p class="text-lightgrey" *ngIf="dataType=='Request'&&StateType=='Done';" translate>總完成量</p>
        <p class="text-lightgrey" *ngIf="dataType=='Event'" translate>總數量</p>
        <p class="text-lightgrey" *ngIf="dataType=='Donation'" translate>總捐分次數</p>
        <p class="mt-4" style="font-size: 1.875rem; line-height: 1.875rem;">{{ total }}</p>
      </div>
    </div>

    <app-simple-table-box style="flex: 7;padding-left: 1rem;" [displayList]="displayList">
      <div class="head service-item">
        <div class="service-ratio-title-dot" style="background-color:transparent"></div>
        <span class="service-ratio-col1" translate>類別</span>
        <span class="service-ratio-col2" translate>佔比</span>
        <span class="service-ratio-col3 center" translate *ngIf="dataType=='Request'&&StateType=='All';">申請次數</span>
        <span class="service-ratio-col3 center" translate *ngIf="dataType=='Request'&&StateType=='Done';">完成次數</span>
        <span class="service-ratio-col3 center" translate *ngIf="dataType=='Event'">數量</span>
        <span class="service-ratio-col3 center" translate *ngIf="dataType=='Donation'">次數</span>
        <span class="service-ratio-col4 center" translate *ngIf="dataType=='Request'">義工人數</span>
        <span class="service-ratio-col4 center" translate *ngIf="dataType=='Event'&&StateType=='All'">確認人數</span>
        <span class="service-ratio-col4 center" translate *ngIf="dataType=='Event'&&StateType=='Done'">出席人數</span>
      </div>

      <ng-template #row let-service='service' let-index="index">
        <div class="service-item">
          <div class="service-ratio-title-dot" [style]="'background-color:'+color(index)"></div>
          <p class="service-ratio-col1 ">{{ service.index }}</p>
          <p class="service-ratio-col2">{{ service.value * 100 / total | number }}%</p>
          <p class="service-ratio-col3 center">{{ service.value }}</p>
          <p class="service-ratio-col4 center" *ngIf="dataType!='Donation'">{{ UserNumber(service.index) }}</p>
        </div>
      </ng-template>
    </app-simple-table-box>
  </div>

  <div class="chart-container-footer">
    <a class="exportBtn data-type-button" translate (click)="export()">匯出報告</a>
  </div>
</div>

<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px"
    height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted">
    <path
      d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
    </path>
  </svg></ng-template>