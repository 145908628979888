<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>獎勵兌換</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>獎勵設置</p>
    </div>

    <p class="page-title mt-8" translate>獎勵設置</p>

    <div class="container mt-5">
        <div class="container-body">

            <div class="section">
                <div class="input-row">
                    <p class="section-title" matTooltip="{{ '功能設置' | translate }}" [matTooltipPosition]="'above'" translate>功能設置</p>
                    <p class="text-xs text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" matTooltip="{{ '屏蔽設置' | translate }}" [matTooltipPosition]="'above'" translate>屏蔽設置</p>
                    <div class="inline-flex-column" style="width: 25.9375rem;">
                        <p style="line-height: 1.375rem;" matTooltip="{{ '以下所選中心的全部會員在APP中會屏蔽整個獎勵兌換功能區' | translate }}" [matTooltipPosition]="'above'" translate>以下所選中心的全部會員在APP中會屏蔽整個獎勵兌換功能區</p>
                        <div class="border-bottom mt-4 mb-5"></div>
                        <div class="flex-row align-items-center mt-2 mb-4" *ngFor="let center of blockedCenters">
                            <p style="flex: 0 0 3rem">{{ center.centerCode }}</p>
                            <p class="flex-1">{{ center.name }}</p>
                            <p class="click-text-lightblue text-xs" (click)="deleteCenter(center)" translate>移除</p>
                        </div>
                        <div class="flex-row align-items-center" (click)="center_popup.open(blockedCenters)">
                            <p class="text-lightgrey mr-4" translate>新增中心</p>
                            <i class="add-icon material-icons-round">add_circle</i>
                        </div>
                    </div>
                </div>

                <app-center-popup #center_popup [action]="centerPopupAction"></app-center-popup>
            </div>

            <div class="section">
                <div class="input-row">
                    <p class="section-title" matTooltip="{{ '問卷設置' | translate }}" [matTooltipPosition]="'above'" translate>問卷設置</p>
                    <p class="text-xs text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row" style="padding-left: 0.875rem;">
                    <p translate>問題設置適用於通用獎勵兌換功能，如職員發布某獎勵項目時設置會員須回答問題，會員則必須作答以下問題才可換領</p>
                </div>

                <div class="inline-flex-column" style="padding-left: 0.875rem;width: 36.875rem;box-sizing: border-box;">

                    <ng-container *ngFor="let question of questions.controls;let index = index">
                        <app-redeem-question [question]="question" [index]="index" [delete]="removeQuestion"></app-redeem-question>
                        <div class="mt-7" style="border-top: 1px solid #979797;"></div>
                    </ng-container>

                    <div class="inline-flex-row align-items-center" style="padding-top: 0.75rem" (click)="addQuestion()" *ngIf="questions.length < 3">
                        <i class="add-icon click-text-lightblue material-icons mr-4">add_circle</i>
                        <p class="click-text-lightblue" translate>增加問題（最多可設置3個問題）</p>
                    </div>
                </div>
            </div>

            <form class="section" [formGroup]="ruleForm">
                <div class="input-row">
                    <p class="section-title" matTooltip="{{ '獎勵規則' | translate }}" [matTooltipPosition]="'above'" translate>獎勵規則</p>
                    <p class="text-xs text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p style="padding-left: 0.875rem;" translate>獎勵規則通用於所屬機構下全部獎勵的換領流程，會員在App中可查看規則詳情，建議謹慎填寫不要輕易改動</p>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '規則標題' | translate }}" [matTooltipPosition]="'above'" translate>規則標題</p>
                    <input class="user-input xl" type="text" formControlName="ruleTitle" placeholder="{{ '請輸入規則標題' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '規則詳情' | translate }}" [matTooltipPosition]="'above'" translate>規則詳情</p>
                    <textarea style="width: 22rem;height: 15.5rem;" formControlName="ruleDescription" placeholder="{{ '請輸入最少一句話描述（例如計劃介紹，兌換規則及領取方式等）' | translate }}"></textarea>
                </div>
            </form>

            <div class="inline-flex-row justify-content-center" style="margin-top: 8.4375rem; margin-bottom: 4.375rem;">
                <button class="button fill blue" (click)="save()" translate>儲 存</button>
            </div>
        </div>
    </div>
</div>