import { Component, Input, OnInit } from "@angular/core";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { CellGroupService } from "src/app/services/cell-group/cell-group.service";

@Component({
  selector: "app-cell-group-popup",
  templateUrl: "./cell-group-popup.component.html",
  styleUrls: ["./cell-group-popup.component.scss"],
})
export class CellGroupPopupComponent implements OnInit {
  @Input() action: (cellGroups: CellGroup[]) => void = () => {};
  cellGroups: CellGroup[] = [];
  display: boolean = false;

  constructor(private cellGroupService: CellGroupService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.cellGroupService.getCellGroupList().subscribe({
      next: (value) => {
        this.cellGroups = value;
      },
    });
  }

  open(cellGroups: CellGroup[]): void {
    this.cellGroups
      .filter((cellGroup) => cellGroups.some((c) => c.uuId == cellGroup.uuId))
      .forEach((cellGroup) => (cellGroup.selected = true));
    this.display = true;
  }

  close(): void {
    this.display = false;
    this.reset();
  }

  confirm(): void {
    this.action(this.cellGroups.filter((cellGroup) => cellGroup.selected));
    this.close();
  }

  reset(): void {
    this.cellGroups.forEach((cellGroup) => (cellGroup.selected = false));
  }
}
