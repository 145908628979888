import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-emoji-picker",
  templateUrl: "./emoji-picker.component.html",
  styleUrls: ["./emoji-picker.component.scss"],
})
export class EmojiPickerComponent implements OnInit {
  @Input() form!: FormControl;
  emojis: IEmoji[] = [];
  categories: IEmoji[] = [];
  selectedCategory: IEmoji | null = null;

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.httpClient.get("assets/emoji.json").subscribe((value: any) => {
      this.emojis = value.emojis;
      this.categories = this.emojis.filter(
        (value, index, self) =>
          self.findIndex((v) => v.category == value.category) === index,
      );
      this.selectedCategory = this.categories[0];
    });
  }

  get filteredEmojis(): IEmoji[] {
    return this.selectedCategory == null
      ? []
      : this.emojis.filter(
          (emoji) => emoji.category == this.selectedCategory?.category,
        );
  }

  onSelect(emoji: IEmoji): void {
    if (this.form == null) return;

    if (this.form.value == null) {
      this.form.setValue(`${emoji.emoji}`);
      return;
    }

    this.form.setValue(`${this.form.value}${emoji.emoji}`);
  }
}

interface IEmoji {
  category: string;
  emoji: string;
  html: "&#128105;&zwj;&#128105;&zwj;&#128103;&zwj;&#128103;";
  name: "family: woman, woman, girl, girl";
  order: "";
  shortname: ":woman_woman_girl_girl:";
  unicode: "1F469 200D 1F469 200D 1F467 200D 1F467";
}
