import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { VolunteerService } from 'src/app/services/volunteer/volunteer.service';
import { Request } from 'src/app/models/requests/request';
import { Volunteer } from 'src/app/models/requests/volunteer';
import { Transaction } from 'src/app/models/transactions/transaction';
import { PopupMessages } from 'src/app/backend/popup-message';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-request-detail-volunteer-table',
  templateUrl: './request-detail-volunteer-table.component.html',
  styleUrls: ['./request-detail-volunteer-table.component.scss']
})
export class RequestDetailVolunteerTableComponent implements OnInit {

  @Input() request!: Request;
  @Input() reloadAction: () => void = () => { }

  filters: any = {
    page: 1,
    itemsPerPage: 10,
  };

  constructor(
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService,
    private volunteerService: VolunteerService,
    private route: ActivatedRoute,
    private transactionService: TransactionService
  ) { }

  ngOnInit(): void {
  }

  showInterest(userUUId: string): void {
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.volunteerService.showInterest(value.id, { volunteersUUId: [userUUId] })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.reloadAction();
        });
    })
  }

  match(userUUId: string): void {
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.volunteerService.match(value.id, { volunteersUUId: [userUUId] })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.reloadAction();
        });
    })
  }

  checkIn(userUUId: string): void {
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.volunteerService.checkIn(value.id, { volunteersUUId: [userUUId] })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {
          this.reloadAction();
        })
    });
  }

  editTransaction(transaction: Transaction): void {
    if (transaction.form.invalid) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();
    this.transactionService.editTransaction(transaction.uuId, { amount: transaction.form.value })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          transaction.uuId = value.newTransactionUUId;
          transaction.amount = transaction.form.value
          transaction.editing = false;
          this.popupMessageService.messageSignal.emit(PopupMessages.EditTransactionSuccessMessage);
        }
      });
  }

  get pages(): number {
    return Math.ceil(this.request.volunteers.length / this.filters.itemsPerPage);
  }

  get startToday(): boolean {
    let today = new Date()
    return this.request.requestStatus == 'Ready' &&
      (this.request ?
        this.request.startTime.getFullYear() == today.getFullYear()
        && this.request.startTime.getMonth() == today.getMonth()
        && this.request.startTime.getDate() == today.getDate() : false)
  }

  get displayedVolunteers(): Volunteer[] {
    return this.request.volunteers.slice((this.filters.page - 1) * this.filters.itemsPerPage, this.filters.page * this.filters.itemsPerPage);
  }

  get spending(): number {
    return this.request.volunteers.filter(v => v.volunteerStatus == 'CheckedOut').map(v => v.transaction ? v.transaction.amount : 0).reduce((sum, t) => sum + t, 0);
  }
}
