import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TemplateColumn } from "src/app/backend/app-column";
import { Event } from "src/app/models/events/event";
import { EventDay } from "src/app/models/events/event-day";
import { UpdateEventModel } from "src/app/models/events/update-event-model";
import { IEventDayForm } from "./create-event-day-form";
import { UpdateEventDayForm } from "./update-event-day-form";

export class UpdateEventForm {
    formGroup: FormGroup;
    eventDays: IEventDayForm[];
    columns: TemplateColumn[];

    constructor(event: Event) {
        this.formGroup = new FormGroup({
            eventType: new FormControl(event.eventType.uuId, [Validators.required]),
            eventName: new FormControl(event.eventName, [Validators.required]),
            venue: new FormControl(event.venue?.room.uuId, [Validators.required]),
            eventPay: new FormControl(event.eventPay),
            eventJoinType: new FormControl(event.eventJoinType),
            participantVacancy: new FormControl(event.participantVacancy, [Validators.required, Validators.min(0)]),
            remark: new FormControl(event.remark),
            detail: new FormControl(event.detail),
            earning: new FormControl(event.earning, [Validators.required, Validators.min(0)]),
            applyDeadline: new FormControl(event.applyDeadline, [Validators.required]),
            urlType: new FormControl(event.url == null ? 'text' : 'url'),
            url: new FormControl(event.url)
        });

        this.formGroup.controls['urlType'].valueChanges.subscribe(value => {
            if (value == 'url') {
                this.formGroup.controls['url'].setValidators([Validators.required]);
            }
            else {
                this.formGroup.controls['url'].setValidators([Validators.nullValidator]);
            }

            this.formGroup.controls['url'].updateValueAndValidity();
        })

        this.eventDays = event.eventDays.map(ed => new UpdateEventDayForm(ed));
        this.columns = [];
    }

    getUpdateEventModel(): UpdateEventModel {
        return {
            eventTypeUUId: this.formGroup.controls['eventType'].value,
            eventName: this.formGroup.controls['eventName'].value,
            venue: this.formGroup.controls['venue'].value,
            startTime: this.getEventStartDate().toJSON(),
            endTime: this.getEventEndDate().toJSON(),
            isUnknownEndTime: false,
            participantVacancy: this.formGroup.controls['participantVacancy'].value,
            remark: this.formGroup.controls['remark'].value,
            detail: this.formGroup.controls['detail'].value,
            eventPay: this.formGroup.controls['eventPay'].value,
            earning: this.formGroup.controls['earning'].value,
            eventJoinType: this.formGroup.controls['eventJoinType'].value,
            applyDeadline: (this.formGroup.controls['applyDeadline'].value as Date).toJSON(),
            eventDays: this.eventDays.map(ed => ed.getModel()),
            extraEventJson: this.stringtifyColumns(this.columns),
            url: this.formGroup.controls['url'].value
        }
    }

    public getEventStartDate(): Date {
        return this.eventDays.map(ed => ed.start.value).sort((a, b) => a > b ? 1 : a < b ? -1 : 0)[0];
    }

    public getEventEndDate(): Date {
        return this.eventDays.map(ed => ed.end.value).sort((a, b) => a > b ? -1 : a < b ? 1 : 0)[0];
    }

    protected stringtifyColumns(columns: TemplateColumn[]): string {
        var temp: any = {};
        columns.forEach(column => {
            temp[column.columnName] = column.input.value
        });
        return JSON.stringify(temp);
    }
}