import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { CellGroupService } from "src/app/services/cell-group/cell-group.service";
import { CenterService } from "src/app/services/center/center.service";

@Component({
  selector: "app-cell-group-setting",
  templateUrl: "./cell-group-setting.component.html",
  styleUrls: ["./cell-group-setting.component.scss"],
})
export class CellGroupSettingComponent implements OnInit {
  index: number = 0;
  centers: Center[] = [];

  form: FormGroup = new FormGroup({
    center: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
  });

  loading: boolean = false;

  constructor(
    private centerService: CenterService,
    private cellGroupService: CellGroupService,
  ) {}

  ngOnInit(): void {
    this.centerService.centerCreatedEvent.subscribe(() => {
      this.fetch();
    });

    this.centerService.centerUpdatedEvent.subscribe(() => {
      this.fetch();
    });

    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.cellGroupService
      .getCenterCellGroupList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => (this.centers = value),
      });
  }

  create(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.cellGroupService
      .createCellGroup({
        centerUUId: this.form.controls["center"].value,
        name: this.form.controls["name"].value,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
          this.form.reset();
        },
      });
  }

  update(cellGroup: CellGroup): void {
    if (cellGroup.form.invalid) {
      cellGroup.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.cellGroupService
      .updateCellGroup(cellGroup.getUpdateCellGroupModel())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => this.fetch(),
      });
  }

  get selectedCenter(): Center | null {
    return this.centers.length > 0 ? this.centers[this.index] : null;
  }
}
