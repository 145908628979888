import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CreateAddressModel } from "src/app/models/addresses/create-address-model";
import { Living } from "src/app/models/livings/living";

export class AddressForm {
    formGroup: FormGroup;
    livings: Living[];
    disabled: boolean;

    constructor() {
        this.formGroup = new FormGroup({
            region: new FormControl(null, [Validators.required]),
            district: new FormControl(null, [Validators.required]),
            subDistrict: new FormControl(''),
            street: new FormControl(''),
            streetNumber: new FormControl(''),
            estate: new FormControl(''),
            building: new FormControl(''),
            floor: new FormControl(''),
            room: new FormControl('')
        });
        this.livings = [];
        this.disabled = false;
    }

    public getCreateAddressModel(): CreateAddressModel {
        return {
            region: this.formGroup.controls['region'].value,
            district: this.formGroup.controls['district'].value,
            subDistrict: this.formGroup.controls['subDistrict'].value,
            street: this.formGroup.controls['street'].value,
            streetNumber: this.formGroup.controls['streetNumber'].value,
            estate: this.formGroup.controls['estate'].value,
            block: this.formGroup.controls['building'].value,
            floor: this.formGroup.controls['floor'].value,
            room: this.formGroup.controls['room'].value,
            isDefault: false,
            livingConditions: this.livings.map(lc => lc.uuId)
        };
    }
}