import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-data-setting-container",
  templateUrl: "./data-setting-container.component.html",
  styleUrls: ["./data-setting-container.component.scss"],
})
export class DataSettingContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
