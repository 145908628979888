import { EventEmitter, Injectable } from "@angular/core";
import { PopupMessage } from "src/app/backend/popup-message";

@Injectable({
  providedIn: "root",
})
export class PopupMessageService {
  constructor() {}

  public messageSignal: EventEmitter<PopupMessage> = new EventEmitter();

  public executeSuccessSignal: EventEmitter<any> = new EventEmitter();
}
