import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Center } from "src/app/models/centers/center";
import { CreateStaffModel } from "src/app/models/staffs/create-staff-model";
import { AddressForm } from "../../share/forms/address-form";

export class CreateStaffForm {
  public formGroup: FormGroup;
  public addresses: AddressForm[];
  public accessibleCenters: Center[];

  constructor() {
    this.formGroup = new FormGroup({
      memberId: new FormControl(null, [Validators.required]),
      username: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(8),
      ]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(12),
      ]),
      chineseName: new FormControl(null, [Validators.required]),
      englishName: new FormControl(null),
      center: new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8),
      ]),
      allowApproval: new FormControl(false),
    });
    this.addresses = [new AddressForm()];
    this.accessibleCenters = [];
  }

  public getCreateStaffModel(): CreateStaffModel {
    var model: CreateStaffModel = {
      Username: this.formGroup.controls["username"].value,
      Password: this.formGroup.controls["password"].value,
      MemberId: this.formGroup.controls["memberId"].value,
      phoneNumber: this.formGroup.controls["phone"].value,
      Profile: {
        ChineseName: this.formGroup.controls["chineseName"].value,
        EnglishName: this.formGroup.controls["englishName"].value,
        Gender: this.formGroup.controls["gender"].value,
        Remark: null,
        CenterCode: this.formGroup.controls["center"].value,
        Addresses: this.addresses.map((address) =>
          address.getCreateAddressModel(),
        ),
      },
      CentersStaff: this.accessibleCenters.map((center) => center.uuId),
      allowApproval: this.formGroup.controls["allowApproval"].value,
    };
    model.Profile.Addresses[0].isDefault = true;
    return model;
  }

  addAddress(): void {
    this.addresses.push(new AddressForm());
  }

  public reset(): void {
    this.formGroup.reset();
    this.addresses = [new AddressForm()];
    this.accessibleCenters = [];
  }

  get valid(): boolean {
    return (
      this.formGroup.valid &&
      this.addresses.every((address) => address.formGroup.valid)
    );
  }
}
