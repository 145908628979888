<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="service-box zoomIn" (click)="$event.stopPropagation()">

        <div class="service-body">
            <div class="service-type-column">
                <p translate>可提供服務類型</p>
                <div class="all-box mt-6 mb-6">
                    <p class="flex-1" translate>全部</p>
                    <p class="text-sm text-lightgrey">{{ numberOfServices }}</p>
                </div>

                <label class="service-type-item" *ngFor="let serviceType of serviceTypes">
                    <input class="mr-5" type="checkbox" [(ngModel)]="serviceType.selected">
                    <p>{{ serviceType.name }}</p>
                </label>
            </div>

            <div class="service-column">
                <div class="inline-flex-row">
                    <p class="flex-1" translate>可提供服務</p>
                    <p class="click-text-blue" style="margin-right: 2.25rem;" (click)="selectAll()" translate>選擇全部</p>
                    <p class="click-text-blue" (click)="reset()" translate>重置</p>
                </div>

                <ng-container *ngFor="let serviceType of selectedServiceTypes">
                    <p class="service-title">{{ serviceType.name }}</p>
                    <div class="service-item-box">
                        <label class="service-item" *ngFor="let service of serviceType.services">
                            <input class="mr-4" type="checkbox" [(ngModel)]="service.selected">
                            <p class="mr-4" style="flex: 0 0 1.875rem;">{{ service.servicesCode }}</p>
                            <p>{{ service.name }}</p>
                        </label>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="footer">
            <button class="button outline" style="margin-right: 7.5rem;" (click)="close()" translate>返 回</button>
            <button class="button fill blue" (click)="submit()" translate>確 認</button>
        </div>
    </div>
</div>