<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedAddresses > 0}" (click)="toggle()">
    <i class="filter-icon material-icons-outlined" *ngIf="numberOfSelectedAddresses == 0">location_on</i>
    <p class="filter-number" *ngIf="numberOfSelectedAddresses > 0">{{ numberOfSelectedAddresses }}</p>
    <p translate>分區</p>
    <div class="filter-body address" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-6">
                <p class="text-bold flex-1" style="color: #5A5A5A" translate>分區</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="search-div mb-6">
                <p class="search-chip" *ngFor="let subDistrict of subDistricts;let index = index">{{ subDistrict }}<i class="search-chip-remove material-icons-round" (click)="removeSubDistrict(index)">close</i></p>
                <input type="text" class="search-input" [formControl]="form" (keyup.enter)="addSubDistrict()" placeholder="{{ '搜索關鍵詞並以enter鍵結束' | translate }}">
            </div>

            <div class="filter-box">
                <ng-container *ngFor="let region of regions">
                    <label class="checkbox mb-3">
                        <input class="mr-8" type="checkbox" [ngModel]="region.isSelectedAll" (click)="region.selectAll()">
                        <p>{{ region.name | translate }}</p>
                    </label>
                    <label class="ml-8 checkbox mb-4" *ngFor="let district of region.districts">
                        <input class="ml-8 mr-8" type="checkbox" [(ngModel)]="district.selected">
                        <p class="text-sm">{{ district.name | translate }}</p>
                    </label>
                </ng-container>

            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>