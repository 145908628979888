import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Availability } from "src/app/models/profiles/availability";
import { Profile } from "src/app/models/profiles/profile";
import { ServiceType } from "src/app/models/services/service-type";
import { SignUpUser } from "src/app/models/sign-ups/sign-up-user";
import { CenterService } from "src/app/services/center/center.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { ServiceService } from "src/app/services/service/service.service";
import { SignUpService } from "src/app/services/sign-up/sign-up.service";

@Component({
  selector: "app-sign-up-user-detail",
  templateUrl: "./sign-up-user-detail.component.html",
  styleUrls: ["./sign-up-user-detail.component.scss"],
})
export class SignUpUserDetailComponent implements OnInit {
  client!: Profile;
  lang: string = this.translateService.currentLang;
  user!: SignUpUser;
  centers: Center[] = [];
  serviceTypes: ServiceType[] = [];
  availabilities: Availability[] = [];

  constructor(
    private signUpService: SignUpService,
    private loadingService: LoadingService,
    private centerService: CenterService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private serviceService: ServiceService,
    private profileService: ProfileService,
    private popupMessageService: PopupMessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.translateService.onLangChange.subscribe((value) => {
      this.lang = value.lang;
    });

    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      forkJoin({
        user: this.signUpService.getSignUpUser(value.id),
        centers: this.centerService.getCenters(),
        services: this.serviceService.getServiceTypeList(),
        client: this.profileService.getMyProfile(),
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.user = value.user;
          this.centers = value.centers;
          this.serviceTypes = value.services;
          this.serviceTypes.forEach((st) =>
            st.services
              .filter((s) =>
                this.user?.services.some((service) => s.uuId == service.uuId),
              )
              .forEach((s) => (s.selected = true)),
          );

          this.client = value.client;

          if (this.user.signUpStatus == "Approving") {
            this.user.form.enable();
            return;
          }

          if (this.user.signUpStatus == "Approved") {
            this.router.navigate(["/main/user/overview", this.user.userUUId]);
            return;
          }

          this.user.form.disable();
          this.user.form.updateValueAndValidity();

          this.availabilities = [
            {
              uuId: null,
              weekDay: 0,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 1,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 2,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 3,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 4,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 5,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
            {
              uuId: null,
              weekDay: 6,
              availabilityStart: new Date("2000-02-08T22:00:00.000Z"),
              availabilityEnd: new Date("2000-02-09T13:00:00.000Z"),
            },
          ];
        });
    });
  }

  startApproval(): void {
    this.route.params.subscribe((value) => {
      this.signUpService.startApproval(value.id).subscribe((value) => {
        this.user.form.enable();
        window.scrollTo(0, 0);
        this.fetch();
      });
    });
  }

  get selectedServiceTypes(): ServiceType[] {
    return this.serviceTypes.filter((st) =>
      st.services.some((s) => s.selected == true),
    );
  }

  approve(): void {
    if (this.user.form.invalid) {
      this.user.form.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      this.signUpService
        .approve(this.user.getApproveModel())
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.router.navigate(["/main/user/overview", this.user.userUUId]);
        });
    });
  }

  reapprove(): void {
    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      this.signUpService
        .resetApproval(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.fetch();
          window.scrollTo(0, 0);
        });
    });
  }

  get userWantCenter() {
    if (this.user.wantJoinCenter != null)
      return (
        this.user.wantJoinCenter.centerCode + this.user.wantJoinCenter.name
      );
    else return "";
  }

  deleteUser(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteUserMessage(
        this.profileService.deleteUsers([this.user?.userUUId as string]),
      ),
    );
  }
}
