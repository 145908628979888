<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="deactivate-box zoomIn" (click)="$event.stopPropagation()">
        <div class="inline-flex-row">
            <i class="material-icons-round help-icon mr-8">help</i>
            <div class="inline-flex-column">
                <p class="text-lg" style="line-height: 1.375rem;" translate>請填寫終止原因</p>
                <p class="text-lightgrey mt-6 mb-8" style="line-height: 1.375rem;white-space: pre-line;" translate>若通告已發布，終止該通告後，相關職員及會員將會收到系統通知</p>
                <label class="mb-9 text-lightgrey" *ngFor="let reason of reasons;let i = index">
                    <input class="align-self-start mr-4" type="checkbox" [(ngModel)]="reason.selected">
                    <p *ngIf="i < reasons.length - 1">{{ reason.reason | translate }}</p>
                    <div class="flex-column" *ngIf="i == reasons.length - 1">
                        <p translate>自訂原因</p>
                        <textarea [(ngModel)]="reason.reason" class="mt-3" style="width: 17.1875rem;height: 6.4375rem;" placeholder="{{ '請輸入取消原因' | translate }}"></textarea>
                    </div>
                </label>
            </div>
        </div>

        <div class="flex-1"></div>

        <div class="flex-row justify-content-end">
            <button class="button outline xs" (click)="close()" translate>取 消</button>
            <button class="button fill blue xs ml-4" (click)="submit()" translate>确 定</button>
        </div>
    </div>
</div>