<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>通告</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>通告詳情</p>
    </div>

    <p class="page-title mt-8" translate>通告詳情</p>

    <div class="container mt-5" *ngIf="announcement">
        <div class="container-header">
            <img class="pin" src="/v2/assets/pin.png" alt="pin" *ngIf="announcement.isPinned == true">
            <p>{{ '記錄編號：' | translate: {value: announcement.id} }}</p>
            <p class="click-text-lightblue ml-8 flex-1" *ngIf="announcement.isPinned == false" (click)="pinAnnouncement()" translate>置頂</p>
            <p class="click-text-lightblue ml-8 flex-1" *ngIf="announcement.isPinned == true" (click)="unpinAnnouncement()" translate>取消置頂</p>
            <p>{{ '創建時間：' | translate: {value: announcement.createdAt | date: 'yyyy-MM-dd HH:mm'} }}</p>
        </div>

        <form class="container-body" [formGroup]="announcement.form">
            <ng-container *ngIf="announcement.isActive == true; then status else announcement_cancel"></ng-container>

            <ng-container *ngIf="announcement.lastModifiedBy != null">
                <div class="edit-box" style="margin-top: 3.375rem;">
                    <i class="material-icons-round info-icon mr-4" style="font-size: 1rem!important;">error</i>
                    <p translate>{{ '編輯過該通告' | translate: {value: announcement.lastModifiedBy} }}</p>
                    <p style="margin-left: 5rem;">{{ announcement.lastModifiedAt | date: 'yyyy-MM-dd HH:mm'}}</p>
                </div>
            </ng-container>

            <div class="input-row">
                <p class="title" matTooltip="{{ '通告狀態' | translate }}" [matTooltipPosition]="'above'" translate>通告狀態</p>
                <ng-container *ngIf="announcement.isActive">
                    <div class="status orange announcement-status mr-4" *ngIf="announcement.announceDate > now">
                        <p translate>未發布</p>
                    </div>
                    <div class="status green announcement-status mr-4" *ngIf="announcement.announceDate < now && announcement.expirationDate > now">
                        <p translate>已發布</p>
                    </div>
                    <div class="status grey announcement-status mr-4" *ngIf="announcement.expirationDate < now">
                        <p translate>已完結</p>
                    </div>
                </ng-container>
                <div class="status grey announcement-status mr-4" *ngIf="announcement.isActive == false">
                    <p translate>已終止</p>
                </div>
                <div class="status blue announcement-status" *ngIf="announcement.lastModifiedBy">
                    <p translate>已修改 </p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '通告標題' | translate }}" [matTooltipPosition]="'above'" translate>通告標題</p>
                <p *ngIf="announcement.editing == false">{{ announcement.title }}</p>
                <ng-container *ngIf="announcement.editing">
                    <input class="user-input md" formControlName="title">
                    <div class="zoomIn user-input-error" *ngIf="title.touched && title.errors">
                        <p *ngIf="title.errors.required" translate>請輸入通告標題</p>
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" translate>類型</p>
                <ng-container *ngIf="announcement.editing == false">
                    <p *ngIf="announcement.type == 'Text'" translate>純文本</p>
                    <p *ngIf="announcement.type == 'HyperLink'" translate>超鏈接</p>
                </ng-container>
                <ng-container *ngIf="announcement.editing">
                    <label style="margin-right: 1.75rem;">
                        <input type="radio" formControlName="type" value="Text" (change)="announcement.enableURL()">
                        <p class="ml-2" translate>純文本</p>
                    </label>
                    <label>
                        <input type="radio" formControlName="type" value="HyperLink" (change)="announcement.enableURL()">
                        <p class="ml-2" translate>超鏈接（會員直接訪問網址）</p>
                    </label>

                    <div class="zoomIn input-error-text" *ngIf="type.touched && type.errors">
                        <p class="error-text text-sm" *ngIf="type.errors.required" translate>請輸入類型</p>
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" translate>發布時間</p>
                <p *ngIf="announcement.editing == false">{{ announcement.announceDate | date : 'longDate' : '' : lang }} {{ announcement.announceDate | date : 'HH:mm' }}</p>
                <ng-container *ngIf="announcement.editing">
                    <input class="user-input xs" formControlName="announceDate" [matDatepicker]="picker_1" (click)="picker_1.open()" [min]="minDate" placeholder="{{ '發布時間' | translate }}" readonly>
                    <mat-datepicker startView="multi-year" disabled="false" touchUi #picker_1></mat-datepicker>
                    <app-time-picker [form]="announceDate" class="ml-9"></app-time-picker>
                    <div class="user-input-error zoomIn" *ngIf="announceDate.touched && announceDate.errors">
                        <p *ngIf="announceDate.errors.required" translate>請輸入發布日期</p>
                        <p *ngIf="announceDate.errors.time" translate>請輸入發布時間</p>
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" translate>有效期至</p>
                <p *ngIf="announcement.editing == false">{{ announcement.expirationDate | date : 'longDate' : '' : lang }} {{ announcement.expirationDate | date : 'HH:mm' }}</p>
                <ng-container *ngIf="announcement.editing">
                    <input class="user-input xs" formControlName="expireDate" [matDatepicker]="picker_2" (click)="picker_2.open()" [min]="minDate" placeholder="{{ '有效期至' | translate }}" readonly>
                    <mat-datepicker startView="multi-year" disabled="false" touchUi #picker_2></mat-datepicker>
                    <app-time-picker [form]="expireDate" class="ml-9"></app-time-picker>
                    <div class="user-input-error zoomIn" *ngIf="expireDate.touched && expireDate.errors">
                        <p *ngIf="expireDate.errors.required" translate>請輸入有效日期</p>
                        <p *ngIf="expireDate.errors.time" translate>請輸入有效時間</p>
                    </div>
                    <div class="user-input-error zoomIn" *ngIf="!expireDate.errors && announcement.invalidExpireDate">
                        <p translate>有效期必須在發佈日期後</p>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="announcement.type == 'HyperLink' && announcement.editing == false">
                <div class="input-row">
                    <p class="title required" translate>連結網址</p>
                    <p>{{ announcement.url }}</p>
                    <button type="button" class="button outline blue sm ml-6" type="button" (click)="openURL()" translate>瀏覽網址</button>
                </div>
            </ng-container>

            <ng-container *ngIf="type.value == 'HyperLink' && announcement.editing">
                <div class="input-row">
                    <p class="title required" translate>連結網址</p>
                    <input class="user-input xl" type="text" formControlName="url" placeholder="{{ '請輸入超鏈接' | translate }}">
                    <button type="button" class="button outline blue sm ml-6" type="button" (click)="openURL()" translate>瀏覽網址</button>
                    <div class="zoomIn user-input-error" *ngIf="url.touched && url.errors">
                        <p class="error-text text-sm" *ngIf="url.errors.required" translate>請輸入超鏈接</p>
                    </div>
                </div>
            </ng-container>

            <div class="input-row">
                <p class="title align-self-start" style="line-height: 1.375rem;" translate>通告詳情</p>
                <p *ngIf="announcement.editing == false" style="width: 20.3125rem;white-space: normal;line-height: 1.375rem;">{{ announcement.content }}</p>
                <textarea *ngIf="announcement.editing" style="width: 22rem;height: 17.0625rem;" formControlName="content"></textarea>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '通告對象' | translate }}" [matTooltipPosition]="'above'" translate>通告對象</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row align-items-center">
                        <p translate>已選擇邀請參與 </p>
                        <p class="ml-2 mr-2 text-lightblue">{{ announcement.users.length }}</p>
                        <p translate>位 會員</p>

                        <button class="button outline blue sm" style="margin-left: 2.5rem;" (click)="users.open()" translate>查看詳情</button>
                    </div>

                    <div class="inline-flex-row mt-2" style="width: 20.3125rem;">
                        <i class="material-icons-round info-icon mr-5">error_outline</i>
                        <p class="text-lightgrey text-xs" style="white-space: normal;" translate>職員可查看/管理所屬權限內的會員，未被選擇的會員將不會收到通告，如有需要，職員可新增通告對象至通告對象列表</p>
                    </div>
                </div>
            </div>

            <div class="input-row">
                <p class="title align-self-start" style="line-height: 1.375rem;" translate>備註</p>
                <ng-container *ngIf="announcement.editing == false">
                    <p *ngIf="announcement.remark == null" translate>暫無</p>
                    <p style="width: 20.3125rem;white-space: normal;line-height: 1.375rem;">{{ announcement.remark }}</p>
                </ng-container>
                <textarea *ngIf="announcement.editing" style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
            </div>

            <div class="input-row">
                <p class="title" translate>創建職員</p>
                <p>{{ announcement.createdBy }}</p>
            </div>


            <div class="input-row" *ngIf="announcement.isActive == false">
                <p class="title" translate>終止原因</p>
                <p>{{ announcement.deactivatedReason }}</p>
            </div>
        </form>

        <div class="container-footer" *ngIf="announcement.editing == false">
            <button class="button fill red" *ngIf="announcement.isActive" (click)="deactivate.open()" translate>終 止</button>
            <button class="button outline blue" style="margin-left: 10.25rem;" *ngIf="announcement.isActive && announcement.announceDate > now" (click)="announcement.editing = true" translate>編 輯</button>
        </div>


        <div class="container-footer" *ngIf="announcement.editing">
            <button class="button outline" (click)="announcement.editing = false;fetch()">取 消</button>
            <button class="button fill blue" style="margin-left: 10.25rem;" (click)="submit()">確 認</button>
        </div>

        <app-announcement-create-users-popup [users]="announcement.users" #users></app-announcement-create-users-popup>
    </div>
</div>

<ng-template #announcement_cancel>
    <div class="flex-column align-items-center mb-3">
        <i class="material-icons-round cross-icon mt-5 mb-3">add_circle</i>
        <p style="font-size: 1.5rem;line-height: 2rem;" translate>該記錄已終止</p>
    </div>
</ng-template>

<ng-template #status>
    <div class="flex-row" *ngIf="announcement">
        <div class="stage-container">
            <p class="stage blue" *ngIf="announcement.announceDate > now">1</p>
            <i class="material-icons-round stage blue" *ngIf="announcement.announceDate < now">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>等候發布</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>通告正在等待發布</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': announcement.announceDate < now}" *ngIf="announcement.expirationDate > now">2</p>
            <i class="material-icons-round stage blue" *ngIf="announcement.expirationDate < now">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已發布</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>通告已發布</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" *ngIf="announcement.expirationDate > now">3</p>
            <i class="material-icons-round stage blue" *ngIf="announcement.expirationDate < now">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已完結</p>
                </div>
                <p class="mt-2 text-lightgrey" translate>通告已過期</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #schedule>

</ng-template>

<app-announcement-deactivation-popup (refreshSignal)="fetch()" #deactivate></app-announcement-deactivation-popup>