import { Component, Input, OnInit } from '@angular/core';
import { Health } from 'src/app/models/healths/health';
import { HealthService } from 'src/app/services/health/health.service';

@Component({
  selector: 'app-health-popup',
  templateUrl: './health-popup.component.html',
  styleUrls: ['./health-popup.component.scss']
})
export class HealthPopupComponent implements OnInit {

  @Input() action: (healths: Health[]) => void = () => { }
  healths: Health[] = [];

  display: boolean = false;

  constructor(
    private healthService: HealthService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.healthService.getHealthConditionList()
      .subscribe({
        next: value => {
          this.healths = value
        }
      });
  }

  open(healths: Health[]): void {
    this.healths.filter(health => healths.some(h => h.uuId == health.uuId))
      .forEach(health => health.selected = true);
    this.display = true;
  }

  close(): void {
    this.display = false;
    this.reset();
  }

  confirm(): void {
    this.action(this.healths.filter(health => health.selected));
    this.close();
  }

  reset(): void {
    this.healths.forEach(health => health.selected = false);
  }
}
