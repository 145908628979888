<div class="filter" [ngClass]="{active: display, selected: start.value != null}" (click)="toggle()">
    <i class="material-icons-outlined filter-icon">date_range</i>
    <p translate>服務日期</p>
    <div class="filter-body center" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <input class="date-input user-input" type="text" [ngModel]="start.value | date: 'longDate': '': lang" (click)="picker.open()" placeholder="{{ '開始時間' | translate }}" readonly>
            <p class="mt-2 mb-2" translate>至</p>
            <input class="date-input user-input" type="text" [ngModel]="end.value | date: 'longDate': '': lang" (click)="picker.open()" placeholder="{{ '結束時間' | translate }}" readonly>

            <div class="inline-flex-row mt-4    ">
                <button class="button outline sm" (click)="clear()" translate>取消</button>
                <button class="button fill blue sm ml-4" (click)="toggle()" translate>確定</button>
            </div>

            <mat-date-range-input style="display: none;" [formGroup]="form" [rangePicker]="picker">
                <input matStartDate formControlName="start">
                <input matEndDate formControlName="end">
            </mat-date-range-input>
            <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        </div>
    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>