import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CellGroupData } from "src/app/models/analytics/cell-group-data";
import { DailyRequestCount } from "src/app/models/analytics/daily-request-count";
import { GetTransactionChartDataModel } from "src/app/models/analytics/get-transaction-chart-data-model";
import { ServiceRatio } from "src/app/models/analytics/service-ratio";
import { TransactionChartData } from "src/app/models/analytics/transaction-chart-data";
import { Profile, IProfile } from "src/app/models/profiles/profile";
import { BehaviorSubject, Observable } from "rxjs";
import * as dayjs from "dayjs";
import { DonationAnalysis } from "src/app/models/analytics/donation-analysis";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private httpService: HttpService) {}

  public balanceAnalysisDateRange = new BehaviorSubject<{
    startTime: string | undefined;
    endTime: string | undefined;
  }>({
    startTime: undefined,
    endTime: undefined,
  });

  public getBalanceAnalysisDateRange() {
    return this.balanceAnalysisDateRange.asObservable();
  }
  public setBalanceAnalysisDateRange(startTime?: string, endTime?: string) {
    this.balanceAnalysisDateRange.next({
      startTime: startTime,
      endTime: endTime,
    });
  }

  public getTotalTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/TotalTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getAverageTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/AverageTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRequestTotalTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Requests/TotalTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRequestAverageTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Requests/AverageTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getEventTotalTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Events/TotalTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getEventAverageTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Events/AverageTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRedeemTotalTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Redeem/TotalTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRedeemAverageTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Redeem/AverageTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getDonationTotalTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Donation/TotalTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getDonationAverageTimeBalance(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<number>("Reports/Donation/AverageTimeBalance" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getDailyRequestCount(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<DailyRequestCount[]> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";

    return this.httpService
      .get<DailyRequestCount[]>("Reports/CountRequestDaily" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRequestSuccessRate(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<number> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";

    return this.httpService
      .get<number>("Reports/RequestMatchedRate" + getvalue)
      .pipe(map((value) => value.result));
  }

  public GetTransactionChartData(
    model: GetTransactionChartDataModel,
  ): Observable<TransactionChartData> {
    return this.httpService
      .post<TransactionChartData, GetTransactionChartDataModel>(
        "Reports/GetTransactionsPeriod",
        model,
      )
      .pipe(map((value) => value.result));
  }

  public getInactiveUsers(
    centerUUId?: string,
    type?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<Profile[]> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    getvalue += type ? "&type=" + type : "";
    return this.httpService
      .get<IProfile[]>("Reports/InactiveUser" + getvalue)
      .pipe(
        map((value) => value.result.map((profile) => new Profile(profile))),
      );
  }
  public getCellGroupData(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
    type?: string,
    StateType?: string,
    ServiceUUId?: string,
  ): Observable<CellGroupData> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    getvalue += type ? "&type=" + type : "";
    getvalue += StateType ? "&StateType=" + StateType : "";
    getvalue += ServiceUUId ? "&ServiceUUId=" + ServiceUUId : "";

    return this.httpService
      .get<CellGroupData>("Reports/CellGroupData" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getServiceRatio(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
    type?: string,
    cellGroupsUUId?: string,
  ): Observable<ServiceRatio> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    getvalue += type ? "&type=" + type : "";
    getvalue += cellGroupsUUId ? "&cellGroupsUUId=" + cellGroupsUUId : "";

    return this.httpService
      .get<ServiceRatio>("Reports/ServiceRatio" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getRanking(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<{ index: string; value: number }[]> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<{ index: string; value: number }[]>("Reports/earnRanking" + getvalue)
      .pipe(map((value) => value.result));
  }

  public getTotalDonation(
    centerUUId?: string,
    startTime?: string,
    endTime?: string,
  ): Observable<DonationAnalysis> {
    var getvalue = "?a=1";
    getvalue += centerUUId ? "&centerUUId=" + centerUUId : "";
    getvalue += startTime ? "&startTime=" + dayjs(startTime).toJSON() : "";
    getvalue += endTime ? "&endTime=" + dayjs(endTime).toJSON() : "";
    return this.httpService
      .get<DonationAnalysis>("Reports/totalDonate" + getvalue)
      .pipe(map((value) => value.result));
  }
}
