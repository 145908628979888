import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { ConditionConflict } from "src/app/models/condition-conflicts/condition-conflict";

@Injectable({
  providedIn: "root",
})
export class ConditionConflictService {
  constructor(private httpService: HttpService) {}

  public getConditionConflictList(): Observable<ConditionConflict[]> {
    return this.httpService
      .get<ConditionConflict[]>("Settings/conflict")
      .pipe(map((value) => value.result));
  }

  public createConditionConflict(model: ConditionConflict[]): Observable<any> {
    return this.httpService.post<any, { list: ConditionConflict[] }>(
      "Settings/conflict",
      { list: model },
    );
  }

  public deleteConditionConflict(model: ConditionConflict[]): Observable<any> {
    return this.httpService.put<any, { list: ConditionConflict[] }>(
      "Settings/conflict",
      { list: model },
    );
  }
}
