<div class="inline-flex-column flex-1">
    <form class="inline-flex-column" style="width: 44.5rem;" [formGroup]="form.formGroup">
        <ng-container *ngIf="form.donor == null">
            <div class="input-row">
                <button class="button outline blue sm" type="button" routerLink="select-donor" translate>選擇會員</button>
            </div>
        </ng-container>

        <ng-container *ngIf="form.donor">
            <div class="input-row">
                <p class="text-lightgrey text-sm" translate>欄目帶 * 號為必填項</p>
            </div>
            <div class="input-row">
                <p class="title required" translate>會員姓名</p>
                <input class="user-input light md" type="text" [ngModel]="form.donor.chineseName" [ngModelOptions]="{standalone: true}" disabled>
                <input class="user-input light md" style="margin-left: 1.3125rem;" type="text" [ngModel]="form.donor.englishName" [ngModelOptions]="{standalone: true}" disabled>
            </div>

            <div class="input-row">
                <p class="title required" translate>會員編號</p>
                <input class="user-input light md" type="text" [ngModel]="form.donor.memberId" [ngModelOptions]="{standalone: true}" disabled>
                <button class="button outline blue sm" style="margin-left: 1.3125rem;" type="button" routerLink="select-donor" translate>重新指定</button>
            </div>
        </ng-container>

        <div style="border-bottom: 1px solid #979797;"></div>

        <div class="input-row" style="margin-top: 1.625rem;">
            <p class="title required" matTooltip="{{ '會員結餘' | translate }}" [matTooltipPosition]="'above'" translate>會員結餘</p>
            <p *ngIf="form.donor == null">-</p>
            <p *ngIf="form.donor">{{ form.donor.timeBalance }}</p>
        </div>

        <div class="input-row">
            <p class="title required" matTooltip="{{ '捐出時分' | translate }}" [matTooltipPosition]="'above'" translate>捐出時分</p>
            <div class="inline-flex-row align-items-center">
                <p translate>將從會員帳戶中支出</p>
                <input class="user-input xxxs ml-2 mr-2" type="number" formControlName="amount">
                <p translate>時分 至 中心</p>
            </div>
        </div>

        <div class="input-row">
            <p class="title align-self-start" style="line-height: 2rem;" translate>備註</p>
            <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
        </div>
    </form>

    <ng-container *ngIf="form.donor == null">
        <div class="inline-flex-row justify-content-center" style="margin-top: 2rem;margin-bottom: 3rem;">
            <div class="inline-flex-column align-items-center">
                <button class="button fill blue md" disabled translate>提 交</button>
                <p class="text-lightgrey text-sm mt-4" translate>有必填欄位未完成 / 資料格式出錯</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="form.donor">
        <div class="inline-flex-row justify-content-center" style="margin-top: 2rem;margin-bottom: 3rem;">
            <button class="button outline" type="button" (click)="form.reset()" translate>取消創建</button>
            <button class="button fill blue" type="button" style="margin-left: 9.375rem;" (click)="submit()" translate>提 交</button>
        </div>
    </ng-container>
</div>