import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IProfile, Profile } from "../profiles/profile";
import { UpdateAnnouncementModel } from "./update-announcement-model";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IAnnouncement {
  id: number;
  uuId: string;
  title: string;
  content: string;
  url: string;
  type: string;
  announceDate: Date;
  expirationDate: Date;
  isPinned: boolean;
  isActive: boolean;
  remark: string;
  users: IProfile[];
  createdBy: string;
  createdAt: Date;
  lastModifiedBy: string;
  lastModifiedAt: Date;
  deactivatedReason: string;
}

export class Announcement {
  id: number;
  uuId: string;
  title: string;
  content: string;
  url: string;
  type: string;
  announceDate: Date;
  expirationDate: Date;
  isPinned: boolean;
  isActive: boolean;
  remark: string;
  users: Profile[];
  createdBy: string;
  createdAt: Date;
  lastModifiedBy: string;
  lastModifiedAt: Date;
  deactivatedReason: string;

  editing: boolean;

  form: FormGroup;

  constructor(announcement: IAnnouncement) {
    this.id = announcement.id;
    this.uuId = announcement.uuId;
    this.title = announcement.title;
    this.content = announcement.content;
    this.url = announcement.url;
    this.type = announcement.type;
    this.announceDate = dayjs.utc(announcement.announceDate).toDate();
    this.expirationDate = dayjs.utc(announcement.expirationDate).toDate();
    this.isPinned = announcement.isPinned;
    this.isActive = announcement.isActive;
    this.remark = announcement.remark;
    this.users = announcement.users.map((user) => new Profile(user));
    this.createdBy = announcement.createdBy;
    this.createdAt = dayjs.utc(announcement.createdAt).toDate();
    this.lastModifiedBy = announcement.lastModifiedBy;
    this.lastModifiedAt = dayjs.utc(announcement.lastModifiedAt).toDate();
    this.deactivatedReason = announcement.deactivatedReason;
    this.editing = false;

    this.form = new FormGroup({
      title: new FormControl(this.title, [Validators.required]),
      content: new FormControl(this.content),
      type: new FormControl(this.type, [Validators.required]),
      url:
        this.type == "Text"
          ? new FormControl(null)
          : new FormControl(announcement.url, [Validators.required]),
      announceDate: new FormControl(this.announceDate, [Validators.required]),
      expireDate: new FormControl(this.expirationDate, [Validators.required]),
      remark: new FormControl(this.remark),
    });
  }

  public enableURL(): void {
    if (this.form.controls["type"].value == "Text") {
      this.form.controls["url"].setValidators([Validators.nullValidator]);
      this.form.controls["url"].reset();
    } else {
      this.form.controls["url"].setValidators([Validators.required]);
      this.form.controls["url"].reset();
    }
  }

  getUpdateAnnouncementModel(): UpdateAnnouncementModel {
    return {
      announcementUUId: this.uuId,
      title: this.form.controls["title"].value,
      content: this.form.controls["content"].value,
      type: this.form.controls["type"].value,
      url: this.form.controls["url"].value,
      announceDate: this.form.controls["announceDate"].value,
      expirationDate: this.form.controls["expireDate"].value,
      remark: this.form.controls["remark"].value,
    };
  }

  get invalidExpireDate(): boolean {
    return (
      (this.form.controls["expireDate"].value as Date) <
      (this.form.controls["announceDate"].value as Date)
    );
  }
}
