import { EventEmitter, Injectable } from "@angular/core";
import { mergeMapTo } from "rxjs/operators";
import { AngularFireMessaging } from "@angular/fire/messaging";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private WebDeviceToken: string | null = null;

  public WebMessageSignal: EventEmitter<string> = new EventEmitter();

  constructor(private afMessaging: AngularFireMessaging) {}

  public requestPermission(): void {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe({
        next: (token) => {
          console.log(token);
          this.WebDeviceToken = token;
          this.subscribeMessaging();
        },
        error: (error) => {
          {
            console.error(error);
          }
        },
      });
  }

  public getToken(): string | null {
    return this.WebDeviceToken;
  }

  private subscribeMessaging(): void {
    this.afMessaging.onMessage({
      next: (message) => {
        console.log("On Message");
        console.log(message);
      },
      error: () => {},
      complete: () => {},
    });

    this.afMessaging.messages.subscribe((message: any) => {
      console.log(message);
      this.WebMessageSignal.emit(
        message.notification.title + ": " + message.notification.body,
      );
    });
  }
}
