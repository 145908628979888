import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

@Component({
  selector: "app-success-rate-analysis",
  templateUrl: "./success-rate-analysis.component.html",
  styleUrls: ["./success-rate-analysis.component.scss"],
})
export class SuccessRateAnalysisComponent implements OnInit {
  successRate: number = 0;

  @Input() form!: FormControl;

  centerUUId?: string;
  startTime?: string;
  endTime?: string;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });

    this.analyticsService.getBalanceAnalysisDateRange().subscribe((value) => {
      this.startTime = value.startTime;
      this.endTime = value.endTime;
      this.fetch();
    });
  }

  fetch(): void {
    this.analyticsService
      .getRequestSuccessRate(this.centerUUId, this.startTime, this.endTime)
      .subscribe((value) => {
        this.successRate = Math.round(value * 100) || 0;
      });
  }
}
