import { Route } from '@angular/compiler/src/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SignUpService } from 'src/app/services/sign-up/sign-up.service';

@Component({
  selector: 'app-sign-up-disapprove-popup',
  templateUrl: './sign-up-disapprove-popup.component.html',
  styleUrls: ['./sign-up-disapprove-popup.component.scss']
})
export class SignUpDisapprovePopupComponent implements OnInit {

  @Output() signal: EventEmitter<any> = new EventEmitter<any>();

  display: boolean = false;
  form: FormControl = new FormControl(null);

  constructor(
    private loadingService: LoadingService,
    private signUpService: SignUpService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  open(): void {
    this.display = true;

  }

  close(): void {
    this.display = false;
  }

  submit(): void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.signUpService.disapprove({
        UserUUId: value.id,
        Reason: this.form.value
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.signal.emit();
          window.scrollTo(0, 0);
          this.close();
        });
    });
  }
}
