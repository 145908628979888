<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="living-box zoomIn" (click)="$event.stopPropagation()">

        <div class="inline-flex-row align-items-center">
            <p class="flex-1" translate>居住條件限制</p>
            <p class="click-text-blue" (click)="reset()" translate>重置</p>
        </div>

        <div class="living-body mt-4">
            <div class="living-type-column">
                <div class="living-type-item mb-2" [ngClass]="{active: index == i}" *ngFor="let type of types; let i = index" (click)="index = i">
                    <p>{{ type.name | translate }}</p>
                    <p>{{ numberOfLivingOfType(type.type) }}</p>
                </div>
                <div class="inline-flex-row ml-4 mt-4">
                    <i class="material-icons-outlined info-icon mr-2">error_outline</i>
                    <p class="text-xs text-lightgrey" style="white-space: pre-line;" translate>請根據居住條件實際情況去選擇所屬類型以提高住宅條件自動顯示準確率</p>
                </div>
            </div>


            <div class="living-column">
                <div class="living-item mb-2" *ngFor="let living of livingsOfSelectedType">
                    <label>
                        <input class="mr-4" type="checkbox" [(ngModel)]="living.selected">
                        <p class="name">{{ living.name }}</p>
                    </label>
                </div>
            </div>
        </div>

        <div class="footer">
            <button class="button outline" style="margin-right: 7.5rem;" (click)="close()" translate>返 回</button>
            <button class="button fill blue" (click)="submit()" translate>確 認</button>
        </div>
    </div>
</div>