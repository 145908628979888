import { RequestVolunteerImportModel } from "../imports/request-import-model";

export interface CreateRequestModel {
    originalRequestUUId: string | null;
    addressUUId: string;
    destinationUUId: string;
    service: string;
    startTime: string;
    endTime: string;
    remark: string;
    numberOfVolunteer: number;
    transportationUUId: string;
    extraRequestJson: string | null;
}

export class RequestRecordModel{
    
    requesterUUId: string | undefined;
    addressUUId: string;
    destinationUUId: string;
    serviceUUId: string;
    startTime: string | null;
    endTime: string | null;
    remark: string;
    volunteers: RequestVolunteerImportModel[];
    numberOfVolunteer: number;
    transportationUUId: string;
    extraRequestJson: string | null;

    constructor(requesterUUId:string,
                addressUUId:string,
                destinationUUId: string,
                serviceUUId: string,
                startTime: string ,
                endTime: string ,
                remark: string,
                volunteers: RequestVolunteerImportModel[],
                numberOfVolunteer: number,
                transportationUUId: string,
                extraRequestJson: string){
                    this.requesterUUId=requesterUUId;
                    this.addressUUId=addressUUId;
                    this.destinationUUId=destinationUUId;
                    this.serviceUUId=serviceUUId;
                    this.startTime=startTime;
                    this.endTime=endTime;
                    this.remark=remark;
                    this.volunteers=volunteers;
                    this.numberOfVolunteer=numberOfVolunteer;
                    this.transportationUUId=transportationUUId;
                    this.extraRequestJson=extraRequestJson;
    }
}