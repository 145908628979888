<div class="analytics-container">
  <div class="inline-flex-row justify-content-end border-bottom" style="padding: 0.5rem 1rem;">
    <div class="inline-flex-row mr-4 endinline" style="position:relative">
      <input class="user-input xl ml-4" [ngModel]="dateRange" (click)="picker.open()" placeholder="{{placeholder}}"
        readonly>
      <ng-container *ngTemplateOutlet="datepicker"></ng-container>
      <mat-date-range-picker touchUi #picker></mat-date-range-picker>
      <mat-date-range-input style="display: none;" [rangePicker]="picker">
        <input matStartDate [formControl]="start">
        <input matEndDate [formControl]="end" (dateInput)="fetch()">
      </mat-date-range-input>
    </div>

    <ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24"
        width="24px" height="24px" fill="#DADADA" focusable="false"
        class="mat-datepicker-toggle-default-icon ng-star-inserted">
        <path
          d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
        </path>
      </svg></ng-template>
    <i matTooltip="{{ '總交易時分 - 義工服務和中心活動加起來的總交易時分。' | translate }}" [matTooltipPosition]="'above'"
      class="material-icons-outlined text-lightgrey" style="font-size: 1rem">info</i>
  </div>

  <div class="inline-flex-row flex-1">
    <div class="inline-flex-column align-items-center justify-content-center flex-1">
      <p style="font-size: 1rem;line-height: 1.25rem;" translate>總交易時分</p>
      <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ totalTimeBalance | number : '1.0-0'}}</p>
      <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: averageTimeBalance | number : '1.0-2'} }}
      </p>
    </div>
    <div style="border-right: 1px solid #E8E8E8;"></div>
    <div class="inline-flex-column flex-1">
      <div class="inline-flex-column justify-content-center align-items-center flex-1 border-bottom border-right">
        <p style="font-size: 1rem;" translate>義工服務總交易時分</p>
        <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ requestTotalTimeBalance | number : '1.0-0' }}</p>
        <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: requestAverageTimeBalance | number :
          '1.0-2'} }}</p>
      </div>
      <div class="inline-flex-column justify-content-center align-items-center flex-1 border-right">
        <p style="font-size: 1rem;" translate>獎勵兑換時分</p>
        <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ redeemTotalTimeBalance | number : '1.0-0' }}</p>
        <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: redeemAverageTimeBalance | number :
          '1.0-2'} }}</p>
      </div>
    </div>
    <div class="inline-flex-column flex-1">
      <div class="inline-flex-column justify-content-center align-items-center flex-1 border-bottom">
        <p style="font-size: 1rem;" translate>中心活動總交易時分</p>
        <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ eventTotalTimeBalance | number : '1.0-0' }}</p>
        <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: eventAverageTimeBalance | number :
          '1.0-2'} }}</p>
      </div>
      <div class="inline-flex-column justify-content-center align-items-center flex-1">
        <p style="font-size: 1rem;" translate>捐分交易時分</p>
        <p style="font-size: 1.5rem;line-height: 2.5rem;">{{ donationTotalTimeBalance | number : '1.0-0' }}</p>
        <p class="text-sm mt-4 text-lightgrey">{{ '平均交易時分' | translate: {value: donationAverageTimeBalance | number :
          '1.0-2'} }}</p>
      </div>
    </div>
  </div>
</div>