import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";

@Component({
  selector: "app-redeem-question",
  templateUrl: "./redeem-question.component.html",
  styleUrls: ["./redeem-question.component.scss"],
})
export class RedeemQuestionComponent implements OnInit {
  @Input() question: FormGroup = new FormGroup({});
  @Input() index: number = 0;
  @Input() delete: (index: number) => void = () => {};

  constructor() {}

  ngOnInit(): void {}

  addOption(): void {
    (this.question.controls["options"] as FormArray).push(
      new FormControl(null, [Validators.required]),
    );
  }

  removeOption(index: number): void {
    (this.question.controls["options"] as FormArray).removeAt(index);

    this.question.markAsDirty();
  }

  get questionInput(): AbstractControl {
    return this.question.controls["question"];
  }

  get setting(): AbstractControl {
    return this.question.controls["setting"];
  }

  get options(): FormArray {
    return this.question?.controls["options"] as FormArray;
  }

  duplicateCheck(control: FormControl, index: number): void {
    if (
      (
        (this.question.controls["options"] as FormArray).value as string[]
      ).indexOf(control.value) < index
    ) {
      control.setErrors({ duplicated: true });
    }
  }
}
