import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent implements OnInit {
  @Input() form: FormControl = new FormControl(null);
  @Input() selfGenerateDate: boolean = false;

  hour: string | null = null;
  minute: string | null = null;

  constructor() {}

  ngOnInit(): void {
    this.init();

    this.form.valueChanges.subscribe(() => {
      if (this.form.disabled) return;

      if (this.form.value == null) {
        this.form.markAllAsTouched();
        return;
      }

      if (this.hour != null && this.minute != null) {
        let date = this.form.value as Date;
        date.setHours(parseInt(this.hour));
        date.setMinutes(parseInt(this.minute));
        this.form.setErrors(null);
      } else {
        this.form.markAllAsTouched();
        this.form.setErrors({ time: true });
      }
    });
  }

  init(): void {
    if (this.form.value == null) return;

    let hour = (this.form.value as Date).getHours();
    if (Number.isNaN(hour) == false) {
      if (hour < 10) {
        this.hour = "0" + hour.toString();
      } else {
        this.hour = hour.toString();
      }
    }

    let minute = (this.form.value as Date).getMinutes();
    if (Number.isNaN(minute) == false) {
      if (minute < 10) {
        this.minute = "0" + minute.toString();
      } else {
        this.minute = minute.toString();
      }
    }
  }

  onHourChange(): void {
    if (this.hour == null || this.hour == "") {
      this.hour = "00";
      return;
    }

    let hour = parseInt(this.hour);
    if (Number.isNaN(hour) == false) {
      if (hour >= 24) {
        hour = 0;
      }

      if (hour < 10) {
        this.hour = "0" + hour.toString();
      } else {
        this.hour = hour.toString();
      }

      if (this.minute == null) this.minute = "00";
      return;
    }
  }

  addHour(): void {
    if (this.hour == null) {
      this.hour = "01";
      return;
    }
    let hour = parseInt(this.hour);
    hour += 1;
    if (hour >= 24) {
      hour = 0;
    }
    if (hour < 10) {
      this.hour = "0" + hour.toString();
      return;
    }
    this.hour = hour.toString();
  }

  minusHour(): void {
    if (this.hour == null) {
      this.hour = "23";
      return;
    }

    let hour = parseInt(this.hour);
    hour -= 1;
    if (hour < 0) {
      hour = 23;
    }

    if (hour < 10) {
      this.hour = "0" + hour.toString();
      return;
    }
    this.hour = hour.toString();
  }

  onMinuteChange(): void {
    if (this.minute == null || this.minute == "") {
      this.minute = "00";
      return;
    }

    let minute = parseInt(this.minute);
    if (Number.isNaN(minute) == false) {
      if (minute >= 60) {
        minute = 0;
      }

      if (minute < 10) {
        this.minute = "0" + minute.toString();
        return;
      }
      this.minute = minute.toString();
    }
  }

  addMinute(): void {
    if (this.minute == null) {
      this.minute = "01";
      return;
    }
    let minute = parseInt(this.minute);
    minute += 1;
    if (minute >= 60) {
      minute = 0;
    }
    if (minute < 10) {
      this.minute = "0" + minute.toString();
      return;
    }
    this.minute = minute.toString();
  }

  minusMinute(): void {
    if (this.minute == null) {
      this.minute = "59";
      return;
    }

    let minute = parseInt(this.minute);
    minute -= 1;
    if (minute < 0) {
      minute = 59;
    }

    if (minute < 10) {
      this.minute = "0" + minute.toString();
      return;
    }
    this.minute = minute.toString();
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.value == null) {
      if (this.selfGenerateDate == false) return;

      this.form.setValue(new Date());
    }

    if (this.hour != null && this.minute != null) {
      let date = this.form.value as Date;
      date.setHours(parseInt(this.hour));
      date.setMinutes(parseInt(this.minute));
      this.form.setErrors(null);
      this.form.updateValueAndValidity();
    } else {
      this.form.setErrors({ time: true });
    }
  }

  get timeText(): string | null {
    if (this.hour == null || this.minute == null) return null;
    return `${this.hour}:${this.minute}`;
  }
}
