import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CreateUserDonationModel } from "src/app/models/donations/create-user-donation-model";
import { Profile } from "src/app/models/profiles/profile";

export class CreateUserDonationForm {
    donor: Profile | null;
    formGroup: FormGroup;

    constructor() {
        this.donor = null;
        this.formGroup = new FormGroup({
            amount: new FormControl(null, [Validators.required, Validators.pattern("([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)")]),
            remark: new FormControl(null)
        });
    }

    getCreateUserDonationModel(): CreateUserDonationModel {
        return {
            userUUId: this.donor?.uuId as string,
            amount: this.formGroup.controls['amount'].value,
            remark: this.formGroup.controls['remark'].value
        };
    }

    reset(): void {
        this.donor = null;
        this.formGroup.reset();
    }
}