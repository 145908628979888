import { EventType, IEventType } from "../event-types/event-type";
import { EventExportModel } from "../exports/event-export-model";
import { ITransaction } from "../transactions/transaction";
import { EventDay, IEventDay } from "./event-day";
import { EventParticipant } from "./event-participant";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IEventListItem {
  id: number;
  uuId: string;
  eventName: string;
  eventType: IEventType;
  eventJoinType: string;
  eventStatus: string;
  eventDays: IEventDay[];
  eventPay: string;
  startTime: Date;
  participantVacancy: number;
  participants: IEventListItemParticipant[];
  deleteDate: Date;
  earning: string;
  remark: string;
  applyDeadline: Date;
  isCancelled: boolean;
  extraEventJson: any;
  transactions: ITransaction[];
  numberOfParticipants: number;
  applyUserNum: number;
  lastApplyTime: Date | null;
}

export class EventListItem {
  id: number;
  uuId: string;
  eventName: string;
  eventType: EventType;
  eventJoinType: string;
  eventStatus: string;
  eventDays: EventDay[];
  eventPay: string;
  startTime: Date;
  participantVacancy: number;
  participants: IEventListItemParticipant[];
  deleteDate: Date | null;
  earning: string;
  remark: string;
  applyDeadline: Date;
  isCancelled: boolean;
  extraEventJson: any;
  transactions: ITransaction[];

  selected: boolean;
  NumberOfParticipants: number;

  applyUserNum: number;
  lastApplyTime: Date | null;
  constructor(event: IEventListItem) {
    this.id = event.id;
    this.uuId = event.uuId;
    this.eventName = event.eventName;
    this.eventType = new EventType(event.eventType);
    this.eventJoinType = event.eventJoinType;
    this.eventStatus = event.eventStatus;
    this.eventDays = event.eventDays.map((eventDay) => new EventDay(eventDay));
    this.eventPay = event.eventPay;
    this.startTime = dayjs.utc(event.startTime).toDate();
    this.participantVacancy = event.participantVacancy;
    this.participants = event.participants;
    this.deleteDate = event.deleteDate
      ? dayjs.utc(event.deleteDate).toDate()
      : null;
    this.selected = false;
    this.earning = event.earning;
    this.remark = event.remark;
    this.applyDeadline = dayjs.utc(event.applyDeadline).toDate();
    this.extraEventJson = event.extraEventJson
      ? JSON.parse(event.extraEventJson)
      : null;
    this.isCancelled = event.isCancelled;
    this.transactions = event.transactions;
    this.NumberOfParticipants = event.numberOfParticipants;
    this.applyUserNum = event.applyUserNum;
    this.lastApplyTime = event.lastApplyTime
      ? dayjs.utc(event.lastApplyTime).toDate()
      : null;
  }

  get isOngoing(): boolean {
    return this.startTime <= new Date();
  }

  get numberOfParticipants(): number {
    return this.NumberOfParticipants;
  }
  get IsNotEnoughParticipants(): boolean {
    let offset = Number(localStorage.getItem("EventNotification"));
    let date = dayjs().add(offset, "minutes").toDate();

    return (
      this.eventStatus === "Open" &&
      this.numberOfParticipants < this.participantVacancy &&
      this.applyDeadline < date
    );
  }

  public getEventStartDate(): Date | null {
    return this.eventDays.length > 0
      ? this.eventDays.sort((a, b) =>
          a.date > b.date ? 1 : a.date < b.date ? -1 : 0,
        )[0].startTime
      : null;
  }

  public getEventEndDate(): Date | null {
    if (this.eventDays.length == 0) return null;
    let date = this.eventDays.sort((a, b) =>
      a.date > b.date ? -1 : a.date < b.date ? 1 : 0,
    )[0].endTime;
    if (date == null) return this.getEventStartDate();
    return date;
  }

  /** Return remaining date before deleted permanently */
  get deletedDays(): number {
    return Math.ceil(dayjs().diff(this.deleteDate, "days", true));
  }

  getEventExportModel(): EventExportModel {
    const date: Date = new Date();

    var eventParticipants = this.participants.map((p) => ({
      participants: p,
      transactions: this.transactions.filter(
        (t) =>
          (t.transferer != null && t.transferer.uuId == p.uuId) ||
          (t.transferee != null && t.transferee.uuId == p.uuId),
      ),
    }));
    if (this.uuId == "b25a2e84-f1f0-4732-af5b-2a1b136fa184") {
      console.log(this.participants, this.transactions, eventParticipants);
    }
    var amount =
      this.transactions.map((t) => t.amount).length == 0
        ? 0
        : this.transactions
            .map((t) => t.amount)
            .reduce((a, b) => {
              return a + b;
            });
    var model: any = {
      紀錄編號: this.id.toString(),
      活動名目: this.eventName,
      活動類型: `${this.eventType.code} ${this.eventType.name}`,
      交易類型:
        this.eventPay == "Free"
          ? "免費活動"
          : this.eventPay == "UserEarn"
            ? "會員加分"
            : "會員扣分",
      參加類型: this.eventJoinType == "Staff" ? "職員處理" : "先到先得",
      "活動日期（YYYY-MM-DD）": toDateString(this.getEventStartDate()),
      "截止報名時間（YYYY-MM-DD HH：mm）": dayjs(this.applyDeadline).format(
        "YYYY-MM-DD HH:mm",
      ),
      "預計開始時間（HH：mm）": toTimeString(this.getEventStartDate()),
      "預計結束時間（HH：mm）": toTimeString(this.getEventEndDate()),
      可參加人數: this.participantVacancy,
      實際參加人數: eventParticipants
        .filter(
          (ep) =>
            ep.participants.state == "Confirmed" || ep.transactions.length > 0,
        )
        .map((ep) => ep.participants.memberId).length,
      預計參加的每位會員交易時分:
        this.eventPay == "Free"
          ? "0"
          : `${this.eventPay == "UserEarn" ? "+" : "-"}${this.earning}`,
      總交易時分: this.eventPay == "UserSpend" ? -amount : amount,
      活動狀態: this.isCancelled
        ? "已終止"
        : this.getEventStartDate()
          ? (this.getEventStartDate() as Date) > date
            ? "等候報名"
            : (this.getEventEndDate() as Date) < date &&
                this.eventStatus == "Complete"
              ? "已完結"
              : "進行中"
          : "等候報名",
      備註: "",
      參加會員編號:
        (this.getEventEndDate() as Date) < date ||
        this.eventStatus == "Complete"
          ? eventParticipants
              .filter(
                (ep) =>
                  ep.participants.state == "Confirmed" ||
                  ep.transactions.length > 0,
              )
              .map((ep) => ep.participants.memberId)
              .join(",")
          : "",
      參加會員:
        (this.getEventEndDate() as Date) < date ||
        this.eventStatus == "Complete"
          ? eventParticipants
              .filter(
                (ep) =>
                  ep.participants.state == "Confirmed" ||
                  ep.transactions.length > 0,
              )
              .map((ep) => ep.participants.chineseName)
              .join(",")
          : "",
    };

    if (this.extraEventJson != null)
      Object.keys(this.extraEventJson).forEach((key) => {
        model[key] = this.extraEventJson[key];
      });

    return model;
  }
}

function toDateString(date: Date | null) {
  if (date == null) {
    return "Missing Date";
  }
  return dayjs(date).format("YYYY-MM-DD");
}

function toTimeString(date: Date | null) {
  if (date == null) {
    return "";
  }
  return dayjs(date).format("HH:mm");
}

export interface IEventListItemParticipant {
  uuId: string;
  chineseName: string;
  memberId: string;
  state: string;
}
