import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { Product, IProduct } from "src/app/models/redeems/product";
import {
  ProductListItem,
  IProductListItem,
} from "src/app/models/redeems/product-list-item";
import { CreateProductForm } from "src/app/modules/redeem/forms/create-product-form";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  form: CreateProductForm = new CreateProductForm();

  constructor(private httpService: HttpService) {}

  public getProduct(productUUId: string): Observable<Product> {
    return this.httpService
      .get<IProduct>("redeem/admin/products?UUId=" + productUUId)
      .pipe(map((response) => new Product(response.result)));
  }

  public getProductImage(imagePath: string): Observable<Blob> {
    return this.httpService.getImage(
      "redeem/products/image?imagePath=" + imagePath,
    );
  }

  public getRedeemList(
    queryString: string,
  ): Observable<{ list: ProductListItem[]; count: number }> {
    return this.httpService
      .get<{ list: IProductListItem[]; count: number }>(
        "redeem/admin/products/list" + queryString,
      )
      .pipe(
        map((response) => {
          return {
            list: response.result.list.map(
              (redeem) => new ProductListItem(redeem),
            ),
            count: response.result.count,
          };
        }),
      );
  }

  public restock(
    productUUId: string,
    quantity: number,
    maxQuantity: number,
    closeDate: string,
  ) {
    return this.httpService.post<
      Observable<any>,
      {
        ProductUUId: string;
        StockQuantity: number;
        MaximumQuantityPerPerson: number;
        CloseDate: string;
      }
    >("redeem/admin/products/restock", {
      ProductUUId: productUUId,
      StockQuantity: quantity,
      MaximumQuantityPerPerson: maxQuantity,
      CloseDate: closeDate,
    });
  }

  public createProduct(model: FormData): Observable<string> {
    return this.httpService
      .upload("redeem/admin/products", model)
      .pipe(map((result) => result.result));
  }

  public deleteProduct(productUUId: string): Observable<any> {
    return this.httpService.delete<Observable<any>>(
      "redeem/admin/products?productUUId=" + productUUId,
    );
  }

  public getRules(): Observable<{ title: string; rule: string }> {
    return this.httpService
      .get<{ title: string; rule: string }>("redeem/products/rule")
      .pipe(map((result) => result.result));
  }

  public setRules(title: string, rule: string): Observable<any> {
    return this.httpService.post<any, { title: string; rule: string }>(
      "redeem/admin/products/rule",
      { title: title, rule: rule },
    );
  }

  public disableProduct(productUUId: string): Observable<any> {
    return this.httpService.post<Observable<any>, { ProductUUId: string }>(
      "redeem/admin/products/disable",
      { ProductUUId: productUUId },
    );
  }

  public updateProduct(model: FormData): Observable<any> {
    return this.httpService.update("redeem/admin/products", model);
  }
}
