import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Center } from 'src/app/models/centers/center';
import { Profile } from 'src/app/models/profiles/profile';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-announcement-create-users-popup',
  templateUrl: './announcement-create-users-popup.component.html',
  styleUrls: ['./announcement-create-users-popup.component.scss']
})
export class AnnouncementCreateUsersPopupComponent implements OnInit {

  @Input() users: Profile[] = this.announcementService.form.users;
  filters: any = {
    page: 1,
    itemsPerPage: 20,
    centers: [],
    filterInput: null
  };

  display: boolean = false;

  constructor(
    private announcementService: AnnouncementService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
  }

  searchAction: (value: string) => void = ((value: string) => {
    this.filters.filterInput = value;
    this.filters.page = 1;
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
  }).bind(this);

  close(): void {
    this.display = false;
  }

  open(): void {
    this.display = true;
  }

  dropUser(user: Profile): void {
    this.route.params.subscribe(value => {
      if (value.id == null)
        this.users.splice(this.users.findIndex(u => u.uuId == user.uuId), 1);
      else {
        this.loadingService.startLoading();
        this.announcementService.removeRecipient(value.id, user.uuId)
          .pipe(finalize(() => this.loadingService.stopLoading()))
          .subscribe({
            next: () => {
              this.users.splice(this.users.findIndex(u => u.uuId == user.uuId), 1);
            }
          });
      }
    });
  }

  get pages(): number {
    return Math.ceil(this.filteredUsers.length / this.filters.itemsPerPage);
  }

  get filteredUsers(): Profile[] {
    return this.users.filter(user =>
      (this.filters.centers.length == 0 || this.filters.centers.some((center: Center) => center.uuId == user.center.uuId))
      && (this.filters.filterInput == null || this.filters.filterInput.length == 0
        || user.memberId.toLowerCase().includes(this.filters.filterInput.toLowerCase())
        || user.chineseName.toLowerCase().includes(this.filters.filterInput.toLowerCase())));
  }

  get displayedUsers(): Profile[] {
    return this.filteredUsers
      .slice((this.filters.page - 1) * this.filters.itemsPerPage, this.filters.page * this.filters.itemsPerPage);
  }
}
