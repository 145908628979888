import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateHealthModel } from "./update-health-model";

export interface IHealth {
  uuId: string;
  name: string;
  isShowInRequest: boolean;
}

export class Health {
  uuId: string;
  name: string;
  isShowInRequest: boolean;

  selected: boolean;
  editing: boolean;
  form: FormGroup;

  constructor(health: IHealth) {
    this.uuId = health.uuId;
    this.name = health.name;
    this.isShowInRequest = health.isShowInRequest;
    this.selected = false;
    this.editing = false;
    this.form = new FormGroup({
      name: new FormControl(health.name, [Validators.required]),
      isShowInRequest: new FormControl(health.isShowInRequest),
    });
  }

  getUpdateHealthModel(): UpdateHealthModel {
    return {
      uuId: this.uuId,
      name: this.form.controls["name"].value,
      isShowInRequest: this.form.controls["isShowInRequest"].value,
    };
  }
}
