import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { AppSetting } from "src/app/backend/app-setting";
import { PopupMessages } from "src/app/backend/popup-message";
import { Transaction } from "src/app/models/transactions/transaction";
import { DonationService } from "src/app/services/donation/donation.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { TransactionService } from "src/app/services/transaction/transaction.service";

@Component({
  selector: "app-donation-trash-bin",
  templateUrl: "./donation-trash-bin.component.html",
  styleUrls: ["./donation-trash-bin.component.scss"],
})
export class DonationTrashBinComponent implements OnInit {
  centerUUId: string = AppSetting.centerUUId;

  donations: Transaction[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    types: [],
    idOrder: false,
    dateOrder: null,
    start: null,
    end: null,
  };

  constructor(
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private transactionService: TransactionService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.transactionService
      .getDeletedTransactions()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) =>
          (this.donations = value.filter(
            (value) =>
              value.type == "Donation" || value.type == "RequestDonation",
          )),
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
  }).bind(this);

  dateRangeFilterAction: (start: Date, end: Date) => void = (
    start: Date,
    end: Date,
  ) => {
    this.filters.start = start;
    this.filters.end = end;
    this.filters.page = 1;
  };

  typeFilterAction: (types: string[]) => void = ((types: string[]) => {
    this.filters.types = types;
    this.filters.page = 1;
  }).bind(this);

  get filteredDonations(): Transaction[] {
    let results = this.donations.filter(
      (donation) =>
        (this.filters.searchInput == null ||
          donation.id.toString().includes(this.filters.searchInput) ||
          donation.transferer.memberId.includes(this.filters.searchInput) ||
          donation.transferee.memberId.includes(this.filters.searchInput)) &&
        (this.filters.start == null ||
          this.filters.end == null ||
          (donation.date > this.filters.start &&
            donation.date < this.filters.end)) &&
        (this.filters.types.length == 0 ||
          ((this.filters.types as string[]).includes("User") &&
            donation.type == "Donation" &&
            donation.transferee.uuId == this.centerUUId) ||
          ((this.filters.types as string[]).includes("Center") &&
            donation.type == "Donation" &&
            donation.transferer.uuId == this.centerUUId) ||
          ((this.filters.types as string[]).includes("RequestDonation") &&
            donation.type == "RequestDonation")),
    );

    if (this.filters.idOrder !== null)
      if (this.filters.idOrder === true)
        return results.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
      else
        return results.sort((b, a) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));

    if (this.filters.dateOrder !== null)
      if (this.filters.dateOrder === true)
        return results.sort((a, b) =>
          a.date > b.date ? 1 : a.date < b.date ? -1 : 0,
        );
      else
        return results.sort((b, a) =>
          a.date > b.date ? 1 : a.date < b.date ? -1 : 0,
        );

    return results;
  }

  get displayedDonations(): Transaction[] {
    return this.filteredDonations.slice(
      (this.filters.page - 1) * this.filters.itemsPerPage,
      this.filters.page * this.filters.itemsPerPage,
    );
  }

  get pages(): number {
    return Math.ceil(this.filteredDonations.length / this.filters.itemsPerPage);
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.displayedDonations.forEach(
        (donation) => (donation.selected = false),
      );
    else
      this.displayedDonations.forEach((donation) => (donation.selected = true));
  }

  get isSelectedAll(): boolean {
    return this.displayedDonations.every((donation) => donation.selected);
  }

  get selectedDonations(): Transaction[] {
    return this.donations.filter((donation) => donation.selected == true);
  }

  restoreDonation(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.RestoreMessage(
        this.transactionService.restoreTransactions([uuId]),
      ),
    );
  }

  restoreDonations(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.RestoreListMessage(
        this.transactionService.restoreTransactions(
          this.selectedDonations.map((donation) => donation.uuId),
        ),
      ),
    );
  }

  deleteDonationPermanently(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeletePermanentlyMessage(
        this.transactionService.deleteTransactionsPermanently([uuId]),
      ),
    );
  }

  deleteDonationListPermanently(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteListPermanentlyMessage(
        this.transactionService.deleteTransactionsPermanently(
          this.selectedDonations.map((donation) => donation.uuId),
        ),
      ),
    );
  }

  deleteAllPermanently(): void {
    this.loadingService.startLoading();
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteListPermanentlyMessage(
        this.transactionService.deleteTransactionsPermanently(
          this.donations.map((donation) => donation.uuId),
        ),
      ),
    );
  }
}
