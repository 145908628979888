<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>會員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>會員概覽</p>
    </div>

    <p class="page-title mt-8" translate>會員概覽</p>

    <div class="container mt-5">
        <div class="container-body">

            <div class="inline-flex-row align-items-center">
                <app-search-input class="flex-1" [placeholder]="'請輸入姓名 或 會員編號'" [action]="searchFilterAction"></app-search-input>

                <button class="icon-button orange" (click)="fetch()">
                    <i class="material-icons-round mb-1">refresh</i>
                    <p translate>重新整理</p>
                </button>

                <button class="icon-button orange ml-5" routerLink="/main/user/create">
                    <i class="material-icons-round mb-1">person_add</i>
                    <p translate>創建會員</p>
                </button>

                <button class="icon-button yellow ml-5" (click)="openImportPopup()">
                    <i class="material-icons-round mb-1">unarchive</i>
                    <p translate>滙入紀錄</p>
                </button>

                <button class="icon-button yellow ml-5" (click)="exportUesrs()">
                    <i class="material-icons-round mb-1">archive</i>
                    <p translate>匯出所有</p>
                </button>
            </div>

            <div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
                <app-center-filter [action]="centerFilterAction"></app-center-filter>
                <app-address-filter [action]="addressFilterAction" class="ml-4"></app-address-filter>
                <app-service-filter [action]="serviceFilterAction" class="ml-4"></app-service-filter>
                <app-condition-filter [action]="conditionFilterAction" class="ml-4"></app-condition-filter>
                <app-cell-group-filter [action]="cellGroupFilterAction" class="ml-4"></app-cell-group-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>

            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>會員編號</p>
                        <div class="inline-flex-column" (click)="filters.memberIdOrder = !filters.memberIdOrder;filters.creditOrder = null;fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.memberIdOrder}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.memberIdOrder}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>會員姓名</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6.5rem;flex-grow: 11;">
                        <p translate>English Name</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>性別</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>中心代碼</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p translate>電話</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 33;">
                        <p translate>地址</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>時分</p>
                        <div class="d-flex flex-column" (click)="filters.creditOrder = !filters.creditOrder;filters.memberIdOrder = null;fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.creditOrder}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.creditOrder}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let profile of profiles">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <div class="flex-row align-items-center">
                            <input type="checkbox" [(ngModel)]="profile.selected" (change)="cacheSelectedProfile(profile)">
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ profile.memberId }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ profile.chineseName }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6.5rem;flex-grow: 11;">
                        <p [matTooltip]="profile.englishName" matTooltipPosition="above">{{ profile.englishName }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ profile.gender | translate }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ profile.center.centerCode }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p>{{ profile.phone }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 33;">
                        <p [matTooltip]="profile.defaultAddressString" matTooltipPosition="above">{{ profile.defaultAddressString }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p [ngClass]="{'text-red': profile.timeBalance < 0}">{{ profile.timeBalance | number }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;">
                        <p class="flex-0 click-text-lightblue mr-8" [routerLink]="['/main/user/overview', profile.uuId]" translate>檢視</p>
                        <p class="flex-0 click-text-lightblue mr-8" (click)="deleteUser(profile.uuId)" translate>移除</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="profiles.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteUserList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>