<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="event-box zoomIn" (click)="$event.stopPropagation()">
        <div class="event-box-header">
            <p class="text-xxxl text-grey flex-1" translate>會員邀請</p>
            <div class="inline-flex-row align-items-start" style="cursor: pointer;" routerLink="select-participants">
                <i class="material-icons-round add-icon mr-4">add_circle</i>
                <p class="text-xl text-grey">新增邀請</p>
            </div>
        </div>

        <div class="event-box-body flex-1">
            <div class="inline-flex-row align-items-center">
                <app-search-input [placeholder]="'請輸入姓名 或 會員編號'" [action]="searchAction"></app-search-input>
                <app-center-filter style="margin-left: 1.5rem;" (action)="centerFilterAction"></app-center-filter>
            </div>

            <div class="mt-5 inline-flex-column flex-1" style="overflow-y: scroll;">
                <ng-container *ngTemplateOutlet="pagination"></ng-container>

                <div class="user-table mt-3">
                    <div class="user-table-row header">
                        <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 33;">
                            <p translate>會員編號</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                            <p translate>中文姓名</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                            <p translate>中心代碼</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                            <p translate>性別</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                            <p translate>年齡</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                            <p translate>電話</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                            <p translate>狀態</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                            <p translate>交易時分</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
                            <p translate>操作</p>
                        </div>
                    </div>

                    <div class="user-table-scroll-box">
                        <div class="user-table-row" *ngFor="let member of displayedUsers">
                            <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 66;">
                                <p>{{ member.memberId }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                                <p>{{ member.chineseName }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                                <p>{{ member.center.centerCode }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                                <p>{{ member.gender | translate }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                                <p>{{ member.age }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                                <p>{{ member.phone }}</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                                <p>-</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                                <p>-</p>
                            </div>
                            <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
                                <p class="click-text-blue" [routerLink]="['/main/user/overview', member.uuId]" translate>檢視</p>
                                <p class="click-text-blue" style="margin-left: 1.5rem;" (click)="dropUser(member)" translate>取消</p>
                            </div>
                        </div>
                    </div>

                    <div class="user-table-row" *ngIf="displayedUsers.length === 0">
                        <div class="user-table-cell">
                            <p class="text-grey" translate>沒有紀錄</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inline-flex-row justify-content-center" style="margin-top: 3rem;">
                <button class="button outline" (click)="close()">返回</button>
            </div>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇邀請</p>
            <p class="ml-2 mr-2 text-orange">{{ users.length }}</p>
            <p translate>位會員</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>