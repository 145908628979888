import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrganizationUsage } from 'src/app/models/organizations/organization-usage';
import { PortalAnimation } from 'src/app/portal-animation';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';

import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SignUpService } from 'src/app/services/sign-up/sign-up.service';
import { EventService } from 'src/app/services/event/event.service';
import { RequestService } from 'src/app/services/request/request.service';
import { RedeemService } from 'src/app/services/redeem/redeem.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class SideNavComponent implements OnInit {

  usage!: IOrganizationUsage;
  role: string | null = "Staff";
  toggle: boolean = true;
  numSignUpUser:Number=0;
  numRequest:Number=0;
  numEvent:Number=0;
  numRedeem:Number=0;
  menu = {
    staff: false,
    user: false,
    announcement: false,
    system: false,
    donation: false,
    redeem: false,
    activity: false,
    trash: false
  };

  constructor(
    private authenticationService: AuthenticationService,
    private organizationService: OrganizationService,
    private eventService:EventService,
    private requestService:RequestService,
    private router: Router,
    private signUpService:SignUpService,
    private redeemService:RedeemService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('timebank_roles');
    this.fetch();
  }

  openUserGuide(): void {
    window.open("https://drive.google.com/file/d/1vFw5ilvPgMbB-QwcNpOQ4qChziIYyPhk/view?usp=sharing");
  }

  fetch(): void {
    this.organizationService.getUsage()
      .subscribe(value => {
        this.usage = value;
      });
      var isLoad=false;
      var loadRedRemark=()=>{
        if(!isLoad){
          isLoad=true;
          forkJoin(
            {
              numSignUpUser:this.signUpService.getSignUserNum(),
              numRequest: this.requestService.getUrgentNum(),
              numEvent: this.eventService.getUrgentNum(),
              numRedeem:this.redeemService.getUrgentNum()

            }
          ).pipe(finalize(() => {
            isLoad=false;
          }))
          .subscribe(v => {
              this.numSignUpUser=Number(v.numSignUpUser);
              this.numRequest=Number(v.numRequest);
              this.numEvent=Number(v.numEvent);
              this.numRedeem=Number(v.numRedeem);
          });
        }
      } 
      loadRedRemark();
      setInterval(()=>{
          loadRedRemark();
      },10000)
  }
}
