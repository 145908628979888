import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-setting-container',
  templateUrl: './event-setting-container.component.html',
  styleUrls: ['./event-setting-container.component.scss']
})
export class EventSettingContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
