import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Address, IAddressComponent } from 'src/app/models/addresses/address';
import { Room } from 'src/app/models/addresses/room';
import { EventType } from 'src/app/models/event-types/event-type';
import { Profile } from 'src/app/models/profiles/profile';
import { AddressForm } from 'src/app/modules/share/forms/address-form';
import { PortalAnimation } from 'src/app/portal-animation';
import { EventTypeService } from 'src/app/services/event-type/event-type.service';
import { EventService } from 'src/app/services/event/event.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { TemplateService } from 'src/app/services/template/template.service';
import { CreateEventDayForm } from '../../forms/create-event-day-form';
import { CreateEventForm } from '../../forms/create-event-form';

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class EventCreateComponent implements OnInit {

  minDate: Date = new Date();
  toggle: boolean = true;

  form: CreateEventForm = this.eventService.form;
  venueForms: AddressForm[] = [];

  NAaddress:Room=new Room("","");

  newEventDayForm: CreateEventDayForm | null = null;

  profile: Profile | null = null;
  eventTypes: EventType[] = [];

  lang: string = this.translateService.currentLang;

  constructor(
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private translateService: TranslateService,
    private templateService: TemplateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    })
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      profile: this.profileService.getMyProfile(),
      eventTypes: this.eventTypeService.getEventTypeList(),
      templates: this.templateService.getEventColumns(),
      NAaddress:this.eventService.getNAAddress()
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.profile = value.profile;
        this.eventTypes = value.eventTypes;

        if (this.form.columns.length == 0) {
          this.form.columns = value.templates.map(column => {
            return {
              columnName: column.columnName,
              columnDescription: column.columnDescription,
              input: new FormControl({ value: null, disabled: this.form.formGroup.disabled })
            }
          })
        }
        this.NAaddress=value.NAaddress;
      });
  }


  addVenue(): void {
    this.venueForms.push(new AddressForm());
  }

  saveVenue(): void {
    if (this.venueForms.length == 0 || !this.venueForms.every(form => form.formGroup.valid)) {
      this.venueForms.forEach(form => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();

    forkJoin(this.venueForms.map(form => this.profileService.createAddress(this.profile?.uuId as string, form.getCreateAddressModel())))
      .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.venueForms = [];
        this.fetch();
      })
  }

  dropVenue = (() => {
    this.venueForms = [];
  }).bind(this)

  reset(): void {
    this.form.reset();
  }

  submit(): void {
    if (this.form.formGroup.invalid) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      this.form.formGroup.markAllAsTouched();
      return;
    }

    if (this.form.users.length < this.vacancy.value) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvitedParticipantsMustGreaterThanVacancy);
      return;
    }

    if (this.form.eventDays.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.EmptyEventDay);
      return;
    }

    if (this.closeDate.value > this.form.getEventStartDate()) {
      this.popupMessageService.messageSignal.emit(PopupMessages.DeadlineMustBeEarlierThanStartTime);
      return;
    }

    this.loadingService.startLoading();
    this.eventService.createEvent(this.form.getCreateEventModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.form.reset();
        this.popupMessageService.messageSignal.emit(PopupMessages.CreateEventSuccessMessage(
          new Observable<any>(subscriber => {
            this.router.navigate(['/main/activity/overview/event', value.result.uuid]);
            this.loadingService.stopLoading();
          })
        ));
      });
  }

  get eventName(): AbstractControl {
    return this.form.formGroup.controls['eventName'];
  }

  get eventType(): AbstractControl {
    return this.form.formGroup.controls['eventType'];
  }

  get transactionType(): AbstractControl {
    return this.form.formGroup.controls['transactionType'];
  }

  get transactionFee(): AbstractControl {
    return this.form.formGroup.controls['transactionFee'];
  }

  get participationFormat(): AbstractControl {
    return this.form.formGroup.controls['participationFormat'];
  }

  get closeDate(): AbstractControl {
    return this.form.formGroup.controls['closeDate'];
  }

  get venue(): AbstractControl {
    return this.form.formGroup.controls['venue'];
  }

  get vacancy(): AbstractControl {
    return this.form.formGroup.controls['vacancy'];
  }

  get singleDayMode(): AbstractControl {
    return this.form.formGroup.controls['singleDayMode'];
  }

  get url(): AbstractControl {
    return this.form.formGroup.controls['url'];
  }

  get urlType(): AbstractControl {
    return this.form.formGroup.controls['urlType'];
  }

  print(value: any): void {
    this.form.eventDays = value;
  }

  cancelAction: () => void = (() => {
    this.singleDayMode.setValue(true);
  }).bind(this)

  clearAll(): void {
    this.form.eventDays = [];
  }

  addEventDay(): void {
    this.newEventDayForm = new CreateEventDayForm();
  }

  saveNewEventDay(): void {
    if (this.newEventDayForm == null) {
      return;
    }

    if (this.newEventDayForm?.formGroup.invalid) {
      this.newEventDayForm.formGroup.markAllAsTouched();
      return;
    }

    this.form.eventDays.push(this.newEventDayForm);
    this.newEventDayForm = null;
  }

  dropEventDay(index: number): void {
    this.form.eventDays.splice(index, 1);
  }

  openURL(): void {
    if (this.url.value == null)
      return;
    window.open(this.url.value);
  }

  editAddress(address: Address): void {
    this.loadingService.startLoading();
    this.profileService.editAddress(address.getUpdateAddressModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(() => {
        this.fetch();
      })
  }

  dropAddressAction = ((index: number) => {
    (this.profile as Profile).form.addresses[index - 1].editing = false;
  }).bind(this);
}
