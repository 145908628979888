import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateTransportationModel } from "./update-transportation-model";

export interface ITransportation {
  uuId: string;
  name: string;
}

export class Transportation {
  public uuId: string;
  public name: string;

  editing: boolean;
  form: FormGroup;

  constructor(transportation: ITransportation) {
    this.uuId = transportation.uuId;
    this.name = transportation.name;
    this.editing = false;
    this.form = new FormGroup({
      name: new FormControl(transportation.name, [Validators.required]),
    });
  }

  getUpdateTransportationModel(): UpdateTransportationModel {
    return {
      uuId: this.uuId,
      name: this.form.controls["name"].value,
    };
  }
}
