<div class="flex-column">
    <ng-content select="div.head "></ng-content>
    <div  *ngFor="let service of displayList;let i = index">
        <ng-container *ngIf="i>=pageIndex*6&&i<(pageIndex+1)*6"
             [ngTemplateOutlet]="tplRef" 
             [ngTemplateOutletContext]="{service:service,index:i}">
        </ng-container>
    </div>
    <div *ngIf="displayList.length>6" class="pageBtnBar">
        <span class="pageBtn" [ngClass]="{'disable': pageIndex == 0}" (click)="prevPage()">&lt;</span>
        <span class="pageBtn" [ngClass]="{'active': pageIndex == i-1}" (click)="jumpPage(i)" *ngFor="let i of displayListPage;">{{i}}</span>
        <span class="pageBtn" [ngClass]="{'disable': pageIndex ==MaxPage-1}" (click)="nextPage()">&gt;</span>
    </div> 
</div>