import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PopupMessages } from "src/app/backend/popup-message";
import { EventParticipant } from "src/app/models/events/event-participant";
import { EventService } from "src/app/services/event/event.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-event-detail-rejected-user-tab",
  templateUrl: "./event-detail-rejected-user-tab.component.html",
  styleUrls: ["./event-detail-rejected-user-tab.component.scss"],
})
export class EventDetailRejectedUserTabComponent implements OnInit {
  @Input() users: EventParticipant[] = [];
  @Input() closeAction: () => void = () => {};
  @Input() reloadAction: () => void = () => {};
  @Input() event!: Event;

  constructor(
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private eventService: EventService,
  ) {}

  ngOnInit(): void {}

  remove(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.reloadAction();
        subscriber.unsubscribe();
      },
    );

    this.route.params.subscribe((value) => {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.RemoveUserFromEventMessage(
          this.eventService.removeParticipants(value.id, {
            userList: [uuId],
            role: "Attendant",
          }),
        ),
      );
    });
  }
}
