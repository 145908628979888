import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Request } from "src/app/models/requests/request";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RequestService } from "src/app/services/request/request.service";
import { VolunteerService } from "src/app/services/volunteer/volunteer.service";

@Component({
  selector: "app-request-rating",
  templateUrl: "./request-rating.component.html",
  styleUrls: ["./request-rating.component.scss"],
})
export class RequestRatingComponent implements OnInit {
  @Input() request!: Request;
  @Input() reloadAction: () => void = () => {};

  rating: number = 0;
  ratings: number[] = [1, 2, 3, 4, 5];
  ratedBy: string | null = null;
  disabled: boolean = false;
  questions: [string, AbstractControl][] = [];
  isRequesterComment: boolean = true;
  RequesterOtherComment: [string, string] = ["", ""];

  constructor(
    private popupMessageService: PopupMessageService,
    private requestService: RequestService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.initRating();
  }

  rate(rating: number): void {
    if (this.disabled) return;
    this.rating = rating;
  }

  private initRating(): void {
    if (this.request.requestStatus == "Finished") {
      var volunteer = this.request.volunteers.filter(
        (v) => v.volunteerStatus == "CheckedOut",
      )[0];
      this.disabled = volunteer.requesterComment != null;
      this.ratedBy = volunteer.requesterCommentCreatedBy;
      this.isRequesterComment = volunteer.isRequesterComment;
      var comment: {
        Q1: { A: string; Q: string };
        Q2: { A: string; Q: string };
        Q3: { A: string; Q: string };
        OtherComment: { A: string; Q: string };
      } = JSON.parse(volunteer.requesterComment);

      if (comment) {
        this.disabled = true;
        this.rating = volunteer.requesterRating;
        this.questions = [
          [
            comment.Q1.Q,
            new FormControl({ value: comment.Q1.A, disabled: true }),
          ],
          [
            comment.Q2.Q,
            new FormControl({ value: comment.Q2.A, disabled: true }),
          ],
          [
            comment.Q3.Q,
            new FormControl({ value: comment.Q3.A, disabled: true }),
          ],
        ];
        this.RequesterOtherComment = [
          "RequesterOtherComment",
          comment.OtherComment.A,
        ];
      } else {
        if (this.questions.length == 0)
          this.questions = [
            [
              "此次服務的義工態度是否友善？\n Is the volunteer friendly and sincere?",
              new FormControl(null, [Validators.required]),
            ],
            [
              "此次服務是否準時開始？\n Was the service started punctually?",
              new FormControl(null, [Validators.required]),
            ],
            [
              "會員對此次服務內容是否滿意？\n Are you satisfied with the service performed?",
              new FormControl(null, [Validators.required]),
            ],
          ];
        this.RequesterOtherComment = ["RequesterOtherComment", ""];
      }
    }
  }

  answerQuestion(question: FormControl, value: string): void {
    question.setValue(value);
  }

  submitComment(): void {
    if (this.questions.some((q) => q[1].invalid)) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidRatingMessage,
      );
      return;
    }

    if (this.disabled) return;

    this.route.params.subscribe((value) => {
      var json = {
        Q1: { Q: this.questions[0][0], A: this.questions[0][1].value },
        Q2: { Q: this.questions[1][0], A: this.questions[1][1].value },
        Q3: { Q: this.questions[2][0], A: this.questions[2][1].value },
        OtherComment: { Q: "OtherComment", A: this.RequesterOtherComment[1] },
      };

      var rating = {
        rating: this.rating,
        comment: JSON.stringify(json),
      };

      this.loadingService.startLoading();
      forkJoin({
        a: this.requestService.postRating(value.id, rating),
        b: this.requestService.postStaffComment(value.id, {
          Comment: this.request.staffComment,
        }),
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.SubmitCommentSuccessMessage,
          );
          this.reloadAction();
        });
    });
  }

  get stars(): number[] {
    return this.ratings.filter((r) => r <= this.rating);
  }

  get outlineStars(): number[] {
    return this.ratings.filter((r) => r > this.rating);
  }

  get volunteerRating(): number {
    let count = 0;
    let sum = 0;
    this.request.volunteers
      .filter((v) => v.volunteerStatus == "CheckedOut")
      .forEach((v) => {
        if (v.volunteerComment) {
          sum += v.volunteerRating;
          count++;
        }
      });
    if (count == 0) return 0;

    return Math.ceil(sum / count);
  }

  get vcount(): number {
    return this.request.volunteers.filter(
      (v) => v.volunteerStatus == "CheckedOut" && v.volunteerRating,
    ).length;
  }

  get vstars(): number[] {
    return this.ratings.filter((r) => r <= this.volunteerRating);
  }

  get voutlineStars(): number[] {
    return this.ratings.filter((r) => r > this.volunteerRating);
  }
}
