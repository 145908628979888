<div class="analysis-container">
  <div class="analysis-container-header">
    <div class="filterHeader">
      <div class="inline-flex-row startline">
        <div>
          <p class="text-bold" style="margin-bottom: 1rem;" translate>會員累積時分排行榜</p>
          <p *ngIf="this.start==null && this.end==null"></p>
          <p>由 {{ placeholder }} 累積</p>
        </div>
      </div>
      <div class="inline-flex-row mt-8 endinline" style="position:relative">
        <input class="user-input xl" [ngModel]="dateRange" (click)="picker.open()" placeholder="{{placeholder}}"
          readonly>
        <ng-container *ngTemplateOutlet="datepicker"></ng-container>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
        <mat-date-range-input style="display: none;" [rangePicker]="picker">
          <input matStartDate [formControl]="start">
          <input matEndDate [formControl]="end" (dateInput)="fetch()">
        </mat-date-range-input>
      </div>
    </div>
  </div>

  <div class="analysis-container-body">
    <div class="ranking-box" *ngFor="let rank of topRanking; let i = index">
      <p *ngIf="i+1<=3" class="ranking-number-top mr-4">{{ i+1 }}</p>
      <p *ngIf="i+1>3" class="ranking-number mr-4">{{ i+1 }}</p>
      <p class="flex-1">{{ rank.index }}</p>
      <p>{{ rank.value | number }}</p>
    </div>
    <div *ngIf="ranking.length == 0" style="display: flex; justify-content: center; align-items: center; height:100%;">
      <p translate>沒有排名</p>
    </div>
  </div>
</div>

<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px"
    height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted">
    <path
      d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
    </path>
  </svg></ng-template>