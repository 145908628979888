import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as dayjs from "dayjs";
import { forkJoin } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

@Component({
  selector: "app-balance-analysis",
  templateUrl: "./balance-analysis.component.html",
  styleUrls: ["./balance-analysis.component.scss"],
})
export class BalanceAnalysisComponent implements OnInit {
  placeholder: string = `${dayjs().set("month", 0).set("date", 1).format("YYYY-MM-DD")} - ${dayjs().format("YYYY-MM-DD")}`;

  //Total
  totalTimeBalance: number = 0;
  averageTimeBalance: number = 0;

  //Request
  requestTotalTimeBalance: number = 0;
  requestAverageTimeBalance: number = 0;

  //Event
  eventTotalTimeBalance: number = 0;
  eventAverageTimeBalance: number = 0;

  //Redeem
  redeemTotalTimeBalance: number = 0;
  redeemAverageTimeBalance: number = 0;

  //Donation
  donationTotalTimeBalance: number = 0;
  donationAverageTimeBalance: number = 0;

  @Input() form!: FormControl;
  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  centerUUId?: string;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });
  }

  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null) return null;
    return `${dayjs(this.start.value).format("YYYY-MM-DD")} - ${dayjs(this.end.value).format("YYYY-MM-DD")}`;
  }

  fetch() {
    var start = !this.start.value
      ? undefined
      : dayjs(this.start.value).format("YYYY-MM-DD");
    var end = !this.end.value
      ? undefined
      : dayjs(this.end.value).format("YYYY-MM-DD");

    this.analyticsService.setBalanceAnalysisDateRange(start, end);

    forkJoin({
      totalTimeBalance: this.analyticsService.getTotalTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      averageTimeBalance: this.analyticsService.getAverageTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      requestTotalTimeBalance: this.analyticsService.getRequestTotalTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      requestAverageTimeBalance:
        this.analyticsService.getRequestAverageTimeBalance(
          this.centerUUId,
          start,
          end,
        ),
      eventTotalTimeBalance: this.analyticsService.getEventTotalTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      eventAverageTimeBalance: this.analyticsService.getEventAverageTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      redeemTotalTimeBalance: this.analyticsService.getRedeemTotalTimeBalance(
        this.centerUUId,
        start,
        end,
      ),
      redeemAverageTimeBalance:
        this.analyticsService.getRedeemAverageTimeBalance(
          this.centerUUId,
          start,
          end,
        ),
      donationTotalTimeBalance:
        this.analyticsService.getDonationTotalTimeBalance(
          this.centerUUId,
          start,
          end,
        ),
      donationAverageTimeBalance:
        this.analyticsService.getDonationAverageTimeBalance(
          this.centerUUId,
          start,
          end,
        ),
    }).subscribe((value) => {
      this.totalTimeBalance = value.totalTimeBalance;
      this.averageTimeBalance = value.averageTimeBalance;
      this.requestTotalTimeBalance = value.requestTotalTimeBalance;
      this.requestAverageTimeBalance = value.requestAverageTimeBalance;
      this.eventTotalTimeBalance = value.eventTotalTimeBalance;
      this.eventAverageTimeBalance = value.eventAverageTimeBalance;
      this.redeemTotalTimeBalance = value.redeemTotalTimeBalance;
      this.redeemAverageTimeBalance = value.redeemAverageTimeBalance;
      this.donationTotalTimeBalance = value.donationTotalTimeBalance;
      this.donationAverageTimeBalance = value.donationAverageTimeBalance;
    });
  }
}
