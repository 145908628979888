import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { AnnouncementItem } from "src/app/models/announcements/announcement-item";
import { Center } from "src/app/models/centers/center";
import { AnnouncementService } from "src/app/services/announcement/announcement.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-announcement-overview-page",
  templateUrl: "./announcement-overview-page.component.html",
  styleUrls: ["./announcement-overview-page.component.scss"],
})
export class AnnouncementOverviewPageComponent implements OnInit {
  count: number = 0;
  public announcements: AnnouncementItem[] = [];
  public caches: AnnouncementItem[] = [];
  public now: Date = new Date();
  public pages: number = 1;

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    from: null,
    centers: [],
    to: null,
    statuses: [],
    orderById: null,
    orderByAnnounceDate: true,
  };

  constructor(
    private announcementService: AnnouncementService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.announcementService
      .getAnnouncementList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.count = value.count;
          this.announcements = value.list;
          this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
          this.now = new Date();
        },
      });
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction = ((value: Center[] | any) => {
    this.filters.centers = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction = ((start: Date, end: Date) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction = ((value: string[]) => {
    (this.filters.statuses as string[]) = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  public deleteAnnouncement(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteAnnouncementMessage(
        this.announcementService.deleteAnnouncement(uuId),
      ),
    );
  }

  public deleteAnnouncementList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.caches = [];
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    let requests: any = {};
    this.caches.forEach((announcement, index) => {
      requests[index] = this.announcementService.deleteAnnouncement(
        announcement.uuId,
      );
    });

    let observable: Observable<any> = forkJoin(requests);

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteAnnouncementListMessage(observable),
    );
  }

  cacheSelectedAnnouncement(announcement: AnnouncementItem): void {
    if (announcement.selected) this.caches.push(announcement);
    else
      this.caches.splice(
        this.caches.findIndex((a) => a.uuId == announcement.uuId),
        1,
      );
  }

  public duplicateAnnouncement(announcement: AnnouncementItem): void {
    this.announcementService.form.formGroup.controls["title"].setValue(
      announcement.title,
    );
    this.announcementService.form.formGroup.controls["detail"].setValue(
      announcement.content,
    );
    this.announcementService.form.formGroup.controls["type"].setValue(
      announcement.type,
    );
    this.announcementService.form.formGroup.controls["url"].setValue(
      announcement.url,
    );
    this.router.navigate(["/main/announcement/create"]);
  }

  public getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput != null
        ? "&filterString=" + this.filters.searchInput
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      (this.filters.from
        ? "&from=" + (this.filters.from as Date).toJSON()
        : "") +
      (this.filters.to ? "&to=" + (this.filters.to as Date).toJSON() : "") +
      this.filters.statuses.map((s: string) => "&statuses=" + s).join("") +
      (this.filters.orderById != null
        ? "&orderById=" + this.filters.orderById
        : "") +
      (this.filters.orderByAnnounceDate != null
        ? "&orderByAnnounceDate=" + this.filters.orderByAnnounceDate
        : "")
    );
  }

  changePage(): void {
    this.router.navigate(["/main/announcement/overview"], {
      queryParams: {
        page: this.filters.page,
        itemsPerPage: this.filters.itemsPerPage,
      },
    });
  }

  selectAll(): void {
    if (this.isSelectedAll) {
      this.announcements.forEach((announcement) => {
        announcement.selected = false;
        this.caches.splice(
          this.caches.findIndex((a) => a.uuId == announcement.uuId),
          1,
        );
      });
    } else {
      this.caches = this.caches.concat(
        this.announcements.filter(
          (announcement) => announcement.selected == false,
        ),
      );
      this.announcements.forEach(
        (announcement) => (announcement.selected = true),
      );
    }
  }

  get isSelectedAll(): boolean {
    return this.announcements.every(
      (announcement) => announcement.selected == true,
    );
  }
}
