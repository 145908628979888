import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  constructor(private httpService: HttpService) {}

  public getProfileColumns(): Observable<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > {
    return this.httpService
      .get<
        {
          uuId: string;
          columnName: string;
          columnDescription: string;
          display: boolean;
        }[]
      >("organizations/ProfileColumn")
      .pipe(map((value) => value.result));
  }

  public setProfileColumn(
    columnName: string,
    columnDescription: string,
    display: boolean,
  ): Observable<any> {
    return this.httpService.post<
      any,
      { columnName: string; columnDescription: string; display: boolean }
    >("organizations/ProfileColumn", {
      columnName: columnName,
      columnDescription: columnDescription,
      display: display,
    });
  }
  public updateProfileColumn(uuid: string, display: boolean): Observable<any> {
    return this.httpService.put<any, { uuId: string; display: boolean }>(
      "organizations/ProfileColumn",
      { uuId: uuid, display: display },
    );
  }

  public removeProfileColumn(uuid: string) {
    return this.httpService.delete("organizations/ProfileColumn/" + uuid);
  }

  public getRequestColumns(): Observable<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > {
    return this.httpService
      .get<
        {
          uuId: string;
          columnName: string;
          columnDescription: string;
          display: boolean;
        }[]
      >("organizations/RequestColumn")
      .pipe(map((value) => value.result));
  }

  public setRequestColumn(
    columnName: string,
    columnDescription: string,
    display: boolean,
  ): Observable<any> {
    return this.httpService.post<
      any,
      { columnName: string; columnDescription: string; display: boolean }
    >("organizations/RequestColumn", {
      columnName: columnName,
      columnDescription: columnDescription,
      display: display,
    });
  }
  public updateRequestColumn(uuid: string, display: boolean): Observable<any> {
    return this.httpService.put<any, { uuId: string; display: boolean }>(
      "organizations/RequestColumn",
      { uuId: uuid, display: display },
    );
  }

  public removeRequestColumn(uuid: string) {
    return this.httpService.delete("organizations/RequestColumn/" + uuid);
  }

  public getEventColumns(): Observable<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > {
    return this.httpService
      .get<
        {
          uuId: string;
          columnName: string;
          columnDescription: string;
          display: boolean;
        }[]
      >("organizations/EventColumn")
      .pipe(map((value) => value.result));
  }

  public setEventColumn(
    columnName: string,
    columnDescription: string,
    display: boolean,
  ): Observable<any> {
    return this.httpService.post<
      any,
      { columnName: string; columnDescription: string; display: boolean }
    >("organizations/EventColumn", {
      columnName: columnName,
      columnDescription: columnDescription,
      display: display,
    });
  }
  public updateEventColumn(uuid: string, display: boolean): Observable<any> {
    return this.httpService.put<any, { uuId: string; display: boolean }>(
      "organizations/EventColumn",
      { uuId: uuid, display: display },
    );
  }

  public removeEventColumn(uuid: string) {
    return this.httpService.delete("organizations/EventColumn/" + uuid);
  }
}
