import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Center } from 'src/app/models/centers/center';
import { Profile } from 'src/app/models/profiles/profile';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { CenterService } from 'src/app/services/center/center.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StaffService } from 'src/app/services/staff/staff.service';

@Component({
  selector: 'app-staff-profile-page',
  templateUrl: './staff-profile-page.component.html',
  styleUrls: ['./staff-profile-page.component.scss']
})
export class StaffProfilePageComponent implements OnInit {

  profile!: Profile;
  centers: Center[] = [];
  readonly: boolean = true;
  showSuccessMessage: boolean = false;

  constructor(
    private profileService: ProfileService,
    private centerService: CenterService,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private popupMessageService: PopupMessageService,
    private authService: AuthenticationService,
    private staffService: StaffService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      profile: this.profileService.getMyProfile(),
      centers: this.centerService.getCenters()
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(v => {
        this.profile = v.profile;
        this.centers = v.centers;
      })
  }


  dropAddressAction = ((index: number) => {
    this.profile?.form.addresses.splice(index, 1);
  }).bind(this);

  submit(): void {

    if (this.profile?.form.valid == false) {
      this.profile.form.formGroup.markAllAsTouched();
      this.profile.form.addresses.forEach(address => address.form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    let observable: Observable<any>;
    if (this.profile?.form.doChangeUsernameAndPassword())
      observable = this.staffService.editMyStaffProfile(this.profile.form.getUpdateMyStaffModel())
        .pipe(switchMap(() => {
          return this.authService.changeUsernameAndPassword({
            userUUId: (this.profile as Profile).uuId,
            username: this.username.value,
            password: this.password.value
          });
        }))
    else
      observable = this.staffService.editMyStaffProfile((this.profile as Profile).form.getUpdateMyStaffModel());

    this.loadingService.startLoading();
    observable
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.readonly = true;
          // this.showSuccessMessage = true;
          forkJoin({
            text: this.translateService.get("資料已保存"),
            action: this.translateService.get("關閉")
          }).subscribe(value => {
            this.snackBar.open(value.text, value.action, { horizontalPosition: 'right', duration: 5000 });
          });
          window.scrollTo(0, 0);
          this.fetch();
        }
      });
  }

  get memberId(): AbstractControl {
    return this.profile?.form.formGroup.controls['memberId'] as FormControl;
  }

  get username(): AbstractControl {
    return this.profile?.form.formGroup.controls['username'] as FormControl;
  }

  get password(): AbstractControl {
    return this.profile?.form.formGroup.controls['password'] as FormControl;
  }

  get center(): AbstractControl {
    return this.profile?.form.formGroup.controls['center'] as FormControl;
  }

  get chineseName(): AbstractControl {
    return this.profile?.form.formGroup.controls['chineseName'] as FormControl;
  }

  get englishName(): AbstractControl {
    return this.profile?.form.formGroup.controls['englishName'] as FormControl;
  }

  get gender(): AbstractControl {
    return this.profile?.form.formGroup.controls['gender'] as FormControl;
  }

  get phone(): AbstractControl {
    return this.profile?.form.formGroup.controls['phone'] as FormControl;
  }
}
