<div class="section-1">
    <div class="input-row mt-0">
        <button class="button outline blue sm" translate>選擇會員</button>
    </div>
</div>

<form class="section-2">
    <div class="input-row mt-0">
        <p class="title required" translate>服務類型</p>
        <div class="user-select">
            <select class="user-select xl">
                <option [ngValue]="null" selected disabled translate>可提供服務類型</option>
            </select>
        </div>
        <div class="user-select ml-4">
            <select class="user-select sm">
                <option [ngValue]="null" selected disabled translate>服務</option>
            </select>
        </div>
    </div>

    <div class="input-row">
        <p class="title required" translate>服務日期</p>
        <input matInput class="user-input md" [matDatepicker]="picker" [min]="minDate" (click)="picker.open()" placeholder="{{ '服務日期' | translate }}" readonly>
        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
    </div>

    <div class="input-row">
        <p class="title required" translate>預計開始</p>
        <app-time-picker [form]="timeForm"></app-time-picker>
    </div>

    <div class="input-row">
        <p class="title required" translate>預計結束</p>
        <app-time-picker [form]="timeForm"></app-time-picker>
        <input class="ml-4" type="checkbox">
        <p class="ml-4" translate>未知</p>
    </div>

    <div class="input-row">
        <p class="title required" translate>義工數量</p>
        <input class="user-input md" type="number" placeholder="{{ '義工數量' | translate }}">
        <p class="ml-8 text-sm text-grey" translate>義工服務要求人數不超過50個人</p>
    </div>

    <div class="input-row">
        <p class="title required" translate>交通工具</p>
        <div class="user-select">
            <select class="user-select xl">
                <option [ngValue]="null" translate>自行選擇</option>
            </select>
        </div>
    </div>

    <div class="input-row">
        <p class="title align-self-start" style="line-height: 2rem;" translate>備註</p>
        <textarea style="width: 22rem;height: 8.625rem;"></textarea>
    </div>

    <div class="input-row" *ngFor="let column of columns">
        <p class="title">{{ column.columnName }}</p>
        <input class="user-input sm" type="text" [placeholder]="column.columnDescription">
        <label class="margin-left: 2rem;align-items-center">
            <input class="ml-6 mr-4"  type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="!column.display" (change)="changeDisplay(column.uuId)">
            <p translate>會員不可見</p>
        </label>
        <i class="material-icons-round ml-4 text-lg" style="color: #F5222D;cursor: pointer;" (click)="removeColumn(column.uuId)">remove_circle</i>
    </div>

    <div class="input-row" style="cursor: pointer;" *ngIf="!showColumnForm;else ColumnForm" (click)="showColumnForm = !showColumnForm">
        <i class="material-icons-round text-lg text-lightblue">add_circle</i>
        <p class="text-lightblue ml-4" translate>增加自訂義框</p>
    </div>

    <ng-template #ColumnForm>
        <div class="input-row">
            <div class="template-div">
                <input type="text" class="template-input" [formControl]="columnName"  placeholder="{{ '標題名稱' | translate }}">
            </div>
            <div class="template-div" style="margin-left: 2rem;">
                <input type="text" class="template-input lg" [formControl]="columnDescription" placeholder="{{ '描述文字' | translate }}">
            </div>

            <label class="align-items-center">
                <input class="ml-6 mr-4" type="checkbox" [(ngModel)]="hideUser" [ngModelOptions]="{standalone: true}">
                <p translate>會員不可見</p>
            </label>

            <p class="click-text-lightblue text-sm ml-8" (click)="saveColumn()" translate>保存</p>
            <i class="material-icons-round ml-8 text-lg" style="color: #F5222D;cursor: pointer;" (click)="resetForm()">remove_circle</i>
            <i class="material-icons-round ml-8 text-sm text-lightblue">error_outline</i>
            <p class="text-lightgrey text-sm ml-4" translate>注意，保存後不可刪除</p>
        </div>
    </ng-template>
</form>

<div class="section-3">
    <div class="input-row mt-0">
        <p class="title required align-self-start" style="line-height: 2rem;" translate>邀請義工</p>
        <p translate>未指定</p>
    </div>
</div>

<div class="inline-flex-row align-items-center align-self-center" style="margin-top: 4.6875rem; margin-bottom: 2rem;">
    <button class="button outline disable-grey" translate>取消創建</button>
    <button class="button outline disable-grey" style="margin-left: 9.375rem;" translate>提 交</button>
</div>