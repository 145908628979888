import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router, RouterOutlet } from "@angular/router";
import { PortalAnimation } from "src/app/portal-animation";
import { RequestService } from "src/app/services/request/request.service";

@Component({
  selector: "app-overview-page",
  templateUrl: "./overview-page.component.html",
  styleUrls: ["./overview-page.component.scss"],
  animations: [PortalAnimation.ActivityPageSlide],
})
export class OverviewPageComponent implements OnInit {
  form: FormControl = new FormControl(null);
  numberOfUrgentRequests: number = 0;

  tab: string = "request";

  constructor(
    private requestService: RequestService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url == "/main/overview/event") {
        this.tab = "event";
      } else {
        this.tab = "request";
      }
    });

    this.getUrgentRequests();
  }

  private getUrgentRequests(): void {
    this.requestService
      .getRequests("?start=0&limit=10000&isUrgent=true")
      .subscribe((value) => (this.numberOfUrgentRequests = value.count));
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animationState"]
    );
  }
}
