import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from './app-setting';
import { timeout } from 'rxjs/operators'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

const httpUploadOptions = {
  headers: new HttpHeaders({
    'Authorization': ''
  })
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  public get<Type>(path: string): Observable<ApiResponse<Type>> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey))
    return this.http.get<ApiResponse<Type>>(AppSetting.baseUrl + path, httpOptions)
      .pipe(timeout(AppSetting.timeoutValue))
  }


  public post<Type, Body>(path: string, body: Body): Observable<ApiResponse<Type>> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey))
    return this.http.post<ApiResponse<Type>>(AppSetting.baseUrl + path, body, httpOptions)
      .pipe(timeout(AppSetting.timeoutValue))
  }

  public put<Type, Body>(path: string, body: Body): Observable<ApiResponse<Type>> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey));
    //multipart/form-data
    return this.http.put<ApiResponse<Type>>(AppSetting.baseUrl + path, body, httpOptions)
      .pipe(timeout(AppSetting.timeoutValue))
  }

  public delete<Type>(path: string): Observable<ApiResponse<Type>> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey));
    return this.http.delete<ApiResponse<Type>>(AppSetting.baseUrl + path, httpOptions)
      .pipe(timeout(AppSetting.timeoutValue));
  }

  public upload(path: string, body: FormData): Observable<any> {
    httpUploadOptions.headers = httpUploadOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey));
    return this.http.post(AppSetting.baseUrl + path, body, httpUploadOptions)
      .pipe(timeout(AppSetting.timeoutValue));
  }
  public uploadPut(path: string, body: FormData): Observable<any> {
    httpUploadOptions.headers = httpUploadOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey));
    return this.http.put(AppSetting.baseUrl + path, body, httpUploadOptions)
      .pipe(timeout(AppSetting.timeoutValue));
  }
  
  public update(path: string, body: FormData): Observable<any> {
    httpUploadOptions.headers = httpUploadOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem(AppSetting.tokenKey));
    return this.http.put(AppSetting.baseUrl + path, body, httpUploadOptions)
      .pipe(timeout(AppSetting.timeoutValue));
  }

  public getImage(path: string): Observable<any> {
    return this.http.get(AppSetting.baseUrl + path, {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem(AppSetting.tokenKey) }), responseType: "blob"
    }).pipe(timeout(AppSetting.timeoutValue));
  }

  public getExcels(path: string): Observable<any> {
    return this.http.get("/v2/assets/" + path, {
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      responseType: "blob"
    }).pipe(timeout(AppSetting.timeoutValue));
  }
}

interface ApiResponse<Type> {
  result: Type;
  status: string;
}