import { Component, Input, OnInit } from '@angular/core';
import { AddressType, Living } from 'src/app/models/livings/living';
import { LivingService } from 'src/app/services/living/living.service';

@Component({
  selector: 'app-living-popup',
  templateUrl: './living-popup.component.html',
  styleUrls: ['./living-popup.component.scss']
})
export class LivingPopupComponent implements OnInit {

  display: boolean = false;
  index: number = 0;
  @Input() action: (livings: Living[]) => void = (livings: Living[]) => { }
  livings: Living[] = [];

  public types = [
    { name: "單位", type: AddressType.Room },
    { name: "樓層", type: AddressType.Floor },
    { name: "大廈/期/座", type: AddressType.Block },
    { name: "屋苑/屋邨", type: AddressType.Estate },
    { name: "街號", type: AddressType.StreetNumber },
    { name: "街道", type: AddressType.Street },
  ];

  constructor(
    private livingService: LivingService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.livingService.getLivingConditionList()
      .subscribe({
        next: value => {
          this.livings = value
        }
      });
  }

  submit(): void {
    this.action(this.livings.filter(living => living.selected == true));
    this.close();
  }

  open(livings: Living[]) {
    this.livings.filter(living => livings.some(l => living.uuId == l.uuId))
      .forEach(living => living.selected = true);
    this.display = true;
  }

  close(): void {
    this.reset();
    this.display = false;
  }

  reset(): void {
    this.livings.forEach(living => living.selected = false);
  }

  get livingsOfSelectedType(): Living[] {
    return this.livings.filter(living => living.type == this.types[this.index].type);
  }

  numberOfLivingOfType(type: AddressType): number {
    return this.livings.filter(living => living.type == type).length;
  }
}
