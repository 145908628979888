import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Region } from "src/app/models/addresses/region";
import { Living } from "src/app/models/livings/living";
import { AddressForm } from "../../forms/address-form";

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent implements OnInit {
  @Input() index: number = 1;
  @Input() form: AddressForm = new AddressForm();
  @Input() dropAction: (index: number) => void = () => {};

  public regions: Region[] = [
    new Region("香港島", ["中西區", "東區", "南區", "灣仔區"]),
    new Region("九龍", [
      "九龍城區",
      "觀塘區",
      "深水埗區",
      "黃大仙區",
      "油尖旺區",
    ]),
    new Region("新界", [
      "葵青區",
      "荃灣區",
      "屯門區",
      "元朗區",
      "西貢區",
      "大埔區",
      "離島區",
      "沙田區",
      "北區",
    ]),
  ];

  constructor() {}

  ngOnInit(): void {}

  livingPopupAction = ((livings: Living[]) => {
    this.form.livings = livings;
  }).bind(this);

  dropAddress(): void {
    this.dropAction(this.index - 1);
  }

  dropLiving(index: number): void {
    this.form.livings.splice(index, 1);
  }

  get district(): AbstractControl {
    return this.form.formGroup.controls["district"];
  }

  get selectedRegion(): Region | undefined {
    return this.regions.find(
      (r) => r.name == this.form.formGroup.controls["region"].value,
    );
  }
}
