import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recommendation-setting-page',
  templateUrl: './recommendation-setting-page.component.html',
  styleUrls: ['./recommendation-setting-page.component.scss']
})
export class RecommendationSettingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
