<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>請求/訂單</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>記錄詳情</p>
    </div>

    <p class="page-title mt-8" translate>記錄詳情</p>

    <div class="container mt-5" *ngIf="request">

        <div class="container-header">
            <p class="flex-1">{{ '記錄編號：' | translate: {value: request.id} }}</p>
            <p>{{ '創建時間：' | translate: {value: request.createdAt | date: 'yyyy-MM-dd HH:mm'} }}</p>
        </div>

        <div class="container-body">

            <ng-container *ngIf="request.requestStatus == 'Cancelled'; then request_cancel"></ng-container>

            <ng-container *ngIf="request.requestStatus != 'Cancelled'">
                <ng-container *ngTemplateOutlet="request_status"></ng-container>

                <div class="agent-block" *ngIf="request.agent">
                    <i class="material-icons-round info-icon mr-4">info</i>
                    <p style="color: black;font-size: 0.8125rem;">{{ '代理人代為申請該義工服務' | translate: {value1: request.agent.chineseName, value2: request.agent.phone} }}</p>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '申請服務' | translate }}" [matTooltipPosition]="'above'" translate>申請服務</p>
                    <p>{{ request.service.type }} - {{ request.service.name }}</p>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '申請人' | translate }}" [matTooltipPosition]="'above'" translate>申請人</p>
                    <p>{{ request.requester.chineseName}}</p>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '記錄狀態' | translate }}" [matTooltipPosition]="'above'" translate>記錄狀態</p>
                    <div class="status orange mr-2" *ngIf="request.requestStatus == 'Pending'">
                        <p translate>等候配對</p>
                    </div>
                    <div class="status green mr-2" *ngIf="request.requestStatus == 'Ready'">
                        <p translate>未開始</p>
                    </div>
                    <div class="status green mr-2" *ngIf="request.requestStatus == 'Started'">
                        <p translate>進行中</p>
                    </div>
                    <div class="status grey mr-2" *ngIf="request.requestStatus == 'Finished'">
                        <p translate>已完結</p>
                    </div>
                    <div class="status grey mr-2" *ngIf="request.requestStatus == 'Cancelled'">
                        <p translate>已終止</p>
                    </div>
                    <div class="status red mr-2" *ngIf="request.matchingOvertime">
                        <p translate>配對超時</p>
                    </div>
                    <div class="status red mr-2" *ngIf="request.startOvertime">
                        <p translate>超時開始</p>
                    </div>
                    <div class="status red mr-2" *ngIf="request.endOvertime">
                        <p translate>超時結束</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '聯絡電話' | translate }}" [matTooltipPosition]="'above'" translate>聯絡電話</p>
                    <p>{{ request.requester.phone}}</p>
                </div>

                <ng-container *ngIf="request.requestStatus != 'Finished';then start else actual_start"></ng-container>

                <ng-template #start>
                    <div class="input-row">
                        <p class=" title" matTooltip="{{ '預計開始' | translate }}" [matTooltipPosition]="'above'" translate>預計開始</p>
                        <p class="mr-8">{{ request.startTime | date: 'longDate': '': lang }}</p>
                        <p>{{ request.startTime | date: 'hh:mm aa' }}</p>
                    </div>
                </ng-template>

                <ng-template #actual_start>
                    <div class="input-row">
                        <p class="title" matTooltip="{{ '開始時間' | translate }}" [matTooltipPosition]="'above'" translate>開始時間</p>
                        <p class="mr-8">{{ request.checkInTime | date: 'longDate': '': lang }}</p>
                        <ng-container *ngIf="editing == false">
                            <p>{{ request.checkInTime | date: 'hh:mm aa' }}</p>
                            <p class="click-text-blue ml-8" (click)="editing = true" translate>編輯</p>
                        </ng-container>
                        <ng-container *ngIf="editing == true">
                            <app-time-picker [form]="request.actualStartForm"></app-time-picker>
                            <p class="click-text-blue ml-8" (click)="updateRequest()" translate>確定</p>
                            <p class="click-text-blue ml-8" (click)="editing = false" translate>取消</p>
                        </ng-container>
                    </div>
                </ng-template>

                <ng-container *ngIf="request.requestStatus != 'Finished';then end else actual_end"></ng-container>

                <ng-template #end>
                    <div class="input-row">
                        <p class="title" matTooltip="{{ '預計結束' | translate }}" [matTooltipPosition]="'above'" translate>預計結束</p>
                        <p class="mr-8">{{ request.endTime | date: 'longDate': '': lang }}</p>
                        <p>{{ request.endTime | date: 'hh:mm aa' }}</p>
                    </div>
                </ng-template>

                <ng-template #actual_end>
                    <div class="input-row">
                        <p class="title" matTooltip="{{ '結束時間' | translate }}" [matTooltipPosition]="'above'" translate>結束時間</p>
                        <p class="mr-8">{{ request.checkOutTime | date: 'longDate': '': lang }}</p>
                        <ng-container *ngIf="editing == false">
                            <p>{{ request.checkOutTime | date: 'hh:mm aa' }}</p>
                            <p class="click-text-blue ml-8" *ngIf="editing == false" (click)="editing = true" translate>編輯</p>
                        </ng-container>

                        <ng-container *ngIf="editing == true">
                            <app-time-picker [form]="request.actualEndForm"></app-time-picker>
                            <p class="click-text-blue ml-8" (click)="updateRequest()" translate>確定</p>
                            <p class="click-text-blue ml-8" (click)="editing = false" translate>取消</p>
                        </ng-container>
                    </div>
                </ng-template>


                <div class="input-row">
                    <p class="title" translate>提醒時限</p>
                    <p translate>15 分鐘</p>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '交通工具' | translate }}" [matTooltipPosition]="'above'" translate>交通工具</p>
                    <ng-container *ngIf="editing == false">
                        <p *ngIf="request.transportation == null" translate>自行選擇</p>
                        <p *ngIf="request.transportation">{{ request.transportation.name }}</p>
                        <p class="click-text-blue ml-8" *ngIf="editing == false" (click)="editing = true" translate>編輯</p>
                    </ng-container>

                    <ng-container *ngIf="editing == true">
                        <div class="user-select">
                            <select class="user-select xl" [formControl]="request.transportationForm">
                                <option [ngValue]="null" translate>自行選擇</option>
                                <option [ngValue]="transportation.uuId" *ngFor="let transportation of transportations">{{ transportation.name }}</option>
                            </select>
                        </div>
                        <p class="click-text-blue ml-8" (click)="updateRequestTransportation()" translate>確定</p>
                        <p class="click-text-blue ml-8" (click)="editing = false" translate>取消</p>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title align-self-start lh-8" translate>備註</p>

                    <p *ngIf="!remarkEdit&&request.remark" style="white-space: pre-line;line-height: 2rem;">{{ request.remark }}</p>
                    <p *ngIf="!remarkEdit&&request.remark == null" translate>無</p>
                    <ng-container *ngIf="remarkEdit == true">
                        <div class="inline-flex-column">
                            <textarea  style="width: 22rem;height: 8.625rem;" [formControl]="remark"></textarea>
                            
                            <div class="align-self-end inline-flex-row lh-8 mt-5" style="line-height: 2rem;">
                                <p class="click-text-blue ml-8" (click)="updateRequestRemark()" translate>確定</p>
                                <p class="click-text-blue ml-8" (click)="remarkEdit = false" translate>取消</p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="remarkEdit == false">
                            <p class="click-text-blue ml-8 align-self-start"  style="line-height: 2rem;" *ngIf="remarkEdit == false" (click)="remarkEdit = true" translate>編輯</p>
                       
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title" matTooltip="{{ '邀請義工' | translate }}" [matTooltipPosition]="'above'" translate>邀請義工</p>
                    <p class="text-ligthgrey" *ngIf="request.requestStatus == 'Pending' && !request.matchingOvertime && !request.isAccepted" translate>正在等候配對，尚未有義工接受邀請</p>
                    <p class="text-ligthgrey" *ngIf="request.requestStatus == 'Pending' && !request.matchingOvertime && request.isAccepted" translate>已有義工接受邀請，尚未進行配對</p>
                    <p class="text-red" *ngIf="request.requestStatus == 'Pending' && request.matchingOvertime && !request.isAccepted" translate>超過時限，未有義工接受邀請</p>
                    <p class="text-red" *ngIf="request.requestStatus == 'Pending' && request.matchingOvertime && request.isAccepted" translate>超過時限，尚未進行配對</p>
                    <p class="text-ligthgrey" *ngIf="request.requestStatus == 'Ready' && !request.startOvertime" translate>尚未到服務日期</p>
                    <p class="text-red" *ngIf="request.requestStatus == 'Ready' && request.startOvertime" translate>超過時限，服務尚未確認開始</p>
                    <p class="text-ligthgrey" *ngIf="request.requestStatus == 'Started' && !request.endOvertime" translate>服務正在進行中</p>
                    <p class="text-red" *ngIf="request.requestStatus == 'Started' && request.endOvertime" translate>超過時限，服務尚未確認結束</p>
                    <p class="text-ligthgrey" *ngIf="request.requestStatus == 'Finished'" translate>服務已確認結束</p>
                </div>

                <app-request-detail-volunteer-table class="mt-5" [request]="request" [reloadAction]="reloadAction"></app-request-detail-volunteer-table>

                <ng-container *ngIf="request.requestStatus == 'Finished'">
                    <app-request-subsidy [requester]="request.requester" [subsidy]="request.centerDonate" [reloadAction]="reloadAction"></app-request-subsidy>

                    <app-request-rating [request]="request" [reloadAction]="reloadAction"></app-request-rating>
                </ng-container>

                <div class=" inline-flex-row align-items-center justify-content-center" style="margin-top: 4.75rem;">
                    <button class="button fill red" *ngIf="request.requestStatus == 'Pending' || request.requestStatus == 'Ready'" (click)="terminate_popup.open()" translate>終 止</button>
                    <button class="button fill blue" style="margin-left: 10rem;" *ngIf="request.requestStatus == 'Pending'" routerLink="select-volunteers" translate>重新邀請</button>
                    <button class="button fill blue" style="margin-left: 10rem;" *ngIf="request.requestStatus == 'Pending' && request.matchingOvertime" (click)="approve()" translate>確認配對</button>
                    <button class="button fill blue" style="margin-left: 10rem;" *ngIf="request.requestStatus == 'Ready'" (click)="start_popup.open()" translate>確認開始</button>
                    <button class="button fill blue" *ngIf="request.requestStatus == 'Started'" (click)="finish()" translate>確認結束</button>
                </div>
            </ng-container>
        </div>

    </div>

    <div class="container" style="margin-top: 2.5rem;" *ngIf="request">
        <div class="container-body">
            <div class="input-row">
                <p class="title required" matTooltip="{{ '會員姓名' | translate }}" [matTooltipPosition]="'above'" translate>會員姓名</p>
                <p style="flex: 0 0 7.5rem;">{{ request.requester.chineseName }}</p>
                <p>{{ request.requester.englishName }}</p>
            </div>

            <div class="input-row">
                <p class="title required" translate>會員編號</p>
                <p>{{ request.requester.memberId }}</p>
            </div>
            
            <div class="input-row">
                <p class="title" matTooltip="{{ '聯絡電話' | translate }}" [matTooltipPosition]="'above'" translate>聯絡電話</p>
                <p>{{ request.requester.phone}}</p>
            </div>

            <div class="input-row">
                <p class="title align-self-start" style="line-height: 2rem;" matTooltip="{{ '身體狀況' | translate }}" [matTooltipPosition]="'above'" translate>身體狀況</p>
                <p *ngIf="request.requester.healths.length == 0" translate>無</p>

                <div class="status-box" *ngIf="request.requester.healths.length > 0">
                    <div class="inline-flex-row" style="height: 2rem;" *ngFor="let health of request.requester.healths">
                        <div class="health-status align-self-center mr-4">
                            <p>{{ health.name }}</p>
                            <i class="material-icons-round ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest">visibility</i>
                            <i class="material-icons-outlined ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest == false">visibility_off</i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input-row">
                <p class="title" matTooltip="{{ '緊急聯絡人' | translate }}" [matTooltipPosition]="'above'" translate>緊急聯絡人</p>
                <p *ngIf="request.requester.contactPersonName == null" translate>沒有緊急聯絡人</p>
                <p *ngIf="request.requester.contactPersonName">{{ request.requester.contactPersonName }}</p>
                <p *ngIf="request.requester.contactPersonPhoneNumber">({{ request.requester.contactPersonPhoneNumber }})</p>
            </div>

            <div style="border-bottom: 1px solid #979797;width: 44.5rem;margin-top: 1.5rem;"></div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '服務日期' | translate }}" [matTooltipPosition]="'above'" translate>服務日期</p>
                <p>{{ request.startTime | date: 'longDate': '' : lang }}</p>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '預計開始' | translate }}" [matTooltipPosition]="'above'" translate>預計開始</p>
                <p>{{ request.startTime | date: 'hh:mm aa' }}</p>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '預計結束' | translate }}" [matTooltipPosition]="'above'" translate>預計結束</p>
                <p>{{ request.endTime | date: 'hh:mm aa' }}</p>
            </div>

            <div class="input-row">
                <p class="title required" translate>地址</p>
                <p>{{ request.venue.toString() }}</p>
            </div>

            <div class="input-row" *ngIf="request.destination">
                <p class="title required" translate>目的地</p>
                <p>{{ request.destination.toString() }}</p>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '義工數量' | translate }}" [matTooltipPosition]="'above'" translate>義工數量</p>
                <p>{{ request.numberOfVolunteer }}</p>
            </div>

            <div class="input-row">
                <p class="title align-self-start lh-8" translate>備註</p>
                <p *ngIf="request.remark" style="width: 20rem; white-space: pre-line;line-height: 2rem;">{{ request.remark }}</p>
                <p *ngIf="request.remark == null" translate>無</p>
            </div>

            <div class="input-row" *ngFor="let column of columns">
                <p class="title">{{ column.columnName }}</p>
                <p *ngIf="request.extraRequestJson">{{ request.extraRequestJson[column.columnName] }}</p>
                <p *ngIf="request.extraRequestJson == null" translate>暫無</p>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '邀請義工' | translate }}" [matTooltipPosition]="'above'" translate>邀請義工</p>
                <div class="inline-flex-row align-items-center flex-wrap" style="width: 20rem;">
                    <p class="lh-8" style="flex: 0 0 10rem;" *ngFor="let volunteer of request.volunteers">{{ volunteer.chineseName }} ({{ volunteer.phone }})</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-request-start-popup #start_popup></app-request-start-popup>

<app-request-terminate-popup #terminate_popup></app-request-terminate-popup>

<ng-template #request_status>
    <div class="flex-row">
        <div class="stage-container">
            <p class="stage blue" *ngIf="request.requestStatus == 'Pending'" [ngClass]="{'red': request.matchingOvertime}">1</p>
            <i class="material-icons-round stage blue" *ngIf="request.requestStatus != 'Pending'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>等候配對</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>該請求正在等候配對</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" *ngIf="request.requestStatus == 'Pending' || request.requestStatus == 'Ready'" [ngClass]="{'blue': request.requestStatus == 'Ready', 'red': request.startOvertime}">2</p>
            <i class="material-icons-round stage blue" *ngIf="request.requestStatus == 'Started' || request.requestStatus == 'Finished'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>未開始</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>服務正在等待開始</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" *ngIf="request.requestStatus != 'Finished'" [ngClass]="{'blue': request.requestStatus == 'Started', 'red': request.endOvertime}">3</p>
            <i class="material-icons-round stage blue" *ngIf="request.requestStatus == 'Finished'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>進行中</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>服務正在進行中</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': request.requestStatus == 'Finished'}">4</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已完結</p>
                </div>
                <p class="mt-2 text-lightgrey" translate>服務已經確認結束</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #request_cancel>
    <div class="inline-flex-column align-items-center">
        <i class="material-icons-round cross-icon mb-5">add_circle</i>
        <p style="font-size: 1.5rem;line-height: 2rem;" translate>該記錄已終止</p>
    </div>

    <div class="input-row">
        <p class="title" translate>記錄狀態</p>
        <div class="status grey">
            <p translate>已終止</p>
        </div>
    </div>

    <div class="input-row">
        <p class="title" translate>預計開始</p>
        <p>{{ request.startTime | date: 'hh:mm aa' }}，{{ request.startTime | date: 'longDate': '': lang }}</p>
    </div>

    <div class="input-row">
        <p class="title" translate>預計結束</p>
        <p>{{ request.endTime | date: 'hh:mm aa' }}，{{ request.endTime | date: 'longDate': '': lang }}</p>
    </div>

    <div class="input-row">
        <p class="title align-self-start lh-8" translate>終止原因</p>
        <div  class="inline-flex-column">
        <textarea style="width: 22rem;height: 8.625rem;" [(ngModel)]="request.cancelRemark"></textarea>
        <br>
        </div>
    </div>
    <button class="button fill blue center" translate (click)="saveCancelDetail()">保 存</button>
</ng-template>