import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Center } from "src/app/models/centers/center";
import { CellGroupService } from "src/app/services/cell-group/cell-group.service";

@Component({
  selector: "app-analysis-page",
  templateUrl: "./analysis-page.component.html",
  styleUrls: ["./analysis-page.component.scss"],
})
export class AnalysisPageComponent implements OnInit {
  centers: Center[] = [];
  form: FormControl = new FormControl(null);

  constructor(private cellGroupService: CellGroupService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.cellGroupService.getCenterCellGroupList().subscribe((value) => {
      this.centers = value;
    });
  }
}
