import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {

  errorSignal: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
