export interface INotificationContent {
    title: string;
    body: string;
    notificationBody: string;
    notificationTitle: string;
}

export interface INotification {
    uuId: string;
    notificationContent: INotificationContent | string;
    createdAt: Date;
    readTime: Date;
    isRead: boolean
}

export class Notification implements INotification {
    uuId: string;
    notificationContent: INotificationContent;
    createdAt: Date;
    readTime: Date;
    isRead: boolean;

    constructor(notification: INotification) {
        this.uuId = notification.uuId;
        this.notificationContent = JSON.parse(<string>notification.notificationContent);
        this.createdAt = new Date(notification.createdAt + 'Z');
        this.readTime = new Date(notification.readTime + 'Z');
        this.isRead = notification.isRead;
    }
}