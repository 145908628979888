import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Product } from "src/app/models/redeems/product";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProductService } from "src/app/services/product/product.service";

@Component({
  selector: "app-redeem-detail-page",
  templateUrl: "./redeem-detail-page.component.html",
  styleUrls: ["./redeem-detail-page.component.scss"],
})
export class RedeemDetailPageComponent implements OnInit {
  @ViewChild("image") image!: ElementRef;
  @ViewChild("image2") image2!: ElementRef;
  product!: Product;
  file!: Blob;
  editing: boolean = false;

  lang: string = this.translateService.currentLang;

  notices: boolean[] = [true, true, true];

  constructor(
    private loadingService: LoadingService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((value) => {
      this.lang = value.lang;
    });
    this.fetch();
  }

  fetchImage(): void {
    this.productService.getProductImage(this.product.imagePath).subscribe({
      next: (result) => {
        this.file = result;
        this.product.form.image = result;
        let element = this.image.nativeElement as HTMLImageElement;
        element.src = URL.createObjectURL(result);
      },
      error: (error) => {
        //Do Nothing
      },
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.route.params.subscribe((value) => {
      this.productService
        .getProduct(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: (result) => {
            this.product = result;
            if (this.product.imagePath == null) return;
            this.fetchImage();
          },
        });
    });
  }

  disableProduct(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DisableProductMessage(
        this.productService.disableProduct(this.product.uuId),
      ),
    );
  }

  centerPopupAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.product.form.centers = centers;
  }).bind(this);

  toggleEdit(): void {
    this.editing = true;
    if (this.file && this.product.isLaunched == false)
      setTimeout(
        () =>
          ((this.image2.nativeElement as HTMLImageElement).src =
            URL.createObjectURL(this.file)),
        500,
      );
  }

  submit(): void {
    let observable!: Observable<any>;

    if (this.product.isClosed) {
      return;
    }

    if (this.product.form.formGroup.invalid) {
      this.product.form.formGroup.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    if (this.product.isLaunched == false) {
      observable = this.productService.updateProduct(
        this.product.form.getFormData(),
      );
    }

    if (this.product.isLaunched) {
      observable = this.productService.restock(
        this.product.uuId,
        this.product.form.formGroup.controls["stock"].value,
        this.product.form.formGroup.controls["limit"].value,
        this.product.form.getCloseDate().toJSON(),
      );
    }

    if (observable == null) {
      return;
    }

    this.loadingService.startLoading();
    observable
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.editing = false;
          this.fetch();
        },
      });
  }

  get numberOfRedeemedUesrs(): number {
    return this.product
      ? [
          ...new Set(
            this.product.redeems
              .filter(
                (redeem) =>
                  redeem.redeemState == "Redeemed" ||
                  redeem.redeemState == "Delivered",
              )
              .map((redeem) => redeem.userUUId),
          ),
        ].length
      : 0;
  }

  get filename(): string {
    return this.product
      ? this.product.imagePath?.slice(this.product.imagePath.indexOf("/") + 1)
      : "";
  }

  get productName(): AbstractControl {
    return this.product.form.formGroup.controls["productName"];
  }

  get target(): AbstractControl {
    return this.product.form.formGroup.controls["target"];
  }

  get brand(): AbstractControl {
    return this.product.form.formGroup.controls["brand"];
  }

  get format(): AbstractControl {
    return this.product.form.formGroup.controls["format"];
  }

  get price(): AbstractControl {
    return this.product.form.formGroup.controls["price"];
  }

  get stock(): AbstractControl {
    return this.product.form.formGroup.controls["stock"];
  }

  get limit(): AbstractControl {
    return this.product.form.formGroup.controls["limit"];
  }

  get closeDate(): AbstractControl {
    return this.product.form.formGroup.controls["closeDate"];
  }

  get description(): AbstractControl {
    return this.product.form.formGroup.controls["description"];
  }

  get launchDate(): AbstractControl {
    return this.product.form.formGroup.controls["launchDate"];
  }

  get isAnswerQuestionRequired(): AbstractControl {
    return this.product.form.formGroup.controls["isAnswerQuestionRequired"];
  }

  imageUploadAction: (image: Blob) => void = ((image: Blob) => {
    this.product.form.image = image;
    this.file = image;
    this.product.form.formGroup.controls["imagePath"].setValue(null);
    setTimeout(
      () =>
        ((this.image2.nativeElement as HTMLImageElement).src =
          URL.createObjectURL(this.product.form.image)),
      1000,
    );
  }).bind(this);
}
