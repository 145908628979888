<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="cell-group-box zoomIn" (click)="$event.stopPropagation()">
        <div class="inline-flex-row">
            <p class="flex-1" translate>細胞小組</p>
            <p class="click-text-blue" (click)="reset()" translate>重置</p>
        </div>

        <div class="cell-group-item-box">
            <label class="cell-group-item" *ngFor="let cellGroup of cellGroups">
                <input class="mr-4" type="checkbox" [(ngModel)]="cellGroup.selected">
                <p>{{ cellGroup.name }}</p>
            </label>
        </div>

        <div class="flex-1"></div>

        <div class="cell-group-box-footer">
            <button class="button outline" (click)="close()" translate>返 回</button>
            <button class="button fill blue" style="margin-left: 7.5rem;" (click)="confirm()" translate>確 認</button>
        </div>
    </div>
</div>