import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Center } from 'src/app/models/centers/center';
import { CenterService } from 'src/app/services/center/center.service';

@Component({
  selector: 'app-center-detail',
  templateUrl: './center-detail.component.html',
  styleUrls: ['./center-detail.component.scss']
})
export class CenterDetailComponent implements OnInit {
  @Input() beforeClose!: Function;
  @ViewChild("#image") image!:HTMLElement;
  display:boolean=false;
  loading:boolean=false;

  uuId:string="";
  centerCode:string="";
  name:string="";
  phoneNumber:string="";
  wts:boolean=false;
  wts_num:string="";
  service_Time:string="";
  file:any;
  newIcon:boolean=false;
  oldIcon:string="";
  logoFile:File|null=null;
  update(center:Center) {
    this.display=true;
    this.newIcon=false;
    this.logoFile=null;
    this.oldIcon="";
    this.uuId=center.uuId;
    this.centerCode=center.centerCode;
    this.phoneNumber=center.phoneNumber;
    this.name=center.name;
    this.file=null;
    if(center.otherContactInfo&&typeof center.otherContactInfo=="object"){
      this.wts=( center.otherContactInfo.wts)? center.otherContactInfo.wts:false;
      this.wts_num=( center.otherContactInfo.wts_num)? center.otherContactInfo.wts_num:"";
      this.service_Time=( center.otherContactInfo.service_Time)? center.otherContactInfo.service_Time:"";
      //="/api/center/icon?OrgCode="+localStorage.getItem("OrgCode")+"&CenterCode="+this.centerCode;
      this.oldIcon=( center.otherContactInfo.icon)?( center.otherContactInfo.icon):"";
      this.centerService.getCenterImage(this.centerCode)
      .subscribe({
        next: result => {
          var reader = new FileReader();
          reader.readAsDataURL(result); 
          var _this=this ;
          reader.onloadend = function() {
              _this.file = reader.result
          }
        },
        error: error => {
          //Do Nothing
          this.file =null;
        }
      })
    }
  }
  close(){
    if(this.beforeClose!=null)
      this.beforeClose();
    this.display=false;
  }
  constructor(
    private centerService: CenterService) { }
  
  ngOnInit(): void {
    this.image.onerror=()=>{
      this.newIcon=false;
      this.logoFile=null;
    }
  }
  get oldIconTxt(){
    return this.oldIcon!=""?
      this.oldIcon.substring(this.oldIcon.lastIndexOf("/")+1)
    :"";
  }
  save(){
   
      this.loading = true;

      var formData:FormData=new FormData();
      
      formData.append("uuId",this.uuId);
      formData.append("name",this.name);
      formData.append("centerCode",this.centerCode);
      formData.append("phoneNumber",this.phoneNumber);
      if(this.newIcon&&this.logoFile){
        formData.append("OtherContactInfo",JSON.stringify({
          wts:this.wts,
          wts_num:this.wts_num,
          service_Time:this.service_Time
        }));
        formData.append("icon",this.logoFile);
      }else{
        formData.append("OtherContactInfo",JSON.stringify({
          wts:this.wts,
          wts_num:this.wts_num,
          service_Time:this.service_Time,
          icon:this.oldIcon
        }));
      }
      
      
      this.centerService.updateCenter(formData)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: () => {
            this.close();
          }
        });
  }
  public onImageImport(files: FileList): void {

    this.processLogoImage(files[0])
      .subscribe({
        next: response => {
          this.file= response;
           this.newIcon=true;
        },
        complete: () => { }
      });
  }
  private processLogoImage(logo: File): Observable<any> {
    this.logoFile=logo;
    return new Observable(subscriber => {
      if (logo == null) {
        subscriber.complete();
        return;
      }
      var fileReader: FileReader = new FileReader();
      fileReader.onload = function (e) {
        subscriber.next(e.target?.result);
      };
      fileReader.readAsDataURL(logo);
    });
  }
}

