import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { defaultIfEmpty, finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { EventParticipant } from "src/app/models/events/event-participant";
import { Transaction } from "src/app/models/transactions/transaction";
import { EventService } from "src/app/services/event/event.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { TransactionService } from "src/app/services/transaction/transaction.service";
import { Event } from "src/app/models/events/event";

@Component({
  selector: "app-event-detail-confirmed-user-tab",
  templateUrl: "./event-detail-confirmed-user-tab.component.html",
  styleUrls: ["./event-detail-confirmed-user-tab.component.scss"],
})
export class EventDetailConfirmedUserTabComponent implements OnInit {
  @Input() users: EventParticipant[] = [];
  @Input() closeAction: () => void = () => {};
  @Input() reloadAction: () => void = () => {};
  @Input() type: string = "Free";
  @Input() event!: Event;

  changeAll: boolean = false;
  form: FormControl = new FormControl(null);

  constructor(
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private transactionService: TransactionService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      if (this.changeAll == false) return;

      this.selectedUsers.forEach((user) => user.form.setValue(value));
    });
  }

  remove(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.reloadAction();
        subscriber.unsubscribe();
      },
    );

    this.route.params.subscribe((value) => {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.RemoveUserFromEventMessage(
          this.eventService.removeParticipants(value.id, {
            userList: [uuId],
            role: "Attendant",
          }),
        ),
      );
    });
  }

  update(user: EventParticipant) {
    if (user.transaction == null) {
      this.route.params.subscribe((value) => {
        this.loadingService.startLoading();
        this.eventService
          .createEventTransaction(value.id, {
            list: [{ userUUId: user.uuId, amount: user.form.value }],
            isCenterPaid: this.type == "UserEarn",
          })
          .pipe(finalize(() => this.loadingService.stopLoading()))
          .subscribe((value) => {
            this.reloadAction();
            this.popupMessageService.messageSignal.emit(
              PopupMessages.ParticipantNewTransactionEventSuccessMessage,
            );
            user.editing = false;
          });
        // forkJoin(
        //     this.selectedUsers.filter(user => user.state == 'Confirmed' && user.transaction == null)
        //                       .map(user => this.eventService.createEventTransaction(value.id, {
        //                               list: [{ userUUId: user.uuId, amount: user.form.value }],
        //                               isCenterPaid: this.type == "UserEarn"
        //                             }
        //                             )
        //                           )
        // ).pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
        // .subscribe(value => {
        //     this.popupMessageService.messageSignal.emit(PopupMessages.ParticipantFinishEventSuccessMessage);
        //     this.reloadAction();
        // });
      });
    } else {
      this.updateTransaction(user);
    }
  }

  updateTransaction(user: EventParticipant) {
    this.loadingService.startLoading();
    this.transactionService
      .editTransaction((user.transaction as Transaction).uuId, {
        amount: (user.transaction as Transaction).form.value,
      })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          (user.transaction as Transaction).uuId = value.newTransactionUUId;
          user.editing = false;
        },
      });
  }

  absent(uuId: string): void {
    this.route.params.subscribe((value) => {
      var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
        (value) => {
          this.reloadAction();
          subscriber.unsubscribe();
        },
      );

      this.popupMessageService.messageSignal.emit(
        PopupMessages.UserAbsentMessage(
          this.eventService.absent(value.id, { list: [uuId] }),
        ),
      );
    });
  }

  submut(): void {
    if (this.selectedUsers.some((u) => u.form.invalid)) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    this.route.params.subscribe((value) => {
      this.loadingService.startLoading();
      forkJoin(
        this.selectedUsers
          .filter(
            (user) => user.state == "Confirmed" && user.transaction == null,
          )
          .map((user) =>
            this.eventService.createEventTransaction(value.id, {
              list: [{ userUUId: user.uuId, amount: user.form.value }],
              isCenterPaid: this.type == "UserEarn",
            }),
          ),
      )
        .pipe(
          defaultIfEmpty(),
          finalize(() => this.loadingService.stopLoading()),
        )
        .subscribe((value) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ParticipantFinishEventSuccessMessage,
          );
          this.reloadAction();
        });
    });
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.users.forEach((user) => (user.selected = false));
    else this.users.forEach((user) => (user.selected = true));
  }

  get selectedUsers(): EventParticipant[] {
    return this.users.filter((user) => user.selected);
  }

  get isSelectedAll(): boolean {
    return this.users.every((user) => user.selected);
  }
  needRed(user: EventParticipant): boolean {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return (
      user.requestTime != null &&
      user.requestTime > date &&
      this.event.eventJoinType == "FIFJ"
    );
  }
}
