import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../share/share.module';
import { BinPageComponent } from './pages/bin-page/bin-page.component';
import { RouterModule, Routes } from '@angular/router';
import { RequestTrashBinComponent } from './components/request-trash-bin/request-trash-bin.component';
import { EventTrashBinComponent } from './components/event-trash-bin/event-trash-bin.component';
import { UserTrashBinComponent } from './components/user-trash-bin/user-trash-bin.component';
import { DonationTrashBinComponent } from './components/donation-trash-bin/donation-trash-bin.component';
import { RedeemTrashBinComponent } from './components/redeem-trash-bin/redeem-trash-bin.component';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
  {
    path: '', component: BinPageComponent, children: [
      { path: 'request', component: RequestTrashBinComponent, data: { animationState: 'request' } },
      { path: 'event', component: EventTrashBinComponent, data: { animationState: 'event' } },
      { path: 'user', component: UserTrashBinComponent, data: { animationState: 'user' } },
      { path: 'donation', component: DonationTrashBinComponent, data: { animationState: 'donation' } },
      { path: 'redeem', component: RedeemTrashBinComponent, data: { animationState: 'redeem' } },
      { path: '', redirectTo: 'request', pathMatch: 'full' },
      { path: '**', redirectTo: 'request' }
    ]
  },
  { path: '**', redirectTo: '' }
]

@NgModule({
  declarations: [BinPageComponent, RequestTrashBinComponent, EventTrashBinComponent, UserTrashBinComponent, DonationTrashBinComponent, RedeemTrashBinComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ShareModule,
    RouterModule.forChild(routes),
    MatTooltipModule
  ]
})
export class TrashModule { }
