import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { AddressType, Living } from "src/app/models/livings/living";
import { LivingService } from "src/app/services/living/living.service";

@Component({
  selector: "app-living-setting",
  templateUrl: "./living-setting.component.html",
  styleUrls: ["./living-setting.component.scss"],
})
export class LivingSettingComponent implements OnInit {
  livings: Living[] = [];

  index: number = 0;
  loading: boolean = false;
  form: FormGroup = new FormGroup({
    type: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
  });

  public types = [
    { name: "單位", type: AddressType.Room },
    { name: "樓層", type: AddressType.Floor },
    { name: "大廈/期/座", type: AddressType.Block },
    { name: "屋苑/屋邨", type: AddressType.Estate },
    { name: "街號", type: AddressType.StreetNumber },
    { name: "街道", type: AddressType.Street },
  ];

  constructor(private livingService: LivingService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.livingService
      .getLivingConditionList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.livings = value;
        },
      });
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.livingService
      .createLivingCondition({
        name: this.form.controls["name"].value,
        type: this.form.controls["type"].value,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.form.reset();
          this.fetch();
        },
      });
  }

  update(living: Living): void {
    if (living.form.invalid == true) {
      living.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.livingService
      .updateLivingConditon(living.getUpdateLivingModel())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
        },
      });
  }

  get livingsOfSelectedType(): Living[] {
    return this.livings.filter(
      (living) => living.type == this.types[this.index].type,
    );
  }

  numberOfLivingOfType(type: AddressType): number {
    return this.livings.filter((living) => living.type == type).length;
  }
}
