<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedItems > 0}" (click)="toggle()">
    <i class="filter-icon material-icons-outlined" *ngIf="numberOfSelectedItems == 0">favorite_border</i>
    <p class="filter-number" *ngIf="numberOfSelectedItems > 0">{{ numberOfSelectedItems }}</p>
    <p translate>細胞小組</p>
    <div class="filter-body condition" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-8">
                <p class="text-bold flex-1" style="color: #5A5A5A" translate>細胞小組<span style="font-weight: normal;font-size: 0.75rem;">（最多可篩選30個小組）</span></p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">

                <div class="center-box mb-16" *ngFor="let center of centers">
                    <label class="checkbox mb-4">
                        <!-- <input class="mr-8" type="checkbox" [ngModel]="isSelectedAllLivingConditions" (click)="selectAllLivingConditions()"> -->
                        <p translate>{{center.name}}</p>
                    </label>
                    <div *ngFor="let cellGroup of center.cellGroups">
                        <label class="checkbox mb-4">
                            <input class="mr-8" type="checkbox" [(ngModel)]="cellGroup.selected" [attr.disabled]="numberOfSelectedItems==30&&!cellGroup.selected?true:null">
                            <p translate>{{cellGroup.name}}</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>