import { UpdateEventForm } from "src/app/modules/event/forms/update-event-form";
import { Address, IAddress } from "../addresses/address";
import { EventType, IEventType } from "../event-types/event-type";
import { ITransaction, Transaction } from "../transactions/transaction";
import { EventDay, IEventDay } from "./event-day";
import { EventParticipant, IEventParticipant } from "./event-participant";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IEvent {
  id: number;
  uuId: string;
  eventName: string;
  venue: IAddress;
  startTime: Date;
  endTime: Date;
  isUnknownEndTime: boolean;
  createdDate: Date;
  participantVacancy: number;
  volunteerVacancy: number;
  earning: number;
  spending: number;
  remark: string;
  detail: string;
  lastEditedTime: Date;
  lastEditedBy: string;
  participants: IEventParticipant[];
  transactions: ITransaction[];
  eventType: IEventType;
  eventPay: string;
  eventJoinType: string;
  applyDeadline: Date;
  eventDays: IEventDay[];
  isCancelled: boolean;
  cancelledTime: Date;
  cancelledBy: string;
  cancelDetail: string;
  eventStatus: string;
  isFull: boolean;
  extraEventJson: any;
  deleteDate: Date;
  url: string;
  numberOfParticipants: number;
  applyUserNum: number;
}

export class Event {
  id: number;
  uuId: string;
  eventName: string;
  venue: IAddress | null;
  startTime: Date;
  endTime: Date;
  createdDate: Date;
  participantVacancy: number;
  volunteerVacancy: number;
  earning: number;
  spending: number;
  remark: string;
  detail: string;
  lastEditedTime: Date | null;
  lastEditedBy: string;
  participants: EventParticipant[];
  transactions: Transaction[];
  eventType: EventType;
  eventPay: string;
  eventJoinType: string;
  applyDeadline: Date;
  eventDays: EventDay[];
  isCancelled: boolean;
  cancelledTime: Date;
  cancelledBy: string;
  cancelDetail: string;
  eventStatus: string;
  isFull: boolean;
  extraEventJson: any;
  deleteDate: Date | null;
  url: string;

  selected: boolean;
  editing: boolean;

  form: UpdateEventForm;
  NumberOfParticipants: number;
  applyUserNum: number;

  constructor(event: IEvent) {
    this.id = event.id;
    this.uuId = event.uuId;
    this.eventName = event.eventName;
    this.venue = event.venue ? new Address(event.venue) : null;
    this.startTime = dayjs.utc(event.startTime).toDate();
    this.endTime = dayjs.utc(event.endTime).toDate();
    this.createdDate = dayjs.utc(event.createdDate).toDate();
    this.participantVacancy = event.participantVacancy;
    this.volunteerVacancy = event.volunteerVacancy;
    this.earning = event.earning;
    this.spending = event.spending;
    this.remark = event.remark;
    this.detail = event.detail;
    this.lastEditedTime = event.lastEditedTime
      ? dayjs.utc(event.lastEditedTime).toDate()
      : null;
    this.lastEditedBy = event.lastEditedBy;
    this.isCancelled = event.isCancelled;
    this.cancelledTime = dayjs.utc(event.cancelledTime).toDate();
    this.cancelledBy = event.cancelledBy;
    this.cancelDetail = event.cancelDetail;
    this.eventStatus = event.eventStatus;
    this.isFull = event.isFull;
    this.deleteDate = event.deleteDate
      ? dayjs.utc(event.deleteDate).toDate()
      : null;
    this.extraEventJson = event.extraEventJson
      ? JSON.parse(event.extraEventJson)
      : null;
    this.eventPay = event.eventPay;
    this.eventType = new EventType(event.eventType);
    this.eventJoinType = event.eventJoinType;
    this.applyDeadline = dayjs.utc(event.applyDeadline).toDate();
    this.eventDays = event.eventDays.map((d) => new EventDay(d));
    this.transactions = event.transactions.map(
      (transaction) => new Transaction(transaction),
    );
    this.participants = event.participants.map(
      (p) => new EventParticipant(p, this.transactions),
    );
    this.editing = false;
    this.selected = false;
    this.url = event.url;
    this.NumberOfParticipants = event.numberOfParticipants;

    this.participants.forEach((p) => p.form.setValue(this.earning));
    this.applyUserNum = event.applyUserNum;
    this.form = new UpdateEventForm(this);
  }

  public getEventStartDate(): Date {
    return this.eventDays
      .map((ed) => ed.startTime)
      .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))[0];
  }

  public getEventEndDate(): Date {
    return this.eventDays
      .map((ed) => ed.endTime as Date)
      .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];
  }

  get isOngoing(): boolean {
    let date = new Date();
    return this.startTime <= date && this.endTime >= date;
  }

  get numberOfParticipants(): number {
    return this.NumberOfParticipants;
  }

  get numberOfApplication(): number {
    return this.participants.filter((p) => p.state === "Interested").length;
  }

  get numberOfInvited(): number {
    return this.participants.length;
  }

  get full(): boolean {
    return (
      this.participants.filter((p) => p.state === "Confirmed").length >=
      this.participantVacancy
    );
  }
  get IsNotEnoughParticipants(): boolean {
    let offset = Number(localStorage.getItem("EventNotification"));
    let date = dayjs().add(offset, "minutes").toDate();

    return (
      this.eventStatus === "Open" &&
      this.numberOfParticipants < this.participantVacancy &&
      this.applyDeadline < date
    );
  }
}
