<div class="fadeIn" *ngIf="profile">
    <div class="flex-row text-lightgrey">
        <p translate>職員</p>
        <p class="ml-4">/</p>
        <p class="ml-4" translate>職員概覽</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>{{ '的職員檔案' | translate: {value: profile.chineseName} }}</p>
    </div>

    <p class="page-title mt-8" translate>{{ profile.chineseName }} {{ profile.englishName }}</p>

    <div class="container mt-5">
        <form class="container-body" [formGroup]="profile.form.formGroup">

            <!-- <div class="success-message" *ngIf="showSuccessMessage">
                <div class="inline-flex-row">
                    <i class="material-icons-round check-icon">check_circle_outline</i>
                    <div class="inline-flex-column flex-1" style="margin-left: 1.25rem;">
                        <p style="line-height: 1.5rem;font-size: 1rem;" translate>保存成功！</p>
                        <p class="text-lightgrey" translate>您所更新的信息已被保存。最後更新時間和修改者已被標注於頁面最底。</p>
                    </div>
                    <i class="material-icons-round close-icon align-self-start text-lightgrey" (click)="showSuccessMessage = false">close</i>
                </div>
            </div> -->

            <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '會員編號' | translate }}" [matTooltipPosition]="'above'" translate>職員編號</p>
                <p>{{ profile.memberId }}</p>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '登入帳號' | translate }}" [matTooltipPosition]="'above'" translate>登入帳號</p>
                <p *ngIf="readonly">{{ profile.userName }}</p>
                <ng-container *ngIf="readonly == false && username">
                    <input class="user-input xs" type="text" placeholder="{{ '登入帳號' | translate }}" formControlName="username">
                    <div class="user-input-error zoomIn" *ngIf="username.touched && username.errors">
                        <!-- <p *ngIf="username.errors.required" translate>請輸入登入帳號</p> -->
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '登入密碼' | translate }}" [matTooltipPosition]="'above'" translate>登入密碼</p>
                <p *ngIf="readonly">********</p>
                <ng-container *ngIf="readonly == false && password">
                    <input class="user-input pw" type="text" placeholder="{{ '至少4個字符，必須包含數字' | translate }}" formControlName="password">
                    <div class="user-input-error zoomIn" *ngIf="password.touched && password.errors">
                        <!-- <p *ngIf="password.errors.required" translate>請輸入帳號密碼</p> -->
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '職員姓名' | translate }}" [matTooltipPosition]="'above'" translate>職員姓名</p>
                <p *ngIf="readonly">{{ profile.chineseName }}</p>
                <ng-container *ngIf="readonly == false">
                    <input class="user-input xs" type="text" formControlName="chineseName" placeholder="{{ '中文姓名*' | translate }}">
                    <input class="user-input xl ml-6" type="text" formControlName="englishName" placeholder="{{ 'English Name' | translate }}">
                    <div class="user-input-error zoomIn" *ngIf="chineseName.touched && chineseName.errors">
                        <p *ngIf="chineseName.errors.required" translate>請輸入中文姓名</p>
                    </div>
                    <div class="user-input-error zoomIn" *ngIf="!chineseName.errors && englishName.touched && englishName.errors">
                        <p *ngIf="englishName.errors.required" translate>請輸入英文姓名</p>
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '中心名稱' | translate }}" [matTooltipPosition]="'above'" translate>中心名稱</p>
                <p *ngIf="readonly">{{ profile.center.centerCode }}</p>
                <ng-container *ngIf="readonly == false">
                    <div class="user-select">
                        <select class="user-select xxxl" formControlName="center">
                            <option [ngValue]="null" disabled selected translate>中心名稱</option>
                            <option *ngFor="let center of centers" [ngValue]="center.uuId">{{ center.name }}</option>
                        </select>
                    </div>
                    <div class="user-input-error zoomIn" *ngIf="center.touched && center.errors">
                        <p *ngIf="center.errors.required" translate>請選擇所屬中心</p>
                    </div>
                </ng-container>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '性別' | translate }}" [matTooltipPosition]="'above'" translate>性別</p>
                <ng-container *ngIf="readonly">
                    <input type="radio" name="gender" value="M" [ngModel]="profile.gender" [ngModelOptions]="{standalone: true}" disabled>
                    <p class="ml-1" translate>男</p>
                    <input type="radio" style="margin-left: 2rem;" name="gender" value="F" [ngModel]="profile.gender" [ngModelOptions]="{standalone: true}" disabled>
                    <p class="ml-1" translate>女</p>
                </ng-container>

                <ng-container *ngIf="readonly == false">
                    <label>
                        <input type="radio" name="gender" value="M" formControlName="gender">
                        <p class="ml-1" translate>男</p>
                    </label>
                    <label>
                        <input type="radio" style="margin-left: 2rem;" name="gender" value="F" formControlName="gender">
                        <p class=" ml-1" translate>女</p>
                    </label>
                </ng-container>

            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '電話號碼' | translate }}" [matTooltipPosition]="'above'" translate>電話號碼</p>
                <p *ngIf="readonly">{{ profile.phone }}</p>
                <ng-container *ngIf="readonly == false">
                    <input class="user-input xs" type="text" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話*' | translate }}">
                    <div class="user-input-error zoomIn" *ngIf="phone.touched && phone.errors">
                        <p *ngIf="phone.errors.required" translate>請輸入電話號碼</p>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="readonly">
                <ng-container *ngFor="let address of profile.addresses;let index = index">
                    <div class="input-row">
                        <p class="title align-self-start" [ngClass]="{required: index == 0}" style="line-height: 1.375rem;">{{ '地址#' | translate: {value: index+1} }}</p>
                        <div class="inline-flex-column">
                            <p style="line-height: 1.375rem;">{{ address.region.name }}，{{ address.district.name }}，{{
                                address.subDistrict.name }}</p>
                            <p style="line-height: 1.375rem;">{{ address.street.name }}，{{ address.streetNumber.name }}
                            </p>
                            <p style="line-height: 1.375rem;">{{ address.estate.name }}，{{ address.block.name }}</p>
                            <p style="line-height: 1.375rem;">{{ address.floor.name }}，{{ address.room.name }}</p>
                        </div>
                    </div>

                    <div class="input-row">
                        <p class="title align-self-start" style="line-height: 2rem;">{{ '居住條件 #' | translate: {value:
                            index+1} }}</p>
                        <div class="status-box">
                            <p *ngIf="address.livings.length == 0" translate>未設置</p>
                            <div class="inline-flex-row" style="height: 2rem;" *ngFor="let living of address.livings; let index = index">
                                <div class="living-status align-self-center mr-4">
                                    <p>{{ living.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="readonly == false">
                <ng-container *ngFor="let address of profile.form.addresses;let index = index">
                    <app-address-form style="margin-top: 2rem;margin-left: -1.5rem;" [form]="address.form" [index]="index+1" [dropAction]="dropAddressAction"></app-address-form>
                </ng-container>

                <div class="add-address-button" style="margin-left: -1.5rem;" (click)="profile.form.addAddress()">
                    <p class="text-lightgrey" translate>增添地址</p>
                </div>
            </ng-container>


            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '權限設置' | translate }}" [matTooltipPosition]="'above'" translate>權限設置</p>
                <div class="inline-flex-column">
                    <p class="text-lightgrey border-bottom" style="line-height: 2rem;width: 25.9375rem;" translate>可擁有以下中心的職員權限，包括查看同操作會員資料</p>
                    <ng-container *ngIf="readonly">
                        <div class="inline-flex-row align-items-center" *ngFor="let center of profile.staffCenters;let index = index">
                            <p style="flex: 0 0 4.5rem;line-height: 2rem;">{{ center.centerCode }}</p>
                            <p class="flex-1">{{ center.name }}</p>
                            <p class="text-xs" *ngIf="center.centerCode == profile.center.centerCode" translate>默認</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="readonly == false">
                        <div class="inline-flex-row align-items-center" *ngFor="let center of profile.form.staffCenters;let index = index">
                            <p style="flex: 0 0 4.5rem;line-height: 2rem;">{{ center.centerCode }}</p>
                            <p class="flex-1">{{ center.name }}</p>
                            <p class="text-xs" *ngIf="center.centerCode == profile.center.centerCode" translate>默認</p>
                        </div>

                        <div class="inline-flex-row align-items-center" (click)="permission_popup.open(profile.form.staffCenters)">
                            <p class="text-lightgrey mr-4" style="line-height: 2rem;">新增中心</p>
                            <i class="material-icons-round add-icon">add_circle</i>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '註冊審核' | translate }}" [matTooltipPosition]="'above'" translate>註冊審核</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row align-items-center">
                        <p class="text-lightgrey lh-8" translate>是否擁有審核機構內會員帳號申請資料的權限？</p>
                        <i class="material-icons-round info-icon ml-4">help_outline</i>
                        <p class="text-lightgrey text-xs ml-4 lh-8" translate>該權限可審核由App遞交的會員帳號申請，並可指定通過申請的會員所屬中心位置</p>
                    </div>

                    <div class="inline-flex-row">
                        <ng-container *ngIf="readonly">
                            <label class="align-items-center" style="margin-right: 2.75rem;">
                                <input type="radio" [value]="true" formControlName="allowApproval" [ngModelOptions]="{standalone: true}" disabled>
                                <p class="lh-8" translate>是</p>
                            </label>
                            <label class="align-items-center">
                                <input type="radio" [value]="false" formControlName="allowApproval" [ngModelOptions]="{standalone: true}" disabled>
                                <p class="lh-8" translate>否</p>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="readonly == false">
                            <label class="align-items-center" style="margin-right: 2.75rem;">
                                <input type="radio" [value]="true" formControlName="allowApproval">
                                <p class="lh-8" translate>是</p>
                            </label>
                            <label class="align-items-center">
                                <input type="radio" [value]="false" formControlName="allowApproval">
                                <p class="lh-8" translate>否</p>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="inline-flex-row align-items-center justify-content-center" style="margin-top: 21.5625rem;margin-bottom: 21.0625rem;">
                <ng-container *ngIf="readonly == true">
                    <button class="button outline md" (click)="deleteStaff()" translate>移 除</button>
                    <button class="button fill blue md" style="margin-left: 10.375rem;" (click)="readonly = false" translate>編 輯</button>
                    <button class="button fill red md" style="margin-left: 10.375rem;" (click)="forcedSignOut()" translate>強制登出</button>
                </ng-container>
                <ng-container *ngIf="readonly == false">
                    <button class="button outline md" (click)="readonly = true" translate>取 消</button>
                    <button class="button fill blue md" style="margin-left: 10.375rem;" (click)="submit()" translate>保 存</button>
                </ng-container>
            </div>

        </form>
    </div>
</div>

<app-center-permission-popup [action]="permissionAction" [defaultCenter]="defaultCenter" #permission_popup>
</app-center-permission-popup>