<div class="inline-flex-column">
    <div class="input-row">
        <p class="title align-self-start" style="line-height: 2.5rem;" translate>對義工評價</p>
        <div class="inline-flex-column">
            <div class="inline-flex-row align-items-center">
                <i class="material-icons-round star" *ngFor="let star of stars" (click)="rate(star)">star</i>
                <i class="material-icons-round star-border" *ngFor="let star of outlineStars" (click)="rate(star)">star_border</i>
                <i class="info-icon material-icons-round mr-5">error_outline</i>
                <p class="text-lightgrey text-xs" translate>該評價視為申請人對整體義工進行評價，查看詳情可查看具體評價</p>
            </div>

            <div class="inline-flex-row text-lightgrey text-xs" *ngIf="ratedBy&&!isRequesterComment">
                <p class="text-xs" translate>{{ '該評價由職員' | translate: {value: ratedBy} }}</p>
            </div>

            <div class="inline-flex-column mt-8" *ngFor="let question of questions; let i = index">
                <p>{{ question[0] }}</p>
                <div class="rating-button-box">
                    <button class="rating-button" [ngClass]="{'filled': question[1].value == 'no'}" (click)="answerQuestion(question[1], 'no')" [disabled]="disabled" translate>否</button>
                    <button class="rating-button" [ngClass]="{'filled': question[1].value == 'notClear'}" (click)="answerQuestion(question[1], 'notClear')" [disabled]="disabled" translate>不清楚</button>
                    <button class="rating-button" [ngClass]="{'filled': question[1].value == 'yes'}" (click)="answerQuestion(question[1], 'yes')" [disabled]="disabled" translate>是</button>
                </div>
            </div>
            <div  class="inline-flex-column mt-8" >
                <p translate>RequesterOtherComment</p>
                <div class="rating-button-box">
                    <div class="inline-flex-row mt-6">
                        <textarea [disabled]="disabled" style="width: 22rem;height:8.625rem" [(ngModel)]="RequesterOtherComment[1]"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="input-row">
        <p class="title align-self-start" style="line-height: 2.5rem;" translate>對申請人評價</p>
        <div class="inline-flex-column">
            <div class="inline-flex-row align-items-center">
                <i class="material-icons-round star" *ngFor="let star of vstars">star</i>
                <i class="material-icons-round star-border" *ngFor="let star of voutlineStars">star_border</i>
                <i class="info-icon material-icons-round mr-5">error_outline</i>
                <p class="text-lightgrey text-xs" translate>該評分是經過系統收集參與義工對申請人的評價後計算的平均數值，在配對義工列表中點擊“反饋”可查看具體評價</p>
            </div>

            <div class="inline-flex-row text-lightgrey text-xs">
                <p class="text-xs" translate>{{ '已有位參與義工評價' | translate: {value : vcount} }}</p>
            </div>
        </div>
    </div>

    <div class="input-row">
        <p class="title align-self-start lh-8" translate>備註</p>
        <p *ngIf="disabled">{{ request.staffComment }}</p>
        <p *ngIf="disabled && request.staffComment == null" translate>暫無</p>
        <textarea *ngIf="disabled == false" style="width: 22rem;height:8.625rem" [(ngModel)]="request.staffComment"></textarea>
    </div>

    <div class="inline-flex-row justify-content-center" style="margin-top: 4.6875rem;">
        <button class="button fill blue" *ngIf="disabled == false" (click)="submitComment()" translate>保 存</button>
    </div>
</div>