<div class="emoji-button" tabindex="1">
    <i class="material-icons-round" style="font-size: 1.25rem;">sentiment_satisfied_alt</i>
    <div class="emoji-popup zoomIn">
        <div class="emoji-category">
            <p class="category-emoji" *ngFor="let emoji of categories" (click)="selectedCategory = emoji">{{ emoji.emoji }}</p>
        </div>
        <div class="emoji-box">
            <p class="emoji" *ngFor="let emoji of filteredEmojis" (click)="onSelect(emoji)">{{ emoji.emoji }}</p>
        </div>
    </div>
</div>