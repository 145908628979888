import { FormControl, Validators } from "@angular/forms";
import { RedeemExportModel } from "../exports/redeem-export-model";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IRedeem {
  memberId: string;
  chineseName: string;
  centerCode: string;
  gender: string;
  dateOfBirth: Date;
  englishName: string;
  redeemCode: string;
  redeemReason: string;
  timeBalance: number;
  uuId: string;
  phone: string;
  redeemDate: Date;
  quantity: number;
  redeemState: string;
  price: number;
  userUUId: string;
  deliveredDate: string;
}

export class Redeem {
  selected: boolean;
  memberId: string;
  chineseName: string;
  centerCode: string;
  gender: string;
  dateOfBirth: Date;
  englishName: string;
  redeemCode: string;
  redeemReason: any;
  timeBalance: number;
  uuId: string;
  phone: string;
  redeemDate: Date;
  quantity: number;
  redeemState: string;
  price: number;
  userUUId: string;
  editing: boolean;
  quantityInput: FormControl;
  deliveredDate: Date;

  constructor(redeem: IRedeem) {
    this.memberId = redeem.memberId;
    this.chineseName = redeem.chineseName;
    this.centerCode = redeem.centerCode;
    this.gender = redeem.gender;
    this.dateOfBirth = dayjs.utc(redeem.dateOfBirth).toDate();
    this.englishName = redeem.englishName;
    this.redeemCode = redeem.redeemCode;
    this.redeemDate = dayjs.utc(redeem.redeemDate).toDate();
    this.redeemReason =
      redeem.redeemReason == null ? null : JSON.parse(redeem.redeemReason);
    this.timeBalance = redeem.timeBalance;
    this.uuId = redeem.uuId;
    this.phone = redeem.phone;
    this.quantity = redeem.quantity;
    this.selected = false;
    this.redeemState = redeem.redeemState;
    this.price = redeem.price;
    this.userUUId = redeem.uuId;
    this.editing = false;
    this.quantityInput = new FormControl(this.quantity, [
      Validators.required,
      Validators.min(1),
    ]);
    this.deliveredDate = dayjs.utc(redeem.deliveredDate).toDate();
  }

  getExportModel(): RedeemExportModel {
    return {
      會員編號: this.memberId,
      姓名: this.chineseName,
      暱稱: this.englishName,
      中心編號: this.centerCode,
      性別: this.gender == "M" ? "男" : this.gender == "F" ? "女" : "",
      年齡: this.age?.toString(),
      電話: this.phone,
      換領數量: this.quantity.toString(),
      交易時分: this.price.toString(),
      換領編碼: this.redeemCode,
      換領時間:
        this.redeemDate == null
          ? "-"
          : dayjs(this.redeemDate).format("YYYY-MM-DD HH:mm"),
      狀態:
        this.redeemState == "Redeemed"
          ? "已兌換"
          : this.redeemState == "Delivered"
            ? "已領取"
            : "等候處理",
      問題1:
        typeof this.redeemReason != "undefined"
          ? this.redeemReason[0]?.question
          : null,
      答案1:
        typeof this.redeemReason.answer != "undefined"
          ? typeof this.redeemReason[0]?.answer == "string"
            ? this.redeemReason[0]?.answer
            : this.redeemReason[0]?.answer.join(", ")
          : null,
      問題2:
        typeof this.redeemReason != "undefined"
          ? this.redeemReason[1]?.question
          : null,
      答案2:
        typeof this.redeemReason.answer != "undefined"
          ? typeof this.redeemReason[1]?.answer == "string"
            ? this.redeemReason[1]?.answer
            : this.redeemReason[1]?.answer.join(", ")
          : null,
      問題3:
        typeof this.redeemReason != "undefined"
          ? this.redeemReason[2]?.question
          : null,
      答案3:
        typeof this.redeemReason.answer != "undefined"
          ? typeof this.redeemReason[2]?.answer == "string"
            ? this.redeemReason[2]?.answer
            : this.redeemReason[2]?.answer.join(", ")
          : null,
      備註: "",
    };
  }

  getExportModelForRedeem(): any {
    return {
      會員編號: this.memberId,
      姓名: this.chineseName,
      英文姓名: this.englishName,
      中心編號: this.centerCode,
      性別: this.gender == "M" ? "男" : this.gender == "F" ? "女" : "",
      年齡: this.age?.toString(),
      電話: this.phone,
      狀態:
        this.redeemState == "Redeemed"
          ? "已兌換"
          : this.redeemState == "Delivered"
            ? "已領取"
            : "等候處理",
      兌換數量: this.quantity,
      交易時分: this.price,
      換領編碼: this.redeemCode,
      兌換時間: dayjs(this.redeemDate).format("YYYY-MM-DD"),
      確認已換領時間: dayjs(this.deliveredDate).format("YYYY-MM-DD"),
    };
  }

  get age(): number | null {
    return dayjs().diff(this.dateOfBirth, "years");
  }

  get hasEnoughBalance(): boolean {
    return this.timeBalance >= this.price;
  }
}
