import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-success-rate-analysis',
  templateUrl: './success-rate-analysis.component.html',
  styleUrls: ['./success-rate-analysis.component.scss']
})
export class SuccessRateAnalysisComponent implements OnInit {

  successRate: number = 0;

  @Input() form!: FormControl;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe(value => {
      this.analyticsService.getRequestSuccessRate(value)
        .subscribe(v => {
          this.successRate = Math.round(v * 100);
        });
    });
  }

  fetch(): void {
    this.analyticsService.getRequestSuccessRate()
      .subscribe(value => {
        this.successRate = Math.round(value * 100) || 0;
      });
  }

}
