import { Component, Input, OnInit } from "@angular/core";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-event-status-filter",
  templateUrl: "./event-status-filter.component.html",
  styleUrls: ["./event-status-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class EventStatusFilterComponent implements OnInit {
  @Input() action: (statuses: number) => void = () => {};
  statuses = [
    { name: "等待參加", selected: false, value: 1 },
    { name: "進行中", selected: false, value: 2 },
    { name: "已完結", selected: false, value: 4 },
    { name: "已終止", selected: false, value: 8 },
    { name: "已修改", selected: false, value: 16 },
    { name: "人數不足", selected: false, value: 32 },
  ];
  display: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  reset(): void {
    this.statuses.forEach((status) => (status.selected = false));
  }

  toggle(): void {
    if (this.display == true) {
      this.action(
        this.statuses
          .filter((s) => s.selected)
          .map((s) => s.value)
          .reduce((sum, value) => sum + value, 0),
      );
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter((status) => status.selected).length;
  }
}
