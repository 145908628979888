import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Center } from "../../../models/centers/center";
import { Product } from "../../../models/redeems/product";
import { UpdateProductModel } from "../../../models/redeems/update-product-model";
import * as dayjs from "dayjs";

export class UpdateProductForm {
  uuId: string;
  formGroup: FormGroup;
  centers: Center[] = [];
  image!: Blob;

  constructor(product: Product) {
    this.uuId = product.uuId;
    this.formGroup = new FormGroup({
      productName: new FormControl(product.productName, [Validators.required]),
      brand: new FormControl(product.brand, [Validators.required]),
      imagePath: new FormControl(product.imagePath),
      format: new FormControl(product.format == "StaffOpting" ? "0" : "1", [
        Validators.required,
      ]),
      price: new FormControl(product.price, [
        Validators.required,
        Validators.min(0),
      ]),
      stock: new FormControl(product.stock, [
        Validators.required,
        Validators.min(-1),
      ]),
      limit: new FormControl(product.maximumQuantityPerPerson, [
        Validators.required,
        Validators.min(-1),
      ]),
      description: new FormControl(product.description, [Validators.required]),
      closeDate: new FormControl(product.closeDate, [Validators.required]),
      launchDate: new FormControl(product.launchDate, [Validators.required]),
      isAnswerQuestionRequired: new FormControl(
        product.isAnswerQuestionRequired,
        [Validators.required],
      ),
      target: new FormControl(product.target),
      free: new FormControl(product.price == 0 ? true : false),
      adequateStock: new FormControl(product.stock == -1 ? true : false),
      noLimit: new FormControl(
        product.maximumQuantityPerPerson == -1 ? true : false,
      ),
      launchImmediately: new FormControl(false),
      targetAll: new FormControl(false),
    });

    if (product.stock == -1) {
      this.formGroup.controls["stock"].disable();
    }

    if (product.maximumQuantityPerPerson == -1) {
      this.formGroup.controls["limit"].disable();
    }

    if (product.price == 0) {
      this.formGroup.controls["price"].disable();
    }

    this.centers = product.centers;

    (
      this.formGroup.controls["targetAll"] as FormControl
    ).valueChanges.subscribe((value) => {
      var control = this.formGroup.controls["target"];

      if (value == true) {
        control.disable();
        control.setValue("所有會員");
      } else {
        control.enable();
        control.setValue(null);
      }
    });

    (
      this.formGroup.controls["launchImmediately"] as FormControl
    ).valueChanges.subscribe((value) => {
      let date = this.formGroup.controls["launchDate"];

      if (value == true) {
        date.disable();
        date.setValue(null);
      } else {
        date.enable();
        date.setValue(null);
      }
    });

    (
      this.formGroup.controls["adequateStock"] as FormControl
    ).valueChanges.subscribe((value) => {
      var control = this.formGroup.controls["stock"];

      if (value == true) {
        control.disable();
        control.setValue(-1);
      } else {
        control.enable();
        control.setValue(null);
      }
    });

    (this.formGroup.controls["noLimit"] as FormControl).valueChanges.subscribe(
      (value) => {
        var control = this.formGroup.controls["limit"];

        if (value == true) {
          control.disable();
          control.setValue(-1);
        } else {
          control.enable();
          control.setValue(null);
        }
      },
    );

    (this.formGroup.controls["free"] as FormControl).valueChanges.subscribe(
      (value) => {
        var control = this.formGroup.controls["price"];

        if (value == true) {
          control.disable();
          control.setValue(0);
        } else {
          control.enable();
          control.setValue(null);
        }
      },
    );
  }

  getFormData(): FormData {
    let date = dayjs().add(3, "minutes").toDate();

    let model: UpdateProductModel = {
      ProductUUId: this.uuId,
      ProductName: this.formGroup.controls["productName"].value,
      Description: this.formGroup.controls["description"].value,
      Price: this.formGroup.controls["price"].value,
      Stock: this.formGroup.controls["stock"].value,
      MaximumQuantityPerPerson: this.formGroup.controls["limit"].value,
      LaunchDate:
        this.formGroup.controls["launchImmediately"].value == true
          ? date.toJSON()
          : (this.formGroup.controls["launchDate"].value as Date).toJSON(),
      CloseDate: (this.formGroup.controls["closeDate"].value as Date).toJSON(),
      Brand: this.formGroup.controls["brand"].value,
      Target: this.formGroup.controls["target"].value,
      Format: this.formGroup.controls["format"].value,
      Image: this.image,
      ImagePath: this.formGroup.controls["imagePath"].value,
      CenterList: this.centers.map((center) => center.uuId),
      IsAnswerQuestionRequired:
        this.formGroup.controls["isAnswerQuestionRequired"].value,
    };

    let data = new FormData();
    Object.keys(model).forEach((key) => {
      if (key == "CenterList") {
        if ((model[key] as string[]).length > 0)
          (model[key] as string[]).forEach((value) => data.append(key, value));
        return;
      }
      data.append(key, model[key]);
    });

    return data;
  }

  getCloseDate(): Date {
    return this.formGroup.controls["closeDate"].value as Date;
  }
}
