import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { ProductListItem } from "src/app/models/redeems/product-list-item";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProductService } from "src/app/services/product/product.service";

@Component({
  selector: "app-redeem-trash-bin",
  templateUrl: "./redeem-trash-bin.component.html",
  styleUrls: ["./redeem-trash-bin.component.scss"],
})
export class RedeemTrashBinComponent implements OnInit {
  products: ProductListItem[] = [];
  caches: ProductListItem[] = [];

  count: number = 0;
  pages: number = 1;

  filters: any = {
    itemsPerPage: 10,
    page: 1,
    searchInput: null,
    from: null,
    to: null,
    statuses: [],
    isDeleted: true,
    centers: [],
    formats: [],
    orderById: true,
    orderByLaunchDate: null,
    orderByCloseDate: null,
  };

  constructor(
    private loadingService: LoadingService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.productService
      .getRedeemList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (result) => {
          this.products = result.list;
          this.count = result.count;
          this.pages = Math.ceil(result.count / this.filters.itemsPerPage);
          this.products.forEach((product) => {
            if (this.caches.find((p) => p.uuId == product.uuId))
              product.selected = true;
          });
        },
      });
  }

  changePage(): void {
    this.router.navigate(["/main/trash/redeem"], {
      queryParams: {
        page: this.filters.page,
        itemsPerPage: this.filters.itemsPerPage,
      },
    });
  }

  cache(redeem: ProductListItem): void {
    if (redeem.selected) this.caches.push(redeem);
    else
      this.caches.splice(
        this.caches.findIndex((p) => p.uuId == redeem.uuId),
        1,
      );
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  typeFilterAction: (formats: number[]) => void = ((formats: number[]) => {
    this.filters.formats = formats;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction: (start: Date, end: Date) => void = ((
    start: Date,
    end: Date,
  ) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction: (statuses: number[]) => void = ((statuses: number[]) => {
    this.filters.statuses = statuses;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  private getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      (this.filters.from
        ? "&LaunchDateFrom=" + this.filters.from.toJSON()
        : "") +
      (this.filters.to ? "&LaunchDateTo=" + this.filters.to.toJSON() : "") +
      this.filters.statuses
        .map((status: string) => "&statuses=" + status)
        .join("") +
      (this.filters.orderById != null
        ? "&OrderById=" + String(this.filters.orderById)
        : "") +
      (this.filters.orderByLaunchDate != null
        ? "&OrderByLaunchDate=" + String(this.filters.orderByLaunchDate)
        : "") +
      (this.filters.orderByCloseDate != null
        ? "&OrderByCloseDate=" + String(this.filters.orderByCloseDate)
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.formats
        .map((format: string) => "&productRedeemFormats=" + format)
        .join("") +
      "&isDeleted=true"
    );
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.products.forEach((product) => {
        product.selected = false;
        this.caches.splice(
          this.caches.findIndex((r) => r.uuId == product.uuId),
          1,
        );
      });
    else {
      this.products.forEach((product) => (product.selected = true));
      this.caches = [...new Set([...this.caches, ...this.products])];
    }
  }

  get isSelectedAll(): boolean {
    return this.products.every((product) => product.selected);
  }
}
