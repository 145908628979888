import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalysisPageComponent } from './pages/analysis-page/analysis-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BalanceAnalysisComponent } from './components/balance-analysis/balance-analysis.component';
import { ApplicationAnalysisComponent } from './components/application-analysis/application-analysis.component';
import { SuccessRateAnalysisComponent } from './components/success-rate-analysis/success-rate-analysis.component';
import { ChartAnalysisComponent } from './components/chart-analysis/chart-analysis.component';
import { InactiveUserAnalysisComponent } from './components/inactive-user-analysis/inactive-user-analysis.component';
import { ServiceRatioAnalysisComponent } from './components/service-ratio-analysis/service-ratio-analysis.component';
import { UserRankingAnalysisComponent } from './components/user-ranking-analysis/user-ranking-analysis.component';
import { ShareModule } from 'src/app/modules/share/share.module';
import { CellGroupDataAnalysisComponent } from './components/cell-group-data-analysis/cell-group-data-analysis.component';

const routes: Routes = [
  { path: 'report', component: AnalysisPageComponent },
]

@NgModule({
  declarations: [
    AnalysisPageComponent,
    BalanceAnalysisComponent,
    ApplicationAnalysisComponent,
    SuccessRateAnalysisComponent,
    ChartAnalysisComponent,
    InactiveUserAnalysisComponent,
    ServiceRatioAnalysisComponent,
    UserRankingAnalysisComponent,
    CellGroupDataAnalysisComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    ShareModule
  ],
  exports: [
    BalanceAnalysisComponent,
    ApplicationAnalysisComponent,
    SuccessRateAnalysisComponent
  ]
})
export class AnalyticsModule { }
