<div class="box">
    <p class="title" translate>身體狀況</p>
    <div class="inline-flex-column">
        <form class="input-row" [formGroup]="form" style="width: 36.875rem;">
            <input type="text" class="user-input sm" formControlName="name" placeholder="{{ '新增項目' | translate }}">
            <i class="material-icons-round ml-3 click-text-lightblue" style="font-size: 1rem;" (click)="form.controls['isShowInRequest'].setValue(false)" *ngIf="form.controls['isShowInRequest'].value">visibility</i>
            <i class="material-icons-outlined ml-3 click-text-lightblue" style="font-size: 1rem;" (click)="form.controls['isShowInRequest'].setValue(true)" *ngIf="form.controls['isShowInRequest'].value == false">visibility_off</i>
            <p class="click-text-blue text-xs ml-3" (click)="create()" translate>保存</p>
        </form>

        <div class="health-box">
            <div class="health-item" *ngFor="let health of healths">
                <ng-container *ngIf="health.editing == false;else editing">
                    <p class="name">{{ health.name }}</p>
                    <i class="material-icons-round mr-3" style="font-size: 1rem;color: rgba(0, 0, 0, 0.3);" *ngIf="health.isShowInRequest">visibility</i>
                    <i class="material-icons-outlined mr-3" style="font-size: 1rem;color: rgba(0, 0, 0, 0.3);" *ngIf="health.isShowInRequest == false">visibility_off</i>
                    <i class="click-text-lightblue material-icons-outlined create-icon" (click)="health.editing = true">create</i>
                </ng-container>
                <ng-template #editing>
                    <form class="inline-flex-row align-items-center" [formGroup]="health.form">
                        <input type="text" class="user-input sm" formControlName="name" placeholder="{{ '身體狀況' | translate }}">
                        <i class="material-icons-round ml-3 click-text-lightblue" style="font-size: 1rem;" (click)="updateVisibility(health, false)" *ngIf="health.isShowInRequest">visibility</i>
                        <i class="material-icons-outlined ml-3 click-text-lightblue" style="font-size: 1rem;" (click)="updateVisibility(health, true)" *ngIf="health.isShowInRequest == false">visibility_off</i>
                        <p class="click-text-blue text-xs ml-3" (click)="update(health)" translate>保存</p>
                        <p class="click-text-blue text-xs ml-3" (click)="health.editing = false" translate>取消</p>
                    </form>
                </ng-template>
            </div>
        </div>

    </div>

    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>