import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-balance-analysis',
  templateUrl: './balance-analysis.component.html',
  styleUrls: ['./balance-analysis.component.scss']
})
export class BalanceAnalysisComponent implements OnInit {
  //Total
  public totalTimeBalance: number = 0;
  public averageTimeBalance: number = 0;

  //Request
  public requestTotalTimeBalance: number = 0;
  public requestAverageTimeBalance: number = 0;

  //Event
  public eventTotalTimeBalance: number = 0;
  public eventAverageTimeBalance: number = 0;

  @Input() form!: FormControl;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe(value => {
      this.fetch(value);
    });
  }

  fetch(centerUUId?: string) {
    forkJoin({
      totalTimeBalance: this.analyticsService.getTotalTimeBalance(centerUUId),
      averageTimeBalance: this.analyticsService.getAverageTimeBalance(centerUUId),
      requestTotalTimeBalance: this.analyticsService.getRequestTotalTimeBalance(centerUUId),
      requestAverageTimeBalance: this.analyticsService.getRequestAverageTimeBalance(centerUUId),
      eventTotalTimeBalance: this.analyticsService.getEventTotalTimeBalance(centerUUId),
      eventAverageTimeBalance: this.analyticsService.getEventAverageTimeBalance(centerUUId)
    }).subscribe(value => {
      this.totalTimeBalance = value.totalTimeBalance;
      this.averageTimeBalance = value.averageTimeBalance;
      this.requestTotalTimeBalance = value.requestTotalTimeBalance;
      this.requestAverageTimeBalance = value.requestAverageTimeBalance;
      this.eventTotalTimeBalance = value.eventTotalTimeBalance;
      this.eventAverageTimeBalance = value.eventAverageTimeBalance;
    });
  }

}
