import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { Room } from "src/app/models/addresses/room";
import { CreateEventModel } from "src/app/models/events/create-event-model";
import { CreateEventTransactionModel } from "src/app/models/events/create-event-transaction-model";
import { IEvent, Event } from "src/app/models/events/event";
import {
  EventListItem,
  IEventListItem,
} from "src/app/models/events/event-list-item";
import { UpdateEventDayModel } from "src/app/models/events/update-event-day-model";
import { UpdateEventModel } from "src/app/models/events/update-event-model";
import { EventImportModel } from "src/app/models/imports/event-import-model";
import { CreateEventForm } from "src/app/modules/event/forms/create-event-form";
import { CreateEventRecordForm } from "src/app/modules/event/forms/create-event-record-form";

@Injectable({
  providedIn: "root",
})
export class EventService {
  form: CreateEventForm = new CreateEventForm();
  eventRecordForm: CreateEventRecordForm = new CreateEventRecordForm();

  constructor(private httpService: HttpService) {}

  public getEvents(
    queryString: string,
  ): Observable<{ list: EventListItem[]; count: number }> {
    return this.httpService
      .get<{ list: IEventListItem[]; count: number }>("events" + queryString)
      .pipe(
        map((value) => {
          return {
            list: value.result.list.map((e) => new EventListItem(e)),
            count: value.result.count,
          };
        }),
      );
  }

  public getEventByUUId(uuId: string): Observable<Event> {
    return this.httpService
      .get<IEvent>("events/" + uuId)
      .pipe(map((value) => new Event(value.result)));
  }

  public createEvent(model: CreateEventModel): Observable<any> {
    return this.httpService.post<any, CreateEventModel>("events", model);
  }

  public updateEvent(
    eventUUId: string,
    model: UpdateEventModel,
  ): Observable<any> {
    return this.httpService.put<any, UpdateEventModel>(
      "events/" + eventUUId,
      model,
    );
  }

  /** Delete Events */
  public deleteEvents(model: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("events/delete", {
      list: model,
    });
  }

  public terminate(
    eventUUId: string,
    model: { CancelEventReason: string },
  ): Observable<any> {
    return this.httpService.post<any, { CancelEventReason: string }>(
      "events/" + eventUUId + "/Cancel",
      model,
    );
  }

  public complete(eventUUId: string): Observable<any> {
    return this.httpService.post<any, any>(
      "events/" + eventUUId + "/Complete",
      null,
    );
  }

  public addParticipantsFromExistingEvent(
    eventUUId: string,
    model: { users: string[]; role: string },
  ): Observable<any> {
    return this.httpService.post<any, { users: string[]; role: string }>(
      "events/" + eventUUId + "/addParticipants",
      model,
    );
  }

  public removeParticipants(
    eventUUId: string,
    model: { userList: string[]; role: string },
  ): Observable<any> {
    return this.httpService.put<any, { userList: string[]; role: string }>(
      "events/" + eventUUId + "/removeParticipants",
      model,
    );
  }

  public acceptInvitation(
    eventUUId: string,
    model: { list: string[] },
  ): Observable<any> {
    return this.httpService.post<any, { list: string[] }>(
      "events/" + eventUUId + "/interestStaff",
      model,
    );
  }

  public joinEvent(
    eventUUId: string,
    model: { list: string[] },
  ): Observable<any> {
    return this.httpService.post<any, { list: string[] }>(
      "events/" + eventUUId + "/applyStaff",
      model,
    );
  }

  public unselect(
    eventUUId: string,
    model: { list: string[] },
  ): Observable<any> {
    return this.httpService.post<any, { list: string[] }>(
      "events/" + eventUUId + "/unselectStaff",
      model,
    );
  }

  public absent(eventUUId: string, model: { list: string[] }): Observable<any> {
    return this.httpService.post<any, { list: string[] }>(
      "events/" + eventUUId + "/AbsentStaff",
      model,
    );
  }

  public createEventTransaction(
    eventUUId: string,
    model: CreateEventTransactionModel,
  ): Observable<{ userUUId: string; transactionUUId: string }[]> {
    return this.httpService
      .post<
        { userUUId: string; transactionUUId: string }[],
        CreateEventTransactionModel
      >("events/" + eventUUId + "/transactions", model)
      .pipe(map((value) => value.result));
  }

  /** Restore the list of deleted events */
  public restoreEvents(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>(
      "events/restore",
      model,
    );
  }

  /** Delete Event Permanently */
  public deleteEventsPermanently(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>(
      "events/deletePermanently",
      model,
    );
  }

  public importEvent(model: EventImportModel): Observable<any> {
    return this.httpService.post<any, EventImportModel>("events/import", model);
  }

  public SetAttendance(model: UpdateEventDayModel): Observable<any> {
    return this.httpService.put<any, UpdateEventDayModel>(
      "events/EditEventAttendance",
      model,
    );
  }

  public getNAAddress(): Observable<Room> {
    return this.httpService
      .get<Room>("addresses/GetNA")
      .pipe(map((value) => value.result));
  }
  public getUrgentNum(): Observable<Number> {
    return this.httpService
      .get<Number>("events/urgentNum")
      .pipe(map((value) => Number(value.result)));
  }
}
