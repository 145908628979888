export const AppSetting = {
  excelUrl: "https://hku.hincaretb.com/excels/",
  baseUrl: "https://hku.hincaretb.com/api/",

  timeoutValue: 60000,

  orgName: "將軍澳聖公會老人服務中心 · 織福計劃",

  centerUUId: "00000000-0000-0000-0000-000000000001",

  firebaseConfig: {
    apiKey: "AIzaSyCA78PwtBolkQ2uXP2eWLGcCaJiILQNlss",
    authDomain: "timebank-hku.firebaseapp.com",
    databaseURL: "https://timebank-hku.firebaseio.com",
    projectId: "timebank-hku",
    storageBucket: "timebank-hku.appspot.com",
    messagingSenderId: "796090772356",
    appId: "1:796090772356:web:5807ce0d717e43c3120bd1",
    measurementId: "G-TPK3ZQKDE4",
  },

  tokenKey: "timebank_token",
  roleKey: "timebank_roles",
  remainDays: 14,
};
