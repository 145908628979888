import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-organization-setting-container",
  templateUrl: "./organization-setting-container.component.html",
  styleUrls: ["./organization-setting-container.component.scss"],
})
export class OrganizationSettingContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
