import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { EventType } from 'src/app/models/event-types/event-type';
import { EventTypeService } from 'src/app/services/event-type/event-type.service';

@Component({
  selector: 'app-event-type-setting',
  templateUrl: './event-type-setting.component.html',
  styleUrls: ['./event-type-setting.component.scss']
})
export class EventTypeSettingComponent implements OnInit {

  eventTypes: EventType[] = [];
  loading: boolean = false;
  form: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required])
  });

  constructor(
    private eventTypeService: EventTypeService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.eventTypeService.getEventTypeList()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: value => {
          this.eventTypes = value;
        }
      })
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.eventTypeService.createEventType({
      code: this.form.controls['code'].value,
      name: this.form.controls['name'].value
    }).pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.form.reset();
          this.fetch();
        }
      });
  }

  update(eventType: EventType): void {
    if (eventType.form.invalid == true) {
      eventType.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.eventTypeService.updateEventType(eventType.getUpdateEventTypeModel())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.fetch();
        }
      })
  }

}
