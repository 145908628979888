import { Component, Input, OnInit } from '@angular/core';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-announcement-status-filter',
  templateUrl: './announcement-status-filter.component.html',
  styleUrls: ['./announcement-status-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class AnnouncementStatusFilterComponent implements OnInit {

  @Input() action: (statuses: string[]) => void = () => { }
  statuses = [
    { name: "未發布", selected: false, value: 'Pending' },
    { name: "已發布", selected: false, value: 'Announced' },
    { name: "已修改", selected: false, value: 'Edited' },
    { name: "已終止", selected: false, value: 'Deactivated' },
    { name: "已完結", selected: false, value: 'Expired' }
  ];
  display: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  reset(): void {
    this.statuses.forEach(status => status.selected = false);
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.statuses.filter(status => status.selected).map(status => status.value));
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter(status => status.selected).length;
  }

}
