<div class="analytics-container">
  <div class="inline-flex-row" style="padding: 0.5rem 1rem;">
    <p class="text-lightgrey flex-1" translate>申請量</p>
    <i matTooltip="{{ '今年申請量 - 由本年度 1月1號 至 12月31號的總義工服務申請數量。' | translate }}" [matTooltipPosition]="'above'"
      class="material-icons-outlined text-lightgrey" style="font-size: 1rem">info</i>
  </div>

  <p class="text-xxl" style="padding-left: 1rem;">{{ totalRequestCount }}</p>

  <div class="inline-flex-row align-self-center justify-content-center mb-4 mr-4 flex-1">
    <canvas #graph></canvas>
  </div>

  <div class="border-bottom ml-8 mr-8"></div>

  <div class="inline-flex-row align-items-center" style="padding: 0.5rem 1rem;">
    <p class="text-xs text-lightgrey" translate>日申請量</p>
    <p class="text-xs ml-4">{{ averageRequestCount | number:'1.0-0' }}</p>
  </div>
</div>
