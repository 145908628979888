import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ChartData } from 'chart.js';

@Component({
  selector: 'app-simple-table-box',
  templateUrl: './simple-table-box.component.html',
  styleUrls: ['./simple-table-box.component.scss']
})
export class SimpleTableBoxComponent implements OnInit,OnChanges  {

  @Input() displayList!:[];
  @ContentChild('row', {static: true}) tplRef!: TemplateRef<any>;
  pageIndex:number=0;

  public reset() {
    this.pageIndex=0;
  }
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void{
    
    this.pageIndex=0;
  } 
  ngOnInit(): void {
  }
  get displayListPage():number[]{
    this.pageIndex;
    var n:number[]=[];

    if( this.pageIndex+5>this.MaxPage){
      for(var i=Math.max(this.MaxPage-5,0);i<this.MaxPage;i++){
        n.push(i+1);
      }
    }else{
      if( this.pageIndex==0){
        for(var i=this.pageIndex;i<Math.min(this.MaxPage,this.pageIndex+5);i++){
          n.push(i+1);
        }
      }else{
        for(var i=this.pageIndex-1;i<Math.min(this.MaxPage,this.pageIndex+4);i++){
          n.push(i+1);
        }
      }
    }

    return n;
  }
  get MaxPage():number{
    return Math.ceil(this.displayList.length/6);
  }
  prevPage():void{
    this.pageIndex--;
    this.pageIndex=Math.max(this.pageIndex,0);
  }
  nextPage():void{
    this.pageIndex++;

    this.pageIndex=Math.min(this.pageIndex,this.MaxPage-1);

  }
  jumpPage(i:number):void{
    this.pageIndex=i-1;
  }
}
