<div class="availability-table">
    <div class="availability-table-column cbox">
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(0)" (click)="selectAllTimeSlot(0)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(1)" (click)="selectAllTimeSlot(1)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(2)" (click)="selectAllTimeSlot(2)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(3)" (click)="selectAllTimeSlot(3)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(4)" (click)="selectAllTimeSlot(4)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(5)" (click)="selectAllTimeSlot(5)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(6)" (click)="selectAllTimeSlot(6)" [disabled]="readonly">
        </div>
        <div class="availability-table-cell">
            <input type="checkbox" [ngModel]="getTimeslotAllSelected(7)" (click)="selectAllTimeSlot(7)" [disabled]="readonly">
        </div>
    </div>

    <div class="availability-table-column time">
        <div class="availability-table-cell">
            <p translate>全部時間</p>
        </div>
        <div class="availability-table-cell">
            <p translate>上午 6 至 8 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>上午 8 至 10 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>上午 10 至 12 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>中午 12 至 1 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>下午 1 至 3 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>下午 3 至 5 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>下午 5 至 7 時</p>
        </div>
        <div class="availability-table-cell">
            <p translate>下午 7 至 9 時</p>
        </div>
    </div>

    <div class="availability-table-column weekday" *ngFor="let day of days">
        <div class="availability-table-cell">
            <input class="mr-4" type="checkbox" [ngModel]="day.isSelectedAll" (click)="day.selectAll()" [disabled]="readonly">
            <p>{{ day.name | translate }}</p>
        </div>
        <div class="availability-table-time-cell" [ngClass]="{active: time.selected, disabled: readonly}" (click)="time.selected = !time.selected" *ngFor="let time of day.times"></div>
    </div>
</div>