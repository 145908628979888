import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Address, IAddress } from "../addresses/address";
import { IAgent, Agent } from "../profiles/agent";
import { IService, Service } from "../services/service";
import { ITransaction, Transaction } from "../transactions/transaction";
import {
  ITransportation,
  Transportation,
} from "../transportations/transportation";
import { IRequester, Requester } from "./requester";
import { IVolunteer, Volunteer } from "./volunteer";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface IRequest {
  uuId: string;
  agent: IAgent;
  cancelRemark: string;
  centerDonate: ITransaction;
  checkInTime: Date;
  checkOutTime: Date;
  createdAt: Date;
  destination: IAddress;
  endTime: Date;
  id: number;
  numberOfVolunteer: number;
  remark: string;
  requester: IRequester;
  requestStatus: string;
  service: IService;
  staffComment: string;
  startTime: Date;
  transportation: ITransportation;
  venue: IAddress;
  volunteers: IVolunteer[];
  extraRequestJson: any;
}

export class Request {
  uuId: string;
  agent: Agent | null;
  cancelRemark: string;
  centerDonate: Transaction | null;
  checkInTime: Date | null;
  checkOutTime: Date | null;
  createdAt: Date;
  destination: Address;
  endTime: Date;
  id: number;
  numberOfVolunteer: number;
  remark: string;
  requester: Requester;
  requestStatus: string;
  service: Service;
  staffComment: string;
  startTime: Date;
  transportation: Transportation | null;
  venue: Address;
  volunteers: Volunteer[];
  extraRequestJson: any;

  form: FormGroup;
  // editing: boolean;
  // remarkEdit: boolean;

  constructor(request: IRequest) {
    this.uuId = request.uuId;
    this.agent = request.agent ? new Agent(request.agent) : null;
    this.cancelRemark = request.cancelRemark;
    this.centerDonate = request.centerDonate
      ? new Transaction(request.centerDonate)
      : null;
    this.checkInTime = request.checkInTime
      ? dayjs.utc(request.checkInTime).toDate()
      : null;
    this.checkOutTime = request.checkOutTime
      ? dayjs.utc(request.checkOutTime).toDate()
      : null;
    this.createdAt = dayjs.utc(request.createdAt).toDate();
    this.destination = new Address(request.destination);
    this.endTime = dayjs.utc(request.endTime).toDate();
    this.id = request.id;
    this.numberOfVolunteer = request.numberOfVolunteer;
    this.remark = request.remark;
    this.requester = new Requester(request.requester);
    this.requestStatus = request.requestStatus;
    this.service = new Service(request.service);
    this.staffComment = request.staffComment;
    this.startTime = dayjs.utc(request.startTime).toDate();
    this.transportation = request.transportation
      ? new Transportation(request.transportation)
      : null;
    this.venue = new Address(request.venue);
    this.volunteers = request.volunteers
      .map((volunteer) => new Volunteer(volunteer))
      .sort(VolunteerSorting);
    this.extraRequestJson = request.extraRequestJson
      ? JSON.parse(request.extraRequestJson)
      : null;

    this.form = new FormGroup({
      actualStart: new FormControl(this.checkInTime ? this.checkInTime : null, [
        Validators.required,
      ]),
      actualEnd: new FormControl(this.checkOutTime ? this.checkOutTime : null, [
        Validators.required,
      ]),
      transportation: new FormControl(this.transportation?.uuId),
    });
    // this.editing = false;
    // this.remarkEdit=false;
  }

  get matchingOvertime(): boolean {
    let date = dayjs().add(2, "days").toDate();
    return this.requestStatus == "Pending" && this.startTime < date;
  }

  get startOvertime(): boolean {
    return this.requestStatus == "Ready" && this.startTime < new Date();
  }

  get endOvertime(): boolean {
    return this.requestStatus == "Started" && this.endTime < new Date();
  }

  get isAccepted(): boolean {
    return this.volunteers.some(
      (volunteer) => volunteer.volunteerStatus == "Interested",
    );
  }

  get numberOfMatchedVolunteers(): number {
    return this.volunteers.filter(
      (volunteer) => volunteer.volunteerStatus == "Confirmed",
    ).length;
  }

  get numberOfCheckedInVolunteers(): number {
    return this.volunteers.filter(
      (volunteer) =>
        volunteer.volunteerStatus == "CheckedIn" ||
        volunteer.volunteerStatus == "CheckedOut",
    ).length;
  }

  get latestCheckedInTime(): Date | null {
    let checkedInVolunteers = this.volunteers.filter(
      (volunteer) => volunteer.checkedInTime != null,
    );

    if (checkedInVolunteers.length == 0) return null;

    return checkedInVolunteers
      .map((volunteer) => volunteer.checkedInTime)
      .reduce((previous, current) =>
        (previous as Date) > (current as Date) ? previous : current,
      );
  }

  get actualStartForm(): AbstractControl {
    return this.form.controls["actualStart"];
  }

  get actualEndForm(): AbstractControl {
    return this.form.controls["actualEnd"];
  }

  get transportationForm(): AbstractControl {
    return this.form.controls["transportation"];
  }

  get remarkForm(): AbstractControl {
    return this.form.controls["remark"];
  }
}

function VolunteerSorting(a: Volunteer, b: Volunteer): number {
  return a.volunteerStatus == "Rejected"
    ? 1
    : b.volunteerStatus == "Rejected"
      ? -1
      : a.volunteerStatus == "Asked" || a.volunteerStatus == "Invited"
        ? 2
        : b.volunteerStatus == "Asked" || b.volunteerStatus == "Invited"
          ? -2
          : a.volunteerStatus == "Interested"
            ? 3
            : b.volunteerStatus == "Interested"
              ? -3
              : a.volunteerStatus == "Confirmed"
                ? 4
                : b.volunteerStatus == "Confirmed"
                  ? -4
                  : a.volunteerStatus == "CheckedIn"
                    ? 5
                    : b.volunteerStatus == "CheckedIn"
                      ? -5
                      : a.volunteerStatus == "CheckedOut"
                        ? 6
                        : b.volunteerStatus == "CheckedOut"
                          ? -6
                          : 0;
}
