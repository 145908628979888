import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  animateChild,
  group,
} from "@angular/animations";

export class PortalAnimation {
  public static DropDown = [
    trigger("dropdown", [
      state("on", style({})),
      state(
        "off",
        style({
          overflow: "hidden",
          height: "0px",
          "box-shadow": "none",
          "border-color": "transparent",
        }),
      ),
      transition("on => off", [animate("0.25s")]),
      transition("off => on", [animate("0.25s")]),
    ]),
  ];

  public static SystemPageSlide = [
    trigger("slide", [
      transition(
        "time => event, time => data, time => organization, event => data, event => organization, data => organization",
        [
          style({ position: "relative" }),
          query(":enter, :leave", [
            style({
              position: "absolute",
              width: "100%",
            }),
          ]),
          query(":enter", [style({ left: "-100%", opacity: 0 })]),
          query(":leave", animateChild()),
          group([
            query(":leave", [
              animate("0.5s ease-out", style({ left: "100%", opacity: 0 })),
            ]),
            query(":enter", [
              animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
            ]),
          ]),
          query(":enter", animateChild()),
        ],
      ),
      transition(
        "event => time, organization => time, data => time, data => event, organization => data, organization => event",
        [
          style({ position: "relative" }),
          query(":enter, :leave", [
            style({
              position: "absolute",
              width: "100%",
            }),
          ]),
          query(":enter", [style({ left: "100%", opacity: 0 })]),
          query(":leave", animateChild()),
          group([
            query(":leave", [
              animate("0.5s ease-out", style({ left: "-100%", opacity: 0 })),
            ]),
            query(":enter", [
              animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
            ]),
          ]),
          query(":enter", animateChild()),
        ],
      ),
    ]),
  ];

  public static UserPageSlide = [
    trigger("slide", [
      transition("transaction => profile", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "-100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
      transition("profile => transaction", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "-100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
    ]),
  ];

  public static DonationPageSlide = [
    trigger("slide", [
      transition("overview => center, overview => user, center => user", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "-100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
      transition("center => overview, user => center, user => overview", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "-100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
    ]),
  ];

  public static ActivityPageSlide = [
    trigger("slide", [
      transition("event => request", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "-100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
      transition("request => event", [
        style({ position: "relative" }),
        query(":enter, :leave", [
          style({
            position: "absolute",
            width: "100%",
          }),
        ]),
        query(":enter", [style({ left: "100%", opacity: 0 })]),
        query(":leave", animateChild()),
        group([
          query(":leave", [
            animate("0.5s ease-out", style({ left: "-100%", opacity: 0 })),
          ]),
          query(":enter", [
            animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
          ]),
        ]),
        query(":enter", animateChild()),
      ]),
    ]),
  ];

  public static TrashPageSlide = [
    trigger("slide", [
      transition(
        "redeem => donation, redeem => user, redeem => event, redeem => request, donation => user, donation => event, donation => request, user => event, user => request, event => request",
        [
          style({ position: "relative" }),
          query(":enter, :leave", [
            style({
              position: "absolute",
              width: "100%",
            }),
          ]),
          query(":enter", [style({ left: "-100%", opacity: 0 })]),
          query(":leave", animateChild()),
          group([
            query(":leave", [
              animate("0.5s ease-out", style({ left: "100%", opacity: 0 })),
            ]),
            query(":enter", [
              animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
            ]),
          ]),
          query(":enter", animateChild()),
        ],
      ),
      transition(
        "request => event, request => user, request => donation, request => redeem, event => user, event => donation, event => redeem, user => donation, user => redeem, donation => redeem",
        [
          style({ position: "relative" }),
          query(":enter, :leave", [
            style({
              position: "absolute",
              width: "100%",
            }),
          ]),
          query(":enter", [style({ left: "100%", opacity: 0 })]),
          query(":leave", animateChild()),
          group([
            query(":leave", [
              animate("0.5s ease-out", style({ left: "-100%", opacity: 0 })),
            ]),
            query(":enter", [
              animate("0.5s ease-out", style({ left: "0%", opacity: 1 })),
            ]),
          ]),
          query(":enter", animateChild()),
        ],
      ),
    ]),
  ];
}
