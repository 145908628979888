import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Profile } from "src/app/models/profiles/profile";
import { RedeemQuestion } from "src/app/models/redeem-questions/redeem-question";
import { Product } from "src/app/models/redeems/product";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-redeem-grant-popup",
  templateUrl: "./redeem-grant-popup.component.html",
  styleUrls: ["./redeem-grant-popup.component.scss"],
})
export class RedeemGrantPopupComponent implements OnInit {
  @Input() user: Profile | null = null;
  @Input() product: Product | null = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  quantity: FormControl = new FormControl(null, [
    Validators.required,
    Validators.min(1),
  ]);
  questions: RedeemQuestion[] = [];

  constructor(
    private redeemService: RedeemService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.redeemService.getQuestions().subscribe({
      next: (value) => (this.questions = value),
    });
  }

  close(): void {
    this.redeemService.user = null;
    this.user = null;
  }

  submit(): void {
    if (this.quantity.invalid) {
      this.quantity.markAllAsTouched();
      return;
    }

    if (
      this.product?.isAnswerQuestionRequired == true &&
      this.questions.some((question) =>
        question.options.every((option) => option.selected == false),
      )
    ) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.MissingRedeemReasonMessage,
      );
      return;
    }

    if (
      (this.product as Product).maximumQuantityPerPerson != -1 &&
      this.product?.stock != -1 &&
      this.quantity.value > (this.product as Product).maximumQuantityPerPerson
    ) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.ExceedRedeemLimitMessage,
      );
      return;
    }

    this.loadingService.startLoading();
    this.redeemService
      .grant(
        this.product?.uuId as string,
        this.user?.uuId as string,
        this.quantity.value,
        JSON.stringify(
          this.questions.map((question) => question.toJsonFormat()),
        ),
      )
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.GrantRedeemSuccessMessage,
          );
          this.redeemService.user = null;
          this.user = null;
          this.refresh.emit();
        },
        error: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.GrantRedeemFailMessage,
          );
          this.redeemService.user = null;
          this.user = null;
        },
      });
  }
}
