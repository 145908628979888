import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { defaultIfEmpty, finalize, switchMap, tap } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Living } from 'src/app/models/livings/living';
import { Profile } from 'src/app/models/profiles/profile';
import { Service } from 'src/app/models/services/service';
import { ServiceType } from 'src/app/models/services/service-type';
import { Transportation } from 'src/app/models/transportations/transportation';
import { AddressForm } from 'src/app/modules/share/forms/address-form';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ServiceService } from 'src/app/services/service/service.service';
import { TemplateService } from 'src/app/services/template/template.service';
import { TransportationService } from 'src/app/services/transportation/transportation.service';
import { CreateRequestForm } from '../../forms/create-request-form';

@Component({
  selector: 'app-request-create',
  templateUrl: './request-create.component.html',
  styleUrls: ['./request-create.component.scss']
})
export class RequestCreateComponent implements OnInit {

  form: CreateRequestForm = this.requestService.form;
  venueForms: AddressForm[] = [];
  destinationForms: AddressForm[] = [];

  minDate: Date = new Date();

  requester: Profile | null = null;
  serviceTypes: ServiceType[] = [];
  transportations: Transportation[] = [];

  constructor(
    private requestService: RequestService,
    private profileService: ProfileService,
    private serviceService: ServiceService,
    private loadingService: LoadingService,
    private transportationService: TransportationService,
    private popupMessageService: PopupMessageService,
    private templateService: TemplateService,
    private organizationService: OrganizationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form.formGroup.disable({ onlySelf: true, emitEvent: false });

    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      profile: this.form.requester == null ? of(null) : this.profileService.getProfileByUUId(this.form.requester),
      service: this.serviceService.getServiceTypeList(),
      transportation: this.transportationService.getTransportationList(),
      templates: this.templateService.getRequestColumns()
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.requester = value.profile;
        this.serviceTypes = value.service;
        this.transportations = value.transportation;
        if (value.profile != null){
          this.form.formGroup.enable({ onlySelf: true, emitEvent: false });

          if(this.requester?.addresses.find(address => address.room.uuId == this.venue.value)==null){
            this.form.formGroup.controls['venue'].setValue(null);
          }
        }
        if (this.form.formGroup.controls['service'].value) {
          let st = this.serviceTypes.find(serviceType => serviceType.services.some(s => s.uuId == this.form.formGroup.controls['service'].value));
          this.form.formGroup.controls['serviceType'].setValue(st?.uuId, { emitEvent: false });
        }

        if (this.form.columns.length == 0) {
          this.form.columns = value.templates.map(column => {
            return {
              columnName: column.columnName,
              columnDescription: column.columnDescription,
              input: new FormControl({ value: null, disabled: this.form.formGroup.disabled })
            }
          })
        }else{
          this.form.columns.forEach(col=>{
            if( this.form.formGroup.disabled)
              col.input.disable();
            else
              col.input.enable();
          })
        }
      });
  }

  addVenue(): void {
    this.venueForms.push(new AddressForm());
  }

  saveVenue(): void {
    if (this.venueForms.length == 0 || !this.venueForms.every(form => form.formGroup.valid)) {
      this.venueForms.forEach(form => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();

    forkJoin(this.venueForms.map(form => this.profileService.createAddress(this.requester?.uuId as string, form.getCreateAddressModel())))
      .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.venueForms = [];
        this.fetch();
      })
  }

  dropVenue = (() => {
    this.venueForms = [];
  }).bind(this)

  addDestination(): void {
    this.destinationForms.push(new AddressForm());
  }

  saveDestination(): void {
    if (this.destinationForms.length == 0 || !this.destinationForms.every(form => form.formGroup.valid)) {
      this.destinationForms.forEach(form => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();

    forkJoin(this.destinationForms.map(form => this.profileService.createAddress(this.requester?.uuId as string, form.getCreateAddressModel())))
      .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.destinationForms = [];
        this.fetch();
      })
  }

  dropDestination = (() => {
    this.destinationForms = [];
  }).bind(this)

  reset(): void {
    this.requester = null;
    this.form.reset();
    this.form.formGroup.disable();
  }
  
  dropVolunteer(index: number) {
    this.form.volunteers.splice(index, 1);
  }


  submit(): void {
    if (this.form.valid == false || this.form.volunteers.length == 0) {
      this.form.formGroup.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();
    this.organizationService.getOrganizationSetting()
      .pipe(switchMap(value => {
        console.log(value);
        if (value.DirectConfirm == true) {
          return this.requestService.createRequest(this.form.requester as string, this.form.getCreateRequestModel())
            .pipe(tap((value: any) => this.newRequestUUId = value.result.uuid),
              switchMap((value: any) => this.requestService.inviteVolunteers(value.result.uuid, { volunteersUUId: this.form.volunteers.map(v => v.userUUId) })),
              finalize(() => this.loadingService.stopLoading()))
        }
        else {
          return this.requestService.createRequest(this.form.requester as string, this.form.getCreateRequestModel())
            .pipe(tap((value: any) => this.newRequestUUId = value.result.uuid),
              switchMap((value: any) => this.requestService.askForInterest(value.result.uuid, { volunteersUUId: this.form.volunteers.map(v => v.userUUId) })),
              finalize(() => this.loadingService.stopLoading()))
        }
      }))
      .subscribe(value => {
        this.popupMessageService.messageSignal.emit(PopupMessages.CreateRequestSuccessMessage(
          new Observable<any>(subscriber => {
            this.router.navigate(['/main/activity/overview/request', this.newRequestUUId]);
            this.loadingService.stopLoading()
          })
        ));

      });
  }

  get serviceType(): AbstractControl {
    return this.form.formGroup.controls['serviceType'];
  }

  get service(): AbstractControl {
    return this.form.formGroup.controls['service'];
  }

  get date(): AbstractControl {
    return this.form.formGroup.controls['date'];
  }

  get start(): AbstractControl {
    return this.form.formGroup.controls['start'];
  }

  get end(): AbstractControl {
    return this.form.formGroup.controls['end'];
  }

  get noEndTime(): AbstractControl {
    return this.form.formGroup.controls['noEndTime'];
  }

  get venue(): AbstractControl {
    return this.form.formGroup.controls['venue'];
  }

  get destination(): AbstractControl {
    return this.form.formGroup.controls['destination'];
  }

  get numberOfVolunteer(): AbstractControl {
    return this.form.formGroup.controls['numberOfVolunteer'];
  }

  get selectedServiceTypeServices(): Service[] {
    var type = this.serviceTypes.find(st => st.uuId == this.serviceType.value);
    return type ? type.services : [];
  }

  get hasDestination(): boolean {
    let service = this.selectedServiceTypeServices.find(service => service.uuId == this.service.value);
    return service ? service.destinationNeeded : false;
  }

  get selectedVenueLivingConditions(): Living[] {
    let address = this.requester?.addresses.find(address => address.room.uuId == this.venue.value);
    return address ? address.livings : [];
  }

  get selectedDestinationLivingCondition(): Living[] {
    let address = this.requester?.addresses.find(address => address.room.uuId == this.destination.value);
    return address ? address.livings : [];
  }

  //For holding uuid after create only
  private newRequestUUId: string | null = null;
}
