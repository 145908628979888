<div class="popup-bg" *ngIf="display" (click)="closeAction()">
    <div class="user-management-popup zoomIn"  [ngClass]="{'width_en':lang==='en','width_tc':lang==='zh-hk'}" (click)="$event.stopPropagation()">
        <p style="font-size: 1.5rem;line-height: 2.125rem;" translate>實際出席人次記錄</p>
        <div class="header" >
            <div class="inline-flex-row align-items-center text-grey">
               <p translate>總實際出席人數 </p> 
               <p style="margin-left: 1.5rem;">{{total}}</p>
            </div>
           
        </div>
        <div class="table-row text-grey" style="margin-top: 1rem;margin-bottom: 0.5rem;">
            <div class="" style="flex:1;flex-basis: 6.25rem;">
                <p translate class="ml-4">活動場次</p>
            </div>
            <div class="" style="flex-basis: 5.5rem;flex-grow: 1rem;text-align: center;">
                <p translate>狀態</p>
            </div>
            <div class="" style="flex-basis: 6.25rem;flex-grow: 1rem;text-align: center;">
                <p translate>實際出席人數</p>
            </div>
        </div>
        <div style="overflow:auto;height: 30rem;">
            
                <div class="table-row sub-table-row "  *ngFor="let day of eventdays; let index = index">
                    <div class="table-cell"  >
                    
                            <p class="ml-4" style="flex-basis: 40%;">{{ day.date | date: 'longDate' : '' : lang }}</p>
                        
                            <p style="flex-basis: 15%;">{{ day.date | date: 'EEEE': '' : lang }}</p>
                            <p style="flex-basis: 13%;text-align: center;">{{ day.startTime | date: 'HH:mm' }}</p>
                            <p style="text-align: center;" translate>至</p>
                            <p style="flex-basis: 13%;text-align: center;">{{ day.endTime | date: 'HH:mm' }}</p>
                            <div style="flex-basis: 1rem;"></div>
                    </div>
                    <div style="flex-basis: 5.5rem;flex-grow: 1rem;text-align: center;">
                        <p translate  *ngIf="checkState(day)==-1">已結束</p>
                        <p translate  *ngIf="checkState(day)==0">進行中</p>
                        <p translate  *ngIf="checkState(day)==1">未開始</p>
                    </div>
                    <div style="flex-basis: 6.25rem;flex-grow: 1rem;align-items: center;">
                        <p *ngIf="!isEdit" style="text-align: center;" translate>{{day.numOfAttendance}}</p>
                        <input *ngIf="isEdit" style="width: 5.25rem;" class="user-input" type="number" [(ngModel)]="day.numOfAttendance" >
                    </div>
                </div>
        </div>
        <div class="footer">
            <div *ngIf="!isEdit">
                <button class="button outline" (click)="closeAction()" translate>返 回</button>
                <button class="button fill blue" style="margin-left: 11.5rem;" (click)="edit()" translate>編 輯</button>
            </div>
            <div *ngIf="isEdit">
                <button class="button outline" (click)="closeEdit()" translate>取 消</button>
                <button class="button fill blue" style="margin-left: 11.5rem;" (click)="save()" translate>確 認</button>
            </div>
        </div>
    </div>
</div>