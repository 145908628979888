import { Component, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-data-setting-page",
  templateUrl: "./data-setting-page.component.html",
  styleUrls: ["./data-setting-page.component.scss"],
  animations: [PortalAnimation.SystemPageSlide],
})
export class DataSettingPageComponent implements OnInit {
  tab: string = "organization";

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.router.url == "/main/system/setting/data") this.tab = "data";
    if (this.router.url == "/main/system/setting/event") this.tab = "event";
    if (this.router.url == "/main/system/setting/time") this.tab = "time";
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animationState"]
    );
  }
}
