import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { defaultIfEmpty, finalize } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Product } from "src/app/models/redeems/product";
import { Redeem } from "src/app/models/redeems/redeem";
import { ExportService } from "src/app/services/export/export.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-product-management-redeem",
  templateUrl: "./product-management-redeem.component.html",
  styleUrls: ["./product-management-redeem.component.scss"],
})
export class ProductManagementRedeemComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Input() product!: Product;
  @Input() redeems: Redeem[] = [];

  filters: any = {
    itemsPerPage: 10,
    page: 1,
    searchInput: null,
    centers: [],
  };

  constructor(
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService,
    private redeemService: RedeemService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {}

  cancelRedeem(redeem: Redeem): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.refresh.emit();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.CancelRedeemMessage(this.redeemService.cancel(redeem.uuId)),
    );
  }

  updateRedeem(redeem: Redeem): void {
    if (redeem.quantityInput.invalid) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }
    this.loadingService.startLoading();
    this.redeemService
      .update(redeem.uuId, redeem.quantityInput.value)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.UpdateRedeemSuccessMessage,
          );
          redeem.editing = false;
          redeem.quantity = redeem.quantityInput.value;
          var sum = this.product.price * redeem.quantityInput.value;
          redeem.price = sum;
        },
        error: (error) => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.UpdateRedeemFailMessage,
          );
        },
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
  }).bind(this);

  selectPage(): void {
    if (this.isPageSelectedAll)
      this.displayedRedeems.forEach((redeem) => (redeem.selected = false));
    else this.displayedRedeems.forEach((redeem) => (redeem.selected = true));
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.redeems
        .filter(
          (redeem) =>
            redeem.redeemState == "Redeemed" ||
            redeem.redeemState == "Delivered",
        )
        .forEach((redeem) => (redeem.selected = false));
    else
      this.redeems
        .filter(
          (redeem) =>
            redeem.redeemState == "Redeemed" ||
            redeem.redeemState == "Delivered",
        )
        .forEach((redeem) => (redeem.selected = true));
  }

  exportProduct(): void {
    this.exportService.exportRedeems(
      this.product,
      this.redeems.filter((redeem) => redeem.selected),
    );
  }

  deliveredProduct(): void {
    var redeems = this.redeems.filter((redeem) => redeem.selected);
    if (redeems.length == 0) {
      return;
    }

    this.loadingService.startLoading();
    forkJoin(
      redeems.map((redeem) => this.redeemService.deliveredRedeem(redeem.uuId)),
    )
      .pipe(
        defaultIfEmpty(),
        finalize(() => this.loadingService.stopLoading()),
      )
      .subscribe({
        next: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ApproveRedeemSuccessMessage,
          );
          this.refresh.emit();
        },
        error: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.ApproveRedeemFailMessage,
          );
        },
      });
  }

  get displayedRedeems(): Redeem[] {
    return this.redeems
      .filter(
        (redeem) =>
          (this.filters.searchInput == null ||
            redeem.chineseName.includes(this.filters.searchInput) ||
            redeem.memberId.includes(this.filters.searchInput)) &&
          (this.filters.centers.length == 0 ||
            (this.filters.centers as Center[])
              .map((c) => c.centerCode)
              .includes(redeem.centerCode)),
      )
      .slice(
        (this.filters.page - 1) * this.filters.itemsPerPage,
        this.filters.page * this.filters.itemsPerPage,
      );
  }

  get isPageSelectedAll(): boolean {
    return this.displayedRedeems.every((redeem) => redeem.selected);
  }

  get isSelectedAll(): boolean {
    return this.redeems
      .filter(
        (redeem) =>
          redeem.redeemState == "Redeemed" || redeem.redeemState == "Delivered",
      )
      .every((redeem) => redeem.selected);
  }

  get numberOfSelectedUsers(): number {
    return this.redeems.filter((redeem) => redeem.selected).length;
  }

  get numberOfRedeemUsers(): number {
    return [...new Set(this.redeems.map((redeem) => redeem.userUUId))].length;
  }

  get pages(): number {
    return Math.ceil(this.redeems.length / this.filters.itemsPerPage);
  }
}
