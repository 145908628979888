<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>會員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>申請審核列表</p>
    </div>

    <p class="page-title mt-8" translate>待審核列表</p>

    <div class="container mt-5">
        <div class="container-body">
            <div class="inline-flex-row align-items-center">
                <app-search-input class="flex-1" [placeholder]="'請輸入申請人姓名 '" [action]="searchFilterAction"></app-search-input>

                <button class="icon-button yellow ml-5">
                    <i class="material-icons-round mb-1">archive</i>
                    <p translate>匯出所有</p>
                </button>
            </div>

            <div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
                <app-address-filter [action]="addressFilterAction"></app-address-filter>
                <app-service-filter [action]="serviceFilterAction" class="ml-4"></app-service-filter>
                <app-sign-up-status-filter [action]="statusFilterAction" class="ml-4"></app-sign-up-status-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>

            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p matTooltip="{{ '申請人姓名' | translate }}" [matTooltipPosition]="'above'" translate>申請人姓名</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>暱稱</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>性別</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>年齡</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p translate>電話</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 14.5rem;flex-grow: 29;">
                        <p translate>地址</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p matTooltip="{{ '申請日期' | translate }}" [matTooltipPosition]="'above'" translate>申請日期</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6.5rem;flex-grow: 13;">
                        <p translate>狀態</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let user of users">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [(ngModel)]="user.selected" (change)="cacheSelectedUser(user)">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p><span [ngClass]="{'red-rot':user.signUpOverTime,'white-rot':!user.signUpOverTime}" ></span>{{ user.chineseName }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ user.englishName }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ user.gender | translate }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ user.age }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p>{{ user.phone }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 14.5rem;flex-grow: 29;">
                        <p translate>{{ user.address.toString() }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>{{ user.signUpAt | date : 'yyyy/MM/dd' }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6.5rem;flex-grow: 13;">
                        <div class="status orange mr-2" *ngIf="user.status == 'Pending' && user.signUpOverTime == false">
                            <p translate>等待審核</p>
                        </div>
                        <div class="status green mr-2" *ngIf="user.status == 'Approving'">
                            <p translate>審核中</p>
                        </div>
                        <div class="status grey mr-2" *ngIf="user.status == 'Disapproved'">
                            <p translate>已拒絕</p>
                        </div>
                        <div class="status red mr-2" *ngIf="user.signUpOverTime">
                            <p translate>超時審核</p>
                        </div>
                        <p></p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8rem;flex-grow: 16;">
                        <p class="flex-0 click-text-lightblue mr-8" [routerLink]="['/main/user/sign-up-overview', user.uuId]" translate>檢視</p>
                        <p class="flex-0 click-text-lightblue mr-8" (click)="deleteUser(user.uuId)" translate>移除</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="users.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteUserList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>