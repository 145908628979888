import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty, finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { UserTransaction } from 'src/app/models/transactions/user-transaction';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-user-donation-transaction',
  templateUrl: './user-donation-transaction.component.html',
  styleUrls: ['./user-donation-transaction.component.scss']
})
export class UserDonationTransactionComponent implements OnInit {


  userId!: string;
  @Input() transactions: UserTransaction[] = [];
  @Input() reloadAction: () => void = () => { }

  filters: any = {
    page: 1,
    itemsPerPage: 10,
    idOrder: null,
    dateOrder: true
  };

  constructor(
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private transactionService: TransactionService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.route.parent?.params.subscribe(value => {
      this.userId = value.id;
    });
  }

  deleteDonation(transactionUUId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.popupMessageService.messageSignal.emit(PopupMessages.DeleteDonationSuccessMessage);
      this.reloadAction();
      subscriber.unsubscribe();
    })

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteDonationMessage(
      this.transactionService.cancelTransaction(transactionUUId, { remark: "Cancel Donation" })
        .pipe(finalize(() => this.loadingService.stopLoading()))));
  }

  deleteDonationList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.popupMessageService.messageSignal.emit(PopupMessages.DeleteDonationSuccessMessage);
      this.reloadAction();
      subscriber.unsubscribe();
    })

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteDonationMessage(
      forkJoin(this.selectedTransactions.map(transaction => this.transactionService.cancelTransaction(transaction.uuId, { remark: "Cancel Donation" })))
        .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))));
  }

  get pages(): number {
    return this.transactions ? Math.ceil(this.transactions.length / this.filters.itemsPerPage) : 0;
  }

  get selectedTransactions(): UserTransaction[] {
    return this.transactions.filter(transaction => transaction.selected);
  }

  get displayedTransactions(): UserTransaction[] {
    let result = this.transactions;

    if (this.filters.idOrder != null) {
      if (this.filters.idOrder == true)
        result = this.transactions.sort((a, b) => a.event.id > b.event.id ? 1 : a.event.id < b.event.id ? -1 : 0);
      else
        result = this.transactions.sort((b, a) => a.event.id > b.event.id ? 1 : a.event.id < b.event.id ? -1 : 0);
    }

    if (this.filters.dateOrder != null) {
      if (this.filters.dateOrder == true)
        result = this.transactions.sort((b, a) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
      else
        result = this.transactions.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
    }

    return result.slice((this.filters.page - 1) * this.filters.itemsPerPage, this.filters.page * this.filters.itemsPerPage);
  }

  get isSelectedAll(): boolean {
    return this.displayedTransactions.every(transaction => transaction.selected == true);
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.displayedTransactions.forEach(transaction => transaction.selected = false);
    else
      this.displayedTransactions.forEach(transaction => transaction.selected = true);
  }
}
