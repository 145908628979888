<div class="analysis-container">
    <div class="analysis-container-header">
        <div class="inline-flex-row align-items-center">
            <p class="text-bold" translate>細胞小組</p>
        </div>
        <div class="filterHeader">
            <div class="inline-flex-row mt-8 startline">
                <button class="left-button" (click)="dataType = 'Request';ServiceType.setValue(null);fetch()" [ngClass]="{'active': dataType == 'Request'}" translate>義工服務</button>
                <button class="right-button" (click)="dataType = 'Event';ServiceType.setValue(null);fetch()" [ngClass]="{'active': dataType != 'Request'}" translate>中心活動</button>
            </div>
            <div class="inline-flex-row mt-8 endinline" style="position:relative">
                <input class="user-input xl ml-4" [ngModel]="dateRange" (click)="picker.open()" placeholder="2015-10-10 ~ 2015-11-10" readonly>
                <ng-container *ngTemplateOutlet="datepicker"></ng-container>
                <mat-date-range-picker touchUi #picker></mat-date-range-picker>
                <mat-date-range-input style="display: none;" [rangePicker]="picker">
                    <input matStartDate [formControl]="start">
                    <input matEndDate [formControl]="end" (dateInput)="fetch()">
                </mat-date-range-input>
            </div>
        </div>
    </div>
    <div class="filterHeader stateData" >
        <div class="inline-flex-row startline" *ngIf="dataType == 'Request'">
            <label class="cellgroupStateType"><input type="radio" name='cellgroupStateType' [ngModel]="StateType" value="All" checked="checked" (change)="StateType='All';fetch()"/><span translate>全部</span></label>
            <label class="cellgroupStateType"><input type="radio" name='cellgroupStateType' [ngModel]="StateType" value="Requester" (change)="StateType='Requester';fetch()"/><span translate>找幫手</span></label>
            <label class="cellgroupStateType"><input type="radio" name='cellgroupStateType' [ngModel]="StateType" value="Volunteer" (change)="StateType='Volunteer';fetch()"/><span translate>幫他人</span></label>
        </div>
        <div class="inline-flex-row startline" *ngIf="dataType != 'Request'">

        </div>
        <div class="inline-flex-row endinline selectDIV">
            <div>
                <select class="user-select xl" [formControl]="ServiceType" (change)="fetch();">
                    <option [ngValue]="null" translate>全部</option>
                    <option [ngValue]="cg.value" *ngFor="let cg of typeList">{{ cg.name }}</option>
                    <!-- <optgroup *ngFor="let center of centers" [label]="center.name">
                        
                    </optgroup> -->
                </select>
            </div>
        </div>
    </div>
    <div class="analysis-container-body">
        <app-simple-table-box  style="flex: 7;padding-left: 1rem;" [displayList]="displayList">
            <div class="head inline-flex-row">
                <span class="cell-group-data-col1" translate>細胞小組</span>
                <span class="cell-group-data-col2" *ngIf="dataType == 'Request'" translate>服務次數</span>
                <span class="cell-group-data-col2" *ngIf="dataType == 'Event'" translate>參與次數</span>
                <span class="cell-group-data-col3 center" translate >人數</span>
            </div>
            <ng-template #row let-service='service' let-index="index">
                <div class="service-item">
                    <div class="cell-group-name"><span class="cell-group-data-col1" >{{service.cellGourpName}}</span></div>
                    <div>
                        <div class="flex-row">
                            <span class="cell-group-data-col1" >
                                <div class="bar-bg">
                                    <div class="bar-top" [attr.style]="'width: '+((service.NumOfTime/max)*100)+'%;'"></div>
                                </div>
                            </span>
                            <span class="cell-group-data-col2 center" >{{service.NumOfTime}}</span>
                            <span class="cell-group-data-col3 center"  >{{service.NumOfPoeper}}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </app-simple-table-box>
    </div>
    <div class="chart-container-footer"><a class="exportBtn data-type-button" translate (click)="export()">匯出報告</a></div>
</div>
<ng-template #datepicker><svg style="position: absolute;right: 0.2rem;top: 0.25rem;" viewBox="0 0 24 24" width="24px" height="24px" fill="#DADADA" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></ng-template>