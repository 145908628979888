import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { Profile } from "src/app/models/profiles/profile";
import { ExportService } from "src/app/services/export/export.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { StaffService } from "src/app/services/staff/staff.service";

@Component({
  selector: "app-staff-overview-page",
  templateUrl: "./staff-overview-page.component.html",
  styleUrls: ["./staff-overview-page.component.scss"],
})
export class StaffOverviewPageComponent implements OnInit {
  staffs: Profile[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
  };

  constructor(
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private staffService: StaffService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.staffService
      .getStaffProfileList()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => (this.staffs = value),
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
  }).bind(this);

  centerFilterAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.filters.centers = centers;
    this.filters.page = 1;
  }).bind(this);

  public deleteStaff(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteStaffMessage(this.staffService.deleteStaffs([uuId])),
    );
  }

  public deleteStaffList(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteStaffListMessage(
        this.staffService.deleteStaffs(this.selectedStaffs.map((s) => s.uuId)),
      ),
    );
  }

  get pages(): number {
    return Math.ceil(this.filteredStaffs.length / this.filters.itemsPerPage);
  }

  get filteredStaffs(): Profile[] {
    return this.staffs.filter(
      (staff) =>
        (!this.filters.searchInput ||
          staff.memberId
            .toLocaleLowerCase()
            .includes(this.filters.searchInput.toLocaleLowerCase()) ||
          staff.chineseName
            .toLocaleLowerCase()
            .includes(this.filters.searchInput.toLocaleLowerCase())) &&
        (this.filters.centers.length == 0 ||
          this.filters.centers.some(
            (center: Center) =>
              staff.center && staff.center.uuId == center.uuId,
          )),
    );
  }
  public CanDeleteIt(staff: Profile): boolean {
    return (
      staff.uuId != "00000000-0000-0000-0000-000000000001" &&
      staff.roles.filter((s) => s.roleId == 1).length == 0
    );
  }
  get displayedStaffs(): Profile[] {
    return this.filteredStaffs.slice(
      (this.filters.page - 1) * this.filters.itemsPerPage,
      this.filters.page * this.filters.itemsPerPage,
    );
  }

  get selectedStaffs(): Profile[] {
    return this.staffs ? this.staffs.filter((p) => p.selected) : [];
  }

  get isSelectedAll(): boolean {
    return this.displayedStaffs.every((s) => s.selected);
  }

  public selectAll(): void {
    if (this.isSelectedAll)
      this.displayedStaffs.forEach((s) => (s.selected = false));
    else this.displayedStaffs.forEach((s) => (s.selected = true));
  }

  export(): void {
    this.exportService.exportUsers(
      this.staffs.map((staff) => staff.getUserExportModel()),
      "Staff",
    );
  }
}
