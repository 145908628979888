import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { CreateRedeemQuestionModel } from 'src/app/models/redeem-questions/create-redeem-question-model';
import { RedeemQuestion, IRedeemQuestion } from 'src/app/models/redeem-questions/redeem-question';
import { UpdateRedeemQuestionModel } from 'src/app/models/redeem-questions/update-redeem-question-model';

@Injectable({
  providedIn: 'root'
})
export class RedeemQuestionService {


  constructor(
    private httpService: HttpService
  ) { }

  public getQuestions(): Observable<RedeemQuestion[]> {
    return this.httpService.get<IRedeemQuestion[]>("redeem/Redeems/Question")
      .pipe(map(value => value.result.map(question => new RedeemQuestion(question))));
  }

  public createQuestion(model: CreateRedeemQuestionModel): Observable<any> {
    return this.httpService.post<any, CreateRedeemQuestionModel>("redeem/admin/RedeemQuestions", model);
  }

  public updateQuestion(model: UpdateRedeemQuestionModel): Observable<any> {
    return this.httpService.put<any, UpdateRedeemQuestionModel>("redeem/admin/RedeemQuestions", model);
  }

  public deleteQuestion(uuid: string): Observable<any> {
    return this.httpService.delete("redeem/admin/RedeemQuestions?uuid=" + uuid);
  }
}
