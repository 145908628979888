import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Living } from "src/app/models/livings/living";
import { PortalAnimation } from "src/app/portal-animation";
import { CellGroupService } from "src/app/services/cell-group/cell-group.service";

@Component({
  selector: "app-cell-group-filter",
  templateUrl: "./cell-group-filter.component.html",
  styleUrls: ["./cell-group-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class CellGroupFilterComponent implements OnInit {
  @Input() action: (centers: Center[]) => void = () => {};

  centers: Center[] = [];
  display: boolean = false;

  constructor(private cellGroupService: CellGroupService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    forkJoin({
      centers: this.cellGroupService.getCenterCellGroupList(),
    }).subscribe({
      next: (value) => {
        this.centers = value.centers;
      },
    });
  }

  reset(): void {
    this.centers.forEach((item) => {
      item.cellGroups.forEach((e) => {
        e.selected = false;
      });
    });
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.centers);
    }
    this.display = !this.display;
  }

  selectCellGroups(): void {}

  get numberOfSelectedItems(): number {
    return this.centers.reduce((preVal, current) => {
      return current.cellGroups.reduce((p, c) => {
        return p + (c.selected ? 1 : 0);
      }, preVal);
      return 0;
    }, 0);
  }
}
