import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CreateAddressModel } from "src/app/models/addresses/create-address-model";
import { UpdateAddressModel } from "src/app/models/addresses/update-address-model";
import { Center, ICenter } from "src/app/models/centers/center";
import { Agent } from "src/app/models/profiles/agent";
import { Profile, IProfile } from "src/app/models/profiles/profile";
import { UpdateUserModel } from "src/app/models/profiles/update-user-model";
import { StringDecoder } from "string_decoder";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  myCenters: Center[] = [];
  agentSelections: Observable<{
    uuid: string | undefined;
    agents: Agent[] | undefined;
  }> | null = null;
  getObservable: Observable<Profile> | null = null;

  constructor(private httpService: HttpService) {}

  public getMyProfile(): Observable<Profile> {
    return this.httpService.get<IProfile>("profiles/me").pipe(
      map((value) => new Profile(value.result)),
      tap(
        (profile) =>
          (this.myCenters = profile.staffCenters.map(
            (center) => new Center(center),
          )),
      ),
    );
  }

  public getProfileList(
    queryString: string,
  ): Observable<{ list: Profile[]; count: number }> {
    return this.httpService
      .get<{ list: IProfile[]; count: number }>("profiles/list" + queryString)
      .pipe(
        map((value) => {
          return {
            list: value.result.list.map((profile) => new Profile(profile)),
            count: value.result.count,
          };
        }),
      );
  }

  public updateProfile(
    userUUId: string | null,
    model: UpdateUserModel,
  ): Observable<any> {
    return this.httpService.post<any, UpdateUserModel>(
      "profiles/" + userUUId,
      model,
    );
  }

  public getProfileByUUId(uuId: string): Observable<Profile> {
    return this.httpService
      .get<IProfile>("profiles/" + uuId)
      .pipe(map((value) => new Profile(value.result)));
  }

  public deleteUsers(model: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("auth/delete", {
      list: model,
    });
  }

  public FreezeUsers(model: string): Observable<any> {
    return this.httpService.post<any, { userUUId: string }>("profiles/freeze", {
      userUUId: model,
    });
  }

  public unFreezeUsers(model: string): Observable<any> {
    return this.httpService.post<any, { userUUId: string }>("profiles/active", {
      userUUId: model,
    });
  }

  public createAddress(
    userUUId: string | null,
    model: CreateAddressModel,
  ): Observable<any> {
    return this.httpService.post<any, CreateAddressModel>(
      "profiles/" + userUUId + "/address",
      model,
    );
  }

  /** Restore the list of deleted users */
  public restoreUesrs(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("auth/restore", model);
  }

  /** Delete User Permanently */
  public deleteUsersPermanently(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>(
      "auth/deletePermanently",
      model,
    );
  }

  public getUUIdByMemberId(model: { list: string[] }): Observable<string[]> {
    return this.httpService
      .post<string[], { list: string[] }>("profiles/memberId", model)
      .pipe(map((value) => value.result));
  }

  public editAddress(model: UpdateAddressModel): Observable<any> {
    return this.httpService.put<any, UpdateAddressModel>(
      "profiles/me/address",
      model,
    );
  }
}
