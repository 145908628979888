import * as dayjs from "dayjs";

export interface IRequestImportModel {
  "紀錄編號*": string;
  "申請人編號*": string;
  申請人名稱: string;
  "服務日期(YYYY-MM-DD)*": string;
  "預計開始時間(HH:mm)*": string;
  "預計結束時間(HH:mm)*": string;
  "服務鐘數*": string;
  "提供服務*（字眼必須和後台資料設置一致）": string;
  "義工名稱（用英文逗號隔開）": string;
  "義工編號*（用英文逗號隔開）": string;
  "實際開始時間*": string;
  "實際結束時間*": string;
  備註: string;
  "每個義工的交易時分*（根據義工編號次序填寫時分，用英文逗號隔開）": string;
}

export class RequestVolunteerImportModel {
  public uuId: string | undefined;
  public actualStartTime: string | null;
  public actualEndTime: string | null;
  public amount: number;

  constructor(
    uuId: string,
    actualStartTime: string | null,
    actualEndTime: string | null,
    amount: string,
  ) {
    this.uuId = uuId;
    this.actualStartTime = actualStartTime;
    this.actualEndTime = actualEndTime;
    this.amount = Number.parseFloat(amount);
  }

  public valid(): boolean {
    return this.uuId != null && this.amount > 0;
  }
}

export class RequestImportModel {
  id: string;
  row: number;
  requesterUUId: string | undefined;
  serviceUUId: string;
  startTime: string | null;
  endTime: string | null;
  remark: string;
  volunteers: RequestVolunteerImportModel[];
  numberOfVolunteer: number;

  isHaveSlashInDate: boolean = false;

  constructor(model: IRequestImportModel, index: number) {
    console.log(model);
    this.id = model["紀錄編號*"]?.trim();
    this.row = index;
    this.requesterUUId = model["申請人編號*"]?.trim();
    this.serviceUUId = model["提供服務*（字眼必須和後台資料設置一致）"]?.trim();
    this.startTime = dayjs(
      `${model["服務日期(YYYY-MM-DD)*"]} ${model["預計開始時間(HH:mm)*"]}`,
    ).toJSON();
    this.endTime = dayjs(
      `${model["服務日期(YYYY-MM-DD)*"]} ${model["預計結束時間(HH:mm)*"]}`,
    ).toJSON();
    this.remark = model["備註"]?.trim();
    const amounts: string[] = model[
      "每個義工的交易時分*（根據義工編號次序填寫時分，用英文逗號隔開）"
    ]
      .split(",")
      .map((amount) => amount.trim());
    this.volunteers = model["義工編號*（用英文逗號隔開）"]
      .split(",")
      .map((volunteer) => volunteer.trim())
      .map((volunteer, index) => {
        return new RequestVolunteerImportModel(
          volunteer,
          this.startTime,
          this.endTime,
          amounts[index] ? amounts[index] : "-1",
        );
      });
    this.numberOfVolunteer = this.volunteers.length;

    console.log(this);
  }

  public selfValidate(): string | null {
    if (this.id == "error")
      return "第" + (this.row + 7) + "列: 未輸入紀錄編號\n";
    if (this.requesterUUId == null)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入使用者\n";
    if (typeof this.serviceUUId == "undefined" || this.serviceUUId == null)
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入服務類別(1)\n"
      );
    if (this.serviceUUId == null)
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入服務類別(2)\n"
      );

    if (this.serviceUUId == "00000000-0000-0000-0000-000000000000")
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入服務類別(0)\n"
      );

    if (this.isHaveSlashInDate)
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "服務日期格式錯誤\n"
      );

    if (this.volunteers.length == 0)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入助手\n";
    if (this.startTime == null)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入開始時間\n";
    if (this.endTime == null)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入結束時間\n";
    if (this.volunteers.some((v) => v.uuId == null))
      return "第" + (this.row + 7) + "列: 編號" + this.id + "含有未知助手\n";
    if (this.volunteers[0].actualStartTime == null)
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入實際開始時間\n"
      );
    if (this.volunteers[0].actualEndTime == null)
      return (
        "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入實際結束時間\n"
      );
    if (this.volunteers[0].amount == null)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入時分\n";
    return null;
  }
}
