import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { AnnouncementService } from "src/app/services/announcement/announcement.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-announcement-deactivation-popup",
  templateUrl: "./announcement-deactivation-popup.component.html",
  styleUrls: ["./announcement-deactivation-popup.component.scss"],
})
export class AnnouncementDeactivationPopupComponent implements OnInit {
  @Output() refreshSignal: EventEmitter<any> = new EventEmitter();
  display: boolean = false;
  public reasons: { reason: any | null; selected: boolean }[] = [
    { reason: "通告內容有誤", selected: false },
    { reason: "相關安排有變", selected: false },
    { reason: null, selected: false },
  ];

  constructor(
    private loadingService: LoadingService,
    private announcementService: AnnouncementService,
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {}

  public submit(): void {
    this.loadingService.startLoading();
    this.route.params.subscribe((value) =>
      this.announcementService
        .deactivateAnnouncement(
          value.id,
          this.reasons
            .filter((reason) => reason.selected == true)
            .map((reason) => reason.reason)
            .join(", "),
        )
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: (value) => {
            this.close();
            this.popupMessageService.messageSignal.emit(
              PopupMessages.DeactivateAnnouncementSuccessMessage,
            );
            this.refreshSignal.emit();
          },
        }),
    );
  }

  open(): void {
    this.display = true;
  }

  close(): void {
    this.reasons.forEach((reason) => (reason.selected = false));
    this.display = false;
  }
}
