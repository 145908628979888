import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { IApplicationSetting } from 'src/app/models/organizations/application-setting';
import { IOrganization, Organization } from 'src/app/models/organizations/organization';
import { OrganizationConfig, IOrganizationConfig, IOrganizationTimeConfig } from 'src/app/models/organizations/organization-config';
import { IOrganizationSetting } from 'src/app/models/organizations/organization-setting';
import { IOrganizationUsage } from 'src/app/models/organizations/organization-usage';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {


  constructor(
    private httpService: HttpService
  ) { }

  public getOrganizationList(): Observable<Organization[]> {
    return this.httpService.get<IOrganization[]>("organizations")
      .pipe(map(value => value.result.map(o => new Organization(o))));
  }

  public getOrganizationName(): Observable<string> {
    return this.httpService.get<string>("organizations/portalName")
      .pipe(map(value => value.result));
  }

  public getOrganizationSetting(): Observable<IOrganizationSetting> {
    return this.httpService.get<{ config: string }>("organizations/config/setting")
      .pipe(map(value => JSON.parse(value.result.config)));
  }

  public getOrganizationConfig(): Observable<OrganizationConfig> {
    return this.httpService.get<IOrganizationConfig>("organizations/config/backend")
      .pipe(map(value => new OrganizationConfig(value.result)));
  }

  public setTimeConfig(model: IOrganizationTimeConfig): Observable<any> {
    return this.httpService.post<any, { config: string }>("organizations/config/backend", { config: JSON.stringify(model) });
  }

  public getApplicationSetting(): Observable<IApplicationSetting> {
    return this.httpService.get<{ config: string }>("organizations/config/mobile")
      .pipe(map(value => {
        if (value == null)
          return null
        return JSON.parse(value.result.config);
      }));
  }

  public setApplicationSetting(model: IApplicationSetting): Observable<any> {
    return this.httpService.post<any, { config: string }>("organizations/config/mobile", { config: JSON.stringify(model) });
  }

  public setOrganizationNameAndPortal(name: string, logo: string): Observable<any> {
    return this.httpService.post<any, { PlatformName: string, Logo: string }>("organizations/portalSetting", {
      PlatformName: name,
      Logo: logo
    });
  }

  public setOrganizationSetting(model: IOrganizationSetting): Observable<any> {
    return this.httpService.post<any, { config: string }>("organizations/config/setting", { config: JSON.stringify(model) });
  }

  public getUsage(): Observable<IOrganizationUsage> {
    return this.httpService.get<IOrganizationUsage>("settings/usage")
      .pipe(map(value => value.result));
  }
}
