import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { RecommandRatio } from 'src/app/models/recommands/recommand-ratio';

@Injectable({
  providedIn: 'root'
})
export class RecommandRatioService {

  constructor(
    private httpService: HttpService
  ) { }

  public getRecommandRatio(): Observable<RecommandRatio[]> {
    return this.httpService.get<RecommandRatio[]>("Settings/recommendRatio")
      .pipe(map(value => value.result));
  }

  public setRecommandRatio(model: RecommandRatio[]): Observable<any> {
    return this.httpService.post<any, { list: RecommandRatio[] }>("Settings/recommendRatio", { list: model });
  }
}
