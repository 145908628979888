import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CellGroup, ICellGroup } from "src/app/models/cell-groups/cell-group";
import { CreateCellGroupModel } from "src/app/models/cell-groups/create-cell-group-model";
import { UpdateCellGroupModel } from "src/app/models/cell-groups/update-cell-group-model";
import { Center, ICenter } from "src/app/models/centers/center";
import { CellGroupMemberProfile } from "src/app/models/profiles/cell-group-member-profile";
import { IProfile } from "src/app/models/profiles/profile";

@Injectable({
  providedIn: "root",
})
export class CellGroupService {
  constructor(private httpService: HttpService) {}

  public getCellGroupList(): Observable<CellGroup[]> {
    return this.httpService
      .get<ICellGroup[]>("CellGroup")
      .pipe(
        map((value) =>
          value.result
            .map((cellGroup) => new CellGroup(cellGroup))
            .sort(CellGroupSorting),
        ),
      );
  }

  public getCenterCellGroupList(): Observable<Center[]> {
    return this.httpService
      .get<ICenter[]>("CellGroup/CellGroupDetail")
      .pipe(map((value) => value.result.map((center) => new Center(center))));
  }

  public getCellGroupMemberList(
    cellGroupUUId: string | undefined,
  ): Observable<CellGroupMemberProfile[]> {
    return this.httpService
      .get<IProfile[]>("CellGroup/" + cellGroupUUId)
      .pipe(
        map((value) =>
          value.result.map((profile) => new CellGroupMemberProfile(profile)),
        ),
      );
  }

  public createCellGroup(
    model: CreateCellGroupModel,
  ): Observable<{ uuid: string }> {
    return this.httpService
      .post<{ uuid: string }, CreateCellGroupModel>("CellGroup", model)
      .pipe(map((value) => value.result));
  }

  public updateCellGroup(model: UpdateCellGroupModel): Observable<any> {
    return this.httpService.put<any, UpdateCellGroupModel>("CellGroup", model);
  }

  public addCellGroupLeader(
    cellGroupUUId: string | undefined,
    userUUIds: string[],
  ): Observable<any> {
    return this.httpService.post<
      any,
      { CellGroupUUId: string | undefined; UserUUIds: string[] }
    >("CellGroup/AddLeader", {
      CellGroupUUId: cellGroupUUId,
      UserUUIds: userUUIds,
    });
  }

  public removeCellGroupLeader(
    cellGroupUUId: string | undefined,
    userUUIds: string[],
  ): Observable<any> {
    return this.httpService.post<
      any,
      { CellGroupUUId: string | undefined; UserUUIds: string[] }
    >("CellGroup/RemoveLeader", {
      CellGroupUUId: cellGroupUUId,
      UserUUIds: userUUIds,
    });
  }

  public addMembersToCellGroup(
    cellGroupUUId: string,
    userUUIds: string[],
  ): Observable<any> {
    return this.httpService.post<any, { UUId: string; UserUUIds: string[] }>(
      "CellGroup/AddCellGroupMembers",
      { UUId: cellGroupUUId, UserUUIds: userUUIds },
    );
  }

  public removeMembersFromCellGroup(
    cellGroupUUId: string,
    userUUIds: string[],
  ): Observable<any> {
    return this.httpService.post<any, { UUId: string; UserUUIds: string[] }>(
      "CellGroup/RemoveCellGroupMembers",
      { UUId: cellGroupUUId, UserUUIds: userUUIds },
    );
  }
}

function CellGroupSorting(a: CellGroup, b: CellGroup) {
  return a.name.localeCompare(b.name, "en", { numeric: true });
}
