import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import {
  INotification,
  Notification,
} from "src/app/models/notifications/notification";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private httpService: HttpService) {}

  public getNotifications(): Observable<Notification[]> {
    return this.httpService
      .get<INotification[]>("notification")
      .pipe(
        map((value) =>
          value.result.map((notification) => new Notification(notification)),
        ),
      );
  }
}
