import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { TranslateService } from "@ngx-translate/core";
import { ExportService } from "src/app/services/export/export.service";
import * as dayjs from "dayjs";
import { DonationAnalysis } from "src/app/models/analytics/donation-analysis";
import {
  ArcElement,
  Chart,
  ChartData,
  ChartOptions,
  DoughnutController,
  DoughnutControllerChartOptions,
  Legend,
} from "chart.js";

@Component({
  selector: "app-donation-analysis",
  templateUrl: "./donation-analysis.component.html",
  styleUrls: ["./donation-analysis.component.scss"],
})
export class DonationAnalysisComponent implements AfterViewInit {
  @Input() form!: FormControl;

  get placeholder(): string {
    let placeholderStart = dayjs()
      .set("month", 0)
      .set("date", 1)
      .format("YYYY-MM-DD");
    let placeholderEnd = dayjs().format("YYYY-MM-DD");
    if (this.start.value != null) {
      placeholderStart = dayjs(this.start.value).format("YYYY-MM-DD");
    }
    if (this.end.value != null) {
      placeholderEnd = dayjs(this.end.value).format("YYYY-MM-DD");
    }
    return `${placeholderStart} - ${placeholderEnd}`;
  }

  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  centerUUId?: string;

  chart: Chart | null = null;
  @ViewChild("graph") graph!: ElementRef;
  @ViewChild("graphLabel") graphLabel!: ElementRef;
  context!: CanvasRenderingContext2D;

  public dataType: string = "";
  public donationValue: DonationAnalysis | null = null;
  public total: number = 0;
  public currentLang: string = "";

  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private exportService: ExportService,
    private renderer: Renderer2,
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    Chart.register(DoughnutController, ArcElement, Legend);

    this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext(
      "2d",
    ) as CanvasRenderingContext2D;

    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });
  }

  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null) return null;
    return `${dayjs(this.start.value).format("YYYY-MM-DD")} - ${dayjs(this.end.value).format("YYYY-MM-DD")}`;
  }

  export(): void {
    this.exportService.exportDonationAnalysis(
      this.donationValue,
      this.start.value,
      this.end.value,
    );
  }

  setDataType(): void {
    if (this.dataType == "Month") {
      this.start.setValue(dayjs().set("date", 1).toDate());
      this.end.setValue(new Date());
    } else if (this.dataType == "Year") {
      this.start.setValue(dayjs().set("month", 0).set("date", 1).toDate());
      this.end.setValue(new Date());
    }
    this.fetch();
  }

  fetch(): void {
    var start = !this.start.value
      ? undefined
      : dayjs(this.start.value).format("YYYY-MM-DD");
    var end = !this.end.value
      ? undefined
      : dayjs(this.end.value).format("YYYY-MM-DD");

    forkJoin({
      donation: this.analyticsService.getTotalDonation(
        this.centerUUId,
        start,
        end,
      ),
    }).subscribe((value) => {
      this.donationValue = value.donation;
      this.total =
        value.donation.toUser +
        value.donation.toCenter +
        value.donation.requestDonation;
      this.initGraph();
    });
  }

  initGraph(): void {
    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.context, {
      type: "doughnut",
      data: this.getData(),
      options: chartOptions,
    });
    this.renderer.setStyle(
      this.graphLabel.nativeElement,
      "top",
      this.chart.chartArea.top +
        (this.chart.chartArea.height -
          this.graphLabel.nativeElement.offsetHeight) /
          2 +
        "px",
    );
  }

  getData(): ChartData {
    var colorList: string[] = [];
    var data: number[] = Object.values(this.donationValue!);
    for (var i = 0; i < data.length; i++) {
      colorList.push(this.color(i));
    }
    return {
      datasets: [
        {
          data: data,
          backgroundColor: colorList,
          borderColor: colorList,
        },
      ],
      labels: Object.keys(this.donationValue!).map((value) => {
        switch (value) {
          case "toUser":
            value = "中心捐分累計";
            break;
          case "toCenter":
            value = "會員捐分累計";
            break;
          case "requestDonation":
            value = "義工服務 - 中心補貼累計";
            break;
        }
        return value;
      }),
    };
  }

  color(index: number): string {
    var colorList = [
      "rgba(255, 166, 32, 1)",
      "rgba(249, 133, 67, 1)",
      "rgba(160, 210, 255, 1)",
      "rgba(59, 161, 255, 1)",
      "rgba(47, 84, 235, 1)",
      "rgba(4, 105, 255, 1)",
      "rgba(4, 240, 255, 1)",
      "rgba(179, 245, 245, 1)",
      "rgba(54, 203, 203, 1)",
      "rgba(8, 151, 156, 1)",
      "rgba(107, 99, 151, 1)",
      "rgba(188, 17, 249, 1)",
      "rgba(214, 184, 255, 1)",
      "rgba(151, 95, 229, 1)",
      "rgba(83, 29, 171, 1)",
      "rgba(212, 56, 13, 1)",
      "rgba(255, 0, 168, 1)",
      "rgba(255, 203, 212, 1)",
      "rgba(242, 99, 123, 1)",
      "rgba(245, 34, 45, 1)",
      "rgba(255, 221, 102, 1)",
      "rgba(212, 216, 16, 1)",
      "rgba(255, 240, 181, 1)",
      "rgba(251, 212, 55, 1)",
      "rgba(212, 177, 6, 1)",
      "rgba(89, 232, 1, 1)",
      "rgba(173, 244, 195, 1)",
      "rgba(78, 203, 116, 1)",
      "rgba(56, 158, 13, 1)",
      "rgba(220, 5, 5, 1)",
      "rgba(195, 226, 226, 1)",
      "rgba(54, 203, 203, 1)",
      "rgba(166, 192, 192, 1)",
    ];
    return colorList[index % colorList.length];
  }
}

const chartOptions: any = {
  responsive: true,
  cutout: "80%",
  maintainAspectRatio: false,
  // plugins: {
  //   legend: { position: "right" },
  // },
};
