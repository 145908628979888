import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { Health } from "src/app/models/healths/health";
import { HealthService } from "src/app/services/health/health.service";

@Component({
  selector: "app-health-setting",
  templateUrl: "./health-setting.component.html",
  styleUrls: ["./health-setting.component.scss"],
})
export class HealthSettingComponent implements OnInit {
  healths: Health[] = [];

  loading: boolean = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    isShowInRequest: new FormControl(false, [Validators.required]),
  });

  constructor(private healthService: HealthService) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.healthService
      .getHealthConditionList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.healths = value;
        },
      });
  }

  create(): void {
    if (this.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.healthService
      .createHealthCondition({
        name: this.form.controls["name"].value,
        isShowInRequest: this.form.controls["isShowInRequest"].value,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
          this.form.reset();
        },
      });
  }

  update(health: Health): void {
    if (health.form.invalid == true) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.healthService
      .updateHealthCondition(health.getUpdateHealthModel())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.fetch();
        },
      });
  }

  updateVisibility(health: Health, visibility: boolean): void {
    health.isShowInRequest = visibility;
    health.form.controls["isShowInRequest"].setValue(visibility);
  }
}
