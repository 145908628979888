import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Health } from "src/app/models/healths/health";
import { Living } from "src/app/models/livings/living";
import { Profile } from "src/app/models/profiles/profile";
import { Service } from "src/app/models/services/service";
import { AnnouncementService } from "src/app/services/announcement/announcement.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-select-announcement-users-page",
  templateUrl: "./select-announcement-users-page.component.html",
  styleUrls: ["./select-announcement-users-page.component.scss"],
})
export class SelectAnnouncementUsersPageComponent implements OnInit {
  userUUId: string | null = null;

  count: number = 0;
  pages: number = 0;
  profiles: Profile[] = [];
  caches: Profile[] = [];
  oldCaches: string[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
    districts: [],
    subDistricts: [],
    services: [],
    conditions: [],
    healths: [],
    genders: [],
    memberIdOrder: true,
    creditOrder: null,
    cellGroup: [],
  };

  constructor(
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private announcementService: AnnouncementService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((value) => {
      this.userUUId = value.id;
      if (value.id == null) {
        this.oldCaches = this.announcementService.form.users.map(
          (user) => user.uuId,
        );
      } else {
        this.oldCaches = this.announcementService.getSelection();
      }
    });

    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.profileService
      .getProfileList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.profiles = value.list;
          this.count = value.count;
          this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
          if (this.userUUId == null) {
            this.profiles.forEach((profile) => {
              if (this.oldCaches.find((u) => u == profile.uuId))
                profile.selected = true;
            });
          } else {
            this.profiles.forEach((profile) => {
              if (this.oldCaches.find((u) => u == profile.uuId)) {
                profile.disabled = true;
                profile.selected = true;
              }
            });
          }
        },
      });
  }

  cellGroupFilterAction = ((center: Center[]) => {
    this.filters.cellGroup = [];
    center.forEach((c) =>
      c.cellGroups.forEach((cg) =>
        cg.selected ? this.filters.cellGroup.push(cg) : null,
      ),
    );
    console.log(this.filters.cellGroup);
    this.fetch();
  }).bind(this);

  cacheSelectedProfile(profile: Profile): void {
    if (profile.selected) this.caches.push(profile);
    else
      this.caches.splice(
        this.caches.findIndex((u) => u.uuId == profile.uuId),
        1,
      );
  }

  changePage(): void {
    if (this.userUUId == null)
      this.router.navigate(["/main/announcement/create/user"], {
        queryParams: {
          page: this.filters.page,
          itemsPerPage: this.filters.itemsPerPage,
        },
      });
    else
      this.router.navigate(
        ["/main/announcement/overview", this.userUUId, "user"],
        {
          queryParams: {
            page: this.filters.page,
            itemsPerPage: this.filters.itemsPerPage,
          },
        },
      );
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction = ((value: Center[] | any) => {
    this.filters.centers = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  conditionFilterAction = ((
    genders: string[] | any,
    healths: Health[] | any,
    livings: Living[] | any,
  ) => {
    this.filters.genders = genders;
    this.filters.healths = healths;
    this.filters.conditions = livings;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  private getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.services
        .map((service: Service) => "&services=" + service.uuId)
        .join("") +
      this.filters.districts
        .map((district: string) => "&districts=" + district)
        .join("") +
      this.filters.subDistricts
        .map((subDistrict: string) => "&subDistricts=" + subDistrict)
        .join("") +
      this.filters.conditions
        .map((condition: Living) => "&livingConditions=" + condition.uuId)
        .join("") +
      this.filters.healths
        .map((health: Health) => "&healthConditions=" + health.uuId)
        .join("") +
      this.filters.genders
        .map((gender: string) => "&gender=" + gender)
        .join("") +
      (this.filters.memberIdOrder != null
        ? "&orderByMemberId=" + String(this.filters.memberIdOrder)
        : "") +
      (this.filters.creditOrder != null
        ? "&orderByBalance=" + String(this.filters.creditOrder)
        : "") +
      this.filters.cellGroup
        .map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId)
        .join("")
    );
  }

  selectAll(): void {
    if (this.isSelectedAll) {
      this.profiles
        .filter((profile) => !profile.disabled)
        .forEach((p) => {
          p.selected = false;
          this.caches.splice(
            this.caches.findIndex((u) => u.uuId == p.uuId),
            1,
          );
        });
    } else {
      this.caches = this.caches.concat(
        this.profiles.filter(
          (profile) => profile.selected == false && !profile.disabled,
        ),
      );
      this.profiles.forEach((p) => (p.selected = true));
    }
  }

  submit(): void {
    if (this.userUUId == null) {
      this.announcementService.form.users = this.caches;
      this.router.navigate(["/main/announcement/create"]);
      return;
    }

    //Back to Overview
    this.loadingService.startLoading();

    this.announcementService
      .addRecipients(
        this.userUUId as string,
        this.caches.map((user) => user.uuId),
      )
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          this.popupMessageService.messageSignal.emit(
            PopupMessages.AddAnnouncementRecipientsSuccessMessage(
              new Observable<any>((subscriber) => {
                this.router.navigate([
                  "/main/announcement/overview",
                  this.route.snapshot.paramMap.get("id"),
                ]);
              }),
            ),
          );
        },
      });
  }

  back(): void {
    if (this.userUUId == null)
      this.router.navigate(["/main/announcement/create"]);
    else this.router.navigate(["main/announcement/overview", this.userUUId]);
  }

  get isSelectedAll(): boolean {
    return this.profiles
      .filter((profile) => !profile.disabled)
      .every((profile) => profile.selected == true);
  }

  fetchEverything(): void {
    this.loadingService.startLoading();
    this.profileService
      .getProfileList(this.getEverythingQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          var templist = value.list.map((profile) => new Profile(profile));
          this.caches = templist.filter(
            (profile) => !this.oldCaches.find((p) => p == profile.uuId),
          );
          this.profiles.forEach((profile) => {
            if (this.caches.find((u) => u.uuId == profile.uuId))
              profile.selected = true;
          });
        },
      });
  }

  private getEverythingQueryString(): string {
    return (
      "?start=0" +
      "&limit=5000" +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      this.filters.centers
        .map((center: Center) => "&centers=" + center.uuId)
        .join("") +
      this.filters.services
        .map((service: Service) => "&services=" + service.uuId)
        .join("") +
      this.filters.districts
        .map((district: string) => "&districts=" + district)
        .join("") +
      this.filters.subDistricts
        .map((subDistrict: string) => "&subDistricts=" + subDistrict)
        .join("") +
      this.filters.conditions
        .map((condition: Living) => "&livingConditions=" + condition.uuId)
        .join("") +
      this.filters.healths
        .map((health: Health) => "&healthConditions=" + health.uuId)
        .join("") +
      this.filters.genders
        .map((gender: string) => "&gender=" + gender)
        .join("") +
      this.filters.cellGroup
        .map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId)
        .join("") +
      (this.filters.memberIdOrder != null
        ? "&orderByMemberId=" + String(this.filters.memberIdOrder)
        : "") +
      (this.filters.creditOrder != null
        ? "&orderByBalance=" + String(this.filters.creditOrder)
        : "")
    );
  }

  get isSelectedEverything(): boolean {
    return this.caches.length >= this.count - this.oldCaches.length;
  }

  selectEverything(): void {
    if (this.isSelectedEverything == true) {
      this.caches = [];
      this.profiles
        .filter((profile) => !profile.disabled)
        .forEach((profile) => (profile.selected = false));
    } else {
      this.fetchEverything();
    }
  }
}
