import { FormControl, FormGroup } from "@angular/forms";
import { UpdateEventTypeModel } from "./update-event-type-model";

export interface IEventType {
    code: string;
    name: string;
    uuId: string;
}

export class EventType {
    public code: string;
    public name: string;
    public uuId: string;
    public editing: boolean;
    selected: boolean;
    form: FormGroup;

    constructor(eventType: IEventType) {
        this.code = eventType.code;
        this.name = eventType.name;
        this.uuId = eventType.uuId;
        this.editing = false;
        this.selected = false;
        this.form = new FormGroup({
            code: new FormControl(eventType.code),
            name: new FormControl(eventType.name)
        });
    }

    getUpdateEventTypeModel(): UpdateEventTypeModel {
        return {
            uuId: this.uuId,
            code: this.form.controls['code'].value,
            name: this.form.controls['name'].value
        }
    }
}