import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Region } from "src/app/models/addresses/region";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-address-filter",
  templateUrl: "./address-filter.component.html",
  styleUrls: ["./address-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class AddressFilterComponent implements OnInit {
  @Input() action: (regions: string[], subDistricts: string[]) => void =
    () => {};
  form: FormControl = new FormControl(null);
  display: boolean = false;

  regions: Region[] = [
    new Region("香港島", ["中西區", "東區", "南區", "灣仔區"]),
    new Region("九龍", [
      "九龍城區",
      "觀塘區",
      "深水埗區",
      "黃大仙區",
      "油尖旺區",
    ]),
    new Region("新界", [
      "葵青區",
      "荃灣區",
      "屯門區",
      "元朗區",
      "西貢區",
      "大埔區",
      "離島區",
      "沙田區",
      "北區",
    ]),
  ];

  subDistricts: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  toggle(): void {
    if (this.form.value != "") {
      this.addSubDistrict();
    }
    if (this.display == true) {
      this.action(
        this.regions
          .map((r) => r.districts.filter((d) => d.selected))
          .reduce((merged, value) => merged.concat(value))
          .map((v) => v.name),
        this.subDistricts,
      );
    }
    this.display = !this.display;
  }

  reset(): void {
    this.regions.forEach((region) => region.reset());
    this.subDistricts = [];
  }

  addSubDistrict(): void {
    if (this.form.value == null) return;
    this.subDistricts.push(this.form.value);
    this.form.reset();
  }

  removeSubDistrict(index: number): void {
    this.subDistricts.splice(index, 1);
  }

  get numberOfSelectedAddresses(): number {
    return (
      this.regions
        .map((r) => r.districts.filter((d) => d.selected).length)
        .reduce((sum, length) => sum + length, 0) + this.subDistricts.length
    );
  }
}
