<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>職員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>創建職員</p>
    </div>

    <p class="page-title mt-8" translate>創建職員</p>

    <div class="container mt-5">
        <form class="container-body" [formGroup]="form.formGroup">
            <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '會員編號' | translate }}" [matTooltipPosition]="'above'" translate>會員編號</p>
                <input class="user-input xs" type="text" formControlName="memberId" placeholder="{{ '編號' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="memberId.touched && memberId.errors">
                    <p *ngIf="memberId.errors.required" translate>請輸入會員編號</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '登入帳號' | translate }}" [matTooltipPosition]="'above'" translate>登入帳號</p>
                <input class="user-input xs" type="text" formControlName="username" placeholder="{{ '登入帳號' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="username.touched && username.errors">
                    <p *ngIf="username.errors.required" translate>請輸入登入帳號</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '登入密碼' | translate }}" [matTooltipPosition]="'above'" translate>登入密碼</p>
                <input class="user-input pw" type="text" formControlName="password" placeholder="{{ '至少4個字符，必須包含數字' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="password.touched && password.errors">
                    <p *ngIf="password.errors.required" translate>請輸入帳號密碼</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '職員姓名' | translate }}" [matTooltipPosition]="'above'" translate>職員姓名</p>
                <input class="user-input xs" type="text" formControlName="chineseName" placeholder="{{ '中文姓名*' | translate }}">
                <input class="user-input xl ml-6" type="text" formControlName="englishName" placeholder="{{ 'English Name' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="chineseName.touched && chineseName.errors">
                    <p *ngIf="chineseName.errors.required" translate>請輸入中文姓名</p>
                </div>
                <div class="user-input-error zoomIn" *ngIf="!chineseName.errors && englishName.touched && englishName.errors">
                    <p *ngIf="englishName.errors.required" translate>請輸入英文姓名</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '中心名稱' | translate }}" [matTooltipPosition]="'above'" translate>中心名稱</p>
                <div class="user-select">
                    <select class="user-select xxxl" formControlName="center" (change)="onCenterChange()">
                        <option [ngValue]="null" disabled selected translate>中心名稱</option>
                        <option *ngFor="let center of centers" [ngValue]="center.uuId">{{ center.name }}</option>
                    </select>
                </div>
                <div class="user-input-error zoomIn" *ngIf="center.touched && center.errors">
                    <p *ngIf="center.errors.required" translate>請選擇所屬中心</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '性別' | translate }}" [matTooltipPosition]="'above'" translate>性別</p>
                <label class="align-items-center" style="margin-right: 2.75rem;">
                    <input type="radio" value="M" formControlName="gender">
                    <p translate>男</p>
                </label>
                <label class="align-items-center">
                    <input type="radio" value="F" formControlName="gender">
                    <p translate>女</p>
                </label>
                <div class="user-input-error zoomIn" *ngIf="gender.touched && gender.errors">
                    <p *ngIf="gender.errors.required" translate>請選擇性別</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '電話號碼' | translate }}" [matTooltipPosition]="'above'" translate>電話號碼</p>
                <input class="user-input xs" type="text" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話*' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="phone.touched && phone.errors">
                    <p *ngIf="phone.errors.required" translate>請輸入電話號碼</p>
                </div>
            </div>

            <ng-container *ngFor="let address of form.addresses;let index = index">
                <app-address-form style="margin-top: 2rem;margin-left: -1.5rem;" [form]="address" [index]="index+1" [dropAction]="dropAddressAction"></app-address-form>
            </ng-container>

            <div class="add-address-button" style="margin-left: -1.5rem;" (click)="form.addAddress()">
                <p class="text-lightgrey" translate>增添地址</p>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '權限設置' | translate }}" [matTooltipPosition]="'above'" translate>權限設置</p>
                <p class="text-lightgrey" *ngIf="form.accessibleCenters.length == 0" translate>暫未指定可見資料的中心代碼</p>
                <ng-container *ngIf="form.accessibleCenters.length > 0;then permission"></ng-container>
            </div>

            <div class="input-row">
                <p class="title required align-self-start lh-8" matTooltip="{{ '註冊審核' | translate }}" [matTooltipPosition]="'above'" translate>註冊審核</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row align-items-center">
                        <p class="text-lightgrey lh-8" translate>是否擁有審核機構內會員帳號申請資料的權限？</p>
                        <i class="material-icons-round info-icon ml-4">help_outline</i>
                        <p class="text-lightgrey text-xs ml-4 lh-8" translate>該權限可審核由App遞交的會員帳號申請，並可指定通過申請的會員所屬中心位置</p>
                    </div>

                    <div class="inline-flex-row">
                        <label class="align-items-center" style="margin-right: 2.75rem;">
                            <input type="radio" [value]="true" formControlName="allowApproval">
                            <p class="lh-8" translate>是</p>
                        </label>
                        <label class="align-items-center">
                            <input type="radio" [value]="false" formControlName="allowApproval">
                            <p class="lh-8" translate>否</p>
                        </label>
                    </div>
                </div>
            </div>

            <div class="inline-flex-row justify-content-center" style="margin-top: 20.5625rem;">
                <button class="button outline" type="button" (click)="form.reset()" translate>取消創建</button>
                <button class="button fill blue" style="margin-left: 9.375rem;" type="button" (click)="submit()" translate>確認創建</button>
            </div>
        </form>
    </div>
</div>

<ng-template #permission>
    <div class="inline-flex-column">
        <p class="text-grey border-bottom" style="line-height: 2rem;width: 25.9375rem;" translate>
            可擁有以下中心的職員權限，包括查看同操作會員資料</p>
        <div class="inline-flex-row align-items-center" *ngFor="let center of form.accessibleCenters;let index = index">
            <p style="flex: 0 0 2.5rem;line-height: 2rem;">{{ center.centerCode }}</p>
            <p class="flex-1">{{ center.name }}</p>
            <p class="text-xs" *ngIf="index == 0" translate>默認</p>
            <p class="text-xs" *ngIf="index != 0" (click)="dropPermission(index)" translate>移除</p>
        </div>

        <div class="inline-flex-row align-items-center" (click)="permission_popup.open(form.accessibleCenters)">
            <p class="text-lightgrey mr-4" style="line-height: 2rem;">新增中心</p>
            <i class="material-icons-round add-icon">add_circle</i>
        </div>
    </div>
</ng-template>

<app-center-permission-popup [action]="permissionAction" [defaultCenter]="defaultCenter" #permission_popup>
</app-center-permission-popup>