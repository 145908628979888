<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="sign-up-box zoomIn" (click)="$event.stopPropagation()">
        <div class="inline-flex-row align-items-start">
            <i class="material-icons-round" style="color: #FAAD14;font-size: 1.5rem;">help</i>
            <div class="inline-flex-column ml-8">
                <p class="text-lg text-grey" style="line-height: 1.5rem;" translate>確認要拒絕該申請嗎？</p>
                <p class="text-lightgrey mt-6" style="white-space: initial;text-overflow: inherit; line-height: 1.25rem;" translate>請填寫拒絕理由，拒絕該帳號申請後，審核結果通知會發送至該申請人應用程式，且資料在XX日後自動移除</p>
            </div>
        </div>

        <textarea class="align-self-center mt-8" placeholder="{{ '請輸入不通過理由' | translate }}" [formControl]="form" style="width: 22rem;height: 8.625rem;"></textarea>

        <div class="inline-flex-row align-items-center justify-content-center" style="margin-top: 2.5rem;">
            <button class="button outline xs" (click)="close()" translate>取 消</button>
            <button class="button fill blue xs" style="margin-left: 10.625rem;" (click)="submit()" translate>確 定</button>
        </div>
    </div>
</div>