<div class="time-picker" [ngClass]="{disabled: form.disabled}">
    <input class="user-input xs" type="text" [ngClass]="{'invalid': timeText == null}" (focus)="form.markAsTouched()" [ngModel]="timeText" placeholder="--:--" readonly [disabled]="form.disabled">
    <div tabindex="0" class="time-picker-popup zoomIn" (focusout)="submit()">
        <div class="inline-flex-row justify-content-center align-items-center" style="flex: 1 0">
            <div class="inline-flex-column justify-content-center align-items-center arrow">
                <i class="material-icons-round text-lightgrey arrow" (click)="addHour()">expand_less</i>
                <input #hourInput class="user-input time-input" type="text" [(ngModel)]="hour" (change)="onHourChange()" (click)="hourInput.select()" maxlength="2" pattern="^[0][0-9]$|^[1][0-9]$|^[2][0-3]$" placeholder="HH">
                <i class="material-icons-round text-lightgrey arrow" (click)="minusHour()">expand_more</i>
            </div>
            <p class="ml-4 mr-4">:</p>
            <div class="inline-flex-column justify-content-center align-items-center">
                <i class="material-icons-round text-lightgrey arrow" (click)="addMinute()">expand_less</i>
                <input #minuteInput class="user-input time-input" type="text" [(ngModel)]="minute" (change)="onMinuteChange()" (click)="minuteInput.select()" maxlength="2" pattern="^[0][0-9]$|^[1-5][0-9]$" placeholder="MM">
                <i class="material-icons-round text-lightgrey arrow" (click)="minusMinute()">expand_more</i>
            </div>
        </div>
        <button class="time-button" #confirm (click)="confirm.blur()" type="button" translate>確 定</button>
    </div>
</div>