import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { AppSetting } from "src/app/backend/app-setting";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { EventType } from "src/app/models/event-types/event-type";
import { EventListItem } from "src/app/models/events/event-list-item";
import { EventService } from "src/app/services/event/event.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { OrganizationService } from "src/app/services/organization/organization.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-event-trash-bin",
  templateUrl: "./event-trash-bin.component.html",
  styleUrls: ["./event-trash-bin.component.scss"],
})
export class EventTrashBinComponent implements OnInit {
  remainDays: number = AppSetting.remainDays;

  events: EventListItem[] = [];
  caches: EventListItem[] = [];
  count: number = 0;
  pages: number = 0;

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    from: null,
    to: null,
    statuses: null,
    isDeleted: true,
    idOrder: true,
    dateOrder: null,
    eventTypes: [],
    eventTransactionTypes: [],
    centers: [],
  };

  constructor(
    private eventService: EventService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((value) => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      events: this.eventService.getEvents(this.getQueryString()),
      remainDays: this.organizationService.getOrganizationConfig(),
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.count = value.events.count;
        this.pages =
          value.events.count != 0
            ? Math.ceil(value.events.count / this.filters.itemsPerPage)
            : 0;
        this.events = value.events.list;
        this.events.forEach((e) => {
          if (this.caches.find((event) => event.uuId == e.uuId))
            e.selected = true;
        });

        this.remainDays = value.remainDays.config.TrashClearTime;
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction = ((start: Date, end: Date) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  eventTypeFilterAction = ((eventTypes: EventType[]) => {
    this.filters.eventTypes = eventTypes;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  eventTransactionTypeFilterAction = ((types: string[]) => {
    this.filters.eventTransactionTypes = types;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction = ((status: number) => {
    this.filters.statuses = status;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  changePage(): void {
    this.router.navigate(["/main/trash/event"], {
      queryParams: {
        page: this.filters.page,
        itemsPerPage: this.filters.itemsPerPage,
      },
    });
  }

  cacheEvent(e: EventListItem): void {
    if (e.selected) this.caches.push(e);
    else
      this.caches.splice(
        this.caches.findIndex((event) => event.uuId == e.uuId),
        1,
      );
  }

  restoreEvent(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.caches = [];
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.RestoreMessage(
        this.eventService.restoreEvents({ list: [uuId] }),
      ),
    );
  }

  restoreEvents(): void {
    if (this.caches.length == 0) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.NoRecordSelectedMessage,
      );
      return;
    }

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.caches = [];
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.RestoreListMessage(
        this.eventService.restoreEvents({
          list: this.caches.map((event) => event.uuId),
        }),
      ),
    );
  }

  deleteEventPermanently(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeletePermanentlyMessage(
        this.eventService.deleteEventsPermanently({ list: [uuId] }),
      ),
    );
  }

  deleteEventListPermanently(): void {
    if (this.caches.length == 0) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.NoRecordSelectedMessage,
      );
      return;
    }

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.caches = [];
        this.fetch();
        subscriber.unsubscribe();
      },
    );

    this.popupMessageService.messageSignal.emit(
      PopupMessages.DeleteListPermanentlyMessage(
        this.eventService.deleteEventsPermanently({
          list: this.caches.map((event) => event.uuId),
        }),
      ),
    );
  }

  deleteAllPermanently(): void {
    this.loadingService.startLoading();
    this.eventService
      .getEvents(this.getAllQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          var subscriber =
            this.popupMessageService.executeSuccessSignal.subscribe((value) => {
              this.caches = [];
              this.fetch();
              subscriber.unsubscribe();
            });

          this.popupMessageService.messageSignal.emit(
            PopupMessages.DeleteListPermanentlyMessage(
              this.eventService.deleteEventsPermanently({
                list: value.list.map((event) => event.uuId),
              }),
            ),
          );
        },
      });
  }

  private getQueryString(): string {
    return (
      "?start=" +
      (this.filters.page - 1) * this.filters.itemsPerPage +
      "&limit=" +
      this.filters.itemsPerPage +
      (this.filters.searchInput
        ? "&filterString=" + this.filters.searchInput
        : "") +
      (this.filters.from ? "&from=" + this.filters.from.toJSON() : "") +
      (this.filters.to ? "&to=" + this.filters.to.toJSON() : "") +
      (this.filters.statuses ? "&statuses=" + this.filters.statuses : "") +
      (this.filters.idOrder != null
        ? "&orderById=" + String(this.filters.idOrder)
        : "") +
      (this.filters.dateOrder != null
        ? "&orderByDate=" + String(this.filters.dateOrder)
        : "") +
      (this.filters.isDeleted
        ? "&isDeleted=" + String(this.filters.isDeleted)
        : "") +
      this.filters.eventTypes
        .map((type: EventType) => "&eventTypes=" + type.uuId)
        .join("") +
      this.filters.eventTransactionTypes
        .map((type: string) => "&eventPays=" + type)
        .join("") +
      (this.filters.centers
        ? this.filters.centers.map((c: Center) => "&centers=" + c.uuId)
        : "")
    );
  }

  private getAllQueryString(): string {
    return "?start=0&limit=10000&isDeleted=true";
  }
}
