import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateServiceModel } from "./update-service-model";

export interface IService {
  type: string;
  uuId: string;
  name: string;
  servicesCode: string;
  destinationNeeded: boolean;
  enable: boolean;
}

export class Service {
  type: string;
  uuId: string;
  name: string;
  destinationNeeded: boolean;
  servicesCode: string;
  enable: boolean;

  selected: boolean;
  editing: boolean;
  form: FormGroup;

  public constructor(service: IService) {
    this.type = service.type;
    this.uuId = service.uuId;
    this.name = service.name;
    this.destinationNeeded = service.destinationNeeded;
    this.servicesCode = service.servicesCode;
    this.enable = service.enable;
    this.selected = false;
    this.editing = false;
    this.form = new FormGroup({
      name: new FormControl(service.name, [Validators.required]),
      code: new FormControl(service.servicesCode),
      destinationNeeded: new FormControl(service.destinationNeeded),
      enable: new FormControl(service.enable),
    });
  }

  getUpdateServiceModel(): UpdateServiceModel {
    return {
      uuId: this.uuId,
      name: this.form.controls["name"].value,
      servicesCode: this.form.controls["code"].value,
      destinationNeeded: this.form.controls["destinationNeeded"].value,
      enable: this.form.controls["enable"].value,
    };
  }
}
