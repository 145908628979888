import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { BarController, BarElement, Chart, ChartOptions } from "chart.js";
import * as dayjs from "dayjs";
import { TransactionChartData } from "src/app/models/analytics/transaction-chart-data";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { ExportService } from "src/app/services/export/export.service";
@Component({
  selector: "app-chart-analysis",
  templateUrl: "./chart-analysis.component.html",
  styleUrls: ["./chart-analysis.component.scss"],
})
export class ChartAnalysisComponent implements AfterViewInit {
  transactionChartData: TransactionChartData | null = null;

  get placeholder(): string {
    let placeholderStart = dayjs()
      .set("month", 0)
      .set("date", 1)
      .format("YYYY-MM-DD");
    let placeholderEnd = dayjs().format("YYYY-MM-DD");
    if (this.start.value != null) {
      placeholderStart = dayjs(this.start.value).format("YYYY-MM-DD");
    }
    if (this.end.value != null) {
      placeholderEnd = dayjs(this.end.value).format("YYYY-MM-DD");
    }
    return `${placeholderStart} - ${placeholderEnd}`;
  }

  transactionType: string = "Request";
  dataType: string = "Monthly";

  @ViewChild("graph") graph!: ElementRef;
  context!: CanvasRenderingContext2D;
  chart!: Chart;

  @Input() form!: FormControl;
  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  centerUUId?: string;

  constructor(
    private analyticsService: AnalyticsService,
    private exportService: ExportService,
  ) {}

  ngAfterViewInit(): void {
    Chart.register(BarController, BarElement);

    this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext(
      "2d",
    ) as CanvasRenderingContext2D;

    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });
  }

  fetch(): void {
    this.analyticsService
      .GetTransactionChartData({
        centerUUId: this.centerUUId,
        startDate: this.start.value ? this.start.value : null,
        endDate: this.end.value ? this.end.value : null,
        periodType: this.dataType,
        transactionType: this.transactionType,
      })
      .subscribe((value) => {
        this.transactionChartData = value;
        this.transactionChartData.service =
          this.transactionChartData.service.sort((a, b) =>
            a.value > b.value ? -1 : a.value < b.value ? 1 : 0,
          );

        this.initChart();
      });
  }
  initChart(): void {
    if (this.chart) this.chart.destroy();

    var sortArray = this.transactionChartData?.amount.sort((a, b) => {
      if (a.index > b.index) return 1;
      if (a.index < b.index) return -1;
      return 0;
    });
    if (sortArray == null) return;

    this.chart = new Chart(this.context, {
      type: "bar",
      data: {
        labels: sortArray.map((data) => data.index),
        datasets: [
          {
            // labels: this.transactionChartData?.amount.map(data => data.index),
            data: sortArray.map((data) => data.value) as number[],
            backgroundColor: "rgba(24, 144, 255, 0.3)",
            borderColor: "rgba(24, 144, 255, 1)",
            borderWidth: 1,
            barPercentage: 0.5,
          },
        ],
      },
      options: chartOptions,
    });
  }

  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null) return null;
    return `${dayjs(this.start.value).format("YYYY-MM-DD")} - ${dayjs(this.end.value).format("YYYY-MM-DD")}`;
  }

  export(): void {
    this.exportService.exportTransactionChartData(
      this.transactionChartData,
      this.dataType,
      this.transactionType,
      this.start.value,
      this.end.value,
    );
  }
}

const chartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: { display: false },
  },
  scales: {
    x: {
      grid: { display: false },
    },
    y: { beginAtZero: true },
  },
};
