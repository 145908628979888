export interface IImportFile {
  file: File;
  uploadResult: boolean;
  error: any;
}

export class ImportFile implements IImportFile {
  public file: File;
  public uploadResult: boolean;
  public error: any;

  constructor(file: File) {
    this.file = file;
    this.uploadResult = false;
    this.error = null;
  }
}
