<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>獎勵兌換</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>發布獎勵</p>
    </div>

    <p class="page-title mt-8" translate>發布獎勵</p>

    <div class="container mt-5">
        <form class="container-body" [formGroup]="form.formGroup">
            <p class="text-xs text-lightgrey">欄目帶 * 號為必填項</p>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '獎勵標題' | translate }}" [matTooltipPosition]="'above'" translate>獎勵標題</p>
                <input class="user-input xl" type="text" formControlName="title" placeholder="{{ '請輸入獎勵項目標題' | translate }}">

                <div class="zoomIn user-input-error" *ngIf="title.touched && title.errors">
                    <p class="error-text text-sm" *ngIf="title.errors.required" translate>請輸入獎勵標題</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '獎勵來源' | translate }}" [matTooltipPosition]="'above'" translate>獎勵來源</p>
                <input class="user-input xl" type="text" formControlName="source" placeholder="{{ '請輸入來源（例如品牌，商家等）' | translate }}">

                <div class="zoomIn user-input-error" *ngIf="source.touched && source.errors">
                    <p class="error-text text-sm" *ngIf="source.errors.required" translate>請輸入獎勵來源</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title" matTooltip="{{ '宣傳圖片' | translate }}" [matTooltipPosition]="'above'" translate>宣傳圖片</p>
                <button type="button" class="button outline blue sm" (click)="image_input.open()" *ngIf="!hasImage;else image_preview" translate>上傳圖片</button>
                <ng-template #image_preview>
                    <img class="image mr-3" #image>
                    <p class="mr-3">{{ filename }}</p>
                    <i class="material-icons-outlined click-text-lightblue" style="font-size: 1rem;" (click)="image_input.open(file)">edit</i>
                </ng-template>
                <app-redeem-image-popup [action]="imageUploadAction" #image_input></app-redeem-image-popup>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '參與方式' | translate }}" [matTooltipPosition]="'above'" translate>參與方式</p>
                <label>
                    <input type="radio" formControlName="format" value="1">
                    <p translate>先到先得</p>
                </label>
                <label style="margin-left: 1.5rem;">
                    <input type="radio" formControlName="format" value="0">
                    <p translate>職員處理</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="format.touched && format.errors">
                    <p class="error-text text-sm" *ngIf="format.errors.required" translate>請輸入參與方式</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '適用對象' | translate }}" [matTooltipPosition]="'above'" translate>適用對象</p>
                <input class="user-input xl" type="text" formControlName="target" placeholder="{{ '請輸入文字描述' | translate }}">
                <label>
                    <input class="ml-4" type="checkbox" formControlName="targetAll">
                    <p class="ml-4" translate>所有會員</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="target.touched && target.errors">
                    <p class="error-text text-sm" *ngIf="target.errors.required" translate>請輸入適用對象</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '兌換比率' | translate }}" [matTooltipPosition]="'above'" translate>兌換比率</p>
                <p translate>單個獎勵須扣除</p>
                <input class="ml-4 mr-4 user-input xxs" type="number" formControlName="price">
                <p translate>時分</p>
                <label>
                    <input class="ml-4" type="checkbox" formControlName="free">
                    <p class="ml-4" translate>免費</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="price.touched && price.errors">
                    <p class="error-text text-sm" *ngIf="price.errors.required" translate>請輸入兌換比率</p>
                    <p class="error-text text-sm" *ngIf="price.errors.min" translate>無效兌換比率</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" translate>庫存</p>
                <input class="mr-4 user-input xxs" type="number" formControlName="stock">
                <p translate>件</p>
                <label>
                    <input class="ml-4" type="checkbox" formControlName="adequateStock">
                    <p class="ml-4" translate>庫存充足</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="stock.touched && stock.errors">
                    <p class="error-text text-sm" *ngIf="stock.errors.required" translate>請輸入庫存</p>
                    <p class="error-text text-sm" *ngIf="stock.errors.min" translate>無效庫存</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '換領次限' | translate }}" [matTooltipPosition]="'above'" translate>換領次限</p>
                <p translate>每個會員最多可換領數量</p>
                <input class="ml-4 user-input" style="width: 4.3125rem;" type="number" formControlName="limit">
                <label>
                    <input class="ml-8" type="checkbox" formControlName="noLimit">
                    <p class="ml-4" translate>不限次限</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="limit.touched && limit.errors">
                    <p class="error-text text-sm" *ngIf="limit.errors.required" translate>請輸入換領次限</p>
                    <p class="error-text text-sm" *ngIf="limit.errors.min" translate>無效換領次限</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" translate>發布時間</p>
                <input matInput class="user-input" formControlName="launchDate" [matDatepicker]="picker2" (click)="picker2.open()" readonly placeholder="{{ '發布時間' | translate }}">

                <app-time-picker [form]="launchDate" class="ml-9"></app-time-picker>

                <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                <label>
                    <input class="ml-4" type="checkbox" formControlName="launchImmediately">
                    <p class="ml-4" translate>即時發布</p>
                </label>

                <div class="user-input-error zoomIn" *ngIf="launchDate.touched && launchDate.errors">
                    <p *ngIf="launchDate.errors.required" translate>請輸入發布日期</p>
                    <p *ngIf="launchDate.errors.time" translate>請輸入發布時間</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '截止換領' | translate }}" [matTooltipPosition]="'above'" translate>截止換領</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row">
                        <input matInput class="user-input" formControlName="closeDate" [matDatepicker]="picker" (click)="picker.open()" readonly placeholder="{{ '截止換領' | translate }}">

                        <app-time-picker [form]="closeDate" class="ml-9"></app-time-picker>

                        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
                    </div>

                    <div class="flex-row align-items-center mt-2">
                        <i class="info-icon material-icons-round mr-5">error_outline</i>
                        <p class="text-xs text-lightgrey" translate>過了截止換領日期後會員不可再換領，但仍可在App中見到該項目，除非職員已下架該項目則該項目不可見</p>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="closeDate.touched && closeDate.errors">
                        <p *ngIf="closeDate.errors.required" translate>請輸入截止換領日期</p>
                        <p *ngIf="closeDate.errors.time" translate>請輸入截止換領時間</p>
                        <p *ngIf="closeDate.errors.invalid" translate>截止換領必須在發布時間之後</p>
                    </div>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '詳情介紹' | translate }}" [matTooltipPosition]="'above'" translate>詳情介紹</p>
                <textarea style="width: 22rem;height: 15.5rem;" formControlName="description" placeholder="{{ '請輸入最少一句話描述（例如獎勵介紹，規則及領取方式等）' | translate}}"></textarea>

                <div class="user-input-error zoomIn" *ngIf="description.touched && description.errors">
                    <p *ngIf="description.errors.required" translate>請輸入詳情介紹</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" translate>選擇中心</p>
                <div class="inline-flex-column" *ngIf="form.centers.length == 0">
                    <p class="text-lightgrey" style="line-height: 2rem;" translate>未指定，選擇並提交後所選中心下所屬會員（包括後加入的新會員）都會見到該獎勵項目</p>
                    <button type="button" class="mt-4 button outline blue sm" (click)="center_popup.open(form.centers)" translate>中心列表</button>
                </div>

                <div class="inline-flex-column" *ngIf="form.centers.length > 0">
                    <div class="inline-flex-row align-items-center">
                        <p translate>已選擇</p>
                        <p class="ml-4 mr-4 text-lightblue">{{ form.centers.length}}</p>
                        <p translate>個中心</p>
                        <p class="text-lightgrey" style="line-height: 2rem;" translate>所選中心下所屬會員（包括後加入的新會員）都會見到該獎勵項目</p>
                    </div>
                    <div class="border-bottom mt-4 mb-5"></div>
                    <div class="flex-row align-items-center mt-2 mb-4" *ngFor="let center of form.centers">
                        <p style="flex: 0 0 3rem">{{ center.centerCode }}</p>
                        <p class="flex-1">{{ center.name }}</p>
                    </div>
                    <div class="flex-row align-items-center" (click)="center_popup.open(form.centers)">
                        <p class="text-lightgrey mr-4" translate>新增中心</p>
                        <i class="add-icon material-icons-round">add_circle</i>
                    </div>
                </div>

                <app-center-popup #center_popup [action]="centerPopupAction"></app-center-popup>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" matTooltip="{{ '問題設置' | translate }}" [matTooltipPosition]="'above'" translate>問題設置</p>
                <div class="inline-flex-column">
                    <div class="inline-flex-row">
                        <label>
                            <input type="radio" formControlName="questionSetting" [value]="true">
                            <p translate>會員換領前須填寫問卷</p>
                        </label>
                        <label style="margin-left: 1rem;">
                            <input type="radio" formControlName="questionSetting" [value]="false">
                            <p translate>無須填寫問卷</p>
                        </label>
                    </div>
                    <div class="flex-row align-items-center mt-4">
                        <i class="info-icon material-icons-round mr-5">error_outline</i>
                        <p class="text-xs text-lightgrey" translate>在導航欄--獎勵兌換--問題設置中可設置通用的問題以及選項</p>
                    </div>
                </div>

                <div class="user-input-error zoomIn" *ngIf="questionSetting.touched && questionSetting.errors">
                    <p *ngIf="questionSetting.errors.required" translate>請輸入問題設置</p>
                </div>
            </div>

            <div class="inline-flex-row justify-content-center" style="margin-top: 7.125rem;">
                <button type="button" class="button fill blue" (click)="submit()" translate>提 交</button>
            </div>
        </form>
    </div>
</div>