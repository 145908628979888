import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { EventParticipant } from "src/app/models/events/event-participant";
import { EventService } from "src/app/services/event/event.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-event-detail-pending-user-tab",
  templateUrl: "./event-detail-pending-user-tab.component.html",
  styleUrls: ["./event-detail-pending-user-tab.component.scss"],
})
export class EventDetailPendingUserTabComponent implements OnInit {
  @Input() users: EventParticipant[] = [];
  @Input() closeAction: () => void = () => {};
  @Input() reloadAction: () => void = () => {};
  @Input() changeTabAction: (index: number) => void = () => {};
  @Input() format: string = "Staff";
  @Input() event!: Event;

  constructor(
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {}

  public acceptInvitation(): void {
    if (this.selectedUsers.length == 0) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.ZeroParticipantErrorMessage,
      );
      return;
    }

    let observable: Observable<any>;

    this.route.params.subscribe((value) => {
      if (this.format == "Staff")
        observable = this.eventService.acceptInvitation(value.id, {
          list: this.users.filter((u) => u.selected).map((u) => u.uuId),
        });
      else
        observable = this.eventService.joinEvent(value.id, {
          list: this.users.filter((u) => u.selected).map((u) => u.uuId),
        });

      this.loadingService.startLoading();
      observable
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          if (this.format == "Staff") this.changeTabAction(1);
          else this.changeTabAction(0);
          this.reloadAction();
        });
    });
  }

  remove(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(
      (value) => {
        this.reloadAction();
        subscriber.unsubscribe();
      },
    );

    this.route.params.subscribe((value) => {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.RemoveUserFromEventMessage(
          this.eventService.removeParticipants(value.id, {
            userList: [uuId],
            role: "Attendant",
          }),
        ),
      );
    });
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.users.forEach((user) => (user.selected = false));
    else this.users.forEach((user) => (user.selected = true));
  }

  get selectedUsers(): EventParticipant[] {
    return this.users.filter((user) => user.selected);
  }

  get isSelectedAll(): boolean {
    return this.users.every((user) => user.selected);
  }
}
