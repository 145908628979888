import { Component, Input, OnInit } from "@angular/core";
import { PortalAnimation } from "src/app/portal-animation";

@Component({
  selector: "app-donation-type-filter",
  templateUrl: "./donation-type-filter.component.html",
  styleUrls: ["./donation-type-filter.component.scss"],
  animations: [PortalAnimation.DropDown],
})
export class DonationTypeFilterComponent implements OnInit {
  display: boolean = false;
  @Input() action: (types: string[]) => void = () => {};
  types = [
    { name: "會員捐分", selected: false, value: "User" },
    { name: "中心捐分", selected: false, value: "Center" },
    { name: "中心補貼", selected: false, value: "RequestDonation" },
  ];

  constructor() {}

  ngOnInit(): void {}

  reset(): void {
    this.types.forEach((type) => (type.selected = false));
  }

  toggle(): void {
    if (this.display == true) {
      this.action(
        this.types.filter((type) => type.selected).map((type) => type.value),
      );
    }
    this.display = !this.display;
  }

  get numberOfSelectedTypes(): number {
    return this.types.filter((type) => type.selected).length;
  }
}
