import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CreateEventTypeModel } from "src/app/models/event-types/create-event-type-model";
import { EventType, IEventType } from "src/app/models/event-types/event-type";
import { UpdateEventTypeModel } from "src/app/models/event-types/update-event-type-model";

@Injectable({
  providedIn: "root",
})
export class EventTypeService {
  constructor(private httpService: HttpService) {}

  public getEventTypeList(): Observable<EventType[]> {
    return this.httpService
      .get<IEventType[]>("eventtypes")
      .pipe(
        map((value) =>
          value.result
            .map((eventType) => new EventType(eventType))
            .sort(EventTypeSorting),
        ),
      );
  }

  public createEventType(model: CreateEventTypeModel): Observable<any> {
    return this.httpService.post<any, CreateEventTypeModel>(
      "eventtypes",
      model,
    );
  }

  public updateEventType(model: UpdateEventTypeModel): Observable<any> {
    return this.httpService.put<any, IEventType>(
      "eventtypes/" + model.uuId,
      model,
    );
  }
}

function EventTypeSorting(a: EventType, b: EventType): number {
  return a.code.localeCompare(b.code, "en", { numeric: true });
}
