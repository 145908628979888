import { FormControl, FormGroup } from "@angular/forms";
import { EventRecordUser } from "src/app/models/profiles/event-record-user";
import { Profile } from "src/app/models/profiles/profile";
import { CreateEventDayForm } from "./create-event-day-form";
import { CreateEventForm } from "./create-event-form";

export class CreateEventRecordForm extends CreateEventForm {
  users: EventRecordUser[];

  giveAll: FormControl;
  giveInput: FormControl;

  constructor() {
    super();
    this.formGroup.controls["vacancy"] = new FormControl(null);
    this.users = [];
    this.giveAll = new FormControl(false);
    this.giveInput = new FormControl(null);

    this.giveAll.valueChanges.subscribe((value) => {
      if (value == false) {
        this.giveInput.disable();
        this.users.forEach((user) => user.recordForm.enable());
      } else {
        this.giveInput.enable();
        this.users.forEach((user) => user.recordForm.disable());
      }
    });

    this.giveInput.valueChanges.subscribe((value) => {
      if (this.giveAll.value == true) {
        this.users.forEach((user) => user.recordForm.setValue(value));
      }
    });
  }

  get sum(): number {
    return this.users
      .map((user) => (user.recordForm.value ? user.recordForm.value : 0))
      .reduce((sum, value) => (sum += value), 0);
  }
}
