import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConditionConflict } from 'src/app/models/condition-conflicts/condition-conflict';
import { Health } from 'src/app/models/healths/health';
import { Living } from 'src/app/models/livings/living';
import { ConditionConflictService } from 'src/app/services/condition-conflict/condition-conflict.service';
import { HealthService } from 'src/app/services/health/health.service';
import { LivingService } from 'src/app/services/living/living.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-health-living-setting',
  templateUrl: './health-living-setting.component.html',
  styleUrls: ['./health-living-setting.component.scss']
})
export class HealthLivingSettingComponent implements OnInit {

  healths: Health[] = [];
  index: number = 0;
  conflicts: ConditionConflict[] = [];
  livings: Living[] = [];

  constructor(
    private healthService: HealthService,
    private conditionConflictService: ConditionConflictService,
    private livingService: LivingService,
    private loadingService: LoadingService,
    private translate:TranslateService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      healths: this.healthService.getHealthConditionList(),
      conflicts: this.conditionConflictService.getConditionConflictList(),
      livings: this.livingService.getLivingConditionList()
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.healths = value.healths;
          this.conflicts = value.conflicts
          this.livings = value.livings;
        }
      });
  }

  createConflictAction: (livings: Living[]) => void = ((livings: Living[]) => {
    this.loadingService.startLoading();
    this.conditionConflictService.createConditionConflict(livings.map(lc => {
      return {
        healthConditionUUId: this.healths[this.index].uuId,
        livingConditionUUId: lc.uuId
      }
    })).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          // this.messageService.displayPopupMessage(AppStaticMessage.CreateConditionConflictSuccessMessage);
          this.fetch();
        }
      });
  }).bind(this);


  deleteConditionConflict(living: Living): void {
    this.loadingService.startLoading();
    this.conditionConflictService.deleteConditionConflict([{
      healthConditionUUId: this.healths[this.index].uuId,
      livingConditionUUId: living.uuId
    }]).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => {
          // this.messageService.displayPopupMessage(AppStaticMessage.DeleteConditionConflictSuccessMessage);
          this.fetch();
        }
      });
  }


  getHealthConditionConflictsLength(health: Health): number {
    return this.conflicts.filter(cc => cc.healthConditionUUId == health.uuId).length;
  }
   get  selectedHealthName():string{
    var out:string="";
    this.translate.get('和健康狀況 「行動不便」 相斥的申請人居住條件：').subscribe((text:string) => {
      if(this.healths[this.index]!=null)
      out=text.replace("{{ value }}",this.healths[this.index].name);
  });
    return out;
  }
  get selectedHealthConflicts(): Living[] {
    if (this.healths[this.index] == null)
      return [];

    var conflicts = this.conflicts
      .filter(cc => cc.healthConditionUUId == this.healths[this.index].uuId)
      .map(cc => cc.livingConditionUUId);
    return this.livings.filter(living => conflicts.includes(living.uuId));
  }
}
