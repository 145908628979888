import { Component, Input, OnInit } from '@angular/core';
import { Center } from 'src/app/models/centers/center';
import { CenterService } from 'src/app/services/center/center.service';

@Component({
  selector: 'app-center-popup',
  templateUrl: './center-popup.component.html',
  styleUrls: ['./center-popup.component.scss']
})
export class CenterPopupComponent implements OnInit {

  centers: Center[] = [];
  display: boolean = false;
  @Input() action: (centers: Center[]) => void = () => { }

  constructor(
    private centerService: CenterService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.centerService.getRedeemCenters()
      .subscribe({
        next: value => {
          this.centers = value;
        }
      });
  }

  open(centers: Center[]): void {
    this.display = true
    this.centers.forEach(center => {
      center.selected = false;
      if (centers.some(c => c.uuId == center.uuId))
        center.selected = true;
    });
  }

  close(): void {
    this.action(this.centers.filter(center => center.selected == true));
    this.display = false;
  }

  confirm(): void {
    this.action(this.centers.filter(center => center.selected));
    this.close();
  }

  reset(): void {
    this.centers.forEach(center => center.selected = false);
  }
}
