<div class="filter" [ngClass]="{active: display, selected: numberOfSelectedServices > 0}" (click)="toggle()">
    <i class="filter-icon material-icons-outlined" *ngIf="numberOfSelectedServices == 0">favorite_border</i>
    <p class="filter-number" *ngIf="numberOfSelectedServices > 0">{{ numberOfSelectedServices }}</p>
    <p>{{ title | translate }}</p>
    <div class="filter-body service" [@dropdown]="display ? 'on' : 'off'" (click)="$event.stopPropagation()">
        <div class="filter-inner-body">
            <div class="inline-flex-row align-items-center mb-6">
                <p class="text-bold flex-1" style="color: #5A5A5A" translate>服務</p>
                <p class="click-text-blue" (click)="reset()" translate>清除</p>
            </div>

            <div class="filter-box">
                <ng-container *ngFor="let serviceType of serviceTypes">
                    <ng-container *ngIf="serviceType.services.length > 0">
                        <label class="checkbox mb-4">
                            <input class="mr-8" type="checkbox" [ngModel]="serviceType.isSelectedAll" (click)="serviceType.selectAll()">
                            <p>{{ serviceType.name }}</p>
                        </label>
                        <label class="ml-8 checkbox mb-4" *ngFor="let service of serviceType.services">
                            <input class="ml-8 mr-8" type="checkbox" [(ngModel)]="service.selected">
                            <p class="text-sm">{{ service.name }}</p>
                        </label>
                    </ng-container>
                </ng-container>

            </div>
        </div>

    </div>
</div>

<div *ngIf="display" class="popup-bg" (click)="toggle()"></div>