export interface IRedeemQuestion {
    uuId: string;
    question: string;
    mode: string;
    options: IRedeemOption[];
}

export interface IRedeemOption {
    uuId: string;
    option: string;
}

export class RedeemQuestion {
    public uuId: string;
    public question: string;
    public mode: string;
    public options: RedeemOption[]

    constructor(question: IRedeemQuestion) {
        this.uuId = question.uuId;
        this.question = question.question;
        this.mode = question.mode;
        this.options = question.options.map(option => new RedeemOption(option));
    }

    public isOptionDisabled(option: RedeemOption): boolean {
        return this.options.some(o => o.selected && o.uuId != option.uuId);
    }

    public toJsonFormat(): { question: string, answer: string[] } | { question: string, answer: string } {
        if (this.mode == "Single")
            return { question: this.question, answer: this.options.filter(o => o.selected).map(option => option.option).join("") }
        else
            return { question: this.question, answer: this.options.filter(o => o.selected).map(option => option.option) }
    }
}

export class RedeemOption {
    public uuId: string;
    public option: string;
    public selected: boolean

    constructor(option: IRedeemOption) {
        this.uuId = option.uuId;
        this.option = option.option;
        this.selected = false;
    }
}