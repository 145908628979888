<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="health-box zoomIn" (click)="$event.stopPropagation()">
        <div class="inline-flex-row">
            <p class="" translate>身體情況</p>
            <i class="material-icons info-icon ml-8" style="font-size: 1rem;">help_outline</i>
            <p class="flex-1 text-xs text-lightgrey ml-3" translate>身體狀況項目可在後台設置——資料設置中編輯以及設置是否在申請義工服務時對相關義工可見</p>
            <p class="click-text-blue" (click)="reset()" translate>重置</p>
        </div>

        <div class="health-item-box">
            <label class="health-item" *ngFor="let health of healths">
                <input class="mr-4" type="checkbox" [(ngModel)]="health.selected">
                <p>{{ health.name }}</p>
                <i class="material-icons-round ml-2" style="font-size: 1rem;color: rgba(0, 0, 0, 0.3);" *ngIf="health.isShowInRequest">visibility</i>
                <i class="material-icons-outlined ml-2" style="font-size: 1rem;color: rgba(0, 0, 0, 0.3);" *ngIf="health.isShowInRequest == false">visibility_off</i>
            </label>
        </div>

        <div class="flex-1"></div>

        <div class="health-box-footer">
            <button class="button outline" (click)="close()" translate>返 回</button>
            <button class="button fill blue" style="margin-left: 7.5rem;" (click)="confirm()" translate>確 認</button>
        </div>
    </div>
</div>