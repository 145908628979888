import { Component, Input, OnInit } from '@angular/core';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-sign-up-status-filter',
  templateUrl: './sign-up-status-filter.component.html',
  styleUrls: ['./sign-up-status-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class SignUpStatusFilterComponent implements OnInit {


  @Input() action: (statuses: string[]) => void = () => { }

  statuses: { name: string, value: string, selected: boolean }[] = [
    { name: "等待審核", value: "Pending", selected: false },
    { name: "審核中", value: "Approving", selected: false },
    { name: "已拒絕", value: "Disapproved", selected: false },
    { name: "超時審核", value: "TimeoutReview", selected: false }
  ];

  display: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  reset(): void {
    this.statuses.forEach(status => status.selected = false);
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.statuses.filter(status => status.selected).map(status => status.value));
    }
    this.display = !this.display;
  }

  get numberOfSelectedStatuses(): number {
    return this.statuses.filter(status => status.selected).length;
  }

}
