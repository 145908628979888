<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>後台設置</p>
        <p class="ml-4">/</p>
    </div>

    <p class="page-title mt-8" translate>推薦設置</p>

    <div class="container mt-5">
        <div class="container-body">
            <app-health-living-setting></app-health-living-setting>

            <div style="margin-top: 2.8125rem;">
                <app-recommand-setting></app-recommand-setting>
            </div>
        </div>
    </div>
</div>