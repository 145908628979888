import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { RedeemHistory } from "src/app/models/redeems/redeem-history";
import { UserTransaction } from "src/app/models/transactions/user-transaction";
import { ExportService } from "src/app/services/export/export.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";
import { TransactionService } from "src/app/services/transaction/transaction.service";

@Component({
  selector: "app-user-transaction",
  templateUrl: "./user-transaction.component.html",
  styleUrls: ["./user-transaction.component.scss"],
})
export class UserTransactionComponent implements OnInit {
  tab: string = "Request";

  userUUId!: string;
  transactions: UserTransaction[] = [];
  redeems: RedeemHistory[] = [];
  date: FormControl = new FormControl(null);
  current: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private redeemService: RedeemService,
    private loadingService: LoadingService,
    private exportService: ExportService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.route.parent?.params.subscribe((value) => {
      this.userUUId = value.id;
      this.loadingService.startLoading();
      forkJoin({
        transactions: this.transactionService.getUserTransactions(value.id),
        redeems: this.redeemService.getUserRedeemHistory(value.id),
      })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.transactions = value.transactions;
          this.redeems = value.redeems;
        });
    });
  }

  onMonthSelected(value: any, picker: MatDatepicker<Date>): void {
    this.date.setValue(value);
    picker.close();
  }

  close(): void {
    this.date.setValue(null);
  }

  reloadAction: () => void = () => {
    this.fetch();
  };

  exportUserTransactions(): void {
    this.loadingService.startLoading();
    this.profileService
      .getProfileByUUId(this.userUUId)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.exportService.exportTransactions(
          value,
          this.transactions,
          this.redeems,
          {
            startDate: this.date.value,
            endDate: this.date.value,
          },
        );
      });
  }

  get isCurrentMonth(): boolean {
    return this.date.value
      ? (this.date.value as Date).getMonth() == this.current.getMonth()
      : false;
  }

  get filteredTransactions(): UserTransaction[] {
    return this.date.value
      ? this.transactions.filter(
          (transaction) =>
            transaction.date.getMonth() == this.date.value.getMonth(),
        )
      : this.transactions;
  }

  get filteredRedeems(): RedeemHistory[] {
    return this.date.value
      ? this.redeems.filter(
          (redeem) =>
            redeem.redeemDate.getMonth() == this.date.value.getMonth(),
        )
      : this.redeems;
  }

  get requestTransactions(): UserTransaction[] {
    return this.filteredTransactions.filter(
      (transaction) => transaction.type == "Request",
    );
  }

  get eventTransactions(): UserTransaction[] {
    return this.filteredTransactions.filter(
      (transaction) => transaction.type == "Event",
    );
  }

  get donationTransactions(): UserTransaction[] {
    return this.filteredTransactions.filter(
      (transaction) => transaction.type == "Donation",
    );
  }

  get income(): number {
    return this.filteredTransactions
      .filter((t) => t.transferee.uuId == this.userUUId)
      .map((t) => t.amount)
      .reduce((sum, t) => sum + t, 0);
  }

  get expend(): number {
    return this.filteredTransactions
      .filter((t) => t.transferer.uuId == this.userUUId)
      .map((t) => t.amount)
      .reduce((sum, t) => sum + t, 0);
  }
}
