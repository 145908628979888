<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>請求/訂單</p>
        <p class="ml-4">/</p>
        <p class="ml-4" translate>創建請求</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>會員請求</p>
    </div>

    <p class="page-title mt-8" translate>新增歷史紀錄-義工服務</p>

    <div class="container mt-5">
        <div class="container-body">
            <div class="inline-flex-column">
            <p class='remark' translate>適用於新增尚未經系統創建的已完結義工服務紀錄</p>
            <p class="text-sm text-lightgrey mb1" translate>欄目帶 * 號為必填項</p>
            <div class="section-1">
                <ng-container *ngIf="requester == null">
                    <div class="input-row mt-0">
                        <button class="button outline blue sm" [routerLink]="['/main/activity/create/request/select-requester',{ back: 'request-record' }]" translate>選擇會員</button>
                    </div>
                </ng-container>
            
                <ng-container *ngIf="requester">
            
                    <div class="input-row">
                        <p class="title required" matTooltip="{{ '會員姓名' | translate }}" [matTooltipPosition]="'above'" translate>會員姓名</p>
                        <input class="user-input md" type="text" [ngModel]="requester.chineseName" disabled>
                        <input class="user-input md" style="margin-left: 1.25rem;" type="text" [ngModel]="requester.englishName" disabled>
                    </div>
            
                    <div class="input-row">
                        <p class="title required" translate>會員編號</p>
                        <input class="user-input md" type="text" [ngModel]="requester.memberId" disabled>
                        <button class="button outline sm blue" style="margin-left: 1.25rem;" [routerLink]="['/main/activity/create/request/select-requester',{ back: 'request-record' }]" translate>重新指定</button>
                    </div>
            
                    <div class="input-row">
                        <p class="title" matTooltip="{{ '身體狀況' | translate }}" [matTooltipPosition]="'above'" translate>身體狀況</p>
                        <p *ngIf="requester.healths.length == 0" translate>無</p>
            
                        <div class="status-box" *ngIf="requester.healths.length > 0">
                            <div class="inline-flex-row" style="height: 2rem;" *ngFor="let health of requester.healths">
                                <div class="health-status align-self-center mr-4">
                                    <p>{{ health.name }}</p>
                                    <i class="material-icons-round ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest">visibility</i>
                                    <i class="material-icons-outlined ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest == false">visibility_off</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            
           <form class="section-2" [formGroup]="form.formGroup">
               <div class="input-row mt-0">
                    <p class="title required" translate>服務類型</p>
                    <div class="user-select">
                        <select class="user-select xl" formControlName="serviceType">
                            <option [ngValue]="null" disabled translate>可提供服務類型</option>
                            <option *ngFor="let type of serviceTypes" [ngValue]="type.uuId">{{ type.name }}</option>
                        </select>
                    </div>
                    <div class="user-select ml-4">
                        <select class="user-select sm" formControlName="service">
                            <option [ngValue]="null" disabled translate>服務</option>
                            <option *ngFor="let service of selectedServiceTypeServices" [ngValue]="service.uuId">{{ service.name }}</option>
                        </select>
                    </div>
            
                    <div class="user-input-error zoomIn" *ngIf="service.touched && service.errors">
                        <p *ngIf="service.errors.required" translate>請選擇服務</p>
                    </div>
                </div>
            
                <div class="input-row">
                    <p class="title required" translate>服務日期</p>
                    <input matInput class="user-input md" [matDatepicker]="picker" formControlName="date" (click)="picker.open()" placeholder="{{ '服務日期' | translate }}" readonly>
                    <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
            
                    <div class="user-input-error zoomIn" *ngIf="date.touched && date.errors">
                        <p *ngIf="date.errors.required" translate>請輸入服務日期</p>
                    </div>
                </div>
            
                <div class="input-row">
                    <p class="title required" matTooltip="{{ '開始時間' | translate }}" [matTooltipPosition]="'above'" translate>開始時間</p>
                    <app-time-picker [form]="start"></app-time-picker>
            
                    <div class="user-input-error zoomIn" *ngIf="start.touched && start.errors">
                        <p *ngIf="start.errors.required" translate>請輸入預計開始日期</p>
                        <p *ngIf="start.errors.time" translate>請輸入預計開始時間</p>
                    </div>
                </div>
            
                <div class="input-row">
                    <p class="title required" matTooltip="{{ '預計結束' | translate }}" [matTooltipPosition]="'above'" translate>結束時間</p>
                    <app-time-picker [form]="end"></app-time-picker>
                    <div class="user-input-error zoomIn" *ngIf="end.touched && end.errors && end.enabled">
                        <p *ngIf="end.errors.required" translate>請輸入預計結束日期</p>
                        <p *ngIf="end.errors.time" translate>請輸入預計結束時間</p>
                        <p *ngIf="end.errors.invalid" translate>預計結束時間必須在預計開始時間後</p>
                    </div>
                </div>
            
                <ng-container *ngIf="requester">
                    <div class="input-row">
                        <p class="title required align-self-start" matTooltip="{{ '會面地址' | translate }}" [matTooltipPosition]="'above'" style="line-height: 1.5rem;" translate>會面地址</p>
                        <div class="inline-flex-column">
                            <div class="inline-flex-row align-items-center" *ngFor="let address of requester.addresses">
                                <label>
                                    <input class="mr-4" type="radio" formControlName="venue" [value]="address.room.uuId">
                                    <p style="line-height: 1.5rem;">{{ address.toString() }}</p>
                                </label>
                            </div>
            
                            <ng-container *ngFor="let form of venueForms">
                                <app-address-form class="mt-4" [form]="form" [index]="2" [dropAction]="dropVenue"></app-address-form>
            
                                <button class="add-address-button" (click)="saveVenue()" translate>保存地址</button>
                            </ng-container>
            
                            <button class="add-address-button" *ngIf="venueForms.length == 0" (click)="addVenue()" translate>增添地址</button>
                        </div>
                    </div>
            
                    <div class="input-row mt-4">
                        <p class="title" matTooltip="{{ '居住條件' | translate }}" [matTooltipPosition]="'above'" translate>居住條件</p>
                        <p *ngIf="selectedVenueLivingConditions.length == 0" translate>不適用</p>
                        <div class="status orange mr-4" *ngFor="let living of selectedVenueLivingConditions">
                            <p>{{ living.name }}</p>
                        </div>
                    </div>
                </ng-container>
            
                <ng-container *ngIf="requester && hasDestination">
                    <div class="input-row">
                        <p class="title required align-self-start" style="line-height: 1.5rem;" matTooltip="{{ '目的地' | translate }}" [matTooltipPosition]="'above'" translate>目的地</p>
                        <div class="inline-flex-column">
                            <div class="inline-flex-row align-items-center" *ngFor="let address of requester.addresses">
                                <label>
                                    <input class="mr-4" type="radio" formControlName="destination" [value]="address.room.uuId">
                                    <p style="line-height: 1.5rem;">{{ address.toString() }}</p>
                                </label>
                            </div>
            
                            <ng-container *ngFor="let form of destinationForms">
                                <app-address-form class="mt-4" [form]="form" [index]="2" [dropAction]="dropDestination"></app-address-form>
            
                                <button class="add-address-button" (click)="saveDestination()" translate>保存地址</button>
                            </ng-container>
            
                            <button class="add-address-button" *ngIf="destinationForms.length == 0" (click)="addDestination()" translate>增添地址</button>
                        </div>
                    </div>
            
                    <div class="input-row mt-4">
                        <p class="title" matTooltip="{{ '居住條件' | translate }}" [matTooltipPosition]="'above'" translate>居住條件</p>
                        <p *ngIf="selectedDestinationLivingCondition.length == 0" translate>不適用</p>
                        <div class="status orange mr-4" *ngFor="let living of selectedDestinationLivingCondition">
                            <p>{{ living.name }}</p>
                        </div>
                    </div>
                </ng-container>
            
                <div class="input-row">
                    <p class="title " matTooltip="{{ '義工數量' | translate }}" [matTooltipPosition]="'above'" translate>義工數量</p>
                    <input class="user-input md" type="number" [value]="form.volunteers.length" disabled>
                </div>
            
                <div class="input-row">
                    <p class="title required" matTooltip="{{ '交通工具' | translate }}" [matTooltipPosition]="'above'" translate>交通工具</p>
                    <div class="user-select">
                        <select class="user-select xl" formControlName="transportation">
                            <option [ngValue]="null" translate>自行選擇</option>
                            <option [ngValue]="transportation.uuId" *ngFor="let transportation of transportations">{{ transportation.name }}</option>
                        </select>
                    </div>
                </div>
            
                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>備註</p>
                    <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
                </div>


                <div class="input-row" *ngFor="let column of form.columns">
                    <p class="title">{{ column.columnName }}</p>
                    <input type="text" class="user-input" [formControl]="column.input" placeholder="{{ column.columnDescription }}">
                </div>
            </form> 
            
            <div class="section-3">

                <div class="input-row">
                    <p class="title required align-self-start lh-8" matTooltip="{{ '參與義工' | translate }}" [matTooltipPosition]="'above'" translate>參與義工</p>
                    <p  *ngIf="form.volunteers.length == 0" class="text-lightgrey lh-8" translate>未指定</p>
                    <button *ngIf="form.valid" type="button" class="button outline blue sm"  [ngClass]="{'ml-8':form.volunteers.length == 0}" [routerLink]="['/main/activity/create/request/select-volunteers',{ back: 'request-record' }]" translate>選擇會員</button>
                </div>
                <div class="inline-flex-column mt-4" *ngIf="form.volunteers.length > 0">
                    <div class="inline-flex-row align-items-center">
                        <input class="mr-4" type="checkbox" [formControl]="form.giveAll">
                        <p translate>每個帳戶將會獲得</p>
                        <input class="user-input ml-4 mr-4" type="number" style="width: 4.3125rem;" [formControl]="form.giveInput">
                        <p translate>時分</p>
                    </div>
    
                    <div class="inline-flex-row mt-4">
                        <p translate>已選擇以下2</p>
                        <p class="ml-2 mr-2 text-lightblue">{{ form.volunteers.length }}</p>
                        <p translate>位會員，請填妥交易時分.</p>
                    </div>
    
                    <div class="agent-table">
                        <div class="agent-table-row header">
                            <div class="agent-table-cell">
                                <p translate>會員編號</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中文姓名</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中心代碼</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>電話</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>交易時分</p>
                            </div>
                            <div class="agent-table-cell"></div>
                        </div>
                        <div class="agent-table-row" *ngFor="let user of form.volunteers;let index = index">
                            <div class="agent-table-cell">
                                <p>{{ user.memberId }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ user.chineseName }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ user.center.centerCode }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ user.phone }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <input class="user-input" style="width: 4rem;" type="number" [formControl]="user.recordForm">
                            </div>
                            <div class="agent-table-cell">
                                <p class="text-xs click-text-lightblue" (click)="dropUser(index)" translate>移除</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="inline-flex-row mt-4">
                        <p translate>總共支出</p>
                        <p class="ml-2 mr-2 text-lightblue">{{ form.sum }}</p>
                        <p translate>時分至所選會員帳戶</p>
                    </div>
                </div>
            </div>
           
          
            <div class="inline-flex-row align-items-center align-self-center" style="margin-top: 4.6875rem;">
                <button class="button outline" (click)="reset()" translate>取消創建</button>
                <button class="button fill blue" style="margin-left: 9.375rem;" (click)="submit()" translate [disabled]="!canSubmit()">提 交</button>
            </div>
            </div>
        </div>
    </div>
</div>
