import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { AuthenticationGuard } from "src/app/guards/authentication.guard";
import { TopBarComponent } from "./components/top-bar/top-bar.component";
import { SideNavComponent } from "./components/side-nav/side-nav.component";
import { TranslateModule } from "@ngx-translate/core";
import { ShareModule } from "../share/share.module";
import { InitializePopupComponent } from "./components/initialize-popup/initialize-popup.component";
import { SuperAdminGuard } from "src/app/guards/super-admin.guard";
import { MatSnackBarModule } from "@angular/material/snack-bar";

const routes: Routes = [
  { path: "login", component: LoginPageComponent },
  {
    path: "main",
    component: MainPageComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: "system",
        loadChildren: () =>
          import("../system/system.module").then(
            (module) => module.SystemModule,
          ),
        canActivate: [SuperAdminGuard],
      },
      {
        path: "user",
        loadChildren: () =>
          import("../user/user.module").then((module) => module.UserModule),
      },
      {
        path: "announcement",
        loadChildren: () =>
          import("../announcement/announcement.module").then(
            (module) => module.AnnouncementModule,
          ),
      },
      {
        path: "staff",
        loadChildren: () =>
          import("../staff/staff.module").then((module) => module.StaffModule),
      },
      {
        path: "donation",
        loadChildren: () =>
          import("../donation/donation.module").then(
            (module) => module.DonationModule,
          ),
      },
      {
        path: "redeem",
        loadChildren: () =>
          import("../redeem/redeem.module").then(
            (module) => module.RedeemModule,
          ),
      },
      {
        path: "activity",
        loadChildren: () =>
          import("../activity/activity.module").then(
            (module) => module.ActivityModule,
          ),
      },
      {
        path: "trash",
        loadChildren: () =>
          import("../trash/trash.module").then((module) => module.TrashModule),
        canActivate: [SuperAdminGuard],
      },
      {
        path: "analytics",
        loadChildren: () =>
          import("../analytics/analytics.module").then(
            (module) => module.AnalyticsModule,
          ),
      },
      {
        path: "overview",
        loadChildren: () =>
          import("../overview/overview.module").then(
            (module) => module.OverviewModule,
          ),
      },
      { path: "", redirectTo: "overview", pathMatch: "full" },
      { path: "**", redirectTo: "overview" },
    ],
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  declarations: [
    LoginPageComponent,
    MainPageComponent,
    TopBarComponent,
    SideNavComponent,
    InitializePopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ShareModule,
    MatSnackBarModule,
  ],
})
export class MainModule {}
