import { Observable } from "rxjs";

export interface PopupMessage {
    type: string;
    message: string;
    description: string
    observable?: Observable<any>
}

export class PopupMessages {
    public static ForcedSignOutSuccessMessage: PopupMessage = {
        type: "success",
        message: "已強制登出會員",
        description: ""
    }

    public static ForcedSignOutFailMessage: PopupMessage = {
        type: "error",
        message: "會員已登出",
        description: ""
    }

    public static InvalidInformationMessage: PopupMessage = {
        type: "error",
        message: "請輸入正確的數值",
        description: ""
    }

    public static CreateUserSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功創建會員",
            description: "",
            observable: observable
        };
    }

    public static CreateAnnouncementSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "提交通告成功！會員將會收到系統通知",
            description: "",
            observable: observable
        }
    }

    public static EmptyParticipantErrorMessage: PopupMessage = {
        type: "error",
        message: "請剔參加者",
        description: ""
    }

    public static AddAnnouncementRecipientsSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功新增通告對像",
            description: "",
            observable: observable
        }
    }

    public static AnnouncementIsPublishedErrorMessage: PopupMessage = {
        type: "error",
        message: "通告已發佈",
        description: ""
    }

    public static EditAnnouncementSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認成功！會員及相關職員將會收到系統通知",
        description: ""
    }

    public static DeactivateAnnouncementSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功終止中心通告",
        description: ""
    }

    public static DeleteAnnouncementMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除該通告嗎？",
            description: "該通告將會在通告管理列表中移除。",
            observable: observable
        };
    }

    public static DeleteAnnouncementListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這些通告嗎？",
            description: "這些通告將會在通告管理列表中移除。",
            observable: observable
        };
    }

    public static DeleteStaffMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除該職員嗎？",
            description: "該職員將會在職員管理列表中移除。",
            observable: observable
        };
    }

    public static DeleteStaffListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這些職員嗎？",
            description: "這些職員將會在職員管理列表中移除。",
            observable: observable
        };
    }

    public static CreateStaffSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功創建職員",
            description: "",
            observable: observable
        };
    }

    public static DonateToCenterSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功向中心捐分",
        description: ""
    }

    public static NoRecordSelectedMessage: PopupMessage = {
        type: "error",
        message: "請先剔選紀錄",
        description: ""
    }

    public static DonateToUsersSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功向會員捐分",
        description: ""
    }

    public static DeleteDonationSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功刪除捐分紀錄",
        description: ""
    }

    public static DeleteDonationMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "是否確認移除這交易紀錄?",
            description: "移除該紀錄後，已經產生的時分交易亦會取消。",
            observable: observable
        }
    }

    public static DeleteUserMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除該會員嗎？",
            description: "該會員將會在會員管理列表中移除。",
            observable: observable
        };
    }

    public static UserDeletedMessage(observable: Observable<any>): PopupMessage {
        return {
            type: 'success',
            message: "會員已移除",
            description: "",
            observable: observable
        }
    }


    public static UserFreezedMessage(observable: Observable<any>): PopupMessage {
        return {
            type: 'success',
            message: "會員已凍結",
            description: "",
            observable: observable
        }
    }

    public static FreezeUserMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要凍結這些會員嗎？",
            description: "這些會員將會停止使用。",
            observable: observable
        };
    }

    public static UserUNFreezedMessage(observable: Observable<any>): PopupMessage {
        return {
            type: 'success',
            message: "會員已重新啟動",
            description: "",
            observable: observable
        }
    }

    public static UNFreezedUserMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認重新啟動這些會員嗎？",
            description: "這些會員將會新啟動。",
            observable: observable
        };
    }

    public static DeleteUserListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這些會員嗎？",
            description: "這些會員將會在會員管理列表中移除。",
            observable: observable
        };
    }

    public static AddMembersToCellGroupSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功將會員加入細胞小組",
            description: "",
            observable: observable
        }
    }

    public static DeleteProductMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除 1 條紀錄嗎？",
            description: "移除後將會產生的和已產生的所有時分交易都會取消，在 15 天內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        }
    }

    public static DeleteProductListMessage(observable: Observable<any>, count: number): PopupMessage {
        return {
            type: "help",
            message: "確認要移除 " + count + " 條紀錄嗎？",
            description: "移除後將會產生的和已產生的所有時分交易都會取消，在 15 天內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        }
    }

    public static SaveRedeemQuestionFailMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "error",
            message: "儲存失敗",
            description: "設置填寫有誤，請根據提示修正。",
            observable: observable
        }
    }

    public static SaveRedeemQuestionSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "儲存成功",
            description: "如發布獎勵項目時設置須回答問題，系統則會根據此問題設置提供問題及選項。",
            observable: observable
        }
    }

    public static DisableProductMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要下架該獎勵項目嗎？",
            description: "下架後該項目將在App的獎勵列表不會顯示，已參與的相關會員可在App“我的”可查看記錄。",
            observable: observable
        }
    }

    public static UpdateRedeemSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認修改成功",
        description: "修改完成後，所選會員的時分交易會有變化更新，系統會向所選會員發出已修改通知"
    }


    public static UpdateRedeemFailMessage: PopupMessage = {
        type: "error",
        message: "修改失敗",
        description: "該會員帳戶餘額不足，修改失敗，請重新檢查。"
    }

    public static CancelRedeemMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要取消該會員的換領嗎？",
            description: "該會員將會移除出此次獎勵的參與會員列表，將會或已經產生的時分交易亦會取消。",
            observable: observable
        }
    }
    public static RedeemLaunchDateFailMessage: PopupMessage = {
        type: "error",
        message: "創建獎勵失敗",
        description: "發布日期不可以早於現在"
    }


    public static ApproveRedeemSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認換領成功",
        description: "所選會員已換領成功並扣除相應時分，系統會發出通知，請在“已兌換”列表中查看"
    }

    public static ApproveRedeemFailMessage: PopupMessage = {
        type: "error",
        message: "確認換領失敗",
        description: "此次獎勵項目設定的庫存已不足，請修改庫存後再嘗試。"
    }


    public static DeliveredRedeemSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認領取成功",
        description: "所選會員已領取，系統會發出通知，請在“已兌換”列表中查看"
    }

    public static DeliveredRedeemFailMessage: PopupMessage = {
        type: "error",
        message: "確認領取失敗",
        description: "此次獎勵項目設定的庫存已不足，請修改庫存後再嘗試。"
    }

    public static EmptyRequesterErrorMessage: PopupMessage = {
        type: "error",
        message: "請剔選請求者",
        description: ""
    }

    public static GrantRedeemSuccessMessage: PopupMessage = {
        type: "success",
        message: "新增會員成功",
        description: "已更新至“等候處理”列表中，尚未扣除時分，請檢查是否須填寫問卷以及換領數量後再提交“確認換領”"
    }

    public static GrantRedeemFailMessage: PopupMessage = {
        type: "error",
        message: "新增會員失敗",
        description: "此次獎勵項目設定的庫存已不足，請修改庫存後再嘗試。"
    }

    public static MissingRedeemReasonMessage: PopupMessage = {
        type: "error",
        message: "請回答獎勵問題",
        description: ""
    }

    public static ExceedRedeemLimitMessage: PopupMessage = {
        type: "error",
        message: "超出最大換領上限",
        description: ""
    }

    public static DeleteRequestMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這條紀錄嗎？",
            description: "紀錄在一定時間內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        };
    }

    public static DeleteRequestListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這些紀錄嗎？",
            description: "紀錄在一定時間內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        };
    }

    public static DeleteEventMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這條活動紀錄嗎？",
            description: "活動紀錄在一定時間內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        };
    }

    public static DeleteEventListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除這些活動紀錄嗎？",
            description: "活動紀錄在一定時間內仍可於垃圾桶尋回並復原，過後將被永久移除。",
            observable: observable
        };
    }

    public static CreateRequestSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "創建請求成功",
            description: "",
            observable: observable
        }
    }

    public static InvalidTimeMessage: PopupMessage = {
        type: "error",
        message: "結束時間必須在開始時間之後",
        description: "END_TIME_MUST_BE_LATER_THAN_START_TIME"
    }

    public static InvitedParticipantsMustGreaterThanVacancy: PopupMessage = {
        type: "error",
        message: "邀請人數必須大於活動人數",
        description: ""
    }

    public static DeadlineMustBeEarlierThanStartTime: PopupMessage = {
        type: "error",
        message: "截止日期必須在活動開始前",
        description: ""
    }

    public static EmptyEventDay: PopupMessage = {
        type: "error",
        message: "必須填寫活動日期",
        description: ""
    }

    public static CreateEventSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "創建中心活動成功",
            description: "",
            observable: observable
        }
    }

    public static EditTransactionSuccessMessage: PopupMessage = {
        type: "success",
        message: "已成功更改交易紀錄",
        description: ""
    }

    public static InviteVolunteerSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "邀請義工成功",
            description: "",
            observable: observable
        }
    }

    public static EditRequestSuccessMessage: PopupMessage = {
        type: "success",
        message: "更改請求成功",
        description: ""
    }

    public static InvalidRatingMessage: PopupMessage = {
        type: "error",
        message: "請填寫所有評分",
        description: ""
    }

    public static SubmitCommentSuccessMessage: PopupMessage = {
        type: "success",
        message: "已成功提交評分",
        description: ""
    }

    public static StartRequestSuccessMessage: PopupMessage = {
        type: "success",
        message: "請求成功開始進行",
        description: ""
    }

    public static CancelRequestSuccessMessage: PopupMessage = {
        type: "success",
        message: "已終止請求",
        description: ""
    }

    public static TerminateEventMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "是否確認終止活動？",
            description: "終止該活動後，系統會發出通知，將會或已經產生的時分交易亦會取消。",
            observable: observable
        }
    }

    public static EndEventMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "是否確認結束活動？",
            description: "確認結束後，系統會發出通知，確認參加但尚未確認完成的會員會自動產生時分交易，請在會員管理中查看。",
            observable: observable
        }
    }


    public static DropEventDayFail: PopupMessage = {
        type: "error",
        message: "中心活動必須有至少一個活動日期",
        description: ""
    }

    public static ZeroEventDayErrorMessage: PopupMessage = {
        type: "error",
        message: "請加入活動日期",
        description: ""
    }

    public static UpdateEventSuccessMessage: PopupMessage = {
        type: "success",
        message: "修改成功！",
        description: " 活動內容修改通知已發送至邀請會員。"
    }

    public static InviteEventParticipantsSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "邀請活動參加者成功",
            description: "",
            observable: observable
        }
    }

    public static RemoveUserFromEventMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要移除該會員嗎?",
            description: "該會員將會移除出此次活動的會員管理列表，將會或已經產生的時分交易亦會取消。",
            observable: observable
        };
    }

    public static ZeroParticipantErrorMessage: PopupMessage = {
        type: "error",
        message: "請剔選會員",
        description: ""
    }

    public static UserAbsentMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認該會員缺席該活動嗎？",
            description: "該會員確認缺席後，將不會產生時分交易。",
            observable: observable
        };
    }

    public static ParticipantFinishEventSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認完成成功",
        description: "確認完成後，所選會員的時分交易會發送至會員帳戶。"
    }

    public static ParticipantNewTransactionEventSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認修改成功",
        description: "確認修改後，所選會員的時分交易會發送至會員帳戶。"
    }
    
    public static EditAttendanceSuccessMessage: PopupMessage = {
        type: "success",
        message: "確認完成成功",
        description: ""
    }

    public static CreateEventRecordSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "創建中心活動紀錄成功",
            description: "",
            observable: observable
        }
    }

    public static RestoreMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "你是否確定復原這個紀錄?",
            description: "",
            observable: observable
        };
    }


    public static RestoreListMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "你是否確定復原這些紀錄?",
            description: "",
            observable: observable
        };
    }

    public static DeletePermanentlyMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "你是否確定永久刪除這個紀錄?",
            description: "",
            observable: observable
        };
    }

    public static DeleteListPermanentlyMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "你是否確定永久刪除這些紀錄?",
            description: "",
            observable: observable
        };
    }

    public static InvalidFileFormatMessage: PopupMessage = {
        type: "error",
        message: "請滙入正確的Excel檔案",
        description: ""
    }

    public static ImportSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功滙入紀錄",
            description: "",
            observable: observable
        };
    }

    public static EditTimeConfigSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功修改系統時間設定",
        description: ""
    }

    public static EditApplicationSettingSuccessMessage: PopupMessage = {
        type: "success",
        message: "成功修改應用程式設定",
        description: ""
    }

    public static DisableServiceMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要停用該服務嗎？",
            description: "停用後機構會員將不可再申請該服務，系統會儲存以往該服務相關紀錄。",
            observable: observable
        }
    }

    public static EnableServiceMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確認要恢復該服務嗎？",
            description: "確認恢復該服務後，機構會員可申請該服務。",
            observable: observable
        }
    }

    public static RemoveTemplateColumnMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "help",
            message: "確定要移除自訂義框?",
            description: "請注意，自訂義框移除後，該欄以往會員紀錄將會被移除。",
            observable: observable
        }
    }

    public static RemoveTemplateColumnSuccessMessage(observable: Observable<any>): PopupMessage {
        return {
            type: "success",
            message: "成功移除自訂義框",
            description: "",
            observable: observable
        }
    }

    
}

