<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>後台設置</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>會員檔案模板</p>
    </div>

    <p class="page-title mt-8" translate>會員檔案模板</p>

    <div class="container mt-5">
        <div class="container-body">

            <div class="account-box">
                <div class="title-box">
                    <p class="title text-bold" translate>帳號信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員編號</p>
                    <input class="user-input xs" type="text" placeholder="{{ '編號' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入帳號</p>
                    <input class="user-input xs" type="text" placeholder="{{ '登入帳號' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入密碼</p>
                    <input class="user-input xs" type="text" placeholder="{{ '密碼' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title required" translate>所屬中心</p>
                    <div class="user-select">
                        <select class="user-select lg">
                            <option [ngValue]=null selected disabled translate>請選擇中心</option>
                        </select>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>時分餘額</p>
                    <input class="user-input xs" type="number" placeholder="{{ '系統填寫' | translate }}" disabled>
                </div>
            </div>

            <div class="information-box">
                <div class="title-box">
                    <p class="title text-bold" translate>基本信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員姓名</p>
                    <input class="user-input xs" type="text" placeholder="{{ '中文姓名*' | translate }}">
                    <input class="user-input xl ml-6" type="text" placeholder="{{ 'English Name' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title required" translate>性別</p>
                    <label class="align-items-center" style="margin-right: 2.75rem;">
                        <input type="radio" value="M">
                        <p translate>男</p>
                    </label>
                    <label class="align-items-center">
                        <input type="radio" value="F">
                        <p translate>女</p>
                    </label>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>電話號碼</p>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center">
                            <input class="user-input xs" type="text" placeholder="{{ '電話*' | translate }}">
                            <label class="align-items-center">
                                <input class="ml-6 mr-4" type="checkbox">
                                <p translate>智能電話</p>
                            </label>
                        </div>

                        <input class="user-input xl mt-4" type="text" placeholder="{{ '電話型號' | translate }}">
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>出生日期</p>
                    <input class="user-input xs" [matDatepicker]="picker" (click)="picker.open()" placeholder="{{ '出生日期' | translate }}" readonly>
                    <input class="user-input xs ml-3" placeholder="{{ '自動推算年齡' | translate }}" readonly disabled>
                    <mat-datepicker startView="multi-year" disabled="false" touchUi #picker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                </div>

                <div class="input-row">
                    <p class="title" translate>是否有流動數據</p>
                    <label class="align-items-center" style="margin-right: 2.75rem;">
                        <input type="radio" [value]="true">
                        <p translate>有</p>
                    </label>
                    <label class="align-items-center">
                        <input type="radio" [value]="false">
                        <p translate>沒有</p>
                    </label>
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>細胞小組</p>
                    <div class="inline-flex-row align-items-center">
                        <p class="text-lightgrey" translate>未指定</p>
                        <i class="material-icons-round add-icon ml-4 disable-grey">add_circle</i>
                        <i class="material-icons-round info-icon" style="margin-left: 1.25rem;">help_outline</i>
                        <p class="ml-4 text-xs" translate>同一細胞小組的會員在義工推薦時優先顯示</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>代理人</p>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center" style="height: 2rem;">
                            <p class="text-lightgrey" translate>未指定，請在會員列表中確認人選</p>
                            <i class="material-icons-round info-icon ml-8">help_outline</i>
                            <p class="text-xs ml-3 text-lightgrey" translate>代理人帳號可代該會員帳號申請義工服務和中心活動</p>
                        </div>

                        <button type="button" class="button outline blue sm mt-4" translate>會員列表</button>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title" translate>緊急聯絡人</p>
                    <input class="user-input xs" type="text" placeholder="{{ '中文姓名' | translate }}">
                    <input class="user-input xl ml-6" type="text" placeholder="{{ '聯絡電話號碼' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>身體狀況</p>
                    <div class="inline-flex-row align-items-center">
                        <p class="text-lightgrey" translate>未設置</p>
                        <i class="material-icons-round add-icon ml-4 disable-grey">add_circle</i>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title" translate>教育程度</p>
                    <div class="user-select">
                        <select class="user-select xs">
                            <option [ngValue]=null selected disabled>讀寫能力</option>
                            <option value="CanReadAndWrite" translate>能讀能寫</option>
                            <option value="CanRead" translate>只能讀，不能寫</option>
                            <option value="CanSpeak" translate>不懂文字</option>
                        </select>
                    </div>
                    <div class="user-select ml-4">
                        <select class="user-select xs">
                            <option [ngValue]=null selected disabled>教育程度</option>
                            <option value="University" translate>大學或以上</option>
                            <option value="Secondary" translate>中學</option>
                            <option value="Primary" translate>小學</option>
                            <option value="None" translate>不適用</option>
                        </select>
                    </div>
                </div>

                <div class="input-row" *ngFor="let column of columns">
                    <p class="title">{{ column.columnName }}</p>
                    <input class="user-input sm" type="text" [placeholder]="column.columnDescription">
                    
                    <label class="margin-left: 2rem;align-items-center">
                        <input class="ml-6 mr-4" type="checkbox" [(ngModel)]="!column.display" (change)="changeDisplay(column.uuId)">
                        <p translate>會員不可見</p>
                    </label>

                    <i class="material-icons-round ml-4 text-lg" style="color: #F5222D;cursor: pointer;" (click)="removeColumn(column.uuId)">remove_circle</i>
                </div>

                <div class="input-row" style="cursor: pointer;" *ngIf="!showColumnForm;else ColumnForm" (click)="showColumnForm = !showColumnForm">
                    <i class="material-icons-round text-lg text-lightblue">add_circle</i>
                    <p class="text-lightblue ml-4" translate>增加自訂義框</p>
                </div>

                <ng-template #ColumnForm>
                    <div class="input-row">
                        <div class="template-div">
                            <input type="text" class="template-input" [formControl]="columnName" placeholder="{{ '標題名稱' | translate }}">
                        </div>
                        <div class="template-div" style="margin-left: 2rem;">
                            <input type="text" class="template-input lg" [formControl]="columnDescription" placeholder="{{ '描述文字' | translate }}">
                        </div>
                       
                        <label class="align-items-center">
                            <input class="ml-6 mr-4" type="checkbox" [(ngModel)]="hideUser">
                            <p translate>會員不可見</p>
                        </label>
                       

                        <p class="click-text-lightblue text-sm ml-8" (click)="saveColumn()" translate>保存</p>
                        <i class="material-icons-round ml-8 text-lg" style="color: #F5222D;cursor: pointer;" (click)="resetForm()">remove_circle</i>
                        <i class="material-icons-round ml-8 text-sm text-lightblue">error_outline</i>
                        <p class="text-lightgrey text-sm ml-4" translate>注意，保存後不可刪除</p>
                    </div>
                </ng-template>
                <div class="input-row" style="margin-top: 2rem;margin-bottom: 2rem;">
                    <p class="title align-self-start" translate >備註</p>
                    <div>
                        <div class="text-sm text-lightgrey" translate style="margin-bottom: 0.5rem;">備註僅職員可見</div>
                        <textarea style="width: 22rem; height: 8.625rem;"  class="user-input" type="text" formControlName="remark" placeholder="{{ '備註' | translate }}"></textarea>
                   
                    </div>
                     <!-- <p *ngIf="readonly == true">{{profile.remark}}</p> -->
                </div>
            </div>

            <div class="address-box">
                <div class="title-box">
                    <p class="title text-bold" translate>地址信息</p>
                    <p class="text-sm text-lightgrey" translate>居住條件選項可在後台設置中增加/編輯</p>
                </div>

                <app-address-form class="ml-7" style="margin-top: 2rem;" [form]="addressForm" [index]="1"></app-address-form>

                <div class="add-address-button ml-7">
                    <p class="text-lightgrey" translate>增添地址</p>
                </div>
            </div>


            <div class="volunteer-box">
                <div class="title-box">
                    <p class="title text-bold" translate>義工服務信息</p>
                    <p class="text-sm text-lightgrey" translate>義工會員須填寫，系統會根據以下信息在邀請義工時篩選適合的義工會員，非義工會員可不填</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>空閒時間</p>
                    <ng-container>
                        <app-availability-table #availability [availabilities]="[]"></app-availability-table>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>可提供服務</p>
                    <div class="inline-flex-row align-items-center">
                        <p class="text-lightgrey" translate>未設置</p>
                        <i class="material-icons-round add-icon ml-4 disable-grey">add_circle</i>
                    </div>
                </div>
            </div>

            <div class="inline-flex-row align-items-center justify-content-center" style="margin-bottom: 5.1875rem;">
                <button type="button" class="button outline disable-grey" translate>取消</button>
                <button type="submit" class="button outline disable-grey" style="margin-left: 6.125rem;" translate>確認創建</button>
            </div>
        </div>
    </div>
</div>