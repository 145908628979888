<div class="popup-bg" *ngIf="display">
    <div class="message-box zoomIn">
        <div class="inline-flex-row flex-1 align-items-start" *ngIf="message">
            <i class="material-icons-round success-icon mr-8" *ngIf="message.type == 'success'">check_circle</i>
            <i *ngIf="message.type == 'error'" class="material-icons-round error-icon mr-8">cancel</i>
            <i *ngIf="message.type == 'help'" class="material-icons-round help-icon mr-8">help</i>
            <div class="inline-flex-column">
                <p style="line-height: 1.375rem;font-size: 1rem;white-space: pre-line;">{{ message.message | translate }}</p>
                <p class="text-grey mt-2" style="line-height: 1.375rem; white-space: pre-line;">{{ message.description | translate }}</p>
            </div>
        </div>

        <div class="inline-flex-row justify-content-end">
            <button class="button outline xs" *ngIf="message?.type == 'help'" (click)="display = false" translate>取 消</button>
            <button class="button fill blue xs ml-4" (click)="confirm()" translate>確 定</button>
        </div>
    </div>
</div>