import { Component, Input, OnInit } from '@angular/core';
import { Service } from 'src/app/models/services/service';
import { ServiceType } from 'src/app/models/services/service-type';
import { PortalAnimation } from 'src/app/portal-animation';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-service-filter',
  templateUrl: './service-filter.component.html',
  styleUrls: ['./service-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class ServiceFilterComponent implements OnInit {

  @Input() action: (services: Service[]) => void = () => { }
  @Input() title: string = "可提供服務";
  serviceTypes: ServiceType[] = [];
  display: boolean = false;

  constructor(
    private serviceService: ServiceService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.serviceService.getServiceTypeList()
      .subscribe({
        next: value => {
          this.serviceTypes = value;
        }
      });
  }

  reset(): void {
    this.serviceTypes.forEach(serviceType => serviceType.reset())
  }

  toggle(): void {
    if (this.display == true) {
      this.action(this.serviceTypes.map(st => st.services.filter(s => s.selected)).reduce((merged, value) => merged.concat(value)));
    }
    this.display = !this.display;
  }

  get numberOfSelectedServices(): number {
    return this.serviceTypes.map(st => st.services.filter(s => s.selected).length).reduce((sum, length) => sum + length, 0);
  }

}
