<!-- <div class="success-message" *ngIf="showSuccessMessage">
    <div class="inline-flex-row">
        <i class="material-icons-round check-icon">check_circle_outline</i>
        <div class="inline-flex-column flex-1" style="margin-left: 1.25rem;">
            <p style="line-height: 1.5rem;font-size: 1rem;" translate>保存成功！</p>
            <p class="text-lightgrey" translate>您所更新的信息已被保存。最後更新時間和修改者已被標注於頁面最底。</p>
        </div>
        <i class="material-icons-round close-icon align-self-start text-lightgrey" (click)="showSuccessMessage = false">close</i>
    </div>
</div> -->

<form class="inline-flex-column" *ngIf="profile" [formGroup]="profile.form.formGroup">
    <div class="account-box">
        <div class="title-box">
            <p class="title text-bold" translate>帳號信息</p>
            <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
        </div>

        <div class="input-row">
            <p class="title required" translate>創建日期</p>
            <p>{{ profile.createAt | date: 'medium': '+0800': lang}}</p>
        </div>
        
        <div class="input-row">
            <p class="title required" translate>會員編號</p>
            <p>{{ profile.memberId }}</p>
        </div>

        <div class="input-row">
            <p class="title required" translate>登入帳號</p>
            <p *ngIf="readonly == true">{{ profile.userName }}</p>
            <ng-container *ngIf="readonly == false && username">
                <input class="user-input xs" type="text" placeholder="{{ '登入帳號' | translate }}" formControlName="username">
                <div class="user-input-error zoomIn" *ngIf="username.touched && username.errors">
                    <!-- <p *ngIf="username.errors.required" translate>請輸入登入帳號</p> -->
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>登入密碼</p>
            <p *ngIf="readonly == true">********</p>
            <ng-container *ngIf="readonly == false && password">
                <input class="user-input pw" type="text" placeholder="{{ '至少4個字符，必須包含數字' | translate }}" formControlName="password">
                <div class="user-input-error zoomIn" *ngIf="password.touched && password.errors"> 
                    <!-- <p *ngIf="password.errors.required" translate>請輸入帳號密碼</p> -->
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>中心名稱</p>
            <p *ngIf="readonly == true">{{ profile.center.name }}</p>
            <ng-container *ngIf="readonly == false && center">
                <div class="user-select">
                    <select class="user-select lg" formControlName="center">
                        <option [ngValue]=null selected disabled translate>請選擇中心</option>
                        <option *ngFor="let center of centers" [ngValue]="center.uuId">{{ center.name }}</option>
                    </select>
                </div>
                <div class="user-input-error zoomIn" *ngIf="center.touched && center.errors">
                    <p *ngIf="center.errors.required" translate>請選擇所屬中心</p>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>時分餘額</p>
            <p>{{ profile.timeBalance | number }}</p>
        </div>

    </div>
    <div class="information-box">
        <div class="title-box">
            <p class="title text-bold" translate>基本信息</p>
            <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
        </div>

        <div class="input-row">
            <p class="title required" translate>會員姓名</p>
            <ng-container *ngIf="readonly == true">
                <p style="flex: 0 0 7.75rem;">{{ profile.chineseName }}</p>
                <p>{{ profile.englishName }}</p>
            </ng-container>
            <ng-container *ngIf="readonly == false && chineseName && englishName">
                <input class="user-input xs" type="text" placeholder="{{ '中文姓名*' | translate }}" formControlName="chineseName">
                <input class="user-input xl ml-6" type="text" placeholder="{{ 'English Name' | translate }}" formControlName="englishName">
                <div class="user-input-error zoomIn" *ngIf="chineseName.touched && chineseName.errors">
                    <p *ngIf="chineseName.errors.required" translate>請輸入中文姓名</p>
                </div>
                <div class="user-input-error zoomIn" *ngIf="!chineseName.errors && englishName.touched && englishName.errors">
                    <p *ngIf="englishName.errors.required" translate>請輸入英文姓名</p>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>性別</p>
            <p *ngIf="readonly == true">{{ profile.gender | translate }}</p>
            <ng-container *ngIf="readonly == false && gender">
                <label class="align-items-center" style="margin-right: 2.75rem;">
                    <input type="radio" value="M" formControlName="gender">
                    <p translate>男</p>
                </label>
                <label class="align-items-center">
                    <input type="radio" value="F" formControlName="gender">
                    <p translate>女</p>
                </label>
                <div class="user-input-error zoomIn" *ngIf="gender.touched && gender.errors">
                    <p *ngIf="gender.errors.required" translate>請選擇性別</p>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required align-self-start" style="line-height: 2rem;" translate>電話號碼</p>
            <div class="inline-flex-column" *ngIf="readonly == true">
                <p style="line-height: 2rem;">{{ profile.phone }}</p>
                <p style="line-height: 2rem;" class="mt-4">{{ profile.mobilePhoneModel }}</p>
            </div>
            <ng-container *ngIf="readonly == false && phone">
                <div class="inline-flex-column">
                    <div class="inline-flex-row align-items-center">
                        <input class="user-input xs" type="text" maxlength="8" formControlName="phone" placeholder="{{ '電話*' | translate }}">
                        <label class="align-items-center">
                            <input class="ml-6 mr-4" type="checkbox">
                            <p translate>智能電話</p>
                        </label>
                    </div>

                    <input class="user-input xl mt-4" formControlName="phoneModel" type="text" placeholder="{{ '電話型號' | translate }}">
                    <div class="user-input-error zoomIn" *ngIf="phone.touched && phone.errors">
                        <p *ngIf="phone.errors.required" translate>請輸入電話號碼</p>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>出生日期</p>
            <ng-container *ngIf="readonly == true">
                <p style="flex: 0 0 7.75rem;">{{ profile.dateOfBirth | date: 'longDate': '': lang }}</p>
                <p translate>{{ '歲' | translate: {value: profile.age} }}</p>
            </ng-container>
            <ng-container *ngIf="readonly == false && birthday">
                <input class="user-input xs" [matDatepicker]="picker" formControlName="birthday" (click)="picker.open()" placeholder="{{ '出生日期' | translate }}" readonly>
                <input class="user-input xs ml-3" [ngModel]="age" [ngModelOptions]="{standalone: true}" type="number" placeholder="{{ '自動推算年齡' | translate }}" readonly disabled>
                <mat-datepicker startView="multi-year" disabled="false" touchUi #picker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                <div class="user-input-error zoomIn" *ngIf="birthday.touched && birthday.errors">
                    <p *ngIf="birthday.errors.required" translate>請輸入出生日期</p>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title" translate>是否有流動數據</p>
            <ng-container *ngIf="readonly == true">
                <p *ngIf="profile.hasSimCard" translate>有</p>
                <p *ngIf="profile.hasSimCard == false" translate>沒有</p>
            </ng-container>
            <ng-container *ngIf="readonly == false && simCard">
                <label class="align-items-center" style="margin-right: 2.75rem;">
                    <input type="radio" [value]="true" formControlName="simCard">
                    <p translate>有</p>
                </label>
                <label class="align-items-center">
                    <input type="radio" [value]="false" formControlName="simCard">
                    <p translate>沒有</p>
                </label>

                <div class="user-input-error zoomIn" *ngIf="simCard.touched && simCard.errors">
                    <p *ngIf="simCard.errors.required" translate>請選擇網卡配備</p>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title align-self-start" style="line-height: 2rem;" translate>細胞小組</p>
            <ng-container *ngIf="readonly == true">
                <div class="status-box" *ngIf="profile.cellGroups.length > 0">
                    <div class="inline-flex-row" style="height: 2rem;" *ngFor="let cellGroup of profile.cellGroups">
                        <div class="cell-group-status align-self-center mr-4">
                            <p>{{ cellGroup.name }}</p>
                        </div>
                    </div>
                    <i class="material-icons-round info-icon ml-4">help_outline</i>
                    <p class="ml-4 text-xs">同一細胞小組的會員在義工推薦時優先顯示</p>
                </div>

                <p *ngIf="profile.cellGroups.length == 0" translate>未指定</p>
            </ng-container>

            <ng-container *ngIf="readonly == false">
                <div class="inline-flex-row align-items-center" *ngIf="profile.form.cellGroups.length == 0">
                    <p class="text-lightgrey" translate>未指定</p>
                    <i class="material-icons-round add-icon ml-4" (click)="cellGroup_popup.open(profile.form.cellGroups)">add_circle</i>
                    <i class="material-icons-round info-icon" style="margin-left: 1.25rem;">help_outline</i>
                    <p class="ml-4 text-xs" translate>同一細胞小組的會員在義工推薦時優先顯示</p>
                </div>

                <div class="status-box" *ngIf="profile.form.cellGroups.length > 0">
                    <div class="inline-flex-row" style="height: 2rem;" *ngFor="let cellGroup of profile.form.cellGroups">
                        <div class="cell-group-status align-self-center mr-4">
                            <p>{{ cellGroup.name }}</p>
                        </div>
                    </div>
                    <i class="material-icons-round add-icon" (click)="cellGroup_popup.open(profile.form.cellGroups)">add_circle</i>
                    <i class="material-icons-round info-icon" style="margin-left: 1.25rem;">help_outline</i>
                    <p class="ml-4 text-xs" translate>同一細胞小組的會員在義工推薦時優先顯示</p>
                </div>
            </ng-container>
        </div>

        <app-cell-group-popup #cellGroup_popup [action]="cellGroupPopupAction"></app-cell-group-popup>

        <div class="input-row">
            <p class="title align-self-start" style="line-height: 2rem;" translate>代理人</p>
            <ng-container *ngIf="readonly == true">
                <p *ngIf="profile.agents.length == 0" translate>未指定</p>
                <div class="inline-flex-column" *ngIf="profile.agents.length > 0">
                    <div class="inline-flex-row align-items-center mt-4">
                        <p translate>已選擇以下</p>
                        <p class="ml-2 mr-2 text-lightblue">{{ profile.agents.length }}</p>
                        <p translate>位會員</p>
                    </div>

                    <div class="agent-table mt-2">
                        <div class="agent-table-row header">
                            <div class="agent-table-cell">
                                <p translate>會員編號</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中文姓名</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中心代碼</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>電話</p>
                            </div>
                            <div class="agent-table-cell remark">
                                <p class="ml-4'" translate>備註</p>
                            </div>
                        </div>
                        <div class="agent-table-row" *ngFor="let agent of profile.agents; let index = index">
                            <div class="agent-table-cell">
                                <p>{{ agent.memberId}}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ agent.chineseName }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ agent.centerCode}}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p>{{ agent.phone }}</p>
                            </div>
                            <div class="agent-table-cell remark">
                                <p *ngIf="agent.remark == null">-</p>
                                <p>{{ agent.remark.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


            <ng-container *ngIf="readonly == false">
                <div class="inline-flex-column" *ngIf="profile.form.agents.length == 0">
                    <div class="inline-flex-row align-items-center" style="height: 2rem;">
                        <p class="text-lightgrey" translate>未指定，請在會員列表中確認人選</p>
                        <i class="material-icons-round info-icon ml-8">help_outline</i>
                        <p class="text-xs ml-3 text-lightgrey" translate>代理人帳號可代該會員帳號申請義工服務和中心活動</p>
                    </div>

                    <button type="button" class="button outline blue sm mt-4" (click)="selectAgents()" routerLink="agent" translate>會員列表</button>
                </div>

                <div class="inline-flex-column" *ngIf="profile.form.agents.length > 0">
                    <div class="inline-flex-row align-items-center">
                        <button type="button" class="button outline blue sm" (click)="selectAgents()" [routerLink]="['/main/user/overview', profile.uuId, 'agent']">重新選擇</button>
                        <i class="material-icons-round info-icon ml-9">help_outline</i>
                        <p class="text-xs ml-4 text-lightgrey" translate>代理人帳號可代該會員帳號申請義工服務</p>
                    </div>


                    <div class="inline-flex-row align-items-center mt-4">
                        <p translate>已選擇以下</p>
                        <p class="ml-2 mr-2 text-lightblue">{{ profile.form.agents.length }}</p>
                        <p translate>位會員</p>
                    </div>

                    <div class="agent-table mt-2">
                        <div class="agent-table-row header">
                            <div class="agent-table-cell">
                                <p translate>會員編號</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中文姓名</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>中心代碼</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>電話</p>
                            </div>
                            <div class="agent-table-cell remark">
                                <p class="ml-4'" translate>備註</p>
                            </div>
                        </div>
                        <div class="agent-table-row" *ngFor="let agent of profile.form.agents; let index = index">
                            <div class="agent-table-cell">
                                <p translate>{{ agent.memberId}}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>{{ agent.chineseName }}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>{{ agent.centerCode}}</p>
                            </div>
                            <div class="agent-table-cell">
                                <p translate>{{ agent.phone }}</p>
                            </div>
                            <div class="agent-table-cell remark">
                                <input class="user-input remark" type="text" [formControl]="agent.remark" placeholder="{{ '不超過6個字' | translate }}">
                                <p class="click-text-blue text-xs" style="margin-left: 1.75rem;margin-right: 1rem;" (click)="dropAgent(agent, index)">移除</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title" translate>緊急聯絡人</p>
            <ng-container *ngIf="readonly == true">
                <p style="flex: 0 0 7.75rem;">{{ profile.contactPersonName }}</p>
                <p>{{ profile.contactPersonPhoneNumber }}</p>
            </ng-container>

            <ng-container *ngIf="readonly == false && contactPersonName && contactPersonPhone">
                <input class="user-input xs" type="text" formControlName="contactPersonName" placeholder="{{ '中文姓名' | translate }}">
                <input class="user-input xl ml-6" type="text" formControlName="contactPersonPhone" placeholder="{{ '聯絡電話號碼' | translate }}">
            </ng-container>
        </div>

        <div class="input-row">
            <p class="title align-self-start" style="line-height: 2rem;" translate>身體狀況</p>
            <ng-container *ngIf="readonly == true">
                <p *ngIf="profile.healths.length == 0" translate>未設置</p>

                <div class="status-box" *ngIf="profile.healths.length > 0">
                    <div class="inline-flex-row" style="height: 2rem;" *ngFor="let health of profile.healths">
                        <div class="health-status align-self-center mr-4">
                            <p>{{ health.name }}</p>
                            <i class="material-icons-round ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest">visibility</i>
                            <i class="material-icons-outlined ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest == false">visibility_off</i>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="readonly == false">
                <div class="inline-flex-row align-items-center" *ngIf="profile.form.healths.length == 0">
                    <p *ngIf="profile.form.healths.length == 0" translate>未設置</p>
                    <i class="material-icons-round add-icon ml-4" (click)="health_popup.open(profile. form.healths)">add_circle</i>
                </div>

                <div class="status-box" *ngIf="profile.form.healths.length > 0">
                    <div class="inline-flex-row" style="height: 2rem;" *ngFor="let health of profile.form.healths">
                        <div class="health-status align-self-center mr-4">
                            <p>{{ health.name }}</p>
                            <i class="material-icons-round ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest">visibility</i>
                            <i class="material-icons-outlined ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest == false">visibility_off</i>
                        </div>
                    </div>

                    <i class="material-icons-round add-icon" (click)="health_popup.open(profile.form.healths)">add_circle</i>
                </div>
            </ng-container>
        </div>

        <app-health-popup #health_popup [action]="healthPopupAction"></app-health-popup>


        <div class="input-row">
            <p class="title" translate>教育程度</p>
            <ng-container *ngIf="readonly == true">
                <p style="flex: 0 0 7.75rem;" *ngIf="profile.literacy == 'CanReadAndWrite'" translate>能讀能寫</p>
                <p style="flex: 0 0 7.75rem;" *ngIf="profile.literacy == 'CanRead'" translate>只能讀，不能寫</p>
                <p style="flex: 0 0 7.75rem;" *ngIf="profile.literacy == 'CanSpeak'" translate>不懂文字</p>
                <p *ngIf="profile.educationLevel == 'University'" translate>大學或以上</p>
                <p *ngIf="profile.educationLevel == 'Secondary'" translate>中學</p>
                <p *ngIf="profile.educationLevel == 'Primary'" translate>小學</p>
                <p *ngIf="profile.educationLevel == 'None'" translate>不適用</p>
            </ng-container>

            <ng-container *ngIf="readonly == false && literacy && educationLevel">
                <div class="user-select">
                    <select class="user-select xs" formControlName="literacy">
                        <option [ngValue]=null selected disabled>讀寫能力</option>
                        <option value="CanReadAndWrite" translate>能讀能寫</option>
                        <option value="CanRead" translate>只能讀，不能寫</option>
                        <option value="CanSpeak" translate>不懂文字</option>
                    </select>
                </div>
                <div class="user-select ml-4">
                    <select class="user-select xs" formControlName="educationLevel">
                        <option [ngValue]=null selected disabled>教育程度</option>
                        <option value="University" translate>大學或以上</option>
                        <option value="Secondary" translate>中學</option>
                        <option value="Primary" translate>小學</option>
                        <option value="None" translate>不適用</option>
                    </select>
                </div>

                <div class="user-input-error zoomIn" *ngIf="literacy.touched && literacy.errors">
                    <p *ngIf="literacy.errors.required" translate>請選擇讀寫能力</p>
                </div>

                <div class="user-input-error zoomIn" *ngIf="!literacy.errors && educationLevel.touched && educationLevel.errors">
                    <p *ngIf="educationLevel.errors.required" translate>請選擇教育程度</p>
                </div>
            </ng-container>
        </div>        
        <div class="input-row" style="margin-top: 2rem;margin-bottom: 2rem;">
            <p class="title align-self-start" translate>備註</p>
            <div>
                <div class="text-sm text-lightgrey"  *ngIf="readonly == false"  translate style="margin-bottom: 0.5rem;line-height: 2rem;">備註僅職員可見</div>
                <textarea style="width: 22rem; height: 8.625rem;" *ngIf="readonly == false" class="user-input" type="text" formControlName="remark" placeholder="{{ '備註' | translate }}"></textarea>
                <p *ngIf="readonly == true"style="line-height: 2rem;" >{{profile.remark}}</p>
                <p *ngIf="readonly == true" class="text-sm text-lightgrey">(<span translate>備註僅職員可見</span>)</p>
            </div>
           
        </div>

        <ng-container *ngIf="readonly == true">
            <div class="input-row" *ngFor="let column of columns">
                <p class="title">{{ column.columnName }}</p>
                <p *ngIf="profile.extraPersonJson">{{ profile.extraPersonJson[column.columnName] }}</p>
                <p *ngIf="profile.extraPersonJson == null" translate>暫無</p>
            </div>
        </ng-container>

        <ng-container *ngIf="readonly == false">
            <div class="input-row" *ngFor="let column of profile.form.columns">
                <p class="title">{{ column.columnName }}</p>
                <input type="text" class="user-input" [formControl]="column.input">
            </div>
        </ng-container>
    </div>

    <div class="address-box">
        <div class="title-box">
            <p class="title text-bold" translate>地址信息</p>
            <p class="text-sm text-lightgrey" translate>居住條件選項可在後台設置中增加/編輯</p>
        </div>

        <ng-container *ngIf="readonly == true">
            <ng-container *ngFor="let address of profile.addresses; let index = index">
                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 1.25rem;">{{ '地址#' | translate: {value: index+1} }}</p>
                    <div class="inline-flex-column">
                        <p style="line-height: 1.25rem;">{{ address.region.name }}，{{ address.district.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ address.subDistrict.name }}{{ address.street.name }}{{ address.streetNumber.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ address.estate.name }}，{{ address.block.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ address.floor.name }}，{{ address.room.name }}</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;">{{ '居住條件 #' | translate: {value: index+1} }}</p>
                    <div class="status-box">
                        <p *ngIf="address.livings.length == 0" translate>未設置</p>
                        <div class="inline-flex-row" style="height: 2rem;" *ngFor="let living of address.livings; let index = index">
                            <div class="living-status align-self-center mr-4">
                                <p>{{ living.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="readonly == false">
            <ng-container *ngFor="let address of profile.form.addresses;let index = index">
                <app-address-form class="ml-7" style="margin-top: 2rem;" [form]="address.form" [index]="index+1" [dropAction]="dropAddressAction"></app-address-form>
            </ng-container>

            <div class="add-address-button ml-7" (click)="profile.form.addAddress()">
                <p class="text-lightgrey" translate>增添地址</p>
            </div>
        </ng-container>
    </div>

    <div class="volunteer-box">
        <div class="title-box">
            <p class="title text-bold" translate>義工服務信息</p>
            <p class="text-sm text-lightgrey" translate>義工會員須填寫，系統會根據以下信息在邀請義工時篩選適合的義工會員，非義工會員可不填</p>
        </div>

        <div class="input-row">
            <p class="title required align-self-start" style="line-height: 2rem;" translate>空閒時間</p>
            <app-availability-table #availability [availabilities]="profile.availabilities" [readonly]="readonly"></app-availability-table>
        </div>

        <div class="input-row">
            <p class="title required align-self-start" style="line-height: 2rem;" translate>可提供服務</p>
            <div class="inline-flex-row align-items-center" *ngIf="profile.form.services.length == 0">
                <p class="text-lightgrey" translate>未設置</p>
                <i class="material-icons-round add-icon ml-4" *ngIf="readonly == false" (click)="service_popup.open(profile?.form?.services)">add_circle</i>
            </div>

            <ng-container *ngIf="profile.form.services.length > 0">
                <ng-container *ngTemplateOutlet="service_template"></ng-container>
            </ng-container>

        </div>
    </div>
    <div class="inline-flex-row align-items-center justify-content-center" style="margin-bottom: 5.1875rem;">
        <ng-container *ngIf="readonly == true">
            <button type="button" class="button md outline" (click)="deleteUser()" translate>移除</button>
            <button type="submit" class="button md fill blue" style="margin-left: 6.125rem;" (click)="readonly = false" translate>編輯</button>
            <button  *ngIf="profile.isFreeze == false" type="submit" class="button fill red" style="margin-left: 6.125rem;" (click)="forcedSignOut()" translate>強制登出</button> 
            <button  *ngIf="profile.isFreeze == false" type="submit" class="button fill red" style="margin-left: 6.125rem;" (click)="FreezeUser()" translate>凍結會員</button> 
            <button  *ngIf="profile.isFreeze == true" type="submit"  class="button md fill blue" style="margin-left: 6.125rem;" (click)="unFreezeUser()" translate>重啟會員</button> 
        </ng-container>
        <ng-container *ngIf="readonly == false">
            <button type="button" class="button md outline" (click)="readonly = true" translate>取消</button>
            <button type="submit" class="button md fill blue" style="margin-left: 6.125rem;" (click)="submit()" translate>保存</button>
        </ng-container>
    </div>
</form>


<ng-template #service_template>
    <div class="service-box">
        <div class="service-box-header mb-4">
            <p class="text-sm text-lightgrey" style="line-height: 2rem;">該會員可提供以下義工服務，系統根據可供服務和空閒時間進行義工推薦</p>
        </div>
        <div class="service-box-body">
            <div class="service-type-column">
                <div class="service-type-item" [ngClass]="{active: index == i}" (click)="index = i" *ngFor="let serviceType of selectedServiceTypes;let i = index">
                    <p class="flex-1">{{ serviceType.name }}</p>
                    <p class="text-sm text-lightgrey">{{ serviceType.selectedServices.length }}</p>
                </div>
            </div>
            <div class="service-column" *ngIf="selectedServiceTypes[index]">
                <div class="service-item" *ngFor="let service of selectedServiceTypes[index].selectedServices">
                    <p style="flex: 0 0 5.25rem;">{{ service.servicesCode }}</p>
                    <p style="flex: 0 0 7rem;">{{ service.name }}</p>
                </div>
            </div>
        </div>
        <div class="inline-flex-row align-items-center mt-7" *ngIf="readonly == false">
            <p class="mr-3 text-lightgrey">新增服務</p>
            <i class="material-icons-round add-icon" (click)="service_popup.open(profile?.form?.services)">add_circle</i>
        </div>
    </div>
</ng-template>

<app-service-popup #service_popup [action]="servicePopupAction"></app-service-popup>