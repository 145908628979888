import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { SearchVolunteerByRequestModel } from 'src/app/models/requests/search-volunteer-by-request-model';
import { SearchVolunteerModel } from 'src/app/models/requests/search-volunteer-model';
import { IVolunteerChoice, VolunteerChoice } from 'src/app/models/requests/volunteer-choice';

@Injectable({
  providedIn: 'root'
})
export class VolunteerService {

  constructor(
    private httpService: HttpService
  ) { }

  public showInterest(uuId: string, model: { volunteersUUId: string[] }): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>("requests/" + uuId + "/showinterest", model);
  }

  public match(requestUUId: string, model: { volunteersUUId: string[] }): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>("requests/" + requestUUId + "/confirm", model);
  }


  public checkIn(requestUUId: string, model: { volunteersUUId: string[] }): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>("orders/" + requestUUId + "/checkin", model);
  }

  public checkOut(requestUUId: string, model: { volunteersUUId: string[] }): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>("orders/" + requestUUId + "/checkout", model);
  }

  public searchVolunteers(model: SearchVolunteerModel): Observable<VolunteerChoice[]> {
    return this.httpService.post<IVolunteerChoice[], SearchVolunteerModel>("volunteers", model)
      .pipe(map(value => value.result.map(choice => new VolunteerChoice(choice))));
  }

  public searchVolunteersByRequest(requestUUId: string, model: SearchVolunteerByRequestModel): Observable<VolunteerChoice[]> {
    return this.httpService.post<IVolunteerChoice[], SearchVolunteerByRequestModel>("volunteers/" + requestUUId, model)
      .pipe(map(value => value.result.map(choice => new VolunteerChoice(choice))));
  }

  public feedback(requestUUId: string, model: { VolunteerUUId:string,rating: number, comment: string }): Observable<any> {
    return this.httpService.post<any, { VolunteerUUId:string,rating: number, comment: string }>("requests/" + requestUUId + "/requesterRatingbyStaff", model);
  }
}
