import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { forkJoin, Observable, PartialObserver } from "rxjs";
import { defaultIfEmpty, finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Center } from "src/app/models/centers/center";
import { Health } from "src/app/models/healths/health";
import { Service } from "src/app/models/services/service";
import { TemplateColumn } from "src/app/backend/app-column";
import { ServiceType } from "src/app/models/services/service-type";
import { AvailabilityTableComponent } from "src/app/modules/share/components/availability-table/availability-table.component";
import { AgentService } from "src/app/services/agent/agent.service";
import { AuthenticationService } from "src/app/services/authenticate/authentication.service";
import { CenterService } from "src/app/services/center/center.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ServiceService } from "src/app/services/service/service.service";
import { TemplateService } from "src/app/services/template/template.service";
import { CreateUserForm } from "../../forms/create-user-form";
import * as dayjs from "dayjs";

@Component({
  selector: "app-user-create-page",
  templateUrl: "./user-create-page.component.html",
  styleUrls: ["./user-create-page.component.scss"],
})
export class UserCreatePageComponent implements OnInit {
  @ViewChild("availability") availabilityComponent:
    | AvailabilityTableComponent
    | undefined;

  form: CreateUserForm;

  centers: Center[] = [];
  serviceTypes: ServiceType[] = [];

  index: number = 0;

  constructor(
    private authService: AuthenticationService,
    private loadingService: LoadingService,
    private centerService: CenterService,
    private serviceService: ServiceService,
    private agentService: AgentService,
    private router: Router,
    private popupMessageService: PopupMessageService,
    private templateService: TemplateService,
  ) {
    this.form = this.authService.form;
  }

  ngOnInit(): void {
    this.fetchResource().subscribe(this.fetchResourceObserver);
    this.fetch();
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      centers: this.centerService.getCenters(),
      services: this.serviceService.getServiceTypeList(),
      templates: this.templateService.getProfileColumns(),
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: (value) => {
          this.centers = value.centers;
          this.serviceTypes = value.services;
          this.form.columns = value.templates.map((column) => {
            return {
              columnName: column.columnName,
              columnDescription: column.columnDescription,
              input: new FormControl(null),
            };
          });
        },
      });
  }

  cellGroupPopupAction = ((cellGroups: CellGroup[]) => {
    this.form.cellGroups = cellGroups;
  }).bind(this);

  healthPopupAction = ((healths: Health[]) => {
    this.form.healths = healths;
  }).bind(this);

  servicePopupAction = ((services: Service[]) => {
    this.form.services = services;
    this.serviceTypes.forEach((st) =>
      st.services.forEach((s) => {
        s.selected = services.some((service) => s.uuId == service.uuId);
      }),
    );
    this.index = 0;
  }).bind(this);

  dropAddressAction = ((index: number) => {
    this.form.addresses.splice(index, 1);
  }).bind(this);

  dropService(service: Service): void {
    this.form.services.splice(
      this.form.services.findIndex((s) => service.uuId == s.uuId),
      1,
    );
    service.selected = false;
  }

  dropAgent(index: number): void {
    this.form.agents.splice(index, 1);
  }

  reset(): void {
    this.form.reset();
  }

  get selectedServiceTypes(): ServiceType[] {
    return this.serviceTypes.filter((st) =>
      st.services.some((s) => s.selected == true),
    );
  }

  get memberId(): AbstractControl {
    return this.form.formGroup.controls["memberId"];
  }

  get username(): AbstractControl {
    return this.form.formGroup.controls["username"];
  }

  get password(): AbstractControl {
    return this.form.formGroup.controls["password"];
  }

  get center(): AbstractControl {
    return this.form.formGroup.controls["center"];
  }

  get chineseName(): AbstractControl {
    return this.form.formGroup.controls["chineseName"];
  }

  get englishName(): AbstractControl {
    return this.form.formGroup.controls["englishName"];
  }

  get gender(): AbstractControl {
    return this.form.formGroup.controls["gender"];
  }

  get phone(): AbstractControl {
    return this.form.formGroup.controls["phone"];
  }

  get birthday(): AbstractControl {
    return this.form.formGroup.controls["birthday"];
  }

  get age(): number | null {
    return this.birthday.valid
      ? dayjs().diff(this.birthday.value, "years")
      : null;
  }

  get simCard(): AbstractControl {
    return this.form.formGroup.controls["simCard"];
  }

  get literacy(): AbstractControl {
    return this.form.formGroup.controls["literacy"];
  }

  get educationLevel(): AbstractControl {
    return this.form.formGroup.controls["educationLevel"];
  }

  get contactPersonName(): AbstractControl {
    return this.form.formGroup.controls["contactPersonName"];
  }

  get contactPersonPhone(): AbstractControl {
    return this.form.formGroup.controls["contactPersonPhone"];
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.formGroup.markAllAsTouched();
      this.form.addresses.forEach((address) =>
        address.formGroup.markAllAsTouched(),
      );
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    this.form.availabilities = this.availabilityComponent
      ? this.availabilityComponent.getModels()
      : [];
    this.loadingService.startLoading();
    this.authService
      .register(this.form.getCreateUserModel())
      .pipe(
        finalize(() => {
          this.loadingService.stopLoading();
        }),
      )
      .subscribe({
        next: (value: any) => {
          if (this.form.agents.length == 0) {
            this.form.reset();
            this.popupMessageService.messageSignal.emit(
              PopupMessages.CreateUserSuccessMessage(
                new Observable<any>((subscriber) => {
                  this.router.navigate([
                    "/main/user/overivew",
                    value.uuid,
                    "profile",
                  ]);
                }),
              ),
            );
            return;
          }

          forkJoin(
            this.form.agents.map((agent) =>
              this.agentService.assignAgent({
                AssigneeUUId: value.uuId,
                AgentUUId: agent.uuId,
                Remark: agent.remark.value,
              }),
            ),
          )
            .pipe(defaultIfEmpty())
            .subscribe(() => {
              this.form.reset();
              this.popupMessageService.messageSignal.emit(
                PopupMessages.CreateUserSuccessMessage(
                  new Observable<any>((subscriber) => {
                    this.router.navigate([
                      "/main/user/overivew",
                      value.uuid,
                      "profile",
                    ]);
                  }),
                ),
              );
            });
        },
      });
  }

  fetchResource(): Observable<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > {
    this.loadingService.startLoading();
    return this.templateService
      .getProfileColumns()
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }

  fetchResourceObserver: PartialObserver<
    {
      uuId: string;
      columnName: string;
      columnDescription: string;
      display: boolean;
    }[]
  > = {
    next: (value) => {
      this.form.columns = value.map(function (v) {
        return {
          columnName: v.columnName,
          columnDescription: v.columnDescription,
          input: new FormControl(),
        };
      });
    },
  };
}
