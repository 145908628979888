<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="center-popup" (click)="$event.stopPropagation()">
        <div class="inline-flex-row align-items-center mb-9">
            <p class="flex-1" translate>指定中心</p>
            <p class="click-text-blue" (click)="reset()" translate>重置</p>
        </div>

        <div class="popup-body">
            <label class="inline-flex-row align-items-center" *ngFor="let center of centers">
                <input class="mr-4" type="checkbox" [(ngModel)]="center.selected" [disabled]="defaultCenter?.uuId == center.uuId">
                <p style="line-height: 2rem;width: 2.5rem;">{{ center.centerCode }}</p>
                <p style="width: 5rem;">{{ center.phoneNumber }}</p>
                <p>{{ center.name }}</p>
            </label>
        </div>

        <div class="popup-footer">
            <button class="button outline" (click)="close()" translate>返 回</button>
            <button class="button fill blue" style="margin-left: 7.5rem;" (click)="submit()" translate>確 認</button>
        </div>

    </div>
</div>