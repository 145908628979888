import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PortalAnimation } from 'src/app/portal-animation';

@Component({
  selector: 'app-donation-page',
  templateUrl: './donation-page.component.html',
  styleUrls: ['./donation-page.component.scss'],
  animations: [PortalAnimation.DonationPageSlide]
})
export class DonationPageComponent implements OnInit {

  tab: string = "user";

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.router.url == "/main/donation/center")
      this.tab = "center";
    if (this.router.url == "/main/donation/overview")
      this.tab = "overview";
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }
}
