import * as dayjs from "dayjs";
import { CreateEventTransactionModel } from "../events/create-event-transaction-model";

export interface IEventImportModel {
  紀錄編號: string;
  "活動名目*": string;
  "活動類型*": string;
  "交易類型*": string;
  "參加類型*": string;
  "活動日期（YYYY- MM-DD）*": string;
  "開始時間*（HH:mm）": string;
  "結束時間*（HH:mm）": string;
  "預計交易時分*（只作資訊參考）": number;
  "實際交易時分*（實際時分請以該欄目為準）": string;
  "會員編號*": string;
  義工名稱: string;
  備註: string;
}

export class EventImportModel {
  public id: string;
  public row: number;
  public eventTypeUuid: string;
  public eventName: string;
  public startTime: string | null;
  public endTime: string | null;
  public remark: string;
  public eventPayType: string | null;
  public paritcipants: CreateEventTransactionModel;
  public earning: number;
  public eventJoinType: string | null;
  public eventDays: {
    date: string | null;
    startTime: string | null;
    endTime: string | null;
  }[];

  constructor(event: IEventImportModel, index: number) {
    this.id = event["紀錄編號"]?.trim();
    this.row = index;
    this.eventTypeUuid = event["活動類型*"]?.trim();
    this.eventName = event["活動名目*"]?.trim();
    this.startTime = dayjs(
      `${event["活動日期（YYYY- MM-DD）*"]} ${event["開始時間*（HH:mm）"]}`,
    ).toJSON();
    this.endTime = dayjs(
      `${event["活動日期（YYYY- MM-DD）*"]} ${event["結束時間*（HH:mm）"]}`,
    ).toJSON();
    this.remark = event["備註"]?.trim();
    this.eventPayType =
      event["交易類型*"]?.trim() == "用戶加分"
        ? "UserEarn"
        : event["交易類型*"]?.trim() == "用戶扣分"
          ? "UserSpend"
          : event["交易類型*"]?.trim() == "免費"
            ? "Free"
            : null;

    this.paritcipants = {
      list: [],
      isCenterPaid: this.eventPayType == "UserSpend" ? false : true,
    };
    this.earning = event["預計交易時分*（只作資訊參考）"];
    this.eventJoinType =
      event["參加類型*"]?.trim() == "職員處理"
        ? "Staff"
        : event["參加類型*"]?.trim() == "先到先得"
          ? "FIFJ"
          : null;
    this.eventDays = [
      {
        date: dayjs(event["活動日期（YYYY- MM-DD）*"]).toJSON(),
        startTime: this.startTime,
        endTime: this.endTime,
      },
    ];
  }
  public selfValidate(): string | null {
    if (this.id == null) return "第" + (this.row + 7) + "列: 輸入紀錄編號\n";
    if (this.eventName == null)
      return "第" + (this.row + 7) + "列: 編號" + this.id + "未輸入活動名稱\n";
    if (this.eventTypeUuid == null)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入活動類別\n"
      );
    if (this.earning == null)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入時分\n"
      );
    if (this.eventPayType == null)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入支付類型\n"
      );
    if (this.paritcipants.list.length == 0)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入義工名稱\n"
      );
    if (this.startTime == null)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入開始時間\n"
      );
    if (this.endTime == null)
      return (
        "第" +
        (this.row + 7) +
        "列: 編號" +
        this.id +
        "活動" +
        this.eventName +
        "未輸入結束時間\n"
      );
    return null;
  }
}
