import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Health } from 'src/app/models/healths/health';
import { Living } from 'src/app/models/livings/living';
import { PortalAnimation } from 'src/app/portal-animation';
import { HealthService } from 'src/app/services/health/health.service';
import { LivingService } from 'src/app/services/living/living.service';

@Component({
  selector: 'app-condition-filter',
  templateUrl: './condition-filter.component.html',
  styleUrls: ['./condition-filter.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class ConditionFilterComponent implements OnInit {

  @Input() action: (genders: string[], healths: Health[], livings: Living[], birthdayFrom: Date | null, birthdayTo: Date | null) => void = () => { }
  livings: Living[] = [];
  healths: Health[] = [];
  genders = [
    { name: "只顯示男", selected: false, value: 'M' },
    { name: "只顯示女", selected: false, value: 'F' }
  ];

  ageFromGroup = new FormGroup({
    from: new FormControl(null, [Validators.required, Validators.min(0)]),
    to: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  display: boolean = false;

  constructor(
    private healthService: HealthService,
    private livingService: LivingService
  ) { }

  ngOnInit(): void {
    this.ageFromGroup.valueChanges.subscribe(value => {
      if (value.to < value.from) {
        this.ageFromGroup.controls['to'].setErrors({ "invalid-range": true });
      }
    });

    this.fetch();
  }

  fetch(): void {
    forkJoin({
      healths: this.healthService.getHealthConditionList(),
      livings: this.livingService.getLivingConditionList()
    }).subscribe({
      next: value => {
        this.livings = value.livings;
        this.healths = value.healths;
      }
    });
  }

  reset(): void {
    this.genders.forEach(gender => gender.selected = false);
    this.healths.forEach(health => health.selected = false);
    this.livings.forEach(living => living.selected = false);
  }

  toggle(): void {
    if (this.display == true) {
      let birthdayFrom: Date | null = null;
      let birthdayTo: Date | null = null;

      if (this.ageFromGroup.valid) {
        var today = new Date();
        birthdayFrom = new Date();
        birthdayFrom.setFullYear(today.getFullYear() - this.ageFromGroup.controls['to'].value)
        birthdayTo = new Date();
        birthdayTo.setFullYear(today.getFullYear() - this.ageFromGroup.controls['from'].value)
      }

      this.action(
        this.genders.filter(gender => gender.selected).map(gender => gender.value),
        this.healths.filter(health => health.selected),
        this.livings.filter(living => living.selected),
        birthdayFrom,
        birthdayTo
      );
    }
    this.display = !this.display;
  }

  public selectAllGenders(): void {
    if (this.isSelectedAllGender)
      this.genders.forEach(g => g.selected = false);
    else
      this.genders.forEach(g => g.selected = true);
  }

  public selectAllLivingConditions(): void {
    if (this.isSelectedAllLivingConditions)
      this.livings.forEach(living => living.selected = false);
    else
      this.livings.forEach(living => living.selected = true);
  }

  public selectAllHealthConditions(): void {
    if (this.isSelectedAllHealthConditions)
      this.healths.forEach(h => h.selected = false);
    else
      this.healths.forEach(h => h.selected = true);
  }

  get isSelectedAllGender(): boolean {
    return this.genders.every(g => g.selected);
  }

  get isSelectedAllLivingConditions(): boolean {
    return this.livings.every(living => living.selected);
  }

  get isSelectedAllHealthConditions(): boolean {
    return this.healths.every(h => h.selected);
  }

  get numberOfSelectedItems(): number {
    return this.genders.filter(gender => gender.selected).length
      + this.livings.filter(living => living.selected).length
      + this.healths.filter(health => health.selected).length;
  }

}
