import { Component, OnInit } from "@angular/core";
import { Subscriber } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessage, PopupMessages } from "src/app/backend/popup-message";
import { ErrorHandleService } from "src/app/services/error-handle/error-handle.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";

@Component({
  selector: "app-message-popup",
  templateUrl: "./message-popup.component.html",
  styleUrls: ["./message-popup.component.scss"],
})
export class MessagePopupComponent implements OnInit {
  public message: PopupMessage | null =
    PopupMessages.ForcedSignOutSuccessMessage;
  public display: boolean = false;

  constructor(
    private popupMessageService: PopupMessageService,
    private loadingService: LoadingService,
    private errorHandlerService: ErrorHandleService,
  ) {}

  ngOnInit(): void {
    this.errorHandlerService.errorSignal.subscribe((value) => {
      this.message = {
        type: "error",
        message: value.error_message,
        description: "",
      };
      this.display = true;
    });

    this.popupMessageService.messageSignal.subscribe((value) => {
      this.message = value;
      this.display = true;
    });

    // this.errorHandleService.getAppErrorSignal().subscribe((error: AppError) => {
    //   this.message = {
    //     type: "error",
    //     message: error.error_message,
    //     description: null
    //   }
    //   this.display = true;
    // });
  }

  public close() {
    this.popupMessageService.executeSuccessSignal.observers.forEach((ob) =>
      (ob as Subscriber<any>).unsubscribe(),
    );
    this.display = false;
  }

  public confirm() {
    if (this.message?.observable != null) {
      this.loadingService.startLoading();
      this.message.observable
        .pipe(
          finalize(() => {
            this.loadingService.stopLoading();
            this.popupMessageService.executeSuccessSignal.emit();
          }),
        )
        .subscribe();
    }

    this.display = false;
  }
}
