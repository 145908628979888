<div class="box">
    <p class="title" translate>機構中心</p>
    <div class="inline-flex-column">
        <form class="input-row" [formGroup]="form" style="width: 36.875rem;">
            <input type="text" class="user-input xxs" formControlName="code" placeholder="{{ '中心代碼' | translate }}">
            <input type="text" class="user-input xs ml-4" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話號碼' | translate }}">
            <input type="text" class="user-input xl ml-4" formControlName="name" placeholder="{{ '中心名稱' | translate }}">
            <p class="click-text-blue text-xs" style="margin-left: 2.25rem;" (click)="create()" translate>保存</p>
        </form>
        <ng-container *ngFor="let center of centers">
            <ng-container *ngIf="center.editing;then edit;else read"></ng-container>

            <ng-template #read>
                <div class="data-row">
                    <p class="code">{{ center.centerCode }}</p>
                    <p class="ml-4 phone">{{ center.phoneNumber }}</p>
                    <p class="ml-4 name">{{ center.name }}</p>
                    <i class="click-text-lightblue material-icons-outlined create-icon" (click)="detail.update(center)">create</i>
                </div>

            </ng-template>

            <!-- <ng-template #edit>
                <form class="data-row" [formGroup]="center.form">
                    <input type="text" class="user-input xxs" formControlName="code" placeholder="{{ '中心代碼' | translate }}">
                    <input type="text" class="user-input xs ml-4" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話號碼' | translate }}">
                    <input type="text" class="user-input xl ml-4" formControlName="name" placeholder="{{ '中心名稱' | translate }}">
                    <p class="click-text-blue text-xs" style="margin-left: 2.25rem;" (click)="update(center)" translate>保存</p>
                    <p class="click-text-blue text-xs ml-4" (click)="center.editing = false" translate>取消</p>
                </form>
            </ng-template> -->
        </ng-container>
    </div>

    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>
<app-center-detail #detail [beforeClose]="refresh.bind(this)"></app-center-detail>