import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpdateCellGroupModel } from "./update-cell-group-model";

export interface ICellGroup {
  uuId: string;
  name: string;
  leaders: ICellGroupLeader[];
}

export class CellGroup {
  uuId: string;
  name: string;
  leaders: CellGroupLeader[];
  editing: boolean;
  form: FormGroup;

  selected: boolean;

  constructor(cellGroup: ICellGroup) {
    this.uuId = cellGroup.uuId;
    this.name = cellGroup.name;
    this.leaders = cellGroup.leaders?.map(
      (leader) => new CellGroupLeader(leader),
    );
    this.editing = false;
    this.form = new FormGroup({
      name: new FormControl(cellGroup.name, [Validators.required]),
    });
    this.selected = false;
  }

  getUpdateCellGroupModel(): UpdateCellGroupModel {
    return {
      uuid: this.uuId,
      name: this.form.controls["name"].value,
    };
  }
}

export interface ICellGroupLeader {
  uuId: string;
  name: string;
  phoneNumber: string;
}

export class CellGroupLeader {
  uuId: string;
  name: string;
  phoneNumber: string;

  constructor(leader: ICellGroupLeader) {
    this.uuId = leader.uuId;
    this.name = leader.name;
    this.phoneNumber = leader.phoneNumber;
  }
}
