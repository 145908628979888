import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as dayjs from "dayjs";
import { Profile } from "src/app/models/profiles/profile";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { ExportService } from "src/app/services/export/export.service";

@Component({
  selector: "app-inactive-user-analysis",
  templateUrl: "./inactive-user-analysis.component.html",
  styleUrls: ["./inactive-user-analysis.component.scss"],
})
export class InactiveUserAnalysisComponent implements OnInit {
  get placeholder(): string {
    let placeholderStart = dayjs().subtract(6, "months").format("YYYY-MM-DD");
    let placeholderEnd = dayjs().format("YYYY-MM-DD");
    if (this.start.value != null) {
      placeholderStart = dayjs(this.start.value).format("YYYY-MM-DD");
    }
    if (this.end.value != null) {
      placeholderEnd = dayjs(this.end.value).format("YYYY-MM-DD");
    }
    return `${placeholderStart} - ${placeholderEnd}`;
  }
  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  dataType: string = "Request";
  centerUUId?: string;

  users: Profile[] = [];
  filters = {
    page: 1,
    itemsPerPage: 5,
  };

  @Input() form!: FormControl;

  constructor(
    private analyticsService: AnalyticsService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe((value) => {
      this.centerUUId = value;
      this.fetch();
    });
  }

  fetch(): void {
    var start = !this.start.value
      ? undefined
      : dayjs(this.start.value).format("YYYY-MM-DD");
    var end = !this.end.value
      ? undefined
      : dayjs(this.end.value).format("YYYY-MM-DD");
    this.analyticsService
      .getInactiveUsers(this.centerUUId, this.dataType, start, end)
      .subscribe((value) => {
        this.users = value;
      });
  }

  get pages(): number {
    return Math.ceil(this.users.length / this.filters.itemsPerPage);
  }

  get displayedUsers(): Profile[] {
    return this.users;
  }

  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null) return null;
    return `${dayjs(this.start.value).format("YYYY-MM-DD")} - ${dayjs(this.end.value).format("YYYY-MM-DD")}`;
  }

  export() {
    this.exportService.exportInactiveUser(
      this.users,
      this.dataType,
      this.start.value,
      this.end.value,
    );
  }
}
