import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Profile } from 'src/app/models/profiles/profile';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ExportService } from 'src/app/services/export/export.service';

@Component({
  selector: 'app-inactive-user-analysis',
  templateUrl: './inactive-user-analysis.component.html',
  styleUrls: ['./inactive-user-analysis.component.scss']
})
export class InactiveUserAnalysisComponent implements OnInit {

  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  dataType: string = "Request";


  users: Profile[] = [];
  filters = {
    page: 1,
    itemsPerPage: 5,
  };

  @Input() form!: FormControl;

  constructor(
    private analyticsService: AnalyticsService,
    private exportService:ExportService
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.form.valueChanges.subscribe(value => {
      this.fetch(value);
    });
  }

  dateformat(data:Date){
    return data.getFullYear()+"-"+(data.getMonth()+1<10?"0":"")+(data.getMonth()+1)+"-"+(data.getDate()<10?"0":"")+(data.getDate());
  }
  fetch(centerUUId?: string): void {
    var start=!this.start.value?undefined:this.dateformat(this.start.value);
    var end=!this.end.value?undefined:this.dateformat(this.end.value);
    this.analyticsService.getInactiveUsers(centerUUId,this.dataType,start,end)
      .subscribe(value => {
        this.users = value;
      });
  }

  get pages(): number {
    return Math.ceil(this.users.length / this.filters.itemsPerPage);
  }
  get displayedUsers(): Profile[] {
    return this.users;
  }
  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null)
      return null;
    return (<Date>this.start.value).toLocaleDateString() + " - " + (<Date>this.end.value).toLocaleDateString();
  }
  export(){
    this.exportService.exportInactiveUser(this.users,this.dataType,this.start.value,this.end.value);
  }
}
