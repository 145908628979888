import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CellGroup } from 'src/app/models/cell-groups/cell-group';
import { CellGroupMemberProfile } from 'src/app/models/profiles/cell-group-member-profile';
import { CellGroupService } from 'src/app/services/cell-group/cell-group.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-cell-group-members-popup',
  templateUrl: './cell-group-members-popup.component.html',
  styleUrls: ['./cell-group-members-popup.component.scss']
})
export class CellGroupMembersPopupComponent implements OnInit {

  members: CellGroupMemberProfile[] = [];
  filteredMembers: CellGroupMemberProfile[] = [];
  cellGroup: CellGroup | null = null;
  display: boolean = false;

  constructor(
    private cellGroupService: CellGroupService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.cellGroupService.getCellGroupMemberList(this.cellGroup?.uuId)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.members = value;
        this.members.filter(member => member.cellGroups.find(group => group.uuId == this.cellGroup?.uuId)?.leaders.some(leader => leader.uuId == member.uuId)).forEach(member => member.isLeader = true);
        this.filteredMembers = this.members;
      })
  }

  open(cellGroup: CellGroup): void {
    this.display = true;
    this.cellGroup = cellGroup;
    this.fetch();
  }

  close(): void {
    this.cellGroup = null;
    this.members = [];
    this.filteredMembers = [];
    this.display = false;
  }

  searchAction: (value: string) => void = ((value: string) => {
    this.filteredMembers = this.members.filter(member => member.chineseName.includes(value) || member.memberId.toLowerCase().includes(value.toLowerCase()));
  }).bind(this);

  addLeader(user: CellGroupMemberProfile): void {
    this.cellGroupService.addCellGroupLeader(this.cellGroup?.uuId, [user.uuId])
      .subscribe({
        next: () => user.isLeader = true
      });
  }

  removeLeader(user: CellGroupMemberProfile): void {
    this.cellGroupService.removeCellGroupLeader(this.cellGroup?.uuId, [user.uuId])
      .subscribe({
        next: () => user.isLeader = false
      });
  }

  public removeMemberFromCellGroup(userUUId: string): void {
    this.loadingService.startLoading();
    this.cellGroupService.removeMembersFromCellGroup(this.cellGroup?.uuId as string, [userUUId])
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: () => this.fetch()
      });
  }
}
