import { FormControl, Validators } from "@angular/forms";
import { Profile } from "./profile";

export class EventRecordUser extends Profile {
    recordForm: FormControl;

    constructor(profile: Profile) {
        super(profile);

        this.recordForm = new FormControl(null, [Validators.required, Validators.min(0)])
    }
}