import { IProfile, Profile } from "../profiles/profile";

export class CellGroupMemberProfile extends Profile {
  isLeader: boolean;

  constructor(profile: IProfile) {
    super(profile);
    this.isLeader = false;
  }
}
