import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Center } from 'src/app/models/centers/center';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ExportService } from 'src/app/services/export/export.service';
import { EventType } from 'src/app/models/event-types/event-type';
import { ServiceType } from 'src/app/models/services/service-type';
import { ServiceService } from 'src/app/services/service/service.service';
import { forkJoin } from 'rxjs';
import { EventTypeService } from 'src/app/services/event-type/event-type.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading/loading.service';
@Component({
  selector: 'app-cell-group-data-analysis',
  templateUrl: './cell-group-data-analysis.component.html',
  styleUrls: ['./cell-group-data-analysis.component.scss']
})
export class CellGroupDataAnalysisComponent implements OnInit {


  @Input() form!: FormControl;
  @Input() centers!: Center[];

  start: FormControl = new FormControl(null);
  end: FormControl = new FormControl(null);
  ServiceType: FormControl = new FormControl(null);

  ServiceTypes!:{name:string,uuId:string}[];
  EventTypes!:EventType[]
  StateType: string = "All";
  dataType: string = "Request";
  centerUUId?: string;
  Max:number=0;
  TypeList!:{name:string,value:string}[];
  
  Cell_group_list:{ cellGourpName: string, NumOfTime: number|undefined , NumOfPoeper: number|undefined}[]= [{ cellGourpName: "Name", NumOfTime: 10 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 20 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 30 , NumOfPoeper: 11},
  { cellGourpName: "Name", NumOfTime: 40 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 50 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 60 , NumOfPoeper: 11},
  { cellGourpName: "Name", NumOfTime: 70 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 80 , NumOfPoeper: 11},{ cellGourpName: "Name", NumOfTime: 90 , NumOfPoeper: 11}]
  constructor(    
    private analyticsService: AnalyticsService,
    private exportService:ExportService,
    private serviceService:ServiceService,
    private eventTypeService:EventTypeService,
    private loadingService:LoadingService) { }

  ngOnInit(): void {
   
      this.loadingService.startLoading();
      forkJoin({
        eventTypes: this.eventTypeService.getEventTypeList(),
        serviceType: this.serviceService.getServiceTypeList()
      }).pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {
          this.EventTypes = value.eventTypes;
          this.ServiceTypes=[];
          value.serviceType.forEach((st)=>{
            st.services.forEach( s=>this.ServiceTypes.push({name:s.name,uuId:s.uuId}))
          })
        
          console.log(value.serviceType);
          this.fetch();
        });
  }
  ngAfterViewInit(): void {
  
    this.form.valueChanges.subscribe(value => {
      this.centerUUId=value;
      this.fetch();
    });

  }
  dateformat(data:Date){
    return data.getFullYear()+"-"+(data.getMonth()+1<10?"0":"")+(data.getMonth()+1)+"-"+(data.getDate()<10?"0":"")+(data.getDate());
  }
  fetch(): void {
    if(this.dataType == 'Request'){
      this.TypeList= this.ServiceTypes.map(item=> ({name:item.name,value:item.uuId}));
    }else
    this.TypeList= this.EventTypes.map(item=> ({name:item.name,value:item.uuId}));
    var start=!this.start.value?undefined:this.dateformat(this.start.value);
    var end=!this.end.value?undefined:this.dateformat(this.end.value);
    this.analyticsService.getCellGroupData(start,end,this.dataType,this.StateType,this.ServiceType.value,this.centerUUId)
      .subscribe(value => {
       this.Cell_group_list=[];
       this.Max=0;
       this.centers.forEach(element => {
          element.cellGroups.forEach(item=>{
            var a={
              cellGourpName:item.name,
              NumOfTime:value.numOfTime.find(v=>v.index==item.uuId)?value.numOfTime.find(v=>v.index==item.uuId)?.value:0,
              NumOfPoeper:value.numOfUser.find(v=>v.index==item.uuId)?value.numOfUser.find(v=>v.index==item.uuId)?.value:0
            };
            this.Cell_group_list.push(a);
            this.Max=Math.max(typeof a.NumOfTime=="undefined"?0:a.NumOfTime,this.Max)
            })
       });
      });
  }
  export():void{
    var cellName="";
    var str=this.ServiceTypes.find(a=>a.uuId==this.ServiceType.value)?.name
    
    this.exportService.exportCellGroupData(this.Cell_group_list,this.dataType,this.StateType,this.start.value,this.end.value,str);
  }
  get typeList():{name:string,value:string}[]{
    return this.TypeList;
  }
  get dateRange(): string | null {
    if (this.start.value == null || this.end.value == null)
      return null;
    return (<Date>this.start.value).toLocaleDateString() + " - " + (<Date>this.end.value).toLocaleDateString();
  }
  get max():number{
    return this.Max;
  }
  
  get displayList(): { cellGourpName: string, NumOfTime: number |undefined, NumOfPoeper: number|undefined}[]{

    return this.Cell_group_list;//{ cellGourpName: "Name", NumOfTime: 10 , NumOfPoeper: 11}
  }
}
