import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { CreateAnnouncementModel } from "src/app/models/announcements/create-announcement-model";
import { Profile } from "src/app/models/profiles/profile";

export class CreateAnnouncementForm {
  formGroup: FormGroup;
  users: Profile[];

  constructor() {
    this.formGroup = new FormGroup({
      title: new FormControl(null, [
        Validators.required,
        ByteLengthValidator(54),
      ]),
      type: new FormControl(null, [Validators.required]),
      url: new FormControl(null),
      detail: new FormControl(null),
      remark: new FormControl(null),
      announceDate: new FormControl(null, [Validators.required]),
      expireDate: new FormControl(null, [Validators.required]),
    });
    this.users = [];
  }

  public enableURL(): void {
    if (this.formGroup.controls["type"].value == "Text") {
      this.formGroup.controls["url"].setValidators([Validators.nullValidator]);
      this.formGroup.controls["url"].reset();
    } else {
      this.formGroup.controls["url"].setValidators([Validators.required]);
      this.formGroup.controls["url"].reset();
    }
  }

  public reset(): void {
    this.formGroup.reset();
    this.users = [];
  }

  public getCreateAnnouncementModel(): CreateAnnouncementModel {
    return {
      Title: this.formGroup.controls["title"].value,
      Content: this.formGroup.controls["detail"].value,
      Url: this.formGroup.controls["url"].value,
      Type: this.formGroup.controls["type"].value,
      AnnounceDate: (
        this.formGroup.controls["announceDate"].value as Date
      ).toJSON(),
      ExpirationDate: (
        this.formGroup.controls["expireDate"].value as Date
      ).toJSON(),
      Remark: this.formGroup.controls["remark"].value,
      Users: this.users.map((user) => user.uuId),
    };
  }

  get invalidExpireDate(): boolean {
    return (
      (this.formGroup.controls["expireDate"].value as Date) <
      (this.formGroup.controls["announceDate"].value as Date)
    );
  }
}

function ByteLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let encoder: TextEncoder = new TextEncoder();
    let valid = encoder.encode(control.value).length > length;
    return valid ? { byte: { value: control.value } } : null;
  };
}
