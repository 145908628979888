import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingComponent } from "./components/loading/loading.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { LivingPopupComponent } from "./components/living-popup/living-popup.component";
import { CenterFilterComponent } from "./components/center-filter/center-filter.component";
import { AddressFilterComponent } from "./components/address-filter/address-filter.component";
import { ServiceFilterComponent } from "./components/service-filter/service-filter.component";
import { ConditionFilterComponent } from "./components/condition-filter/condition-filter.component";
import { HealthPopupComponent } from "./components/health-popup/health-popup.component";
import { CellGroupPopupComponent } from "./components/cell-group-popup/cell-group-popup.component";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { ServicePopupComponent } from "./components/service-popup/service-popup.component";
import { MessagePopupComponent } from "./components/message-popup/message-popup.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { AnnouncementStatusFilterComponent } from "./components/announcement-status-filter/announcement-status-filter.component";
import { AnnouncementDateRangeFilterComponent } from "./components/announcement-date-range-filter/announcement-date-range-filter.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ServiceDateRangeFilterComponent } from "./components/service-date-range-filter/service-date-range-filter.component";
import { DonationTypeFilterComponent } from "./components/donation-type-filter/donation-type-filter.component";
import { RedeemTypeFilterComponent } from "./components/redeem-type-filter/redeem-type-filter.component";
import { RedeemStatusFilterComponent } from "./components/redeem-status-filter/redeem-status-filter.component";
import { CenterPopupComponent } from "./components/center-popup/center-popup.component";
import { RequestStatusFilterComponent } from "./components/request-status-filter/request-status-filter.component";
import { EventTypeFilterComponent } from "./components/event-type-filter/event-type-filter.component";
import { EventTransactionTypeFilterComponent } from "./components/event-transaction-type-filter/event-transaction-type-filter.component";
import { EventStatusFilterComponent } from "./components/event-status-filter/event-status-filter.component";
import { ImportPopupComponent } from "./components/import-popup/import-popup.component";
import { ImportStatusPopupComponent } from "./components/import-status-popup/import-status-popup.component";
import { EmojiPickerComponent } from "./components/emoji-picker/emoji-picker.component";
import { SignUpStatusFilterComponent } from "./components/sign-up-status-filter/sign-up-status-filter.component";

import { AvailabilityTableComponent } from "./components/availability-table/availability-table.component";
import { CellGroupFilterComponent } from "./components/cell-group-filter/cell-group-filter.component";
import { SimpleTableBoxComponent } from "./components/simple-table-box/simple-table-box.component";

@NgModule({
  declarations: [
    AvailabilityTableComponent,
    LoadingComponent,
    SearchInputComponent,
    LivingPopupComponent,
    CenterFilterComponent,
    AddressFilterComponent,
    ServiceFilterComponent,
    ConditionFilterComponent,
    HealthPopupComponent,
    CellGroupPopupComponent,
    AddressFormComponent,
    ServicePopupComponent,
    MessagePopupComponent,
    TimePickerComponent,
    AnnouncementStatusFilterComponent,
    AnnouncementDateRangeFilterComponent,
    ServiceDateRangeFilterComponent,
    DonationTypeFilterComponent,
    RedeemTypeFilterComponent,
    RedeemStatusFilterComponent,
    CenterPopupComponent,
    RequestStatusFilterComponent,
    EventTypeFilterComponent,
    EventTransactionTypeFilterComponent,
    EventStatusFilterComponent,
    ImportPopupComponent,
    ImportStatusPopupComponent,
    EmojiPickerComponent,
    SignUpStatusFilterComponent,
    CellGroupFilterComponent,
    SimpleTableBoxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatDatepickerModule,
    MatProgressBarModule,
  ],
  exports: [
    LoadingComponent,
    SearchInputComponent,
    LivingPopupComponent,
    CenterFilterComponent,
    AddressFilterComponent,
    ServiceFilterComponent,
    ConditionFilterComponent,
    HealthPopupComponent,
    CellGroupPopupComponent,
    AddressFormComponent,
    ServicePopupComponent,
    MessagePopupComponent,
    TimePickerComponent,
    AnnouncementStatusFilterComponent,
    AnnouncementDateRangeFilterComponent,
    ServiceDateRangeFilterComponent,
    DonationTypeFilterComponent,
    RedeemTypeFilterComponent,
    RedeemStatusFilterComponent,
    CenterPopupComponent,
    RequestStatusFilterComponent,
    EventTypeFilterComponent,
    EventTransactionTypeFilterComponent,
    EventStatusFilterComponent,
    ImportPopupComponent,
    ImportStatusPopupComponent,
    EmojiPickerComponent,
    AvailabilityTableComponent,
    SignUpStatusFilterComponent,
    CellGroupFilterComponent,
    SimpleTableBoxComponent,
  ],
})
export class ShareModule {}
