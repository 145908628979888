import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { Profile } from "src/app/models/profiles/profile";
import {
  RedeemQuestion,
  IRedeemQuestion,
} from "src/app/models/redeem-questions/redeem-question";
import {
  RedeemHistory,
  IRedeemHistory,
} from "src/app/models/redeems/redeem-history";

@Injectable({
  providedIn: "root",
})
export class RedeemService {
  public user: Profile | null = null;

  constructor(private httpService: HttpService) {}

  public approve(redeemUUId: string, quantity: number): Observable<any> {
    return this.httpService.post<any, { RedeemUUId: string; Quantity: number }>(
      "redeem/admin/redeems/approve",
      { RedeemUUId: redeemUUId, Quantity: quantity },
    );
  }

  public grant(
    productUUId: string,
    userUUId: string,
    quantity: number,
    reason: string,
  ): Observable<any> {
    return this.httpService.post<
      any,
      {
        ProductUUId: string;
        UserUUId: string;
        Quantity: number;
        RedeemReason: string;
      }
    >("redeem/admin/redeems/grant", {
      ProductUUId: productUUId,
      UserUUId: userUUId,
      Quantity: quantity,
      RedeemReason: reason,
    });
  }

  public update(redeemUUId: string, quantity: number): Observable<any> {
    return this.httpService.put<any, { RedeemUUId: string; Quantity: number }>(
      "redeem/admin/redeems",
      { RedeemUUId: redeemUUId, Quantity: quantity },
    );
  }

  public cancel(redeemUUId: string): Observable<any> {
    return this.httpService.delete<any>(
      "redeem/admin/redeems?RedeemUUId=" + redeemUUId,
    );
  }

  public getQuestions(): Observable<RedeemQuestion[]> {
    return this.httpService
      .get<IRedeemQuestion[]>("redeem/redeems/question")
      .pipe(
        map((result) =>
          result.result.map((question) => new RedeemQuestion(question)),
        ),
      );
  }

  public getRules(): Observable<{ title: string; rule: string }> {
    return this.httpService
      .get<{ title: string; rule: string }>("redeem/products/rule")
      .pipe(map((result) => result.result));
  }

  public setRules(title: string, rule: string): Observable<any> {
    return this.httpService.post<any, { title: string; rule: string }>(
      "redeem/admin/products/rule",
      { title: title, rule: rule },
    );
  }

  public getUserRedeemHistory(userUUId: string): Observable<RedeemHistory[]> {
    return this.httpService
      .get<IRedeemHistory[]>("redeem/admin/redeems?UserUUId=" + userUUId)
      .pipe(
        map((result) =>
          result.result.map((redeem) => new RedeemHistory(redeem)),
        ),
      );
  }

  public deliveredRedeem(redeemUUId: string): Observable<any> {
    return this.httpService.post<any, { RedeemUUId: string }>(
      "redeem/admin/redeems/Delivered",
      { RedeemUUId: redeemUUId },
    );
  }
  public getUrgentNum(): Observable<Number> {
    return this.httpService
      .get<Number>("redeem/admin/products/urgentNum")
      .pipe(map((value) => Number(value.result)));
  }
}
