import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { EMPTY, forkJoin, Observable } from "rxjs";
import { concatMap, defaultIfEmpty, finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Center } from "src/app/models/centers/center";
import { CenterService } from "src/app/services/center/center.service";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { ProductService } from "src/app/services/product/product.service";
import { RedeemQuestionService } from "src/app/services/redeem-question/redeem-question.service";
import { RedeemService } from "src/app/services/redeem/redeem.service";

@Component({
  selector: "app-redeem-setting-page",
  templateUrl: "./redeem-setting-page.component.html",
  styleUrls: ["./redeem-setting-page.component.scss"],
})
export class RedeemSettingPageComponent implements OnInit {
  centers: Center[] = [];

  questions: FormArray = new FormArray([]);
  bins: string[] = [];

  ruleForm: FormGroup = new FormGroup({
    ruleTitle: new FormControl(null, [Validators.required]),
    ruleDescription: new FormControl(null, [Validators.required]),
  });

  constructor(
    private centerService: CenterService,
    private loadingService: LoadingService,
    private productService: ProductService,
    private redeemQuestionService: RedeemQuestionService,
    private popupMessageService: PopupMessageService,
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.loadingService.startLoading();

    forkJoin({
      centers: this.centerService.getRedeemCenters(),
      questions: this.redeemQuestionService.getQuestions(),
      rules: this.productService.getRules(),
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe((value) => {
        this.centers = value.centers;
        this.questions = new FormArray(
          value.questions.map(
            (question) =>
              new FormGroup({
                uuid: new FormControl(question.uuId),
                question: new FormControl(question.question, [
                  Validators.required,
                ]),
                setting: new FormControl(question.mode, [Validators.required]),
                options: new FormArray(
                  question.options.map(
                    (option) =>
                      new FormControl(option.option, [Validators.required]),
                  ),
                ),
              }),
          ),
        );

        this.ruleForm.controls["ruleTitle"].setValue(value.rules?.title);
        this.ruleForm.controls["ruleDescription"].setValue(value.rules?.rule);

        if (this.questions.length == 0) this.addQuestion();
      });
  }

  deleteCenter(center: Center): void {
    center.redeemEnabled = true;
    this.loadingService.startLoading();
    this.centerService
      .setRedeemCenters(
        this.centers
          .filter((center) => center.redeemEnabled == true)
          .map((c) => c.uuId),
      )
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe();
  }

  get blockedCenters(): Center[] {
    return this.centers.filter((center) => center.redeemEnabled == false);
  }

  centerPopupAction: (centers: Center[]) => void = ((centers: Center[]) => {
    this.centerService
      .setRedeemCenters(
        this.centers
          .filter((center) => centers.every((c) => center.uuId != c.uuId))
          .map((center) => center.uuId),
      )
      .subscribe({
        next: (value) => {
          this.fetchData();
        },
      });
  }).bind(this);

  addQuestion(): void {
    this.questions.push(
      new FormGroup({
        question: new FormControl(null, [Validators.required]),
        setting: new FormControl(null, [Validators.required]),
        options: new FormArray([
          new FormControl(null, [Validators.required]),
          new FormControl(null, [Validators.required]),
        ]),
      }),
    );
  }

  removeQuestion = ((index: number) => {
    if ((this.questions.controls[index] as FormGroup).controls["uuid"] != null)
      this.bins.push(
        (this.questions.controls[index] as FormGroup).controls["uuid"].value,
      );
    this.questions.removeAt(index);
  }).bind(this);
  save(): void {
    if (this.questions.invalid) {
      this.questions.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.SaveRedeemQuestionFailMessage(EMPTY),
      );
      return;
    }

    if (this.ruleForm.invalid) {
      this.ruleForm.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(
        PopupMessages.SaveRedeemQuestionFailMessage(EMPTY),
      );
      return;
    }
    let formValue = this.questions.value as {
      uuid: string;
      question: string;
      setting: string;
      options: string[];
    }[];

    let createTask = formValue
      .filter((value) => value.uuid == null)
      .map((value) =>
        this.redeemQuestionService.createQuestion({
          question: value.question,
          questionMode: value.setting,
          options: value.options,
        }),
      );

    let updateTask = this.questions.controls
      .filter((control) => control.dirty == true)
      .map((control) => control.value)
      .filter((value) => value.uuid != null)
      .map((value) =>
        this.redeemQuestionService.updateQuestion({
          uuid: value.uuid,
          question: value.question,
          questionMode: value.setting,
          options: value.options,
        }),
      );

    let ruleTask = this.productService.setRules(
      this.ruleForm.controls["ruleTitle"].value,
      this.ruleForm.controls["ruleDescription"].value,
    );

    this.loadingService.startLoading();
    forkJoin(
      this.bins.map((uuid) => this.redeemQuestionService.deleteQuestion(uuid)),
    )
      .pipe(
        defaultIfEmpty(),
        concatMap(() =>
          forkJoin({
            create: forkJoin(createTask).pipe(defaultIfEmpty()),
            update: forkJoin(updateTask).pipe(defaultIfEmpty()),
            rule: ruleTask,
          }),
        ),
        finalize(() => {
          this.loadingService.stopLoading();
          this.bins = [];
        }),
      )
      .subscribe({
        next: () =>
          this.popupMessageService.messageSignal.emit(
            PopupMessages.SaveRedeemQuestionSuccessMessage(
              new Observable((subscriber) => {
                this.fetchData();
              }),
            ),
          ),
        error: () =>
          this.popupMessageService.messageSignal.emit(
            PopupMessages.SaveRedeemQuestionFailMessage(
              new Observable((subscriber) => {
                this.fetchData();
              }),
            ),
          ),
      });
  }
}
