import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { RequestImportModel } from "src/app/models/imports/request-import-model";
import {
  CreateRequestModel,
  RequestRecordModel,
} from "src/app/models/requests/create-request-model";
import { IRequest, Request } from "src/app/models/requests/request";
import {
  IRequestListItem,
  RequestListItem,
} from "src/app/models/requests/request-list-item";
import { CreateRequestForm } from "src/app/modules/request/forms/create-request-form";
import { CreateRequestRecordForm } from "src/app/modules/request/forms/create-request-record-form";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  form: CreateRequestForm = new CreateRequestForm();
  requestRecordForm: CreateRequestRecordForm = new CreateRequestRecordForm();

  constructor(private httpService: HttpService) {}

  public getRequests(
    queryString: string,
  ): Observable<{ list: RequestListItem[]; count: number }> {
    return this.httpService
      .get<{ list: IRequestListItem[]; count: number }>(
        "requests/list" + queryString,
      )
      .pipe(
        map((value) => {
          return {
            list: value.result.list.map(
              (request) => new RequestListItem(request),
            ),
            count: value.result.count,
          };
        }),
      );
  }

  public getRequestByUUId(uuId: string): Observable<Request> {
    return this.httpService
      .get<IRequest>("requests/" + uuId)
      .pipe(map((value) => new Request(value.result)));
  }

  public createRequest(
    userUUId: string,
    model: CreateRequestModel,
  ): Observable<any> {
    return this.httpService.post<any, CreateRequestModel>(
      "requests/" + userUUId,
      model,
    );
  }

  public deleteRequests(model: string[]): Observable<any> {
    return this.httpService.put<any, { list: string[] }>("requests/delete", {
      list: model,
    });
  }

  public inviteVolunteers(
    requestUUId: string,
    model: { volunteersUUId: string[] },
  ): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>(
      "requests/" + requestUUId + "/invite",
      model,
    );
  }

  public askForInterest(
    requestUUId: string,
    model: { volunteersUUId: string[] },
  ): Observable<any> {
    return this.httpService.post<any, { volunteersUUId: string[] }>(
      "requests/" + requestUUId + "/askforinterest",
      model,
    );
  }

  public finishRequest(requestUUId: string): Observable<any> {
    return this.httpService.post<any, null>(
      "requests/" + requestUUId + "/Finish",
      null,
    );
  }

  public approveRequest(requestUUId: string): Observable<any> {
    return this.httpService.put<any, any>(
      "requests/" + requestUUId + "/ready",
      null,
    );
  }
  public editRemark(requestUUId: string, model: { Remark: string }) {
    return this.httpService.put<any, { Remark: string }>(
      "orders/" + requestUUId + "/remark",
      model,
    );
  }
  public editCheckInCheckOutTime(
    requestUUId: string,
    model: { CheckInTime: string; CheckOutTime: string },
  ): Observable<any> {
    return this.httpService.post<
      any,
      { CheckInTime: string; CheckOutTime: string }
    >("orders/" + requestUUId + "/edit", model);
  }

  public giveRequestAllowance(model: {
    requestUUId: string;
    amount: number;
  }): Observable<any> {
    return this.httpService.post<{ requestUUId: string; amount: number }, any>(
      "donations/DonationRequest",
      model,
    );
  }

  public postRating(
    requestUUId: string,
    rating: { rating: number; comment: string },
  ): Observable<any> {
    return this.httpService.post<any, { rating: number; comment: string }>(
      "requests/" + requestUUId + "/volunteerRating",
      rating,
    );
  }

  public postStaffComment(
    requestUUId: string,
    model: { Comment: string },
  ): Observable<any> {
    return this.httpService.post<any, { Comment: string }>(
      "orders/" + requestUUId + "/StaffComment",
      model,
    );
  }

  public startRequest(
    requestUUId: string,
    model: { dateTime: string },
  ): Observable<any> {
    return this.httpService.post<any, { dateTime: string }>(
      "orders/" + requestUUId + "/start",
      model,
    );
  }

  public cancelRequest(
    requestUUId: string,
    model: { CancelRequestReason: string },
  ): Observable<any> {
    return this.httpService.put<any, { CancelRequestReason: string }>(
      "requests/" + requestUUId + "/cancel",
      model,
    );
  }

  public cancelRequestReason(
    requestUUId: string,
    model: { CancelRequestReason: string },
  ): Observable<any> {
    return this.httpService.put<any, { CancelRequestReason: string }>(
      "requests/" + requestUUId + "/CancelReason",
      model,
    );
  }

  /** Restore the list of deleted requests */
  public restoreRequests(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>(
      "requests/restore",
      model,
    );
  }

  /** Delete requests permanently */
  public deleteRequestsPermanently(model: { list: string[] }): Observable<any> {
    return this.httpService.put<any, { list: string[] }>(
      "requests/deletePermanently",
      model,
    );
  }

  public ImportRequest(model: RequestImportModel): Observable<any> {
    return this.httpService.post<any, RequestImportModel>(
      "requests/import",
      model,
    );
  }
  public CreateRequestRecord(model: RequestRecordModel): Observable<any> {
    return this.httpService.post<any, RequestRecordModel>(
      "requests/createRecord",
      model,
    );
  }

  public getUrgentNum(): Observable<Number> {
    return this.httpService
      .get<Number>("requests/urgentNum")
      .pipe(map((value) => Number(value.result)));
  }
}
