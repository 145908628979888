import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { PopupMessages } from "src/app/backend/popup-message";
import { Requester } from "src/app/models/requests/requester";
import { Transaction } from "src/app/models/transactions/transaction";
import { LoadingService } from "src/app/services/loading/loading.service";
import { PopupMessageService } from "src/app/services/popup-message/popup-message.service";
import { RequestService } from "src/app/services/request/request.service";
import { TransactionService } from "src/app/services/transaction/transaction.service";

@Component({
  selector: "app-request-subsidy",
  templateUrl: "./request-subsidy.component.html",
  styleUrls: ["./request-subsidy.component.scss"],
})
export class RequestSubsidyComponent implements OnInit {
  @Input() subsidy!: Transaction | null;
  @Input() reloadAction: () => void = () => {};
  @Input() requester!: Requester;

  form!: FormGroup;
  editing!: boolean;

  constructor(
    private popupMessageService: PopupMessageService,
    private requestService: RequestService,
    private transcationService: TransactionService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      isSubsidised: new FormControl(this.subsidy ? true : false),
      amount: new FormControl(this.subsidy ? this.subsidy.amount : null, [
        Validators.required,
      ]),
    });

    this.editing = this.subsidy ? false : true;
  }

  subsidise(): void {
    if (this.form.invalid) {
      this.popupMessageService.messageSignal.emit(
        PopupMessages.InvalidInformationMessage,
      );
      return;
    }

    if (this.subsidy) this.updateSubsidy();
    else this.createSubsidy();
  }

  private createSubsidy(): void {
    this.loadingService.startLoading();
    this.route.params.subscribe((value) => {
      console.log(value);
      this.requestService
        .giveRequestAllowance({
          requestUUId: value.id,
          amount: this.form.controls["amount"].value,
        })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.editing = false;
          this.reloadAction();
        });
    });
  }

  private updateSubsidy(): void {
    this.route.params.subscribe(() => {
      this.loadingService.startLoading();
      this.transcationService
        .editTransaction(this.subsidy?.uuId as string, {
          amount: this.form.controls["amount"].value,
        })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((value) => {
          this.editing = false;
          this.reloadAction();
        });
    });
  }

  get isSubsidised(): boolean {
    return this.form.controls["isSubsidised"].value;
  }
}
