import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/backend/http.service";
import { CreateServiceModel } from "src/app/models/services/create-service-model";
import { CreateServiceTypeModel } from "src/app/models/services/create-service-type-model";
import {
  ServiceType,
  IServiceType,
} from "src/app/models/services/service-type";
import { UpdateServiceModel } from "src/app/models/services/update-service-model";
import { UpdateServiceTypeModel } from "src/app/models/services/update-service-type-model";

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  signal: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService) {}

  public getServiceTypeList(): Observable<ServiceType[]> {
    return this.httpService
      .get<IServiceType[]>("services")
      .pipe(
        map((value) =>
          value.result
            .map((serviceType) => new ServiceType(serviceType))
            .sort(ServiceTypeSorting),
        ),
      );
  }

  public getAllServiceTypeList(): Observable<ServiceType[]> {
    return this.httpService
      .get<IServiceType[]>("services?ShowAll=true")
      .pipe(
        map((value) =>
          value.result
            .map((serviceType) => new ServiceType(serviceType))
            .sort(ServiceTypeSorting),
        ),
      );
  }

  public createServiceType(model: CreateServiceTypeModel): Observable<any> {
    return this.httpService.post<any, CreateServiceTypeModel>(
      "services/serviceType",
      model,
    );
  }

  public updateServiceType(model: UpdateServiceTypeModel): Observable<any> {
    return this.httpService.put<any, UpdateServiceTypeModel>(
      "services/serviceType",
      model,
    );
  }

  public createService(model: CreateServiceModel): Observable<any> {
    return this.httpService.post<any, CreateServiceModel>("services", model);
  }

  public updateService(model: UpdateServiceModel): Observable<any> {
    return this.httpService.put<any, UpdateServiceModel>("services", model);
  }
}

function ServiceTypeSorting(a: ServiceType, b: ServiceType): number {
  return a.name.localeCompare(b.name, "en", { numeric: true });
}
