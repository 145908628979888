<div class="box">
    <p class="title" translate>中心義工</p>
    <div class="inline-flex-column">
        <p class="subTitle" translate>默認義工服務申請只顯示同一中心內的義工列表，可設置和其他中心的義工列表互通邀請</p>

        <div class="cell-group-box">
            <div class="center-column">
                <div class="center-item mb-2" [ngClass]="{active: index == i}" *ngFor="let center of centers; let i = index" (click)="index = i">
                    <p>{{ center.center.name }}</p>
                    <p>{{ center.canFindCenters.length }}</p>
                </div>
            </div>

            <div class="cell-group-column">
                <ng-container *ngIf="selectedCenter">
                    <div>
                        <div class="remark" translate>（只可用於邀請義工，管理其他中心義工會員檔案另需開通權限）</div>
                    </div>
                    <div class="cell-group-item mb-2" [ngClass]="{hide: center.center==selectedCenter}" *ngFor="let center of centers">
                        <div >
                           <label> <input type="checkbox" (click)="update(center.center)" [checked]="hasCenter(center.center)"/><p>{{center.center.name}}</p></label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="local-loading-bg" *ngIf="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>